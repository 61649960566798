import React, { useEffect, useState } from "react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import log from "loglevel";
import { hookListPolicyDocumentsByOrgId } from "../../../hooks/policyDocumentHooks";
import { GetSignedURL } from "../../../hooks/s3Hooks";
import { Document, Page, pdfjs } from "react-pdf";
import { Skeleton } from "../../CustomComponentsLibrary/Skeleton";
import PageHeader from "../../common/PageHeader";
import { Button } from "../../CustomComponentsLibrary/Button";
export function EmployeeModuleEmployeeHandbook() {
  const [file, setFile] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [S3Key, setS3Key] = useState([]);
  const { organizationID, theme } = useAuthContextData();
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber] = React.useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  pdfjs.verbosity = 5;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (numPages !== null) {
    }
  }, [numPages]);

  useEffect(() => {
    async function fetchData() {
      const policyDocument = await hookListPolicyDocumentsByOrgId(
        organizationID
      );
      log.debug(policyDocument);
      setS3Key(policyDocument);
    }
    fetchData();
  }, [organizationID]);
  log.debug(S3Key);
  useEffect(() => {
    var f = S3Key ? S3Key[0]?.employeeHandbookS3Key : "";
    log.debug(f);
    try {
      setFile(f);
    } catch (e) {
      log.debug(e);
    }
  }, [S3Key]);

  async function data(file) {
    if (file) {
      var signedURL = await GetSignedURL(file);
      log.debug("GetSignedURL :  URL = ", signedURL);
      setPdfFile(signedURL);
    }
  }
  const downloadFile = () => {
    window.open(pdfFile);
  };
  data(file);
  const showSkeleton = [1, 2, 3, 4, 5];

  return (
    <div className="m-3 overflow-auto">
      <div className="mt-4 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <PageHeader
            title="Employee Handbook"
            SubHeading="You can view or download employee handbook"
            showDropdown={false}
          />
        </div>
      </div>
      <div className=" shadow-md rounded-lg border-t border-zinc-100 mt-5">
        {pdfFile ? (
          <>
            <div className="float-right m-5">
              <Button
                variant=""
                onClick={downloadFile}
                className={`"m-5" ${theme?.textColor} ${theme?.background}`}
              >
                Download{" "}
              </Button>
            </div>
            <div className="mt-8 flow-root">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-5">
                {/* <DataTable columns={columns} data={handbookS3Key} /> */}
              </div>

              {file && (
                <Document
                  className="flex flex-col justify-center items-center w-full ml-48"
                  file={pdfFile}
                  onLoadError={(error) => {
                    console.error("Error loading PDF:", error);
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {/* <Page /> */}
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((pageNumber) => (
                      <Page
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        customTextRenderer={false}
                      />
                    ))}
                </Document>
              )}
              <p className="m-5">
                Page {pageNumber} of {numPages}
              </p>
            </div>
          </>
        ) : (
          <>
            {showSkeleton?.map(() => (
              <div className="flex items-center w-full m-auto ">
                <Skeleton className="h-12 w-12 rounded-full" />
                <div className="space-y-2  w-[100%]">
                  <Skeleton className="h-4 w-[100%]" />
                  <Skeleton className="h-4 w-[100%]" />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
