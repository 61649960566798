import React from "react";
import log from "loglevel";
import { useState, useMemo, useEffect } from "react";
import {
  CONST_DOCUMENT_STATUS,
  CONST_EMPLOYEE_STATUS,
} from "../../../constants/dbconstants";
import Dropdown from "../../../components/common/Dropdown";
import useForm from "../../../customHooks/useForm";
import {
  hookAddEmployeeDocument,
  hookListEmployeeDocumentsByUserId,
} from "../../../hooks/employeeDocumentsHooks";
import {
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../hooks/employeeMachineHooks";
import moment from "moment";
import { GetSignedURL } from "../../../hooks/s3Hooks";
import { Storage } from "aws-amplify";
import { hookUpdateEmployee } from "../../../hooks/employeeHooks";
import SuccessPopupOnAction from "../../../components/common/SuccessPopupOnAction";
import { Input } from "../../../components/CustomComponentsLibrary/Input";
import { Button } from "../../../components/CustomComponentsLibrary/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/CustomComponentsLibrary/Card";
import { UploadIcon, ViewIcon } from "lucide-react";
import { useToast } from "../../../components/CustomComponentsLibrary/UseToast";
import { DataTable } from "../../CustomComponentsLibrary/data-table";
import PageHeader from "../../common/PageHeader";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export default function UploadEmployeeDocs() {
  const [inputFields, setInputFields] = useState(false);
  const [docsArray, setDocsArray] = useState([]);
  const [selectedDocToUpload, setselectedDocToUpload] = useState(null);
  const [UploaedDocsList, setUploaedDocsList] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [docVerify, setDocVerify] = useState(false);
  const [filesrc, setFileSrc] = useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [actionForPopup, setActionForPopup] = useState("");
  const { organization, theme } = useAuthContextData();
  const { myEmployeeProfile, userID } = useEmployeeContextData();
  log.debug("myEmployeeProfile from upload docs", myEmployeeProfile);
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  log.debug("userID::", userID);
  useEffect(() => {
    async function fetchDocuments() {
      try {
        let documentsList = await hookListEmployeeDocumentsByUserId(
          myEmployeeProfile?.cognitoId
        );
        var sorted_list = documentsList
          .sort((a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          })
          .reverse();
        log.debug("SORTED_LIST", sorted_list);
        setUploaedDocsList(sorted_list);
        setDocsArray(sorted_list);
      } catch (e) {
        log.debug("ERROR", e);
      }
      log.debug("UseEffect UploaedDocsList ", UploaedDocsList, docsArray);
    }
    if (myEmployeeProfile?.cognitoId) {
      fetchDocuments();
    }
  }, [myEmployeeProfile?.cognitoId, docVerify]);
  const documentList = organization?.allowedDocumentsToUpload?.map((str) =>
    str?.replace(/_/g, " ")
  );
  const docTypes = useMemo(() => {
    const docNames = docsArray?.map((item) => ({
      name: item?.name,
    }));

    const filteredDocTypes = organization?.allowedDocumentsToUpload?.map(
      (item) => ({
        name: item,
      })
    );
    log.debug("filteredDocTypes", filteredDocTypes, docNames);

    const result = filteredDocTypes?.filter((element) => {
      return !docNames?.some((doc) => doc?.name === element?.name);
    });

    log.debug("result", result);
    return result;
  }, [docsArray]);

  function openSuccessPopup() {
    setSuccessPopup(true);
    setIsOpen(true);
  }

  function closeSuccessPopup() {
    setIsOpen(false);
  }

  const handleAddInputField = () => {
    setInputFields(true);
  };

  const handleUploadSave = async (e) => {
    log.debug("selectedFile:handleUploadSave", selectedFile);
    if (selectedFile) {
      let filename;
      if (selectedDocToUpload) {
        filename =
          selectedDocToUpload?.name +
          "_" +
          myEmployeeProfile?.cognitoId +
          "_" +
          myEmployeeProfile?.firstName +
          ".pdf";
      }
      const fileType = selectedFile.type;
      log.debug(
        "fileType: :",
        fileType,
        filename,
        selectedFile,
        fileType === "application/pdf"
      );
      const fileURL = URL.createObjectURL(selectedFile);
      log.debug("fileURL", fileURL);
      if (fileType === "application/pdf") {
        {
          try {
            await Storage.put(
              filename,
              selectedFile,
              { level: "public" },
              {
                progressCallback(progress) {
                  log.debug(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
              }
            );
            const uploadDate = new Date();
            const documentToAdd = {
              name: selectedDocToUpload?.name,
              s3key: filename,
              status: CONST_DOCUMENT_STATUS.docUploaded,
              uploadDate: moment(uploadDate).format("YYYY-MM-DD"),
              userID: myEmployeeProfile?.cognitoId,
            };

            log.debug("documentToAdd", documentToAdd);

            const document = await hookAddEmployeeDocument(documentToAdd);
            if (document) {
              setDocsArray((prev) => [...prev, document]);
              log.debug(" : after uploading Documents ", document);
              setUploaedDocsList((prev) => [...prev, document]);
              const updateTeamMember = {
                employeeStatus: CONST_EMPLOYEE_STATUS.employeeVerifyPending,
                cognitoId: myEmployeeProfile?.cognitoId,
              };

              log.debug("updateTeamMember", updateTeamMember);
              const employeeUpdated = await hookUpdateEmployee(
                updateTeamMember
              );

              setFileSrc(filename);
              setInputFields(false);
              setselectedDocToUpload(null);
              openSuccessPopup();
              log.debug("employeeUpdated", employeeUpdated);
            }
            // setselectedDocToUpload(false);
            log.debug(" : after uploading pdf ", filename, successPopup);
            log.debug(" : after uploading pdf ", docsArray);
          } catch (error) {
            toast({
              variant: "destructive",
              title: "Failed",
              description: "Failed to upload document",
            });
            log.debug("Error uploading file: ", error);
          }
        }
      }
    }
    setIsSelected(false);
  };

  const { inputs, handleChange, clearForm, resetForm } = useForm({
    selectedDocToUpload: "",
  });

  const onChangeHandleMultipleDocs = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const DownloadUploadedEmployeeDocPDF = async (s3key) => {
    let fileToView;
    if (s3key) {
      fileToView = s3key;
    }

    log.debug("fileToView::", fileToView);
    if (fileToView) {
      let signedURL = await GetSignedURL(fileToView);
      log.debug("GetSignedURL :  URL = ", signedURL);
      window.open(signedURL);
    }
  };

  const columns = [
    {
      header: "Document Name",
      accessorKey: "name",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <>
          {row.original?.status === CONST_DOCUMENT_STATUS.documentVerifyPass ? (
            <div className="h-6 w-6 relative top-[-10px] text-green-500 font-semibold">
              Verify Pass
            </div>
          ) : row.original?.status ===
            CONST_DOCUMENT_STATUS.documentVerifyFail ? (
            <div className="h-6 w-6 relative top-[-10px] text-red-500 font-semibold">
              Verify Fail
            </div>
          ) : (
            <div className="h-6 w-6 relative top-[-10px] font-semibold">
              Verify Pending
            </div>
          )}
        </>
      ),
    },
    {
      header: "Action",
      accessorKey: "",
      cell: ({ row }) => (
        <Button
          variant="primary"
          // className="rounded-full px-2"
          onClick={() => DownloadUploadedEmployeeDocPDF(row.original?.s3key)}
        >
          <ViewIcon className="h-6 w-6"></ViewIcon>
          <span className="sr-only">View</span>
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="m-2">
        <PageHeader
          title="Documents"
          SubHeading=" Upload your userID's documents such as contracts, forms, and emails."
        />
      </div>
      <div className="shadow-md border-t border-zinc-100 m-3 pb-5">
        <div className="p-10">
          <label className="text-base font-bold my-4">
            Upload below number of documents
          </label>
          {documentList?.map((item, index) => (
            <div className="list-decimal mt-2 text-base">
              {index + 1}
              {"."}
              {item}
            </div>
          ))}
          <div className="mt-5">
            <Card className={`${theme?.borderBg}`}>
              <CardHeader>
                <CardTitle>Upload Documents</CardTitle>
                <CardDescription>
                  Upload all Documents from above mentioned list
                </CardDescription>
              </CardHeader>
              <CardContent className="">
                {inputFields ? (
                  <div className="flex flex-col flex-wrap overflow-x-hidden">
                    <div className="m-0 w-fit sm:w-96 sm:m-3">
                      <option>Select Document To Upload</option>
                      <Dropdown
                        list={docTypes}
                        setSelected={setselectedDocToUpload}
                        selected={selectedDocToUpload}
                        onChange={handleChange}
                        className="w-auto"
                        required
                      />
                    </div>
                    <div className="flex">
                      <div className="m-3">
                        <Input
                          type="file"
                          onChange={onChangeHandleMultipleDocs}
                          name="file-upload"
                          id="file-upload"
                          accept="application/pdf"
                          required
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </CardContent>
              <CardFooter>
                <div>
                  {selectedDocToUpload && isSelected ? (
                    <div className="mx-3">
                      <Button
                        className={`"" ${theme?.textColor} ${theme?.background}`}
                        variant="default"
                        size="sm"
                        onClick={() => {
                          handleUploadSave();
                          setActionForPopup("UploadDocs");
                        }}
                      >
                        Upload <UploadIcon className="ml-2 h-4 w-4" />
                        {/* Click here to Upload File */}
                      </Button>
                    </div>
                  ) : (
                    <div className="m-auto sm:m-3">
                      <Button
                        className={`"" ${theme?.textColor} ${theme?.background}`}
                        variant=""
                        // className="bg-blue-600 mt-10 p-4"
                        onClick={handleAddInputField}
                      >
                        Upload Docs
                      </Button>
                    </div>
                  )}
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>

        <div>
          {UploaedDocsList ? (
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mt-4 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h3 className="text-2xl">Uploaded Documents</h3>
                </div>
              </div>
              <div className="mt-8 flow-root shadow-md sm:rounded-lg border-t border-zinc-100">
                <div className="relative overflow-x-auto m-5">
                  <DataTable
                    columns={columns}
                    data={UploaedDocsList}
                    customCss={`${theme?.border}`}
                    customWidth={[
                      {
                        index: 0,
                        cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                      },
                      {
                        index: 1,
                        cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                      },
                      {
                        index: 2,
                        widthClass: "32",
                        cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div>
          {successPopup === true ? (
            <SuccessPopupOnAction
              openSuccessPopup={openSuccessPopup}
              closeSuccessPopup={closeSuccessPopup}
              isOpen={isOpen}
              messageTitle={
                actionForPopup === "UpdateEmplopyee"
                  ? "Employee Status"
                  : "Document Upload successfully"
              }
              MessageBody={
                actionForPopup === "UpdateEmplopyee"
                  ? "Employee Status is Updated successfully"
                  : "Your Document was successfully uploaded "
              }
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
