import React from "react";
import { TabPageLink } from "../../components/common/TabPageLink";

const tabs = [
  {
    name: "Blogs",
    path: "/employee/blogs",
  },

  // {
  //   name: "Blog Details",
  //   path: "/employee/blogs/details",
  // },
  // {
  //   name: "Edit Blog",
  //   path: "/employee/blogs/edit",
  // },
  {
    name: "Write Blog",
    path: "/employee/blogs/add",
  },
  //   {
  //     name: "Blogs",
  //     path: "/employee/settings/blogs",
  //   },
];

export function EmployeeBlogsLayout() {
  console.log("tabs::", tabs);
  return (
    <>
      <TabPageLink tabs={tabs} />
    </>
  );
}
