import { useEmployeeContextData } from "../../hooks/employeeMachineHooks";
import React, { useMemo } from "react";
import { CONST_GOAL_STATUS } from "../../constants/dbconstants";
import { DataTable } from "../../components/CustomComponentsLibrary/data-table";
import log from "loglevel";
import { FriendlyName } from "../../lib/friendlyName";
import PageHeader from "../../components/common/PageHeader";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
const Achivements = () => {
  const { goals } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const approvedGoals = useMemo(() => {
    return (
      goals?.filter(
        (goal) => goal.status === CONST_GOAL_STATUS.goalStatusDoneApproved
      ) || []
    );
  }, [goals]);
  log.debug("approvedGoals", approvedGoals);
  const columns = [
    {
      header: "Title",
      accessorKey: "title",
    },
    {
      header: "Description",
      accessorKey: "content",
    },
    {
      header: "Status",
      accessorKey: "status",

      cell: ({ row }) => (
        <FriendlyName className="w-48" status={row.getValue("status")} />
      ),
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
  ];
  return (
    <>
      <div className="m-3">
        <div>
          <PageHeader
            title="Achievements"
            SubHeading="Overview and tracking of all your goals."
          />
        </div>
        <div className="shadow-md rounded-md mt-5 border-t border-t-zinc-100">
          <div className="ml-5 mr-5 mt-5 pb-5">
            <DataTable
              columns={columns}
              data={approvedGoals}
              customCss={`${theme?.border}`}
              customWidth={[
                {
                  index: 0,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 1,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 2,
                  widthClass: "w-40",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Achivements;
