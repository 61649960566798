import React, { useEffect, useState } from "react";
import log from "loglevel";

import ListCOffs from "../../../components/employee/Calendar/COffs/ListCOffs";
import AddEditCOffs from "../../../components/employee/Calendar/COffs/AddEditCOffs";
import { useEmployeeContextState } from "../../../hooks/employeeMachineHooks";

export function COffsLayout() {
  const { coffsCrud  } = useEmployeeContextState();

  log.debug("COffCrud:::", coffsCrud );

  return (
    <div className="h-full md:rounded-sm lg:rounded-md">
      <ListCOffs />
      <> {coffsCrud  ? <AddEditCOffs /> : <></>}</>
    </div>
  );
}
