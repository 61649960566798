import { toast } from "../../components/CustomComponentsLibrary/UseToast";
import {
  CONST_EMPLOYEE_STATUS,
  CONST_UPDATE_CONTEXT_ACTIONS,
} from "../../constants/dbconstants";
import {
  hookGetEmployeeByCognitoId,
  hookGetEmployeeById,
  hookGetEmployeeShortProfileById,
  hookUpdateEmployee,
} from "../../hooks/employeeHooks";
import log from "loglevel";
import { hookListProjectsByOrganizationId } from "../../hooks/projectHooks";

export const getMyEmployeeProfileService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID } = context;
    const employeeProfile = await hookGetEmployeeById(userID);
    log.debug("employeeProfile:getMyEmployeeProfile", employeeProfile);
    if (employeeProfile?.employees !== null) {
      let userNameArr = await Promise?.all(
        employeeProfile?.employees?.map(async (item) => {
          const userName = await hookGetEmployeeShortProfileById(item);
          log.debug("userName::hookGetEmployeeShortProfileById", userName);

          if (
            userName?.employeeStatus !==
            CONST_EMPLOYEE_STATUS.employeeTerminated
          ) {
            return {
              id: item,
              title: userName?.firstName + " " + userName?.LastName,
            };
          } else {
            return null;
          }
        })
      );
      log.debug("userNameArr1", userNameArr);

      let filteredUsernameArr = userNameArr?.filter((obj) => {
        if (obj === null) return false;
        for (let key in obj) {
          if (obj[key] === null) return false;
        }
        return true;
      });
      log.debug("filteredUsernameArr", filteredUsernameArr);
      if (filteredUsernameArr) {
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "myEmployeeProfile",
            value: employeeProfile,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "employeeList",
            value: filteredUsernameArr,
          },
        ]);
      }
      reject("Failed To Fetch employeeList");
    }
    // log.debug("userNames:hookGetEmployeeShortProfileById", userNames);
    if (employeeProfile) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "myEmployeeProfile",
          value: employeeProfile,
        },
      ]);
    }
    reject("Failed To Fetch employeeProfile Tables");
  });
export const getEmployeePersonalDetailService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID } = context;
    const employeeProfile = await hookGetEmployeeByCognitoId(userID);
    log.debug(
      "employeeProfile:getEmployeePersonalDetailService",
      employeeProfile
    );
    if (employeeProfile) resolve({ personalProfile: employeeProfile });
    reject("Failed To Fetch employeeProfile Tables");
  });
export const updateEmployeeProfileService = (context) =>
  new Promise(async (resolve, reject) => {
    const { personalProfileToBeUpdate } = context;

    const employeeProfile = await hookUpdateEmployee(personalProfileToBeUpdate);
    log.debug("personalProfileToBeUpdate", employeeProfile);

    if (employeeProfile) {
      resolve({ personalProfile: employeeProfile });
      toast({
        title: "Success",
        description: "Profile successfully updated",
      });
    }
    reject("Failed to update profile");
  });

export const getHierarchyDataByEmployeeService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID, organization } = context;
    const employeeProfile = await hookGetEmployeeById(userID);
    log.debug(
      "employeeProfile:HierarchyService",
      employeeProfile,
      organization
    );
    let managersId = employeeProfile?.managers;

    const userNameManagerArr = managersId?.map(async (item) => {
      const userDetails = await hookGetEmployeeShortProfileById(item);
      return userDetails;
    });
    const managersData = await Promise.all(userNameManagerArr);

    const projectDataList = await hookListProjectsByOrganizationId(
      organization?.id
    );

    log.debug("managersData::", managersData);
    log.debug("projectDataList::", projectDataList);

    let employeeProject = projectDataList?.filter((project) => {
      return project?.teammembers?.includes(userID);
    });
    let teamMembersIdsAll = [];
    log.debug("employeeProject::", employeeProject);
    employeeProject?.forEach((project) => {
      let TeamMembers = project?.teammembers;
      log.debug("TeamMembers::", TeamMembers);
      teamMembersIdsAll?.push(...TeamMembers);
    });
    log.debug("teamMembersIdsAll::", teamMembersIdsAll);
    let uniqueMembersId = Array?.from(new Set(teamMembersIdsAll));
    log.debug("uniqueMembersId::", uniqueMembersId);
    const projectEmpArray = uniqueMembersId?.map(async (item) => {
      const userDetails = await hookGetEmployeeShortProfileById(item);
      return userDetails;
    });
    const projectEmployeeData = await Promise?.all(projectEmpArray);
    log.debug("projectEmployeeData::", projectEmployeeData);
    let myTeamLead = [];
    employeeProject?.forEach((project) => {
      let teamLead = project?.leadName;
      myTeamLead?.push(teamLead);
    });
    log.debug("myTeamLead::", myTeamLead);
    const filteredDataTeamLead = projectEmployeeData?.filter(
      (person) =>
        myTeamLead?.some(
          (name) =>
            person?.firstName?.includes(name?.split(" ")[0]) &&
            person?.LastName?.includes(name?.split(" ")[1])
        ) && person?.userType?.includes("TEAMLEAD")
    );
    log.debug("filteredDataTeamLead::", filteredDataTeamLead);
    let initNodes = [
      {
        id: "company",
        type: "custom",
        data: {
          name: organization?.uniqueName?.toUpperCase(),
          job: "Organization",
          email: organization?.tagline,
          emoji: "😎",
        },
        position: { x: 0, y: -300 },
      },
    ];
    let initEdges = [];
    if (managersData) {
      let admins = managersData?.filter((item) => {
        return item?.userType?.includes("HRADMIN");
      });
      log.debug("Admin:::", admins);
      let managers = managersData?.filter((item) => {
        return item?.userType?.includes("MANAGER");
      });
      log.debug("managers:::", managers);
      let teamLeads = managersData?.filter((item) => {
        return item?.userType?.includes("TEAMLEAD");
      });
      if (filteredDataTeamLead) {
        const duplicate = teamLeads?.includes(...filteredDataTeamLead);
        log.debug("dupManager", duplicate);
        if (!duplicate) {
          teamLeads?.push(...filteredDataTeamLead);
        }
      }
      log.debug("teamLeads:::", teamLeads);
      function removeDuplicates(arr) {
        const uniqueMap = {};
        const result = [];

        arr.forEach((item) => {
          if (!uniqueMap[item?.cognitoId]) {
            uniqueMap[item?.cognitoId] = true;
            result?.push(item);
          }
        });

        return result;
      }
      let uniqueTeamLeads = removeDuplicates(teamLeads);
      log.debug("uniqueTeamLeads:::", uniqueTeamLeads);
      function createNode(id, name, job, email, emoji, position) {
        return {
          id: id,
          type: "custom",
          data: {
            name: name,
            job: job,
            email: email,
            emoji: emoji,
          },
          position: position,
        };
      }
      let x = 200;
      let y = -350;
      for (let index = 0; index < managers.length; index++) {
        y += 100;
        x += 100;
        if (index > 5) {
          x -= 350;
          y -= 100;
        }
        const manager = managers[index];
        const nodeId = `manager_${index}`;
        const nodeName = `${manager?.firstName} ${manager?.LastName}`;
        const nodeJob = "Manager";
        const nodeEmail = manager?.email;
        const nodeEmoji = "🤓";
        const nodePosition = { x: x, y: y };
        const newNode = createNode(
          nodeId,
          nodeName,
          nodeJob,
          nodeEmail,
          nodeEmoji,
          nodePosition
        );
        initNodes.push(newNode);
      }

      // for teamLead
      let xt = -400;
      let yt = -350;
      for (let index = 0; index < uniqueTeamLeads?.length; index++) {
        yt += 100;
        xt -= 100;
        const teamLead = uniqueTeamLeads[index];
        const nodeId = `teamLead_${index}`;
        const nodeName = `${teamLead?.firstName} ${teamLead?.LastName}`;
        const nodeJob = "TeamLead";
        const nodeEmail = teamLead?.email;
        const nodeEmoji = "🤓";
        const nodePosition = { x: xt, y: yt };
        const newNode = createNode(
          nodeId,
          nodeName,
          nodeJob,
          nodeEmail,
          nodeEmoji,
          nodePosition
        );
        initNodes.push(newNode);
      }

      // For employee
      const nodeId = `employee`;
      const nodeName = `${employeeProfile?.firstName} ${employeeProfile?.LastName}`;
      const nodeJob = "Self";
      const nodeEmail = employeeProfile?.email;
      const nodeEmoji = "🤓";
      const nodePosition = { x: 0, y: 0 };
      const newNode = createNode(
        nodeId,
        nodeName,
        nodeJob,
        nodeEmail,
        nodeEmoji,
        nodePosition
      );
      initNodes.push(newNode);
      function createEdge(id, source, target) {
        return {
          id: id,
          source: source,
          target: target,
        };
      }
      managers?.forEach((manager, managerIndex) => {
        const edgeId = `manager-admin-${managerIndex}`;
        const sourceNode = `company`;
        const targetNode = `manager_${managerIndex}`;
        const newEdge = createEdge(edgeId, sourceNode, targetNode);
        initEdges.push(newEdge);
      });
      // for TeamLead
      uniqueTeamLeads?.forEach((teamLead, teamLeadIndex) => {
        const edgeId = `teamLead-admin-${teamLeadIndex}`;
        const sourceNode = `teamLead_${teamLeadIndex}`;
        const targetNode = `employee`;
        const newEdge = createEdge(edgeId, sourceNode, targetNode);
        initEdges.push(newEdge);
      });
      // for self Employee
      managers?.forEach((manager, managerIndex) => {
        const edgeId = `employee-1-${managerIndex}`;
        const sourceNode = `manager_${managerIndex}`;
        const targetNode = `employee`;
        const newEdge = createEdge(edgeId, sourceNode, targetNode);
        initEdges.push(newEdge);
      });
      console.log(initEdges);
    }
    function calculateValues(length) {
      let parts = Math.ceil(length / 8);
      if (length < 10) parts = 2;
      const partLength = Math.ceil(length / parts);

      const result = [];

      let xx = 300;
      let yy = 100;

      for (let part = 0; part < parts; part++) {
        for (let i = 0; i < partLength && result?.length < length; i++) {
          result.push([xx, yy]);
          xx += 100;
          yy += 100;
        }
        if (part % 2 === 0) {
          xx = -(part + 1) * 400;
        } else {
          xx = +((part + 1) * 400);
        }
        yy = 100;
      }

      return result;
    }
    if (employeeProfile?.employees) {
      let employeesId = employeeProfile?.employees;
      const userEmployeesArr = employeesId?.map(async (item) => {
        const userDetails = await hookGetEmployeeShortProfileById(item);
        return userDetails;
      });
      let employeesData = [];
      if (employeeProfile?.userType?.includes("TEAMLEAD")) {
        employeesData = projectEmployeeData;
      } else {
        employeesData = await Promise.all(userEmployeesArr);
      }
      log.debug("employeesData::", employeesData);
      const values = calculateValues(employeesData?.length);
      function createNode(id, name, job, email, emoji, position) {
        return {
          id: id,
          type: "custom",
          data: {
            name: name,
            job: job,
            email: email,
            emoji: emoji,
          },
          position: position,
        };
      }
      function createEdge(id, source, target) {
        return {
          id: id,
          source: source,
          target: target,
        };
      }
      for (let index = 0; index < employeesData?.length; index++) {
        const employee = employeesData[index];
        const nodeId = `employee_${index}`;
        const nodeName = `${employee?.firstName} ${employee?.LastName}`;
        const nodeJob = "Employee";
        const nodeEmail = employee?.email;
        const nodeEmoji = "🤓";
        const nodePosition = { x: values[index][0], y: values[index][1] };
        const newNode = createNode(
          nodeId,
          nodeName,
          nodeJob,
          nodeEmail,
          nodeEmoji,
          nodePosition
        );
        initNodes.push(newNode);
      }

      employeesData?.forEach((employee, index) => {
        const edgeId = `employee-list-${index}`;
        const sourceNode = `employee`;
        const targetNode = `employee_${index}`;
        const newEdge = createEdge(edgeId, sourceNode, targetNode);
        initEdges.push(newEdge);
      });
    }

    if (initNodes && initEdges) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "initNodesData",
          value: initNodes,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "initEdgesData",
          value: initEdges,
        },
      ]);
    }
    if (employeeProfile) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "myEmployeeProfile",
          value: employeeProfile,
        },
      ]);
    }
    reject("Failed To Fetch employeeProfile Tables");
  });

export const getTeamLeadEmployeeListService = (context) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { userID, teamLeadMembersToUpdate } = context;
      let teamLeadEmployee = [];
    
      const isInCognitoIdsToCheck = teamLeadMembersToUpdate?.includes(userID);
      if (!isInCognitoIdsToCheck) {
        teamLeadMembersToUpdate?.push(
          userID
        );
    }
   
      const userEmployeesArr = teamLeadMembersToUpdate?.map(async (item) => {
        const user = await hookGetEmployeeShortProfileById(item);
        log.debug("user::", user);
        return user;
      });



      const employeesData = await Promise.all(userEmployeesArr);
      log.debug(
        "getTeamLeadEmployeeListService::::employeesData",
        employeesData
      );

      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "employee fetched",
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "teamLeadMembers",
          value: employeesData,
        },
      ]);
    } catch (error) {
      log.debug("Error in getTeamLeadEmployeeListService::", error);
      reject(error);
    }
  });
};
