import React from "react";
import log from "loglevel";
import { useMemo } from "react";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { FriendlyName } from "../../../lib/friendlyName";
import { User } from "../../../components/common/User";
import FormatDateAndTime from "../../../components/common/FormatDateAndTime";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import {
  ScrollArea,
  ScrollBar,
} from "../../../components/CustomComponentsLibrary/ScrollArea";
import { useSelector } from "@xstate/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
export function CurrentWeekOnLeave() {
  const { dashboardData } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const { teamMemberCurrentWeekLeave } = dashboardData;
  const currentMonthLeaves = useMemo(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    return teamMemberCurrentWeekLeave.filter((leave) => {
      const leaveMonth = Number(leave.date[0].split("-")[1]);
      const leaveYear = Number(leave.date[0].split("-")[0]);

      return leaveMonth === currentMonth && leaveYear === currentYear;
    });
  }, [teamMemberCurrentWeekLeave]);
  log.debug(currentMonthLeaves);

  const employeeMachineService = useEmployeeContextService();
  const isLeavesLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );

  const columns = [
    {
      header: "Person",
      accessorKey: "appliedBy",
      cell: ({ row }) => (
        <>
          <User userID={row.original?.appliedBy} />
        </>
      ),
    },
    {
      header: "Date",
      accessorKey: "appliedBy",
      cell: ({ row }) => (
        <>
          <FormatDateAndTime
            dateTime={row.original?.date?.join()}
            format={"DD/MM/YYYY"}
          />
        </>
      ),
    },
    {
      header: "Status",
      accessorKey: "appliedBy",
      cell: ({ row }) => (
        <>
          <FriendlyName status={row.original.status} />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="text-[19px] shadow-lg rounded-md border-t border-t-zinc-100 h-72 sm:h-96">
        <div className="px-4 py-3">
          <span className="font-bold"> Leaves for The Week</span>
        </div>

        <div className="text-sm font-bold rounded-none px-5 border-none text-foreground">
          <ScrollArea className="h-48 w-full">
            <DataTable
              columns={columns}
              data={currentMonthLeaves}
              dataTableToolBar={false}
              loader={isLeavesLoading}
              customCss={`${theme?.border}`}
              customWidth={[
                {
                  index: 0,
                  widthClass: "w-96",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 1,
                  widthClass: "w-40",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 2,
                  widthClass: "w-48",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
              ]}
            />
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </div>
    </>
  );
}
