import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { TextField } from "./Fields";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { LogoName } from "./LogoName";
import ButtonComponent from "./common/ButtonComponent";
import Message from "./common/Message";
import { Link, useNavigate } from "react-router-dom/dist";
import log from "loglevel";

export default function NewPassword({
  email = "",
  setCodeVerified,
  authCode = "",
}) {
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState({ messageText: "", messageType: "" });
  const router = useNavigate();
  function onChange(e) {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    if (e.target.name === "confirmPassword") {
      if (passwords.newPassword !== e.target.value) {
        setMessage({
          messageText: "confirm Password not matching with password!",
          messageType: "failure",
        });
      } else {
        setMessage({
          messageText: "both passwords  matching",
          messageType: "success",
        });
      }
    }
  }
  async function forgotPasswordSubmit() {
    try {
      await Auth.forgotPasswordSubmit(email, authCode, passwords.newPassword);
      setMessage({
        messageText: "Password reset Successfully !",
        messageType: "success",
      });
      setTimeout(async () => {
        // await login(email, passwords.newPassword);
        router.push("/home");
      }, 1000);
    } catch (err) {
      setMessage({
        messageText: err.message,
        messageType: "failure",
      });
      setTimeout(() => setCodeVerified(false), 1000);
      log.debug(err, err.message);
    }
  }
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col">
        <div className="flex h-full flex-col place-items-center md:overflow-y-hidden">
          <div className="flex w-full flex-row">
            <button
              className="float-left w-max basis-2/6"
              onClick={() => {
                // setUiState("welcome");
              }}
            >
              <ArrowNarrowLeftIcon className="h-8 w-auto" />
            </button>
            <div className="ml-9 basis-4/6 ">
              <LogoName />
            </div>
          </div>
          <p className=" invisible mt-2 text-sm text-gray-700 md:visible">
            Don’t have an account?{" "}
            <Link
              href="/register"
              className="font-medium text-delightmenuColor4 hover:underline"
            >
              Sign up
            </Link>{" "}
            for a free trial.
          </p>
        </div>
        <div className="float-left ml-2 mt-3 text-2xl font-bold">
          New password
        </div>
      </div>
      <div className="mt-14 flex grow flex-col gap-y-4">
        <TextField
          label="newPassword"
          id="newPassword"
          name="newPassword"
          type="password"
          required
          className="h-14 md:h-10"
          onChange={onChange}
        />

        <TextField
          className="col-span-full h-14 md:h-10"
          label="confirmPassword"
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          required
          onChange={onChange}
        />
        {message.messageText ? (
          <div className="text-center">
            <Message message={message} setMessage={setMessage} />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="order-last mt-4">
        {/* <Button type="submit" variant="solid" color="blue" className="w-full">
          <span>
            Sign in <span aria-hidden="true">&rarr;</span>
          </span>
        </Button> */}
        <ButtonComponent
          disabled={passwords.newPassword !== passwords.confirmPassword}
          name="Sign In"
          action={forgotPasswordSubmit}
        />
      </div>
    </div>
  );
}
