const Theme = {
  BLUE: {
    theme: "blue",
    primary: "blue-500",
    background: "bg-blue-800",
    foreground: "bg-white-300",
    card: "bg-blue-300",
    cardForeground: "bg-white-300",
    danger: "red",
    success: "green",
    border: "border border-blue-200 rounded-t-lg",
    buttonBackground: "hsl(216, 62%, 58%)",
    textBase: "text-base",
    textLarge: "text-lg",
    textMedium: "text-medium",
    textColor: "text-white",
    input: "black",
    buttonForeground: "bg-white-600",
    ring: "blue-500",
    popoverBackground: "bg-blue-300",
    popoverText: "text-white-300",
    popoverBorder: "border-blue-500",
    bgCommon: "bg-blue-600",
    bgLight: "bg-blue-100",
    radius: " 0.5rem",
    black: "black-500",
    white: "white",
    textCommon: "text-blue-800",
    textLight: "text-blue-600",
    dbcard: "bg-gradient-to-r from-blue-900 to-blue-500",
    cardRound: "rounded-lg",
    activeText: "active:text-white",
    hoverText: "hover:text-white",
    activebg: "active:bg-blue-800",
    borderBg: "hover:border-blue-800",
    hoverbg: "hover:bg-blue-500",
    mainBackground: "bg-slate-300",
    activeTab:
      "ring-offset-bg-blue-800 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-blue-800 data-[state=active]:text-white data-[state=active]:shadow",
    progressBar: "bg-blue-800 w-[40%] m-2",
    default: "bg-blue-800 border border-blue-200 rounded-t-lg text-white",
    destructive: "bg-red-600 text-white",
  },
  RED: {
    theme: "red",
    primary: "red-500",
    background: "bg-red-800",
    foreground: "text-white",
    danger: "red",
    success: "green",
    border: "border border-red-200 rounded-t-lg",
    buttonBackground: "bg-red-500",
    textBase: "text-base",
    textLarge: "text-lg",
    textMedium: "text-medium",
    textColor: "text-white",
    card: "bg-red-300",
    cardForeground: "bg-white-300",
    input: "black",
    buttonForeground: "bg-white-600",
    ring: "red-500",
    popoverBackground: "bg-red-300",
    popoverText: "text-white-300",
    popoverBorder: "border-red-500",
    bgCommon: "bg-red-600",
    bgLight: "bg-red-100",
    radius: " 0.5rem",
    black: "black-500",
    white: "white",
    textCommon: "text-red-800",
    dbcard: "bg-gradient-to-r from-red-900 to-red-500",
    cardRound: "rounded-lg",
    activeText: "active:text-white",
    hoverText: "hover:text-white",
    activebg: "active:bg-red-800",
    borderBg: "hover:border-red-800",
    hoverbg: "hover:bg-red-500",
    mainBackground: "bg-slate-300",
    activeTab:
      "ring-offset-bg-red-800 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-red-800 data-[state=active]:text-white data-[state=active]:shadow",
    progressBar: "bg-blue-800 w-[40%] m-2",
    default: "bg-red-800 border border-red-200 rounded-t-lg text-white",
    destructive: "bg-red-600 text-white",
  },
  DARK: {
    theme: "dark",
    primary: "slate-600",
    background: "bg-slate-800",
    foreground: "white",
    danger: "red",
    success: "green",
    border: "border border-slate-200 rounded-t-lg",
    buttonBackground: "bg-black-500",
    textBase: "text-base",
    textLarge: "text-lg",
    textMedium: "text-medium",
    textColor: "text-white",
    card: "bg-slate-300",
    cardForeground: "bg-white-300",
    input: "black",
    buttonForeground: "bg-white-600",
    ring: "black-500",
    popoverBackground: "bg-slat-300",
    popoverText: "text-white-300",
    popoverBorder: "border-black-500",
    bgCommon: "bg-slate-600",
    bgLight: "bg-slate-100",
    radius: " 0.5rem",
    black: "black-500",
    white: "white",
    textCommon: "text-slate-900",
    dbcard: "bg-gradient-to-r from-slate-900 to-slate-500",
    cardRound: "rounded-lg",
    activeText: "active:text-white",
    hoverText: "hover:text-white",
    activebg: "active:bg-slate-800",
    borderBg: "hover:border-slate-800",
    hoverbg: "hover:bg-slate-500",
    mainBackground: "bg-slate-300",
    activeTab:
      "ring-offset-bg-slate-800 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-slate-800 data-[state=active]:text-white data-[state=active]:shadow",
    progressBar: "bg-blue-800 w-[40%] m-2",
    default: "bg-slate-800 border border-blue-200 rounded-t-lg text-white",
    destructive: "bg-red-600 text-white",
  },
};

export function ThemeConstant(theme) {
  return Theme[theme];
}
