import { CONST_UPDATE_CONTEXT_ACTIONS } from "../../constants/dbconstants";
import {
  hookAddReimbursement,
  hookUpdateReimbursement,
  hookDeleteReimbursementByReimbursementId,
  hookListReimbursementsByUserId,
} from "../../hooks/reimbursementHooks";
import log from "loglevel";

export const getReimbursementDataByEmployeeIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID } = context;
    log.debug("calling:hookListReimbursementByUserId", userID);

    const reimbursements = await hookListReimbursementsByUserId(userID);
    //const reimbursements = await hookListReimbursements(userID);
    log.debug(
      "reimbursements:getReimbursementDataByEmployeeId",
      reimbursements
    );
    if (reimbursements) resolve({ reimbursements });
    reject("Failed To Fetch Tables");
  });
//return Promise.resolve();

export const submitReimbursementDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { reimbursementsToAdd, userID, organizationID } = context;
    reimbursementsToAdd.userID = userID;
    log.debug(
      "reimbursementsToAdd:submitReimbursementData",
      reimbursementsToAdd,
      userID
    );
    const reimbursement = await hookAddReimbursement({
      ...reimbursementsToAdd,
      organizationID,
    });
    if (reimbursement) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
          contextField: "reimbursements",
          value: reimbursement,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully added reimbursement",
        },
      ]);
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To add reimbursement",
      },
    ]);
  });
export const updateReimbursementDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { reimbursementsToAdd } = context;
    const res = await hookUpdateReimbursement({
      ...reimbursementsToAdd,
    });
    if (res)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "reimbursements",
          value: res,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully updated reimbursement",
        },
      ]);
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update reimbursement",
      },
    ]);
  });
export const deleteReimbursementDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedReimbursement } = context;
    log.debug("deleteReimbursementData====>", selectedReimbursement?.id);
    const reimbursementDeleted = await hookDeleteReimbursementByReimbursementId(
      selectedReimbursement?.id
    );
    log.debug("reimbursementDeleted====>", reimbursementDeleted);
    if (reimbursementDeleted)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.removeArr, // what to do
          contextField: "reimbursements",
          value: reimbursementDeleted,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully deleted reimbursement",
        },
      ]);
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To delete reimbursement",
      },
    ]);
  });
