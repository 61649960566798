/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_mobile_analytics_app_id": "3355cf7d2ee44093b7ed390fd5d73f9e",
    "aws_mobile_analytics_app_region": "ap-south-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "3355cf7d2ee44093b7ed390fd5d73f9e",
            "region": "ap-south-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://je6fi74wgzacfmmlv3erbpagsu.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gxotkxezbnhhfbh5yi3jaxmf6y",
    "aws_cognito_identity_pool_id": "ap-south-1:05dfaaee-a6b7-4d8c-8afc-42a17a1bb2d0",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_i7WnhwpER",
    "aws_user_pools_web_client_id": "588fretbv4b8jd61cujfldi02h",
    "oauth": {
        "domain": "dtplatformdeploy6eb8f2e5-6eb8f2e5-dev.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.delight360.com/",
        "redirectSignOut": "https://www.delight360.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dtportaldeploys3storage92351-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
