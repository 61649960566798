import React, { useEffect, useState, useMemo } from "react";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import {
  useEmployeeContextData,
  useEmployeeContextService,
  useEmployeeContextState,
} from "../../../../hooks/employeeMachineHooks";
import log from "loglevel";
import { useSelector } from "@xstate/react";
import { CheckCircle2, Clock, Edit2, Plus, XCircle } from "lucide-react";
import { Button } from "../../../CustomComponentsLibrary/Button";
import AddLogHoursPopup from "./AddLogHoursPopup";
import Dropdown from "../../../common/Dropdown";
import { useToast } from "../../../CustomComponentsLibrary/UseToast";
import PopoverCard from "../../../common/PopoverCard";
import { useParams } from "react-router-dom";

import ApproveRejectLogHour from "./ApproveRejectLogHour";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
import { CONST_LEAVE_STATUS } from "../../../../constants/dbconstants";
import { sortByKeyAlphabetical } from "../../../common/SortByName";
import AddEditLoghoursPopup from "./AddEditLoghoursPopup";

export default function AddEditByProjectLogHours({
  week,
  userToShow,
  selectedProject,
}) {
  const employeeMachineService = useEmployeeContextService();
  const { employeeWeeklyTaskHours, projects, projectTasks, myEmployeeProfile } =
    useEmployeeContextData();
  const { taskHoursCrud } = useEmployeeContextState();
  const { theme } = useAuthContextData();
  const param = useParams();
  const [Open, setOpen] = useState(false);
  const [approve, setApprove] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [logHourData, setLogHourData] = useState([]);
  const { toast } = useToast();
  const isLogHourLoading = useSelector(employeeMachineService, (state) =>
    state.matches("EMPLOYEE_LOG_HOURS.EMPLOYEE_LOG_HOURS_LOADED")
  );
  log.debug("employeeWeeklyTaskHours::", employeeWeeklyTaskHours);
  useEffect(() => {
    if (selectedProject) {
      employeeMachineService.send({
        type: "get_project_id",
        data: {
          selectedProjectId: selectedProject?.id,
        },
      });
    }
    setSelectedTask(null);
  }, [selectedProject]);

  useEffect(() => {
    log.debug("Tasks Selected", selectedTask?.id);
    if (selectedTask) {
      const hasLogHours = employeeWeeklyTaskHours?.some(
        (log) => log.taskID === selectedTask?.id
      );

      if (hasLogHours) {
        toast({
          variant: "destructive",
          title: `Selected Task: ${selectedTask?.title} `,
          description:
            "Log hours are already added for this task,You can edit or add log hours from the table",
        });

        const timeoutId = setTimeout(() => {
          setSelectedTask(null);
        }, 5000);

        return () => clearTimeout(timeoutId);
      } else {
        employeeMachineService.send({
          type: "update_rows_task_selected_id",
          data: {
            selectedTaskId: selectedTask?.id,
          },
        });
        setSelectedTask(null);
      }
    }
  }, [selectedTask?.id]);
  useEffect(() => {
    if (userToShow === null) {
      setSelectedUser(myEmployeeProfile);
    } else {
      setSelectedUser(userToShow);
    }
  }, [userToShow]);
  const getDateForWeekday = (weekStartDate, dayName) => {
    const startDate = new Date(weekStartDate);
    log.debug("startDate::getDateForWeekday", myEmployeeProfile);
    const dayIndices = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6,
    };

    const dayIndex = dayIndices[dayName];

    if (dayIndex === undefined) {
      return "Invalid Day";
    }

    const targetDate = new Date(startDate);
    targetDate.setDate(startDate.getDate() + dayIndex);

    const formattedDate = `${String(targetDate.getDate()).padStart(
      2,
      "0"
    )}/${String(targetDate.getMonth() + 1).padStart(2, "0")}/${String(
      targetDate.getFullYear()
    ).slice(0)}`;
    log.debug("formattedDate", formattedDate);
    return `${formattedDate}`;
  };
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    employeeMachineService.send({ type: "cancel_crud" });
  };
  function refresh() {
    log.debug("getting called");
    setRefetch(!refetch);
  }
  const closeApproveDialog = () => {
    setApprove(false);
  };

  const handleTaskHours = (date, actionToSend, selectedTask) => {
    log.debug("{taskHours[1]?.date}", date, selectedTask, actionToSend);

    employeeMachineService.send({
      type: "task_hours_crud",
      data: {
        selectedTask: selectedTask,
        selectedTaskId: selectedTask?.taskID,
        logHourDate: date,
        actionForTaskHoursCURD: actionToSend,
      },
    });

    openDialog();
  };

  const approveAndRejectLogHours = (RowData, dayData) => {
    const logForDay = RowData?.filter((item) => item?.date === dayData?.date);
    setOpen(false);
    setApprove(true);
    employeeMachineService.send({
      type: "task_hours_crud",
      data: {
        selectedLogHourForApprove: logForDay,
      },
    });
  };

  const daysToShow = useMemo(() => {
    const firstItem = employeeWeeklyTaskHours[0];
    if (firstItem) {
      return Object.keys(firstItem)?.filter(
        (key) => key !== "description" && key !== "id" && key !== "isApproved"
      );
    }
    return [];
  }, [employeeWeeklyTaskHours]);
  function calculateOverallStatus(logHourData) {
    let hasNull = false;
    let hasRejected = false;
    let hasApproved = false;

    for (const logEntry of logHourData) {
      const { isApproved } = logEntry;

      if (isApproved === null) {
        hasNull = true;
      } else if (isApproved) {
        hasApproved = true;
      } else {
        hasRejected = true;
      }
    }

    if (hasNull) {
      return "Submitted";
    } else if (hasRejected && hasApproved) {
      return "No action required";
    } else if (hasRejected) {
      return "Rejected";
    } else {
      return "Approved";
    }
  }
  log.debug("userToShow::", userToShow);
  var takeActionColumn = [];
  if (myEmployeeProfile?.userType?.includes("TEAMLEAD")) {
    takeActionColumn = [
      {
        header: "Action",
        cell: ({ row }) => (
          <>
            {selectedUser?.cognitoId !== myEmployeeProfile?.cognitoId &&
            row?.original?.rowLogHour?.length ? (
              <>
                <Button
                  onClick={() => {
                    setApprove(true);
                    setOpen(false);
                    employeeMachineService.send({
                      type: "task_hours_crud",
                      data: {
                        selectedLogHourForApprove: row?.original?.rowLogHour,
                      },
                    });
                  }}
                >
                  Take Action
                </Button>
              </>
            ) : row?.original?.rowLogHour?.length ? (
              <div className="text-xxs">
                {calculateOverallStatus(row.original.rowLogHour)}
              </div>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ];
  }

  function checkLeaveStatus(day, leavesArray, hoildayArray) {
    if (day) {
      const leaveItem = leavesArray?.find((item) => item.date2 === day);
      if (leaveItem && leaveItem?.quantity === 1) {
        const status =
          leaveItem?.status === CONST_LEAVE_STATUS.leaveStatusApproved
            ? "Leave approved"
            : "Leave submitted";
        return {
          leaveStatus: "Leave present",
          status,
        };
      } else if (hoildayArray?.includes(day)) {
        return "Holiday";
      } else if (leaveItem && leaveItem?.quantity === 0.5) {
        const status =
          leaveItem?.status === CONST_LEAVE_STATUS.leaveStatusApproved
            ? "Half Day Leave approved"
            : "Half Day Leave submitted";
        return {
          leaveStatus: "Half Day Leave present",
          status,
        };
      } else {
        return "Log hours";
      }
    } else {
      return "Log hours";
    }
  }
  console.log("employeeWeeklyTaskHours:columns2", employeeWeeklyTaskHours);
  const columns2 = useMemo(() => {
    let weekDataForDay = [];
    let weekDate = [];
    function getNextSevenDaysWithDays(startDateStr) {
      const startDate = new Date(startDateStr);
      const weekDay = startDate?.getDate();
      let startDateDay = startDate.getDay();
      const num = weekDay == 1 ? (startDateDay >= 1 ? 8 - startDateDay : 1) : 7;
      for (let i = 0; i < num; i++) {
        const currentDate = new Date(startDate);
        currentDate?.setDate(startDate?.getDate() + i);

        const day = currentDate?.getDate()?.toString()?.padStart(2, "0");
        const month = (currentDate?.getMonth() + 1)
          ?.toString()
          ?.padStart(2, "0");
        const year = currentDate?.getFullYear();
        if (
          month == (startDate?.getMonth() + 1)?.toString()?.padStart(2, "0")
        ) {
          const dateString = `${day}/${month}/${year}`;

          weekDate?.push(dateString);
          const dayString = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ][currentDate.getDay()];

          weekDataForDay?.push(dayString);
        }
      }
      log.debug("weekDataForDay", weekDataForDay);
      return weekDataForDay;
    }
    getNextSevenDaysWithDays(week);
    log.debug("weekDataForDay:Outerpart", weekDataForDay);
    return [
      ...weekDataForDay
        .filter((day) =>
          employeeWeeklyTaskHours?.some((item) => item[day] !== undefined)
        )
        .map((day, index) => {
          return {
            header: (
              <>
                {day}
                <p className="flex flex-col">
                  {weekDate ? weekDate[index] : day}
                </p>
              </>
            ),
            accessorKey: day,
            cell: ({ row }) => {
              const checkLeavePresent = checkLeaveStatus(
                row?.original[day]?.date,
                row?.original?.leaveDate,
                row?.original?.orgHolidays
              );

              const rowLogHour = row?.original?.rowLogHour?.filter(
                (item) => item?.date === row?.original[day]?.date
              );

              log.debug("rowLogHour:for colour", rowLogHour);
              const notApproved = rowLogHour?.some(
                (item) => item?.isApproved === false
              );
              const Approved = rowLogHour?.some(
                (item) => item?.isApproved === true
              );
              const submitted = rowLogHour?.some(
                (item) => item?.isApproved === null
              );
              let className = "";
              let iconColor = "h-4 mb-3";
              let description = "Take action on log hour";

              if (row?.original[day]?.logHours > 0) {
                if (Approved && !notApproved) {
                  className = "bg-green-100 rounded-lg";
                  description = "Approved";
                  iconColor = "h-4 mb-3 text-green-700";
                } else if (!Approved && notApproved) {
                  className = "bg-red-100 rounded-lg";
                  description = "Rejected";
                  iconColor = "h-4 mb-3 text-red-700";
                } else if (!Approved && !notApproved) {
                  className = "bg-transparent rounded-lg";
                  description = "Submitted";
                  iconColor = "h-4 mb-3 text-red-700";
                } else {
                  className = "bg-transparent rounded-lg";
                  description = "Submitted";
                  iconColor = "h-4 mb-3 text-default-color";
                }
              }

              return (
                <div className="flex flex-col ">
                  <div className={`${className} order-1 `}>
                    {checkLeavePresent &&
                    checkLeavePresent?.leaveStatus === "Leave present" ? (
                      <div
                        className={`"flex justify-center text-xxs  pt-3  border rounded-lg w-24 flex-wrap p-2 font-thin"${theme?.textCommon}`}
                      >
                        <div className="mx-auto w-full ">
                          {checkLeavePresent?.status}
                        </div>
                      </div>
                    ) : checkLeavePresent &&
                      checkLeavePresent?.leaveStatus ===
                        "Half Day Leave present" ? (
                      <div>
                        <div className="flex justify-center border rounded-lg w-24 flex-wrap h-15 pt-1 text-sm font-thin">
                          <PopoverCard
                            description={
                              row?.original[day]?.logHours > 0
                                ? row?.original[day]?.description
                                : "No log hour added..."
                            }
                          >
                            <p
                              className={`${
                                row?.original[day]?.logHours > 0
                                  ? "text-slate-950"
                                  : "text-slate-400"
                              }`}
                            >
                              {row?.original[day]?.logHours}
                            </p>
                          </PopoverCard>
                          <p
                            className={`ml-2 ${
                              row?.original[day]?.logHours > 0
                                ? "text-slate-950"
                                : "text-slate-400"
                            }`}
                          >
                            |
                          </p>
                          <Button
                            variant="primary"
                            size="xsm"
                            className="pl-0 pr-0 h-6 mt-[3px]"
                            disabled={row?.original?.taskID === null}
                            onClick={() => {
                              if (row?.original[day]?.logHours > 0) {
                                handleTaskHours(
                                  row?.original[day]?.date,
                                  "EDIT",
                                  row?.original
                                );
                              } else {
                                handleTaskHours(
                                  row?.original[day]?.date,
                                  "ADD",
                                  row?.original
                                );
                              }
                            }}
                          >
                            {row?.original[day]?.logHours > 0 ? (
                              <Edit2 className="h-4 mb-3" />
                            ) : (
                              <Plus className="h-4 mb-3" />
                            )}
                          </Button>
                          <PopoverCard description={description}>
                            {(myEmployeeProfile?.userType?.includes(
                              "TEAMLEAD"
                            ) ||
                              myEmployeeProfile?.userType?.includes(
                                "MANAGER"
                              )) &&
                            selectedUser?.cognitoId !==
                              myEmployeeProfile?.cognitoId ? (
                              <Button
                                variant="primary"
                                size="sm"
                                className="pl-0 pr-0 h-6 mt-[3px]"
                                onClick={() => {
                                  log.debug(
                                    "ApproveEachDay",
                                    row?.original[day]
                                  );
                                  approveAndRejectLogHours(
                                    row.original?.rowLogHour,
                                    row?.original[day]
                                  );
                                }}
                              >
                                {row?.original[day]?.logHours > 0 && (
                                  <Clock className={iconColor} />
                                )}
                              </Button>
                            ) : description === "Approved" ? (
                              <>
                                <CheckCircle2 className="h-4 mb-3 text-green-700" />
                              </>
                            ) : description === "Rejected" ? (
                              <>
                                <XCircle className="h-4 mb-3 text-red-700" />
                              </>
                            ) : (
                              <></>
                            )}
                          </PopoverCard>
                          <div
                            className="text-xs m-0.3"
                            style={{
                              fontSize: "7.5px",
                              color: "black",
                              fontWeight: 700,
                            }}
                          >
                            {checkLeavePresent?.status}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center border rounded-lg w-24 flex-wrap pt-2 text-sm font-thin">
                        <PopoverCard
                          description={
                            row?.original[day]?.logHours > 0
                              ? row?.original[day]?.description
                              : "No log hour added..."
                          }
                        >
                          <p
                            className={`${
                              row?.original[day]?.logHours > 0
                                ? "text-slate-950"
                                : "text-slate-400"
                            }`}
                          >
                            {row?.original[day]?.logHours}
                          </p>
                        </PopoverCard>
                        <p
                          className={`ml-2 ${
                            row?.original[day]?.logHours > 0
                              ? "text-slate-950"
                              : "text-slate-400"
                          }`}
                        >
                          |
                        </p>
                        <Button
                          variant="primary"
                          size="sm"
                          className="pl-0 pr-0 h-6 mt-[3px]"
                          disabled={row?.original?.taskID === null}
                          onClick={() => {
                            if (row?.original[day]?.logHours > 0) {
                              handleTaskHours(
                                row?.original[day]?.date,
                                "EDIT",
                                row?.original
                              );
                            } else {
                              handleTaskHours(
                                row?.original[day]?.date,
                                "ADD",
                                row?.original
                              );
                            }
                          }}
                        >
                          {row?.original[day]?.logHours > 0 ? (
                            <Edit2 className="h-4 mb-3" />
                          ) : (
                            <Plus className="h-4 mb-3" />
                          )}
                        </Button>
                        <PopoverCard description={description}>
                          {myEmployeeProfile?.userType?.includes("TEAMLEAD") &&
                          selectedUser?.cognitoId !==
                            myEmployeeProfile?.cognitoId ? (
                            <Button
                              variant="primary"
                              size="sm"
                              className="pl-0 pr-0 h-6 mt-[3px]"
                              onClick={() => {
                                log.debug("ApproveEachDay", row?.original[day]);
                                approveAndRejectLogHours(
                                  row.original?.rowLogHour,
                                  row?.original[day]
                                );
                              }}
                            >
                              {row?.original[day]?.logHours > 0 && (
                                <Clock className={iconColor} />
                              )}
                            </Button>
                          ) : description === "Approved" ? (
                            <>
                              <CheckCircle2 className="h-4 mb-3 text-green-700" />
                            </>
                          ) : description === "Rejected" ? (
                            <>
                              <XCircle className="h-4 mb-3 text-red-700" />
                            </>
                          ) : (
                            <></>
                          )}
                        </PopoverCard>
                      </div>
                    )}
                  </div>
                  {checkLeavePresent && checkLeavePresent === "Holiday" && (
                    <div className="text-xxs order-2 -mb-2">
                      {checkLeavePresent}
                    </div>
                  )}
                </div>
              );
            },
          };
        }),
      {
        header: "Total Hours",
        cell: ({ row }) => {
          const rowLogHours = row?.original?.rowLogHour;

          if (rowLogHours && Array.isArray(rowLogHours)) {
            const finalTotalHours = rowLogHours?.reduce(
              (accumulator, logHour) => {
                return accumulator + logHour?.totalHours;
              },
              0
            );

            return finalTotalHours;
          } else {
            return 0;
          }
        },
      },
    ];
  }, [daysToShow, week, employeeWeeklyTaskHours]);
  const columns = [
    {
      header: "Project Name",
      accessorKey: "projectTitle",
      cell: ({ row }) => (
        <>
          {selectedProject
            ? selectedProject?.title
            : row?.original?.projectTitle
            ? row?.original?.projectTitle
            : "Select Project first"}
        </>
      ),
    },

    {
      header: "Task Name",
      accessorKey: "taskTitle",
      cell: ({ row }) => (
        <>
          {row?.original?.taskID ? (
            <>{row?.original?.taskTitle}</>
          ) : (
            <>
              <Dropdown
                selected={selectedTask}
                setSelected={setSelectedTask}
                list={sortByKeyAlphabetical(projectTasks, "title")}
                nameField="title"
                placeholder="Select Task."
                custom="w-40"
              />
            </>
          )}
        </>
      ),
    },
    {
      header: "Budgeted Hours",
      accessorKey: "billableHours",
    },
    {
      header: "Logged Hours",
      accessorKey: "LoggedHours",
    },
    {
      header: "Billed Unbillable Hours",
      accessorKey: "billedUnbillableHours",
      cell: ({ row }) => (
        <>
          {row?.original?.billedUnbillableHours ? (
            <>{row?.original?.billedUnbillableHours?.toFixed(2)}</>
          ) : (
            <>0</>
          )}
        </>
      ),
    },

    ...columns2,
    ...takeActionColumn,
  ];
  const getDayNameFromDate = (dateString) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };

  return (
    <div className="">
      <DataTable
        className="pb-40"
        columns={columns}
        data={employeeWeeklyTaskHours}
        dataTableToolBar={false}
        loader={isLogHourLoading}
        customCss={`${theme?.border}`}
        customWidth={[
          {
            index: 0,
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 1,
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 2,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 3,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 4,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 5,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 6,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 7,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 8,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 9,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 10,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 11,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 12,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 13,
            widthClass: "w-28",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
        ]}
      />

      <>
        {taskHoursCrud ? (
          <AddEditLoghoursPopup
            isOpen={Open}
            onClose={closeDialog}
            refreshTask={refresh}
            selectedUser={selectedUser}
          />
        ) : null}
      </>
      <>
        {approve ? (
          <ApproveRejectLogHour
            approve={approve}
            setApprove={setApprove}
            onClose={closeApproveDialog}
          />
        ) : null}
      </>
    </div>
  );
}
