import { Listbox } from "@headlessui/react";
import React, { useEffect, useState, useRef } from "react";
import { ScrollArea } from "../CustomComponentsLibrary/ScrollArea";
import log from "loglevel";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
import { sortByKeyAlphabetical } from "./SortByName";

const MultiselectorDropdown = (props) => {
  const {
    list,
    setSelected,
    selected,
    nameField = "name",
    nameField2,
    placeholder,
    defaultSelectedIndex,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const { theme } = useAuthContextData();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!list || list?.length === 0) {
      setSelected([]);
      return;
    }
    if (defaultSelectedIndex && list?.length > 0) {
      setSelected([list[0]]);
    }

    const handleClickOutside = (event) => {
      if (
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [list, defaultSelectedIndex, setSelected]);

  const removeSelected = (data) => {
    setSelected(selected?.filter((item) => item?.id !== data?.id));
    setOpen(true);
  };

  const handleDropdownClick = () => {
    setOpen(!open);
  };

  const isSelected = (data) => {
    return selected?.some((item) => item?.id === data?.id);
  };
  return (
    <>
      <Listbox as="div" value={selected} onChange={setSelected} multiple>
        {() => (
          <>
            <div className="relative mt-2" ref={dropdownRef}>
              <span className="inline-flex w-full rounded-md shadow-sm">
                <Listbox.Button
                  onClick={handleDropdownClick}
                  className="cursor-pointer relative w-full  rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left text-base focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {selected?.length === 0 && (
                    <div className="inline-flex items-center px-1 mr-1 mt-1 text-base font-light rounded text-text bg-background">
                      {placeholder}
                    </div>
                  )}

                  {sortByKeyAlphabetical(selected, "title")?.map((item) => (
                    <div
                      key={item}
                      className="inline-flex items-center px-1 mr-1 mt-1 text-1xl font-light rounded text-text bg-background"
                    >
                      {nameField2 ? item[nameField] : item[nameField]}
                      <div
                        className="ml-1 bg-indigo-100 rounded-full cursor-pointer"
                        onClick={() => removeSelected(item)}
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                            fill="#4A5568"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
              </span>

              {open && (
                <Listbox.Options
                  static
                  className="absolute mt-1 w-64 rounded-md bg-zinc-100 z-20 shadow-lg"
                >
                  <ScrollArea className="h-64 px-2">
                    {sortByKeyAlphabetical(list, "title")?.map(
                      (person, personIdx) => (
                        <Listbox.Option key={personIdx} value={person}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? `"text-text bg-background rounded-lg" ${theme?.hoverbg}`
                                  : "text-gray-900"
                              }  select-none cursor-pointer  relative py-2 pl-8 pr-4`}
                            >
                              <span className="block truncate px-1 mr-1 mt-1 text-1xl">
                                {nameField2
                                  ? person?.[nameField] + person?.[nameField2]
                                  : person?.[nameField]}
                              </span>
                              {isSelected(person) && (
                                <span
                                  className={`${
                                    active ? "text-black" : "text-common "
                                  } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                >
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      )
                    )}
                  </ScrollArea>
                </Listbox.Options>
              )}
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};

export default MultiselectorDropdown;
