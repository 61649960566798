import { assign } from "xstate";
import { CONST_UPDATE_CONTEXT_ACTIONS } from "../../constants/dbconstants";
import log from "loglevel";
export const assignContext = assign((context, event) => {
  log.debug("ASSIGNING GOAL TO CONTEXt AS FIELDS", event.data);
  if (event?.data) {
    let newObjectToUpdateContext = event.data;
    let contextKeys = Object.keys(context);
    log.debug("CONTEXT KEYS ", contextKeys);
    newObjectToUpdateContext = Object.fromEntries(
      contextKeys?.map((key) => [key, newObjectToUpdateContext[key]])
    );
    Object.keys(newObjectToUpdateContext).forEach(
      (key) =>
        newObjectToUpdateContext[key] === undefined &&
        delete newObjectToUpdateContext[key]
    );
    log.debug("Assigning CONTEXT", newObjectToUpdateContext);
    return { ...newObjectToUpdateContext };
  } else {
    return {};
  }
});
// To use below action
/*
Resolve array of objects of below blueprint
  { 
    action, // what to do 
    contextField,
    value,
    uniqueIdField = "id"
  } 
*/
export const assignUpdateContext = assign((context, event) => {
  log.debug(
    "event with array of objects {action:'updateArr', contextField:'contextKey', value:''",
    event.data
  );
  const actionsArr = event.data;
  const ret = Object.fromEntries(
    actionsArr?.map((actionObj) => {
      const { action, contextField, value, uniqueIdField = "id" } = actionObj;
      const oldContextFieldValue = context[contextField];
      let newContextFieldValue = null;

      switch (action) {
        case CONST_UPDATE_CONTEXT_ACTIONS.update:
          newContextFieldValue = value;
          break;
        case CONST_UPDATE_CONTEXT_ACTIONS.updateArr:
          newContextFieldValue = oldContextFieldValue?.map((item) =>
            item[uniqueIdField] === value[uniqueIdField] ? value : item
          );
          break;
        case CONST_UPDATE_CONTEXT_ACTIONS.removeArr:
          newContextFieldValue = oldContextFieldValue.filter(
            (item) => item[uniqueIdField] !== value[uniqueIdField]
          );
          break;
        case CONST_UPDATE_CONTEXT_ACTIONS.addArr:
          newContextFieldValue = [...oldContextFieldValue, value];
          break;
        case CONST_UPDATE_CONTEXT_ACTIONS.updateArrMultiple:
          log.debug("updateArrMultiple::", value, oldContextFieldValue);
          const newArray = [...value];
          log.debug("newArray::", newArray);
          const updatedContextFieldValue = oldContextFieldValue?.map(
            (oldItem) => {
              const newItem = newArray?.find(
                (newItem) => oldItem?.id === newItem?.value?.id
              );
              if (newItem) {
                log.debug("newItem", newItem);
                return { ...oldItem, isApproved: newItem?.value?.isApproved };
              }
              return oldItem;
            }
          );

          newContextFieldValue = [...updatedContextFieldValue];

          break;
      }
      if (newContextFieldValue !== null) {
        return [contextField, newContextFieldValue];
      }
      return {};
    })
  );
  log.debug("returning to assign function", ret);
  return ret;
});

export const assignTeamMemberIdToContext = assign({
  selectedTeamMemberId: (context, event) => event.data,
  tasks: [],
  goals: [],
});
