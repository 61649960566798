import moment from "moment";
import {
  CONST_COFF_STATUS,
  CONST_UPDATE_CONTEXT_ACTIONS,
} from "../../constants/dbconstants";
import {
  hookListCoffsByUserId,
  hookListSubmittedCoffs,
  hookUpdateCoff,
  hookAddCoff,
  hookDeleteCoffsByCoffId,
  hookListCoffsByUserIdAndYear,
} from "../../hooks/hookCoffs";
import {
  hookGetEmployeeLeavesOverviewById
} from "../../hooks/leaveHooks"
import log from "loglevel";
import {
  hookGetEmployeeLeavesDataById,
  hookUpdateEmployee,
} from "../../hooks/employeeHooks";

export const getCoffDataByEmployeeIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID, selectedYear } = context;
    log.debug("getCoffDataByEmployeeIdService", userID);
    var currentDate = new Date();
    log.debug(selectedYear, currentDate);
    // const currentYear = new Date().getFullYear();
    // const currentYearString = currentYear.toString();
    let theFirst = null;
    let theLast = null;
    let firstDay = null;
    let lastDay = null;
    if (selectedYear) {
      firstDay = selectedYear?.firstday;
      lastDay = selectedYear?.lastDay;
    } else {
      theFirst = new Date(currentDate.getFullYear(), 0, 1);
      theLast = new Date(currentDate.getFullYear(), 11, 31);
      firstDay = moment(theFirst)?.format("YYYY-MM-DD");
      lastDay = moment(theLast)?.format("YYYY-MM-DD");
    }

    const coffs = await hookListCoffsByUserIdAndYear(
      userID,
      firstDay,
      lastDay
    );
    const leavesOverview = await hookGetEmployeeLeavesOverviewById(userID);
    if(leavesOverview)
    {
      for (const key in leavesOverview)
      {
        if (leavesOverview[key] === null) 
        {
          leavesOverview[key] = 0;
        }
      }
    }
    log.debug("coff:leavesOverview", leavesOverview);
    log.debug("coff:hookListCoffsByUserId", coffs);
    if (coffs) resolve({ coffs, leavesOverview });
    reject("Failed To Fetch coffs Tables");
  });

export const updateCoffDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { coffsToAdd } = context;

    const res = await hookUpdateCoff({
      ...coffsToAdd,
    });
    if (res)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "coffs",
          value: res,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully updated coff",
        },
      ]);
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update coff",
      },
    ]);
  });

export const submitCoffDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { coffsToAdd, organizationID } = context;
    log.debug("coffsToAdd:submitCoffData", coffsToAdd);
    const coff = await hookAddCoff({ ...coffsToAdd, organizationID });
    if (coff) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
          contextField: "coffs",
          value: coff,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully added coff",
        },
      ]);
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To add coff",
      },
    ]);
  });

export const submitMultipleCoffDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { coffsToAdd, organizationID } = context;
    log.debug("submitMultipleCoffDataService:coffsToAdd", coffsToAdd);
    const userID = coffsToAdd[0].appliedBy;
    const coffsOfSelectedPerson = await hookListCoffsByUserId(userID);

    const addMultiCoff = async (item) => {
      const coffData = {
        ...item,
      };
      const coff = await hookAddCoff({ ...coffData, organizationID });

      return coff;
    };

    const promises = coffsToAdd?.map((item) => addMultiCoff(item));
    try {
      const results = await Promise.all(promises);
      log.debug("coffsToAdd:submitCoffData", results);
      if (results) {
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "coffs",
            value: [...coffsOfSelectedPerson, ...results],
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully added coff",
          },
        ]);
      } else {
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Failed To add coff",
          },
        ]);
      }
    } catch (error) {
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To add coff",
        },
      ]);
    }
  });

export const deleteCoffDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedCoffId } = context;
    log.debug("deleteCoffData====>", selectedCoffId);
    const coffDeleted = await hookDeleteCoffsByCoffId(selectedCoffId);

    log.debug("coffDeleted====>", coffDeleted);
    if (coffDeleted) resolve(true);
    reject("Failed To Delete coff");
  });

export const actionOnMultipleCoffDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const {
      selectedCoffsForAction,
      actionOnCoff,
      myEmployeeProfile,
      userID,
    } = context;
    log.debug(
      "selectedCoffsForAction",
      selectedCoffsForAction,
      actionOnCoff,
      myEmployeeProfile
    );

    const updateCoff = async (coff) => {
      var updateCoffData = {};
      if (actionOnCoff == "Approve") {
        updateCoffData = {
          id: coff?.id,
          approvedBy: myEmployeeProfile?.cognitoId,
          status: CONST_COFF_STATUS?.coffApproved,
        };
      } else if (actionOnCoff == "Reject") {
        updateCoffData = {
          id: coff?.id,
          approvedBy: myEmployeeProfile?.cognitoId,
          status: CONST_COFF_STATUS.coffRejected,
        };
      }

      try {
        const updatedCoff = await hookUpdateCoff(updateCoffData);
        console.log("actionOnMultipleCoffDataService", updatedCoff);
        return updatedCoff;
      } catch (error) {
        console.log("actionOnMultipleCoffDataService", error);
        return {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To action coff",
        };
      }
    };
    try {
      const Promises = selectedCoffsForAction?.map((item) =>
        updateCoff(item)
      );

      const results = await Promise.all(Promises);
 
      if (actionOnCoff == "Approve") 
      {
        log.debug(
          "Action for c-off is approve modifying the leave balance of employee",
          actionOnCoff,
        );
        let employeeCoffData = await hookGetEmployeeLeavesDataById(userID);

        if(isNaN(employeeCoffData?.noOfCOffLeaves) || (employeeCoffData?.noOfCOffLeaves == null))
        {
          employeeCoffData.noOfCOffLeaves = 0
        }

        if(isNaN(employeeCoffData?.noOfCOffLeavesTaken) || (employeeCoffData?.noOfCOffLeavesTaken == null))
        {
          employeeCoffData.noOfCOffLeavesTaken = 0
        }

        if(isNaN(employeeCoffData?.noOfCOffLeavesRemaining) || (employeeCoffData?.noOfCOffLeavesRemaining == null))
        {
          employeeCoffData.noOfCOffLeavesRemaining = 0
        }

        results?.forEach((coff) => {
          if(coff?.status === CONST_COFF_STATUS.coffApproved)
          {
            employeeCoffData.noOfCOffLeaves += coff.quantity;
          }
        });

        employeeCoffData.noOfCOffLeaves = employeeCoffData.noOfCOffLeaves;
        employeeCoffData.noOfCOffLeavesRemaining = employeeCoffData.noOfCOffLeaves - employeeCoffData.noOfCOffLeavesTaken;
        
        const emp = {
          noOfCOffLeaves: employeeCoffData.noOfCOffLeaves,
          noOfCOffLeavesRemaining: employeeCoffData.noOfCOffLeavesRemaining,
          cognitoId: userID,
        };

        log.debug(
          "Modified leave balance of employee",
          actionOnCoff,
        );

        const updatedEmployee = await hookUpdateEmployee(emp);

        log.debug("update coffs now to: employee ", updatedEmployee);

        if (results?.length > 0)
          resolve([
            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr,
              contextField: "coffs",
              value: results,
            },
            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.update,
              contextField: "leavesOverview",
              value: employeeCoffData,
            },
            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.update,
              contextField: "message",
              value: "Successfully updated coff status",
            },
          ]);
      }

      if (results?.length > 0)
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr,
            contextField: "coffs",
            value: results,
          },

          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully updated coff status",
          },
        ]);
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed upadate coff status",
        },
      ]);
    } catch (error) {
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed upadate coff status",
        },
      ]);
    }
  });
