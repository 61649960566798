import React, { useEffect, useMemo, useState } from "react";

import {
  useAuthService,
  useAuthContextState,
  useAuthContextData,
} from "../../customHooks/authMachineHooks";
import WelComeComponent from "../auth/WelcomeComponent";
import LoginComponent from "../auth/LoginComponent";
import SignUpComponent from "../SignUpComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useActor } from "@xstate/react/lib";
import AddFirstEmployee from "../../pages/common/AddFirstEmployee";
import getStripe from "../../lib/getStripe";
import { CONST_USER_TYPES } from "../../constants/dbconstants";
import { hookGetOrganizationByUniqueName } from "../../hooks/organizationHooks";
import WelcomeComponentOrg from "../auth/WelcomeComponentOrg";
import AuthCommonLayout from "../auth/AuthCommonLayout";
import log from "loglevel";

export default function Authenticate() {
  const navigate = useNavigate();
  const { userProfile } = useAuthContextData();
  const authService = useAuthService();
  const [state, send] = useActor(authService);
  const [loading, setLoading] = useState(true);
  // get access to the login function
  const { isWelcomeState, isSignInState, isSignUpState } =
    useAuthContextState();
  const [orgDetail, setOrgDetail] = useState(null);
  const { orgDomainName } = useParams();
  const noOrgFound = useMemo(() => {
    if (!loading) {
      if (orgDetail === null) {
        return true;
      }
    }
    return false;
  }, [loading, orgDetail]);
  useEffect(() => {
    async function fetchOrgDetails() {
      setLoading(true);
      try {
        const orgDetails = await hookGetOrganizationByUniqueName(orgDomainName);
        setOrgDetail(orgDetails);
      } catch (err) {
        log.debug("err", err);
      } finally {
        setLoading(false);
      }
    }
    if (orgDomainName) {
      fetchOrgDetails();
    }
  }, [orgDomainName]);

  log.debug("noOrgFound", noOrgFound);
  useEffect(() => {
    let subscription = authService.subscribe(async (state) => {
      log.debug("STATE CHANGED++++++++++=>", state);
      if (state.matches("LOGGED_IN.USER_PROFILE_PRESENT.APP_READY_TO_START")) {
        navigate("/candidate");
      }
      if (state.matches({ LOGGED_IN: "CANDIDATE_APPLYING_FOR_INVALID_ORG" })) {
        const orgDetails = await hookGetOrganizationByUniqueName(orgDomainName);
        send({
          type: "candidateAssignOrgToContext",
          data: {
            organization: orgDetails,
            organizationID: orgDetails?.id,
          },
        });
      }
    });

    return () => {
      if (subscription) {
        log.debug("Returnining Unsubscribe: ");
        subscription.unsubscribe();
      }
    };
  }, [userProfile]);
  if (loading) {
    return <>Loading...</>;
  }
  if (isWelcomeState) {
    if (orgDetail)
      return (
        <>
          <AuthCommonLayout>
            <WelcomeComponentOrg orgDetail={orgDetail} />
          </AuthCommonLayout>
        </>
      );
    else {
      if (noOrgFound) {
        return (
          <div className="flex flex-col justify-center items-center h-screen align-middle">
            <div className="text-3xl font-bold text-blue-400">
              ORGANIZATION NOT FOUND
            </div>
            <span className="font-semibold">
              Please Check URL or Contact Recruiter
            </span>
          </div>
        );
      } else {
        return <>Loading..</>;
      }
    }
  } else if (isSignInState) {
    return (
      <>
        <AuthCommonLayout>
          <LoginComponent />
        </AuthCommonLayout>
      </>
    );
  } else if (isSignUpState) {
    return (
      <>
        <AuthCommonLayout>
          <SignUpComponent />
        </AuthCommonLayout>
      </>
    );
  }
  if (
    state.matches({
      LOGGED_IN: "CANDIDATE_APPLYING_FOR_INVALID_ORG",
    }) ||
    noOrgFound
  ) {
    return (
      <div className="flex flex-col justify-center items-center h-screen align-middle">
        <div className="text-3xl font-bold text-blue-400">
          ORGANIZATION NOT FOUND
        </div>
        <span className="font-semibold">
          Please Check URL or Contact Recruiter
        </span>
      </div>
    );
  }
  if (
    state.matches({
      LOGGED_IN: {
        USER_PROFILE_ABSENT: "WAIT_FOR_USER_PROFILE_CREATE_EVENT",
      },
    })
  ) {
    return (
      <>
        <div className="flex flex-col min-h-full items-center justify-center ">
          <div className="flex-row text-xl font-semibold">
            Profile Creation confirmation:
          </div>
          <div className="flex-row">
            {" "}
            <button
              variant="primary"
              size="lg"
              className="hover:cursor-pointer text-lg	 p-2 m-2"
              onClick={() => {
                send({
                  type: "onCreateUserProfileEvent",
                  data: { userTypeToBeAdded: CONST_USER_TYPES.candidate },
                });
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </>
    );
  }
}
