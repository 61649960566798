import React, { useEffect } from "react";
import log from "loglevel";

import "./index.css";
import "./App.css";
import {
  useAuthContextData,
  useAuthContextState,
  useAuthService,
} from "./customHooks/authMachineHooks";
import { Hub } from "@aws-amplify/core";
import "@aws-amplify/ui-react/styles.css";
import MainApp from "./MainApp";
import { useNavigate } from "react-router";

function App() {
  const { theme } = useAuthContextData();
  const navigate = useNavigate();
  // log.debug("userProfile", userProfile);
  const authService = useAuthService();
  // log.debug("Rerendering the App.js", userProfile);
  const { checkingCogntioUser } = useAuthContextState();
  log.debug("Checking CogntiOTUser", theme);
  const themeData = JSON.parse(localStorage.getItem("Theme"));
  log.debug("Checking CogntiOTUser", themeData);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          log.debug("HUB LISTEN : signIn => ", event, data);
          authService.send("loginNotify");
          break;
        case "signOut":
          navigate("/");
          break;
        case "cognitoHostedUI":
          log.debug("HUB LISTEN : cognitoHostedUI => ", event, data);

          authService.send("loginNotify");

          break;
        // case "signOut":
        //   log.debug("HUB LISTEN : signOut => ", event, data);
        //   authMachineService.authService.send("logout");

        //   break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          log.debug("Sign in failure", data);
          break;
        default:
          log.debug("HUB LISTEN : DEFAULT => ", event);
      }
    });
  }, []);
  if (checkingCogntioUser) {
    <div className="text-3xl ">Loading...</div>;
  }

  return (
    <div className={theme?.theme}>
      <MainApp />
    </div>
  );
}

// To Test TestUserNOTLoggedIn = false uncomment below
//export default App;

export default App;
