import { Fragment, useState } from "react";

import { Listbox, Transition } from "@headlessui/react";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/outline";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
import { cn } from "../lib/utils";

export default function Dropdown(props) {
  const {
    list,
    setSelected,
    selected,
    nameField = "name",
    nameField2 = "LastName",
    placeholder,
    custom,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");

  const filteredList = list?.filter((item) =>
    item[nameField]?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  const { theme } = useAuthContextData();
  return (
    <div className="z-20">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button
            className={cn(
              "relative flex h-10 w-full border border-input cursor-default select-none items-center rounded-sm px-2 py-1.5 text-lg ring-offset-input focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
              custom
            )}
          >
            <input
              type="text"
              placeholder={placeholder}
              value={
                selected
                  ? selected[nameField2]
                    ? selected[nameField] + " " + selected[nameField2]
                    : selected[nameField]
                  : searchTerm
              }
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full outline-none text-md"
            />
            <ChevronDoubleDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`"absolute mt-1.5 max-h-36 w-full overflow-auto rounded-md z-20   p-1  py-1 text-base shadow-lg ring-1 ring-input ring-opacity-5 focus:outline-none sm:text-sm" ${theme?.cardForeground}`}
            >
              {filteredList?.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative overflow-visible cursor-default select-none ${
                      active
                        ? `"bg-input text-blue  rounded-sm" ${theme?.hoverbg} ${theme?.hoverText}`
                        : "text-foreground overflow-visible rounded-sm"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person?.[nameField]} {person?.[nameField2]}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
