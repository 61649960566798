import React, { useEffect } from "react";
import log from "loglevel";

import {
  useAuthService,
  useAuthContextState,
  useAuthContextData,
} from "../../customHooks/authMachineHooks";
import WelComeComponent from "../auth/WelcomeComponent";
import LoginComponent from "../auth/LoginComponent";
import SignUpComponent from "../SignUpComponent";
import { useActor } from "@xstate/react/lib";
import AddOrganization from "../../pages/common/AddOrganization";
import AddFirstEmployee from "../../pages/common/AddFirstEmployee";
import getStripe from "../../lib/getStripe";
import { CONST_USER_TYPES } from "../../constants/dbconstants";
import AuthCommonLayout from "../auth/AuthCommonLayout";
import { useNavigate } from "react-router-dom";
import ForgotPasswordComponent from "../ForgotPasswordComponent";

export default function Authenticate() {
  const navigate = useNavigate();
  const { userProfile } = useAuthContextData();
  const authService = useAuthService();
  const [state, send] = useActor(authService);
  // get access to the login function
  const {
    isWelcomeState,
    isSignInState,
    isSignUpState,
    isForgotPasswordState,
  } = useAuthContextState();

  useEffect(() => {
    let subscription = authService.subscribe((state) => {
      log.debug("STATE CHANGED++++++++++=>", state);
      if (state.matches("LOGGED_IN.USER_PROFILE_PRESENT.APP_READY_TO_START")) {
        navigate("/");
      }
      if (state.matches({ LOGGED_IN: "CANDIDATE_APPLYING_FOR_INVALID_ORG" })) {
        navigate("/candidate");
      }
    });

    return () => {
      if (subscription) {
        log.debug("Returnining Unsubscribe: ");
        subscription.unsubscribe();
      }
    };
  }, [userProfile]);

  if (isWelcomeState) {
    return (
      <>
        <AuthCommonLayout>
          <WelComeComponent />
        </AuthCommonLayout>
      </>
    );
  } else if (isSignInState) {
    return (
      <>
        <AuthCommonLayout>
          <LoginComponent />
        </AuthCommonLayout>
      </>
    );
  } else if (isSignUpState) {
    return (
      <>
        <AuthCommonLayout>
          <SignUpComponent />
        </AuthCommonLayout>
      </>
    );
  } else if (isForgotPasswordState) {
    return (
      <>
        <AuthCommonLayout>
          <ForgotPasswordComponent />
        </AuthCommonLayout>
      </>
    );
  }
  if (state.matches({ LOGGED_IN: "ORG_DO_NOT_EXISTS" })) {
    return (
      <>
        <AuthCommonLayout>
          <AddOrganization />
        </AuthCommonLayout>
      </>
    );
  }

  if (
    state.matches({ LOGGED_IN: { USER_PROFILE_PRESENT: "WAIT_FOR_PAYMENT" } })
  ) {
    return (
      <>
        <AuthCommonLayout>
          <div className="flex flex-col min-h-full items-center justify-center">
            <div className="text-lg font-semibold text-primary">
              Complete Payment
            </div>
            <PaymentComponent />
          </div>
        </AuthCommonLayout>
      </>
    );
  }

  if (
    state.matches({ LOGGED_IN: { USER_PROFILE_PRESENT: "PLAN_DO_NOT_EXIST" } })
  ) {
    return (
      <AuthCommonLayout>
        <div className="flex flex-row gap-x-4">
          <button
            className="hover:cursor-pointer  p-2 m-2"
            onClick={() => {
              send({ type: "start_trial" });
            }}
          >
            Get Trial
          </button>
          <button
            className="hover:cursor-pointer  p-2 m-2"
            onClick={() => {
              send({ type: "get_subscription" });
            }}
          >
            Payment
          </button>
        </div>
      </AuthCommonLayout>
    );
  }

  if (
    state.matches({
      LOGGED_IN: { USER_PROFILE_ABSENT: "WAIT_FOR_USER_PROFILE_CREATE_EVENT" },
    })
  ) {
    return (
      <>
        <AuthCommonLayout>
          <button
            variant="primary"
            className="hover:cursor-pointer text-lg p-2 m-2"
            onClick={() => {
              send({
                type: "onCreateUserProfileEvent",
                data: { userTypeToBeAdded: CONST_USER_TYPES.employee },
              });
            }}
          >
            Continue with profile creation
          </button>
        </AuthCommonLayout>
      </>
    );
  }

  if (
    state.matches({
      LOGGED_IN: {
        USER_PROFILE_PRESENT: {
          HRADMIN_CREATE_EMPLOYEE_PROFILE:
            "HRADMIN_FILL_EMPLOYEE_PROFILE_DETAILS",
        },
      },
    })
  ) {
    return (
      <>
        <AuthCommonLayout>
          <AddFirstEmployee />
        </AuthCommonLayout>
      </>
    );
  }

  if (
    state.matches({
      LOGGED_IN: { USER_PROFILE_PRESENT: "ORG_PLAN_TRIAL_EXPIRED" },
    })
  ) {
    return (
      <>
        <AuthCommonLayout>
          <div className="text-lg">Trial Plan Expired</div>
          <button
            className="hover:cursor-pointer  p-2 m-2"
            onClick={() => {
              send({ type: "continue_payment" });
            }}
          >
            Get Subscription
          </button>
        </AuthCommonLayout>
      </>
    );
  }
  if (
    state.matches({
      LOGGED_IN: { USER_PROFILE_PRESENT: "ORG_PLAN_REGULAR_EXPIRED" },
    })
  ) {
    return (
      <>
        <AuthCommonLayout>
          <div className="flex flex-col min-h-full items-center justify-center">
            <div className="flex-row">Subscription Plan Expired</div>
            <div className="flex-row">
              <button
                variant="dark"
                className="hover:cursor-pointer  p-2 m-2"
                onClick={() => {
                  send({ type: "continue_payment" });
                }}
              >
                Get Subscription
              </button>
            </div>
          </div>
        </AuthCommonLayout>
      </>
    );
  }

  return (
    <div>
      <AuthCommonLayout>Loading ...</AuthCommonLayout>
    </div>
  );
}
const NEXT_PUBLIC_STRIPE_PRICE_ID = "price_1NLS2GCLds5ZYjtNkK1cqbh9";

function PaymentComponent() {
  const { paymentURLs, email } = useAuthContextData();

  async function handleCheckout() {
    const { success, cancelled, failed } = paymentURLs;
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: NEXT_PUBLIC_STRIPE_PRICE_ID,
          quantity: 1,
        },
      ],
      mode: "subscription",
      successUrl: `http://localhost:3000${success}`,
      cancelUrl: `http://localhost:3000${cancelled}`,
      customerEmail: email,
    });
    console.warn(error.message);
  }

  return (
    <div className="flex flex-col min-h-full items-center justify-center">
      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
        Stripe
      </h1>
      <h2 className=" scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0">
        All in one Super App for your business!
      </h2>

      <button
        variant="primary"
        size="lg"
        className="hover:cursor-pointer  p-2 m-2"
        onClick={handleCheckout}
      >
        Checkout
      </button>
      <div className="z-10 w-full max-w-5xl items-center justify-between font-mono text-sm lg:flex"></div>
    </div>
  );
}
