// InventoryCard.js
import React from "react";
import log from "loglevel";
import { motion } from "framer-motion";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../CustomComponentsLibrary/Card";
import { Button } from "../CustomComponentsLibrary/Button";
import FormatDateAndTime from "./FormatDateAndTime";
import { useAuthContextData } from "../../customHooks/authMachineHooks";

export function InventoryCard({ inventory, cardClickAction }) {
  const { theme } = useAuthContextData();
  const cardVariants = {
    hover: { rotateY: 180 },
    initial: { rotateY: 0 },
  };

  return (
    <>
      <Card className="m-auto">
        <CardHeader>
          <CardTitle>{inventory?.title}</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col text-base font-medium text-slate-900 w-56 md:w-fit">
          <div className="flex items-center space-x-4 rounded-md p-2 hover:bg-accent hover:text-accent-foreground">
            <div className="space-y-1">
              <p className="leading-none">Date:</p>
              <p className="text-sm text-muted-foreground">
                <FormatDateAndTime
                  dateTime={inventory?.date}
                  format={"DD/MM/YYYY"}
                />
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4 rounded-md hover:bg-accent p-2 hover:text-accent-foreground">
            <div className="space-y-1">
              <p className="leading-none">Assigned To:</p>
              <p className="text-sm muted-foreground">
                {inventory?.assignedToName}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4 rounded-md p-2 hover:bg-accent hover:text-accent-foreground">
            <div className="space-y-1">
              <p className="leading-none">Assigned By: </p>
              <p className="text-sm muted-foreground">
                {inventory?.assignedByName}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4 rounded-md p-2 hover:bg-accent hover:text-accent-foreground">
            <div className="space-y-1">
              <p className="leading-none">Content: </p>
              <p className="text-sm muted-foreground">{inventory?.content}</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 rounded-md p-2 hover:bg-accent hover:text-accent-foreground">
            <div className="space-y-1">
              <p className="leading-none">Serial No: </p>
              <p className="text-sm muted-foreground">{inventory?.serialNo}</p>
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Button
            className={`"w-32" ${theme?.textColor} ${theme?.background}`}
            onClick={() => {
              cardClickAction(inventory);
            }}
          >
            <p className="p-4">View Details</p>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}
