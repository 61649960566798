import React, { useState, useEffect } from "react";
import log from "loglevel";
import { hookListOrgYearLeavessByOrgId } from "../../../../hooks/orgYearLeaveHooks";
import { hookListOrgLeavesByYearId } from "../../../../hooks/orgLeaveHooks";
import { useEmployeeContextData } from "../../../../hooks/employeeMachineHooks";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import PageHeader from "../../../common/PageHeader";
import FormatDateAndTime from "../../../common/FormatDateAndTime";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";

export function EmployeeHolidays() {
  const { organizationID } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const [refetch, setRefetch] = useState(false);
  const [orgYearHolidays, setOrgYearHolidays] = useState([]);
  const [orgLeaves, setOrgLeaves] = useState([]);
  log.debug("Yearly Holidays for employee", orgYearHolidays);

  useEffect(() => {
    async function fetchOrgYearHolidays() {
      const fetchedOrgYearHolidays = await hookListOrgYearLeavessByOrgId(
        organizationID
      );
      if (fetchedOrgYearHolidays) {
        const currentYear = new Date().getFullYear();
        const filteredHolidays = fetchedOrgYearHolidays.filter((holiday) => {
          const holidayYear = new Date(holiday?.year).getFullYear();
          return holidayYear === currentYear;
        });
        setOrgYearHolidays(filteredHolidays);
        if (filteredHolidays) {
          const fetchedOrgLeaves = await hookListOrgLeavesByYearId(
            filteredHolidays[0]?.id
          );
          log.debug("fetchedOrgLeaves", fetchedOrgLeaves);
          if (fetchedOrgLeaves) setOrgLeaves(fetchedOrgLeaves);
        }
        log.debug("filteredOrgYearHolidays", filteredHolidays);
      }
    }

    if (organizationID) fetchOrgYearHolidays();

    return () => {
      setOrgYearHolidays([]);
    };
  }, [organizationID, refetch]);

  function refresh() {
    setRefetch(!refetch);
  }

  const columns = [
    {
      header: " SR. NO",
      cell: ({ row }) => <>{row.index + 1}</>,
    },
    {
      header: "Occcasion",
      accessorKey: "occasion",
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: ({ row }) => (
        <div className="text-sm font-semibold">
          <FormatDateAndTime
            dateTime={row.original?.date}
            format={"DD/MM/YYYY"}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="m-3">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto w-full">
          <PageHeader
            title="Holidays"
            SubHeading="Overview of your yearly holidays"
            showDropdown={false}
          />
        </div>
      </div>

      <div className="inline-block mt-5 min-w-full py-2 align-middle border-t border-gray-200 shadow sm:rounded-lg">
        <div className="m-5">
          <DataTable
            columns={columns}
            data={orgLeaves}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 2,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
