import { useState, useEffect } from "react";
import log from "loglevel";
import * as XLSX from "xlsx";
import {
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../hooks/employeeMachineHooks";
import useForm from "../../../customHooks/useForm";

import {
  DialogBox,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/CustomComponentsLibrary/Dailog";
import { Input } from "../../../components/CustomComponentsLibrary/Input";
import { Button } from "../../../components/CustomComponentsLibrary/Button";
import { Label } from "../../../components/CustomComponentsLibrary/Label";
import { Textarea } from "../../../components/CustomComponentsLibrary/Textarea";
import {
  ScrollArea,
  ScrollBar,
} from "../../../components/CustomComponentsLibrary/ScrollArea";
import { Edit2Icon, Trash, Upload } from "lucide-react";
import moment from "moment";
import { useToast } from "../../../components/CustomComponentsLibrary/UseToast";
import { hookListOrgYearLeavessByOrgId } from "../../../hooks/orgYearLeaveHooks";
import { hookListOrgLeavesByYearId } from "../../../hooks/orgLeaveHooks";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { CONST_LEAVE_STATUS } from "../../../constants/dbconstants";
import FormatDateAndTime from "../../../components/common/FormatDateAndTime";

import { Checkbox } from "../../CustomComponentsLibrary/Checkbox";
import { getLogHourByOrgId } from "../../../constants/orgLogHourConstant";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export default function AddEditDeleteTaskHours({ isOpen, onClose }) {
  const employeeMachineService = useEmployeeContextService();
  const {
    selectedTask,
    employeeTaskHoursForDay,
    selectedTaskHour,
    userID,
    myEmployeeProfile,
    organizationID,
    taskHours,
    projects,
    personalTaskHour,
  } = useEmployeeContextData();

  const project = projects?.find((item) => item.id === selectedTask?.projectID);
  log.debug("selectedProjectdata", project);
  const [error, setError] = useState("");
  const [rowSelected, setRowSelected] = useState({});
  const [leaveError, setLeaveError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { toast } = useToast();
  const [orgYearHolidays, setOrgYearHolidays] = useState([]);
  const [orgLeaves, setOrgLeaves] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [editLogHour, setEditLogHour] = useState(null);
  log.debug("isOpen, onClose", taskHours);

  const { inputs, handleChange, clearForm, resetForm } = useForm({
    userID: userID,
    date: editLogHour ? editLogHour?.date : "",
    description: editLogHour ? editLogHour?.description : "",
    isApproved: editLogHour ? editLogHour?.isApproved : null,
    taskID: selectedTask?.id,
    projectID: project?.id,
    totalHours: editLogHour ? editLogHour?.totalHours : 0,
  });

  const handleRowClick = (filteredallProjects) => {
    if (filteredallProjects) {
      const xlsData = filteredallProjects.map((row) => ({
        Date: moment(row?.date)?.format("DD-MM-YYYY, dddd"),
        TotalHours: row?.totalHours,
        Description: row?.description,
      }));

      const xlsWorksheet = XLSX.utils.json_to_sheet(xlsData);
      const xlsWorkbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(xlsWorkbook, xlsWorksheet, "SelectedRows");

      XLSX.writeFile(xlsWorkbook, "selected_rows.xlsx");
    }
  };
  const orgLogHour = getLogHourByOrgId(organizationID);

  useEffect(() => {
    async function fetchOrgYearHolidays() {
      const fetchedOrgYearHolidays = await hookListOrgYearLeavessByOrgId(
        organizationID
      );
      if (fetchedOrgYearHolidays) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear().toString();
        const currentYearObject = fetchedOrgYearHolidays?.filter(
          (item) => item?.year === currentYear
        );
        setOrgYearHolidays(currentYearObject);
        if (currentYearObject) {
          const fetchedOrgLeaves = await hookListOrgLeavesByYearId(
            currentYearObject[0]?.id
          );
          if (fetchedOrgLeaves) setOrgLeaves(fetchedOrgLeaves);
        }
      }
    }
    if (organizationID) fetchOrgYearHolidays();
    return () => {
      setOrgYearHolidays([]);
    };
  }, [organizationID, refetch]);
  var holiday = orgLeaves?.filter((i) => i.date == inputs?.date);
  const leaveForToday = myEmployeeProfile?.leaves?.items?.find((leave) =>
    leave?.date?.some((date) => date === inputs?.date)
  );
  useEffect(() => {
    //fetch log hour for selected date
    const firstDay = moment(inputs?.date)?.format("YYYY-MM-DD");
    const lastDay = moment(inputs?.date)?.format("YYYY-MM-DD");
    const dataToSend = {
      firstDay: firstDay,
      lastDay: lastDay,
      userID: userID,
    };
    employeeMachineService.send({
      type: "get_employee_task_hours",
      data: {
        taskHourByMonth: dataToSend,
      },
    });

    //if leave on selected date not allow to add logHour
    if (leaveForToday) {
      if (
        leaveForToday?.quantity == "1" &&
        (leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusApproved ||
          leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusSubmitted)
      ) {
        setLeaveError(true);
        setHasError(true);
        toast({
          variant: "destructive",
          title: "Failed To add Task Hours",
          description: "You're on Leave",
        });
      } else if (
        leaveForToday?.quantity == "0.5" &&
        (leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusApproved ||
          leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusSubmitted)
      ) {
        setLeaveError(false);
        setHasError(false);
        toast({
          variant: "destructive",
          title: "Add log Hours for half day",
          description: "You're on half day Leave",
        });
      } else {
        setLeaveError(false);
        setHasError(false);
      }
    }
  }, [inputs?.date]);

  useEffect(() => {
    console.log("setHasError", employeeTaskHoursForDay?.totalLogHour);

    if (editLogHour) {
      if (
        leaveForToday?.quantity == "0.5" &&
        (leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusApproved ||
          leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusSubmitted) &&
        (employeeTaskHoursForDay?.totalLogHour +
          parseFloat(inputs?.totalHours) -
          editLogHour?.totalHours >
          orgLogHour?.halfDayWorkingHour ||
          employeeTaskHoursForDay?.totalLogHour - editLogHour?.totalHours >=
            orgLogHour?.halfDayWorkingHour)
      ) {
        setHasError(true);
        toast({
          variant: "destructive",
          title: `Total Hour cannot exceed ${orgLogHour?.halfDayWorkingHour}  Hour`,
          description: `for this date you can add more than ${orgLogHour?.halfDayWorkingHour}  hour beacuse You on half day leave`,
        });
      } else if (
        employeeTaskHoursForDay?.totalLogHour +
          parseFloat(inputs?.totalHours) -
          editLogHour?.totalHours >
          orgLogHour?.workingHour ||
        employeeTaskHoursForDay?.totalLogHour +
          parseFloat(inputs?.totalHours) -
          editLogHour?.totalHours >
          orgLogHour?.workingHour ||
        employeeTaskHoursForDay?.totalLogHour - editLogHour?.totalHours >=
          orgLogHour?.workingHour
      ) {
        setHasError(true);
        toast({
          variant: "destructive",
          title: `Already added ${orgLogHour?.workingHour} logHour for this day`,
          description: `for this day you can add only ${orgLogHour?.workingHour} hour`,
        });
      } else {
        setHasError(false);
      }
    } else {
      if (
        leaveForToday?.quantity == "0.5" &&
        (leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusApproved ||
          leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusSubmitted) &&
        (parseFloat(inputs?.totalHours) > orgLogHour?.halfDayWorkingHour ||
          employeeTaskHoursForDay?.totalLogHour +
            parseFloat(inputs?.totalHours) >
            orgLogHour?.halfDayWorkingHour ||
          employeeTaskHoursForDay?.totalLogHour >=
            orgLogHour?.halfDayWorkingHour)
      ) {
        setHasError(true);
        toast({
          variant: "destructive",
          title: `Total Hour cannot exceed ${orgLogHour?.halfDayWorkingHour} Hour`,
          description: `for this date you can add more than ${orgLogHour?.halfDayWorkingHour} hour beacuse You on half day leave`,
        });
      } else if (
        employeeTaskHoursForDay?.totalLogHour + parseFloat(inputs?.totalHours) >
          orgLogHour?.workingHour ||
        employeeTaskHoursForDay?.totalLogHour + parseFloat(inputs?.totalHours) >
          orgLogHour?.workingHour ||
        employeeTaskHoursForDay?.totalLogHour >= orgLogHour?.workingHour
      ) {
        log.debug(
          "employeeTaskHoursForDay",
          employeeTaskHoursForDay?.totalLogHour + parseFloat(inputs?.totalHours)
        );
        setHasError(true);
        toast({
          variant: "destructive",
          title: `Already added ${orgLogHour?.workingHour} logHour for this day`,
          description: `for this day you can add only ${orgLogHour?.workingHour} hour`,
        });
      } else {
        setHasError(false);
      }
    }

    if (inputs?.totalHours <= 0) {
      setHasError(true);
    }
  }, [employeeTaskHoursForDay, inputs?.totalHours, inputs?.description]);

  const updateLogHour = () => {
    log.debug("inputs", inputs);
    employeeMachineService.send({
      type: "update_task_hours",
      data: {
        taskHoursToBeAdded: {
          ...inputs,
          id: editLogHour?.id,
        },
      },
    });
    setEditLogHour();
  };

  const addLogHour = () => {
    const currentTime = moment();

    const subtractedTime = moment(currentTime).subtract(
      inputs?.totalHours,
      "hours"
    );

    // Format the times in ISO 8601 format
    const isoCurrentTime = currentTime.toISOString();
    const isoSubtractedTime = subtractedTime.toISOString();
    log.debug("inputs", inputs);
    employeeMachineService.send({
      type: "add_task_hours",
      data: {
        taskHoursToBeAdded: {
          ...inputs,
          totalHours: inputs?.totalHours,
          description: inputs?.description,
          startTime: isoSubtractedTime,
          endTime: isoCurrentTime,
        },
      },
    });

    inputs.date = "";
    inputs.description = "";
    inputs.totalHours = 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editLogHour) {
      updateLogHour();
    } else {
      addLogHour();
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            log.debug("selecetd Tixck", value, row, row.getIsSelected());
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: ({ row }) => (
        <FormatDateAndTime dateTime={row.original?.date} format="DD/MM/YYYY" />
      ),
    },
    {
      header: "Description",
      accessorKey: "description",
      cell: ({ row }) => (
        <>
          <div className="w-40  overflow-hidden overflow-ellipsis">
            {row.original?.description}
          </div>
        </>
      ),
    },
    {
      header: "Total Hours",
      accessorKey: "totalHours",
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <div className="flex flex-row gap-x-1">
          {log.debug("_editLogHour", row.original?.isApproved)}
          <>
            {" "}
            <Button
              disabled={row.original?.isApproved}
              variant="link"
              size="sm"
              onClick={() => {
                setEditLogHour(row.original);
              }}
              className="inline-flex text-base font-semibold leading-5"
            >
              <Edit2Icon className="h-4 w-4" />
            </Button>
            <Button
              variant="danger"
              size="sm"
              disabled={row.original?.isApproved}
              onClick={() => {
                log.debug("delete_task_hours", row.original);
                employeeMachineService.send({
                  type: "delete_task_hours",
                  data: {
                    selectedTaskHour: row.original,
                  },
                });
              }}
              className="inline-flex text-base font-semibold leading-5"
            >
              <Trash className="h-4 w-4" />
            </Button>
          </>
          <></>
        </div>
      ),
    },
  ];
  const { theme } = useAuthContextData();
  const handleEscape = (e) => {
    if (e.key === "Escape") {
      console.log(e.key, "keys@");
      onClose();
      if (!selectedTaskHour) {
        employeeMachineService.send({
          type: "cancel_crud",
        });
      }
    }
  };

  if (isOpen === true) {
    console.log(isOpen, "isOpen@@");
    document.addEventListener("keydown", handleEscape);
  }
  return (
    <DialogBox open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[100%] sm:max-w-[70%]  p-3 mt-10  sm:ml-10 sm:mr-10">
        <ScrollArea className=" h-96 sm:h-[600px]  md:h-[600px] lg:h-[600px]  xl:h-[700px]">
          <DialogHeader>
            <DialogTitle>Log Hours</DialogTitle>
            <div className="flex flex-row space-x-8">
              <DialogTitle className="text-md">
                Project : {"  "} {project?.title}
              </DialogTitle>
              <DialogTitle className="text-md">
                Lead Name : {"  "} {project?.leadName}
              </DialogTitle>
            </div>
          </DialogHeader>

          <form method="POST" onSubmit={handleSubmit}>
            <div className="flex flex-col p-1">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Label
                  htmlFor="totalHours"
                  className="block text-sm font-medium leading-6 text-common"
                >
                  Date
                </Label>
                <Input
                  value={inputs?.date}
                  disabled={editLogHour}
                  required
                  type="date"
                  name="date"
                  id="session-time"
                  onChange={handleChange}
                  className={`"mt-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Label
                  htmlFor="totalHours"
                  className="block text-sm font-medium leading-6 text-foreground"
                >
                  Description
                </Label>
                <Textarea
                  value={inputs?.description}
                  type="text"
                  name="description"
                  id="description"
                  onChange={handleChange}
                  maxLength={150}
                  className={`"mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-foreground shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" ${theme?.border}`}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Label
                  htmlFor="totalHours"
                  className="block text-sm font-medium leading-6 text-foreground"
                >
                  Log Hours
                </Label>
                <Input
                  type="float"
                  name="totalHours"
                  id="totalHours"
                  required
                  value={inputs?.totalHours}
                  onChange={handleChange}
                  disabled={leaveError}
                  step="any"
                  className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                />
              </div>
            </div>

            <div className="col-span-6 sm:col-span-3 text-danger text-lg lg:col-span-2">
              {error ? error : null}
            </div>
            <DialogFooter className="pt-2">
              <Button
                type="cancle"
                onClick={() => {
                  onClose();
                  if (!selectedTaskHour) {
                    employeeMachineService.send({
                      type: "cancel_crud",
                    });
                  }
                }}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={hasError}>
                {editLogHour ? "Update" : "Add"}
              </Button>
            </DialogFooter>
          </form>
          <h2 className="font-bold ">Log Hours History</h2>
          <div className="container mx-auto">
            <div className="overflow-x-auto">
              <div className=" flex flex-row justify-end">
                <Button
                  className={`"bg-common text-white mt-2 mb-2 " ${theme?.bgCommon}`}
                  size="sm"
                  onClick={() => {
                    const selectedKeys = Object.keys(rowSelected);
                    const filteredHours = personalTaskHour?.filter(
                      (item, index) => {
                        log.debug("Task, index", item, index);
                        return selectedKeys?.includes(index.toString())
                          ? true
                          : false;
                      }
                    );
                    if (filteredHours) {
                      handleRowClick(filteredHours, selectedKeys);
                    }
                    log.debug("filteredHours", filteredHours, selectedKeys);
                    setRowSelected({});
                  }}
                >
                  <Upload className="h-4 w-4 " /> Export Selected Row
                </Button>
              </div>
              <div className="inline-block min-w-full shadow sm:rounded-lg relative overflow-x-auto">
                <>
                  <ScrollArea className="h-96 sm:h-[300px] w-full">
                    <div className="text-sm font-semibold">
                      <DataTable
                        columns={columns}
                        data={personalTaskHour}
                        setRowSelected={setRowSelected}
                        rowSelected={rowSelected}
                        customCss={`${theme?.border}`}
                        customWidth={[
                          {
                            index: 0,
                            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                          },
                          {
                            index: 1,
                            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                          },
                          {
                            index: 2,
                            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                          },
                          {
                            index: 3,
                            widthClass: "w-32",
                            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                          },
                          {
                            index: 4,
                            widthClass: "w-40",
                            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                          },
                        ]}
                      />
                    </div>
                    <ScrollBar orientation="Horizotal" />
                  </ScrollArea>
                </>
              </div>
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </DialogBox>
  );
}
