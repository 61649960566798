import { useState, useEffect } from "react";
import log from "loglevel";
import {
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../../hooks/employeeMachineHooks";
import useForm from "../../../../customHooks/useForm";
import {
  DialogBox,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../CustomComponentsLibrary/Dailog";
import { Input } from "../../../CustomComponentsLibrary/Input";
import { Button } from "../../../CustomComponentsLibrary/Button";
import { Label } from "../../../CustomComponentsLibrary/Label";
import { Textarea } from "../../../CustomComponentsLibrary/Textarea";
import { ScrollArea } from "../../../CustomComponentsLibrary/ScrollArea";
import moment from "moment";
import { useToast } from "../../../CustomComponentsLibrary/UseToast";
import { CONST_LEAVE_STATUS } from "../../../../constants/dbconstants";
import EditLogHours from "./EditLogHours";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
import EditLogHourByProject from "./EditLogHourByProject";
// import { useParams } from "react-router-dom";

export default function AddEditLoghoursPopup({
  isOpen,
  onClose,
  refreshTask,
  selectedUser,
}) {
  log.debug("isOpen, onClose", isOpen, onClose, selectedUser);
  log.debug("selectedUser::", selectedUser);
  const employeeMachineService = useEmployeeContextService();
  const {
    selectedTask,
    selectedTaskHour,
    actionForTaskHoursCURD,
    employeeTaskHoursDetils,
    logHourDate,
  } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  //   const param = useParams();
  //   const [currentUserId, setCurrentUserId] = useState();
  //   useEffect(() => {
  //     if (selectedUser?.cognitoId === myEmployeeProfile?.cognitoId) {
  //       setCurrentUserId(myEmployeeProfile?.cognitoId);
  //     } else {
  //       setCurrentUserId(selectedUser?.cognitoId);
  //     }
  //   }, [selectedUser]);
  //   log.debug("currentUserId", currentUserId);
  const [totalHours, setTotalHours] = useState("");
  const [hasError, setHasError] = useState(false);
  const { toast } = useToast();

  const [orgYearHolidays, setOrgYearHolidays] = useState([]);
  const [orgLeaves, setOrgLeaves] = useState([]);

  const [refetch, setRefetch] = useState(false);

  const { inputs, handleChange } = useForm({
    userID: selectedUser?.cognitoId,
    startTime: "",
    endTime: "",
    date: logHourDate ? moment(logHourDate).format("YYYY-MM-DD") : "",
    description: "",
    isApproved: null,
    taskID: selectedTask?.id,
    projectID: selectedTask?.projectID,
    totalHours: 0,
  });

  useEffect(() => {
    if (inputs?.date) {
      if (selectedUser?.cognitoId) {
        const firstDay = moment(inputs?.date)?.format("YYYY-MM-DD");
        const lastDay = moment(inputs?.date)?.format("YYYY-MM-DD");
        const dataToSend = {
          firstDay: firstDay,
          lastDay: lastDay,
          userID: selectedUser?.cognitoId,
        };
        log.debug("dataToSend::get_task_log_hours_by_taskid", dataToSend);
        employeeMachineService.send({
          type: "get_task_log_hours_by_taskid",
          data: {
            selectedTask: selectedTask,
            selectedTaskId: selectedTask?.taskID,
            taskHourByMonth: dataToSend,
          },
        });
      }
    }
  }, [logHourDate?.date, selectedUser?.cognitoId]);

  // var holiday = orgLeaves?.filter((i) => i?.date == inputs?.date);

  const { myEmployeeProfile } = useEmployeeContextData();

  const leaveForToday = myEmployeeProfile?.leaves?.items?.find((leave) =>
    leave?.date?.some((date) => date === inputs?.date)
  );
  log.debug("leaveForToday", leaveForToday?.quantity, inputs?.date);

  useEffect(() => {
    if (
      leaveForToday?.quantity == "1" &&
      leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusApproved
    ) {
      setHasError(true);
      toast({
        variant: "destructive",
        title: "Failed To add Task Hours",
        description: "You're on Leave or holiday",
      });
    } else if (
      leaveForToday?.quantity == "0.5" &&
      leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusSubmitted
    ) {
      setHasError(true);
      toast({
        variant: "destructive",
        title: "Action on half day leave",
        description: "You have submitted half day Leave ask for action",
      });
    } else {
      setHasError(false);
    }
  }, [inputs?.date]);

  useEffect(() => {
    if (inputs?.totalHours > employeeTaskHoursDetils?.remainingHours) {
      setHasError(true);
      toast({
        variant: "destructive",
        title: "Total Hour cannot exceed Total Allocated Hours",
        description: `for this date you can add less than ${employeeTaskHoursDetils?.totalAllocatedHoursForDay}`,
      });
    } else if (inputs?.totalHours > employeeTaskHoursDetils?.remainingHours) {
      setHasError(true);
      toast({
        variant: "destructive",
        title: "Total Hour cannot exceed Total Allocated Hours",
        description: `for this date you can add less than ${employeeTaskHoursDetils?.totalAllocatedHoursForDay}`,
      });
    } else {
      setTotalHours(0);
      setHasError(false);
    }
    if (inputs?.totalHours <= 0) {
      setHasError(true);
    }
  }, [employeeTaskHoursDetils, inputs?.totalHours]);

  const CancelCrud = async () => {
    employeeMachineService.send({ type: "cancel_task_hours_crud" });
  };
  const handleClose = () => {
    onClose();
    CancelCrud();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    log.debug("inputs::", inputs);
    if (inputs?.date) {
      const currentTime = moment();
      const x = 2;
      const subtractedTime = moment(currentTime).subtract(totalHours, "hours");

      const isoCurrentTime = currentTime.toISOString();
      const isoSubtractedTime = subtractedTime.toISOString();
      const formData = {
        userID: selectedUser?.cognitoId,
        startTime: isoSubtractedTime,
        endTime: isoCurrentTime,
        date: moment(inputs?.date).format("YYYY-MM-DD"),
        description: inputs.description,
        isApproved: null,
        taskID: selectedTask?.taskID,
        projectID: selectedTask?.projectID,
        totalHours: inputs?.totalHours ? inputs?.totalHours : 0,
      };
      log.debug("Form Data::", formData);
      const selectedTaskId = {
        id: selectedTask?.taskID,
        billableHours: selectedTask?.billableHours,
        projectID: selectedTask?.projectID,
      };

      log.debug("selectedTaskId", selectedTaskId);

      employeeMachineService.send({
        type: "add_Log_hours",
        data: {
          selectedTask: selectedTaskId,
          taskHoursToBeAdded: formData,
        },
      });

      onClose();
      CancelCrud();
    }
  };
  switch (actionForTaskHoursCURD) {
    case "ADD":
      return (
        <DialogBox open={isOpen} onOpenChange={handleClose}>
          <DialogContent className="sm:max-w-[70%] p-3  mt-14 sm:ml-20 sm:mr-20">
            <ScrollArea className="h-96 sm:h-[700px]">
              <DialogHeader>
                <DialogTitle>
                  Log Hours
                  <p className="m-2 text-sm font-light">
                    Make Changes to your task here.Click save when you're done
                  </p>
                  <br></br>
                  <div className=" py-2 text-base">
                    Task Name : {selectedTask?.taskTitle}
                  </div>
                </DialogTitle>
              </DialogHeader>

              <form onSubmit={handleSubmit} method="POST">
                <div className="flex flex-col p-1">
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <Label
                      htmlFor="totalHours"
                      className="block text-base font-medium leading-6 text-foreground"
                    >
                      Date
                    </Label>
                    <Input
                      value={
                        logHourDate || moment(new Date()).format("YYYY-MM-DD")
                      }
                      defaultValue={
                        logHourDate || moment(new Date()).format("YYYY-MM-DD")
                      }
                      required
                      type="date"
                      name="date"
                      id="session-time"
                      onChange={handleChange}
                      disabled
                      className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <Label
                      htmlFor="totalHours"
                      className="block text-base font-medium leading-6 text-foreground mt-5"
                    >
                      Description
                    </Label>
                    <div>
                      <Textarea
                        value={inputs?.description}
                        placeholder="Add project description here"
                        type="text"
                        name="description"
                        id="description"
                        onChange={handleChange}
                        maxLength={150}
                        className={`"mt-2 block w-full py-1.5 px-3 text-foreground  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        ${theme?.border}`}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <Label
                      htmlFor="totalHours"
                      className="block text-base font-medium leading-6 text-foreground"
                    >
                      Log Hours
                    </Label>
                    <Input
                      type="text"
                      name="totalHours"
                      id="totalHours"
                      required
                      value={inputs?.totalHours}
                      onChange={handleChange}
                      step="any"
                      className={`"mt-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3 text-danger text-lg lg:col-span-2"></div>
                <DialogFooter className="pt-3">
                  <Button
                    type="cancle"
                    onClick={() => {
                      onClose();
                      if (!selectedTaskHour) {
                        employeeMachineService.send({
                          type: "cancel_task_hours_crud",
                        });
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={hasError}>
                    {selectedTaskHour ? "Update" : "Add"}
                  </Button>
                </DialogFooter>
              </form>
            </ScrollArea>
          </DialogContent>
        </DialogBox>
      );

    case "EDIT":
      return (
        <DialogBox open={isOpen} onOpenChange={handleClose}>
          <DialogContent className="sm:max-w-[70%] p-3  mt-14 sm:ml-20 sm:mr-20">
            <ScrollArea className="h-96 sm:h-[700px]">
              <DialogHeader>
                <DialogTitle>
                  Log Hours
                  <br></br>
                  <div className="mt-2 py-2 text-base">
                    Task Name : {selectedTask?.taskTitle}
                  </div>
                </DialogTitle>
              </DialogHeader>

              <EditLogHourByProject
                onClose={onClose}
                refreshTaskHours={refreshTask}
                selectedUser={selectedUser}
              />
            </ScrollArea>
          </DialogContent>
        </DialogBox>
      );
  }
}
