import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API, Auth } from "aws-amplify";
import log from "loglevel";
import { GetSignedURL, convertS3UrlToBase64 } from "./s3Hooks";

export const hookListOrganizations = async () => {
  log.debug(" hookListOrganizations ");

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY Organization ID
    const data = await API.graphql({
      query: queries.listOrganizations,
      variables: {},
    });

    log.debug(" hookListOrganizations : ", data);

    if (data !== null) return data?.data?.listOrganizations.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListOrganizations -- ", e);
    return null;
  }
};

export const hookAddOrganization = async (Organization) => {
  log.debug(" hookAddOrganization : ", Organization);

  try {
    const result = await API.graphql({
      query: mutations.createOrganization,
      variables: {
        input: Organization,
      },
    });

    log.debug(" hookAddOrganization result: ", result);

    return result?.data?.createOrganization;
  } catch (err) {
    log.debug("CATCH ERROR hookAddOrganization result: ", err);
    return null;
  }
};

export const hookUpdateOrganization = async (Organization) => {
  log.debug(" hookUpdateOrganization : ", Organization);
  try {
    const result = await API.graphql({
      query: mutations.updateOrganization,
      variables: {
        input: Organization,
      },
    });

    log.debug(" hookUpdateOrganization result: ", result);
    return result?.data?.updateOrganization;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateOrganization result: ", err);
  }
};

export const hookDeleteOrganizationByOrganizationId = async (
  OrganizationId
) => {
  log.debug(" hookDeleteOrganizationByOrganizationId  ", OrganizationId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteOrganization,
      variables: {
        input: { id: OrganizationId },
      },
    });

    log.debug(" hookDeleteOrganizationByOrganizationId : ", data);
    if (data !== null) return data?.data?.deleteOrganization;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeleteOrganizationByOrganizationUniqueID -- ",
      e
    );
    return null;
  }
};

// export const hookListOrganizationsByUserId = async (userID) => {
//   log.debug(" hookListOrganizationsByUserId userID", userID);

//   try {
//     //Connect Client Amplify GraphQL
//     //TODO LIST BY Organization ID
//     const data = await API.graphql({
//       query: queries.organizationsByUserID,
//       variables: {
//         sortDirection: "DESC",
//         userID: userID,
//       },
//     });

//     log.debug(" hookListOrganizationsByUserId : ", data);

//     if (data !== null) return data?.data?.OrganizationsByUserID.items;
//     else return null;
//   } catch (e) {
//     console.error("CATCH ERROR hookListOrganizationsByUserId -- ", e);
//     return null;
//   }
// };

export const hookGetOrganizationById = async (orgId) => {
  log.debug(" hookGetOrganizationById ++  id", orgId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getOrganization,
      variables: {
        id: orgId,
      },
    });

    if (data?.data?.getOrganization) {
      log.debug(" hookGetOrganizationById : ", data.data.getOrganization);
      return data.data.getOrganization;
    } else {
      return null;
    }
  } catch (e) {
    console.error("CATCH ERROR hookGetOrganizationById -- ", e);
    return null;
  }
};

export const hookGetOrganizationByUniqueName = async (uniqueName) => {
  log.debug(" hookGetOrganizationByUniqueName ++  Id", uniqueName);

  try {
    //Connect Client Amplify GraphQL
    const organizations = await hookListOrganizations();
    log.debug(" All orgs ++  Id", organizations);

    const orgToRet = organizations.find((org) => org.uniqueName === uniqueName);
    log.debug(" Org To ret ++  Id", orgToRet);

    return orgToRet || null;
  } catch (e) {
    console.error("CATCH ERROR hookGetOrganizationByUniqueName -- ", e);
    return null;
  }
};

export async function getOrganizationStyling(orgPassed) {
  let logoBig = orgPassed?.logoSmall
    ? (await GetSignedURL(orgPassed.logoSmall)) || "./logoBig.png"
    : "";
  let logoSmall = orgPassed?.logoSmall
    ? (await GetSignedURL(orgPassed.logoSmall)) || "./logoSmall.png"
    : "";
  let brandLogoBig = orgPassed?.brandLogoBig
    ? (await GetSignedURL(orgPassed.brandLogoBig)) || "./brandLogoBig.png"
    : "";
  let brandLogoSmall = orgPassed?.brandLogoSmall
    ? (await GetSignedURL(orgPassed.brandLogoSmall)) || "./brandLogoSmall.png"
    : "";
  let primaryColor = orgPassed?.primaryColor ? orgPassed.primaryColor : "";
  let secondaryColor = orgPassed?.secondaryColor
    ? orgPassed.secondaryColor
    : "";
  let menuBGImage = orgPassed?.menuBGImage
    ? (await GetSignedURL(orgPassed.menuBGImage)) || "./menuBGImage.png"
    : "";

  logoSmall = await convertS3UrlToBase64(logoSmall);
  logoBig = await convertS3UrlToBase64(logoBig);
  return {
    logoBig,
    logoSmall,
    brandLogoBig,
    brandLogoSmall,
    primaryColor,
    secondaryColor,
    menuBGImage,
  };
}
