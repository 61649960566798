import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddInventoryComment = async (comment) => {
  log.debug(" hookAddInventoryComment : ", comment);

  try {
    const result = await API.graphql({
      query: mutations.createInventoryComment,
      variables: {
        input: comment,
      },
    });

    log.debug(" hookAddInventoryComment result: ", result);

    return result?.data?.createInventoryComment;
  } catch (err) {
    log.debug("CATCH ERROR hookAddInventoryComment result: ", err);
    return null;
  }
};

export const hookUpdateInventoryComment = async (Inventory) => {
  log.debug(" hookUpdateInventoryComment : ", Inventory);
  try {
    const result = await API.graphql({
      query: mutations.updateInventoryComment,
      variables: {
        input: Inventory,
      },
    });

    log.debug(" hookUpdateInventoryComment result: ", result);
    return result?.data?.updateInventoryComment;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateInventoryComment result: ", err);
  }
};

export const hookDeleteCommentByInventoryId = async (InventoryId) => {
  log.debug(" hookDeleteCommentByInventoryId  ", InventoryId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteInventoryComment,
      variables: {
        input: { id: InventoryId },
      },
    });

    log.debug(" hookDeleteCommentByInventoryId : ", data);
    if (data !== null) return data?.data?.deleteInventoryComment;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteCommentByInventoryId -- ", e);
    return null;
  }
};

export const hookListCommentsByInventoryID = async (currentInventoryID) => {
  try {
    const data = await API.graphql({
      query: queries.InventoryCommentsByTaskID,
      variables: { InventoryID: currentInventoryID },
    });

    log.debug(" hookListCommentsByInventoryID : ", data);

    if (data !== null) return data?.data?.InventoryCommentsByTaskID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCommentsByInventoryID -- ", e);
    return null;
  }
};
