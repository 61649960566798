const TableStatus = [
  { name: "AVAILABLE" },
  { name: "UNAVAILABLE" },
  { name: "OCCUPIED" },
];

const enumMenuItemStatusArray = [
  { name: "AVAILABLE" },
  { name: "UNAVAILABLE" },
  { name: "OUTOFSTOCK" },
];
const resourceType = {
  youtube: "YOUTUBE",
  link: "LINK",
  s3storage: "S3STORAGE",
};

const paymentType = {
  reimbursement: "REIMBURSEMENT",
  bill: "BILL",
  invoice: "INVOICE",
  payroll: "PAYROLL",
};

const orgPaymentStatus = {
  paymentDoneFromCustomer: "PAYMENT_DONE_FROM_CUSTOMER",
  paymentProblematic: "PAYMENT_PROBLEMATIC",
  paymentOnhold: "PAYMENT_ONHOLD",
  paymentReceivedVerified: "PAYMENT_RECEIVED_VERIFIED",
  paymentCancelledByUser: "PAYMENT_PROBLEMATIC",
};

const paymentTowards = {
  employeeSalary: "EMPLOYEE_SALARY",
  operatingExpense: "OPERATING_EXPENSE",
  clientPayment: "CLIENT_PAYMENT",
};
const positionTypes = {
  fresh_frontend: "FRESH_FRONTEND",
  fresh_embedded: "FRESH_EMBEDDED",
  fresh_hardware: "FRESH_HARDWARE",
  fresh_android: "FRESH_ANDROID",
  fresh_general: "FRESH_GENERAL",
  exp_frotnend: "EXP_FRONTEND",
  exp_embed: "EXP_EMBEDDED",
  exp_hardware: "EXP_HARDWARE",
  exp_android: "EXP_ANDROID",
  exp_general: "EXP_GENERAL",
};
const questionType = {
  mcq: "MCQ",
  coding: "CODING",
  desc: "DESCRIPTIVE",
};

const OrderStatus = {
  inCart: "INCART",
  submitted: "SUBMITTED",
  confirmed: "CONFIRMED",
  inProcess: "INPROCESS",
  onHold: "ONHOLD",
  billGenerated: "ONHOLD",
  delivered: "DELIVERED",
  paymentDone: "PAYMENTDONE",
};

const workType = {
  home: "WORK_FROM_HOME",
  office: "WORK_FROM_OFFICE",
  clientLocation: "WORK_FROM_CLIENTLOCATION",
  unknown: "WORK_FROM_UNKNOWN",
};

const JobApplicationStatus = {
  JobPosted: "JobPosted",
  JobApplied: "JobApplied",
  Shortlisted: "Shortlisted",
  NotShortlisted: "NotShortlisted",
  SendRejectionEmail: "SendRejectionEmail",
  ScheduleOnlineTest: "ScheduleOnlineTest",
  OnlineTestScheduledToEmail: "OnlineTestScheduledToEmail",
  TEST_START_ALLOWED: "TEST_START_ALLOWED",
  TestInProgress: "TestInProgress",
  TestSubmitted: "TestSubmitted",
  TestPassed: "TestPassed",
  OnlineTestFailed: "OnlineTestFailed",
  SendOnlineTestRejectionEmailToCandidate:
    "SendOnlineTestRejectionEmailToCandidate",
  SendTestPassedEmailToManager: "SendTestPassedEmailToManager",
  ScheduleWrittenTest: "ScheduleWrittenTest",
  SendWrittenTestScheduledEmailToCandidate:
    "SendWrittenTestScheduledEmailToCandidate",
  WRITTENTEST_START_ALLOWED: "WRITTENTEST_START_ALLOWED",
  WrittenTestInProgress: "WrittenTestInProgress",
  WrittenTestSubmitted: "WrittenTestSubmitted",
  WrittenTestFailed: "WrittenTestFailed",
  SendWrittenTestRejectionEmailToCandidate:
    "SendWrittenTestRejectionEmailToCandidate",
  WrittenTestPassed: "WrittenTestPassed",
  SendWrittenTestPassedEmailToManager: "SendWrittenTestPassedEmailToManager",
  ScheduleTechnicalInterview: "ScheduleTechnicalInterview",
  SendTechnicalInterviewScheduledEmailToCandidate:
    "SendTechnicalInterviewScheduledEmailToCandidate",
  TechnicalInterviewInProgress: "TechnicalInterviewInProgress",
  TechnicalInterviewSubmitted: "TechnicalInterviewSubmitted",
  TechnicalInterviewFailed: "TechnicalInterviewFailed",
  SendTechnicalInterviewRejectionEmail: "SendTechnicalInterviewRejectionEmail",
  TechnicalInterviewPassed: "TechnicalInterviewPassed",
  SendTechnicalInterviewPassedEmailToHR:
    "SendTechnicalInterviewPassedEmailToHR",
  ScheduleHRInterview: "ScheduleHRInterview",
  SendHRInterviewScheduledEmailToCandidate:
    "SendHRInterviewScheduledEmailToCandidate",
  HRInterviewInProgress: "HRInterviewInProgress",
  HRInterviewSubmitted: "HRInterviewSubmitted",
  HRInterviewFailed: "HRInterviewFailed",
  SendHRInterviewRejectionEmail: "SendHRInterviewRejectionEmail",
  HRInterviewPassed: "HRInterviewPassed",
  SendEmailToUploadDocuments: "SendEmailToUploadDocuments",
  SendHrInterviewPassedEmailToCandidate:
    "SendHrInterviewPassedEmailToCandidate",
  DocumentUpload: "DocumentUpload",
  DocumentsUploadComplete: "DocumentsUploadComplete",
  PrepareCandidateOffer: "PrepareCandidateOffer",
  PreparedCandidateOffer: "PreparedCandidateOffer",
  ApproveCandidateOffer: "ApproveCandidateOffer",
  SendCandidateOfferEmailToCandidate: "SendCandidateOfferEmailToCandidate",
  CandidateOfferRejected: "CandidateOfferRejected",
  CandidateAcceptedOffer: "CandidateAcceptedOffer",
  SendEmail1WeekBeforeJoining: "SendEmail1WeekBeforeJoining",
  SendEmail1DaysBeforeJoining: "SendEmail1DaysBeforeJoining",
  Hired: "Hired",
  ApplicationEnd: "ApplicationEnd",
};

const emailEvents = {
  SendRejectionEmail: "SendRejectionEmail",
  OnlineTestScheduledToEmail: "OnlineTestScheduledToEmail",
  SendOnlineTestRejectionEmailToCandidate:
    "SendOnlineTestRejectionEmailToCandidate",
  SendTestPassedEmailToManager: "SendTestPassedEmailToManager",
  SendWrittenTestScheduledEmailToCandidate:
    "SendWrittenTestScheduledEmailToCandidate",
  SendWrittenTestRejectionEmailToCandidate:
    "SendWrittenTestRejectionEmailToCandidate",
  SendWrittenTestPassedEmailToManager: "SendWrittenTestPassedEmailToManager",
  SendTechnicalInterviewScheduledEmailToCandidate:
    "SendTechnicalInterviewScheduledEmailToCandidate",
  SendTechnicalInterviewRejectionEmail: "SendTechnicalInterviewRejectionEmail",
  SendTechnicalInterviewPassedEmailToHR:
    "SendTechnicalInterviewPassedEmailToHR",
  SendHRInterviewScheduledEmailToCandidate:
    "SendHRInterviewScheduledEmailToCandidate",
  SendHRInterviewRejectionEmail: "SendHRInterviewRejectionEmail",
  SendEmailToUploadDocuments: "SendEmailToUploadDocuments",
  SendHrInterviewPassedEmailToCandidate:
    "SendHrInterviewPassedEmailToCandidate",
  SendCandidateOfferEmailToCandidate: "SendCandidateOfferEmailToCandidate",
  SendEmail1WeekBeforeJoining: "SendEmail1WeekBeforeJoining",
  SendEmail1DaysBeforeJoining: "SendEmail1DaysBeforeJoining",
};

const paymentStatus = {
  waitingForApproval: "PAYMENT_DONE_FROM_CUSTOMER",
  pending: "PAYMENT_ONHOLD",
  onHold: "PAYMENT_ONHOLD",
  done: "PAYMENT_RECEIVED_VERIFIED",
  problamatic: "PAYMENT_PROBLAMATIC",
  pending: "PENDING",
  completed: "COMPLETED",
  failed: "FAILED",
};

const TableAvailabilityStatus = {
  available: "AVAILABLE",
  unavailable: "UNAVAILABLE",
  occupied: "OCCUPIED",
};

const enumMenuItemStatus = {
  available: "AVAILABLE",
  unavailable: "UNAVAILABLE",
  outofstock: "OUTOFSTOCK",
};

const FoodOrderItemStatus = {
  inCart: "INCART",
  orderPlaced: "ORDERPLACED",
  confirmed: "CONFIRMED",
  preparing: "PREPARING",
  prepared: "PREPARED",
  served: "SERVED",
};

const UserTypes = {
  newStaff: "NEW_STAFF",
  waitingForApproval: "WAITING_FOR_APPROVAL",
  approvedAdmin: "APPROVED_ADMIN",
  disabledStaff: "DISABLED_STAFF",
  admin: "ADMIN",
  superAdmin: "SUPERADMIN",
  employee: "EMPLOYEE",
  manager: "MANAGER",
  hrAdmin: "HRADMIN",
  candidate: "CANDIDATE",
  hr: "HR",
  financeAdmin: " FINANCEADMIN",
  sales: "SALES",
  marketing: "MARKETING",
  software: "SOFTWARE",
  teamLead: "TEAMLEAD",
};

const ErrorSting = {
  isPastOrder: "PAST_ORDERS_IS_THERE",
  noPastOrder: "NO_PAST_ORDERS",
};

const typeOfLeave = {
  leaveAnnual: "LEAVE_ANNUAL",
  leaveUnpaid: "LEAVE_UNPAID",
  leaveSick: "LEAVE_SICK",
  leaveMaternity: "LEAVE_MATERNITY",
  leaveParternity: "LEAVE_PARTERNITY",
  timeOf: "TIMEOFF",
};

const expencseStatus = {
  expencseStatusCreated: "EXPENSE_CREATED",
  expencseStatusInprocess: "EXPENSE_INPROCESS",
  expencseStatusOnhold: "EXPENSE_ONHOLD",
  expencseStatusRejected: "EXPENSE_REJECTED",
  expencseStatusApproved: "EXPENSE_APPROVED",
  expencseStatusPaymentDone: "EXPENSE_PAYMENT_DONE",
};

const leavesStatus = {
  leaveStatusSubmitted: "LEAVESTATUS_SUBMITTED",
  leaveStatusRejected: "LEAVESTATUS_REJECTED",
  leaveStatusApproved: "LEAVESTATUS_APPROVED",
  leaveStatusInprogress: "LEAVESTATUS_INPROCESS",
  leaveStatusOnhold: "LEAVESTATUS_ONHOLD",
};

const coffStatus = {
  coffSubmitted: "COFF_APPLIED",
  coffApproved: "COFF_APPROVED",
  coffRejected: "COFF_REJECTED",
};

const goalStatus = {
  goalStatusCreated: "GOALSTATUS_CREATED",
  goalStatusInprocess: "GOALSTATUS_INPROCESS",
  goalStatusOnhold: "GOALSTATUS_ONHOLD",
  goalStatusCancelled: "GOALSTATUS_CANCELLED",
  goalStatusDone: "GOALSTATUS_DONE",
  goalStatusDoneApproved: "GOALSTATUS_DONE_APPROVED",
};

const taskStatus = {
  taskStatusCreated: "TASKSTATUS_CREATED",
  taskStatusInprogress: "TASKSTATUS_INPROCESS",
  taskStatusOnhold: "TASKSTATUS_ONHOLD",
  taskStatusCancelled: "TASKSTATUS_CANCELLED",
  taskStatusTesting: "TASKSTATUS_TESTING",
  taskStatusDone: "TASKSTATUS_DONE",
  taskStatusDoneApproved: "TASKSTATUS_DONE_APPROVED",
};

const SalesDealStatus = {
  deal_create: "DEAL_CREATED",
  deal_inprogress: "DEAL_INPROCESS",
  deal_onhold: "DEAL_ONHOLD",
  deal_won: "DEAL_WON",
  deal_lost: "DEAL_LOST",
};

const salesTaskStatus = {
  taskStatusCreated: "SALESTASK_CREATED",
  taskStatusInprogress: "SALESTASK_INPROCESS",
  taskStatusOnhold: "SALESTASK_ONHOLD",
  taskStatusCancelled: "SALESTASK_CANCELLED",
  taskStatusDone: "SALESTASK_DONE",
  taskStatusDoneApproved: "SALESTASK_DONE_APPROVED",
};
const currencies = {
  Indianrupee: "INR",
  Singaporedollar: "SGD",
  UnitedStatesdollar: "	USD",
};

const paymentMethod = {
  paymentBankTransfer: "PAYMENT_BANK_TRANSFER",
  paymentCheque: "PAYMENT_CHEQUE",
  paymentCash: "PAYMENT_CASH",
  paymnetOthers: "PAYMENT_OTHERS",
};

const salesPaymentStatus = {
  paymentDoneFromCustomer: "PAYMENT_DONE_FROM_CUSTOMER",
  paymentProblematic: "PAYMENT_PROBLEMATIC",
  paymentOnhold: "PAYMENT_ONHOLD",
  paymentReceivedVerified: "PAYMENT_RECEIVED_VERIFIED",
};

const salesContactStatus = {
  subscriber: "CONTACT_SUBSCRIBER",
  lead: "CONTACT_LEAD",
  qualifiedLead: "CONTACT_QUALIFIED",
  opportunity: "CONTACT_OPPORTUNITY",
  customer: "CONTACT_CUSTOMER",
  evangelist: "CONTACT_EVANGELIST",
  contatParked: "CONTACT_PARKED",
};

const listOfIndustries = {
  healthCare: "HEALTH_CARE",
  teleCommunications: "TELECOMMUNICATIONS",
  engineering: "ENGINEERING",
  informationtechnology: "INFORMATION_TECHNOLOGY",
  automotiveindustry: "AUTOMOTIVE_INDUSTRY",
  electronics: "ELECTRONICS",
  Education: "EDUCATION",
};

const ListOfDocuments = {
  tenthMarksheet: "SSC_MARKSHEET",
  twelfthMarksheet: "HSC_MARKSHEET",
  DegreeMasters: "DEGREE_MASTERS_MARK_SHEETS",
  incrementletters: "INCREMENT_LETTERS",
  appointmentletter: "APPOINTMENT_LETTER",
  salaryslipone: "LAST_ONE_MONTH_SALARY_SLIP",
  salarysliptwo: "LAST_SECOND_MONTH_SALARY_SLIP",
  salaryslipthree: "LAST_THIRD_MONTH_SALARY_SLIP",
  Professionalcertification: "PROFESSIONAL_CERTIFICATION",
  experienceletter: "EXPERIENCE_LETTER",
  relievingletter: "RELIEVING_LETTER",
};

const documentStatus = {
  docUploaded: "DOC_UPLOADED",
  documentVerifyPass: "DOC_VERIFY_PASS",
  documentVerifyFail: "DOC_VERIFY_FAIL",
};

const whomToWhom = {
  orgToVendor: "ORG_TO_VENDOR",
  orgToEmployee: "ORG_TO_EMPLOYEE",
  clientToOrg: "CLIENT_TO_ORG",
};

const paymentDirection = {
  incoming: "PAYMENT_INCOMING",
  outgoing: "PAYMENT_OUTGOING",
};

const employeeStatus = {
  employeeNewHire: "EMPLOYEE_NEWHIRE",
  employeeOnBoarding: "EMPLOYEE_ONBOARDING",
  employeeVerifyPending: "EMPLOYEE_VERIFY_PENDING",
  employeeVerifyFailed: "EMPLOYEE_VERIFY_FAILED",
  employeeOnProbation: "EMPLOYEE_ON_PROBATION",
  employeeTerminated: "EMPLOYEE_TERMINATED",
  employeeNormal: "EMPLOYEE_NORMAL",
  employeeOnPIP: "EMPLOYEE_ON_PIP",
};
const leavesPerYear = {
  totalSickLeavesPerYear: 12,
  totalAnnualLeavesPerYear: 10,
  maxMonthlyLeaves: 2,
};

const updateContextActions = {
  update: "update",
  updateArr: "updateArr",
  removeArr: "removeArr",
  addArr: "addArr",
  updateArrMultiple: "updateArrMultiple",
};

const OrgPlanStatus = {
  noPlan: "NO_PLAN",
  trialPlan: "TRIAL_PLAN",
  trialPlanExpired: "TRIAL_PLAN_EXPIRED",
  regularPlan: "REGULAR_PLAN",
  regularPlanExpired: "REGULAR_PLAN_EXPIRED",
};

const apiResponses = {
  fail: "FAIL",
  success: "SUCCESS",
  networkError: "NETWORK_ERRORED",
};

const reportTypes = {
  plReport: "PROFIT_LOSS_REPORT",
  balanceSheet: "BALANCE_SHEET_REPORT",
  cashFlow: "CASH_FLOW_REPORT",
};

const orgDocumentTypes = {
  estimate: "ESTIMATE",
  invoice: "INVOICE",
  proposal: "PROPOSAL",
  salarySlip: "SALARYSLIP",
  form16: "FORM16",
};

const projectStatus = {
  projectStatusCreated: "PROJECT_CREATED",
  projectStatusInprogress: "PROJECT_INPROCESS",
  projectStatusOnhold: "PROJECT_ONHOLD",
  projectStatusDone: "PROJECT_DONE",
  projectStatusDelivered: "PROJECT_DELIVERED",
  projectStatusClosed: "PROJECT_CLOSED",
};
const invoiceStatus = {
  done: "DONE",
  onHold: "ONHOLD",
  pending: "PENDING",
  completed: "COMPLETED",
  projectStatusDone: "PROJECT_DONE",
  failed: "FAILED",
};

const attendanceFrom = {
  projectHoursAsTimeSheet: "PROJECT_HOURS_AS_TIMESHEET",
  timeInTimeOutAsTimeSheet: "TIME_IN_TIME_OUT_AS_TIMESEET",
};

export const CONST_TABLESTATUS = TableStatus;
export const CONST_ORDER_STATUS = OrderStatus;
export const CONST_PAYMENT_STATUS = paymentStatus;
export const CONST_ORDER_ITEM_STATUS = FoodOrderItemStatus;
export const CONST_TABLE_AVAILABILITY_STATUS = TableAvailabilityStatus;
export const CONST_TABLE_MENUITEM_STATUS = enumMenuItemStatus;
export const CONST_TABLE_MENUITEM_STATUS_ARRAY = enumMenuItemStatusArray;
export const CONST_USER_TYPES = UserTypes;
export const GST_PERCENTAGE = 0.28;
export const SERVICE_CHARGE_PERCENTAGE = 0.1;
export const CONST_ERROR_STRING = ErrorSting;
export const CONST_JOB_APPLICATION_STATUS = JobApplicationStatus;
export const CONST_SEND_EMAIL_EVENT = emailEvents;
export const CONST_QUESTION_TYPES = questionType;
export const CONST_POSITION_TYPES = positionTypes;
export const CONST_TYPE_OF_LEAVE = typeOfLeave;
export const CONST_LEAVE_STATUS = leavesStatus;
export const CONST_COFF_STATUS = coffStatus;
export const CONST_GOAL_STATUS = goalStatus;
export const CONST_TASK_STATUS = taskStatus;
export const CONST_DOCUMENTS_LIST = ListOfDocuments;
export const CONST_RESOURCE_TYPE = resourceType;
export const CONST_SALESTASK_STATUS = salesTaskStatus;
export const CONST_CURRENCY = currencies;
export const CONST_API_RESPONSE = apiResponses;
export const CONST_PAYMENT_METHOD = paymentMethod;
export const CONST_SALES_PAYMENT_STATUS = salesPaymentStatus;
export const CONST_SALESCONTACT_STATUS = salesContactStatus;
export const CONST_INDUSTRIES_LIST = listOfIndustries;
export const CONST_DEALS_STATUS = SalesDealStatus;
export const CONST_EXPENCES_STATUS = expencseStatus;
export const CONST_DOCUMENT_STATUS = documentStatus;
export const CONST_EMPLOYEE_STATUS = employeeStatus;
export const CONST_LEAVES_PER_YEAR = leavesPerYear;
export const CONST_WORK_TYPE = workType;
export const CONST_ORG_PAYMENT_STATUS = orgPaymentStatus;
export const CONST_UPDATE_CONTEXT_ACTIONS = updateContextActions;
export const CONST_ORG_PLAN_STATUS = OrgPlanStatus;
export const CONST_PAYMENT_TYPE = paymentType;
export const CONST_PAYMENT_FROM_TO = whomToWhom;
export const CONST_PAYMENT_DIRECTION = paymentDirection;
export const CONST_PAYMENTS_TOWARDS = paymentTowards;
export const CONST_REPORT_TYPES = reportTypes;
export const CONST_ORG_DOC_TYPES = orgDocumentTypes;
export const CONST_PROJECT_STATUS = projectStatus;
export const CONST_PROJECT_INVOICE_STATUS = invoiceStatus;
export const CONST_ATTENDANCE_FROM = attendanceFrom;

/*
  enum OrderStatus {
    INCART
    CONFIRMED
    PREPARING
    ONHOLD
    DELIVERED
    INPROCESS
    PAYMENTDONE
  }

  enum MenuItemStatus {
    AVAILABLE
    UNAVAILABLE
    OUTOFSTOCK
  }
*/
