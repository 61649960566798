import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddLeaveComment = async (comment) => {
  log.debug(" hookAddLeaveComment : ", comment);

  try {
    const result = await API.graphql({
      query: mutations.createLeaveComment,
      variables: {
        input: comment,
      },
    });

    log.debug(" hookAddLeaveComment result: ", result);

    return result?.data?.createLeaveComment;
  } catch (err) {
    log.debug("CATCH ERROR hookAddLeaveComment result: ", err);
    return null;
  }
};

export const hookUpdateLeaveComment = async (Leave) => {
  log.debug(" hookUpdateLeaveComment : ", Leave);
  try {
    const result = await API.graphql({
      query: mutations.updateLeaveComment,
      variables: {
        input: Leave,
      },
    });

    log.debug(" hookUpdateLeaveComment result: ", result);
    return result?.data?.updateLeaveComment;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateLeaveComment result: ", err);
  }
};

export const hookDeleteCommentByLeaveId = async (LeaveId) => {
  log.debug(" hookDeleteCommentByLeaveId  ", LeaveId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteLeaveComment,
      variables: {
        input: { id: LeaveId },
      },
    });

    log.debug(" hookDeleteCommentByLeaveId : ", data);
    if (data !== null) return data?.data?.deleteLeaveComment;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteCommentByLeaveId -- ", e);
    return null;
  }
};

export const hookListCommentsByLeaveID = async (currentLeaveID) => {
  try {
    const data = await API.graphql({
      query: queries.leaveCommentsByLeaveID,
      variables: { leaveID: currentLeaveID },
    });

    log.debug(" hookListCommentsByLeaveID : ", data);

    if (data !== null) return data?.data?.leaveCommentsByLeaveID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCommentsByLeaveID -- ", e);
    return null;
  }
};
