import { useAuthContextData } from "../../customHooks/authMachineHooks";
import useForm from "../../customHooks/useForm";
import log from "loglevel";

export default function CreateEmployeeProfile() {
  const { email } = useAuthContextData();
  const { inputs, handleChange, clearForm, resetForm } = useForm({
    firstName: "",
    middleName: "",
    LastName: "",
    email: email,
    contactNo: "",
    personalEmail: "",
    userType: "",
    uniqueIdentification: "",
    dateOfBirth: "",
    dateOfHire: "",
    yearlyWage: 0,
    workLocation: "",
    emergencyContact1: "",
    emergencyContact1Name: "",
    emergencyContact1Relation: "",
    emergencyContact2: "",
    emergencyContact2Name: "",
    emergencyContact2Relation: "",
    permanentAddress: "",
    currentAddress: "",
    workHoursPerWeek: 0,
    noOfLeaves: 10,
    noOfLeavesTaken: 0,
    noOfLeavesRemaining: 10,
    noOfMedicalLeaves: 12,
    noOfMedicalLeavesTaken: 0,
    noOfMedicalLeavesRemaining: 12,
  });
  const submitEmployeeProfile = () => {
    log.debug("Submit Employee Profile");
  };
  return (
    <>
      <form
        onSubmit={submitEmployeeProfile}
        method="POST"
        className="divide-y-slate-200 mt-6 space-y-8 divide-y"
      >
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-slate-900">Profile</h2>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              First name
            </label>
            <input
              type="text"
              name="firstName"
              onChange={handleChange}
              value={inputs?.firstName}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="middleName"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Middle name
            </label>
            <input
              type="text"
              name="middleName"
              onChange={handleChange}
              value={inputs?.middleName}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="LastName"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Last name
            </label>
            <input
              type="text"
              name="LastName"
              onChange={handleChange}
              value={inputs?.LastName}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="contactNo"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Contact Number
            </label>
            <input
              required
              type="number"
              name="contactNo"
              value={inputs?.contactNo}
              onChange={handleChange}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="sm:col-span-6">
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Official Email
            </label>
            <div className="mt-2 flex rounded-md shadow-sm">
              <input
                type="email"
                name="email"
                value={inputs?.email}
                onChange={handleChange}
                disabled
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Personal email
            </label>
            <div className="mt-2 flex rounded-md shadow-sm">
              <input
                type="email"
                name="personalEmail"
                id="personalEmail"
                onChange={handleChange}
                value={inputs?.personalEmail}
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="uniqueIdentification"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Unique Identification
            </label>
            <input
              required
              type="text"
              name="uniqueIdentification"
              value={inputs?.uniqueIdentification}
              onChange={handleChange}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="dateOfBirth"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Date Of Birth
            </label>
            <input
              required
              type="date"
              name="dateOfBirth"
              value={inputs?.dateOfBirth}
              onChange={handleChange}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="dateOfHire"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Date Of Hire
            </label>
            <input
              required
              type="date"
              name="dateOfHire"
              value={inputs?.dateOfHire}
              onChange={handleChange}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="permanentAddress"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Permanent Address
            </label>
            <textarea
              type="text"
              name="permanentAddress"
              value={inputs?.permanentAddress}
              onChange={handleChange}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </form>
    </>
  );
}
