import React from "react";
import { TypographyLabel } from "../CustomComponentsLibrary/Typography";
import { useAuthContextData } from "../../customHooks/authMachineHooks";

export default function PageHeader({ title, SubHeading, showDropdown }) {
  const { theme } = useAuthContextData();
  console.log("thems @@@", theme);
  return (
    <div className="flex justify-between  w-full text-[22px] font-bold">
      <div>
        <h2 className={`" leading-8 tracking-tight text-left" ${theme?.textCommon}`}>{title}</h2>
        <TypographyLabel>{SubHeading}</TypographyLabel>
      </div>
    </div>
  );
}
