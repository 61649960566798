import { useAuthContextData } from "./customHooks/authMachineHooks";
import { useRoutes } from "react-router";
import {
  everyUserIsEmployeeRoutes,
  notEmployee,
  authRoutes,
} from "./routes/AppRoutesNew";
import { CONST_USER_TYPES } from "./constants/dbconstants";
import log from "loglevel";

export default function MainApp() {
  const { userType, employeeProfile, userProfile } = useAuthContextData();

  let userRoutes = [];

  if (userType.includes(CONST_USER_TYPES.employee))
    userRoutes = [...everyUserIsEmployeeRoutes, ...userRoutes];

  //if
  if (!employeeProfile && userType.length) {
    userRoutes = [...notEmployee];
  }

  if (userRoutes.length === 0) {
    userRoutes = [...authRoutes];
  }
  let routeToReturn = useRoutes(userRoutes);
  log.debug("USER===================> ", userRoutes);
  return routeToReturn;
}
