import {
  ServerIcon,
  Squares2X2Icon,
  ShoppingCartIcon,
  ChartBarIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  ListChecks,
  LocateFixed,
  Library,
  BookMarked,
  DollarSign,
  Calendar,
  ClipboardList,
  Settings,
} from "lucide-react";

export const EmployeeRoutes = {
  name: "My Stuff",
  icon: Squares2X2Icon,
  routes: [
    {
      href: "/",
      name: "Dashboard",
      icon: Squares2X2Icon,
    },
    {
      href: "/employee/tasks",
      name: "Tasks",
      icon: ListChecks,
    },
    {
      href: "/employee/goals",
      name: "Goals",
      icon: LocateFixed,
    },
    {
      href: "/employee/projects",
      name: "Projects",
      icon: Library,
    },
    // {
    //   href: "/employee/projectdashboard",
    //   name: "Projects Dashboard",
    //   icon: ServerIcon,
    // },
    {
      href: "/employee/courses",
      name: "Courses",
      icon: BookMarked,
    },
    {
      href: "/employee/payroll",
      name: "Payroll",
      icon: DollarSign,
    },
    {
      href: "/employee/calendar",
      name: "Calendar",
      icon: Calendar,
    },
    {
      href: "/employee/policy",
      name: "Policy",
      icon: ClipboardList,
    },

    // {
    //   href: "/employee/leaves",
    //   name: "Leaves",
    //   icon: ServerIcon,
    // },

    // {
    //   href: "/employee/timesheet",
    //   name: "Timesheet",
    //   icon: ServerIcon,
    // },
    // {
    //   href: "/employee/meetings",
    //   name: "Meetings",
    //   icon: ServerIcon,
    // },
    {
      href: "/employee/blogs",
      name: "Blogs",
      icon: Squares2X2Icon,
    },
    {
      href: "/employee/settings",
      name: "Settings",
      icon: Settings,
      // subRoutes: [
      //   {
      //     href: "/employee/settings",
      //     name: "My Profile",
      //     icon: ServerIcon,
      //   },
      //   {
      //     href: "/employee/settings/inventory",
      //     name: "Inventory",
      //     icon: ServerIcon,
      //   },
      //   {
      //     href: "/employee/settings/documents",
      //     name: "Upload Docs",
      //     icon: ServerIcon,
      //   },
      // ],
    },
  ],
};

export const ManagerRoutes = {
  name: "",
  icon: ShoppingCartIcon,
  routes: [],
};

export const HRRoutes = {
  name: "",
  icon: ChartBarIcon,
  routes: [],
};

export const HRAdminRoutes = {
  name: "",
  icon: UserIcon,
  routes: [],
};

export const AdminRoutes = {
  name: "",
  icon: UserIcon,
  routes: [],
};

export const SalesRoutes = {
  name: "",
  icon: ShoppingCartIcon,
  routes: [],
};

export const MarketingRoutes = {
  name: "",
  icon: Squares2X2Icon,
  routes: [],
};

export const CandidateRoutes = {
  name: "Candidate",
  icon: UserIcon,
  routes: [
    {
      href: "/candidate",
      name: "My Profile",
      icon: ServerIcon,
      current: false,
    },
    {
      href: "/candidate/jobs",
      name: "Jobs",
      icon: ServerIcon,
      current: false,
    },
    {
      href: "/candidate/myjobs",
      name: "My Job Applications",
      icon: ServerIcon,
      current: false,
    },
    {
      href: "/candidate/myoffers",
      name: "My Offer",
      icon: ServerIcon,
      current: false,
    },
  ],
};
export const FinanceAdminRoutes = {
  name: "",
  icon: ShoppingCartIcon,
  routes: [],
};
