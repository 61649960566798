import React from "react";
import { TabPageLink } from "../../components/common/TabPageLink";

const tabs = [
  {
    name: "Goals",
    path: "/employee/goals",
    subHead: "Overview and tracking of all your goals",
  },

  {
    name: "Achivements",
    path: "/employee/goals/achivements",
    subHead: "Overview and tracking of all your goals",
  },
];

export function EmployeeGoalsLayout() {
  console.log("RoutestoGen", tabs);
  return (
    <>
      <TabPageLink tabs={tabs} />
    </>
  );
}
