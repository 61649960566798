import React, { useMemo, useState, useEffect } from "react";

import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { useParams } from "react-router";
import log from "loglevel";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../CustomComponentsLibrary/Accordion";
import { Card, CardContent } from "../../CustomComponentsLibrary/Card";
import { Button } from "../../CustomComponentsLibrary/Button";
import { hookGetTopicsByLessonId } from "../../../hooks/topicHooks";
import {
  ArrowLeft,
  CheckCircle2,
  ChevronLeft,
  ChevronRight,
  DownloadCloud,
  FileText,
  Youtube,
} from "lucide-react";
import { hookGetTopicResourcesByTopicId } from "../../../hooks/topicResourceHooks";
import ResourceView from "./ResourceView";
import { Link, useNavigate } from "react-router-dom";
import { useActor } from "@xstate/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export default function Lessons() {
  const { courses, courseToBeAdded } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const employeeMachine = useEmployeeContextService();
  const params = useParams();
  const [activeItem, setActiveItem] = useState(null);
  const [topicDetailsWithResources, setTopicsWithResources] = useState([]);
  const [resourceShow, setResourceShow] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [lessonsShow, setLessonsShow] = useState(true);
  const [state] = useActor(employeeMachine);
  const navigate = useNavigate();
  const navigateToCourse = () => {
    navigate(`/employee/courses`);
  };

  log.debug("courses::Lessons", courses, params);

  const foundCourse = useMemo(() => {
    if (!params?.courseId) return null;
    const filtered = courses?.filter(
      (course) => course?.courseID === params?.courseId
    );
    return filtered[0];
  }, [params?.courseId, courses]);
  log.debug("foundCourse::Lessons", courseToBeAdded);
  const [progress, setProgress] = useState(foundCourse?.progress);
  const handleAccordionItemClick = (itemId) => {
    getTopicDetailsByLessonId(itemId);
    setActiveItem(itemId === activeItem ? null : itemId);
    setResourceShow(false);
    setSelectedResource(null);
  };

  const getTopicDetailsByLessonId = async (selectedLessonId) => {
    const fetchedTopics = await hookGetTopicsByLessonId(selectedLessonId);
    log.debug("fetchedTopics with getTopicDetailsByLessonId::", fetchedTopics);

    if (fetchedTopics) {
      try {
        const topicDetailsWithResources = await Promise.all(
          fetchedTopics.map(async (topic) => {
            const topicResources = await hookGetTopicResourcesByTopicId(
              topic.id
            );
            return topicResources;
          })
        );

        const flattenedTopicResources = topicDetailsWithResources.flat();

        log.debug("flattenedTopicResources,", flattenedTopicResources);

        setTopicsWithResources(flattenedTopicResources);

        return topicDetailsWithResources;
      } catch (error) {
        log.error("Error fetching topic details with resources:", error);
        throw error;
      }
    }

    log.debug("getTopicDetailsByLessonId::", fetchedTopics);
  };

  const handleResourceClick = async (selectedResource, index) => {
    setResourceShow(true);
    log.debug("index", index);
    const dataTosend = {
      ...selectedResource,
      index,
    };
    setSelectedResource(dataTosend);
  };

  useEffect(() => {
    if (
      state.matches({
        COURSES: "WAIT_LOAD_COURSES",
      })
    ) {
      navigateToCourse();
    }
  }, [state]);
  const courseProgrese = async () => {
    const progressData = {
      id: foundCourse?.id,
      progress: progress,
    };
    log.debug("progressData", foundCourse?.id);
    employeeMachine.send({
      type: "update_course",
      data: {
        courseToBeAdded: progressData,
      },
    });
  };

  return (
    <div className="flex bg-main-background  flex-col">
      <div className={`"font-bold p-4" ${theme?.textCommon}`}>
        <h1 className="text-3xl flex flex-row  mb-4 p-1">
          <span className=" text-muted-foreground">
            <Link to={`/employee/courses`}>
              <Button
                variant="ghost"
                className="bg-transpernt hover:bg-transparent"
              >
                <ArrowLeft className={`${theme?.textCommon}`} />
              </Button>
            </Link>
          </span>
          <span>{foundCourse?.name}</span>
        </h1>

        <div className="text-lg text-gray-400 ml-5 sm:w-[430px] w-[290px] font-thin flex flex-row mt-1 justify-between flex-wrap">
          <span>By - {foundCourse?.instructor}.</span>
          <span>|</span>
          <span>{foundCourse?.lessons?.items?.length} lessons </span>
          <span> {foundCourse?.hours} total hours</span>
        </div>
        <div className="text-lg mt-2 ml-5 text-gray-400  font-thin">
          {foundCourse?.description}
        </div>
      </div>
      {/* Container for left and right sections */}
      <div className="flex flex-col md:flex-row mx-auto mt-2 w-full">
        {/* Left section (small section on top-left) */}
        {lessonsShow ? (
          <div className="bg-main-background text-white p-1 w-full md:w-2/5 lg:w-2/5 xl:w-2/5 mb-2 md:mb-0">
            <Card className="bg-main-background w-auto border-2 border-light hover:border-light shadow-xl">
              <CardContent>
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem>
                    <div className="flex flex-row w-full justify-between">
                      <span className="text-black text-lg font-bold ">
                        Lessons -
                      </span>
                      <span className="text-lg font-bold">
                        <Button
                          variant="link"
                          className={`"font-bold" ${theme?.textCommon} ${theme?.textLarge}`}
                          onClick={() => setLessonsShow(false)}
                        >
                          Hide <ChevronLeft />
                        </Button>
                      </span>
                    </div>
                  </AccordionItem>
                  {foundCourse?.lessons?.items?.map((item, index) => (
                    <AccordionItem
                      key={item.id}
                      value={item.id}
                      active={activeItem === item?.id}
                      className="text-black text-lg  "
                    >
                      <div className="flex flex-row justify-between ">
                        <div className="  w-3/5 ">
                          <AccordionTrigger
                            onClick={() => handleAccordionItemClick(item?.id)}
                          >
                            <span className=" text-base text-black">
                              {item?.name}
                            </span>
                          </AccordionTrigger>
                        </div>
                        {log.debug("item_value", foundCourse)}
                        <div className="text-sm">
                          <Button
                            variant="link"
                            className={`${theme?.textCommon}`}
                            onClick={() => {
                              employeeMachine.send({
                                type: "course_crud",
                                data: {
                                  selectedLesson: item,
                                  selectedTopicResource: null,
                                  selectedCourse: foundCourse,
                                  actionForCourseCRUD: "UPDATE",
                                },
                              });
                            }}
                          >
                            {foundCourse.completedLessons?.some(
                              (present) => present == item?.id
                            )
                              ? "Mark as Completed"
                              : "Mark as Incomplete"}
                          </Button>
                        </div>
                      </div>
                      <AccordionContent>
                        <p className="text-sm text-black">
                          {topicDetailsWithResources?.map((topic, index) => (
                            <div className="flex text- justify-between flex-row">
                              <div className="flex flex-row">
                                {log.debug("topic", topic)}
                                <span className="p-1">
                                  {selectedResource?.index === index ? (
                                    <CheckCircle2 />
                                  ) : (
                                    <>
                                      {topic?.type === "YOUTUBE" && <Youtube />}
                                      {topic?.type === "LINK" && <FileText />}
                                      {topic?.type === "S3STORAGE" && (
                                        <DownloadCloud />
                                      )}
                                    </>
                                  )}
                                </span>
                                <div key={index} className="list-decimal">
                                  <Button
                                    variant="link"
                                    className={`"hover:bg-transparent text-md " ${
                                      selectedResource?.index === index
                                        ? "text-gray-400"
                                        : "text-black"
                                    }`}
                                    onClick={() =>
                                      handleResourceClick(topic, index)
                                    }
                                  >
                                    {`${index + 1}. ${topic?.name}`}{" "}
                                  </Button>
                                </div>
                              </div>
                              <div>
                                <Button
                                  variant="link"
                                  className={`${theme?.textCommon}`}
                                  onClick={() => {
                                    employeeMachine.send({
                                      type: "course_crud",
                                      data: {
                                        selectedCourse: foundCourse,
                                        selectedTopicResource: topic,
                                        actionForCourseCRUD: "UPDATE",
                                      },
                                    });
                                  }}
                                >
                                  {foundCourse.completedTopicResources?.some(
                                    (present) => present == topic?.id
                                  )
                                    ? "Mark as Completed"
                                    : "Mark as Incomplete"}
                                </Button>
                              </div>
                            </div>
                          ))}
                        </p>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div className="bg-main-background mx-auto text-white p-3 w-28">
            <Button
              onClick={() => setLessonsShow(true)}
              className={`"bg-main-background h-10 font-bold" ${theme?.textCommon} ${theme?.textLarge}`}

            >
              Show <ChevronRight/>
            </Button>
          </div>
        )}

        {/* Right section (larger section on the right) */}
        <div className="bg-main-background rounded-md p-1 mx-3 flex-1">
          {/* Using Tailwind for styling */}
          {resourceShow ? (
            <ResourceView item={selectedResource ? selectedResource : {}} />
          ) : null}

          {/* Display links based on the selected index */}
          {selectedResource !== null && (
            <div className="mt-4 flex flex-row shadow-xl py-2 rounded-lg border-2 border-light justify-between">
              {/* Rendering the Previous Item Link */}
              {selectedResource?.index > 0 && (
                <Button
                  variant="link"
                  className="text-black underline underline-offset-8"
                  onClick={() => {
                    const index = selectedResource?.index - 1;
                    const prevItem = topicDetailsWithResources[index];
                    const data = {
                      ...prevItem,
                      index,
                    };
                    setSelectedResource(data);
                  }}
                >
                  <ChevronLeft />
                  {topicDetailsWithResources[selectedResource?.index - 1]?.name}
                </Button>
              )}

              {/* Rendering the Next Item Link */}
              {selectedResource?.index <
                topicDetailsWithResources?.length - 1 && (
                <Button
                  variant="link"
                  className="text-black underline underline-offset-8"
                  onClick={() => {
                    const index = selectedResource?.index + 1;
                    const nextItem = topicDetailsWithResources[index];
                    const data = {
                      ...nextItem,
                      index,
                    };
                    setSelectedResource(data);
                  }}
                >
                  <ChevronRight />
                  {topicDetailsWithResources[selectedResource?.index + 1]?.name}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
