import React, { useEffect, useMemo, useState } from "react";
import log from "loglevel";
import {
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../../hooks/employeeMachineHooks";
import {
  CONST_EXPENCES_STATUS,
  CONST_USER_TYPES,
} from "../../../../constants/dbconstants";
import { FriendlyName } from "../../../../lib/friendlyName";
import { Download, Edit2Icon, Trash } from "lucide-react";
import { Button } from "../../../CustomComponentsLibrary/Button";
import FormatDateAndTime from "../../../common/FormatDateAndTime";
import { DataTableColumnHeader } from "../../../CustomComponentsLibrary/data-table-column-header";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import { TruncateDescription } from "../../../common/TruncateDescription";
import PopoverCard from "../../../common/PopoverCard";
import PageHeader from "../../../common/PageHeader";
import { toast } from "../../../CustomComponentsLibrary/UseToast";
import { GetSignedURL } from "../../../../hooks/s3Hooks";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
import Dropdown from "../../../common/Dropdown";
import { sortByKeyAlphabetical } from "../../../common/SortByName";
export default function ReimburseList() {
  const employeeMachineService = useEmployeeContextService();
  const { reimbursements, myEmployeeProfile, employeeList, userID } =
    useEmployeeContextData();
  const { theme } = useAuthContextData();
  const [currentUserDetails, setCurrentUserDetails] = useState({
    id: userID,
    name: "self",
  });
  var sorted_list = reimbursements
    .sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    })
    .reverse();
  log.debug("SORTED_LIST:reimbursements", sorted_list);

  const labels = [
    {
      value: CONST_EXPENCES_STATUS.expencseStatusRejected,
      label: "Rejected",
    },
    {
      value: CONST_EXPENCES_STATUS.expencseStatusOnhold,
      label: "On Hold",
    },
    {
      value: CONST_EXPENCES_STATUS.expencseStatusCreated,
      label: "Created",
    },
    {
      value: CONST_EXPENCES_STATUS.expencseStatusInprocess,
      label: "In Process",
    },
    {
      value: CONST_EXPENCES_STATUS.expencseStatusApproved,
      label: "Approved",
    },
    {
      value: CONST_EXPENCES_STATUS.expencseStatusPaymentDone,
      label: "Paid",
    },
  ];

  const DownloadReimburseSlip = async (s3key) => {
    if (!s3key) {
      toast({
        variant: "destructive",
        title: "Failed",
        description: "Failed to download reimbursement slip",
      });
    }
    let fileToView;
    if (s3key) {
      fileToView = s3key;
    }
    console.log("fileToView::", fileToView);
    if (fileToView) {
      let signedURL = await GetSignedURL(fileToView);
      console.log("GetSignedURL :  URL = ", signedURL);
      window.open(signedURL);
    }
  };
  useEffect(() => {
    if (currentUserDetails?.id) {
      employeeMachineService.send({
        type: "reload_reimbursements",
        data: {
          userID: currentUserDetails?.id,
        },
      });
    }
  }, [currentUserDetails]);
  const columns = [
    {
      header: "Title",
      accessorKey: "title",
    },
    {
      header: "Description",
      accessorKey: "content",
      cell: ({ row }) => (
        <>{<TruncateDescription content={row?.getValue("content")} />}</>
      ),
    },
    {
      header: "Date",
      accessorKey: "date",
      enableSorting: true,
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row?.original?.date}
          format={"DD/MM/YYYY"}
        />
      ),
    },
    {
      accessorKey: "dueDate",
      enableSorting: true,
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row.original?.dueDate}
          format={"DD/MM/YYYY"}
        />
      ),
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Due Date" />
      ),
    },

    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => <FriendlyName status={row?.getValue("status")} />,
      filterFn: (row, id, value) => {
        return value instanceof Array && value?.includes(row.getValue(id));
      },
    },
    {
      header: "Actions",
      accessorKey: "",
      cell: ({ row }) => (
        <div className="flex flex-row gap-x-2">
          <PopoverCard
            title={"Edit Reimbursements"}
            description={"Click this button to edit the reimbursement details."}
          >
            <Button
              disabled={
                row.original?.status ===
                CONST_EXPENCES_STATUS.expencseStatusPaymentDone
              }
              size="sm"
              variant="link"
              className="mr-2"
              onClick={() => {
                employeeMachineService.send({
                  type: "reimbursement_crud",
                  data: {
                    actionForReimbursementCRUD: "EDIT",
                    selectedReimbursement: row?.original,
                  },
                });
              }}
            >
              <Edit2Icon className="h-4" />
            </Button>
          </PopoverCard>
          <PopoverCard
            title={"Delete Reimbursements"}
            description={"Click this button to delete the reimbursement"}
          >
            <Button
              disabled={
                row.original?.status ===
                CONST_EXPENCES_STATUS.expencseStatusPaymentDone
              }
              size="sm"
              variant="link"
              onClick={() => {
                employeeMachineService.send({
                  type: "reimbursement_crud",
                  data: {
                    actionForReimbursementCRUD: "DELETE",
                    selectedReimbursement: row.original,
                  },
                });
              }}
            >
              <Trash className="h-4" />
            </Button>
          </PopoverCard>
          <PopoverCard
            title={"Download Reimbursements"}
            description={"Click this button to download the reimbursement slip"}
          >
            <Button
              disabled={
                row.original?.status ===
                CONST_EXPENCES_STATUS.expencseStatusPaymentDone
              }
              size="sm"
              variant="link"
              onClick={() => {
                DownloadReimburseSlip(row.original?.s3key);
              }}
            >
              <Download className="h-4" />
            </Button>
          </PopoverCard>
        </div>
      ),
    },
  ];
  const filteredTeamMembers = useMemo(() => {
    if (
      employeeList &&
      myEmployeeProfile?.employees &&
      myEmployeeProfile?.userType
        ?.split(",")
        ?.some((type) =>
          [CONST_USER_TYPES.manager, CONST_USER_TYPES.teamLead]?.includes(
            type?.trim()
          )
        )
    ) {
      const filteredMembers = employeeList?.filter((member) =>
        myEmployeeProfile?.employees?.includes(member?.id)
      );
      const currentUserInfo = {
        id: myEmployeeProfile?.cognitoId,
        title: `${myEmployeeProfile?.firstName} ${myEmployeeProfile?.LastName}`,
      };
      if (
        !filteredMembers?.some((member) => member?.id === currentUserInfo?.id)
      ) {
        filteredMembers?.unshift(currentUserInfo);
      }

      return filteredMembers?.map((member) => ({
        id: member.id,
        title: member.title,
      }));
    }
    return null;
  }, [employeeList, myEmployeeProfile?.employees]);
  if (filteredTeamMembers)
    log.debug("filteredTeamMembers", filteredTeamMembers);
  // below useEffect hook use for refreshing data of current login user while switching tabs
  useEffect(() => {
    return () => {
      employeeMachineService.send({
        type: "reload_reimbursements",
        data: {
          userID: currentUserDetails?.id,
        },
      });
    };
  }, []);
  return (
    <div className="m-3">
      <div className="sm:flex">
        <div className="sm:flex-auto">
          <PageHeader
            title={"Reimbursements"}
            SubHeading="A record of all reimbursement claims."
          />
        </div>
        <div className="sm:mt-0 sm:ml-16 sm:flex-none">
          {(myEmployeeProfile?.userType?.includes("TEAMLEAD") ||
            myEmployeeProfile?.userType?.includes("MANAGER")) && (
            <div className="mb-20">
              <Dropdown
                list={sortByKeyAlphabetical(filteredTeamMembers, "title")}
                setSelected={setCurrentUserDetails}
                selected={currentUserDetails}
                className="w-auto"
                placeholder="Select Team Member"
                required
                nameField="title"
              />
            </div>
          )}

          <Button
            type="button"
            variant=""
            className={`"" ${theme?.background} ${theme?.textColor}`}
            onClick={() => {
              employeeMachineService.send({
                type: "reimbursement_crud",
                data: {
                  actionForReimbursementCRUD: "ADD",
                },
              });
            }}
          >
            Add Reimbursement
          </Button>
        </div>
      </div>
      {(myEmployeeProfile?.userType?.includes("TEAMLEAD") ||
        myEmployeeProfile?.userType?.includes("MANAGER")) && (
        <div>
          Name:{" "}
          <span className="text-red-500">
            {currentUserDetails?.title
              ? currentUserDetails?.title
              : currentUserDetails?.name}
          </span>
        </div>
      )}
      <div className="mt-8 flow-root shadow-md sm:rounded-lg border-t border-t-zinc-200">
        <div className="relative overflow-x-auto m-5 font-semibold text-sm ">
          <DataTable
            columns={columns}
            data={reimbursements}
            filterableColumns={[
              {
                id: "status",
                title: "Filter by Status",
                options: labels.map(({ value, label }) => ({
                  label: label,
                  value: value,
                })),
              },
            ]}
            // searchableColumns={[{ id: "title", title: "Title" }]}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 2,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 3,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 4,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 5,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
