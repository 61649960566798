import { CONST_LEAVE_STATUS } from "../constants/dbconstants";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { API } from "aws-amplify";
import log from "loglevel";
import { getWorkingDaysOfWeek } from "../lib/dateService";
import moment from "moment";

export const hookListLeavesByUserId = async (cognitoId) => {
  log.debug("hookListLeavesByUserId:cognitoId", cognitoId);
  try {
    const data = await API.graphql({
      query: queries.LeavesByUserID,
      variables: {
        sortDirection: "DESC",
        userID: cognitoId,
      },
    });

    log.debug(" hookListLeavesByUserId : ", data);
    if (data !== null) return data?.data?.LeavesByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListLeavesByUserId -- ", e);
    return null;
  }
};
export const hookListLeavesByUserIdAndYear = async (
  cognitoId,
  firstDay,
  lastDay
) => {
  log.debug("hookListLeavesByUserIdAndYear", cognitoId);
  try {
    const data = await API.graphql({
      query: queries.LeavesByUserID,
      variables: {
        sortDirection: "DESC",
        userID: cognitoId,
        date2: { between: [firstDay, lastDay] },
      },
    });

    log.debug(" hookListLeavesByUserIdAndYear : ", data);
    if (data !== null) return data?.data?.LeavesByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListLeavesByUserIdAndYear -- ", e);
    return null;
  }
};

export const hookListLeaveStatusByUserId = async (cognitoId) => {
  try {
    const data = await API.graphql({
      query: customQueries.leavesStatusByUserID,
      variables: {
        sortDirection: "DESC",
        userID: cognitoId,
      },
    });

    log.debug(" hookListLeaveStatusByUserId : ", data);
    if (data !== null) return data?.data?.LeavesByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListLeaveStatusByUserId -- ", e);
    return null;
  }
};

export const hookListSubmittedLeaves = async () => {
  try {
    const data = await API.graphql({
      query: queries.listLeaves,

      variables: {
        sortDirection: "DESC",
        limit: 25,
      },
    });

    log.debug(" hookListLeaves : ", data);
    const allLeaves = data?.data?.listLeaves.items;
    if (allLeaves) {
      return allLeaves.filter(
        (leave) => leave.status === CONST_LEAVE_STATUS.leaveStatusSubmitted
      );
    } else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListSubmittedLeaves -- ", e);
    return null;
  }
};

export const hookListEmployeeLeavesByMonth = async ({ month, cognitoId }) => {
  const monthStr = month.substring(0, 7);

  log.debug(" hookListEmployeeLeavesByMonth monthStr: ", monthStr);

  try {
    const data = await API.graphql({
      query: queries.LeavesByUserID,
      variables: {
        userID: cognitoId,
      },
    });
    // log.debug(" hookListEmployeeLeavesByMonth : ", data);

    let monthLeaves = [];
    const leaves = data?.data?.LeavesByUserID.items;
    monthLeaves = leaves.filter((leave) => {
      const monthStrMatchingLeaves = leave.date.filter((dateStr) =>
        dateStr.includes(monthStr)
      );
      log.debug(
        " hookListEmployeeLeavesByMonth monthStrMatchingLeaves: ",
        monthStrMatchingLeaves
      );
      log.debug(
        " hookListEmployeeLeavesByMonth monthStrMatchingLeaves.length: ",
        monthStrMatchingLeaves.length
      );

      return monthStrMatchingLeaves.length > 0
        ? /*  && Comment for now CONST_LEAVE_STATUS.leaveStatusApproved */
          1
        : 0;
    });
    if (data !== null) {
      log.debug(" hookListEmployeeLeavesByMonth : ", monthLeaves);

      return monthLeaves;
    } else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListLeavesByUserId -- ", e);
    return null;
  }
};

export const hookListEmployeeLeavesOfCurrentWeek = async (orgId) => {
  log.debug(" hookListEmployeeLeavesOfCurrentWeek  ", orgId);

  try {
    const data = await API.graphql({
      query: customQueries.teramLeavesByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" hookListEmployeeLeavesOfCurrentWeek : ", data);

    let weekLeaves = [];
    const leaves = data?.data?.LeavesByOrganizationID?.items || [];
    const daysOfWeek = getWorkingDaysOfWeek("currentWeek");
    const shortDaysOfWeek = daysOfWeek.map((day) =>
      moment(day).format("YYYY-MM-DD")
    );

    weekLeaves = leaves.map((leave) => {
      // log.debug("Leaves Mapping,", leave);

      let isLeaveOfCurrentWeek = false;
      shortDaysOfWeek.forEach((weekDay) => {
        // log.debug("Weekday foeeach,", weekDay);
        if (!leave.date) isLeaveOfCurrentWeek = false;
        if (leave.date.includes(weekDay)) {
          // log.debug("Found Leave Week", weekDay, leave);
          isLeaveOfCurrentWeek = true;
        }
        if (isLeaveOfCurrentWeek) return;
      });
      if (isLeaveOfCurrentWeek) return leave;
    });

    // log.debug("WeekLeaves", weekLeaves, shortDaysOfWeek);
    weekLeaves = weekLeaves.filter((leave) => leave);
    if (data !== null) {
      log.debug(" hookListEmployeeLeavesOfCurrentWeek : ", weekLeaves);

      return weekLeaves;
    } else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeLeavesOfCurrentWeek -- ", e);
    return null;
  }
};

export const hookAddLeave = async (Leave) => {
  log.debug(" hookAddLeave : ", Leave);

  try {
    const result = await API.graphql({
      query: mutations.createLeave,
      variables: {
        input: Leave,
      },
    });

    log.debug(" hookAddLeave result: ", result);

    return result?.data?.createLeave;
  } catch (err) {
    log.debug("CATCH ERROR hookAddLeave result: ", err);
    return null;
  }
};

export const hookUpdateLeave = async (Leave) => {
  log.debug(" hookUpdateLeave : ", Leave);
  try {
    const result = await API.graphql({
      query: mutations.updateLeave,
      variables: {
        input: Leave,
      },
    });

    log.debug(" hookUpdateLeave result: ", result);
    return result?.data?.updateLeave;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateLeave result: ", err);
  }
};

export const hookListLeaves = async (cognitoId) => {
  try {
    const data = await API.graphql({
      query: queries.listLeaves,

      variables: {
        sortDirection: "DESC",
        limit: 100,
      },
    });

    log.debug(" hookListLeaves : ", data);

    if (data !== null) return data?.data?.listLeaves.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListLeavesByUserId -- ", e);
    return null;
  }
};

export const hookDeleteLeavesByLeaveId = async (leaveId) => {
  log.debug(" hookDeleteLeavesByLeaveId : ", leaveId);
  try {
    const result = await API.graphql({
      query: mutations.deleteLeave,
      variables: {
        input: { id: leaveId },
      },
    });

    log.debug(" hookDeleteLeavesByLeaveId result: ", result);
    return result;
  } catch (err) {
    log.debug("CATCH ERROR hookDeleteLeavesByLeaveId result: ", err);
  }
};

export const hookListEmployeeLeavesByOrgID = async (organizationID) => {
  try {
    const data = await API.graphql({
      query: queries.LeavesByOrganizationID,

      variables: {
        organizationID: organizationID,
        limit: 200,
      },
    });

    log.debug(" hookListEmployeeLeavesByOrgID : ", data);

    if (data !== null) return data?.data?.LeavesByOrganizationID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeLeavesByOrgID -- ", e);
    return null;
  }
};
export const hookGetEmployeeLeaveHistoryByIdAndYear = async (userId, year) => {
  log.debug(" hookGetEmployeeLeaveHistoryByIdAndYear ", userId, year);
  log.debug("typeofDataYear", typeof year);

  try {
    const data = await API.graphql({
      query: customQueries.getEmployeeLeaveHistoryByUserIdAndYear,
      variables: {
        userID: userId,
        year: year,
      },
    });

    log.debug(" hookGetEmployeeLeaveHistoryByIdAndYear: ", data);

    if (data !== null) return data?.data?.EmployeeLeaveHistoryByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeLeaveHistoryByIdAndYear -- ", e);
    return null;
  }
};
export const hookGetEmployeeLeavesOverviewById = async (userId) => {
  log.debug(" hookGetEmployeeLeavesOverviewById ", userId);

  try {
    const data = await API.graphql({
      query: customQueries.getEmployeeLeavesOverviewByUserID,
      variables: {
        cognitoId: userId,
      },
    });

    log.debug(" hookGetEmployeeLeavesOverviewById: ", data);

    if (data !== null) return data?.data?.getEmployee;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeLeavesOverviewById -- ", e);
    return null;
  }
};
