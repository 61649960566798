import { Outlet } from "react-router";
import { EmployeeMachineContextProvider } from "../context/employeeMachineContext";
import CommonLayout from "../components/layout/CommonLayout";

export default function EmployeeWithContextLayout() {
  return (
    <CommonLayout>
      <EmployeeMachineContextProvider>
        {/* Main content */}
        <Outlet />
      </EmployeeMachineContextProvider>
    </CommonLayout>
  );
}
