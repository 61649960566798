import { Fragment, useEffect, useMemo, useState } from "react";
import log from "loglevel";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  useEmployeeContextData,
  useEmployeeContextState,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { InventoryComments } from "../../common/InventoryComments";

import { InventoryCard } from "../../common/InventoryCard";
import PageHeader from "../../common/PageHeader";

function Popup({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none bor fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto">
                  <div className="flex h-full mt-20 pb-10 flex-col overflow-y-scroll bg-background rounded-md border-2 border-input py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className=" font-semibold leading-6 text-forground text-lg">
                          Inventory Comments:
                        </Dialog.Title>
                      </div>
                    </div>
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function InventoryList() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const handleOpenPopup = (inventory) => {
    setIsOpen(true);
    setSelectedInventory(inventory);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const { inventoryCrud } = useEmployeeContextState();
  log.debug("inventory_crud:::", inventoryCrud);
  const employeeMachineService = useEmployeeContextService();
  const { inventories, myEmployeeProfile } = useEmployeeContextData();
  log.debug(inventories);
  log.debug("inventory_crud:::mm", myEmployeeProfile);

  useEffect(() => {
    employeeMachineService.send({
      type: "load_inventories",
      data: {
        userID: myEmployeeProfile?.cognitoId,
      },
    });
  }, [myEmployeeProfile?.cognitoId]);
  return (
    <div className="h-full md:rounded-sm lg:rounded-md">
      <div className="felx flex-col  w-full">
        <div className="m-2 mt-3">
          <PageHeader
            title="Inventory"
            SubHeading="Overview of your inventory"
          />
        </div>
      </div>
      <div className="mt-4 m-2 min-h-screen grid md:grid-cols-3 sm:grid-cols-1 justify-center shadow-md border-t border-zinc-100">
        {inventories.map((inventory, index) => (
          <div className="m-5" key={inventory.id}>
            <InventoryCard
              inventory={inventory}
              cardClickAction={handleOpenPopup}
            />
          </div>
        ))}
      </div>
      <div className="">
        {isOpen && (
          <Popup isOpen={isOpen} onClose={handleClosePopup} className="">
            <InventoryComments
              inventoryId={selectedInventory?.id}
              inventory={selectedInventory || []}
            />
          </Popup>
        )}
      </div>
    </div>
  );
}
