import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddEmployeeInventory = async (employeeInventory) => {
  log.debug(" hookAddEmployeeInventory : ", employeeInventory);

  try {
    const result = await API.graphql({
      query: mutations.createInventory,
      variables: {
        input: employeeInventory,
      },
    });

    log.debug(" hookAddEmployeeInventory result: ", result);

    return result?.data?.createInventory;
  } catch (err) {
    log.debug("CATCH ERROR hookAddEmployeeInventory result: ", err);
    return null;
  }
};

export const hookUpdateEmployeeEnventory = async (employeeInventory) => {
  log.debug(" hookUpdateEmployeeEnventory : ", employeeInventory);
  try {
    const result = await API.graphql({
      query: mutations.updateInventory,
      variables: {
        input: employeeInventory,
      },
    });

    log.debug(" hookUpdateEmployeeEnventory result: ", result);
    return result?.data?.updateInventory;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateEmployeeEnventory result: ", err);
  }
};

export const hookDeleteEmployeeInventoryById = async (inventoryId) => {
  log.debug(" hookDeleteEmployeeInventoryById  ", inventoryId);
  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteInventory,
      variables: {
        input: { id: inventoryId },
      },
    });

    log.debug(" hookDeleteEmployeeInventoryById : ", data);
    if (data !== null) return data?.data?.deleteInventory;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteEmployeeInventoryById -- ", e);
    return null;
  }
};

export const hookGetAllInventory = async () => {
  try {
    const data = await API.graphql({
      query: queries.listInventories,
      variables: {},
    });
    log.debug(" hookGetEmployeeInventory : ", data);
    if (data !== null) return data?.data?.listInventories.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeInventory -- ", e);
    return null;
  }
};

export const hookGetInventoriesByOrganizationID = async (orgId) => {
  log.debug("inventoriesByOrganizationID OrgId", orgId);
  try {
    const data = await API.graphql({
      query: queries.InventoriesByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" inventoriesByOrganizationID : ", data);
    if (data !== null) return data?.data?.InventoriesByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR inventoriesByOrganizationID -- ", e);
    return null;
  }
};

export const hookGetEmployeeInventory = async (userID) => {
  try {
    const data = await API.graphql({
      query: queries.InventoryByUserID,
      variables: {
        sortDirection: "DESC",
        userID: userID,
      },
    });

    log.debug(" hookGetEmployeeInventory : ", data);
    if (data !== null) return data?.data?.InventoryByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeInventory -- ", e);
    return null;
  }
};
