import React, { useEffect, useState } from "react";
import log from "loglevel";
import {
  hookAddGoalComment,
  hookUpdateGoalComment,
  hookDeleteCommentByGoalId,
  hookListCommentsByGoalID,
} from "../../hooks/hookGoalComments";
import moment from "moment";
import { useEmployeeContextData } from "../../hooks/employeeMachineHooks";
import useForm from "../../customHooks/useForm";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../CustomComponentsLibrary/Card";
import { ScrollArea } from "../CustomComponentsLibrary/ScrollArea";
import { Textarea } from "../CustomComponentsLibrary/Textarea";
import { Button } from "../CustomComponentsLibrary/Button";
import { Send, DeleteIcon, Trash, Edit } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/CustomComponentsLibrary/AlertDailog";
import MDEditor, { commands } from "@uiw/react-md-editor";

export default function GoalComments({ goalId }) {
  const [comment2Add, setComment2Add] = useState("");
  const [comment2Save, setComment2Save] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [comments, setComments] = useState([]);
  const { employeeProfile } = useAuthContextData();
  const [value, setValue] = useState("");
  const { inputs, handleChange, clearForm, resetForm } = useForm({
    content: "",
  });
  function handleSaveCommentChange(e) {
    log.debug("handleSaveCommentChange", e);
    setComment2Save(e.target.value);
  }

  log.debug("submitGoalComment onclickAdd b4 GoalCommentObj", employeeProfile);
  const submitGoalComment = async (e) => {
    e.preventDefault();
    const GoalCommentObj = {
      content: value,
      addedByName: employeeProfile?.firstName,
      addedBy: employeeProfile?.cognitoId,
      dateTime: new Date(),
      goalID: goalId,
    };
    log.debug("submitGoalComment onclickAdd b4 GoalCommentObj", GoalCommentObj);
    if (value) {
      const comment = await hookAddGoalComment(GoalCommentObj);
      log.debug("Added comment", comment);
      if (comment) {
        setComments((prevComments) => [...prevComments, comment]);
        clearForm();
        setValue("");
      }
    }
  };
  const saveGoalComment = async (e) => {
    if (comment2Save) {
      log.debug("saveGoalComment ", comment2Save);

      const GoalCommentObj = {
        content: comment2Save,
        dateTime: new Date(),
        id: selectedComment.id,
      };
      log.debug("saveGoalComment  b4 GoalCommentObj", GoalCommentObj);

      const updatedComment = await hookUpdateGoalComment(GoalCommentObj);
      setComments((prevComments) => [
        ...prevComments?.filter((comment) => comment.id !== updatedComment.id),
        updatedComment,
      ]);
      log.debug("call addcommenthook now");
    }
    setSelectedComment(null);
    setValue("");
  };

  useEffect(() => {
    log.debug(" selectedComment ", selectedComment);
  }, [comment2Save, inputs?.content]);

  const deleteGoalComment = async (commentid) => {
    const deletegoalcomment = await hookDeleteCommentByGoalId(commentid);
    if (deletegoalcomment) {
      const FilterdComments = comments?.filter(
        (comment) => comment.id !== deletegoalcomment.id
      );
      setComments(FilterdComments);
    }
  };
  useEffect(() => {
    log.debug(" selectedComment ", selectedComment);
  }, [comment2Save, inputs?.content]);

  useEffect(() => {
    async function getGoalComments() {
      const result = await hookListCommentsByGoalID(goalId);
      if (result) setComments(result);
    }
    if (goalId) {
      getGoalComments();
    }
  }, [goalId]);
  return (
    <div>
      {comments.length ? (
        <Card>
          <CardHeader>
            <CardTitle>Comments</CardTitle>
            <CardDescription>
              Add what you want to be notified about.
            </CardDescription>
          </CardHeader>
          <ScrollArea className="h-80 px-2">
            <CardContent className="w-full">
              <ScrollArea orientation="horizontal">
                <div className="flex rounded-md p-1">
                  <div className="flex-row w-full">
                    {comments?.map((comment, index) => (
                      <div key={comment.content + index}>
                        {selectedComment &&
                        selectedComment?.id === comment.id ? (
                          <div className="flex-col">
                            <div className="w-full">
                              <MDEditor
                                value={String(comment2Save)}
                                onChange={setComment2Save}
                                preview="edit"
                                height={300}
                              />
                            </div>
                            <Button
                              size="xs"
                              onClick={() => {
                                saveGoalComment();
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              size="xs"
                              onClick={() => {
                                setSelectedComment(null);
                              }}
                            >
                              cancel
                            </Button>
                          </div>
                        ) : (
                          <>
                            {comment?.addedByName} {" : "}
                            <MDEditor
                              preview="preview"
                              hideToolbar="true"
                              value={String(comment?.content)}
                              height={150}
                            />
                            <div className=" flex flex-row text-xs font-thin p-1">
                              {moment(comment?.dateTime).format("llll")}

                              {employeeProfile?.cognitoId ===
                              comment.addedBy ? (
                                <>
                                  <Button
                                    className="rounded-full"
                                    size="xs"
                                    variant="primaryOutline"
                                    onClick={() => {
                                      log.debug(
                                        "edit selectedComment comment: ",
                                        comment
                                      );
                                      setComment2Save(comment.content);
                                      setSelectedComment(comment);
                                    }}
                                  >
                                    <Edit className="h-4 w-4" />
                                  </Button>
                                  {selectedComment != comment.id && (
                                    <AlertDialog>
                                      <AlertDialogTrigger asChild>
                                        <Button
                                          className="rounded-full"
                                          size="xs"
                                          variant="dangerOutline"
                                          onClick={() => {
                                            log.debug(
                                              "deleting selectedComment comment: ",
                                              comment
                                            );
                                          }}
                                        >
                                          <Trash className="h-4 w-4" />
                                        </Button>
                                      </AlertDialogTrigger>
                                      <AlertDialogContent>
                                        <AlertDialogHeader>
                                          <AlertDialogTitle>
                                            Are you absolutely sure?
                                          </AlertDialogTitle>
                                          <AlertDialogDescription>
                                            This action cannot be undone. This
                                            will permanently delete your comment
                                            and remove your comment data from
                                            our servers.
                                          </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                          <AlertDialogCancel>
                                            Cancel
                                          </AlertDialogCancel>
                                          <AlertDialogAction
                                            onClick={() => {
                                              log.debug(
                                                "addSalarySlip event triggred"
                                              );
                                              deleteGoalComment(comment.id);
                                            }}
                                          >
                                            Continue
                                          </AlertDialogAction>
                                        </AlertDialogFooter>
                                      </AlertDialogContent>
                                    </AlertDialog>
                                  )}
                                </>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </ScrollArea>
            </CardContent>
          </ScrollArea>
        </Card>
      ) : null}
      <form onSubmit={submitGoalComment} method="POST">
        <div className="mt-2 p-2">
          <MDEditor
            value={value}
            height={200}
            preview="edit"
            onChange={setValue}
          />
          <Button
            size="sm"
            type="submit"
            variant="dark"
            className="relative float-right  bottom-12 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <Send className="h-4 w-4" />
            <span className="sr-only">Send</span>
          </Button>
        </div>
      </form>
    </div>
  );
}
