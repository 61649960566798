import React, { useState } from "react";
import {
  useAuthService,
  useAuthContextData,
} from "../../customHooks/authMachineHooks";
import { TextField } from "../Fields";
import Message from "../common/Messages";
import { cn } from "../../lib/utils";
import log from "loglevel";
import { Input } from "../CustomComponentsLibrary/Input";
import { Label } from "../CustomComponentsLibrary/Label";
import { Button } from "../CustomComponentsLibrary/Button";
import { TypographyH2 } from "../CustomComponentsLibrary/Typography";

export default function ForgotFormComponent() {
  const authService = useAuthService();
  const [message, setMessage] = useState({
    messageText: "",
    messageType: "",
  });
  const [email, setEmail] = useState("");
  const { error } = useAuthContextData();

  function handleSubmit() {
    log.debug("Submit", email);
    authService.send({
      type: "send_verification_code",
      data: {
        email: email,
      },
    });
    // setMailSent(true)
  }
  return (
    <>
      <div className={cn("grid grid-col-1 place-items-center h-screen ")}>
        <div className="mx-auto flex w-full flex-col justify-center bg-transparent space-y-6">
          <div className="flex flex-col space-y-2 text-center">
            <TypographyH2>Forgot Password</TypographyH2>
            <Label> Enter your email below to reset your password</Label>
          </div>
          <form className="mt-6 grid grid-cols-1  ml-8 md:0 gap-y-5 gap-x-6 ">
            <Label>Email Address</Label>
            <Input
              label="Email"
              id="email"
              name="email"
              type="text"
              autoComplete="email"
              onChange={(e) => {
                log.debug("OnChange", e.target.value);
                setEmail(e.target.value);
              }}
              required
              className="h-10 md:w-full w-64"
            />

            {message.messageText ? (
              <div className="text-center">
                <Message message={message} setMessage={setMessage} />
              </div>
            ) : (
              ""
            )}

            <Button
              className="  items-center px-4 py-5 text-text  md:w-full w-64 text-center text-base
              font-medium"
              onClick={handleSubmit}
            >
              Send Verification Code
            </Button>
          </form>

          <div className="flex grow flex-row justify-center gap-2">
            <p className=" invisible mt-2 text-sm  text-black md:visible">
              If you already have an account?
              <Button
                variant="link"
                onClick={() => {
                  authService.send({ type: "signInOptions" });
                }}
              >
                Sign In
              </Button>
              for a free trial.
            </p>
          </div>
        </div>

        <div className="flex grow flex-col justify-end gap-2">
          {/* User already exists error */}
          {error ? (
            <div className="flex grow flex-row justify-center gap-2">
              <div className="bg-red-100 py-4 text-center lg:px-4">
                <div
                  className="flex items-center bg-red-500 p-2 leading-none text-white lg:inline-flex lg:rounded-full"
                  role="alert"
                >
                  <span className="mr-2 flex-auto text-left font-semibold">
                    {error}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
