import React, { useState, useEffect } from "react";
import log from "loglevel";

export function LogoName() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="120.000000pt"
      height="33.000000pt"
      viewBox="400 420 3800.000000 707.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1417.000000) scale(0.100000,-0.100000)"
        fill="#11E4B9"
        stroke="none"
      >
        <path
          className="animate-pulse"
          d="M36910 11714 c-83 -18 -175 -63 -231 -113 l-44 -40 340 -340 c187 -188 344 -341 350 -341 6 0 97 87 203 193 l192 193 -187 185 c-207 204 -252 235 -388 264 -86 18 -150 18 -235 -1z"
          fill="#11E4B9"
        ></path>
        <path
          d="M39060 11719 c-30 -5 -93 -28 -140 -51 -77 -38 -101 -57 -265 -219 l-180 -178 195 -196 c107 -107 199 -195 205 -195 6 0 163 153 350 341 l340 340 -45 39 c-117 103 -287 146 -460 119z"
          fill="#11E4B9"
        ></path>
        <path d="M36057 10982 l-197 -197 345 -345 345 -345 195 195 c107 107 195 199 195 205 0 10 -670 685 -680 685 -3 0 -94 -89 -203 -198z"></path>
        <path d="M39592 10837 l-342 -342 198 -198 197 -197 345 345 345 345 -195 195 c-107 107 -197 195 -200 195 -3 0 -159 -154 -348 -343z"></path>
        <path
          d="M35287 10212 l-197 -197 345 -345 345 -345 197 198 198 197 -345 345 -345 345 -198 -198z"
          fill="#11E4B9"
        ></path>
        <path
          d="M40370 10065 l-345 -345 198 -197 197 -198 345 345 345 345 -198 198 -197 197 -345 -345z"
          fill="#11E4B9"
        ></path>
        <path
          d="M36735 9530 l-580 -580 885 -886 c710 -711 895 -891 935 -910 66 -32 179 -33 245 -3 35 15 245 219 933 907 l887 887 -582 582 -583 583 -780 0 -780 0 -580 -580z"
          fill="#11E4B9"
        ></path>
        <path
          d="M34678 9603 c-87 -93 -133 -220 -131 -368 1 -114 23 -194 78 -285 24 -38 432 -452 1322 -1341 1136 -1135 1299 -1293 1380 -1347 158 -104 310 -167 504 -209 132 -28 420 -26 558 5 187 42 332 105 496 215 77 52 277 247 1381 1351 1196 1195 1294 1296 1323 1356 44 92 61 162 61 259 0 130 -34 235 -109 339 -18 24 -39 46 -47 49 -11 4 -432 -410 -1462 -1439 -1590 -1588 -1488 -1494 -1682 -1559 -81 -27 -113 -33 -220 -37 -103 -3 -140 -1 -212 17 -99 23 -191 62 -275 117 -38 24 -546 524 -1493 1471 -789 788 -1438 1433 -1441 1433 -3 0 -17 -12 -31 -27z"
          fill="#11E4B9"
        ></path>
        <path
          d="M11423 7103 c3 -1676 5 -1868 20 -1960 89 -546 330 -803 812 -864 119 -14 394 -7 540 15 161 25 145 13 144 114 -1 140 -24 570 -31 577 -3 3 -52 1 -109 -5 -226 -25 -370 30 -434 166 -57 120 -55 49 -55 2010 l0 1804 -445 0 -446 0 4 -1857z"
          fill="#11E4B9"
        ></path>
        <path
          d="M18150 6635 l0 -2315 450 0 449 0 4 943 c3 866 4 947 21 1002 67 230 231 393 451 450 79 21 251 21 328 1 192 -52 329 -193 384 -397 15 -58 17 -152 20 -1031 l4 -968 449 0 450 0 0 919 c0 947 -5 1090 -41 1279 -93 481 -363 783 -794 888 -150 36 -345 50 -505 35 -303 -28 -527 -123 -697 -298 l-73 -74 0 940 0 941 -450 0 -450 0 0 -2315z"
          fill="#11E4B9"
        ></path>
        <path
          d="M30860 6640 l0 -2310 445 0 445 0 0 609 0 608 173 6 c104 3 193 11 224 20 l52 15 362 -629 361 -629 454 0 454 0 0 44 c0 41 -36 105 -435 768 -392 653 -433 724 -417 736 9 7 60 40 112 74 123 80 278 234 355 352 152 234 222 498 232 874 l6 202 -421 0 -420 0 -6 -97 c-18 -288 -54 -453 -137 -622 -42 -86 -64 -117 -128 -180 -88 -88 -158 -132 -273 -172 -109 -37 -207 -49 -394 -49 l-154 0 0 1345 0 1345 -445 0 -445 0 0 -2310z"
          fill="#11E4B9"
        ></path>
        <path
          d="M13573 8745 c-137 -37 -262 -136 -317 -249 -84 -176 -64 -411 48 -564 150 -204 481 -258 719 -119 57 34 139 122 171 185 79 156 73 382 -14 526 -63 105 -178 187 -306 220 -76 20 -230 20 -301 1z"
          fill="#11E4B9"
        ></path>
        <path
          d="M3850 6535 l0 -2216 958 4 c1052 4 1028 2 1292 68 746 185 1298 743 1495 1513 59 229 70 329 70 626 0 289 -11 386 -65 608 -135 552 -460 1013 -913 1294 -265 165 -607 276 -947 307 -71 7 -455 11 -1002 11 l-888 0 0 -2215z m1890 1327 c316 -68 554 -224 730 -478 409 -592 298 -1523 -232 -1948 -106 -86 -289 -177 -420 -210 -146 -38 -266 -46 -659 -46 l-359 0 0 1356 0 1356 423 -5 c375 -4 433 -7 517 -25z"
          fill="#11E4B9"
        ></path>
        <path
          d="M22136 8308 c-3 -7 -23 -204 -45 -438 -23 -234 -44 -442 -47 -462 l-6 -38 -319 0 -319 0 0 -308 0 -309 183 -17 c100 -9 230 -22 289 -28 l107 -11 3 -726 c3 -650 6 -736 22 -821 91 -478 341 -759 767 -864 295 -72 646 -58 1072 45 127 31 128 31 123 58 -3 14 -22 167 -43 339 -36 301 -38 313 -58 309 -11 -2 -60 -16 -110 -31 -129 -38 -243 -56 -359 -56 -173 0 -298 43 -391 135 -61 61 -99 129 -127 232 -21 76 -22 98 -26 731 l-3 652 500 0 501 0 0 335 0 335 -500 0 -500 0 0 475 0 475 -355 0 c-276 0 -356 -3 -359 -12z"
          fill="#11E4B9"
        ></path>
        <path
          d="M28790 7463 c-90 -9 -262 -47 -358 -79 -405 -132 -733 -407 -912 -764 -191 -380 -221 -918 -74 -1341 177 -512 596 -866 1169 -989 128 -28 424 -38 575 -20 454 52 859 287 1091 633 107 160 186 359 223 565 l6 32 -428 0 -427 0 -22 -57 c-56 -143 -177 -274 -315 -342 -113 -56 -183 -72 -308 -72 -126 0 -224 23 -335 78 -335 165 -503 572 -405 980 85 357 384 593 750 593 272 0 485 -127 598 -357 l26 -53 423 0 c408 0 423 1 423 19 0 36 -50 212 -85 299 -72 178 -162 312 -309 457 -226 223 -515 361 -859 410 -92 13 -354 18 -447 8z"
          fill="#11E4B9"
        ></path>
        <path
          d="M9360 7443 c-89 -9 -261 -47 -356 -78 -460 -151 -793 -477 -943 -923 -140 -418 -113 -932 70 -1322 284 -605 921 -926 1732 -872 273 18 511 66 757 151 100 34 278 106 288 115 3 4 -97 648 -102 653 -2 2 -34 -9 -72 -23 -274 -105 -608 -167 -889 -166 -277 1 -427 29 -604 114 -185 89 -300 230 -351 432 -11 44 -20 92 -20 108 l0 28 1033 0 1034 0 23 128 c28 158 38 436 20 579 -76 605 -471 982 -1119 1068 -96 13 -407 18 -501 8z m328 -684 c308 -34 494 -195 510 -441 l5 -78 -663 0 -662 0 5 28 c11 56 49 143 88 202 103 154 290 262 500 289 93 12 109 12 217 0z"
          fill="#11E4B9"
        ></path>
        <path
          d="M25460 7443 c-173 -18 -388 -78 -549 -154 -517 -243 -815 -723 -838 -1349 -25 -692 288 -1247 857 -1519 538 -256 1296 -236 1983 52 85 36 97 44 93 62 -2 11 -26 159 -52 328 -41 263 -50 308 -63 303 -385 -138 -648 -190 -958 -188 -267 1 -420 30 -593 114 -88 43 -117 63 -180 127 -50 50 -89 100 -113 148 -39 75 -77 203 -77 260 l0 33 1029 0 c567 0 1032 3 1034 8 3 4 15 66 28 137 31 173 34 510 6 649 -114 564 -493 901 -1104 981 -104 14 -405 18 -503 8z m439 -704 c245 -57 400 -230 401 -446 l0 -53 -664 0 -665 0 16 53 c72 247 296 428 577 467 85 12 235 3 335 -21z"
          fill="#11E4B9"
        ></path>
        <path
          d="M16000 7389 c-41 -5 -130 -20 -197 -34 -552 -115 -930 -430 -1102 -919 -113 -322 -126 -804 -31 -1178 142 -556 516 -935 1022 -1038 306 -63 660 -18 1002 126 44 19 82 34 85 34 2 0 1 -98 -2 -217 -4 -192 -8 -230 -31 -317 -39 -149 -86 -237 -176 -326 -82 -82 -137 -116 -250 -154 -218 -72 -514 -52 -863 61 -149 48 -328 121 -446 183 -41 22 -76 38 -76 37 -1 -1 -25 -146 -54 -322 -29 -176 -55 -332 -58 -348 -5 -26 -1 -29 103 -78 321 -150 641 -244 953 -280 158 -18 489 -7 624 20 304 61 535 179 728 371 193 192 317 441 381 760 l23 115 3 1621 2 1621 -117 45 c-298 116 -596 188 -902 218 -121 12 -513 11 -621 -1z m545 -745 c61 -8 135 -20 165 -27 l55 -12 3 -741 2 -741 -82 -38 c-212 -97 -394 -133 -568 -114 -254 28 -434 155 -540 384 -72 152 -99 337 -80 543 32 358 161 584 395 692 167 78 352 93 650 54z"
          fill="#11E4B9"
        ></path>
        <path
          d="M13280 5830 l0 -1510 450 0 450 0 0 1510 0 1510 -450 0 -450 0 0 -1510z"
          fill="#11E4B9"
        ></path>
      </g>
    </svg>
  );
}
