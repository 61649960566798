import React, { useEffect } from "react";
import log from "loglevel";
import {
  useEmployeeContextData,
  useEmployeeContextState,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import ReimburseList from "../../../components/employee/Payroll/Reimbursement/ReimburseList";
import AddEditDeleteReimbursement from "../../../components/employee/Payroll/Reimbursement/AddEditDeleteReimbursement";

export function ReimbursementLayout() {
  const { reimbursementCRUD } = useEmployeeContextState();
  const employeeService = useEmployeeContextService();
  log.debug("ReimbursementsCrud:::", reimbursementCRUD);
  useEffect(() => {
    employeeService.send({ type: "load_reimbursements" });
  }, []);
  return (
    <div className="h-full md:rounded-sm lg:rounded-md">
      <ReimburseList />
      <> {reimbursementCRUD ? <AddEditDeleteReimbursement /> : <></>}</>
    </div>
  );
}
