import React from "react";
import log from "loglevel";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "../CustomComponentsLibrary/Button";
import { useAuthContextData } from "../../customHooks/authMachineHooks";

function ConfirmationPopup(props) {
  const {
    actionOnClose,
    actionOnConfirm,
    actionOnCancel,
    message,
    confirmText,
    cancelText,
  } = props;
  const { theme } = useAuthContextData();
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-center">
        <div className="pointer-events-auto w-full max-w-sm  border-input rounded-lg bg-white shadow-lg">
          <div className="z-50 grid w-full scale-100 gap-4 border bg-white p-6 opacity-100 shadow-lg animate-in fade-in-90 slide-in-from-bottom-10 sm:rounded-lg sm:zoom-in-90 sm:slide-in-from-bottom-0 md:w-full">
            <div className="flex items-start">
              <div className="ml-3 w-0 flex-1">
                <p className="mt-1 text-lg text-gray-500">{message}</p>
                <div className="mt-4 flex justify-end">
                  <Button
                    variant="destructive"
                    onClick={actionOnCancel}
                    type="button"
                    className={`${theme?.default}`}
                  >
                    {cancelText}
                  </Button>
                  <Button
                    variant="danger"
                    onClick={actionOnConfirm}
                    className={`${theme?.destructive}`}
                    type="button"
                  >
                    {confirmText}
                  </Button>
                </div>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={actionOnClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopup;
