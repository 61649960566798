import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddTopicResource = async (TopicResource) => {
  log.debug(" hookAddTopicResource : ", TopicResource);

  try {
    const result = await API.graphql({
      query: mutations.createTopicResource,
      variables: {
        input: TopicResource,
      },
    });

    log.debug(" hookAddTopicResource result: ", result);

    return result?.data?.createTopicResource;
  } catch (err) {
    log.debug("CATCH ERROR hookAddTopicResource result: ", err);
    return null;
  }
  //Destructuring Assignment in ES6
  //setTodos([...todos, result.data.createTodo]);
};

export const hookUpdateTopicResource = async (TopicResource) => {
  log.debug(" hookUpdateTopicResource : ", TopicResource);
  try {
    const result = await API.graphql({
      query: mutations.updateTopicResource,
      variables: {
        input: TopicResource,
      },
    });

    log.debug(" hookUpdateTopicResource result: ", result);
    return result?.data?.updateTopicResource;
  } catch (err) {
    log.debug("CATCH ERROR hookAddUser result: ", err);
  }
};

export const hookGetTopicResourceById = async (id) => {
  log.debug(" hookGetTopicResourceById  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getTopicResource,
      variables: {
        questionId: id,
      },
    });

    log.debug(" hookGetTopicResourceById : ", data);
    if (data !== null) return data?.data?.getTopicResource;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTopicResourceByCognitoId -- ", e);
    return null;
  }
};

export const hookListTopicResources = async () => {
  //Connect Client Amplify GraphQL
  //TODO: add filter to get TopicResource by restaurent Id
  try {
    const result = await API.graphql({
      query: queries.listTopicResources,
      variables: {},
    });

    log.debug(" hooklistTopicResource : ", result);

    if (result !== null) return result?.data?.listTopicResources?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hooklistTopicResource ", err);
  }
};

export const hookGetTopicResourcesByTopicId = async (topicId) => {
  //Connect Client Amplify GraphQL
  //TODO: add filter to get TopicResource by restaurent Id
  try {
    const result = await API.graphql({
      query: queries.topicResourcesByTopicID,
      variables: {
        topicID: topicId,
        sortDirection: "ASC",
      },
    });

    log.debug(" hooklistTopicResource : ", result);

    if (result !== null) return result?.data?.topicResourcesByTopicID?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hooklistTopicResource ", err);
  }
};
export const hookDeleteTopicResourceById = async (resourceId) => {
  log.debug(" hookGetTopicResourceById  ", resourceId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteTopicResource,
      variables: {
        input: { id: resourceId },
      },
    });

    log.debug(" hookDeleteTopicResourceById : ", data);
    if (data !== null) return data?.data?.deleteTopicResource;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteTopicResourceByCognitoId -- ", e);
    return null;
  }
};
