import {
  hookDeleteAttendanceByAttendanceId,
  hookAddAttendance,
  hookUpdateAttendance,
  hookAttendanceByUserID,
  hookListEmployeeAttendanceByMonth,
} from "../../hooks/attendanceHooks";
import log from "loglevel";
import moment from "moment";
export const ListAttendanceDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID, selectedMonth } = context;
    log.debug("ListAttendanceData::seselectedMonth", selectedMonth);
    try {
      var testDate = moment(new Date());
      var currentDate = testDate.format("YYYY-MM-DD");
      let firstDay = null;
      let lastDay = null;
      let firstDateOfMonth = null;
      let lastDateOfMonth = null;
      if (selectedMonth) {
        firstDateOfMonth = moment(selectedMonth).startOf("month");
        lastDateOfMonth = moment(selectedMonth).endOf("month");
        firstDay = firstDateOfMonth.format("YYYY-MM-DD");
        lastDay = lastDateOfMonth.format("YYYY-MM-DD");
      } else {
        firstDateOfMonth = moment(currentDate).startOf("month");
        lastDateOfMonth = moment(currentDate).endOf("month");
        firstDay = firstDateOfMonth.format("YYYY-MM-DD");
        lastDay = lastDateOfMonth.format("YYYY-MM-DD");
      }

      log.debug("attendance:currentDate", userID, firstDay, lastDay);

      const ListAttendance = await hookListEmployeeAttendanceByMonth(
        userID,
        firstDay,
        lastDay
      );

      log.debug("ListAttendanceData:ListAttendance::", ListAttendance);
      let todayAttendance = ListAttendance.find((attendance) => {
        log.debug("attendance:todayAttendance", attendance);
        return attendance.date === currentDate;
      });

      log.debug("todayAttendance:loop todayAttendance", todayAttendance);
      log.debug("ListAttendanceData:todayAttendance::", todayAttendance);
      if (todayAttendance) {
        resolve({ attendancesList: ListAttendance, todayAttendance });
      } else if (ListAttendance) {
        resolve({ attendancesList: ListAttendance });
      } else {
        reject("Failed To Fetch Tables");
      }
    } catch (err) {
      log.debug("Catch error for getAttendanceDataByEmployeeId", err);
    }
  });

export const deleteAttendanceByAttendanceIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { attendanceId } = context;
    try {
      const deleteAttendance = await hookDeleteAttendanceByAttendanceId(
        attendanceId
      );
      log.debug("Attendance:deleteAttendance", deleteAttendance);
      if (deleteAttendance) resolve(deleteAttendance);
      reject("Failed To delete Attendance");
    } catch (err) {
      log.debug("catch:deleteAttendance error", err);
    }
  });

export const submitAttendanceDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { timeInOne, attendancesList, userProfile, userID, organizationID } =
      context;
    try {
      var testDate = moment(new Date());
      var currentDate = testDate.format("YYYY-MM-DD");
      const day = moment().format("dddd");

      log.debug("timeInOne=====>", timeInOne, organizationID);
      const Attendance = {
        employeeId: 1,
        userID: userID,
        emailId: userProfile?.email,
        date: currentDate,
        day: day,
        timeIn1: timeInOne,
        workType: "WORK_FROM_UNKNOWN",
        organizationID: organizationID,
      };

      const attendance = await hookAddAttendance(Attendance);
      log.debug("attendance==hookAddAttendance==>", attendance?.id);
      resolve({ attendance });
    } catch (err) {
      reject("Failed To Delete Task");
      log.debug("submiTimeInData error", err);
    }
  });

export const updateAttendanceService = (context) =>
  //TODO UPDATE ATTENDANCE CODE HERE
  new Promise(async (resolve, reject) => {
    try {
      log.debug("AttendanceToBeUpdate===>", context);
      const {
        timeOutOne,
        timeInTwo,
        todayAttendance,
        timeOutTwo,
        attendanceId,
        description,
      } = context;

      let Attendance2Update = null;

      if (todayAttendance?.timeIn2) {
        log.debug("timeOutTwo=====>", timeOutTwo);
        const diffMilliseconds1 =
          new Date(todayAttendance?.TimeOut1) -
          new Date(todayAttendance?.timeIn1);
        const diffHours1 = diffMilliseconds1 / (1000 * 60 * 60);
        const diffMilliseconds2 =
          new Date(timeOutTwo) - new Date(todayAttendance?.timeIn2);
        const diffHours2 = diffMilliseconds2 / (1000 * 60 * 60);
        const totalHours = diffHours2 + diffHours1;

        Attendance2Update = {
          id: attendanceId,
          TimeOut2: timeOutTwo,
          description: description,
          totalHours: totalHours,
        };
      } else if (todayAttendance?.TimeOut1) {
        log.debug("timeInTwo=====>", timeInTwo);
        Attendance2Update = {
          id: attendanceId,
          timeIn2: timeInTwo,
        };
      } else if (todayAttendance?.timeIn1) {
        log.debug("timeOutOne=====>", timeOutOne);
        Attendance2Update = {
          id: attendanceId,
          TimeOut1: timeOutOne,
        };
      }
      log.debug("Attendance2Update::", Attendance2Update);
      const updatedAttendance = await hookUpdateAttendance(Attendance2Update);
      log.debug("updatedAttendance ===>", updatedAttendance);
      resolve(updatedAttendance);
    } catch (e) {
      // log.debug('AttendanceToBeUpdate===>', AttendanceToBeUpdate);
      // if (AttendanceToBeUpdate) resolve(AttendanceToBeUpdate);
      reject("Failed To Update Task");
    }
  });
