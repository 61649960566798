import React from "react";
import log from "loglevel";
import clsx from "clsx";
import { Button } from "../CustomComponentsLibrary/Button";
export default function ButtonComponent({
  name = "",
  disabled = false,
  action = () => {
    log.debug("action undefined");
  },
}) {
  return (
    <Button
      type="button"
      size="lg"
      className={clsx(
        disabled ? "bg-background text-text " : "border border-input bg-dark "
      )}
      disabled={disabled}
      onClick={action}
    >
      {name}
    </Button>
  );
}
