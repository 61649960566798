import React, { useEffect, useState } from "react";
import { Edit, Edit2, Trash, Trash2 } from "lucide-react";
import { useEmployeeContextData } from "../../../hooks/employeeMachineHooks";
import { Button } from "../../CustomComponentsLibrary/Button";
import { GetSignedURL } from "../../../hooks/s3Hooks";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
const ProfilePictureUpload = ({ setSelectedFile, handleRemove }) => {
  const { myEmployeeProfile } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setImagePreview(null);
    handleRemove();
  };
  useEffect(() => {
    async function fetchProfilePic() {
      const profilePic = await GetSignedURL(myEmployeeProfile.profilePicture);
      setImagePreview(profilePic);
    }

    if (myEmployeeProfile?.profilePicture) {
      fetchProfilePic();
    }
  }, [myEmployeeProfile]);
  return (
    <div style={{ position: "relative" }}>
      {imagePreview ? (
        <img
          src={imagePreview}
          alt="Profile"
          style={{ width: "126px", height: "126px", borderRadius: "50%" }}
        />
      ) : (
        <div className="bg-slate-500 rounded-full h-[126px] w-[126px] md:-mb-6 flex items-center justify-center text-3xl font-bold">
          {myEmployeeProfile?.firstName[0]}
        </div>
      )}
      <div className="w-32 flex flex-row  z-20  items-end justify-end">
        {imagePreview ? (
          <Button
            variant="link"
            onClick={handleImageRemove}
            className="border-zinc-200 flex items-center rounded-full h-[40px] w-[40px] bg-slate-200 relative sm:top-[-39px] top-[-36px]"
          >
            <div>
              <Trash className="m-auto h-4 w-4" />
            </div>
          </Button>
        ) : (
          <label htmlFor="profile-upload">
            <div className="border border-zinc-200 flex items-center rounded-full h-[40px] w-[40px] bg-slate-200 relative md:top-[-15px] top-[-36px] ">
              <Edit2 className="m-auto h-4 w-4 text-common" />
            </div>

            <input
              type="file"
              id="profile-upload"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </label>
        )}
      </div>
    </div>
  );
};

export default ProfilePictureUpload;
