import React from "react";
import { DataTableColumnHeader } from "../../CustomComponentsLibrary/data-table-column-header";
import FormatDateAndTime from "../../common/FormatDateAndTime";
import PageHeader from "../../common/PageHeader";
import { DataTable } from "../../CustomComponentsLibrary/data-table";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

const Bonus = () => {
  const { theme } = useAuthContextData();
  const columns = [
    {
      header: "Title",
      accessorKey: "bonustitle",
    },
    {
      header: "Description",
      accessorKey: "bonusdescription",
    },
    {
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Date" />
      ),
      accessorKey: "date",
      cell: ({ row }) => (
        <div className="text-sm font-semibold">
          <FormatDateAndTime
            dateTime={row.original?.date}
            format={"DD/MM/YYYY"}
          />
        </div>
      ),
    },
    {
      header: "Amount",
      accessorKey: "bonusamount",
    },
  ];
  return (
    <div className="p-2 mx-auto">
      <PageHeader
        title={"Bonus"}
        SubHeading="A record of all bonuses you have achieved."
        showDropdown={false}
      />
      <div className="shadow-md mt-5 border-t border-t-zinc-100 rounded-md">
        <div className=" flex flex-row justify-end"></div>
        <div className="ml-5 mr-5 mt-2 pb-5 text-sm font-semibold">
          <DataTable
            columns={columns}
            data={[]}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 2,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 3,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Bonus;
