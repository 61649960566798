import React, { useState, useEffect } from "react";
import log from "loglevel";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../../hooks/employeeMachineHooks";
import { Checkbox } from "../../../CustomComponentsLibrary/Checkbox";
import * as XLSX from "xlsx";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import { Button } from "../../../CustomComponentsLibrary/Button";
import { Upload, Calendar } from "lucide-react";
import FormatDateAndTime from "../../../common/FormatDateAndTime";
import { DataTableColumnHeader } from "../../../CustomComponentsLibrary/data-table-column-header";
import DatePicker from "react-datepicker";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";

function TotalHours({ hours }) {
  var hour = Math.floor(hours);
  var sign = hours >= 0 ? 1 : -1;
  // Set positive value of number of sign negative
  hours = hours * sign;
  // log.debug(':totalHours==>', hours);
  var decpart = hours - hour;
  //  log.debug(':totalHours::decpart==>', hours);
  var min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);
  // log.debug('totalHours:::decpart==>', decpart);
  var minute = Math.floor(decpart * 60) + "";
  // log.debug('totalHours:::decpart:minute==>', minute);
  if (minute.length < 2) {
    minute = "0" + minute;
  }
  sign = sign == 1 ? "" : "-";
  // const time = sign + hour + 'hr' + minute + 'min';
  const time = sign + hour + " h " + minute + " min";
  return (
    <div className="flex flex-row">
      <div className=" h-8 grow">{time}</div>
    </div>
  );
}

export function TimesheetList() {
  const { attendancesList } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  log.debug("attendancesList ===> ", attendancesList);
  const [rowSelected, setRowSelected] = useState({});
  const [startDate, setStartDate] = useState(null);
  const employeeMachineService = useEmployeeContextService();

  const handleRowClick = (filteredallAttendance) => {
    if (filteredallAttendance) {
      const xlsData = filteredallAttendance.map((row) => ({
        Date: row?.date,
        Day: row?.day,
        TimeIn1: row?.timeIn1,
        TimeOut1: row?.TimeOut1,
        TimeIn2: row?.timeIn2,
        TimeOut2: row?.TimeOut2,
        TotalHr: row?.totalHours,
        Description: Array.isArray(row?.description)
          ? row?.description.join("\n")
          : row?.description,
      }));
      log.debug("xlsData::", xlsData);

      const xlsWorksheet = XLSX.utils.json_to_sheet(xlsData);
      const xlsWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(xlsWorkbook, xlsWorksheet, "SelectedRows");

      XLSX.writeFile(xlsWorkbook, "selected_rows.xlsx");
    }

    log.debug("handleRowClick::", rowSelected);
  };

  useEffect(() => {
    employeeMachineService.send({
      type: "reload_tasks",
      data: {
        selectedMonth: startDate,
      },
    });
  }, [startDate]);

  useEffect(() => {
    const currentDate = new Date();
    setStartDate(currentDate);
  }, []);

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            log.debug("selecetd Tixck", value, row, row.getIsSelected());
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Date" />
      ),
      accessorKey: "date",
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row.original?.date}
          format={"DD/MM/YYYY"}
        />
      ),
    },
    { header: "Day", accessorKey: "day" },
    {
      header: "Time In 1",
      accessorKey: "timeIn1",
      cell: ({ row }) => (
        <>
          {row.original?.timeIn1 ? (
            <FormatDateAndTime
              dateTime={new Date(row.original?.timeIn1)}
              format={"LT"}
            />
          ) : (
            <>--:--</>
          )}
        </>
      ),
    },
    {
      header: "Time Out 1",
      accessorKey: "TimeOut1",
      cell: ({ row }) => (
        <>
          {row.original?.TimeOut1 != null ? (
            <FormatDateAndTime
              dateTime={new Date(row.original?.TimeOut1)}
              format={"LT"}
            />
          ) : (
            <>--:--</>
          )}
        </>
      ),
    },
    {
      header: "Time In 2",
      accessorKey: "timeIn2",
      cell: ({ row }) => (
        <>
          {row.original?.timeIn2 != null ? (
            <FormatDateAndTime
              dateTime={new Date(row.original?.timeIn2)}
              format={"LT"}
            />
          ) : (
            <>--:--</>
          )}
        </>
      ),
    },
    {
      header: "Time Out 2",
      accessorKey: "TimeOut2",
      cell: ({ row }) => (
        <>
          {row.original?.TimeOut2 != null ? (
            <FormatDateAndTime
              dateTime={new Date(row.original?.TimeOut2)}
              format={"LT"}
            />
          ) : (
            <>--:--</>
          )}
        </>
      ),
    },
    {
      header: "Total Hrs.",
      accessorKey: "totalHours",
      cell: ({ row }) => <TotalHours hours={row.original?.totalHours} />,
    },
    {
      header: "Description",
      accessorKey: "description",
      cell: ({ row }) => (
        <>
          <div className="text-sm font-semibold w-96  overflow-hidden overflow-ellipsis">
            {row.original?.description}
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="m-3 mt-10 shadow-md rounded-lg border-t border-zinc-100 pb-5">
      <div className="m-5 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h3 className="text-2xl">Attendances</h3>
        </div>
      </div>
      <div className="m-5 flow-root">
        <div className="flex flex-row justify-start">
          <div className="w-full flex  text-base sm:w-96 z-20">
            <DatePicker
              wrapperClassName="custom-datepicker"
              selected={startDate}
              onChange={(selectedDate) => {
                setStartDate(selectedDate);
              }}
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              className="cursor-pointer h-12 p-3  border w-full rounded-md date-input"
              placeholderText="Select month"
            />
            <Calendar className="relative right-10 m-auto" />
          </div>
        </div>
        <div className="shadow-md m-5 rounded-lg border-t border-zinc-100">
          <div className="flex flex-row justify-end">
            <Button
              variant=""
              className={`"text-text m-5 mb-2" ${theme?.hoverbg} ${theme?.hoverText} ${theme?.border}`}
              size="sm"
              onClick={() => {
                const selectedKeys = Object.keys(rowSelected);
                const filteredallAttendance = attendancesList.filter(
                  (contact, index) => {
                    log.debug("Task, index", contact, index);
                    return selectedKeys.includes(index.toString())
                      ? true
                      : false;
                  }
                );
                if (filteredallAttendance) {
                  handleRowClick(filteredallAttendance, selectedKeys);
                }
                log.debug(
                  "filteredallAttendance",
                  filteredallAttendance,
                  selectedKeys
                );
              }}
            >
              <Upload className="h-4 w-4" /> Export Selected Row
            </Button>
          </div>

          <div className="ml-5 mr-5 mt-2 pb-5">
            <DataTable
              columns={columns}
              data={attendancesList}
              searchableColumns={[
                {
                  id: "date",
                  title: "Date",
                },
              ]}
              setRowSelected={setRowSelected}
              rowSelected={rowSelected}
              customCss={`${theme?.border}`}
              customWidth={[
                {
                  index: 0,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 1,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 2,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 3,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 4,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 5,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 6,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 7,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 8,
                  widthClass: "w-40",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
