import RegisterFormComponent from "../../src/components/register/RegisterFormComponent";
import EmailConfirm from "./register/EmailConfirm";
import EmailCodeVerify from "./register/EmailCodeVerify";
import {
  useAuthContextData,
  useAuthRegisterState,
  useAuthService,
} from "../customHooks/authMachineHooks";
import log from "loglevel";

export default function SignUpComponent() {
  const { isFormCreating, checkMailSent, isMailSent } = useAuthRegisterState();
  const { isUserExists, signUpDetails, error } = useAuthContextData();
  const authService = useAuthService();
  log.debug();

  return (
    <>
      {isFormCreating ? (
        isUserExists ? (
          <>
            <div className="flex flex-col items-center justify-center grow h-screen">
              <div className="text-3xl font-bold">Welcome Back!</div>
              <div className="text-blue-500 font-mono text-xl">
                {signUpDetails?.email}
              </div>

              <div className="flex flex-col items-center">
                {error?.includes("confirmed") ? (
                  <div>
                    <span className="text-center font-semibold text-red-600">
                      {error}
                    </span>
                    <div>
                      Please continue with forgot password from sign in
                      <button
                        variant="link"
                        onClick={() => {
                          authService.send({ type: "signInOptions" });
                        }}
                      >
                        Forgot Password
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <div className="font-semibold">
                      It seems you already tried signUp please click Continue to
                      proceed with above email
                    </div>
                    <button
                      variant="primary"
                      className="hover:cursor-pointer text-lg p-2 m-2 w-80"
                      onClick={() => {
                        authService.send({ type: "resendCode" });
                      }}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <RegisterFormComponent />
        )
      ) : null}
      {checkMailSent && <EmailConfirm />}
      {isMailSent && <EmailCodeVerify />}
    </>
  );
}
