import moment from "moment";
import {
  CONST_LEAVE_STATUS,
  CONST_TYPE_OF_LEAVE,
  CONST_UPDATE_CONTEXT_ACTIONS,
} from "../../constants/dbconstants";
import {
  hookListLeavesByUserId,
  hookListSubmittedLeaves,
  hookUpdateLeave,
  hookAddLeave,
  hookDeleteLeavesByLeaveId,
  hookListLeavesByUserIdAndYear,
  hookGetEmployeeLeavesOverviewById,
} from "../../hooks/leaveHooks";
import log from "loglevel";
import {
  hookGetEmployeeLeavesDataById,
  hookUpdateEmployee,
} from "../../hooks/employeeHooks";

export const getLeaveDataByEmployeeIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID, selectedYear } = context;
    log.debug("getLeaveDataByEmployeeIdService", userID);
    var currentDate = new Date();
    log.debug(selectedYear, currentDate);
    // const currentYear = new Date().getFullYear();
    // const currentYearString = currentYear.toString();
    let theFirst = null;
    let theLast = null;
    let firstDay = null;
    let lastDay = null;
    if (selectedYear) {
      firstDay = selectedYear?.firstday;
      lastDay = selectedYear?.lastDay;
    } else {
      theFirst = new Date(currentDate.getFullYear(), 0, 1);
      theLast = new Date(currentDate.getFullYear(), 11, 31);
      firstDay = moment(theFirst)?.format("YYYY-MM-DD");
      lastDay = moment(theLast)?.format("YYYY-MM-DD");
    }

    const leaves = await hookListLeavesByUserIdAndYear(
      userID,
      firstDay,
      lastDay
    );
    const leavesOverview = await hookGetEmployeeLeavesOverviewById(userID);
    if(leavesOverview)
    {
      for (const key in leavesOverview)
      {
        if (leavesOverview[key] === null) 
        {
          leavesOverview[key] = 0;
        }
      }
    }
    log.debug("leave:leavesOverview", leavesOverview);
    log.debug("leave:hookListLeavesByUserId", leaves);
    if (leaves) resolve({ leaves, leavesOverview });
    reject("Failed To Fetch leaves Tables");
  });

export const updateLeaveDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { leavesToAdd } = context;
    let date2 = null;
    if (Array.isArray(leavesToAdd?.date)) {
      date2 = leavesToAdd?.date[0];
    } else {
      date2 = leavesToAdd?.date;
    }

    const res = await hookUpdateLeave({
      ...leavesToAdd,
      date2: date2,
    });
    if (res)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "leaves",
          value: res,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully updated leave",
        },
      ]);
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update leave",
      },
    ]);
  });

export const submitLeaveDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { leavesToAdd, organizationID } = context;
    log.debug("leavesToAdd:submitLeaveData", leavesToAdd);
    const leave = await hookAddLeave({ ...leavesToAdd, organizationID });
    if (leave) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
          contextField: "leaves",
          value: leave,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully added leave",
        },
      ]);
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To add leave",
      },
    ]);
  });

export const submitMultipleLeaveDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { leavesToAdd, organizationID } = context;
    log.debug("submitMultipleLeaveDataService:leavesToAdd", leavesToAdd);
    const userID = leavesToAdd[0].appliedBy;
    const leavesOfSelectedPerson = await hookListLeavesByUserId(userID);

    const addMultiLeave = async (item) => {
      const leaveData = {
        ...item,
        date2: item?.date,
      };
      const leave = await hookAddLeave({ ...leaveData, organizationID });

      return leave;
    };

    const promises = leavesToAdd?.map((item) => addMultiLeave(item));
    try {
      const results = await Promise.all(promises);
      log.debug("leavesToAdd:submitLeaveData", results);
      if (results) {
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "leaves",
            value: [...leavesOfSelectedPerson, ...results],
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully added leave",
          },
        ]);
      } else {
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Failed To add leave",
          },
        ]);
      }
    } catch (error) {
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To add leave",
        },
      ]);
    }
  });

export const deleteLeaveDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedLeaveId } = context;
    log.debug("deleteLeaveData====>", selectedLeaveId);
    const leaveDeleted = await hookDeleteLeavesByLeaveId(selectedLeaveId);

    log.debug("leaveDeleted====>", leaveDeleted);
    if (leaveDeleted) resolve(true);
    reject("Failed To Delete leave");
  });

export const actionOnMultipleLeaveDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const {
      selectedLeavesForAction,
      actionOnLeave,
      myEmployeeProfile,
      userID,
    } = context;
    log.debug(
      "selectedLeavesForAction",
      selectedLeavesForAction,
      actionOnLeave,
      myEmployeeProfile
    );
    let isPreviouslyApproved = false;
    const updateLeave = async (leave) => {
      if (leave?.status === CONST_LEAVE_STATUS?.leaveStatusApproved) {
        isPreviouslyApproved = true;
      }
      var updateLeaveData = {};
      if (actionOnLeave == "Approve") {
        updateLeaveData = {
          id: leave?.id,
          approvedBy: myEmployeeProfile?.cognitoId,
          status: CONST_LEAVE_STATUS?.leaveStatusApproved,
        };
      } else if (actionOnLeave == "Reject") {
        updateLeaveData = {
          id: leave?.id,
          approvedBy: myEmployeeProfile?.cognitoId,
          status: CONST_LEAVE_STATUS.leaveStatusRejected,
        };
      }

      try {
        const updatedLeave = await hookUpdateLeave(updateLeaveData);
        console.log("actionOnMultipleLeaveDataService", updatedLeave);
        return updatedLeave;
      } catch (error) {
        console.log("actionOnMultipleLeaveDataService", error);
        return {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To action leave",
        };
      }
    };
    try {
      const Promises = selectedLeavesForAction?.map((item) =>
        updateLeave(item)
      );

      const results = await Promise.all(Promises);
      let noOfMedicalLeavesTaken = 0;
      let noOfMedicalLeavesRemaining = 0;
      
      let noOfLeavesTaken = 0;
      let noOfLeavesRemaining = 0;

      let noOfCoffLeaveTaken = 0;
      let noOfCoffLeaveRemaining = 0;

      if (!isPreviouslyApproved) {
        const employeeLeaveData = await hookGetEmployeeLeavesDataById(userID);

        if(employeeLeaveData?.noOfCOffLeaves == null)
        {
          employeeLeaveData.noOfCOffLeaves = 0
        }

        if(employeeLeaveData?.noOfCOffLeavesTaken == null)
        {
          employeeLeaveData.noOfCOffLeavesTaken = 0
        }

        if(employeeLeaveData?.noOfCOffLeavesRemaining == null)
        {
          employeeLeaveData.noOfCOffLeavesRemaining = 0
        }
  

        let totalSickLeaveQuantity = 0;
        let totalAnnualLeaveQuantity = 0;
        let totalCoffLeaveQuantity = 0;
        results?.forEach((leave) => {
          if (
            leave?.typeOfLeave === CONST_TYPE_OF_LEAVE.leaveSick &&
            actionOnLeave == "Approve"
          ) {
            totalSickLeaveQuantity += leave?.quantity;
          } else if (
            leave?.typeOfLeave === CONST_TYPE_OF_LEAVE.leaveAnnual &&
            actionOnLeave == "Approve"
          ) {
            totalAnnualLeaveQuantity += leave?.quantity;
          } else if (
            leave?.typeOfLeave === CONST_TYPE_OF_LEAVE.timeOf &&
            actionOnLeave == "Approve"
          ) {
            totalCoffLeaveQuantity += leave?.quantity;
          }
        });

        // Sick leave calculation
        noOfMedicalLeavesTaken =
          employeeLeaveData?.noOfMedicalLeavesTaken + totalSickLeaveQuantity;
        noOfMedicalLeavesRemaining =
          employeeLeaveData?.noOfMedicalLeaves - noOfMedicalLeavesTaken;

        //Annual leave calculation
        noOfLeavesTaken =
          employeeLeaveData?.noOfLeavesTaken + totalAnnualLeaveQuantity;
        noOfLeavesRemaining = employeeLeaveData?.noOfLeaves - noOfLeavesTaken;

        noOfCoffLeaveTaken = employeeLeaveData?.noOfCOffLeavesTaken + totalCoffLeaveQuantity;
        noOfCoffLeaveRemaining  = employeeLeaveData?.noOfCOffLeaves - noOfCoffLeaveTaken;
        
        
        
        const emp = {
          noOfLeavesTaken: noOfLeavesTaken,
          noOfLeavesRemaining: noOfLeavesRemaining,
          noOfMedicalLeavesTaken: noOfMedicalLeavesTaken,
          noOfMedicalLeavesRemaining: noOfMedicalLeavesRemaining,
          noOfCOffLeavesTaken: noOfCoffLeaveTaken,
          noOfCOffLeavesRemaining: noOfCoffLeaveRemaining,
          cognitoId: userID,
        };
        log.debug("update leaves now to: ", emp);
        const updatedEmployee = await hookUpdateEmployee(emp);

        log.debug("update leaves now to: employee ", updatedEmployee);
      }

      if (results?.length > 0)
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr,
            contextField: "leaves",
            value: results,
          },

          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully updated leave status",
          },
        ]);
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed upadate leave status",
        },
      ]);
    } catch (error) {
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed upadate leave status",
        },
      ]);
    }
  });
