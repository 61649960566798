import React from "react";
import log from "loglevel";
import useForm from "../../customHooks/useForm";
import moment from "moment";

import {
  useAuthContextData,
  useAuthService,
} from "../../customHooks/authMachineHooks";

export default function AddFirstEmployee() {
  const { organizationID, userProfile, email } = useAuthContextData();

  const authService = useAuthService();

  const { inputs, handleChange } = useForm({
    firstName: "",
    // middleName: "",
    LastName: "",
    email: email,
    // contactNo: "",
    // personalEmail: email,
    // userType: "",
    // uniqueIdentification: "",
    // dateOfBirth: "",
    dateOfHire: "",
    yearlyWage: 0,
    // workLocation: "",
    // emergencyContact1: "",
    // emergencyContact1Name: "",
    // emergencyContact1Relation: "",
    // emergencyContact2: "",
    // emergencyContact2Name: "",
    // emergencyContact2Relation: "",
    // permanentAddress: "",
    // currentAddress: "",
    workHoursPerWeek: 0,
    noOfLeaves: 10,
    noOfLeavesTaken: 0,
    noOfLeavesRemaining: 10,
    noOfMedicalLeaves: 12,
    noOfMedicalLeavesTaken: 0,
    noOfMedicalLeavesRemaining: 12,
  });
  async function approveUser() {
    // if (selectedGuide === null && isUserTypeSelectedEmployee) return;

    //Get Employee by ID

    var date = new Date();
    var components = [
      date.getYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ];
    var componentsToNumber = Number(components.join(""));
    log.debug("componentsToNumber", componentsToNumber);
    let myEmail = inputs?.email;
    const dateToUpdate = moment(date).format("YYYY-MM-DD");
    myEmail = myEmail.substring(0, 4);
    log.debug("myEmail", myEmail, date);

    const employeeID = (
      myEmail +
      dateToUpdate +
      componentsToNumber
    ).toUpperCase();
    authService.send({
      type: "create_hradmin_employee_profile",
      data: {
        employeeProfileToBeAdded: {
          cognitoId: userProfile.cognitoId,
          guideID: userProfile.cognitoId,
          organizationID: organizationID,
          ...inputs,
          yearlyWage: 0,
          userType: userProfile.userType,
          employeeID: employeeID,
        },
      },
    });
  }

  return (
    <div className=" z-10 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <form
          method="POST"
          className="divide-y-slate-200 mt-6 space-y-8 divide-y"
        >
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <h2 className="text-xl font-medium ">Profile</h2>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 "
              >
                First name
              </label>
              <input
                type="text"
                name="firstName"
                onChange={handleChange}
                value={inputs?.firstName}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div>

            {/* <div className="sm:col-span-3">
              <label
                htmlFor="middleName"
                className="block text-sm font-medium leading-6 "
              >
                Middle name
              </label>
              <input
                type="text"
                name="middleName"
                onChange={handleChange}
                value={inputs?.middleName}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div> */}
            <div className="sm:col-span-3">
              <label
                htmlFor="LastName"
                className="block text-sm font-medium leading-6 "
              >
                Last name
              </label>
              <input
                type="text"
                name="LastName"
                onChange={handleChange}
                value={inputs?.LastName}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div>
            {/* <div className="sm:col-span-3">
              <label
                htmlFor="contactNo"
                className="block text-sm font-medium leading-6 "
              >
                Contact Number
              </label>
              <input
                required
                type="number"
                name="contactNo"
                value={inputs?.contactNo}
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div> */}
            <div className="sm:col-span-6">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 "
              >
                Official Email
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
                <input
                  type="email"
                  name="email"
                  value={inputs?.email}
                  onChange={handleChange}
                  disabled
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* <div className="sm:col-span-6">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 "
              >
                Personal email
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
                <input
                  disabled
                  type="email"
                  name="personalEmail"
                  id="personalEmail"
                  onChange={handleChange}
                  value={inputs?.personalEmail}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}
            {/* <div className="sm:col-span-3">
              <label
                htmlFor="uniqueIdentification"
                className="block text-sm font-medium leading-6 "
              >
                Unique Identification
              </label>
              <input
                required
                type="text"
                name="uniqueIdentification"
                value={inputs?.uniqueIdentification}
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div> */}

            {/* <div className="sm:col-span-3">
              <label
                htmlFor="dateOfBirth"
                className="block text-sm font-medium leading-6 "
              >
                Date Of Birth
              </label>
              <input
                required
                type="date"
                name="dateOfBirth"
                value={inputs?.dateOfBirth}
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div> */}
            <div className="sm:col-span-3">
              <label
                htmlFor="dateOfHire"
                className="block text-sm font-medium leading-6 "
              >
                Date Of Hire
              </label>
              <input
                required
                type="date"
                name="dateOfHire"
                value={inputs?.dateOfHire}
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div>

            {/* <div className="sm:col-span-3">
              <label
                htmlFor="permanentAddress"
                className="block text-sm font-medium leading-6 "
              >
                Permanent Address
              </label>
              <textarea
                type="text"
                name="permanentAddress"
                value={inputs?.permanentAddress}
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-slate-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div> */}

            {/* {tostmessage === true ? (
              <ToastMessage
                HideToastMessage={hideMessageTost}
                showToast={tostmessage}
                messageType="Success"
                message={
                  "Employee personal information was updated successfully "
                }
              />
            ) : (
              <></>
            )} */}
          </div>
        </form>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            onClick={async () => {
              await approveUser();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
