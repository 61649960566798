import * as mutations from "../graphql/mutations";
import { API } from "aws-amplify";
import log from "loglevel";
import * as queries from "../graphql/queries";
export const hookAddBlogs = async (BlogsData) => {
  log.debug(" hookAddBlogs : ", BlogsData);

  try {
    const result = await API.graphql({
      query: mutations.createPost,
      variables: {
        input: BlogsData,
      },
    });

    log.debug(" hookAddBlogs result: ", result);

    return result?.data?.createPost;
  } catch (err) {
    log.debug("CATCH ERROR hookAddBlogs result: ", err);
    return null;
  }

};


export const hookGetBlogsByOrgId = async (orgID) => {
  log.debug(" hookGetBlogsByOrgId ", orgID);

  try {
    const data = await API.graphql({
      query: queries.PostsByOrganizationID,
      variables: {
        organizationID: orgID,
      },
    });

    log.debug(" hookGetBlogsByOrgId: ", data);

    if (data !== null) return data?.data?.PostsByOrganizationID;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetBlogsByOrgId -- ", e);
    return null;
  }
};


export const hookDeleteBlogByBlogId = async (blogId) => {
  log.debug(" hookDeleteBlogByBlogId : ", blogId);
  try {
    const result = await API.graphql({
      query: mutations.deletePost,
      variables: {
        input: { id: blogId },
      },
    });

    log.debug(" hookDeleteBlogByBlogId result: ", result);
    return result;
  } catch (err) {
    log.debug("CATCH ERROR hookDeleteBlogByBlogId result: ", err);
  }
};

export const hookUpdateBlog = async (blog) => {
  log.debug(" hookUpdateBlog : ", blog);
  try {
    const result = await API.graphql({
      query: mutations.updatePost,
      variables: {
        input: blog,
      },
    });

    log.debug(" hookUpdateLeave result: ", result);
    return result?.data?.updatePost;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateBlog result: ", err);
  }
};

