import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddOrgRoutes = async (orgRoutesData) => {
  log.debug(" hookAddOrgRoutes : ", orgRoutesData);

  try {
    const result = await API.graphql({
      query: mutations.createOrgRoutes,
      variables: {
        input: orgRoutesData,
      },
    });

    log.debug(" hookAddOrgRoutes result: ", result);

    return result?.data?.createOrgRoutes;
  } catch (err) {
    log.debug("CATCH ERROR hookAddOrgRoutes result: ", err);
    return null;
  }
};

export const hookUpdateOrgRoutes = async (orgRoutesData) => {
  log.debug(" hookUpdateOrgRoutes : ", orgRoutesData);
  try {
    const result = await API.graphql({
      query: mutations.updateOrgRoutes,
      variables: {
        input: orgRoutesData,
      },
    });

    log.debug(" hookUpdateOrgRoutes result: ", result);
    return result?.data?.updateOrgRoutes;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateOrgRoutes result: ", err);
  }
};

export const hookDeleteOrgRoutes = async (OrgRouteId) => {
  log.debug(" hookDeleteOrgRoutes : ", OrgRouteId);
  try {
    const result = await API.graphql({
      query: mutations.deleteOrgRoutes,
      variables: {
        input: { orgName: OrgRouteId },
      },
    });

    log.debug(" hookDeleteOrgRoutes result: ", result);
    return result?.data?.deleteOrgRoutes;
  } catch (err) {
    log.debug("CATCH ERROR hookDeleteOrgRoutes result: ", err);
  }
};

export const hookGetOrgRoutes = async (orgUniqueName) => {
  log.debug(" hookGetOrgRoutes ++  orgUniqueName", orgUniqueName);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getOrgRoutes,
      variables: {
        orgName: orgUniqueName,
      },
    });
    log.debug(" hookGetOrgRoutes : ", data);
    if (data?.data?.getOrgRoutes) {
      log.debug(" hookGetOrgRoutes : ", data?.data?.getOrgRoutes);
      return data?.data?.getOrgRoutes;
    } else {
      return null;
    }
  } catch (e) {
    console.error("CATCH ERROR hookGetOrgRoutes -- ", e);
    return null;
  }
};
