import React from "react";
import { Label } from "../CustomComponentsLibrary/Label";
import { Input } from "../CustomComponentsLibrary/Input";
import { Button } from "../CustomComponentsLibrary/Button";
import {Trash, Upload } from "lucide-react";
import PopoverCard from "../../components/common/PopoverCard";
import FileViewer from "../employee/Courses/FileViewer";
import S3FileViewer from "../employee/Courses/S3FileViewer";
export function ImageSelectViewUpate({
  handleUploadFile: handleFileChange,
  id,
  title,
  setSelectedFile,
  selectedFile,
  handleResourceKeyName,
}) {
  return (
    <div>
      {selectedFile.type === "" ? (
        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <Label htmlFor="logoBig" className="block  font-semibold mb-2">
            {title}
          </Label>
          <div className="flex border border-gray-300 rounded-md px-3">
            <Input
              type="file"
              id={id}
              name={id}
              required
              onChange={handleFileChange}
              className="border-none"
            />
            <Upload
              className="h-4 w-4 m-auto"
              onClick={() => handleFileChange}
            />
          </div>
        </div>
      ) : selectedFile.type === "selectedFile" ? (
        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <Label
            htmlFor="selectedFile"
            className="block text-sm font-semibold leading-6 text-foreground"
          >
            {title}
          </Label>
          <div
            id="selectedFile"
            className="text-blue-500 block text-sm font-bold leading-6 text-foreground"
          >
            {selectedFile?.file?.name}
            <PopoverCard
              title={"Delete File"}
              description={"Click this button to delete the File."}
            >
              <Button
                variant="danger"
                size="sm"
                className="inline-flex ml-2 text-base font-semibold leading-5"
                onClick={() => {
                  setSelectedFile({ type: "", file: null });
                  handleResourceKeyName({ field: id, value: "" });
                  delete { id };
                }}
              >
                <Trash className="h-4 w-4" />
              </Button>
            </PopoverCard>
            <FileViewer selectedFile={selectedFile?.file} />
          </div>
        </div>
      ) : (
        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <div className=" text-blue-500 inline-flex text-sm font-bold leading-6 text-foreground">
            {title}
            <PopoverCard
              title={"Delete File"}
              description={"Click this button to delete the File."}
            >
              <Button
                variant="danger"
                size="sm"
                className="inline-flex ml-2 text-base font-semibold leading-5"
                onClick={() => {
                  setSelectedFile({ type: "", file: null });
                  handleResourceKeyName({ field: id, value: "" });
                  delete { id };
                }}
              >
                <Trash className="h-4 w-4" />
              </Button>
            </PopoverCard>
          </div>
          <S3FileViewer url={selectedFile?.file} />
        </div>
      )}
    </div>
  );
}
