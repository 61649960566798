import {
  ScrollArea,
  ScrollBar,
} from "../../../components/CustomComponentsLibrary/ScrollArea";

export function DataTableWrapper({ children }) {
  return (
    <div>
      <ScrollArea className="h-auto">
        <div>{children}</div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  );
}
