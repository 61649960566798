import React, { useState } from "react";

import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "../../CustomComponentsLibrary/Button";

export const S3SignedPDFViewer = ({ s3SignedUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  pdfjs.verbosity = 5;
  const handleOpenPDF = () => {
    window.open(s3SignedUrl, "_blank");
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <>
        <Button className="" onClick={handleOpenPDF}>
          Download PDF
        </Button>

        <Document
          className="flex flex-col justify-center items-center overflow-y w-full"
          file={s3SignedUrl}
          onLoadError={(error) => {
            console.error("Error loading PDF:", error);
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {/* <Page /> */}
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((pageNumber) => (
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
              />
            ))}
        </Document>
      </>
    </div>
  );
};
