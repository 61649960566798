import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./table";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
} from "@tanstack/react-table";
import { DataTableToolbar } from "./data-table-toolbar";

import { Skeleton } from "../CustomComponentsLibrary/Skeleton";
import { DataTableWrapper } from "../../pages/employee/metrics/DataTableWrapper";
export type Option = {
  label: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
};

export interface DataTableSearchableColumn<TData> {
  id: keyof TData;
  title: string;
}

export interface DataTableFilterableColumn<TData>
  extends DataTableSearchableColumn<TData> {
  options: Option[];
}

export interface DataTableCustomWidth<TData> {
  columnIndex: Number;
  widthClass: String;
  cssStyleClass: String;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  loader: boolean;
  className: any;
  customCss: any;
  pageCount: number;
  filterableColumns?: DataTableFilterableColumn<TData>[];
  searchableColumns?: DataTableSearchableColumn<TData>[];
  dataTableToolBar: boolean;
  setRowSelected: any;
  rowSelected: any;
  customWidth: DataTableCustomWidth<TData>[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
  loader,
  className,
  customCss,
  searchableColumns = [],
  filterableColumns = [],
  dataTableToolBar = true,
  setRowSelected = () => {},
  rowSelected = {},
  customWidth = [],
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting,
      rowSelection: rowSelected,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelected,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
  });
  function findElementByIndex(indexToFind: number): any | undefined {
    const foundElement = customWidth.find(
      (element: any) => element.index === indexToFind
    );
    return foundElement;
  }
  function firstlastElementByIndex(indexToFind: number): any | undefined {
    var foundFirstLast = "";
    if (indexToFind === 0) {
      foundFirstLast = "rounded-tl-lg";
    } else {
      foundFirstLast = "rounded-tr-lg";
    }
    return foundFirstLast;
  }

  return (
    <DataTableWrapper>
      <div className={`"w-full space-y-4 text-base font-medium" ${className}`}>
        {dataTableToolBar ? (
          <DataTableToolbar
            table={table}
            filterableColumns={filterableColumns}
            searchableColumns={searchableColumns}
          />
        ) : null}
        <div className={`${customCss}`}>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => {
                    const columnClass =
                      findElementByIndex(index)?.widthClass || "";
                    const bgClass =
                      findElementByIndex(index)?.cssStyleClass || "";
                    var firstClass = "";
                    if (index === 0) {
                      firstClass = firstlastElementByIndex(0);
                    } else if (headerGroup.headers.length - 1 === index) {
                      firstClass = firstlastElementByIndex(
                        headerGroup.headers.length - 1
                      );
                    }

                    return (
                      <TableHead
                        key={header.id}
                        className={`${columnClass} + ${firstClass} + ${bgClass}`}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="text-base font-semibold text-slate-700"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : loader ? (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      <>
                        <div className="flex items-center w-full space-x-4">
                          <Skeleton className="h-12 w-12 rounded-full" />
                          <div className="space-y-2  w-[100%]">
                            <Skeleton className="h-4 w-[100%]" />
                            <Skeleton className="h-4 w-[50%]" />
                          </div>
                        </div>
                        <div className="flex items-center w-full space-x-4">
                          <Skeleton className="h-12 w-12 rounded-full" />
                          <div className="space-y-2  w-[100%]">
                            <Skeleton className="h-4 w-[100%]" />
                            <Skeleton className="h-4 w-[50%]" />
                          </div>
                        </div>
                      </>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </DataTableWrapper>
  );
}
