import { Fragment, useState, useEffect, useRef } from "react";
import log from "loglevel";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  useEmployeeContextState,
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../hooks/employeeMachineHooks";
import useForm from "../../../customHooks/useForm";
import { CONST_GOAL_STATUS } from "../../../constants/dbconstants";
import GoalComments from "../../common/GoalComments";
import Dropdown from "../../common/Dropdown";
import {
  DialogBox,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../CustomComponentsLibrary/Dailog";
import { Input } from "../../CustomComponentsLibrary/Input";
import { Button } from "../../CustomComponentsLibrary/Button";
import { Label } from "../../CustomComponentsLibrary/Label";
import { Textarea } from "../../CustomComponentsLibrary/Textarea";
import { ScrollArea } from "../../CustomComponentsLibrary/ScrollArea";
import { Separator } from "../../CustomComponentsLibrary/Separator";
import { X } from "lucide-react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import { sortByKeyAlphabetical } from "../../common/SortByName";

export default function AddEditDeleteGoals() {
  const { goalCrud } = useEmployeeContextState();
  const employeeMachineService = useEmployeeContextService();
  const { theme } = useAuthContextData();
  const [dialogOpen] = useState(goalCrud === true ? true : false);
  const handleClose = () => {
    employeeMachineService.send({ type: "cancel_crud" });
  };

  log.debug("goalCrud", goalCrud);
  const { actionForGoalCRUD, selectedGoal, userID } = useEmployeeContextData();
  log.debug("actionForGoalCRUD, selectedGoal", actionForGoalCRUD, selectedGoal);

  const { inputs, handleChange, clearForm, resetForm } = useForm({
    title: selectedGoal ? selectedGoal.title : "",
    content: selectedGoal ? selectedGoal.content : "",
    dueDate: selectedGoal ? selectedGoal.dueDate : "",
    actualCompletedDate: selectedGoal ? selectedGoal.actualCompletedDate : null,
    specific: selectedGoal ? selectedGoal.specific : "",
    measurable: selectedGoal ? selectedGoal.measurable : "",
    attainable: selectedGoal ? selectedGoal.attainable : "",
    relevant: selectedGoal ? selectedGoal.relevant : "",
    timely: selectedGoal ? selectedGoal.timely : "",
    year: selectedGoal ? selectedGoal.year : "",
    status: selectedGoal ? selectedGoal.status : "",
    percentage: selectedGoal ? selectedGoal.percentage : "",
  });

  const goalStatus = [
    { name: CONST_GOAL_STATUS.goalStatusCreated },
    { name: CONST_GOAL_STATUS.goalStatusInprocess },
    { name: CONST_GOAL_STATUS.goalStatusOnhold },
    { name: CONST_GOAL_STATUS.goalStatusCancelled },
    { name: CONST_GOAL_STATUS.goalStatusDone },
  ];
  const [selectedGoalStatus, setSelectedGoalStatus] = useState(
    goalStatus.find((element) => element.name === selectedGoal?.status) || null
  );
  log.debug("onclickAdd", inputs);

  const submitGoalNow = async (e) => {
    e.preventDefault();
    log.debug("submitGoalNow onclickAdd", inputs);

    if (selectedGoal) {
      log.debug("submitGoalNow if selectedGoal ", inputs);

      const goal2Add = {
        ...inputs,
        id: selectedGoal?.id,
        status: selectedGoalStatus?.name,
      };
      log.debug("submitgoalDataNow goal2Add", goal2Add);

      employeeMachineService.send({
        type: "update_goal",
        data: {
          goalToBeAdded: goal2Add,
        },
      });
    } else {
      log.debug("submitGoalDataNowADD onclickAdd", inputs);
      employeeMachineService.send({
        type: "create_goal",
        data: {
          goalToBeAdded: {
            ...inputs,
            status: CONST_GOAL_STATUS.goalStatusCreated,
            assignedTo: userID,
            assignedTo: userID,
            userID: userID,
            percentage: 0,
          },
        },
      });
    }
  };
  const today = new Date().toISOString().split("T")[0]; // get current date in yyyy-mm-dd format
  if (goalCrud) {
    switch (actionForGoalCRUD) {
      case "DELETE":
        return (
          <Transition.Root show={goalCrud} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                employeeMachineService.send({ type: "cancel_crud" });
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <Button
                          type="submit"
                          size="xs"
                          variant="darkOutline"
                          className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          <X className="h-4 w-4 text-" />
                          <span className="sr-only">Close</span>
                        </Button>
                      </div>
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-semoibold leading-6 text-foreground"
                          >
                            Are you absolutely sure?
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete the Goal?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => {
                            employeeMachineService.send({
                              type: "delete_goal",
                            });
                          }}
                        >
                          Continue
                        </Button>
                        <Button
                          type="button"
                          className="mr-2"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        );

      case "UPDATE":
        return (
          <DialogBox open={dialogOpen} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[40%]">
              <Button
                size="xs"
                variant="dark"
                className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                onClick={() =>
                  employeeMachineService.send({
                    type: "cancel_crud",
                  })
                }
              >
                <X className="h-4 w-4 text-" />
                <span className="sr-only">Close</span>
              </Button>
              <ScrollArea className="h-96 sm:h-[600px]">
                <DialogHeader>
                  <DialogTitle>Update Goal</DialogTitle>
                  <DialogDescription>
                    Update status of Goals here. Click save when you're done.
                  </DialogDescription>
                </DialogHeader>

                <form onSubmit={submitGoalNow} method="POST">
                  <div className="flex flex-col p-1">
                    <div className="flex-row w-full">
                      <Label htmlFor="Title" className="text-right">
                        Title
                      </Label>
                      <Input
                        required
                        defaultValue={inputs?.title}
                        type="text"
                        onChange={handleChange}
                        name="title"
                        id="title"
                        placeholder="Title"
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row w-full">
                      <Label htmlFor="content" className="text-right">
                        Content
                      </Label>

                      <Textarea
                        required
                        defaultValue={inputs?.content}
                        type="text"
                        onChange={handleChange}
                        name="content"
                        id="content"
                        placeholder="Content"
                        className={`${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="dueDate" className="text-right">
                        Due Date
                      </Label>
                      <Input
                        disabled="true"
                        required
                        defaultValue={inputs?.dueDate}
                        type="date"
                        onChange={handleChange}
                        name="dueDate"
                        id="dueDate"
                        min={today}
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="specific" className="text-right">
                        Specific
                      </Label>
                      <Textarea
                        defaultValue={inputs?.specific}
                        type="text"
                        onChange={handleChange}
                        name="specific"
                        id="specific"
                        placeholder="Specific"
                        className={`${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="measurable" className="text-right">
                        Measurable
                      </Label>
                      <Textarea
                        defaultValue={inputs?.measurable}
                        type="text"
                        onChange={handleChange}
                        name="measurable"
                        id="measurable"
                        placeholder="Measurable"
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row">
                      <Label htmlFor="attainable" className="text-right">
                        Attainable
                      </Label>
                      <Textarea
                        defaultValue={inputs?.attainable}
                        type="text"
                        onChange={handleChange}
                        name="attainable"
                        id="attainable"
                        placeholder="attainable"
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row">
                      <Label htmlFor="Relevant" className="text-right">
                        Relevant
                      </Label>
                      <Textarea
                        defaultValue={inputs?.relevant}
                        type="text"
                        onChange={handleChange}
                        name="relevant"
                        id="relevant"
                        placeholder="Relevant"
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className="flex-row">
                      <Label htmlFor="timely" className="text-right">
                        Timely
                      </Label>
                      <Textarea
                        defaultValue={inputs?.timely}
                        type="text"
                        onChange={handleChange}
                        name="timely"
                        id="timely"
                        placeholder="Timely"
                        className={`${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="year" className="text-right">
                        Year
                      </Label>
                      <Input
                        defaultValue={inputs?.year}
                        type="number"
                        min="0"
                        onChange={handleChange}
                        name="year"
                        id="year"
                        placeholder="year"
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label
                        htmlFor="actualCompletedDate"
                        className="text-right"
                      >
                        Actual complition date
                      </Label>
                      <Input
                        defaultValue={inputs?.actualCompletedDate}
                        type="date"
                        onChange={handleChange}
                        name="actualCompletedDate"
                        id="actualCompletedDate"
                        max={today}
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="Percentage" className="text-right">
                        Goal Status
                      </Label>
                      <Dropdown
                        list={sortByKeyAlphabetical(goalStatus, "name")}
                        setSelected={setSelectedGoalStatus}
                        selected={selectedGoalStatus}
                        onChange={handleChange}
                      />
                    </div>
                    <div className=" flex-row">
                      <Label htmlFor="Percentage" className="text-right">
                        Percentage
                      </Label>
                      <Input
                        defaultValue={inputs?.percentage}
                        type="number"
                        onChange={handleChange}
                        name="percentage"
                        id="percentage"
                        max={100}
                        min={0}
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>
                  </div>
                  <DialogFooter className="pt-2">
                    <Button
                      type="button"
                      onClick={() =>
                        employeeMachineService.send({
                          type: "cancel_crud",
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Save changes</Button>
                  </DialogFooter>
                </form>

                <GoalComments goalId={selectedGoal?.id} />
              </ScrollArea>
            </DialogContent>
          </DialogBox>
        );

      case "EDIT":

      case "ADD":
        return (
          <DialogBox open={dialogOpen} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[40%]">
              <Button
                size="xs"
                variant="dark"
                className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                onClick={() =>
                  employeeMachineService.send({
                    type: "cancel_crud",
                  })
                }
              >
                <X className="h-4 w-4 text-" />
                <span className="sr-only">Close</span>
              </Button>
              <ScrollArea className="h-96 sm:h-[600px]">
                <DialogHeader>
                  <DialogTitle>
                    {selectedGoal ? "Edit Goal" : "Add Goal"}
                  </DialogTitle>
                  <DialogDescription>
                    Make changes to your Goals here. Click save when you're
                    done.
                  </DialogDescription>
                </DialogHeader>

                <form onSubmit={submitGoalNow} method="POST">
                  <div className="flex flex-col p-1">
                    <div className="flex-row w-full">
                      <Label htmlFor="Title" className="text-right">
                        Title
                      </Label>
                      <Textarea
                        required
                        defaultValue={inputs?.title}
                        type="text"
                        onChange={handleChange}
                        name="title"
                        id="title"
                        placeholder="Title"
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row w-full">
                      <Label htmlFor="content" className="text-right">
                        Content
                      </Label>

                      <Textarea
                        required
                        defaultValue={inputs?.content}
                        type="text"
                        onChange={handleChange}
                        name="content"
                        id="content"
                        placeholder="Content"
                        className={`${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="dueDate" className="text-right">
                        Due Date
                      </Label>
                      {selectedGoal?.dueDate ? (
                        <>
                          <Input
                            className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                            required
                            defaultValue={inputs?.dueDate}
                            type="date"
                            onChange={handleChange}
                            name="dueDate"
                            id="dueDate"
                            disabled
                          />
                        </>
                      ) : (
                        <>
                          <Input
                            required
                            defaultValue={inputs?.dueDate}
                            type="date"
                            onChange={handleChange}
                            name="dueDate"
                            id="dueDate"
                            min={today}
                            className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                          />
                        </>
                      )}
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="specific" className="text-right">
                        Specific
                      </Label>
                      <Textarea
                        defaultValue={inputs?.specific}
                        type="text"
                        onChange={handleChange}
                        name="specific"
                        id="specific"
                        placeholder="Specific"
                        className={`${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="measurable" className="text-right">
                        Measurable
                      </Label>
                      <Textarea
                        defaultValue={inputs?.measurable}
                        type="text"
                        onChange={handleChange}
                        name="measurable"
                        id="measurable"
                        placeholder="Measurable"
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row">
                      <Label htmlFor="attainable" className="text-right">
                        Attainable
                      </Label>
                      <Textarea
                        defaultValue={inputs?.attainable}
                        type="text"
                        onChange={handleChange}
                        name="attainable"
                        id="attainable"
                        placeholder="attainable"
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row">
                      <Label htmlFor="Relevant" className="text-right">
                        Relevant
                      </Label>
                      <Textarea
                        defaultValue={inputs?.relevant}
                        type="text"
                        onChange={handleChange}
                        name="relevant"
                        id="relevant"
                        placeholder="Relevant"
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className="flex-row">
                      <Label htmlFor="timely" className="text-right">
                        Timely
                      </Label>
                      <Textarea
                        defaultValue={inputs?.timely}
                        type="text"
                        onChange={handleChange}
                        name="timely"
                        id="timely"
                        placeholder="Timely"
                        className={`${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="year" className="text-right">
                        Year
                      </Label>
                      <Input
                        defaultValue={inputs?.year}
                        min="0"
                        type="number"
                        onChange={handleChange}
                        name="year"
                        id="year"
                        placeholder="year"
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>
                  </div>
                  <DialogFooter className="pt-2">
                    <Button
                      type="button"
                      onClick={() =>
                        employeeMachineService.send({
                          type: "cancel_crud",
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Save changes</Button>
                  </DialogFooter>
                </form>

                {selectedGoal ? (
                  <GoalComments goalId={selectedGoal?.id} />
                ) : null}
              </ScrollArea>
            </DialogContent>
          </DialogBox>
        );
    }
  }
  return null;
}
