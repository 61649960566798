import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { API } from "aws-amplify";
import log from "loglevel";
import { isDateBetween } from "../lib/dateService";

export const hookListSalarySlip = async () => {
  log.debug(" hookListSalarySlip ");

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.listSalarySlips,
      variables: {},
    });

    log.debug(" hookListSalarySlip : ", data);

    if (data !== null) return data?.data?.listSalarySlips.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListSalarySlip -- ", e);
    return null;
  }
};

export const hookAddSalarySlip = async (SalarySlip) => {
  log.debug(" hookAddSalarySlip : ", SalarySlip);

  try {
    const result = await API.graphql({
      query: mutations.createSalarySlip,
      variables: {
        input: SalarySlip,
      },
    });

    log.debug(" hookAddSalarySlip result: ", result);

    return result?.data?.createSalarySlip;
  } catch (err) {
    log.debug("CATCH ERROR hookAddSalarySlip result: ", err);
    return null;
  }
};

export const hookUpdateSalarySlip = async (SalarySlip) => {
  log.debug(" hookUpdateSalarySlip : ", SalarySlip);
  try {
    const result = await API.graphql({
      query: mutations.updateSalarySlip,
      variables: {
        input: SalarySlip,
      },
    });

    log.debug(" hookUpdateSalarySlip result: ", result);
    return result?.data?.updateSalarySlip;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateSalarySlip result: ", err);
  }
};

export const hookDeleteSalarySlipBySalarySlipId = async (SalarySlipId) => {
  log.debug(" hookDeleteSalarySlipBySalarySlipId  ", SalarySlipId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteSalarySlip,
      variables: {
        input: { salarySlipID: SalarySlipId },
      },
    });

    log.debug(" hookDeleteSalarySlipBySalarySlipId : ", data);
    if (data !== null) return data?.data?.deleteSalarySlip;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeleteSalarySlipBySalarySlipUniqueID -- ",
      e
    );
    return null;
  }
};

export const hookListSalarySlipByUserId = async (userID) => {
  log.debug(" hookListSalarySlipByUserId userID", userID);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: customQueries.salarySlipsByUserIDCustom,
      variables: {
        sortDirection: "DESC",
        userID: userID,
      },
    });

    log.debug(" hookListSalarySlipByUserId : ", data);

    if (data !== null) return data?.data?.SalarySlipsByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListSalarySlipByUserId -- ", e);
    return null;
  }
};

export const hookGetSalarySlipById = async (SalarySlipId) => {
  log.debug(" hookGetSalarySlipById ++ ", SalarySlipId);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: queries.getSalarySlip,
      variables: {
        salarySlipID: SalarySlipId,
      },
    });

    log.debug(" hookGetSalarySlipById : ", result);
    return result?.data?.getSalarySlip;
  } catch (e) {
    console.error("CATCH ERROR hookGetSalarySlipById -- ", e);

    return null;
  }
};

export const hookListSalarySlipByOrgId = async (OrgId) => {
  log.debug(" hookListSalarySlipByOrgId", OrgId);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.SalarySlipsByOrganizationID,
      variables: {
        organizationID: OrgId,
        limit: 100,
      },
    });

    log.debug(" hookListSalarySlipByOrgId : ", data);

    if (data !== null) return data?.data?.SalarySlipsByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListSalarySlipByUserId -- ", e);
    return null;
  }
};

export const hookGetOrgSalarySlipssByDateRange = async (orgId, dateRange) => {
  log.debug("hookGetOrgSalarySlipssByDateRange OrgId", orgId);
  try {
    const { from, to } = dateRange;
    const data = await API.graphql({
      query: queries.SalarySlipsByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" hookGetOrgSalarySlipssByDateRange : ", data);

    const salarySlipsByOrg = data?.data?.SalarySlipsByOrganizationID?.items;
    const filteredSalarySlips = salarySlipsByOrg.filter((salaryslip) =>
      isDateBetween(from, to, salaryslip?.createdAt)
    );
    if (data !== null) return filteredSalarySlips;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetOrgSalarySlipssByDateRange -- ", e);
    return null;
  }
};
