import React from "react";
import log from "loglevel";
import { Storage } from "aws-amplify";
import { useState, useEffect } from "react";
import useForm from "../../../customHooks/useForm";
import { useParams } from "react-router-dom";
import ToastMessage from "../../common/ToastMessage";
import { hookGetGuideDetailsbyGuideId } from "../../../hooks/employeeHooks";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { CONST_EMPLOYEE_STATUS } from "../../../constants/dbconstants";
import { FriendlyName } from "../../../lib/friendlyName";
import { Input } from "../../CustomComponentsLibrary/Input";
import { Label } from "../../CustomComponentsLibrary/Label";
import { Textarea } from "../../CustomComponentsLibrary/Textarea";
import { Button } from "../../CustomComponentsLibrary/Button";
import { useToast } from "../../CustomComponentsLibrary/UseToast";
import ProfilePictureUpload from "./ProfilePictureUpload";
import PageHeader from "../../common/PageHeader";

import { ThemeConstant } from "../../../constants/ThemeConstant";
import {
  useAuthContextData,
  useAuthService,
} from "../../../customHooks/authMachineHooks";
export default function UpdateEmployeeProfile() {
  const { personalProfile, userID } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const { toast } = useToast();
  const [guideDetails, setGuideDetails] = useState(null);
  const employeeMachineService = useEmployeeContextService();
  const authService = useAuthService();
  const employeeStatus = [
    { name: CONST_EMPLOYEE_STATUS.employeeNewHire },
    { name: CONST_EMPLOYEE_STATUS.employeeOnBoarding },
    { name: CONST_EMPLOYEE_STATUS.employeeOnProbation },
    { name: CONST_EMPLOYEE_STATUS.employeeOnPIP },
    { name: CONST_EMPLOYEE_STATUS.employeeNormal },
    { name: CONST_EMPLOYEE_STATUS.employeeTerminated },
    { name: CONST_EMPLOYEE_STATUS.employeeVerifyFailed },
    { name: CONST_EMPLOYEE_STATUS.employeeVerifyPending },
  ];
  const [tostmessage, setTostmessage] = useState(false);

  log.debug("personalProfile ", personalProfile);
  const params = useParams();
  log.debug("params::CandidateOnlineTest", params);

  const { inputs, handleChange, clearForm, resetForm } = useForm({
    firstName: personalProfile ? personalProfile.firstName : "",
    middleName: personalProfile ? personalProfile.middleName : "",
    LastName: personalProfile ? personalProfile.LastName : "",
    email: personalProfile ? personalProfile.email : "",
    contactNo: personalProfile ? personalProfile.contactNo : "",
    personalEmail: personalProfile ? personalProfile.personalEmail : "",
    userType: personalProfile ? personalProfile.userType : "",
    uniqueIdentification: personalProfile
      ? personalProfile.uniqueIdentification
      : "",
    dateOfBirth: personalProfile ? personalProfile.dateOfBirth : "",
    dateOfHire: personalProfile ? personalProfile.dateOfHire : "",
    yearlyWage: personalProfile ? personalProfile.yearlyWage : "",
    workLocation: personalProfile ? personalProfile.workLocation : "",
    designation: personalProfile ? personalProfile.designation : "",
    emergencyContact1: personalProfile ? personalProfile.emergencyContact1 : "",
    emergencyContact1Name: personalProfile
      ? personalProfile.emergencyContact1Name
      : "",
    emergencyContact1Relation: personalProfile
      ? personalProfile.emergencyContact1Relation
      : "",
    emergencyContact2: personalProfile ? personalProfile.emergencyContact2 : "",
    emergencyContact2Name: personalProfile
      ? personalProfile.emergencyContact2Name
      : "",
    emergencyContact2Relation: personalProfile
      ? personalProfile.emergencyContact2Relation
      : "",
    permanentAddress: personalProfile ? personalProfile.permanentAddress : "",
    currentAddress: personalProfile ? personalProfile.currentAddress : "",
    workHoursPerWeek: personalProfile ? personalProfile.workHoursPerWeek : "",
    profilePicture: personalProfile?.profilePicture || "",
    PAN: personalProfile?.PAN || "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  function hideMessageTost() {
    log.debug("showtost", tostmessage);
    setTostmessage(false);
  }
  log.debug("updateTeamMember userID", userID);

  const updateTeamMember = async (e) => {
    e.preventDefault();

    log.debug("updateTeamMember onclickAdd", inputs);
    let filename =
      personalProfile?.firstName + "_" + "ProfilePic" + "_" + userID;
    const updateTeamMember = {
      ...inputs,
      cognitoId: userID,
      profilePicture: selectedFile ? filename : inputs.profilePicture,
    };
    if (selectedFile) {
      await handleImageSave(filename);
    }
    employeeMachineService.send({
      type: "update_profile",
      data: {
        personalProfileToBeUpdate: updateTeamMember,
      },
    });
  };

  const handleImageSave = async (filename) => {
    // Here, you can implement the logic to upload the image to  server and save it to the user's profile.

    if (selectedFile) {
      const fileType = selectedFile.type;
      log.debug("fileType: :", fileType, filename);
      if (fileType === "jpg" || "png") {
        {
          try {
            await Storage.put(
              filename,
              selectedFile,
              { level: "public" },
              {
                progressCallback(progress) {
                  log.debug(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
              }
            );

            toast({
              title: "Success",
              description: "Profile picture successfully updated",
            });
            log.debug(" : after uploading pdf ", filename);
          } catch (error) {
            toast({
              variant: "destructive",
              title: "Failed",
              description: "Failed to update profile picture",
            });
            log.debug("Error uploading file: ", error);
          }
        }
      }
    } else {
      log.debug("----------------------->");
    }
  };

  const handleImageRemove = () => {
    inputs.profilePicture = "";
  };

  useEffect(() => {
    async function fetchGuideDetails() {
      if (personalProfile?.guideID) {
        const result = await hookGetGuideDetailsbyGuideId(
          personalProfile?.guideID
        );

        if (result) setGuideDetails(result);
      }
    }

    if (personalProfile) {
      fetchGuideDetails();
    }
  }, [personalProfile]);
  const handleTheme = (themeColor) => {
    const ThemeVariable = ThemeConstant(themeColor);

    localStorage.setItem("Theme", JSON.stringify(ThemeVariable));

    authService.send({
      type: "update_theme",
      data: {
        theme: ThemeVariable,
      },
    });
  };

  return (
    <>
      <div className="m-3">
        <div>
          <PageHeader
            title="Profile"
            SubHeading="Make changes to your employee profile here. This data will be visible to public."
          />
        </div>
        <div className="shadow-md rounded-lg mt-5 pb-10 border-t border-zinc-100">
          <div className="m-5 flex flex-col md:flex-row justify-start items-center  md:gap-20 gap-5">
            <ProfilePictureUpload
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              handleRemove={handleImageRemove}
            />
            <div className="flex flex-col gap-10 md:flex-row flex-wrap justify-start align-middle">
              <div className="flex flex-col h-[56px]">
                <p className="font-normal">Name</p>
                <div className="font-semibold">
                  <p>
                    {inputs?.firstName} {inputs?.LastName}
                  </p>
                </div>
              </div>
              <div className="flex flex-col h-[56px]">
                <p>Employment status</p>
                <FriendlyName status={personalProfile?.employeeStatus} />
              </div>
            </div>
            <div className="flex flex-col gap-10 md:flex-row flex-wrap">
              <div>
                <p>Email ID</p>
                <p className="font-semibold">{inputs?.email}</p>
              </div>
              <div>
                <p>Phone Number</p>
                <p className="font-semibold">{inputs?.contactNo}</p>
              </div>
              <div>
                <p>Guide Details</p>
                <div className="font-semibold">
                  {guideDetails?.firstName
                    ? guideDetails?.firstName
                    : "" + "  " + guideDetails?.LastName
                    ? guideDetails?.LastName
                    : ""}
                </div>
              </div>
            </div>
            <div className="flex flex-row  sm:flex-row lg:flex-row md:flex-col ">
              <div className="p-2">
                <Button
                  onClick={() => {
                    handleTheme("RED");
                  }}
                  className="bg-red-700 text-white"
                >
                  red
                </Button>
              </div>
              <div className="p-2">
                <Button
                  onClick={() => {
                    handleTheme("BLUE");
                  }}
                  className="bg-blue-500 text-white"
                >
                  Blue
                </Button>
              </div>
              <div className="p-2">
                <Button
                  onClick={() => {
                    handleTheme("DARK");
                  }}
                  className="bg-slate-800 text-white"
                >
                  dark
                </Button>
              </div>
            </div>
          </div>
          <div className="m-5 ml-7 mr-7">
            <hr className="border-zinc-200" />
          </div>
          <form onSubmit={updateTeamMember} method="POST">
            <div className="m-5 flex flex-col">
              <h6 className="text-common">Edit profile</h6>
              <div className="flex flex-col md:flex-row justify-start items-center gap-5 md:gap-20 mt-5">
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="first-name">First name</Label>
                  <div>
                    <Input
                      className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      type="type"
                      name="firstName"
                      onChange={handleChange}
                      value={inputs?.firstName}
                    />
                  </div>
                </div>

                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="middleName">Middle name</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="middleName"
                    onChange={handleChange}
                    value={inputs?.middleName}
                  />
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="LastName">Last name</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="LastName"
                    onChange={handleChange}
                    value={inputs?.LastName}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-start items-center gap-5 md:gap-20 mt-5">
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="username">Email</Label>
                  <div>
                    <Input
                      className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      type="email"
                      name="email"
                      value={inputs?.email}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="username">Personal email</Label>
                  <div>
                    <Input
                      className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      type="email"
                      name="personalEmail"
                      id="personalEmail"
                      onChange={handleChange}
                      value={inputs?.personalEmail}
                    />
                  </div>
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="uniqueIdentification">
                    Unique Identification
                  </Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="uniqueIdentification"
                    value={inputs?.uniqueIdentification}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-start items-center gap-5 md:gap-20 mt-5">
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="uniqueIdentification">PAN</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="PAN"
                    value={inputs?.PAN}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="dateOfBirth">Date of Birth</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="date"
                    name="dateOfBirth"
                    value={inputs?.dateOfBirth}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="dateOfHire">Date of Hire</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="date"
                    name="dateOfHire"
                    value={inputs?.dateOfHire}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="m-5 ml-7 mr-7">
              <hr className="border-zinc-200" />
            </div>
            <div className="m-5 flex flex-col">
              <h6 className="font-semibold">Emergency Contact Details</h6>
              <div className="flex flex-col md:flex-row justify-start items-center gap-5 md:gap-20 mt-5">
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="emergencyContact1">Emergency Contact 1</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="number"
                    min="0"
                    name="emergencyContact1"
                    value={inputs?.emergencyContact1}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="emergencyContact1Name">
                    Emergency Contact 1 Name
                  </Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="emergencyContact1Name"
                    value={inputs?.emergencyContact1Name}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="emergencyContact1Relation">
                    Emergency Contact 1 Relation
                  </Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="emergencyContact1Relation"
                    value={inputs?.emergencyContact1Relation}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-start items-center gap-5 md:gap-20 mt-5">
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="emergencyContact2">Emergency Contact 2</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="number"
                    min="0"
                    name="emergencyContact2"
                    value={inputs?.emergencyContact2}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="emergencyContact2Name">
                    Emergency Contact 2 Name
                  </Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="emergencyContact2Name"
                    value={inputs?.emergencyContact2Name}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:w-1/3 w-48">
                  <Label htmlFor="emergencyContact2Relation">
                    Emergency Contact 2 Relation
                  </Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="emergencyContact2Relation"
                    value={inputs?.emergencyContact2Relation}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="m-5 ml-7 mr-7">
              <hr className="border-zinc-200" />
            </div>
            <div className="m-5 flex flex-col">
              <h6 className="font-semibold">Address Details</h6>
              <div className="flex flex-col md:flex-row justify-start items-center gap-5 md:gap-20 mt-5 md:mr-64">
                <div className="sm:w-1/2 w-48">
                  <Label htmlFor="workLocation">Work Location</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="workLocation"
                    value={inputs?.workLocation}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:w-1/2 w-48">
                  <Label htmlFor="designation">Designation</Label>
                  <Input
                    className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                    type="text"
                    name="designation"
                    value={inputs?.designation}
                    onChange={handleChange}
                    placeholder="Designation"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-start items-center gap-5 md:gap-20 mt-5">
                <div className="sm:w-1/2 w-48 h-auto mt-5">
                  <Label htmlFor="currentAddress">Current Address</Label>
                  <div className={` ${theme?.border}`}>
                    <Textarea
                      type="text"
                      name="currentAddress"
                      value={inputs?.currentAddress}
                      onChange={handleChange}
                      className="h-[133px] mt-1"
                    />
                  </div>
                </div>
                <div className="sm:w-1/2 w-48 h-auto mt-5">
                  <Label htmlFor="permanentAddress">Permanent Address</Label>
                  <div className={` ${theme?.border}`}>
                    <Textarea
                      type="text"
                      name="permanentAddress"
                      value={inputs?.permanentAddress}
                      onChange={handleChange}
                      className="h-[133px] mt-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            {tostmessage === true ? (
              <ToastMessage
                HideToastMessage={hideMessageTost}
                showToast={tostmessage}
                messageType="Success"
                message={
                  "Employee personal information was updated successfully "
                }
              />
            ) : (
              <></>
            )}
            <div className="flex justify-center md:justify-end gap-x-3 pt-8 m-5">
              <Button
                varient="ghost"
                type="button"
                className={`"bg-slate-50" ${theme?.hoverbg} ${theme?.hoverText} ${theme?.border}`}
              >
                Cancel
              </Button>
              <Button
                varient="ghost"
                type="submit"
                className={`"bg-slate-50" ${theme?.hoverbg} ${theme?.hoverText} ${theme?.border}`}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
