import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  useAuthRegisterState,
  useAuthService,
  useAuthContextData,
} from "../../customHooks/authMachineHooks";
import { Link } from "react-router-dom/dist";
import { TextField } from "../Fields";
import { CameraIcon } from "@heroicons/react/24/outline";
import { ArrowLeft } from "lucide-react";
import Message from "../common/Messages";
import { cn } from "../../lib/utils";
import { Auth } from "aws-amplify";
import log from "loglevel";
import { Label } from "../CustomComponentsLibrary/Label";
import { Button } from "../CustomComponentsLibrary/Button";
import { Input } from "../CustomComponentsLibrary/Input";
import { TypographyH2 } from "../CustomComponentsLibrary/Typography";

export default function ConfirmCodeNewPassword() {
  const [message, setMessage] = useState({
    messageText: "",
    messageType: "",
  });
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const authService = useAuthService();
  const { error, email: username } = useAuthContextData();
  const [email, setEmail] = useState(username);
  const [code, setCode] = useState(0);

  function handleCodeUpdate(e) {
    log.debug(e.target.value, code);
    if (e.target.value.length < 7) setCode(e.target.value);
  }
  function handleResend() {
    Auth.forgotPassword(email);
  }
  function onPasswordsChange(e) {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    if (e.target.name === "confirmPassword") {
      if (passwords.password !== e.target.value) {
        setMessage({
          messageText: "confirm Password not matching with password!",
          messageType: "failure",
        });
      } else {
        setMessage({
          messageText: "both passwords  matching",
          messageType: "success",
        });
      }
    }
  }

  function handleSubmit() {
    log.debug("Submit", email, username, passwords.password);
    authService.send({
      type: "confirm_code_password",
      data: {
        forgotPasswordDetails: {
          email: email,
          code: code,
          password: passwords.password,
        },
      },
    });
    // setMailSent(true)
  }
  return (
    <>
      <div className={cn("grid grid-col-1 place-items-center h-screen ")}>
        <div className="mx-auto flex w-full flex-col justify-center bg-transparent space-y-6">
          <div className="flex flex-col space-y-0 text-center">
            {/* <h1 className="text-2xl font-semibold tracking-tight">
              Enter new password
            </h1> */}
            <TypographyH2> Enter new password</TypographyH2>
            <Label>Confirm code and eeter new password</Label>
            {/* <p className="text-sm text-muted-foreground">
              Confirm code and eeter new password
            </p> */}
          </div>
          <form className="mt-6 grid grid-cols-1 ml-2 md:ml-0 gap-y-3 gap-x-6 ">
            <Label>Email Address</Label>
            <Input
              disabled
              label="Email"
              id="email"
              name="email"
              value={email}
              className="h-10 w-64 md:w-full"
            />
            <Label>Code</Label>
            <Input
              label="code"
              id="code"
              name="code"
              value={code}
              onChange={handleCodeUpdate}
              required
              className="h-10  w-64 md:w-w-44 "
            />
            <Label>Password</Label>
            <Input
              className="col-span-full h-10 w-64 md:w-full"
              label="Password"
              id="password"
              name="password"
              type="password"
              required
              onChange={(e) => {
                onPasswordsChange(e);
              }}
            />
            <Label>Confirm Password</Label>
            <Input
              className="col-span-full h-10 w-64 md:w-full"
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={onPasswordsChange}
              required
            />
            {message.messageText ? (
              <div className="text-center">
                <Message message={message} setMessage={setMessage} />
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-row gap-3">
              <Button
                disabled={passwords.password !== passwords.confirmPassword}
                type="button"
                onClick={handleSubmit}
                className="w-full items-center rounded-xl border border-ring  px-4 py-3 text-center text-base
              font-medium"
              >
                Continue
              </Button>

              <Button
                disabled
                type="button"
                // onClick={handleResend}
                className="w-full items-center rounded-xl border border-ring  px-4 py-3 text-center text-base
              font-medium"
              >
                Resend
              </Button>
            </div>
            <Button
              disabled={passwords.password !== passwords.confirmPassword}
              type="button"
              onClick={() => {
                authService.send({ type: "cancel_forgot_password" });
              }}
              className="w-full items-center rounded-xl border border-ring  px-4 py-3 text-center text-base
              font-medium"
            >
              {" "}
              Cancel & Go To Sign In
            </Button>
          </form>

          {/* <div className="flex grow flex-row justify-center gap-2">
            <p className=" invisible mt-2 text-sm text-muted-foreground md:visible">
              If you already have an account?
              <button
                variant="link"
                onClick={() => {
                  authService.send({ type: "signInOptions" });
                }}
              >
                Sign In
              </button>
              for a free trial.
            </p>
          </div> */}
        </div>

        <div className="flex grow flex-col justify-end gap-2">
          {/* User already exists error */}
          {error ? (
            <div className="flex grow flex-row justify-center gap-2">
              <div className="bg-red-100 py-4 text-center lg:px-4">
                <div
                  className="flex items-center bg-red-500 p-2 leading-none text-white lg:inline-flex lg:rounded-full"
                  role="alert"
                >
                  <span className="mr-2 flex-auto text-left font-semibold">
                    {error}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
