import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddReimbursement = async (Reimbursement) => {
  log.debug(" hookAddReimbursement : ", Reimbursement);

  try {
    const result = await API.graphql({
      query: mutations.createReimbursement,
      variables: {
        input: Reimbursement,
      },
    });

    log.debug(" hookAddReimbursement result: ", result);

    return result?.data?.createReimbursement;
  } catch (err) {
    log.debug("CATCH ERROR hookAddReimbursement result: ", err);
    return null;
  }
};

export const hookUpdateReimbursement = async (Reimbursement) => {
  log.debug(" hookUpdateReimbursement : ", Reimbursement);
  try {
    const result = await API.graphql({
      query: mutations.updateReimbursement,
      variables: {
        input: Reimbursement,
      },
    });

    log.debug(" hookUpdateReimbursement result: ", result);
    return result?.data?.updateReimbursement;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateReimbursement result: ", err);
  }
};

export const hookDeleteReimbursementByReimbursementId = async (
  ReimbursementId
) => {
  log.debug(" hookDeleteReimbursementByReimbursementId  ", ReimbursementId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteReimbursement,
      variables: {
        input: { id: ReimbursementId },
      },
    });

    log.debug(" hookDeleteReimbursementByReimbursementId : ", data);
    if (data !== null) return data?.data?.deleteReimbursement;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteGoalByGoalUniqueID -- ", e);
    return null;
  }
};

export const hookListReimbursementsByUserId = async (userID) => {
  log.debug(" hookListReimbursementsByUserId userID", userID);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.ReimbursementsByUserID,
      variables: {
        userID: userID,
      },
    });

    log.debug(" hookListReimbursementsByUserId : ", data);

    if (data !== null) return data?.data?.ReimbursementsByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListReimbursementsByUserId -- ", e);
    return null;
  }
};

export const hookListReimbursements = async () => {
  log.debug(" hookListReimbursements");

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.listReimbursements,
      variables: {
        // userID: userID,
      },
    });

    log.debug(" hookListReimbursements : ", data);

    if (data !== null) return data?.data?.listReimbursements.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListReimbursements -- ", e);
    return null;
  }
};

export const hookListReimbursementsByOrgId = async (orgId) => {
  log.debug(" hookListReimbursementsByOrgId orgId", orgId);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.ReimbursementsByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });

    log.debug(" ReimbursementsByOrganizationID : ", data);

    if (data !== null) return data?.data?.ReimbursementsByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR ReimbursementsByOrganizationID -- ", e);
    return null;
  }
};
