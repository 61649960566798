import { Analytics } from "@aws-amplify/analytics";
import log from "loglevel";

const emailEvents = [
  { name: "SendRejectionEmail" },
  { name: "OnlineTestScheduledToEmail" },
  { name: "SendOnlineTestRejectionEmailToCandidate" },
  { name: "SendTestPassedEmailToManager" },
  { name: "SendWrittenTestScheduledEmailToCandidate" },
  { name: "SendWrittenTestRejectionEmailToCandidate" },
  { name: "SendWrittenTestPassedEmailToManager" },
  { name: "SendTechnicalInterviewScheduledEmailToCandidate" },
  { name: "SendTechnicalInterviewRejectionEmail" },
  { name: "SendTechnicalInterviewPassedEmailToHR" },
  { name: "SendHRInterviewScheduledEmailToCandidate" },
  { name: "SendHRInterviewRejectionEmail" },
  { name: "SendEmailToUploadDocuments" },
  { name: "SendHrInterviewPassedEmailToCandidate" },
  { name: "SendCandidateOfferEmailToCandidate" },
  { name: "SendEmail1WeekBeforeJoining" },
  { name: "SendEmail1DaysBeforeJoining" },
];

export async function updateEndpointAsEmail(email) {
  log.debug("updateEndpointAsEmail email : ", email);

  const updatePoint = await Analytics.updateEndpoint({
    address: email,
    channelType: "EMAIL",
    optOut: "NONE",
  });

  log.debug("updateEndpointAsEmail email : ", updatePoint);
}

// Attribute values must be strings
export async function analyticsSendEmail(event, email, attributes) {
  log.debug("analyticsSendEmail event : ", event);
  log.debug("analyticsSendEmail attibutes : ", attributes);

  // TestAllEvents(event, email, attributes);

  Analytics.record({
    name: event,
    attributes: attributes,
    // Attribute values must be strings
  });
}

const SendTestEmailAfterXSeconds = (event, attributes, delay) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      Analytics.record({
        name: event,
        attributes: {},
        // Attribute values must be strings
      });

      log.debug("SendTestEmailAfterXSeconds event : ", event);

      resolve();
    }, delay);
  });
};

export async function TestAllEvents(event, email, attributes) {
  log.debug("TestAllEvents event : ");
  log.debug("TestAllEvents attibutes : ");

  for (const event of emailEvents) {
    await SendTestEmailAfterXSeconds(event, attributes, 10000);
  }
}
