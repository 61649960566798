import { CONST_UPDATE_CONTEXT_ACTIONS } from "../../constants/dbconstants";
import {
  hookAddGoal,
  hookListGoals,
  hookUpdateGoal,
  hookDeleteGoalByGoalId,
  hookListGoalsByUserId,
} from "../../hooks/goalsHooks";
import log from "loglevel";

export const getGoalDataByEmployeeIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID } = context;
    log.debug("calling:hookListGoalsByUserId", userID);

    const goals = await hookListGoalsByUserId(userID);
    log.debug("goals:getGoalDataByEmployeeId", goals);
    if (goals) resolve({ goals });
    reject("Failed To Fetch Tables");
  });

export const submitGoalDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { goalToBeAdded, userID, organizationID } = context;
    goalToBeAdded.assignedBy = userID;
    goalToBeAdded.assignedTo = userID;
    goalToBeAdded.userID = userID;
    log.debug("goalToBeAdded:submitGoalData", goalToBeAdded);
    const goal = await hookAddGoal({
      ...goalToBeAdded,
      organizationID,
    });
    if (goal) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
          contextField: "goals",
          value: goal,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully added goal",
        },
      ]);
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To add goal",
      },
    ]);
  });

export const updateGoalDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { goalToBeAdded } = context;
    const res = await hookUpdateGoal({
      ...goalToBeAdded,
    });
    if (res)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "goals",
          value: res,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully updated goal",
        },
      ]);
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update goal",
      },
    ]);
  });

export const deleteGoalDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedGoalId } = context;
    log.debug("deleteGoalData====>", selectedGoalId);
    const goalDeleted = await hookDeleteGoalByGoalId(selectedGoalId);
    log.debug("goalDeleted====>", goalDeleted);
    if (goalDeleted) resolve(true);
    reject("Failed To Delete Goal");
  });
