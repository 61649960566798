import { ReactNode, CSSProperties } from "react";
type TypographyProps = {
  children: ReactNode;
};
export function TypographyH1({ children }: TypographyProps) {
  return (
    <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
      {children}
    </h1>
  );
}

export function TypographyH2({ children }: TypographyProps) {
  return (
    <h2 className="scroll-m-20  pb-2 text-2xl font-bold tracking-tight first:mt-0">
      {children}
    </h2>
  );
}

export function TypographyH3({ children }: TypographyProps) {
  return (
    <h3 className="text-2xl font-inter text-common font-semibold leading-8 tracking-tight text-left">
      {children}
    </h3>
  );
}

export function TypographyH4({ children }: TypographyProps) {
  return (
    <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
      {children}
    </h4>
  );
}

export function TypographyP({ children }: TypographyProps) {
  return <p className="leading-7 [&:not(:first-child)]:mt-6">{children}</p>;
}

export function TypographyLead({ children }: TypographyProps) {
  return <p className="text-xl text-muted-foreground">{children}</p>;
}

export function TypographyLarge({ children }: TypographyProps) {
  return <div className="text-lg font-semibold">{children}</div>;
}

export function TypographySmall({ children }: TypographyProps) {
  return <small className="text-sm font-medium leading-none">{children}</small>;
}

export function TypographyMuted({ children }: TypographyProps) {
  return <p className="text-sm text-muted-foreground">{children}</p>;
}

export function TypographyLabel({children}:TypographyProps){
  return <div className="font-inter text-sm font-normal leading-6 tracking-normal text-left mt-1">{children}</div>
}

export function Typographybox({children}:TypographyProps){
  return <div ></div>
}

export function Typographymainhaead({children}:TypographyProps){
  return(
    <div className="font-inter text-16 font-bold leading-24 tracking-normal text-left"></div>
  )
}
