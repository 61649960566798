import GoalsList from "../../components/employee/Goals/GoalsList";
import { useEmployeeContextState } from "../../hooks/employeeMachineHooks";
import React from "react";
import AddEditDeleteGoals from "../../components/employee/Goals/AddEditDeleteGoals";

const Goals = () => {
  const { goalCrud } = useEmployeeContextState();
  return (
    <div className="h-full md:rounded-sm lg:rounded-md m-4">
      <GoalsList />
      <> {goalCrud ? <AddEditDeleteGoals /> : <></>}</>
    </div>
  );
};

export default Goals;
