import React from "react";
import { CONST_TASK_STATUS } from "../../../constants/dbconstants";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import log from "loglevel";
import FormatDateAndTime from "../../common/FormatDateAndTime";
import { DataTableColumnHeader } from "../../CustomComponentsLibrary/data-table-column-header";
import { FriendlyName } from "../../../../src/lib/friendlyName";
import { Progress } from "../../CustomComponentsLibrary/Progress";
import { DataTable } from "../../CustomComponentsLibrary/data-table";
import { Button } from "../../CustomComponentsLibrary/Button";
import { Edit2Icon, Trash } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../CustomComponentsLibrary/Tooltip";
import { useSelector } from "@xstate/react";
import PageHeader from "../../common/PageHeader";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import { DataTableWrapper } from "../../../pages/employee/metrics/DataTableWrapper";

const TasksList = () => {
  const { tasks, teamMembersNames } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const employeeMachineService = useEmployeeContextService();
  log.debug("tasksList", tasks, teamMembersNames);
  const istaskLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );

  log.debug("istaskLoading", istaskLoading);
  const columns = [
    {
      header: "Task Name",
      accessorKey: "title",
      cell: ({ row }) => {
        log.debug("row.originalTASKSTATUS_CANCELLED", row.original);
        return (
          <>
            {row.original?.status === "TASKSTATUS_DONE" ||
            row.original?.status === "TASKSTATUS_CANCELLED" ? (
              <div className="opacity-50">{row.original.title}</div>
            ) : (
              row.original.title
            )}
          </>
        );
      },
    },
    {
      header: "Description",
      accessorKey: "content",
      cell: ({ row }) => {
        return (
          <>
            {row.original?.status === "TASKSTATUS_DONE" ||
            row.original?.status === "TASKSTATUS_CANCELLED" ? (
              <div className="opacity-50">{row.original.content}</div>
            ) : (
              row.original.content
            )}
          </>
        );
      },
    },
    {
      header: "Assigned To",
      accessorKey: "assignedTo",
      cell: ({ row }) => {
        const assignee = teamMembersNames?.find(
          (member) => member?.id === row.original?.assignedTo
        );
        log.debug("assigneeNames::", assignee);
        return (
          <>
            {row.original?.status === "TASKSTATUS_DONE" ||
            row.original?.status === "TASKSTATUS_CANCELLED" ? (
              <div className="opacity-50">
                {assignee ? assignee?.title : null}
              </div>
            ) : assignee ? (
              assignee?.title
            ) : null}
          </>
        );
      },
    },
    {
      accessorKey: "dueDate",
      enableSorting: true,
      cell: ({ row }) => (
        <>
          {row.original?.status === "TASKSTATUS_DONE" ||
          row.original?.status === "TASKSTATUS_CANCELLED" ? (
            <div className=" opacity-50">
              <FormatDateAndTime
                dateTime={row.original?.dueDate}
                format={"DD/MM/YYYY"}
              />
            </div>
          ) : (
            <FormatDateAndTime
              dateTime={row.original?.dueDate}
              format={"DD/MM/YYYY"}
            />
          )}
        </>
      ),
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Due on" />
      ),
    },
    {
      header: "Completion(%)",
      accessorKey: "percentage",
      cell: ({ row }) => (
        <>
          {row.original?.status === "TASKSTATUS_DONE" ||
          row.original?.status === "TASKSTATUS_CANCELLED" ? (
            <div className="flex  opacity-50">
              <Progress
                value={row.original.percentage}
                className="w-[40%] m-2"
                rootCss={`${theme?.bgLight}`}
                indicatorCss={`${theme?.bgCommon}`}
              />
              <div>{`${parseInt(row.getValue("percentage"))}% `}</div>
            </div>
          ) : (
            <div className="flex ">
              <Progress
                value={row.original.percentage}
                className="w-[40%] m-2"
                rootCss={`${theme?.bgLight}`}
                indicatorCss={`${theme?.bgCommon}`}
              />
              <div>{`${parseInt(row.getValue("percentage"))}% `}</div>
            </div>
          )}
        </>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",

      cell: ({ row }) => <FriendlyName status={row.getValue("status")} />,
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <>
          <div className="flex">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="link"
                    className="font-bold"
                    onClick={() => {
                      employeeMachineService.send({
                        type: "task_crud",
                        data: {
                          actionForTaskCRUD: "EDIT",
                          selectedTask: row?.original,
                          selectedTaskId: row.original?.id,
                        },
                      });
                    }}
                  >
                    <Edit2Icon className="h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Edit Task</TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Button
              variant="link"
              className="font-bold  "
              onClick={() => {
                employeeMachineService.send({
                  type: "task_crud",
                  data: {
                    actionForTaskCRUD: "DELETE",
                    selectedTask: row.original,
                    selectedTaskId: row.original.id,
                  },
                });
              }}
            >
              <Trash className="h-4" />
            </Button>
          </div>
        </>
      ),
    },
  ];

  const labels = [
    {
      value: CONST_TASK_STATUS.taskStatusCreated,
      label: "Created",
    },
    {
      value: CONST_TASK_STATUS.taskStatusOnhold,
      label: "On Hold",
    },
    {
      value: CONST_TASK_STATUS.taskStatusCancelled,
      label: "Cancelled",
    },
    {
      value: CONST_TASK_STATUS.taskStatusInprogress,
      label: "In Progress",
    },
    {
      value: CONST_TASK_STATUS.taskStatusDone,
      label: "Done",
    },

    {
      value: CONST_TASK_STATUS.taskStatusTesting,
      label: "Testing",
    },
    {
      value: CONST_TASK_STATUS.taskStatusDoneApproved,
      label: "Approved",
    },
  ];
  return (
    <div>
      <PageHeader
        title={"Tasks"}
        SubHeading="Overview and tracking of all your tasks."
      />
      <div className="shadow-md rounded-md mt-5 pb-10 border-t border-zinc-100 ">
        <div className="ml-5 mr-5 mt-2 ">
          <DataTableWrapper>
            <DataTable
              customCss={`"border" ${theme?.border}`}
              data={tasks || []}
              columns={columns}
              loader={istaskLoading}
              filterableColumns={[
                {
                  id: "status",
                  title: "Filter By status",
                  options: labels.map(({ value, label }) => ({
                    label: label,
                    value: value,
                  })),
                },
              ]}
              customWidth={[
                {
                  index: 0,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 1,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 2,
                  widthClass: `"w-40" ${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 3,
                  widthClass: "w-40",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 4,
                  widthClass: "w-40",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 5,
                  widthClass: "w-40",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 6,
                  widthClass: "w-40",
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
              ]}
            />
          </DataTableWrapper>
        </div>
      </div>
    </div>
  );
};

export default TasksList;
