import React, { useEffect, useState } from "react";
import useForm from "../../customHooks/useForm";
import {
  useAuthContextData,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import { useActor } from "@xstate/react";
import AddOrganization from "../../pages/common/AddOrganization";
import ConfirmPasswords from "./ConfirmPasswords";
import CreateEmployeeProfile from "./CreateEmployeeProfile.jsx";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const { inputs, handleChange } = useForm({
    email: "",
    otp: "",
  });
  const [password, setPassword] = useState("");
  const { email } = useAuthContextData();

  const authService = useAuthService();
  const [state, send] = useActor(authService);
  const navigate = useNavigate();
  useEffect(() => {
    if (email === "") {
      navigate("/");
    }
  }, [email]);
  return (
    <div>
      SignUp Email: <span className="font-light">{email}</span>
      <div>Please keep Credit/Debit card handy</div>
      <div>
        {state.matches({
          LOGGED_OUT: "ORG_DO_NOT_EXISTS",
        }) ||
        state.matches({
          LOGGED_OUT: "ORG_ADDING",
        }) ? (
          <>
            <AddOrganization />
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        {state.matches({
          LOGGED_OUT: "CONTINUE_USER_SIGNUP",
        }) ? (
          <>
            <ConfirmPasswords setPassword={setPassword} />
            {password && (
              <button
                onClick={() => {
                  send({ type: "get_confirmation_code", data: { password } });
                }}
                className="bg-gray-500 rounded-md p-1 m-2"
              >
                Get Email Verification Code
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        {state.matches({
          LOGGED_OUT: "CONFIRM_OTP_CREATE_USER",
        }) ? (
          <>
            <div>
              <label>Confirmation Code</label>
              <input
                id="otp"
                name="otp"
                className="bg-gray-300 border-2 border-black w-80 mx-8"
                value={inputs.otp}
                onChange={handleChange}
              />
            </div>
            {inputs.otp && (
              <button
                onClick={() => {
                  send({
                    type: "confirm_code",
                    data: { confirmationCode: inputs.otp },
                  });
                }}
                className="bg-gray-500 rounded-md p-1 m-2"
              >
                Submit Confimation Code
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        {state.matches({
          LOGGED_OUT: "CREATE_USER_PROFILE",
        }) ? (
          <>
            <CreateEmployeeProfile />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
