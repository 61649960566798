import React from "react";
import PageHeader from "../../common/PageHeader";
import FormatDateAndTime from "../../common/FormatDateAndTime";
import { DataTableColumnHeader } from "../../CustomComponentsLibrary/data-table-column-header";
import { Progress } from "../../CustomComponentsLibrary/Progress";
import { FriendlyName } from "../../../lib/friendlyName";
import { Button } from "../../CustomComponentsLibrary/Button";
import { CONST_GOAL_STATUS } from "../../../constants/dbconstants";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { DataTable } from "../../CustomComponentsLibrary/data-table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
} from "../../CustomComponentsLibrary/Tooltip";
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { Edit2Icon, Trash } from "lucide-react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import { DataTableWrapper } from "../../../pages/employee/metrics/DataTableWrapper";

const GoalsList = () => {
  const { goals } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const employeeMachineService = useEmployeeContextService();
  const goalStatus = [
    { name: CONST_GOAL_STATUS.goalStatusCreated },
    { name: CONST_GOAL_STATUS.goalStatusInprocess },
    { name: CONST_GOAL_STATUS.goalStatusOnhold },
    { name: CONST_GOAL_STATUS.goalStatusCancelled },
    { name: CONST_GOAL_STATUS.goalStatusDone },
    { name: CONST_GOAL_STATUS.goalStatusDoneApproved },
  ];
  const labels = [
    {
      value: CONST_GOAL_STATUS.goalStatusCreated,
      label: "Created",
    },
    {
      value: CONST_GOAL_STATUS.goalStatusInprocess,
      label: "In Progress",
    },
    {
      value: CONST_GOAL_STATUS.goalStatusOnhold,
      label: "On Hold",
    },
    {
      value: CONST_GOAL_STATUS.goalStatusCancelled,
      label: "Cancelled",
    },
    {
      value: CONST_GOAL_STATUS.goalStatusDone,
      label: "Done",
    },
    {
      value: CONST_GOAL_STATUS.goalStatusDoneApproved,
      label: "Approved",
    },
  ];
  const columns = [
    {
      header: "Title",
      accessorKey: "title",
    },
    {
      header: "Description",
      accessorKey: "content",
    },
    {
      accessorKey: "dueDate",
      enableSorting: true,
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row.original?.dueDate}
          format={"DD/MM/YYYY"}
        />
      ),
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Due Date" />
      ),
    },
    {
      header: "Completion(%)",
      accessorKey: "percentage",
      cell: ({ row }) => (
        <>
          <div className="flex  ">
            <Progress
              value={row.original.percentage}
              className="w-[40%] m-2"
              rootCss={`${theme?.bgLight}`}
              indicatorCss={`${theme?.bgCommon}`}
            />
            <div>{`${parseInt(row.getValue("percentage"))}% `}</div>
          </div>
        </>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <FriendlyName className="w-48" status={row.getValue("status")} />
      ),
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
    {
      header: "Actions",
      accessorKey: "",
      cell: ({ row }) => (
        <>
          <div className="flex">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="link"
                    className="font-bold"
                    disabled={
                      row.getValue("status") ===
                      CONST_GOAL_STATUS.goalStatusDoneApproved
                    }
                    onClick={() => {
                      employeeMachineService.send({
                        type: "goals_crud",
                        data: {
                          actionForGoalCRUD: "UPDATE",
                          selectedGoal: row.original,
                          selectedGoalId: row.original?.id,
                        },
                      });
                    }}
                  >
                    <Edit2Icon className="h-4" />
                  </Button>
                </TooltipTrigger>

                <TooltipContent>Edit Goal</TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Button
              variant="link"
              className="font-bold  "
              disabled={
                row.getValue("status") ===
                CONST_GOAL_STATUS.goalStatusDoneApproved
              }
              onClick={() => {
                employeeMachineService.send({
                  type: "goals_crud",
                  data: {
                    actionForGoalCRUD: "DELETE",
                    selectedGoal: row.original,
                    selectedGoalId: row.original.id,
                  },
                });
              }}
            >
              <Trash className="h-4" />
            </Button>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="flex justify-between">
        <div className="flex-col sm:flex-row w-full">
          <PageHeader
            title={"Goals"}
            SubHeading="Overview and progress of all your goals."
          />
          <div class="flex justify-end mt-2 sm:mt-0">
            <Button
              className={`"mb-0" ${theme?.textColor} ${theme?.background}`}
              onClick={() => {
                employeeMachineService.send({
                  type: "goals_crud",
                  data: {
                    actionForGoalCRUD: "ADD",
                  },
                });
              }}
            >
              Add Goal
            </Button>
          </div>
        </div>
      </div>
      <div className="shadow-md rounded-md mt-5 pb-10 border-t border-zinc-100 ">
        <div className="ml-5 mr-5 mt-2 text-sm font-semibold">
          <DataTable
            customCss={`${theme?.border}`}
            data={goals}
            columns={columns}
            filterableColumns={[
              {
                id: "status",
                title: "Filter by Status",
                options: labels.map(({ value, label }) => ({
                  label: label,
                  value: value,
                })),
              },
            ]}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 2,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 3,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 4,
                widthClass: "w-32",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 5,
                widthClass: "w-32",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default GoalsList;
