import {
  useEmployeeContextData,
  useEmployeeContextService,
  useEmployeeContextState,
} from "../../../hooks/employeeMachineHooks";
import React, { useMemo, useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Label } from "../../CustomComponentsLibrary/Label";
import { Button } from "../../CustomComponentsLibrary/Button";

import { X } from "lucide-react";
import log from "loglevel";
import Dropdown from "../../common/Dropdown";
export default function UpdateCourseProgress() {
  const {
    selectedCourse,
    selectedTopic,
    actionForCourseCRUD,
    selectedLesson,
    courses,
    selectedTopicResource,
  } = useEmployeeContextData();
  const { courseCrud } = useEmployeeContextState();
  const employeeMachineService = useEmployeeContextService();
  const [selected, setSelected] = useState("Incomplete");
  const array = [{ name: "Complete" }, { name: "Incomplete" }];
  log.debug("actionForCourseCRUD", selectedCourse);
  const updateTopicProgress = () => {
    var updatedObject = {};
    if (selected.name === "Complete") {
      if (
        !selectedCourse?.completedTopicResources?.includes(
          selectedTopicResource?.id
        )
      ) {
        updatedObject = {
          id: selectedCourse?.id,
          completedTopicResources: [
            ...(selectedCourse?.completedTopicResources || []),
            selectedTopicResource?.id,
          ],
        };
      } else {
        updatedObject = {
          id: selectedCourse?.id,
        };
      }
    } else if (selected.name === "Incomplete") {
      const incomplete = selectedCourse?.completedTopicResources?.filter(
        (item) => item != selectedTopicResource?.id
      );
      updatedObject = {
        id: selectedCourse?.id,
        completedTopicResources: incomplete,
      };
    }

    employeeMachineService.send({
      type: "update_course",
      data: {
        courseToBeAdded: updatedObject,
      },
    });
    log.debug("matchingCourse", updatedObject);
  };

  useEffect(() => {
    if (selectedTopicResource) {
      const findTopicResources = selectedCourse.completedTopicResources?.find(
        (item) => item == selectedTopicResource?.id
      );
      if (findTopicResources) {
        setSelected(array.find((item) => item.name == "Complete"));
      }
    } else {
      const findLesson = selectedCourse.completedLessons?.find(
        (item) => item == selectedLesson?.id
      );

      if (findLesson) {
        setSelected(array.find((item) => item.name == "Complete"));
      }
    }
  }, [selectedLesson, selectedTopicResource]);

  const updateLessonProgress = () => {
    var updatedObject = {};

    if (selected.name === "Complete") {
      if (!selectedCourse?.completedLessons?.includes(selectedLesson?.id)) {
        updatedObject = {
          id: selectedCourse?.id,
          completedLessons: [
            ...(selectedCourse?.completedLessons || []),
            selectedLesson?.id,
          ],
        };
      } else {
        updatedObject = {
          id: selectedCourse?.id,
        };
      }
    } else if (selected.name === "Incomplete") {
      const incomplete = selectedCourse?.completedLessons?.filter(
        (item) => item != selectedLesson?.id
      );
      updatedObject = {
        id: selectedCourse?.id,
        completedLessons: incomplete,
      };
    }

    employeeMachineService.send({
      type: "update_course",
      data: {
        courseToBeAdded: updatedObject,
      },
    });
  };

  if (courseCrud) {
    switch (actionForCourseCRUD) {
      case "UPDATE":
        return (
          <div>
            <Transition.Root show={courseCrud} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                  employeeMachineService.send({
                    type: "cancel_crud",
                  });
                }}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <Button
                            size="xs"
                            variant="darkOutline"
                            className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                            onClick={() => {
                              employeeMachineService.send({
                                type: "cancel_crud",
                              });
                            }}
                          >
                            <X className="h-4 w-4 text-" />

                            <span className="sr-only">Close</span>
                          </Button>
                        </div>
                        <div className="sm:flex sm:items-start">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-semibold leading-6 text-foreground"
                            >
                              Update the course progress .
                            </Dialog.Title>
                            <div className="mt-4 flex flex-col  space-y-2">
                              <Dropdown
                                selected={selected}
                                setSelected={setSelected}
                                list={array}
                                nameField="name"
                                placeholder="Select Progress"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          {selectedTopicResource ? (
                            <Button onClick={() => updateTopicProgress()}>
                              Update Status
                            </Button>
                          ) : selectedLesson ? (
                            <Button onClick={() => updateLessonProgress()}>
                              Update
                            </Button>
                          ) : (
                            <Button>Update</Button>
                          )}
                          <Button
                            className="mr-2"
                            onClick={() => {
                              employeeMachineService.send({
                                type: "cancel_crud",
                              });
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        );
    }
  }
}
