import React, { useState } from "react";
import Searchbar from "./Searchbar";
import { Sidebarv2 } from "./Sidebarv2";
import { Toaster } from "../CustomComponentsLibrary/Toaster";
const { version } = require("../../../package.json");

export default function CommonLayout({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <div className="flex app h-screen overflow-hidden">
        <div className="flex h-screen w-full flex-col ">
          <div>
            <Searchbar setMobileMenuOpen={setMobileMenuOpen} />
          </div>
          <div className="h-full flex  ">
            <div>
              <Sidebarv2
                setMobileMenuOpen={setMobileMenuOpen}
                mobileMenuOpen={mobileMenuOpen}
              />
            </div>
            <div className="flex flex-col overflow-x-auto grow pb-20">
              <div className="h-full overflow-y-auto grow">{children}</div>
              <div className="sticky bottom-0 justify-self-end p-2 z-10 text-right rounded-md bg-white">
                <p className="mt-6 text-sm text-gray-500  sm:mt-0">
                  Copyright &copy; {new Date().getFullYear()} Delighteck. All
                  rights reserved.{"  "} Version : {version} This is Test
                  Deployment
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Narrow sidebar */}
      </div>

      <Toaster />
    </div>
  );
}
