import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddCourse = async (Course) => {
  log.debug(" hookAddCourse : ", Course);

  try {
    const result = await API.graphql({
      query: mutations.createCourse,
      variables: {
        input: Course,
      },
    });

    log.debug(" hookAddCourse result: ", result);

    return result?.data?.createCourse;
  } catch (err) {
    log.debug("CATCH ERROR hookAddCourse result: ", err);
    return null;
  }
  //Destructuring Assignment in ES6
  //setTodos([...todos, result.data.createTodo]);
};

export const hookUpdateCourse = async (Course) => {
  log.debug(" hookUpdateCourse : ", Course);
  try {
    const result = await API.graphql({
      query: mutations.updateCourse,
      variables: {
        input: Course,
      },
    });

    log.debug(" hookUpdateCourse result: ", result);
    return result?.data?.updateCourse;
  } catch (err) {
    log.debug("CATCH ERROR hookAddUser result: ", err);
  }
};

export const hookGetCourseById = async (id) => {
  log.debug(" hookGetCourseById  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getCourse,
      variables: {
        id: id,
      },
    });

    log.debug(" hookGetCourseById : ", data);
    if (data !== null) return data?.data?.getCourse;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetCourseByCognitoId -- ", e);
    return null;
  }
};

export const hookListCourses = async () => {
  //Connect Client Amplify GraphQL
  //TODO: add filter to get Course by restaurent Id
  try {
    const result = await API.graphql({
      query: queries.listCourses,
      variables: {},
    });

    log.debug(" hooklistCourse : ", result);

    if (result !== null) return result?.data?.listCourses?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hooklistCourse ", err);
  }
};

export const hookDeleteCourseById = async (id) => {
  log.debug(" hookGetCourseById  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteCourse,
      variables: {
        input: { id: id },
      },
    });

    log.debug(" hookDeleteCourseById : ", data);
    if (data !== null) return data?.data?.deleteCourse;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteCourseByCognitoId -- ", e);
    return null;
  }
};

export const hookListCoursesByOrgnizationId = async (organizationID) => {
  try {
    const data = await API.graphql({
      query: queries.CoursesByOrganizationID,
      variables: { organizationID: organizationID },
    });

    log.debug(" hookListCoursesByOrgnizationId : ", data);

    if (data !== null) return data?.data?.CoursesByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCoursesByOrgnizationId -- ", e);
    return null;
  }
};
