import { Button } from "../../../components/CustomComponentsLibrary/Button";
import { Link } from "react-router-dom";
import {
  ScrollArea,
  ScrollBar,
} from "../../../components/CustomComponentsLibrary/ScrollArea";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export function DashboardDataTableWrapper({
  title,
  action,
  actionLink,
  children,
}) {
  const { theme } = useAuthContextData();
  return (
    <div className="text-[19px] shadow-lg rounded-md border-t border-t-zinc-100 sm:h-80 h-fit pb-5 sm:pb-0 ">
      <div className="px-4 py-3">
        <span className="font-extrabold"> {title}</span>
        <span className="float-right ">
          <Button variant="link" className="font-bold underline text-base">
            <Link to={actionLink} className={`${theme?.textCommon}`}>
              {action}
            </Link>
          </Button>
        </span>
      </div>
      <ScrollArea className="h-[250px] w-full">
        <div className="px-5">{children}</div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  );
}
