import React, { useState, Fragment, useEffect } from "react";
import log from "loglevel";
import {
  useEmployeeContextData,
  useEmployeeContextState,
  useEmployeeContextService,
} from "../../../../hooks/employeeMachineHooks";
import { Dialog, Transition } from "@headlessui/react";
import useForm from "../../../../customHooks/useForm";
import Dropdown from "../../../common/Dropdown";
import { CONST_COFF_STATUS } from "../../../../constants/dbconstants";
import moment from "moment";
import CoffComments from "../../../common/CoffComments";
import RadioGroupMulti from "../../../common/RadioGroupMulti";
import {
  DialogBox,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../CustomComponentsLibrary/Dailog";
import { Input } from "../../../CustomComponentsLibrary/Input";
import { Button } from "../../../CustomComponentsLibrary/Button";
import { Label } from "../../../CustomComponentsLibrary/Label";
import { Textarea } from "../../../CustomComponentsLibrary/Textarea";
import { ScrollArea } from "../../../CustomComponentsLibrary/ScrollArea";
import { Trash, X } from "lucide-react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "../../../CustomComponentsLibrary/UseToast";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
import { hookListCoffsByUserId } from "../../../../hooks/hookCoffs";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import { sortByKeyAlphabetical } from "../../../common/SortByName";

export default function AddEditCoffs() {
  const { coffsCrud } = useEmployeeContextState();
  const employeeMachineService = useEmployeeContextService();
  const { organization, theme } = useAuthContextData();
  const [selectedDates, setSelectedDates] = useState([]);
  const [CoffQuantity, setCoffQuantity] = useState([]);
  const [CoffData, setCoffData] = useState([]);
  const [items, setItems] = useState([]);
  const { actionForCoffCRUD, selectedCoff, userID, leavesOverview } =
    useEmployeeContextData();

  log.debug("coffsCrud", coffsCrud);
  log.debug(
    "actionForCoffCRUD, selectedCoff",
    actionForCoffCRUD,
    selectedCoff,
    leavesOverview
  );

  const CoffOptions = [
    {
      name: "Full Day",
      value: 1.0,
    },
    {
      name: "Half Day",
      value: 0.5,
    },
  ];

  const { inputs, handleChange, clearForm, resetForm } = useForm({
    // id: selectedCoff ? selectedCoff.id : "",
    appliedBy: selectedCoff ? selectedCoff.appliedBy : "",
    date: selectedCoff ? selectedCoff.date : "",
    details: selectedCoff ? selectedCoff.details : "",
  });
  const [selectedLeaveQuantity, handleSelectedLeaveQuantity] = useState(1.0);
  const [selectedCoffQuantity, handleSelectedCoffQuantity] = useState(null);
  const [dialogOpen] = useState(coffsCrud === true ? true : false);
  const handleClose = () => {
    employeeMachineService.send({ type: "cancel_crud" });
  };
  useEffect(() => {
    const CoffData = async () => {
      const CoffsOfSelectedPerson = await hookListCoffsByUserId(userID);
      setCoffData(CoffsOfSelectedPerson);
    };
    CoffData();
  }, [userID]);
  log.debug("CoffData_for", CoffData);

  useEffect(() => {
    log.debug("selectedCoffQuantity : ", selectedCoffQuantity);
  }, [selectedCoffQuantity]);

  const coffStatuses = [
    { name: CONST_COFF_STATUS.coffSubmitted },
    { name: CONST_COFF_STATUS.coffRejected },
    { name: CONST_COFF_STATUS.coffApproved },
  ];
  useEffect(() => {
    dateSelector();
  }, [inputs?.date]);
  const dateSelector = () => {
    let filterLevesByDate;

    if (CoffData) {
      filterLevesByDate = CoffData.filter((coff) =>
        coff?.date.includes(inputs?.date)
      );
    }

    if (filterLevesByDate?.length > 0) {
      return toast({
        variant: "destructive",
        title: "Coff Exsits For the Selected Date",
      });
    }
  };
  const submitCoffs = async (e) => {
    e.preventDefault();
    log.debug("submitCoffNow onclickAdd", inputs);

    if (selectedCoff) {
      //TODO: update C-offs
      // const addCoff = {
      //   ...inputs,
      //   id: selectedCoff?.id,
      //   quantity: selectedCoffQuantity ? selectedCoffQuantity : 1.0,
      // };
      // log.debug("submitCoffDataNow ", addCoff);
      // // addItem(addCoff);
      // let filterLevesByDate;
      // let selectDate;
      // if (CoffData) {
      //   filterLevesByDate = CoffData.filter((coff) =>
      //     coff?.date.includes(inputs?.date)
      //   );
      // }
      // if (items) {
      //   selectDate = items.filter((coff) => coff?.date.includes(inputs?.date));
      // }
      // if (filterLevesByDate?.length > 0 || selectDate?.length > 0) {
      //   return toast({
      //     variant: "destructive",
      //     title: "Coff Exsits For the Selected Date",
      //   });
      // } else {
      //   employeeMachineService.send({
      //     type: "update_Coff",
      //     data: {
      //       CoffsToAdd: addCoff,
      //     },
      //   });
      // }
    } else {
      log.debug("submitCoffsDataNowADD onclickAdd", inputs);
      const formData = {
        ...inputs,
        appliedBy: userID,
        userID: userID,
        status: CONST_COFF_STATUS.coffSubmitted,
        quantity: selectedCoffQuantity ? selectedCoffQuantity : 1.0,
      };
      let filterLevesByDate;
      let selectDate;
      if (CoffData) {
        filterLevesByDate = CoffData.filter((coff) =>
          coff?.date.includes(inputs?.date)
        );
      }

      if (filterLevesByDate?.length > 0 || selectDate?.length > 0) {
        return toast({
          variant: "destructive",
          title: "Coff Exsits For the Selected Date",
        });
      } else {
        employeeMachineService.send({
          type: "create_multiple_coff",
          data: {
            coffsToAdd: [formData],
          },
        });
      }
    }
  };
  log.debug("item", items);

  const today = new Date().toISOString().split("T")[0]; // get current date in yyyy-mm-dd format

  if (coffsCrud) {
    switch (actionForCoffCRUD) {
      case "EDIT":
      // return (
      //   <DialogBox open={dialogOpen} onOpenChange={handleClose}>
      //     <DialogContent className="sm:max-w-[70%] p-3  mt-10 sm:ml-20 sm:mr-20">
      //       <ScrollArea className="h-96 sm:h-[500px] sm:pb-10 pb-10">
      //         <DialogHeader>
      //           <DialogTitle>
      //             {selectedCoff ? "Edit Coff" : "Add Coff"}
      //           </DialogTitle>
      //           <DialogDescription>
      //             Make changes to your Coffs here. Click save when you're
      //             done.
      //           </DialogDescription>
      //           <Button
      //             size="xs"
      //             variant=""
      //             className="fixed right-4 top-0 z-50 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
      //             onClick={() => {
      //               employeeMachineService.send({ type: "cancel_crud" });
      //             }}
      //           >
      //             <X className="h-5 w-10" />
      //           </Button>
      //         </DialogHeader>

      //         <form onSubmit={submitCoffs} method="POST">
      //           <div className="flex flex-col p-1">

      //             <div className=" flex-row w-full">
      //               <Label htmlFor="content" className="text-right">
      //                 Title
      //               </Label>

      //               <Textarea
      //                 required
      //                 defaultValue={inputs?.title}
      //                 type="text"
      //                 onChange={handleChange}
      //                 name="title"
      //                 id="title"
      //                 placeholder="Sick coff"
      //                 className={`${theme?.border}`}
      //               />
      //             </div>

      //             <div className=" flex-row">
      //               <Label htmlFor="dueDate" className="text-right">
      //                 Date
      //               </Label>
      //               {organization?.allowPastCoffs === true ? (
      //                 <Input
      //                   type="date"
      //                   onChange={handleChange}
      //                   name="date"
      //                   id="date"
      //                   value={inputs?.date}
      //                   className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
      //                 />
      //               ) : (
      //                 <Input
      //                   type="date"
      //                   onChange={handleChange}
      //                   name="date"
      //                   id="date"
      //                   value={inputs?.date}
      //                   min={today}
      //                   className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
      //                 />
      //               )}
      //             </div>

      //             <div className=" flex-row">
      //               <Label htmlFor="specific" className="text-right">
      //                 Select Half day or Full Day
      //               </Label>
      //               <div className="">
      //                 <RadioGroupMulti
      //                   // title={"Select Half day or Full Day"}
      //                   list={CoffOptions}
      //                   selected={selectedCoffQuantity}
      //                   setSelected={handleSelectedCoffQuantity}
      //                 />
      //               </div>
      //             </div>

      //             <div className=" flex-row">
      //               <Label htmlFor="measurable" className="text-right">
      //                 Details
      //               </Label>
      //               <Textarea
      //                 defaultValue={inputs?.details}
      //                 type="text"
      //                 onChange={handleChange}
      //                 name="details"
      //                 id="details"
      //                 placeholder="Not feeling well"
      //                 className={`${theme?.border}`}
      //               />
      //             </div>
      //           </div>
      //           <DialogFooter className="pt-2">
      //             <Button
      //               type="button"
      //               variant="primary"
      //               className="bg-background border shadow-sm"
      //               onClick={() =>
      //                 employeeMachineService.send({
      //                   type: "cancel_crud",
      //                 })
      //               }
      //             >
      //               Cancel
      //             </Button>
      //             <Button type="submit">Save changes</Button>
      //           </DialogFooter>
      //         </form>
      //         {selectedCoff ? (
      //           <CoffComments CoffId={selectedCoff?.id} />
      //         ) : null}
      //       </ScrollArea>
      //     </DialogContent>
      //   </DialogBox>
      // );
      case "DELETE":
        return (
          <Transition.Root show={coffsCrud} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                employeeMachineService.send({ type: "cancel_crud" });
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                coff="ease-in duration-200"
                CoffFrom="opacity-100"
                CoffTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    coff="ease-in duration-200"
                    CoffFrom="opacity-100 translate-y-0 sm:scale-100"
                    CoffTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <Button
                          type="submit"
                          size="xs"
                          variant="darkOutline"
                          className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          <X className="h-4 w-4 text-" />
                          <span className="sr-only">Close</span>
                        </Button>
                      </div>
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-semoibold leading-6 text-foreground"
                          >
                            Are you absolutely sure?
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete the coff request?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => {
                            employeeMachineService.send({
                              type: "delete_coff",
                            });
                          }}
                        >
                          Continue
                        </Button>
                        <Button
                          type="button"
                          variant="primary"
                          className="bg-background border shadow-sm mr-2"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        );

      case "ADD":
        return (
          <>
            <DialogBox open={dialogOpen} onOpenChange={handleClose}>
              <DialogContent className="sm:max-w-[70%] p-3  mt-10 sm:ml-20 sm:mr-20">
                <ScrollArea className="h-96 sm:h-[500px] sm:pb-20 pb-10">
                  <DialogHeader>
                    <DialogTitle>{"Apply C-Off Request"}</DialogTitle>
                    <DialogDescription>
                      {
                        "Make changes to your Coffs here. Click save when you're done."
                      }
                    </DialogDescription>
                    <Button
                      size="xs"
                      variant=""
                      className="fixed right-4 top-0 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                      onClick={() => {
                        employeeMachineService.send({ type: "cancel_crud" });
                      }}
                    >
                      <X className="h-5 w-10" />
                    </Button>
                  </DialogHeader>

                  <form onSubmit={submitCoffs} method="POST">
                    <div className="flex flex-col p-1">
                      <div className=" flex-row">
                        <Label htmlFor="measurable" className="text-right">
                          Details
                        </Label>
                        <Textarea
                          value={inputs?.details}
                          type="text"
                          onChange={handleChange}
                          name="details"
                          id="details"
                          placeholder={"Enter details about C-Off"}
                          className={`${theme?.border}`}
                        />
                      </div>

                      <div className=" flex-row">
                        <Label htmlFor="dueDate" className="text-right">
                          Date
                        </Label>
                        {organization?.allowPastCoffs === true ? (
                          <>
                            <Input
                              type="date"
                              onChange={handleChange}
                              name="date"
                              id="date"
                              value={inputs?.date}
                              className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                            />
                          </>
                        ) : (
                          <Input
                            type="date"
                            onChange={handleChange}
                            name="date"
                            id="date"
                            max={today}
                            value={inputs?.date}
                            className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                          />
                        )}
                      </div>

                      <div className=" flex-row">
                        <Label htmlFor="specific" className="text-right">
                          Worked for
                        </Label>
                        <div className="">
                          <RadioGroupMulti
                            list={CoffOptions?.map((option, index) => {
                              if (actionForCoffCRUD === "ADD" && index === 1) {
                                return {
                                  ...option,
                                  disabled: true,
                                  className: "opacity-10 cursor-not-allowed",
                                };
                              }
                              return option;
                            })}
                            selected={selectedLeaveQuantity}
                            setSelected={(value) => {
                              if (
                                !(
                                  actionForCoffCRUD === "ADD" &&
                                  value === CoffOptions[1]?.value
                                )
                              ) {
                                handleSelectedLeaveQuantity(value); // Prevent selection of the disabled option
                              }
                            }}
                          />
                        </div>
                      </div>
                      <DialogFooter className="pt-2"></DialogFooter>
                    </div>
                    <DialogFooter className="pt-2"></DialogFooter>
                  </form>
                  {selectedCoff ? (
                    <CoffComments CoffId={selectedCoff?.id} />
                  ) : null}
                  <div className="flex flex-row justify-end pt-2">
                    <div className=" mr-2">
                      <Button
                        type="button"
                        variant="primary"
                        className="bg-background border shadow-sm"
                        onClick={() =>
                          employeeMachineService.send({
                            type: "cancel_crud",
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                    <Button type="button" onClick={submitCoffs}>
                      Save Changes
                    </Button>
                  </div>
                </ScrollArea>
              </DialogContent>
            </DialogBox>
          </>
        );
    }
  }
}
