import { Fragment, useMemo, useState } from "react";
import log from "loglevel";

import { useEmployeeContextData } from "../../../hooks/employeeMachineHooks";

import { DashboardDataTableWrapper } from "./DashboardDataTableWrapper";
import {
  ScrollArea,
  ScrollBar,
} from "../../../components/CustomComponentsLibrary/ScrollArea";
import { AlarmPlus } from "lucide-react";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { Button } from "../../../components/CustomComponentsLibrary/Button";
import { Link } from "react-router-dom";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export function TodayAttendance() {
  const { todayAttendance } = useEmployeeContextData();
  const { theme } = useAuthContextData();

  let timeData = [];
  function calculateTotalHours(obj) {
    const currentDateTime = new Date();

    let totalHours = 0;

    if (obj.timeIn) {
      const timeIn = new Date(obj.timeIn);
      console.log("timeIn1", timeIn);

      if (obj.timeIn2) {
        const timeIn2 = new Date(obj.timeIn2);

        if (obj.TimeOut2) {
          const timeOut2 = new Date(obj.TimeOut2);
          const duration2 = (timeOut2 - timeIn2) / (1000 * 60 * 60);
          totalHours += duration2;
        } else {
          const duration2 = (currentDateTime - timeIn2) / (1000 * 60 * 60);
          totalHours += duration2;
        }
      }
    }

    return totalHours;
  }

  function calculateTotalHours(obj) {
    const currentDateTime = new Date();

    let totalHours = 0;

    if (obj.timeIn1) {
      const timeIn1 = new Date(obj.timeIn1);

      if (obj.TimeOut1) {
        const timeOut1 = new Date(obj.TimeOut1);
        const duration1 = (timeOut1 - timeIn1) / (1000 * 60 * 60);
        totalHours += duration1;
      } else {
        const duration2 =
          (currentDateTime - new Date(obj.timeIn1)) / (1000 * 60 * 60);
        totalHours += duration2;
      }

      if (obj.timeIn2) {
        const timeIn2 = new Date(obj.timeIn2);

        if (obj.TimeOut2) {
          const timeOut2 = new Date(obj.TimeOut2);
          const duration2 = (timeOut2 - timeIn2) / (1000 * 60 * 60);
          totalHours += duration2;
        } else {
          const duration2 = (currentDateTime - timeIn2) / (1000 * 60 * 60);
          totalHours += duration2;
        }
      }
    }

    return totalHours;
  }

  const todaysHours = useMemo(() => {
    if (todayAttendance?.totalHours) return todayAttendance?.totalHours;
    if (todayAttendance) {
      let totalHours = calculateTotalHours(todayAttendance);
      return totalHours;
    }
    return 0;
  }, todayAttendance);
  log.debug("todayAttendance", todayAttendance);

  const columns = [
    {
      header: "Time In",
      accessorKey: "inTime1",
      cell: ({ row }) => (
        <div>
          {todayAttendance?.timeIn1 && (
            <span className="text-base">
              {new Date(todayAttendance?.timeIn1).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          )}
        </div>
      ),
    },

    {
      header: "Time Out",
      accessorKey: "outTime2",
      cell: ({ row }) => (
        <div className="text-base">
          {todayAttendance?.TimeOut2 && (
            <span>
              {new Date(todayAttendance?.TimeOut2).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          )}
        </div>
      ),
    },
    {
      header: "Total",
      accessorKey: "total",
      cell: ({ row }) => (
        <div className="text-base">{todaysHours.toFixed(2)}</div>
      ),
    },
  ];

  return (
    <>
      <div className="text-[19px] shadow-lg rounded-md border-t border-t-zinc-100 h-80">
        <div className="px-3 py-2">
          <span className="font-extrabold">Today's Attendance</span>
          <span className="float-right ">
            <Button variant="link" className="font-bold underline text-base">
              <Link to={`/employee/calendar`}>{`Add Time`}</Link>
            </Button>
          </span>
        </div>
        <div className="flex justify-start align-middle gap-5 p-5">
          <AlarmPlus className="p-2 w-2 h-2 border rounded-full sm:w-8 sm:h-8" />
          <div className="text-2xl text-black">{todaysHours.toFixed(2)}</div>
          <div className="text-black text-base mt-1">Total hours Today</div>
        </div>

        <div className="rounded-none px-4">
          <ScrollArea className="h-48 w-2/2">
            <DataTable
              columns={columns}
              data={[todayAttendance]}
              dataTableToolBar={false}
              customCss={`${theme?.border}`}
              customWidth={[
                {
                  index: 0,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 1,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
                {
                  index: 2,
                  cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                },
              ]}
            />
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </div>
    </>
  );
}
