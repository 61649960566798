import React, { useState } from "react";
import { TextField } from "../Fields";
import NewPassword from "../NewPassword";
import Message from "../common/Message";
import {
  useAuthContextData,
  useAuthRegisterState,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import log from "loglevel";
import { Button } from "../CustomComponentsLibrary/Button";
import ButtonComponent from "../common/ButtonComponent";
export default function EmailCodeVerify({ resetPassword = false }) {
  const { error, organizationID } = useAuthContextData;
  log.debug("error", error, organizationID);
  const [codeVerified, setCodeVerified] = useState(0);
  const [code, setCode] = useState(0);
  const [message, setMessage] = useState({ messageText: "", messageType: "" });
  const { isCodeVerifying } = useAuthRegisterState();

  const authService = useAuthService();

  function handleCodeUpdate(e) {
    log.debug(e.target.value, code);
    if (e.target.value.length < 7) setCode(e.target.value);
  }
  const email = "";
  return codeVerified ? (
    <NewPassword
      setCodeVerified={setCodeVerified}
      email={email}
      authCode={code}
    />
  ) : (
    <div className="flex h-full flex-col place-items-center gap-3 md:overflow-y-hidden">
      <div className="text-center mt-8 text-3xl font-bold">
        Verify your email
      </div>
      <TextField
        label="code"
        id="code"
        name="code"
        // type="number"
        value={code}
        onChange={handleCodeUpdate}
        required
        className="mt-2 h-16 w-44"
      />
      <p className="mt-8 text-center">
        We have sent a verification code to your email <br></br> box. Enter this
        code to verify your account
      </p>
      {message.messageText ? (
        <div className="text-center">
          <Message message={message} setMessage={setMessage} />
        </div>
      ) : (
        ""
      )}
      <div className="flex w-full  flex-col-reverse place-items-center">
        <div className="mt-12 mb-4 w-full text-center">
          <ButtonComponent
            name="Continue"
            action={() => {
              if (resetPassword) {
                setCodeVerified(true);
              } else {
                authService.send({ type: "submitCode", data: code });
              }
            }}
            className="bg-background text-text"
          />
        </div>

        <Button
          className="textl-lg cursor-pointer bg-background text-text"
          onClick={() => {
            authService.send({ type: "resendCode" });
          }}
          // className="w-full text-center font-bold text-delightmenuColor1"
        >
          Resend Code
        </Button>
      </div>
    </div>
  );
}
