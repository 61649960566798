import React, { useEffect } from "react";
import log from "loglevel";
import clsx from "clsx";

export default function Message({
  message: { messageText, messageType },
  setMessage,
}) {
  useEffect(() => {
    /* setTimeout(() => {
      setMessage({ messageText: '', messageType: '' })
    }, 1500)*/
    log.debug("Message", messageText);
  }, [messageText]);

  return (
    <div
      className={clsx(
        messageType === "success" ? "text-blue-600" : "",
        messageType === "failure" ? "text-red-600" : ""
      )}
    >
      {messageText}
    </div>
  );
}
