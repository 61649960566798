import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "@aws-amplify/core";
import awsconfig from "./aws-exports";
import { BrowserRouter } from "react-router-dom";
import { AuthMachineContextProvider } from "./context/authMachineContext";
import { Authenticator } from "@aws-amplify/ui-react";
import log from "loglevel";

console.log("NODE_ENV ", process.env.NODE_ENV);
console.log("log  ", log);
console.log("log.levels  ", log.levels);

console.log("log.levels.DEBUG  ", log.levels.DEBUG);

if (process.env.NODE_ENV === "production") {
  log.setLevel(log.levels.WARN);
} else {
  log.setLevel(log.levels.DEBUG);
}

Amplify.configure(awsconfig);
const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: "cd566fac60c54b8d88c9d385000e837a",
    // Amazon service region
    region: "ap-south-1",
    mandatorySignIn: false,
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Authenticator.Provider>
        <AuthMachineContextProvider>
          <App />
        </AuthMachineContextProvider>
      </Authenticator.Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
