import log from "loglevel";
import ProjectTasksList from "../../components/employee/Projects/ProjectTasksList";
import AddEditDeleteTask from "../../components/employee/Tasks/AddEditDeleteTask";
import { useEmployeeContextState } from "../../hooks/employeeMachineHooks";
export function EmployeeProjectsTasks() {
  const { taskCrud } = useEmployeeContextState();
  log.debug("taskCrudData", taskCrud);
  return (
    <div className="w-full overflow-x-auto">
      <ProjectTasksList />
      {taskCrud ? <AddEditDeleteTask /> : <></>}
    </div>
  );
}
