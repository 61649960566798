import log from "loglevel";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { API } from "aws-amplify";

export const hookAddOrgYearLeaves = async (OrgYearLeaves) => {
  log.debug(" hookAddOrgYearLeaves : ", OrgYearLeaves);

  try {
    const result = await API.graphql({
      query: mutations.createOrgYearLeaves,
      variables: {
        input: OrgYearLeaves,
      },
    });

    log.debug(" hookAddOrgYearLeaves result: ", result);

    return result?.data?.createOrgYearLeaves;
  } catch (err) {
    log.debug("CATCH ERROR hookAddOrgYearLeaves result: ", err);
    return null;
  }
};

export const hookUpdateOrgYearLeaves = async (OrgYearLeaves) => {
  log.debug("hookUpdateOrgYearLeaves: ", OrgYearLeaves);
  try {
    const result = await API.graphql({
      query: mutations.updateOrgYearLeaves,
      variables: {
        input: OrgYearLeaves,
      },
    });

    log.debug(" hookUpdateOrgYearLeaves result: ", result);
    return result?.data?.updateOrgYearLeaves;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateOrgYearLeaves result: ", err);
  }
};

export const hookDeleteOrgYearLeavesByOrgYearLeavesId = async (
  OrgYearLeavesId
) => {
  log.debug(" hookDeleteOrgYearLeavesByOrgYearLeavesId  ", OrgYearLeavesId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteOrgYearLeaves,
      variables: {
        input: { id: OrgYearLeavesId },
      },
    });
    log.debug(" hookDeleteOrgYearLeavesByOrgYearLeavesId : ", data);
    if (data !== null) return data?.data?.deleteOrgYearLeaves;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeleteOrgYearLeavesByOrgYearLeavesUniqueID -- ",
      e
    );
    return null;
  }
};

export const hookGetOrgYearLeavesByOrgYearLeavesId = async (
  OrgYearLeavesId
) => {
  log.debug("  hookGetOrgYearLeavesByOrgYearLeavesId ", OrgYearLeavesId);

  try {
    const data = await API.graphql({
      query: queries.getOrgYearLeaves,
      variables: {
        input: { id: OrgYearLeavesId },
      },
    });

    log.debug(" hookGetOrgYearLeavesByOrgYearLeavesId : ", data);
    if (data !== null) return data?.data?.getOrgYearLeaves;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetOrgYearLeavesByOrgYearLeavesId -- ", e);
    return null;
  }
};
export const hookListOrgYearLeavessByOrgId = async (orgId) => {
  log.debug("hookListOrgYearLeavessByOrgId OrgId", orgId);
  try {
    const data = await API.graphql({
      query: queries.OrgYearLeavesByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" hookListOrgYearLeavessByOrgId : ", data);
    if (data !== null) return data?.data?.OrgYearLeavesByOrganizationID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListOrgYearLeavessByOrgId -- ", e);
    return null;
  }
};
