import { useSelector } from "@xstate/react";
import { useContext } from "react";
import log from "loglevel";
import { EmployeeContext } from "../context/employeeMachineContext";

export function useEmployeeContextData() {
  const employeeMachineService = useContext(EmployeeContext);
  const employeeMachineContext = useSelector(
    employeeMachineService,
    (state) => {
      log.debug("STATE MACHINE", state);
      return state.context;
    }
  );

  return employeeMachineContext;
}

export function useEmployeeContextState() {
  const employeeMachineService = useContext(EmployeeContext);

  //TODO: similarly add states for each state required for CRUD and return

  const goalCrud = useSelector(employeeMachineService, (state) =>
    state.matches("GOALS.GOAL_CRUD")
  );
  const blogCrud = useSelector(employeeMachineService, (state) => {
    log.debug("blogCrud", state, state.matches("BLOGS.BLOG_CRUD"));
    return state.matches("BLOGS.BLOG_CRUD");
  });

  const taskCrud = useSelector(employeeMachineService, (state) => {
    log.debug("taskCrud", state, state.matches("TASKS.TASK_CRUD"));
    return state.matches("TASKS.TASK_CRUD");
  });
  const taskHoursCrud = useSelector(employeeMachineService, (state) => {
    return state.matches("EMPLOYEE_LOG_HOURS.TASK_HOURS_CRUD");
  });

  const leavesCrud = useSelector(employeeMachineService, (state) => {
    log.debug("leavesCrud", state);
    log.debug("leavesCrud state", state.matches("LEAVES.LEAVE_CRUD"));
    return state.matches("LEAVES.LEAVE_CRUD");
  });

  const coffsCrud = useSelector(employeeMachineService, (state) => {
    log.debug("coffsCrud", state);
    log.debug("coffsCrud state", state.matches("COFFS.COFF_CRUD"));
    return state.matches("COFFS.COFF_CRUD");
  });

  const inventoryCrud = useSelector(employeeMachineService, (state) => {
    log.debug("inventoryCrud", state);
    log.debug("inventoryCrud state", state.matches("INVENTORY.INVENTORY_CRUD"));
    return state.matches("INVENTORY.INVENTORY_CRUD");
  });

  const reimbursementCRUD = useSelector(employeeMachineService, (state) => {
    log.debug(
      "reimbursementCRUD==>",
      state,
      state.matches("REIMBURSEMENTS.REIMBURSEMENT_CRUD")
    );
    return state.matches("REIMBURSEMENTS.REIMBURSEMENT_CRUD");
  });

  const courseCrud = useSelector(employeeMachineService, (state) => {
    log.debug("courseCrud", state);
    log.debug("courseCrud state", state.matches("COURSES.COURSE_CRUD"));
    return state.matches("COURSES.COURSE_CRUD");
  });
  return {
    courseCrud,
    goalCrud,
    taskCrud,
    leavesCrud,
    inventoryCrud,
    reimbursementCRUD,
    taskHoursCrud,
    blogCrud,
    coffsCrud
  };
}

export function useEmployeeContextService() {
  const employeeMachineService = useContext(EmployeeContext);

  return employeeMachineService;
}
