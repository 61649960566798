import React, { useEffect } from "react";
import {
  TypographyH2,
  TypographyLabel,
} from "../../components/CustomComponentsLibrary/Typography";
import { MyTasks } from "./metrics/MyTasks";
import { MyGoals } from "./metrics/MyGoals";
import { MyLeaves } from "./metrics/MyLeaves";
import log from "loglevel";
import { BirthDaysOfTheMonth } from "./metrics/BirthDaysOfTheMonth";
import { CurrentWeekOnLeave } from "./metrics/CurrentWeekOnLeave";
import { TodayAttendance } from "./metrics/TodayAttendance";
import { TopTeamMembers } from "./metrics/TopTeamMembers";
import { Link } from "react-router-dom";
import { TimesheetChart } from "./metrics/TimesheetChart";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../hooks/employeeMachineHooks";
import PageHeader from "../../components/common/PageHeader";
import { BookMarked, Library, ListChecks, LocateFixed } from "lucide-react";
import { Skeleton } from "../../components/CustomComponentsLibrary/Skeleton";
import { useSelector } from "@xstate/react";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
export default function EmployeeDashboard() {
  const employeeService = useEmployeeContextService();

  const { dashboardData, projects } = useEmployeeContextData();
  const { goals, tasks, courses } = dashboardData;

  const isDashboardLoading = useSelector(employeeService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );

  const isTaskCompleted = tasks?.filter(
    (item) => item.status == "TASKSTATUS_DONE"
  );

  const isGoalCompleted = goals?.filter((item) => item.percentage == 100);
  const isCourseCompleted = courses?.filter((item) => item.progress == 100);
  log.debug("courses", isCourseCompleted);

  var doneGoalCount = 0;
  var totalGoalCount = 0;
  goals?.forEach((item) => {
    if (item.percentage == 100) {
      doneGoalCount += 1;
    }
    totalGoalCount += 1;
  });

  useEffect(() => {
    employeeService.send({ type: "load_dashboard_data" });
  }, []);
  const { theme } = useAuthContextData();
  return (
    <div className="m-4 h-full">
      <div>
        <PageHeader
          title="Dashboard"
          SubHeading="An overview of all the task and courses"
        />
      </div>

      <div className="flex flex-col space-y-4 gap-2 sm:flex-row sm:space-x-4 xl:ml-4">
        <Link
          to="/employee/tasks"
          className={`"w-full sm:w-1/4 xl:w-96 h-28 mt-4  flex justify-between  shadow shadow-common p-4  rounded-lg" ${theme?.dbcard} ${theme?.cardRound} ${theme?.textColor} ${theme?.border}`}
        >
          <div className="mt-3">
            <TypographyH2>
              {!isDashboardLoading ? (
                isTaskCompleted?.length
              ) : (
                <>
                  <Skeleton className="h-8 w-8 rounded-full" />
                </>
              )}
            </TypographyH2>

            <TypographyLabel> Task Completed</TypographyLabel>
          </div>
          <ListChecks />
        </Link>
        <Link
          to="/employee/goals"
          className={`"w-full sm:w-1/4 xl:w-96 h-28 mt-4  flex justify-between  shadow shadow-common p-4  rounded-lg" ${theme?.dbcard} ${theme?.cardRound} ${theme?.textColor} ${theme?.border}`}
        >
          <div className="mt-3">
            <TypographyH2>
              {!isDashboardLoading ? (
                isGoalCompleted?.length + "/" + goals?.length
              ) : (
                <>
                  <Skeleton className="h-8 w-8 rounded-full" />
                </>
              )}
            </TypographyH2>

            <TypographyLabel> Goals Completed</TypographyLabel>
          </div>
          <div>
            <LocateFixed />
          </div>
        </Link>
        <Link
          to="/employee/projects"
          className={`"w-full sm:w-1/4 xl:w-96 h-28 mt-4  flex justify-between  shadow shadow-common p-4  rounded-lg" ${theme?.dbcard} ${theme?.cardRound} ${theme?.textColor} ${theme?.border}`}
        >
          <div className="mt-3">
            <TypographyH2>
              {projects ? (
                projects?.length
              ) : (
                <Skeleton className="h-8 w-8 rounded-full" />
              )}
            </TypographyH2>

            <TypographyLabel>Ongoing Project</TypographyLabel>
          </div>
          <Library />
        </Link>
        <Link
          to="/employee/courses"
          className={`"w-full sm:w-1/4 xl:w-96 h-28 mt-4  flex justify-between  shadow shadow-common p-4  rounded-lg" ${theme?.dbcard} ${theme?.cardRound} ${theme?.textColor} ${theme?.border}`}
        >
          <div className="mt-3">
            <TypographyH2>
              {!isDashboardLoading ? (
                isCourseCompleted?.length
              ) : (
                <>
                  <Skeleton className="h-8 w-8 rounded-full" />
                </>
              )}
            </TypographyH2>

            <TypographyLabel>Course Completed</TypographyLabel>
          </div>
          <BookMarked />
        </Link>
      </div>

      <div className="grid grid-cols-12  gap-x-5 gap-y-6 mt-10 pb-10">
        <div className="col-span-12 sm:col-span-7">
          <MyTasks />
        </div>
        <div className="col-span-12 sm:col-span-5">
          <MyLeaves />
        </div>
        <div className="col-span-12 sm:col-span-7">
          <MyGoals />
        </div>
        <div className="col-span-12 sm:col-span-5">
          <BirthDaysOfTheMonth />
        </div>
        <div className="col-span-12 sm:col-span-7">
          <TimesheetChart />
        </div>
        <div className="col-span-12 sm:col-span-5">
          <CurrentWeekOnLeave />
        </div>
        <div className="col-span-12 sm:col-span-7">
          <TopTeamMembers />
        </div>
        <div className="col-span-12 sm:col-span-5">
          <TodayAttendance />
        </div>
      </div>
    </div>
  );
}
