import { useEffect } from "react";
import { useToast } from "../components/CustomComponentsLibrary/UseToast";
import log from "loglevel";

export function useToastEffect(machineService) {
  const { toast } = useToast();
  useEffect(() => {
    let subscription = machineService.subscribe((state) => {
      log.debug("STATE CHANGED==========>", state);
      if (JSON.stringify(state.value).includes("ERRORED")) {
        toast({
          variant: "destructive",
          title: "Failed",
          description: state?.context?.message,
        });
        machineService.send({ type: "ack" });
      }
      if (JSON.stringify(state.value).includes("SUCCEEDED")) {
        toast({ title: "Success", description: state?.context?.message });
        machineService.send({ type: "ack" });
      }
    });

    return () => {
      if (subscription) {
        log.debug("Returnining Unsubscribe: ");
        subscription.unsubscribe();
      }
    };
  }, []);
}
