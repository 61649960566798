import { Storage } from "aws-amplify";
import log from "loglevel";

Storage.configure({ level: "public" });

export const GetSignedURL = async (key, privacyLevel) => {
  try {
    log.debug("GetSignedURL ++  ", key);

    const signedURL = await Storage.get(key, { level: privacyLevel });
    log.debug("GetSignedURL ++ signedURL= ", signedURL);

    return signedURL;
  } catch (error) {
    log.debug("GetSignedURL ERROR -- ", error);
    return error;
  }
};

export const convertS3UrlToBase64 = async (s3Url) =>
  fetch(s3Url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
