import React from "react";

import ReactPlayer from "react-player";
import { Button } from "../../CustomComponentsLibrary/Button";
import S3FileViewer from "./S3FileViewer";
import { ScrollArea } from "../../CustomComponentsLibrary/ScrollArea";

export default function ResourceView({ item }) {
  return (
    <div className="border-2 border-light  shadow-xl rounded-lg p-2">
      <div className="flex flex-row justify-between">
        <span className="text-black font-bold text-lg">
          {item?.index} . {item?.name}
        </span>
        <span className="text-muted-foreground">Duration:2.00</span>
      </div>
      <div className="flex flex-row mx-3 text-muted-foreground py-2">
        {item.description}
      </div>
      <div className="w-full p-6">
        {item?.type === "YOUTUBE" ? (
          <div className="my-2  w-full  flex flex-col mx-auto">
            <ReactPlayer
              url={item?.resourceKey}
              width="100%"
              height="500px"
              className="w-full"
            />
          </div>
        ) : item?.type === "S3STORAGE" ? (
          <ScrollArea className="h-96 ">
            <S3FileViewer url={item?.resourceKey} />
          </ScrollArea>
        ) : (
          <a href={item?.resourceKey} target="_blank" rel="noopener noreferrer">
            <Button variant="link"> {item?.resourceKey}</Button>
          </a>
        )}
      </div>
    </div>
  );
}
