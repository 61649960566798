import {
  hookAddEmployeeTask,
  hookListEmployeeTaskByUserId,
  hookUpdateEmployeeTask,
  hookDeleteEmployeeTaskByEmployeeTaskId,
} from "../../hooks/employeeTasksHooks";
import log from "loglevel";
import { CONST_UPDATE_CONTEXT_ACTIONS } from "../../constants/dbconstants";
import { hookGetEmployeeTasksByProjectID } from "../../hooks/projectHooks";

export const getTaskDataByEmployeeIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID } = context;

    log.debug("tasks:getTaskDataByEmployeeId b4 calling hook ", context);

    const tasks = await hookListEmployeeTaskByUserId(userID);
    log.debug("tasks:getTaskDataByEmployeeId", tasks);
    // if (tasks) resolve({ tasks });
    if (tasks)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
          contextField: "tasks",
          value: tasks,
        },
      ]);
    reject("Failed To Fetch tasks");
  });

export const submitTaskDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskToBeAdded, organizationID } = context;
    log.debug("taskToBeAdded:submitTaskData", taskToBeAdded);
    const task = await hookAddEmployeeTask({
      ...taskToBeAdded,
      organizationID,
    });
    log.debug("taskToBeAdded:tasks", task);

    if (task) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
          contextField: "tasks",
          value: task,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully added task",
        },
      ]);
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To add task",
      },
    ]);
  });

export const updateTaskDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskToBeAdded } = context;
    const res = await hookUpdateEmployeeTask({
      ...taskToBeAdded,
    });
    if (res)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "tasks",
          value: res,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully updated task",
        },
      ]);
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update Task",
      },
    ]);
  });

export const deleteTaskDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedTaskId } = context;
    log.debug("deleteTaskData====>", selectedTaskId);
    const taskDeleted = await hookDeleteEmployeeTaskByEmployeeTaskId(
      selectedTaskId
    );
    log.debug("taskDeleted====>", taskDeleted);
    if (taskDeleted) resolve(true);
    reject("Failed To Delete Task");
  });

export const getTaskDataBySelectedProjectId = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedProjectId, selectedProjectForTasks } = context;
    log.debug(
      "selectedProjectId::getTaskDataBySelectedProjectId",
      selectedProjectForTasks,
      selectedProjectId
    );

    if (selectedProjectId || selectedProjectForTasks) {
      let multipletaskData = [];
      let data = null;
      let tasks = [];
      const fetchDataForProject = async (project) => {
        try {
          const taskData = await hookGetEmployeeTasksByProjectID(project?.id);
          data = {
            projectId: project?.id,
            projectTitle: project?.title,
            taskData: taskData,
          };

          multipletaskData.push(data);
        } catch (error) {
          console.error(
            `Error fetching tasks for project ${project?.title}:`,
            error
          );
        }
      };

      if (selectedProjectForTasks) {
        await Promise.all(
          selectedProjectForTasks.map((element) => fetchDataForProject(element))
        );
      }
      if (selectedProjectId) {
        tasks = await hookGetEmployeeTasksByProjectID(selectedProjectId);
      }

      if (tasks || multipletaskData) {
        log.debug("getTaskDataBySelectedProjectId", tasks);
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "projectTasks",
            value: tasks,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "multipleProjectTasks",
            value: multipletaskData,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "SuccessFully fetched tasks",
          },
        ]);
      }
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Fetch tasks",
      },
    ]);
  });
