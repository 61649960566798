import { useSelector } from "@xstate/react";
import { useContext } from "react";
import { AuthMachineContext } from "../context/authMachineContext";
import {
  contextSelector,
  loginSelector,
  activityPermissionSelecter,
  checkForActivityPermissionSelecter,
  appReadySelecter,
  signUpFormSubmittingSelecter,
  signUpCodeVerifyingSelecter,
  isLoggingOutSelector,
  isDeletingUserSelector,
  isWelcomeStateSelector,
  isSignInStateSelector,
  isSignUpStateSelector,
  codeVerifyingSelecter,
  mailSentSelecter,
  checkMailSentSelecter,
  formSubmittingSelector,
  formCreatingSelector,
  isForgorPasswordStateSelector,
} from "../selectors/authMachineSelectors";

export function useAuthContextData() {
  const authMachineService = useContext(AuthMachineContext);
  const {
    userProfile,
    isUserExists,
    platform,
    error,
    navigations,
    navigationCount,
    userType,
    staffDetails,
    employeeProfile,
    organizationID,
    email,
    organization,
    domain,
    paymentURLs,
    signUpDetails,
    organizationStyling,
    theme,
  } = useSelector(authMachineService.authService, contextSelector);

  return {
    email,
    domain,
    userProfile,
    platform,
    isUserExists,
    error,
    paymentURLs,
    navigations,
    navigationCount,
    userType,
    staffDetails,
    employeeProfile,
    organizationID,
    organization,
    signUpDetails,
    organizationStyling,
    theme,
  };
}

export function useAuthContextState() {
  const authMachineService = useContext(AuthMachineContext);
  const isWelcomeState = useSelector(
    authMachineService.authService,
    isWelcomeStateSelector
  );
  const isLoggedInState = useSelector(
    authMachineService.authService,
    loginSelector
  );
  const appReadyToStartState = useSelector(
    authMachineService.authService,
    appReadySelecter
  );
  const checkingCogntioUser = useSelector(
    authMachineService.authService,
    (state) => state.matches("LOGGED_OUT.USER_CHECK_SIGNED_IN")
  );
  const approvalPending = useSelector(authMachineService.authService, (state) =>
    state.matches("LOGGED_IN.USER_PROFILE_PRESENT.ADMIN_WAITING_APPROVAL")
  );

  const noRestaurantJoined = useSelector(
    authMachineService.authService,
    (state) =>
      state.matches(
        "LOGGED_IN.USER_PROFILE_PRESENT.STAFF_USER.NEW_STAFF_MEMBER"
      )
  );

  const restaurantJoining = useSelector(
    authMachineService.authService,
    (state) =>
      state.matches(
        "LOGGED_IN.USER_PROFILE_PRESENT.STAFF_USER.RESTAURANT_JOINING"
      )
  );

  const waitinForRestaurantApproval = useSelector(
    authMachineService.authService,
    (state) =>
      state.matches(
        "LOGGED_IN.USER_PROFILE_PRESENT.STAFF_USER.RESTAURANT_APPROVAL_PENDING"
      )
  );

  const checkForActivityPermissionsState = useSelector(
    authMachineService.authService,
    checkForActivityPermissionSelecter
  );

  const activityPermissionGrantedState = useSelector(
    authMachineService.authService,
    activityPermissionSelecter
  );
  const signUpFormSubmitting = useSelector(
    authMachineService.authService,
    signUpFormSubmittingSelecter
  );
  const signUpCodeVerifying = useSelector(
    authMachineService.authService,
    signUpCodeVerifyingSelecter
  );

  const isLoggingOut = useSelector(
    authMachineService.authService,
    isLoggingOutSelector
  );
  const isDeletingUser = useSelector(
    authMachineService.authService,
    isDeletingUserSelector
  );
  const isSignInState = useSelector(
    authMachineService.authService,
    isSignInStateSelector
  );

  const isForgotPasswordState = useSelector(
    authMachineService.authService,
    isForgorPasswordStateSelector
  );

  const isSignUpState = useSelector(
    authMachineService.authService,
    isSignUpStateSelector
  );

  const orgAdding = useSelector(authMachineService.authService, (state) =>
    state.matches("LOGGED_OUT.ORG_ADDING")
  );

  return {
    orgAdding,
    isLoggedInState,
    appReadyToStartState,
    activityPermissionGrantedState,
    checkForActivityPermissionsState,
    signUpFormSubmitting,
    signUpCodeVerifying,
    isLoggingOut,
    isDeletingUser,
    isWelcomeState,
    isSignInState,
    isSignUpState,
    approvalPending,
    checkingCogntioUser,
    noRestaurantJoined,
    isForgotPasswordState,
    restaurantJoining,
    waitinForRestaurantApproval,
  };
}
export function useAuthRegisterState() {
  const authMachineService = useContext(AuthMachineContext);

  const isFormCreating = useSelector(
    authMachineService.authService,
    formCreatingSelector
  );
  const isFormSubmitting = useSelector(
    authMachineService.authService,
    formSubmittingSelector
  );
  const checkMailSent = useSelector(
    authMachineService.authService,
    checkMailSentSelecter
  );
  const isMailSent = useSelector(
    authMachineService.authService,
    mailSentSelecter
  );
  const isCodeVerifying = useSelector(
    authMachineService.authService,
    codeVerifyingSelecter
  );
  return {
    isCodeVerifying,
    isMailSent,
    checkMailSent,
    isFormSubmitting,
    isFormCreating,
  };
}
export function useAuthService() {
  const authMachineService = useContext(AuthMachineContext);
  return authMachineService?.authService;
}
