import React, { useEffect, useState, useCallback } from "react";
import PageHeader from "../../common/PageHeader";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import log from "loglevel";
import {
  hookGetEmployeeShortProfileById,
  hookListEmployeesByOrgnizationId,
} from "../../../hooks/employeeHooks";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { Button } from "../../CustomComponentsLibrary/Button";
import { FriendlyName } from "../../../lib/friendlyName";
import { User } from "../../common/User";
import { ScrollArea } from "../../CustomComponentsLibrary/ScrollArea";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
} from "reactflow";
import { Handle, Position } from "reactflow";

import "reactflow/dist/base.css";
import { CONST_USER_TYPES } from "../../../constants/dbconstants";
import { useParams } from "react-router-dom";

export const OrganizationGraph = () => {
  const { initNodesData, initEdgesData, userID, projects } =
    useEmployeeContextData();
  const parmas = useParams();
  const employeeMachineService = useEmployeeContextService();
  useEffect(() => {
    employeeMachineService.send({
      type: "send_hirarchy_event",
      data: {
        userID: parmas?.currentUserId ? parmas?.currentUserId : userID,
        
      },
    });
  }, [userID, projects]);
  log.debug("initNodesData::", initNodesData, initEdgesData);
  return (
    <div className="m-3">
      <div>
        <PageHeader
          title="Organization Hierarchy"
          SubHeading="See the heirarchy of your organization."
        />
      </div>
      <div className="shadow-md rounded-lg mt-5 border-t border-zinc-100  h-[650px]">
        {initNodesData?.length > 1 ? (
          <FlowChartOrganization
            initNodes={initNodesData}
            initEdges={initEdgesData}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const nodeTypes = {
  custom: CustomNode,
};

export function CustomNode({ data }) {
  log.debug("data::CustomNode", data);
  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
      <div className="flex">
        <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-100">
          {data.emoji}
        </div>
        <div className="ml-2">
          <div className="text-2xl font-bold">{data.name}</div>
          <div className="text-gray-500 font-bold text-1xl">{data.job}</div>
          <div className="text-gray-500">{data.email}</div>
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Top}
        className="w-16 !bg-blue-500"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-16 !bg-blue-500"
      />
    </div>
  );
}

const FlowChartOrganization = ({ initNodes, initEdges }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
  log.debug("initNodes:::", initNodes, initEdges);
  log.debug("nodeTypes:::", nodeTypes);
  log.debug("edges:::nodes", edges, nodes);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
        className="bg-gradient-to-r from-green-100 via-blue-200 to-blue-200"
      >
        <MiniMap />
        <Controls />
      </ReactFlow>
    </div>
  );
};
