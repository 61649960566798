import React from "react";
import { TabPageLink } from "../../../components/common/TabPageLink";
import { useEmployeeContextData } from "../../../hooks/employeeMachineHooks";
import { LeavesLayout } from "./LeavesLayout";
import { COffsLayout } from "./COffsLayout";
import { EmployeeHolidays } from "../../../components/employee/Calendar/Holiday/EmployeeHolidays";
import TabPageLink2 from "../../../components/common/TabPageLink2";
import { TimeSheet } from "../../../components/employee/Calendar/Timesheet";

const tabs = [
  {
    name: "Timesheet",
    element: <TimeSheet />,
  },
  {
    name: "Leaves",
    element: <LeavesLayout />,
  },
  {
    name: "Holidays",
    element: <EmployeeHolidays />,
  },
  {
    name: "C-offs",
    element: <COffsLayout/>
  },
  {
    name: "Meetings",
    element: <></>,
  },
];

export function EmployeeCalendarLayout() {
  const { organization } = useEmployeeContextData();
  console.log("RoutestoGen", organization?.attendanceFrom);
  var logHour = [];
  if (organization?.attendanceFrom === "PROJECT_HOURS_AS_TIMESHEET") {
    logHour = tabs.filter((item) => item.name != "Timesheet");
  }

  return (
    <>
      {organization?.attendanceFrom === "PROJECT_HOURS_AS_TIMESHEET" ? (
        <>
          <TabPageLink2 tabs={logHour} />
        </>
      ) : (
        <>
          <TabPageLink2 tabs={tabs} />
        </>
      )}
    </>
  );
}
