import React, { useMemo, useState } from "react";
import Message from "../common/Message";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import {
  useAuthContextData,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import log from "loglevel";
import { Input } from "../CustomComponentsLibrary/Input";
import { Label } from "../CustomComponentsLibrary/Label";
import { TypographyH2 } from "../CustomComponentsLibrary/Typography";
import { Button } from "../CustomComponentsLibrary/Button";
import { Checkbox } from "../CustomComponentsLibrary/Checkbox";

export default function LoginComponent() {
  const authService = useAuthService();
  const { theme } = useAuthContextData();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({
    messageText: "",
    messageType: "",
  });

  //Below is for Candidate login we need to avoid common public emails to be signedup as organization
  const { orgDomainName } = useParams();
  // const [isCandidateForValidOrg, setIsCandidateForValidOrg] = useState(
  //   orgDomainName ? true : false
  // );
  log.debug("theme", theme);
  const isCandidateForValidOrg = useMemo(() => {
    return orgDomainName ? true : false;
  }, [orgDomainName]);
  async function signIn() {
    try {
      let loginRemovedSpace = username.replace(/ /g, "");
      let loginPassword = password;
      await Auth.signIn(loginRemovedSpace, loginPassword);
      // setMessage('Login Successful, please wait')
      setMessage({
        messageText: "Login Successful, please wait",
        messageType: "success",
      });
    } catch (err) {
      setMessage({
        messageText: err.message,
        messageType: "failure",
      });
      // setMessage(err.message)
      log.debug("error signing In..", err);
    }
  }

  return (
    <div className="flex h-95% flex-col">
      <div className="flex min-h-full items-center justify-center bg-transparent py-8 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md ">
          <div className="text-center">
            <TypographyH2>Sign In</TypographyH2>
            <Label>Enter your following details to sign in.</Label>
          </div>
          <div className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <Label>Email Address</Label>
                <Input
                  type="email"
                  id="email"
                  autoComplete="email"
                  className={`"mt-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                  required
                  onChange={(e) => {
                    log.debug("OnChange", e.target.value);
                    setUsername(e.target.value);
                  }}
                />
              </div>
              <div>
                <Label>Password</Label>
                <Input
                  type="password"
                  id="password"
                  autoComplete="password"
                  className={`"mt-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                  required
                  onChange={(e) => {
                    log.debug("OnChange", e.target.value);
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Checkbox id="remember-me" name="remember-me" type="checkbox" />

                <Label className="ml-2 block text-sm"> Remember me</Label>
              </div>
              <Button
                onClick={() => {
                  authService.send({
                    type: "forgot_password",
                  });
                }}
                variant="link"
                className={`${theme?.textLight}`}
              >
                Forgot your Password?
              </Button>
            </div>
            <div className="flex min-h-full  items-center justify-center px-4 sm:px-6 lg:px-8">
              <Button
                className={`" items-center px-4 py-5  md:w-full w-64 text-center text-base font-medium" ${theme?.background} ${theme?.textColor}`}
                onClick={() => {
                  signIn();
                  log.debug(
                    "Calling login API and update the context and forward the user to home page"
                  );
                }}
              >
                Sign In
              </Button>
            </div>

            <div className="flex  justify-center ">
              <p className=" mt-2 text-sm text-black md:visible">
                Don’t have an account?
                <Button
                  variant="link"
                  onClick={() => {
                    authService.send({
                      type: "signUpOptions",
                      data: isCandidateForValidOrg,
                    });
                  }}
                  className={`${theme?.textLight}`}
                >
                  Sign Up
                </Button>
                for a free trial.
              </p>
            </div>

            {message.messageText ? (
              <>
                <div className="flex flex-row justify-center gap-2">
                  <div className="bg-red-100 py-4 text-center lg:px-4">
                    <div
                      className="flex items-center bg-red-300 p-2 leading-none text-white lg:inline-flex lg:rounded-full"
                      role="alert"
                    >
                      <span className="mr-3 flex rounded-full  bg-red-300 px-2 py-1 text-xs font-bold uppercase">
                        error
                      </span>
                      <span className="mr-2 flex-auto text-left font-semibold">
                        <Message message={message} setMessage={setMessage} />
                        <span className="mr-2 flex grow flex-col text-left font-semibold text-red-600">
                          (plase check your email id or password)
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* <SocialSignIn /> */}
        </div>
      </div>
    </div>
  );
}
