import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddEmployeeDocument = async (EmployeeDocument) => {
  log.debug(" hookAddEmployeeDocument : ", EmployeeDocument);

  try {
    const result = await API.graphql({
      query: mutations.createEmployeeDocument,
      variables: {
        input: EmployeeDocument,
      },
    });

    log.debug(" hookAddEmployeeDocument result: ", result);

    return result?.data?.createEmployeeDocument;
  } catch (err) {
    log.debug("CATCH ERROR hookAddEmployeeDocument result: ", err);
    return null;
  }
};

export const hookUpdateEmployeeDocument = async (EmployeeDocument) => {
  log.debug(" hookUpdateEmployeeDocument : ", EmployeeDocument);
  try {
    const result = await API.graphql({
      query: mutations.updateEmployeeDocument,
      variables: {
        input: EmployeeDocument,
      },
    });

    log.debug(" hookUpdateEmployeeDocument result: ", result);
    return result?.data?.updateEmployeeDocument;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateEmployeeDocument result: ", err);
  }
};

export const hookDeleteEmployeeDocumentByEmployeeDocumentId = async (
  EmployeeDocumentId
) => {
  log.debug(
    " hookDeleteEmployeeDocumentByEmployeeDocumentId  ",
    EmployeeDocumentId
  );

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteEmployeeDocument,
      variables: {
        input: { id: EmployeeDocumentId },
      },
    });

    log.debug(" hookDeleteEmployeeDocumentByEmployeeDocumentId : ", data);
    if (data !== null) return data?.data?.deleteEmployeeDocument;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeleteEmployeeDocumentByEmployeeDocumentUniqueID -- ",
      e
    );
    return null;
  }
};

export const hookListEmployeeDocumentsByUserId = async (userID) => {
  log.debug(" hookListEmployeeDocumentsByUserId userID", userID);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.EmployeeDocumentByUserID,
      variables: {
        sortDirection: "DESC",
        userID: userID,
      },
    });

    log.debug(" hookListEmployeeDocumentsByUserId : ", data);

    if (data !== null) return data?.data?.EmployeeDocumentByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeDocumentsByUserId -- ", e);
    return null;
  }
};
