import { useEffect, useState } from "react";
import log from "loglevel";

export default function useForm(initial = {}) {
  // create a state object for our inputs
  const [inputs, setInputs] = useState(initial);
  const initialValues = Object.values(initial).join("");

  useEffect(() => {
    // This function runs when the things we are watching change
    setInputs(initial);
  }, [initialValues]);

  function handleChange(e) {
    let { value, name, type } = e.target;

    log.debug("handleChange : ", type, value, name);

    if (type === "number") {
      log.debug("handleChange number : ", value);
      value = parseInt(value);
      log.debug("handleChange parseInt(value) : ", value);
    }
    if (type === "float") {
      value = parseFloat(value);
    }

    if (type === "file") {
      [value] = e.target.files;
    }

    log.debug("Value", value, typeof value);
    if (type === "checkbox") {
      const prevValue = inputs[name];
      setInputs({
        // set to ! of the existing state for checkbox
        ...inputs,
        [name]: !prevValue,
      });
      log.debug("Log values", name, type, prevValue);
    } else {
      setInputs({
        // copy the existing state
        ...inputs,
        [name]: value,
      });
    }
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key, value]) => [key, ""])
    );
    setInputs(blankState);
  }

  function refresh() {
    setInputs({ ...inputs });
  }
  // return the things we want to surface from this custom hook
  return {
    inputs,
    handleChange,
    resetForm,
    clearForm,
    refresh,
  };
}
