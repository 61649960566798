import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

import { API } from "aws-amplify";
import log from "loglevel";

export const hookListForm16 = async () => {
  log.debug(" hookListForm16 ");

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.listForm16s,
      variables: {},
    });

    log.debug(" hookListForm16 : ", data);

    if (data !== null) return data?.data?.listForm16s.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListForm16 -- ", e);
    return null;
  }
};

export const hookAddForm16 = async (Form16) => {
  log.debug(" hookAddForm16 : ", Form16);

  try {
    const result = await API.graphql({
      query: mutations.createForm16,
      variables: {
        input: Form16,
      },
    });

    log.debug(" hookAddForm16 result: ", result);

    return result?.data?.createForm16;
  } catch (err) {
    log.debug("CATCH ERROR hookAddForm16 result: ", err);
    return null;
  }
};

export const hookUpdateForm16 = async (Form16) => {
  log.debug(" hookUpdateForm16 : ", Form16);
  try {
    const result = await API.graphql({
      query: mutations.updateForm16,
      variables: {
        input: Form16,
      },
    });

    log.debug(" hookUpdateForm16 result: ", result);
    return result?.data?.updateForm16;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateForm16 result: ", err);
  }
};

export const hookDeleteForm16ByForm16Id = async (Form16Id) => {
  log.debug(" hookDeleteForm16ByForm16Id  ", Form16Id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteForm16,
      variables: {
        input: { id: Form16Id },
      },
    });

    log.debug(" hookDeleteForm16ByForm16Id : ", data);
    if (data !== null) return data?.data?.deleteForm16;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteForm16ByForm16UniqueID -- ", e);
    return null;
  }
};

export const hookListForm16ByUserId = async (userID) => {
  log.debug(" hookListForm16ByUserId userID", userID);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.Form16sByUserID,
      variables: {
        sortDirection: "DESC",
        userID: userID,
      },
    });

    log.debug(" hookListForm16ByUserId : ", data);

    if (data !== null) return data?.data?.Form16sByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListForm16ByUserId -- ", e);
    return null;
  }
};

export const hookGetForm16ById = async (form16Id) => {
  log.debug(" hookGetForm16ById ++  id", form16Id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getForm16,
      variables: {
        id: form16Id,
      },
    });

    if (data?.data?.getForm16) {
      log.debug(" hookGetForm16ById : ", data.data.getForm16);
      return data.data.getForm16;
    } else {
      return null;
    }
  } catch (e) {
    console.error("CATCH ERROR hookGetForm16ById -- ", e);
    return null;
  }
};
