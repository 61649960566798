import React, { useState, useEffect } from "react";
import log from "loglevel";
import {
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../hooks/employeeMachineHooks";
import moment from "moment";
import { Button } from "../../CustomComponentsLibrary/Button";
import useForm from "../../../customHooks/useForm";
import { hookListOrgLeavesByYearId } from "../../../hooks/orgLeaveHooks";
import { hookListOrgYearLeavessByOrgId } from "../../../hooks/orgYearLeaveHooks";
import { Card, CardContent } from "../../CustomComponentsLibrary/Card";
import {
  DialogBox,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../../CustomComponentsLibrary/Dailog";

import { ScrollArea } from "../../CustomComponentsLibrary/ScrollArea";
import { Textarea } from "../../CustomComponentsLibrary/Textarea";
import { X, Send, Trash } from "lucide-react";
import { useToast } from "../../CustomComponentsLibrary/UseToast";
import { CONST_LEAVE_STATUS } from "../../../constants/dbconstants";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export function TimeInTimeOut({ showDialog }) {
  const { todayAttendance, organizationID, myEmployeeProfile } =
    useEmployeeContextData();
  log.debug("todayAttendance::TimeInTimeOut", todayAttendance);
  const employeeService = useEmployeeContextService();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [currentComment, setCurrentComment] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isAddDescriptionButtonEnabled, setIsAddDescriptionButtonEnabled] =
    useState(false);
  // Track the current comment
  const { toast } = useToast();
  const { theme } = useAuthContextData();

  const { inputs, handleChange, clearForm, resetForm } = useForm({
    description: "",
  });

  const handleRemoveComment = (index) => {
    const updatedComments = [...comments];
    updatedComments.splice(index, 1);
    setComments(updatedComments);
    if (comments.length <= 1) {
      setIsAddDescriptionButtonEnabled(false);
    }
  };

  const submitTaskDescription = (e) => {
    e.preventDefault();
    if (inputs?.description.trim().length >= 100) {
      setComments([...comments, inputs?.description]);
      clearForm();
      setCurrentComment("");
      setIsAddDescriptionButtonEnabled(true);
    } else {
      toast({
        variant: "destructive",
        title: "Descritpion must have atleast 100 characters",
        description: "Please Update the Description.",
      });
      setIsAddDescriptionButtonEnabled(false);
    }
  };

  const saveDescriptionToDB = () => {
    log.debug("description to add", comments);
    employeeService.send({
      type: "attendance_crud",
      data: {
        description: comments,
      },
    });
    setDialogOpen(false);
    setComments([]);
    showDialog();
  };
  const [refetch, setRefetch] = useState(false);
  const [orgYearHolidays, setOrgYearHolidays] = useState([]);
  const [orgLeaves, setOrgLeaves] = useState([]);
  useEffect(() => {
    async function fetchOrgYearHolidays() {
      const fetchedOrgYearHolidays = await hookListOrgYearLeavessByOrgId(
        organizationID
      );
      if (fetchedOrgYearHolidays) {
        const currentYear = new Date().getFullYear();
        const filteredHolidays = fetchedOrgYearHolidays?.filter((holiday) => {
          const holidayYear = new Date(holiday?.year)?.getFullYear();
          return holidayYear === currentYear;
        });
        setOrgYearHolidays(filteredHolidays);
        if (filteredHolidays) {
          const fetchedOrgLeaves = await hookListOrgLeavesByYearId(
            filteredHolidays[0]?.id
          );
          if (fetchedOrgLeaves) setOrgLeaves(fetchedOrgLeaves);
        }
        log.debug("filteredOrgYearHolidays", filteredHolidays);
      }
    }

    if (organizationID) fetchOrgYearHolidays();

    return () => {
      setOrgYearHolidays([]);
    };
  }, [organizationID, refetch]);

  function refresh() {
    setRefetch(!refetch);
  }

  var holiday = orgLeaves?.filter(
    (i) => i?.date == moment(new Date()).format("YYYY-MM-DD")
  );
  const leaveDay = myEmployeeProfile?.leaves?.items?.filter(
    (i) => i?.date == moment(new Date()).format("YYYY-MM-DD")
  );

  const currentDate = moment(new Date()).format("YYYY-MM-DD");

  const leaveForToday = myEmployeeProfile?.leaves?.items?.find((leave) =>
    leave?.date?.some((date) => date === currentDate)
  );

  log.debug("leaveDay::", leaveDay, leaveForToday);
  useEffect(() => {
    if (
      (leaveForToday &&
        leaveForToday.status !== CONST_LEAVE_STATUS.leaveStatusRejected) ||
      (Array.isArray(holiday) && holiday.length)
    ) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <Card className="w-3/4 border-t border-zinc-100 shadow-md">
          <ScrollArea className="px-2 flex flex-wrap">
            <CardContent className="w-full">
              <ScrollArea orientation="horizontal">
                <div className="flex flex-col rounded-md p-1">
                  <div className="flex sm:flex-row flex-col">
                    <div>
                      {todayAttendance === undefined ||
                      todayAttendance === null ? (
                        <div className="m-5 text-text">
                          <Button
                            variant=""
                            size="sm"
                            className={`"" ${theme?.background} ${theme?.textColor}`}
                            disabled={hasError}
                            onClick={() => {
                              employeeService.send({
                                type: "attendance_crud",
                              });
                              showDialog();
                            }}
                          >
                            <span className="p-2 text-base text-center">
                              {" "}
                              Time In{" "}
                            </span>
                          </Button>
                        </div>
                      ) : null}
                      {todayAttendance !== null && todayAttendance?.timeIn1 ? (
                        <>
                          <div className="h-20 w-32 m-2 border rounded-lg p-4">
                            <div className={`"" ${theme?.textCommon}`}>
                              <span className="text-sm ml-3 mt-4">
                                Time In 1
                              </span>
                              <br />
                              <span className="text-base text-center ml-4 my-2">
                                {moment(
                                  new Date(todayAttendance?.timeIn1)
                                ).format("LT")}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="">
                      {todayAttendance !== undefined &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 === null ? (
                        <div className="m-5 text-text">
                          <Button
                            variant="danger"
                            size="sm"
                            className={`"" ${theme?.textColor} ${theme?.destructive}`}
                            onClick={() => {
                              employeeService.send({
                                type: "attendance_crud",
                              });
                              showDialog();
                            }}
                          >
                            <span className="p-2 text-center"> Time Out 1</span>
                          </Button>
                        </div>
                      ) : null}
                      {todayAttendance !== undefined &&
                      todayAttendance !== null &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 !== null ? (
                        <>
                          <div className="h-20 w-32 p-4 border rounded-lg m-2">
                            <div className={`"" ${theme?.textCommon}`}>
                              <span className="text-sm ml-3 mt-4">
                                Time Out 1
                              </span>
                              <br />
                              <span className="text-base ml-4 my-2">
                                {moment(
                                  new Date(todayAttendance?.TimeOut1)
                                ).format("LT")}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div>
                      {todayAttendance !== undefined &&
                      todayAttendance !== null &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 !== null ? (
                        <>
                          <div className="text-base h-20 sm:w-38 w-fit p-4 m-5 sm:mb-0 mb-5">
                            <TotalHalfDayHours
                              time1={todayAttendance?.timeIn1}
                              time2={todayAttendance?.TimeOut1}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex sm:flex-row flex-col mt-4 sm:mt-0">
                    <div>
                      {todayAttendance !== undefined &&
                      todayAttendance !== null &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 !== null &&
                      todayAttendance?.timeIn2 === null ? (
                        <div className="m-5 text-text">
                          <Button
                            variant=""
                            size="sm"
                            className={`"" ${theme?.background} ${theme?.textColor}`}
                            onClick={() => {
                              employeeService.send({
                                type: "attendance_crud",
                              });
                              showDialog();
                            }}
                          >
                            <span className="p-2 text-center"> Time In 2</span>
                          </Button>
                        </div>
                      ) : null}
                      {todayAttendance !== undefined &&
                      todayAttendance !== null &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 !== null &&
                      todayAttendance?.timeIn2 !== null ? (
                        <>
                          <div className="h-20 w-32 m-2 border rounded-lg p-4">
                            <div className={`"" ${theme?.textCommon}`}>
                              <span className="text-sm ml-3 mt-4">
                                Time In 2
                              </span>
                              <br />
                              <span className="text-base ml-4 my-2">
                                {moment(
                                  new Date(todayAttendance?.timeIn2)
                                ).format("LT")}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div>
                      {todayAttendance !== undefined &&
                      todayAttendance !== null &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 !== null &&
                      todayAttendance?.timeIn2 !== null &&
                      todayAttendance?.TimeOut2 === null ? (
                        <div className="m-5 text-text">
                          <Button
                            size="sm"
                            className={`"" ${theme?.textColor} ${theme?.destructive}`}
                            onClick={() => {
                              setDialogOpen(true);
                              log.debug("setDialogOpen", dialogOpen);
                            }}
                          >
                            <span className="p-2 text-center text-foreground">
                              {" "}
                              Time Out 2
                            </span>
                          </Button>
                        </div>
                      ) : null}
                      {todayAttendance !== undefined &&
                      todayAttendance !== null &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 !== null &&
                      todayAttendance?.timeIn2 !== null &&
                      todayAttendance?.TimeOut2 !== null ? (
                        <>
                          <div className="h-20 w-32 m-2 border rounded-lg p-4">
                            <div className={`"" ${theme?.textCommon}`}>
                              <span className="text-sm ml-3 mt-4">
                                Time Out 2
                              </span>
                              <br />
                              <span className="text-base ml-4 mt-4">
                                {moment(
                                  new Date(todayAttendance?.TimeOut2)
                                ).format("LT")}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className=" ">
                      {todayAttendance !== undefined &&
                      todayAttendance !== null &&
                      todayAttendance?.timeIn1 !== null &&
                      todayAttendance?.TimeOut1 !== null &&
                      todayAttendance?.timeIn2 !== null &&
                      todayAttendance?.TimeOut2 !== null ? (
                        <>
                          <div className="h-20 w-38 mt-2 ml-2 p-4 m-5">
                            <TotalHalfDayHours
                              time1={todayAttendance?.timeIn2}
                              time2={todayAttendance?.TimeOut2}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    {todayAttendance !== undefined &&
                    todayAttendance !== null &&
                    todayAttendance?.timeIn1 !== null &&
                    todayAttendance?.TimeOut1 !== null &&
                    todayAttendance?.timeIn2 !== null &&
                    todayAttendance?.TimeOut2 !== null ? (
                      <>
                        <div className="mx-4 mt-4 ">
                          <TotalHours hours={todayAttendance?.totalHours} />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </ScrollArea>
            </CardContent>
          </ScrollArea>
        </Card>
      </div>

      <>
        <DialogBox open={dialogOpen} onChange={setDialogOpen}>
          <DialogContent className="sm:max-w-[50%]">
            <Button
              size="xs"
              variant="darkOutline"
              className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
              onClick={() => {
                setDialogOpen(false);
                setComments([]);
                employeeService.send({ type: "cancel_crud" });
              }}
            >
              <X className="h-4 w-4 text-" />
              <span className="sr-only">Close</span>
            </Button>

            <ScrollArea className="lg:h-[500px] xl:h-[630px] md:h-96">
              <DialogHeader>
                <DialogTitle></DialogTitle>
                <DialogDescription>
                  Add the description before Doing Time Out2 and description
                  should be more descriptive
                </DialogDescription>
              </DialogHeader>

              <div className="flex flex-col p-1">
                <div className="flex-row w-full">
                  <div className="flex rounded-md p-1">
                    <div className="flex-row w-full">
                      <div>
                        <p>Description: {currentComment}</p>
                        <ScrollArea>
                          {comments?.map((comment, index) => (
                            <div key={index}>
                              <p>{comment}</p>
                              <Button
                                variant="danger"
                                className="mt-2"
                                size="xs"
                                onClick={() => handleRemoveComment(index)}
                              >
                                <Trash className="w-5 h-5" />
                              </Button>
                            </div>
                          ))}
                        </ScrollArea>
                      </div>
                    </div>
                  </div>
                  <div>
                    <form onSubmit={submitTaskDescription} method="POST">
                      <div className="mt-2 p-2">
                        <Textarea
                          required
                          type="text"
                          onChange={handleChange}
                          name="description"
                          id="comments"
                          value={inputs?.description}
                          placeholder="Enter Your Description here..."
                          className={`"p-2.5" ${theme?.border}`}
                        />
                        <Button
                          size="sm"
                          type="submit"
                          variant="darkOutline"
                          className="relative float-right  bottom-12 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                        >
                          <Send className="h-4 w-4" />
                          <span className="sr-only">Send</span>
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <DialogFooter className="pt-2">
                <Button
                  type="button"
                  variant=""
                  className={`${theme?.hoverbg} ${theme?.hoverText} ${theme?.border}`}
                  onClick={() => {
                    setDialogOpen(false);
                    setComments([]);
                    employeeService.send({ type: "cancel_crud" });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className={`${theme?.hoverbg} ${theme?.hoverText} ${theme?.border}`}
                  variant=""
                  onClick={() => {
                    saveDescriptionToDB();
                  }}
                  disabled={!isAddDescriptionButtonEnabled}
                >
                  Add
                </Button>
              </DialogFooter>
            </ScrollArea>
          </DialogContent>
        </DialogBox>
      </>
    </div>
  );
}

function TotalHalfDayHours({ time2, time1 }) {
  const { todayAttendance } = useEmployeeContextData();
  const diffMilliseconds1 = new Date(time2) - new Date(time1);
  let diffHours1 = diffMilliseconds1 / (1000 * 60 * 60);

  let hour = Math.floor(diffHours1);
  // Check sign of given number

  let sign = diffHours1 >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  diffHours1 = diffHours1 * sign;
  log.debug(":totalHours==>", diffHours1);
  let decpart = diffHours1 - hour;
  log.debug(":totalHours::decpart==>", diffHours1);
  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);
  //log.debug('totalHours:::decpart==>', decpart);
  let minute = Math.floor(decpart * 60) + "";
  log.debug("totalHours:::decpart:minute==>", minute);
  // Add padding if need
  if (minute.length < 2) {
    minute = "0" + minute;
  }

  // Add Sign in final result
  sign = sign == 1 ? "" : "-";

  // const time = sign + hour + 'hr' + minute + 'min';
  const time = sign + hour + " h " + minute + " min";
  const { theme } = useAuthContextData();
  return (
    <div className={`"" ${theme?.textCommon}`}>
      <span className="text-sm text-center p-2">
        {time2 === todayAttendance?.TimeOut1 ? "1st" : "2nd"} Section Hours:
      </span>
      <span className="text-base text-center ml-4">{time}</span>
    </div>
  );
}

function TotalHours({ hours }) {
  var hour = Math.floor(hours);
  // Check sign of given number

  var sign = hours >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  hours = hours * sign;
  log.debug(":totalHours==>", hours);
  var decpart = hours - hour;
  log.debug(":totalHours::decpart==>", hours);
  var min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);
  log.debug("totalHours:::decpart==>", decpart);
  var minute = Math.floor(decpart * 60) + "";
  log.debug("totalHours:::decpart:minute==>", minute);
  // Add padding if need
  if (minute.length < 2) {
    minute = "0" + minute;
  }

  // Add Sign in final result
  sign = sign == 1 ? "" : "-";

  // const time = sign + hour + 'hr' + minute + 'min';
  const time = sign + hour + " h " + minute + " min";
  const { theme } = useAuthContextData();
  return (
    <div className={`"" ${theme?.textCommon}`}>
      <span className="text-base text-center my-2">Todays Hours : {time}</span>
    </div>
  );
}
