import React from "react";
import log from "loglevel";
//import AsyncStorage from '@react-native-async-storage/async-storage'

export const hookStoreData = async (key, value) => {
  try {
    log.debug("hookStoreData key", key);
    log.debug("hookStoreData value", value);
    //await AsyncStorage.setItem(key, value);
    if (typeof window !== "undefined") {
      return localStorage.setItem(key, value);
    }
  } catch (error) {
    // Error saving data
  }
};

export const hookRetrieveData = async (key) => {
  try {
    //const value = await AsyncStorage.getItem(key);
    var value = null;
    if (typeof window !== "undefined") {
      value = localStorage.getItem(key);
    }

    if (value !== null) {
      log.debug("hookRetrieveData key", key);
      log.debug("hookRetrieveData value", value);
    }
    return value;
  } catch (error) {
    // Error retrieving data
    return null;
  }
};

export const hookClearData = async (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // Error retrieving data
    log.debug(error);
  }
};
