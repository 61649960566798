import React from "react";
import { TabPageLink } from "../../../components/common/TabPageLink";

const tabs = [
  {
    name: "Employee Handbook",
    path: "/employee/policy",
  },

  {
    name: "Leaves Policy",
    path: "/employee/policy/leavespolicy",
  },

];

export function PolicyLayout() {
  console.log("RoutestoGen", tabs);
  return (
    <>
      <TabPageLink tabs={tabs} />
    </>
  );
}
