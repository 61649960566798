import React from "react";
import image from "../../../src/images/buisness2.jpg";
import { TypographyMuted } from "../CustomComponentsLibrary/Typography";
import { Toaster } from "../CustomComponentsLibrary/Toaster";
const { version } = require("../../../package.json");

export default function AuthCommonLayout({ children }) {
  return (
    <>
      <div>
        <div className="container relative md:flex-row  h-screen flex-col  items-center justify-center md:grid md:max-w-none md:grid-cols-2 md:px-0">
          <div className="relative hidden md:flex h-screen   bg-common flex-col   text-white dark:border-r">
            <div
              className="relative h-screen opacity-50 bg-common flex-col p-10 text-white dark:border-r md:flex"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="absolute inset-0 " />

              <div className="relative z-20 flex items-center text-xl font-medium">
                <img
                  className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] py-10 dark:invert"
                  src={require("../../images/delight360logo192.png")}
                  alt="Delight360.biz Logo"
                  width={100}
                  height={37}
                  priority
                />{" "}
                <div className="text-4xl ml-2">Delight360</div>
              </div>
              <div className="relative z-20 mt-auto">
                <blockquote className="space-y-2">
                  <p className="text-lg">
                    &ldquo;Streamline project management and task management for
                    all your projects, ensuring efficiency.&rdquo;
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="grid h-screen place-items-center  lg:p-8 sm:w-full">
            {children}
            <div className="flex justify-end absolute bottom-0 right-0 rounded-md">
              <TypographyMuted>
                Copyright &copy; {new Date().getFullYear()} Delighteck. All
                rights reserved.{"  "} Version : {version}
              </TypographyMuted>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
}
