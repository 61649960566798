import React from "react";
import log from "loglevel";
import { useAuthService } from "../../customHooks/authMachineHooks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function ContactAdmin() {
  const authService = useAuthService();
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-md w-full bg-white shadow-md p-8 rounded-md">
        <h2 className="text-2xl font-bold mb-4">Waiting for Approval</h2>
        <p className="text-gray-700 mb-4">
          Your request has been submitted and is currently awaiting approval.
          Please wait for the admin to review your request.
        </p>
        <div className="flex justify-center">
          <div className="inline-flex rounded-full border-4 border-gray-200">
            <div className="h-12 w-12 m-2 border-t-4 border-blue-500 rounded-full animate-spin"></div>
          </div>
        </div>
        <p className="text-gray-600 mt-4">Please contact Admin.</p>

        <div className="flex w-full justify-around mt-6">
          <button
            onClick={() => authService.send({ type: "logout" })}
            className={classNames(
              "place-self-end block px-4 py-2 text-sm text-gray-700 bg-red-500 rounded-lg hover:cursor-pointer"
            )}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
