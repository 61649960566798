import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API, Auth } from "aws-amplify";
import log from "loglevel";

export const hookAddUser = async (userToAdd) => {
  log.debug(" hookAddUser : ", userToAdd);

  //Connect Client Amplify GraphQL
  //const result = await API.graphql(mutations.createUser, { input: User });
  try {
    const result = await API.graphql({
      query: mutations.createUser,
      variables: {
        input: userToAdd,
      },
    });
    log.debug(" hookAddUser result: ", result);
    return result?.data?.createUser;
  } catch (err) {
    log.debug("CATCH ERROR hookAddUser result: ", err);
    return null;
  }
  //Destructuring Assignment in ES6
  //setTodos([...todos, result.data.createTodo]);
};

export const hookGetUserByCognitoId = async (cognitoId) => {
  const UserObj = {
    cognitoId: cognitoId,
  };

  log.debug(" hookGetUserByCognitoId ++ ");

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: queries.getUser,
      variables: {
        cognitoId: cognitoId,
      },
    });

    log.debug(" hookGetUserByCognitoId : ", result);
    return result?.data?.getUser;
  } catch (e) {
    console.error("CATCH ERROR hookGetUserByCognitoId -- ", e);

    return null;
  }
};

export const hookUpdateUser = async (User) => {
  log.debug(" hookUpdateUser : ", User);
  try {
    const result = await API.graphql({
      query: mutations.updateUser,
      variables: {
        input: User,
      },
    });

    log.debug(" hookUpdateUser result: ", result);
    return result?.data?.updateUser;
  } catch (err) {
    log.debug("CATCH ERROR hookAddUser result: ", err);
  }
};

export const hookDeleteUser = async (cognitoId) => {
  log.debug(" hookDeleteUser : ", cognitoId);
  try {
    const result = await API.graphql({
      query: mutations.deleteUser,
      variables: {
        input: { cognitoId: cognitoId },
      },
    });

    log.debug(" hookDeleteUser result: ", result);
    return result;
  } catch (err) {
    log.debug("CATCH ERROR hookDeleteUser result: ", err);
  }
};

export const hookListUsers = async () => {
  //Connect Client Amplify GraphQL
  log.debug(" Calling hookListUsers : ");

  try {
    const result = await API.graphql({
      query: queries.listUsers,
      variables: {},
    });

    log.debug(" hookListUsers : ", result);

    if (result !== null) return result?.data?.listUsers;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookListUsers ", err);
  }
};

export const hookListUsersByType = async (restaurantUniqueName) => {
  //Connect Client Amplify GraphQL

  try {
    const result = await API.graphql({
      query: queries.listUsers,
      variables: {
        activeRestaurant: restaurantUniqueName,
      },
    });

    log.debug(" hookListUsers : ", result);

    if (result !== null) return result?.data?.listUsers;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookListUsers ", err);
  }
};

export const hookGetUserById = async (cognitoId) => {
  const postObj = {
    cognitoId: cognitoId,
  };

  log.debug(" hookGetUserById ++  id", cognitoId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getUser,
      variables: {
        cognitoId,
      },
    });

    if (data?.data?.getUser) {
      log.debug(" hookGetUserById : ", data.data.getUser);
      return data.data.getUser;
    } else {
      return null;
    }
  } catch (e) {
    console.error("CATCH ERROR hookGetUserById -- ", e);
    return null;
  }
};

export const hookListCognitoUsers = async (filter) => {
  let apiName = "AdminQueries";
  let path = "/ListUsers";
  let listUsersForGroup = "/listGroupsForUser";

  let authHeader = {
    "Content-Type": "application/json",
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };

  let myInit = {
    queryStringParameters: {
      limit: 30,
    },
    headers: authHeader,
  };
  try {
    let response = await API.get(apiName, path, myInit);
    const allusers = [];
    log.debug("list users : ", response);
    for (let i = 0; i < response.Users.length; i++) {
      let phone_number = "";
      let address = "";
      let name = "";
      let Username = "";
      let cognitoId = "";
      let UserCreateDate = "";
      let email = "";
      Username = response.Users[i].Username;
      UserCreateDate = response.Users[i].UserCreateDate;
      for (let j = 0; j < response.Users[i].Attributes.length; j++) {
        if (response.Users[i].Attributes[j].Name === "sub") {
          cognitoId = response.Users[i].Attributes[j].Value;
        }
        if (response.Users[i].Attributes[j].Name === "address") {
          address = response.Users[i].Attributes[j].Value;
        }
        if (response.Users[i].Attributes[j].Name === "name") {
          name = response.Users[i].Attributes[j].Value;
        }
        if (response.Users[i].Attributes[j].Name === "email") {
          email = response.Users[i].Attributes[j].Value;
        }
        if (response.Users[i].Attributes[j].Name === "phone_number") {
          phone_number = response.Users[i].Attributes[j].Value;
        }
      }

      let myInit2 = {
        queryStringParameters: {
          username: Username,
        },
        headers: authHeader,
      };
      let groupResponse = await API.get(apiName, listUsersForGroup, myInit2);

      let UserGroup = [];
      let UserOrMenteeFound = false;
      for (let n_i = 0; n_i < groupResponse.Groups.length; n_i++) {
        if (
          groupResponse.Groups[n_i].GroupName === "User" ||
          groupResponse.Groups[n_i].GroupName === "mentee"
        ) {
          UserOrMenteeFound = true;
        }
        if (UserOrMenteeFound === false) {
          UserGroup.push(groupResponse.Groups[n_i].GroupName);
        }
      }
      log.debug("groupResponse : ", groupResponse);

      if (UserOrMenteeFound === false) {
        allusers.push({
          cognitoId: cognitoId,
          Enabled: response.Users[i].Enabled,
          Username: Username,
          email: email,
          firstName: name,
          address: address,
          phone_number: phone_number,
          group: UserGroup,
        });
      }
    }
    log.debug("Return list users : ", allusers);

    return allusers;
  } catch (error) {
    log.debug("ERROR", error);
    return error;
  }
};

export const hookListUsersByOrgnizationId = async (organizationID) => {
  try {
    const data = await API.graphql({
      query: queries.UsersByOrganizationID,
      variables: { organizationID: organizationID },
    });

    log.debug(" hookListUsersByOrgnizationId : ", data);

    if (data !== null) return data?.data?.UsersByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListUsersByOrgnizationId -- ", e);
    return null;
  }
};
