import { useEffect, useState } from "react";
import log from "loglevel";

import {
  CONST_ORG_PAYMENT_STATUS,
  CONST_ORG_PLAN_STATUS,
  CONST_USER_TYPES,
} from "../../constants/dbconstants";
import {
  hookGetOrgPaymentById,
  hookUpdateOrgPayment,
} from "../../hooks/orgPaymentHooks";
import { hookUpdateOrganization } from "../../hooks/organizationHooks";
import { hookUpdateUser } from "../../hooks/userHooks";
import { useActor } from "@xstate/react";
import {
  useAuthContextData,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import { useNavigate } from "react-router";
import { hookUpdateEmployee } from "../../hooks/employeeHooks";

export default function Success() {
  const [payment, setPayment] = useState(null);
  const orgPaymentId = window.location.pathname.split("/").pop();
  const authService = useAuthService();
  const { userProfile, organizationID, employeeProfile } = useAuthContextData();
  const [state, send] = useActor(authService);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const payment = await hookGetOrgPaymentById(orgPaymentId);
        if (organizationID === payment?.organizationID) {
          let userTypeToUpdate = userProfile.userType;
          if (!userTypeToUpdate.includes(CONST_USER_TYPES.hrAdmin)) {
            userTypeToUpdate = [...userTypeToUpdate, CONST_USER_TYPES.hrAdmin];
          }
          const userToUpdate = {
            cognitoId: userProfile.cognitoId,
            userType: userTypeToUpdate,
          };
          const updateOrgPayment = await hookUpdateOrgPayment({
            id: orgPaymentId,
            status: CONST_ORG_PAYMENT_STATUS.paymentReceivedVerified,
          });
          const updateUser = await hookUpdateUser(userToUpdate);
          const updateOrg = await hookUpdateOrganization({
            id: organizationID,
            //TODO:Remove once DB changes are there for orgPlantStatus
            activePlan: CONST_ORG_PLAN_STATUS.regularPlan,
          });

          if (employeeProfile) {
            if (!employeeProfile.userType.includes(CONST_USER_TYPES.hrAdmin)) {
              const updatedEmp = await hookUpdateEmployee({
                cognitoId: employeeProfile.cognitoId,
                userType: userTypeToUpdate,
              });
              send({
                type: "update_user_org_payment",
                data: {
                  userProfile: updateUser,
                  organization: updateOrg,
                  employeeProfile: updatedEmp,
                },
              });
            }
            send({
              type: "update_user_org_payment",
              data: {
                userProfile: updateUser,
                organization: updateOrg,
              },
            });
          } else {
            send({
              type: "update_user_org_payment",
              data: {
                userProfile: updateUser,
                organization: updateOrg,
              },
            });
          }
          // setPayment(updateOrgPayment);
        }
      } catch (error) {
        console.error("Error fetching payment:", error);
      }
      // navigate("/");
    };

    if (
      state.matches({
        LOGGED_IN: { USER_PROFILE_PRESENT: "PLAN_DO_NOT_EXIST" },
      }) ||
      state.matches({
        LOGGED_IN: { USER_PROFILE_PRESENT: "ORG_PLAN_REGULAR_EXPIRED" },
      }) ||
      state.matches({
        LOGGED_IN: { USER_PROFILE_PRESENT: "ORG_PLAN_TRIAL_EXPIRED" },
      })
    ) {
      log.debug("USERPROFIEL", userProfile, state, employeeProfile);
      fetchPayment();
    }
  }, [orgPaymentId, state]);
  useEffect(() => {
    let subscription = authService.subscribe((state) => {
      log.debug("STATE CHANGED++++++++++=>", state);
      if (
        state.matches({
          LOGGED_IN: { USER_PROFILE_PRESENT: "APP_READY_TO_START" },
        }) ||
        state.matches({
          LOGGED_IN: {
            USER_PROFILE_PRESENT: "HRADMIN_CREATE_EMPLOYEE_PROFILE",
          },
        })
      ) {
        navigate("/");
      }
    });

    return () => {
      if (subscription) {
        log.debug("Returnining Unsubscribe: ");
        subscription.unsubscribe();
      }
    };
  }, [state?.value]);
  if (!payment) {
    return <div>Confirming Payment</div>;
  }

  return (
    <div>
      <h1>Payment Details</h1>
      <p>Payment ID: {payment.id}</p>
      <p>Amount: {payment.amount}</p>
      <p>Status: {payment.status}</p>
      {/* Render other payment details as needed */}
    </div>
  );
}
