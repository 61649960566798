import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddTaskHours = async (TaskHours) => {
  log.debug(" hookAddTaskHours : ", TaskHours);

  try {
    const result = await API.graphql({
      query: mutations.createEmployeeTaskHours,
      variables: {
        input: TaskHours,
      },
    });

    log.debug(" hookAddTaskHours result: ", result);

    return result?.data?.createEmployeeTaskHours;
  } catch (err) {
    log.debug("CATCH ERROR hookAddTaskHours result: ", err);
    return null;
  }
};

export const hookUpdateTaskHours = async (TaskHour) => {
  log.debug(" hookUpdateTaskHours : ", TaskHour);
  try {
    const result = await API.graphql({
      query: mutations.updateEmployeeTaskHours,
      variables: {
        input: TaskHour,
        ID: TaskHour?.id,
      },
    });

    log.debug(" hookUpdateTaskHours result: ", result);
    return result?.data?.updateEmployeeTaskHours;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateTaskHours result: ", err);
  }
};

export const hookDeleteTaskHoursByTaskHoursId = async (id) => {
  log.debug(" hookDeleteTaskHoursByTaskHoursId  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteEmployeeTaskHours,
      variables: {
        input: { id: id },
      },
    });

    log.debug(" hookDeleteTaskHoursByTaskHoursId : ", data);
    if (data !== null) return data?.data?.deleteEmployeeTaskHours;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteTaskByTaskUniqueID -- ", e);
    return null;
  }
};

export const hooklistTaskHours = async () => {
  try {
    const data = await API.graphql({
      query: queries.listTaskHours,
      variables: {},
    });

    log.debug(" hooklistTaskHoursByUserId : ", data);

    if (data !== null) return data?.data?.listTaskHours.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hooklistTaskHourssByUserId -- ", e);
    return null;
  }
};

export const hookGetTaskHoursByTaskId = async (TaskId) => {
  log.debug(" hookGetTaskHoursByTaskId  ", TaskId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.EmployeeTasksHoursByEmployeeTaskID,
      variables: {
        taskID: TaskId,
      },
    });

    log.debug(" hookGetTaskHoursByTaskId : ", data);
    if (data !== null)
      return data?.data?.EmployeeTasksHoursByEmployeeTaskID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskHoursByTaskId -- ", e);
    return null;
  }
};
export const hookGetTaskHoursByMonthAndTaskId = async (
  TaskId,
  lastDay,
  firstDay
) => {
  log.debug(" hookGetTaskHoursByMonthAndTaskId  ", TaskId, lastDay, firstDay);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.EmployeeTasksHoursByEmployeeTaskID,
      variables: {
        taskID: TaskId,
        date: { between: [firstDay, lastDay] },
      },
    });

    log.debug(" hookGetTaskHoursByMonthAndTaskId : ", data);
    if (data !== null)
      return data?.data?.EmployeeTasksHoursByEmployeeTaskID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskHoursByMonthAndTaskId -- ", e);
    return null;
  }
};
export const hookGetTaskHoursByUserId = async (userID) => {
  log.debug(" hookGetTaskHoursByUserId  ", userID);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.EmployeeTasksHoursByUserID,
      variables: {
        userID: userID,
      },
    });

    log.debug(" hookGetTaskHoursByUserId : ", data);
    if (data !== null) return data?.data?.EmployeeTasksHoursByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskHoursByUserId -- ", e);
    return null;
  }
};
export const hookGetTaskHoursByMonthAndUserId = async (taskHourByMonth) => {
  log.debug(" hookGetTaskHoursByUserId  ", taskHourByMonth);
  const startDate = taskHourByMonth?.firstDay;
  const endDate = taskHourByMonth?.lastDay;
  const userID = taskHourByMonth?.userID;
  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.EmployeeTasksHoursByUserID,
      variables: {
        userID: userID,
        date: { between: [startDate, endDate] },
      },
    });

    log.debug(" hookGetTaskHoursByUserId : ", data);
    if (data !== null) return data?.data?.EmployeeTasksHoursByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskHoursByUserId -- ", e);
    return null;
  }
};
export const hookGetTaskHoursByProjectID = async (projectID) => {
  log.debug(" hookGetTaskHoursByProjectID  ", projectID);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.EmployeeTasksHoursByProjectID,
      variables: {
        projectID: projectID,
      },
    });

    log.debug(" hookGetTaskHoursByProjectID : ", data);
    if (data !== null) return data?.data?.EmployeeTasksHoursByProjectID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskHoursByProjectID -- ", e);
    return null;
  }
};

export const hookGetTaskHoursByMonthAndProjectID = async (taskHourByMonth) => {
  log.debug(" hookGetTaskHoursByProjectID  ", taskHourByMonth);
  const startDate = taskHourByMonth?.firstDay;
  const endDate = taskHourByMonth?.lastDay;
  const projectID = taskHourByMonth?.selectedProjectId;
  try {
    //Connect Client Amplify GraphQL employeeTasksHoursByProjectID
    const data = await API.graphql({
      query: queries.EmployeeTasksHoursByProjectID,
      variables: {
        projectID: projectID,
        date: { between: [startDate, endDate] },
      },
    });

    log.debug(" hookGetTaskHoursByProjectID", data);
    if (data !== null) return data?.data?.EmployeeTasksHoursByProjectID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskHoursByProjectID -- ", e);
    return null;
  }
};

export const hookGetTaskHoursByTaskIdAndDates = async (
  TaskId,
  sDate,
  eDate
) => {
  log.debug(" hookGetTaskHoursByTaskIdAndDates  ", TaskId, sDate, eDate);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.EmployeeTasksHoursByEmployeeTaskID,
      variables: {
        taskID: TaskId,
        date: { between: [sDate, eDate] },
      },
    });

    log.debug(" hookGetTaskHoursByTaskIdAndDates : ", data);
    if (data !== null)
      return data?.data?.EmployeeTasksHoursByEmployeeTaskID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskHoursByTaskIdAndDates -- ", e);
    return null;
  }
};
