import React from "react";
import log from "loglevel";
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Storage } from "aws-amplify";
import {
  useEmployeeContextState,
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../../hooks/employeeMachineHooks";
import useForm from "../../../../customHooks/useForm";
import { CONST_EXPENCES_STATUS } from "../../../../constants/dbconstants";
import {
  DialogBox,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../CustomComponentsLibrary/Dailog";
import { Input } from "../../../CustomComponentsLibrary/Input";
import { Button } from "../../../CustomComponentsLibrary/Button";
import { Label } from "../../../CustomComponentsLibrary/Label";
import { Textarea } from "../../../CustomComponentsLibrary/Textarea";
import { ScrollArea } from "../../../CustomComponentsLibrary/ScrollArea";
import { X } from "lucide-react";
import { useToast } from "../../../CustomComponentsLibrary/UseToast";
import { ImageSelectViewUpate } from "../../../common/ImageSelectViewUpate";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
export default function AddEditDeleteReimbursement() {
  const { reimbursementCRUD } = useEmployeeContextState();
  const employeeMachineService = useEmployeeContextService();
  const [selectedFile, setSelectedFile] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen] = useState(reimbursementCRUD === true ? true : false);
  const handleClose = () => {
    employeeMachineService.send({ type: "cancel_crud" });
  };

  function openSuccessPopup() {
    setSuccessPopup(true);
    setIsOpen(true);
  }

  log.debug("reimbursementCRUD", reimbursementCRUD);
  const {
    actionForReimbursementCRUD,
    selectedReimbursement,
    userID,
    organizationID,
  } = useEmployeeContextData();
  log.debug("actionForReimbursementCRUD", actionForReimbursementCRUD);

  const { inputs, handleChange } = useForm({
    title: selectedReimbursement ? selectedReimbursement.title : "",
    amount: selectedReimbursement ? selectedReimbursement.amount : "",
    content: selectedReimbursement ? selectedReimbursement.content : "",
    date: selectedReimbursement ? selectedReimbursement.date : "",
    dueDate: selectedReimbursement ? selectedReimbursement.dueDate : "",
    status: selectedReimbursement ? selectedReimbursement.status : "",
    s3key: selectedReimbursement ? selectedReimbursement.s3key : "",
  });
  const { theme } = useAuthContextData();
  const [s3key, sets3key] = useState(
    selectedReimbursement?.s3key
      ? { type: "uploadedFile", file: selectedReimbursement?.s3key }
      : { type: "", file: null }
  );
  const { toast } = useToast();

  const handleUploadSave = async ({ selectedFile, key }) => {
    log.debug("Selected File", selectedFile);
    if (selectedFile) {
      let filename = inputs[key];
      if (selectedFile?.type === "selectedFile") {
        try {
          await Storage.put(
            filename,
            selectedFile?.file,
            { level: "public" },
            {
              progressCallback(progress) {
                log.debug(`Uploaded: ${progress.loaded}/${progress.total}`);
              },
            }
          );

          log.debug(" : after uploading pdf ", inputs, filename);
        } catch (error) {
          log.debug("Error uploading file: ", error);
        }
      } else {
        log.debug("no change to file");
      }
    } else {
      log.debug("----------------------->");
    }
  };

  const handleFileChange = (event) => {
    log.debug("event", event, event.target.name, event.target.files[0]);
    switch (event.target.name) {
      case "s3key":
        sets3key({
          type: "selectedFile",
          file: event.target.files[0],
        });
        break;
    }
    handleResourceKeyName({
      field: event.target.name,
      value: event.target.files[0].name,
    });
  };

  const handleResourceKeyName = ({ field, value }) => {
    if (value?.length) {
      const valueFiltered = value.replaceAll(" ", "_");
      inputs[field] = `${organizationID}_${valueFiltered}`;
    } else {
      inputs[field] = "";
    }
  };

  const expencesStatus = [
    { name: CONST_EXPENCES_STATUS.expencseStatusCreated },
    { name: CONST_EXPENCES_STATUS.expencseStatusInprocess },
    { name: CONST_EXPENCES_STATUS.expencseStatusOnhold },
    { name: CONST_EXPENCES_STATUS.expencseStatusRejected },
    { name: CONST_EXPENCES_STATUS.expencseStatusApproved },
    { name: CONST_EXPENCES_STATUS.expencseStatusPaymentDone },
  ];
  const [reimbursementStatus2, setReimbursementStatus2] = useState(
    expencesStatus.find(
      (element) => element.name === selectedReimbursement?.status
    ) || null
  );
  useEffect(() => {
    if (reimbursementCRUD) {
      if (actionForReimbursementCRUD === "EDIT") {
      }
    }
  }, []);

  const submitReimbursementNow = async (e) => {
    e.preventDefault();
    log.debug(
      "submitReimbursementNow onclickAdd b4 selectedReimbursement",
      inputs
    );
    if (selectedFile) {
      await handleUploadSave({ selectedFile: s3key, key: "s3key" });
    } else {
      toast({
        title: "Please select a receipt to upload.",
        description: "",
      });
    }
    if (selectedReimbursement) {
      log.debug("submitReimbursementNow onclickUpdate", inputs);

      const reimbursement2Add = {
        ...inputs,
        userID: userID,
        id: selectedReimbursement?.id,
        status: reimbursementStatus2?.name,
      };
      log.debug(
        "submitReimbursementDataNow reimbursement2Add",
        reimbursement2Add
      );

      employeeMachineService.send({
        type: "update_reimbursement",
        data: {
          reimbursementsToAdd: reimbursement2Add,
        },
      });
    } else {
      log.debug("submitReimbursementNow onclickAdd", inputs);

      employeeMachineService.send({
        type: "create_reimbursement",
        data: {
          reimbursementsToAdd: {
            ...inputs,
            status: CONST_EXPENCES_STATUS.expencseStatusCreated,
            userID: userID,
          },
        },
      });
    }
  };
  const today = new Date().toISOString().split("T")[0]; // get current date in yyyy-mm-dd format

  if (reimbursementCRUD) {
    switch (actionForReimbursementCRUD) {
      case "DELETE":
        return (
          <Transition.Root show={reimbursementCRUD} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                employeeMachineService.send({ type: "cancel_crud" });
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <button
                          type="submit"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          <span className="sr-only">Close</span>
                          <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationCircleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Delete Reimbursement
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete the Reimbursement?
                              All of your data will be permanently removed from
                              our servers forever. This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            employeeMachineService.send({
                              type: "delete_reimbursement",
                            });
                          }}
                        >
                          Confirm Delete
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        );

      case "EDIT":

      case "ADD":
        return (
          <>
            <DialogBox open={dialogOpen} onOpenChange={handleClose}>
              <DialogContent className="sm:max-w-[900px]">
                <Button
                  size="xs"
                  variant="darkOutline"
                  className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                  onClick={() => {
                    employeeMachineService.send({ type: "cancel_crud" });
                  }}
                >
                  <X className="h-4 w-4 text-" />
                  <span className="sr-only">Close</span>
                </Button>
                <DialogHeader>
                  <DialogTitle>
                    {selectedReimbursement
                      ? "Edit Reimbursement"
                      : "Add Reimbursement"}
                  </DialogTitle>
                  <DialogDescription>
                    Add your reimbursement here. Click save when you're done.
                  </DialogDescription>
                </DialogHeader>
                <ScrollArea className="h-[500px]">
                  <form onSubmit={submitReimbursementNow}>
                    <div className="flex flex-col">
                      <div className="flex-row w-full">
                        <Label
                          htmlFor="Title"
                          className="text-right font-semibold"
                        >
                          Title
                        </Label>
                        <Input
                          required
                          defaultValue={inputs?.title}
                          type="text"
                          onChange={handleChange}
                          name="title"
                          id="title"
                          placeholder="Add reimbursement title here"
                          className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        />
                      </div>
                      <div className=" flex-row w-full mt-2">
                        <Label
                          htmlFor="content"
                          className="text-right font-semibold"
                        >
                          Description
                        </Label>

                        <Textarea
                          required
                          defaultValue={inputs?.content}
                          type="text"
                          onChange={handleChange}
                          name="content"
                          id="content"
                          placeholder="Add reimbursement description here"
                          className={`${theme?.border}`}
                        />
                      </div>
                      <div className=" flex-row w-full mt-2">
                        <Label
                          htmlFor="content"
                          className="text-right font-semibold"
                        >
                          Amount
                        </Label>

                        <Input
                          required
                          defaultValue={inputs?.amount}
                          type="text"
                          onChange={handleChange}
                          name="amount"
                          id="amount"
                          placeholder="Add amount"
                          min={0}
                          className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        />
                      </div>
                      <div className=" flex-row w-full mt-2">
                        <Label
                          htmlFor="content"
                          className="text-right font-semibold"
                        >
                          Date
                        </Label>

                        <Input
                          required
                          defaultValue={inputs?.date}
                          type="date"
                          onChange={handleChange}
                          name="date"
                          id="date"
                          max={today}
                          className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        />
                      </div>
                      <div className=" flex-row w-full mt-2">
                        <Label
                          htmlFor="content"
                          className="text-right font-semibold"
                        >
                          Due Date
                        </Label>

                        <Input
                          required
                          defaultValue={inputs?.dueDate}
                          type="date"
                          onChange={handleChange}
                          name="dueDate"
                          id="dueDate"
                          min={today}
                          className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        />
                      </div>

                      <>
                        <div className="w-full felx-col float-right mt-2">
                          <div className=" flex-row w-full mt-3">
                            <div className="flex-col">
                              <ImageSelectViewUpate
                                title={"Receipt"}
                                setSelectedFile={sets3key}
                                id={"s3key"}
                                selectedFile={s3key}
                                handleUploadFile={handleFileChange}
                                handleResourceKeyName={handleResourceKeyName}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    </div>

                    <DialogFooter className="pt-2">
                      <Button
                        type="button"
                        variant=""
                        onClick={() =>
                          employeeMachineService.send({
                            type: "cancel_crud",
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button type="submit">Save</Button>
                    </DialogFooter>
                  </form>
                </ScrollArea>
              </DialogContent>
            </DialogBox>
          </>
        );
    }
  }
}
