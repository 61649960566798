import React from "react";

const FileViewer = ({ selectedFile }) => {
  const fileType = selectedFile.type.split("/")[0];

  if (fileType === "image") {
    return <img src={URL.createObjectURL(selectedFile)} alt="Selected File" />;
  } else if (fileType === "video") {
    return <video src={URL.createObjectURL(selectedFile)} controls />;
  } else if (
    fileType === "application" &&
    selectedFile.type === "application/pdf"
  ) {
    return (
      <div>
        <p>PDF File Selected:</p>
        <a
          href={URL.createObjectURL(selectedFile)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="text-blue-500 font-bold">{selectedFile?.name}</span>
        </a>
      </div>
    );
  } else {
    return <p>Unsupported file type selected.</p>;
  }
};

export default FileViewer;
