import React, { useEffect, useState } from "react";
import PageHeader from "../../common/PageHeader";
import CourseCard from "./CourseCard";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import Dropdown from "../../common/Dropdown";
import { sortByKeyAlphabetical } from "../../common/SortByName";

export default function CoursesList() {
  const { courses } = useEmployeeContextData();
  const employeeService = useEmployeeContextService();
  const [assigneToDate, setAssignedToDate] = useState(null);
  const [filterCourse, setFilterCourse] = useState([]);
  useEffect(() => {
    const filter = courses?.filter((item) => item === assigneToDate);
    setFilterCourse(filter);
  }, [assigneToDate]);

  useEffect(() => {
    employeeService.send({ type: "load_courses" });
  }, []);
  return (
    <div>
      <div className="flex flex-row justify-between m-4">
        <div>
          <PageHeader
            title={"Courses"}
            SubHeading="Overview and progress of all your courses."
          />
        </div>

        <div className="mt-3">
          <Dropdown
            selected={assigneToDate}
            setSelected={setAssignedToDate}
            list={sortByKeyAlphabetical(courses, "name")}
            nameField="name"
            placeholder="Select Course"
          />
        </div>
      </div>

      <div className="flex  mx-auto flex-wrap">
        {filterCourse?.length ? (
          filterCourse?.map((item, index) => (
            <CourseCard key={index} data={item} />
          ))
        ) : courses?.length ? (
          courses?.map((item, index) => <CourseCard key={index} data={item} />)
        ) : (
          <>
            <CourseCard />
            <CourseCard />
            <CourseCard />
          </>
        )}
      </div>
    </div>
  );
}
