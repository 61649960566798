export const loginSelector = (state) => {
  //return state.matches('LOGGED_IN.CHECK_FOR_PERMISSION');
  return state.matches("LOGGED_IN.USER_PROFILE_PRESENT");
};

export const activityPermissionSelecter = (state) => {
  return state.matches(
    "LOGGED_IN.USER_PROFILE_PRESENT.ACTIVITY_PERMISSION_GRANTED"
  );
};

export const checkForActivityPermissionSelecter = (state) => {
  return state.matches("LOGGED_IN.USER_PROFILE_PRESENT.CHECK_FOR_PERMISSION");
};

export const appReadySelecter = (state) => {
  return state.matches("LOGGED_IN.USER_PROFILE_PRESENT.APP_READY_TO_START");
};

export const contextSelector = (state) => {
  return state.context;
};

export const signUpFormSubmittingSelecter = (state) => {
  return state.matches(
    "SIGNUP.SIGNUP_USING_EMAIL.SIGNIN_USING_EMAIL_FORM_SUBMITTING"
  );
};
export const signUpCodeVerifyingSelecter = (state) => {
  return state.matches(
    "SIGNUP.SIGNUP_USING_EMAIL.SIGNIN_USING_EMAIL_CONFIRMING_CODE"
  );
};

export const isLoggingOutSelector = (state) => {
  return state.matches("LOGGED_IN.USER_LOG_OUT_NOW");
};

export const isDeletingUserSelector = (state) => {
  return state.matches("LOGGED_IN.USER_DELETE_NOW");
};

export const isWelcomeStateSelector = (state) => {
  return state.matches("LOGGED_OUT.LOGGED_OUT_INITIAL");
};

export const isSignUpStateSelector = (state) => {
  return state.matches("SIGNUP");
};

export const isSignInStateSelector = (state) => {
  return state.matches("SIGNIN.SIGNIN_USING_EMAIL.SIGNING_IN_USING_EMAIL");
};

export const codeVerifyingSelecter = (state) => {
  return state.matches(
    "SIGNUP.SIGNUP_USING_EMAIL.SIGNIN_USING_EMAIL_CONFIRMING_CODE"
  );
};
export const mailSentSelecter = (state) => {
  return state.matches(
    "SIGNUP.SIGNUP_USING_EMAIL.SIGNIN_USING_EMAIL_CODE_RECEVIED"
  );
};
export const checkMailSentSelecter = (state) => {
  return state.matches(
    "SIGNUP.SIGNUP_USING_EMAIL.SIGNIN_USING_EMAIL_EMAIL_SENT"
  );
};
export const formSubmittingSelector = (state) => {
  return state.matches(
    "SIGNUP.SIGNUP_USING_EMAIL.SIGNIN_USING_EMAIL_FORM_SUBMITTING"
  );
};
export const formCreatingSelector = (state) => {
  return state.matches(
    "SIGNUP.SIGNUP_USING_EMAIL.SIGNUP_USING_EMAIL_FORM_CREATING"
  );
};

export const isForgorPasswordStateSelector = (state) => {
  return state.matches("SIGNIN.SIGNIN_USING_EMAIL.FORGOT_PASSWORD_STATE");
};
