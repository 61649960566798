/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($cognitoId: ID!) {
    getUser(cognitoId: $cognitoId) {
      cognitoId
      fullName
      username
      email
      userType
      phone_number
      additionalInfo
      profilePicture
      isOnline
      location
      organizationID
      pushNotificationTokens {
        service
        deviceTokens
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $cognitoId: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      cognitoId: $cognitoId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognitoId
        fullName
        username
        email
        userType
        phone_number
        additionalInfo
        profilePicture
        isOnline
        location
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalarySlip = /* GraphQL */ `
  query GetSalarySlip($salarySlipID: String!) {
    getSalarySlip(salarySlipID: $salarySlipID) {
      salarySlipID
      userID
      s3key
      designation
      employeeID
      month
      dateOfJoining
      accountDetails
      location
      workedDays
      monthWorkingDays
      leavesWithPay
      leavesWithoutPay
      department
      name
      netSalary
      earningBasic
      earningHRA
      earningAllowance
      earningGross
      earningBonus
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      paymentID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalarySlips = /* GraphQL */ `
  query ListSalarySlips(
    $salarySlipID: String
    $filter: ModelSalarySlipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSalarySlips(
      salarySlipID: $salarySlipID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        salarySlipID
        userID
        s3key
        designation
        employeeID
        month
        dateOfJoining
        accountDetails
        location
        workedDays
        monthWorkingDays
        leavesWithPay
        leavesWithoutPay
        department
        name
        netSalary
        earningBasic
        earningHRA
        earningAllowance
        earningGross
        earningBonus
        deductionPF
        deductionInsurance
        deductionProfTax
        taxDeduction
        paymentID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompensation = /* GraphQL */ `
  query GetCompensation($id: ID!) {
    getCompensation(id: $id) {
      id
      startDate
      endDate
      earningBonus
      earningGross
      earningAllowance
      earningHRA
      earningBasic
      netSalary
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompensations = /* GraphQL */ `
  query ListCompensations(
    $filter: ModelCompensationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompensations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        earningBonus
        earningGross
        earningAllowance
        earningHRA
        earningBasic
        netSalary
        deductionPF
        deductionInsurance
        deductionProfTax
        taxDeduction
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeDocument = /* GraphQL */ `
  query GetEmployeeDocument($id: ID!) {
    getEmployeeDocument(id: $id) {
      id
      name
      s3key
      status
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployeeDocuments = /* GraphQL */ `
  query ListEmployeeDocuments(
    $filter: ModelEmployeeDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        s3key
        status
        approvedBy
        uploadDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForm16 = /* GraphQL */ `
  query GetForm16($id: ID!) {
    getForm16(id: $id) {
      grossSalary
      exemptions
      deductionsSection10
      netTaxableSalary
      deductionsChapterVIA
      totalIncome
      DeductedCurrentEmployer
      DeductedpreviousEmployer
      DeductedTotal
      DeductedCertificateNumber
      DeductedDateOfDeduction
      payableIncomeTax
      payableEducationCess
      payableTotalTax
      taxPaid
      taxRefund
      assessmentYear
      s3key
      userID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listForm16s = /* GraphQL */ `
  query ListForm16s(
    $filter: ModelForm16FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForm16s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        grossSalary
        exemptions
        deductionsSection10
        netTaxableSalary
        deductionsChapterVIA
        totalIncome
        DeductedCurrentEmployer
        DeductedpreviousEmployer
        DeductedTotal
        DeductedCertificateNumber
        DeductedDateOfDeduction
        payableIncomeTax
        payableEducationCess
        payableTotalTax
        taxPaid
        taxRefund
        assessmentYear
        s3key
        userID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($cognitoId: ID!) {
    getEmployee(cognitoId: $cognitoId) {
      cognitoId
      firstName
      middleName
      LastName
      email
      personalEmail
      userType
      profilePicture
      uniqueIdentification
      PAN
      dateOfBirth
      dateOfHire
      yearlyWage
      contactNo
      workLocation
      workHoursPerWeek
      emergencyContact1
      emergencyContact1Name
      emergencyContact1Relation
      emergencyContact2
      emergencyContact2Name
      emergencyContact2Relation
      permanentAddress
      currentAddress
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      noOfCOffLeaves
      noOfCOffLeavesTaken
      noOfCOffLeavesRemaining
      currentCompensationID
      accountDetails
      employeeStatus
      designation
      employeeID
      dateOjJoining
      location
      department
      guideID
      managers
      employees
      organizationID
      reimbursements {
        nextToken
        __typename
      }
      form16s {
        nextToken
        __typename
      }
      documentList {
        nextToken
        __typename
      }
      attendance {
        nextToken
        __typename
      }
      salarySlips {
        nextToken
        __typename
      }
      goals {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      inventory {
        nextToken
        __typename
      }
      leaves {
        nextToken
        __typename
      }
      skillSets
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $cognitoId: ID
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployees(
      cognitoId: $cognitoId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognitoId
        firstName
        middleName
        LastName
        email
        personalEmail
        userType
        profilePicture
        uniqueIdentification
        PAN
        dateOfBirth
        dateOfHire
        yearlyWage
        contactNo
        workLocation
        workHoursPerWeek
        emergencyContact1
        emergencyContact1Name
        emergencyContact1Relation
        emergencyContact2
        emergencyContact2Name
        emergencyContact2Relation
        permanentAddress
        currentAddress
        noOfLeaves
        noOfLeavesTaken
        noOfLeavesRemaining
        noOfMedicalLeaves
        noOfMedicalLeavesTaken
        noOfMedicalLeavesRemaining
        noOfCOffLeaves
        noOfCOffLeavesTaken
        noOfCOffLeavesRemaining
        currentCompensationID
        accountDetails
        employeeStatus
        designation
        employeeID
        dateOjJoining
        location
        department
        guideID
        managers
        employees
        organizationID
        skillSets
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeLeaveHistory = /* GraphQL */ `
  query GetEmployeeLeaveHistory($id: ID!) {
    getEmployeeLeaveHistory(id: $id) {
      userID
      year
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployeeLeaveHistories = /* GraphQL */ `
  query ListEmployeeLeaveHistories(
    $filter: ModelEmployeeLeaveHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeLeaveHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        year
        noOfLeaves
        noOfLeavesTaken
        noOfLeavesRemaining
        noOfMedicalLeaves
        noOfMedicalLeavesTaken
        noOfMedicalLeavesRemaining
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      organizationID
      uniqueName
      name
      description
      tagline
      lattitude
      longitude
      addressLine1
      addressLine2
      gstNumber
      contactNumber
      city
      activePlan
      logoBig
      logoSmall
      brandLogoBig
      brandLogoSmall
      primaryColor
      secondaryColor
      menuBGImage
      date
      salarySlipTemplateKey
      offerLetterTemplateKey
      payments {
        nextToken
        __typename
      }
      allowedDocumentsToUpload
      noOfLeaves
      noOfMedicalLeaves
      allowPastLeaves
      attendanceFrom
      allowTeamLeadToAddTask
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgDomains = /* GraphQL */ `
  query GetOrgDomains($id: ID!) {
    getOrgDomains(id: $id) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgDomains = /* GraphQL */ `
  query ListOrgDomains(
    $filter: ModelOrgDomainsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPolicyDocument = /* GraphQL */ `
  query GetPolicyDocument($id: ID!) {
    getPolicyDocument(id: $id) {
      organizationID
      employeeHandbookS3Key
      employeeLeavesPolicyS3Key
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPolicyDocuments = /* GraphQL */ `
  query ListPolicyDocuments(
    $filter: ModelPolicyDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolicyDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        organizationID
        employeeHandbookS3Key
        employeeLeavesPolicyS3Key
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgYearLeaves = /* GraphQL */ `
  query GetOrgYearLeaves($id: ID!) {
    getOrgYearLeaves(id: $id) {
      organizationID
      year
      startDate
      endDate
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgYearLeaves = /* GraphQL */ `
  query ListOrgYearLeaves(
    $filter: ModelOrgYearLeavesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgYearLeaves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        organizationID
        year
        startDate
        endDate
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgLeave = /* GraphQL */ `
  query GetOrgLeave($id: ID!) {
    getOrgLeave(id: $id) {
      organizationID
      orgYearLeavesID
      date
      occasion
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgLeaves = /* GraphQL */ `
  query ListOrgLeaves(
    $filter: ModelOrgLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgLeaves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        organizationID
        orgYearLeavesID
        date
        occasion
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgRoutes = /* GraphQL */ `
  query GetOrgRoutes($orgName: String!) {
    getOrgRoutes(orgName: $orgName) {
      orgName
      sales
      salesSubRoutes
      hr
      hrSubRoutes
      finance
      financeSubRoutes
      manager
      managerSubRoutes
      employee
      employeeSubRoutes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgRoutes = /* GraphQL */ `
  query ListOrgRoutes(
    $orgName: String
    $filter: ModelOrgRoutesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrgRoutes(
      orgName: $orgName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orgName
        sales
        salesSubRoutes
        hr
        hrSubRoutes
        finance
        financeSubRoutes
        manager
        managerSubRoutes
        employee
        employeeSubRoutes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgBusinessDays = /* GraphQL */ `
  query GetOrgBusinessDays($id: ID!) {
    getOrgBusinessDays(id: $id) {
      organizationID
      type
      customOffDays
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgBusinessDays = /* GraphQL */ `
  query ListOrgBusinessDays(
    $filter: ModelOrgBusinessDaysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgBusinessDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        organizationID
        type
        customOffDays
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgPayment = /* GraphQL */ `
  query GetOrgPayment($id: ID!) {
    getOrgPayment(id: $id) {
      id
      amount
      status
      createdAt
      updatedAt
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listOrgPayments = /* GraphQL */ `
  query ListOrgPayments(
    $filter: ModelOrgPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        status
        createdAt
        updatedAt
        organizationID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportExport = /* GraphQL */ `
  query GetReportExport($id: ID!) {
    getReportExport(id: $id) {
      reportExportID
      dateFrom
      dateTo
      s3Key
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReportExports = /* GraphQL */ `
  query ListReportExports(
    $filter: ModelReportExportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        reportExportID
        dateFrom
        dateTo
        s3Key
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgBill = /* GraphQL */ `
  query GetOrgBill($id: ID!) {
    getOrgBill(id: $id) {
      id
      amount
      status
      vendorID
      date
      createdAt
      updatedAt
      s3key
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      paymentID
      __typename
    }
  }
`;
export const listOrgBills = /* GraphQL */ `
  query ListOrgBills(
    $filter: ModelOrgBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        status
        vendorID
        date
        createdAt
        updatedAt
        s3key
        organizationID
        paymentID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgVendor = /* GraphQL */ `
  query GetOrgVendor($id: ID!) {
    getOrgVendor(id: $id) {
      id
      name
      vendorOrgID
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgVendors = /* GraphQL */ `
  query ListOrgVendors(
    $filter: ModelOrgVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        vendorOrgID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaveComment = /* GraphQL */ `
  query GetLeaveComment($id: ID!) {
    getLeaveComment(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      leaveID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeaveComments = /* GraphQL */ `
  query ListLeaveComments(
    $filter: ModelLeaveCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaveComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        leaveID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeave = /* GraphQL */ `
  query GetLeave($id: ID!) {
    getLeave(id: $id) {
      id
      typeOfLeave
      quantity
      appliedBy
      approvedBy
      approvedByName
      date2
      date
      title
      details
      dateTime
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeaves = /* GraphQL */ `
  query ListLeaves(
    $filter: ModelLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeOfLeave
        quantity
        appliedBy
        approvedBy
        approvedByName
        date2
        date
        title
        details
        dateTime
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoffComment = /* GraphQL */ `
  query GetCoffComment($id: ID!) {
    getCoffComment(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      coffId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoffComments = /* GraphQL */ `
  query ListCoffComments(
    $filter: ModelCoffCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoffComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        coffId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoffRequest = /* GraphQL */ `
  query GetCoffRequest($id: ID!) {
    getCoffRequest(id: $id) {
      id
      quantity
      appliedBy
      approvedBy
      approvedByName
      date
      details
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoffRequests = /* GraphQL */ `
  query ListCoffRequests(
    $filter: ModelCoffRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoffRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        appliedBy
        approvedBy
        approvedByName
        date
        details
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskComment = /* GraphQL */ `
  query GetTaskComment($id: ID!) {
    getTaskComment(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskComments = /* GraphQL */ `
  query ListTaskComments(
    $filter: ModelTaskCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        taskID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoalComment = /* GraphQL */ `
  query GetGoalComment($id: ID!) {
    getGoalComment(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      goalID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoalComments = /* GraphQL */ `
  query ListGoalComments(
    $filter: ModelGoalCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoalComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        goalID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      number
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      domain
      leadName
      teamSize
      plantForm
      customerName
      startDate
      completionDate
      poStatus
      invoiceStatus
      remark
      organizationID
      teammembers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        assignedTo
        assignedBy
        title
        content
        dueDate
        status
        actualCompletedDate
        domain
        leadName
        teamSize
        plantForm
        customerName
        startDate
        completionDate
        poStatus
        invoiceStatus
        remark
        organizationID
        teammembers
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTasks = /* GraphQL */ `
  query GetTasks($id: ID!) {
    getTasks(id: $id) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        status
        actualCompletedDate
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskHours = /* GraphQL */ `
  query GetTaskHours($id: ID!) {
    getTaskHours(id: $id) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskHours = /* GraphQL */ `
  query ListTaskHours(
    $filter: ModelTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskHours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReimbursement = /* GraphQL */ `
  query GetReimbursement($id: ID!) {
    getReimbursement(id: $id) {
      id
      title
      content
      dueDate
      amount
      status
      s3key
      date
      organizationID
      userID
      paymentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReimbursements = /* GraphQL */ `
  query ListReimbursements(
    $filter: ModelReimbursementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReimbursements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        dueDate
        amount
        status
        s3key
        date
        organizationID
        userID
        paymentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInventoryComment = /* GraphQL */ `
  query GetInventoryComment($id: ID!) {
    getInventoryComment(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      InventoryID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInventoryComments = /* GraphQL */ `
  query ListInventoryComments(
    $filter: ModelInventoryCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventoryComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        InventoryID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($id: ID!) {
    getInventory(id: $id) {
      id
      userID
      date
      assignedToName
      assignedBy
      assignedByName
      title
      content
      serialNo
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInventories = /* GraphQL */ `
  query ListInventories(
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        date
        assignedToName
        assignedBy
        assignedByName
        title
        content
        serialNo
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSprint = /* GraphQL */ `
  query GetSprint($id: ID!) {
    getSprint(id: $id) {
      id
      name
      startDate
      endDate
      status
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSprints = /* GraphQL */ `
  query ListSprints(
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSprints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        endDate
        status
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeTask = /* GraphQL */ `
  query GetEmployeeTask($id: ID!) {
    getEmployeeTask(id: $id) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      status
      percentage
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      sprint
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployeeTasks = /* GraphQL */ `
  query ListEmployeeTasks(
    $filter: ModelEmployeeTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        status
        percentage
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        sprint
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeTaskHours = /* GraphQL */ `
  query GetEmployeeTaskHours($id: ID!) {
    getEmployeeTaskHours(id: $id) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployeeTaskHours = /* GraphQL */ `
  query ListEmployeeTaskHours(
    $filter: ModelEmployeeTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeTaskHours(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      percentage
      status
      specific
      measurable
      attainable
      relevant
      timely
      year
      organizationID
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        percentage
        status
        specific
        measurable
        attainable
        relevant
        timely
        year
        organizationID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAttendance = /* GraphQL */ `
  query GetAttendance($id: ID!) {
    getAttendance(id: $id) {
      id
      userID
      employeeId
      emailId
      date
      day
      description
      timeIn1
      TimeOut1
      location {
        lat
        lon
        __typename
      }
      timeIn2
      TimeOut2
      totalHours
      isApproved
      workType
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAttendances = /* GraphQL */ `
  query ListAttendances(
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        employeeId
        emailId
        date
        day
        description
        timeIn1
        TimeOut1
        timeIn2
        TimeOut2
        totalHours
        isApproved
        workType
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeCourse = /* GraphQL */ `
  query GetEmployeeCourse($id: ID!) {
    getEmployeeCourse(id: $id) {
      id
      organizationID
      userID
      courseID
      completedLessons
      completedTopics
      completedTopicResources
      progress
      completed
      estimatedCompletionDate
      actualCompletionDate
      testID
      resultPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployeeCourses = /* GraphQL */ `
  query ListEmployeeCourses(
    $filter: ModelEmployeeCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        userID
        courseID
        completedLessons
        completedTopics
        completedTopicResources
        progress
        completed
        estimatedCompletionDate
        actualCompletionDate
        testID
        resultPercentage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      description
      active
      categories
      image
      instructor
      organizationID
      lessons {
        nextToken
        __typename
      }
      totalTopicResources
      testIDs
      passPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        active
        categories
        image
        instructor
        organizationID
        totalTopicResources
        testIDs
        passPercentage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      courseID
      dateTime
      name
      description
      topics {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseID
        dateTime
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      lessonID
      dateTime
      name
      notes
      resources {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lessonID
        dateTime
        name
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopicResource = /* GraphQL */ `
  query GetTopicResource($id: ID!) {
    getTopicResource(id: $id) {
      id
      topicID
      name
      dateTime
      description
      resourceKey
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTopicResources = /* GraphQL */ `
  query ListTopicResources(
    $filter: ModelTopicResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopicResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topicID
        name
        dateTime
        description
        resourceKey
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      title
      jobType
      isActive
      description
      mustHaveSkills
      niceToHaveSkills
      questionPaperID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        jobType
        isActive
        description
        mustHaveSkills
        niceToHaveSkills
        questionPaperID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCandidateDocument = /* GraphQL */ `
  query GetCandidateDocument($id: ID!) {
    getCandidateDocument(id: $id) {
      id
      name
      s3key
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCandidateDocuments = /* GraphQL */ `
  query ListCandidateDocuments(
    $filter: ModelCandidateDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        s3key
        approvedBy
        uploadDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCandidate = /* GraphQL */ `
  query GetCandidate($cognitoId: ID!) {
    getCandidate(cognitoId: $cognitoId) {
      cognitoId
      firstName
      middleName
      lastName
      personalEmail
      uniqueIdentification
      contactNo
      expectedCTC
      aboutMe
      dateOfBirth
      preferredLocation
      permanentAddress
      currentAddress
      s3resume
      documentList {
        nextToken
        __typename
      }
      appliedJobs {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $cognitoId: ID
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCandidates(
      cognitoId: $cognitoId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognitoId
        firstName
        middleName
        lastName
        personalEmail
        uniqueIdentification
        contactNo
        expectedCTC
        aboutMe
        dateOfBirth
        preferredLocation
        permanentAddress
        currentAddress
        s3resume
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobOffer = /* GraphQL */ `
  query GetJobOffer($id: ID!) {
    getJobOffer(id: $id) {
      dateOfJoining
      dateOfOffer
      probationPeriod
      costToCompany
      basicSalary
      allowance
      hra
      professionalTax
      providentFund
      insurance
      gratuity
      annualBonus
      candidateID
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJobOffers = /* GraphQL */ `
  query ListJobOffers(
    $filter: ModelJobOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        dateOfJoining
        dateOfOffer
        probationPeriod
        costToCompany
        basicSalary
        allowance
        hra
        professionalTax
        providentFund
        insurance
        gratuity
        annualBonus
        candidateID
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionPaper = /* GraphQL */ `
  query GetQuestionPaper($id: ID!) {
    getQuestionPaper(id: $id) {
      id
      friendlyName
      addedBy
      approvedBy
      paperType
      questionIds
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuestionPapers = /* GraphQL */ `
  query ListQuestionPapers(
    $filter: ModelQuestionPaperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionPapers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendlyName
        addedBy
        approvedBy
        paperType
        questionIds
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      options
      answer
      type
      position
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        options
        answer
        type
        position
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnswers = /* GraphQL */ `
  query GetAnswers($questionId: ID!) {
    getAnswers(questionId: $questionId) {
      questionId
      answers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $questionId: ID
    $filter: ModelAnswersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnswers(
      questionId: $questionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        questionId
        answers
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobApplication = /* GraphQL */ `
  query GetJobApplication($id: ID!) {
    getJobApplication(id: $id) {
      id
      candidate {
        cognitoId
        firstName
        middleName
        lastName
        personalEmail
        uniqueIdentification
        contactNo
        expectedCTC
        aboutMe
        dateOfBirth
        preferredLocation
        permanentAddress
        currentAddress
        s3resume
        createdAt
        updatedAt
        __typename
      }
      job {
        id
        title
        jobType
        isActive
        description
        mustHaveSkills
        niceToHaveSkills
        questionPaperID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      resume
      status
      dateApplied
      onlineTestScore
      writtenTestResult
      interviewResult
      hrInterviewResult
      contactNo
      expectedSalary
      onlineTestDateTime
      writtenTestDateTime
      interviewTestDateTime
      hrInterviewDateTime
      offer {
        dateOfJoining
        dateOfOffer
        probationPeriod
        costToCompany
        basicSalary
        allowance
        hra
        professionalTax
        providentFund
        insurance
        gratuity
        annualBonus
        candidateID
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      offerID
      questionPaperID
      jobID
      candidateID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJobApplications = /* GraphQL */ `
  query ListJobApplications(
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      contactemailID
      contactFirstName
      contactLastName
      contactEmail
      contactPhoneNo
      email
      phone
      website
      notes
      billingAddress
      shippingAddress
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEstimate = /* GraphQL */ `
  query GetEstimate($estimateNumber: String!) {
    getEstimate(estimateNumber: $estimateNumber) {
      id
      estimateNumber
      createDate
      expiryDate
      customerID
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      estimateItems {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEstimates = /* GraphQL */ `
  query ListEstimates(
    $estimateNumber: String
    $filter: ModelEstimateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEstimates(
      estimateNumber: $estimateNumber
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        estimateNumber
        createDate
        expiryDate
        customerID
        subheading
        summary
        projectName
        description
        footer
        notes
        memo
        currency
        subtotal
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEstimateItem = /* GraphQL */ `
  query GetEstimateItem($id: ID!) {
    getEstimateItem(id: $id) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      productServiceID
      EstimateID
      description
      quantity
      price
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      taxFloat
      taxString
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEstimateItems = /* GraphQL */ `
  query ListEstimateItems(
    $filter: ModelEstimateItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEstimateItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productServiceID
        EstimateID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductService = /* GraphQL */ `
  query GetProductService($id: ID!) {
    getProductService(id: $id) {
      id
      name
      price
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductServices = /* GraphQL */ `
  query ListProductServices(
    $filter: ModelProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      paymentID
      amount
      paymentDate
      paymentMethod
      status
      memo
      s3key
      direction
      fromTo
      transactionID
      vendorClientEmployeeId
      paymentTowards
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        paymentID
        amount
        paymentDate
        paymentMethod
        status
        memo
        s3key
        direction
        fromTo
        transactionID
        vendorClientEmployeeId
        paymentTowards
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      invoiceNumber
      createDate
      dueDate
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      customerID
      paymentID
      organizationID
      payment {
        paymentID
        amount
        paymentDate
        paymentMethod
        status
        memo
        s3key
        direction
        fromTo
        transactionID
        vendorClientEmployeeId
        paymentTowards
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      customerSideNo
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      invoiceItems {
        id
        invoiceNumber
        productServiceID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        invoiceNumber
        createDate
        dueDate
        customerID
        paymentID
        organizationID
        customerSideNo
        subheading
        summary
        projectName
        description
        footer
        notes
        memo
        currency
        subtotal
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTax = /* GraphQL */ `
  query GetTax($id: ID!) {
    getTax(id: $id) {
      id
      name
      percentage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaxes = /* GraphQL */ `
  query ListTaxes(
    $filter: ModelTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoiceItem = /* GraphQL */ `
  query GetInvoiceItem($id: ID!) {
    getInvoiceItem(id: $id) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      invoiceNumber
      productServiceID
      description
      quantity
      price
      taxFloat
      taxString
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoiceItems = /* GraphQL */ `
  query ListInvoiceItems(
    $filter: ModelInvoiceItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invoiceNumber
        productServiceID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnquiryForm = /* GraphQL */ `
  query GetEnquiryForm($id: ID!) {
    getEnquiryForm(id: $id) {
      id
      firstname
      lastname
      email
      companyName
      companyWebsite
      contactNumber
      expectedBudged
      expectedBudgedString
      leadOrigin
      date
      message
      industries
      services
      s3filekey
      sendNDA
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnquiryForms = /* GraphQL */ `
  query ListEnquiryForms(
    $id: ID
    $filter: ModelEnquiryFormFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnquiryForms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstname
        lastname
        email
        companyName
        companyWebsite
        contactNumber
        expectedBudged
        expectedBudgedString
        leadOrigin
        date
        message
        industries
        services
        s3filekey
        sendNDA
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeetingSlot = /* GraphQL */ `
  query GetMeetingSlot($id: ID!) {
    getMeetingSlot(id: $id) {
      id
      organizationID
      addedBy
      date
      startTime
      endTime
      title
      clientFirstName
      clientLastName
      clientEmail
      website
      companyName
      contactNo
      requirements
      relevants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeetingSlots = /* GraphQL */ `
  query ListMeetingSlots(
    $filter: ModelMeetingSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        addedBy
        date
        startTime
        endTime
        title
        clientFirstName
        clientLastName
        clientEmail
        website
        companyName
        contactNo
        requirements
        relevants
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactTag = /* GraphQL */ `
  query GetContactTag($id: ID!) {
    getContactTag(id: $id) {
      id
      label
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactTags = /* GraphQL */ `
  query ListContactTags(
    $filter: ModelContactTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($email: String!) {
    getContact(email: $email) {
      email
      firstName
      lastName
      designation
      country
      contactOffice
      contactMobile
      contactMobile2
      email2
      companyName
      companyWebsite
      status
      industries
      customerID
      profilePicture
      s3businessCardKey
      assignedTo
      notes
      contactNotes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      deals {
        nextToken
        __typename
      }
      linkedinLink
      lnkedIsConnected
      twitterLink
      instagramLink
      socialLink
      organizationID
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $email: String
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContacts(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        designation
        country
        contactOffice
        contactMobile
        contactMobile2
        email2
        companyName
        companyWebsite
        status
        industries
        customerID
        profilePicture
        s3businessCardKey
        assignedTo
        notes
        linkedinLink
        lnkedIsConnected
        twitterLink
        instagramLink
        socialLink
        organizationID
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactNote = /* GraphQL */ `
  query GetContactNote($id: ID!) {
    getContactNote(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      contactID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactNotes = /* GraphQL */ `
  query ListContactNotes(
    $filter: ModelContactNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        contactID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesTaskComment = /* GraphQL */ `
  query GetSalesTaskComment($id: ID!) {
    getSalesTaskComment(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesTaskComments = /* GraphQL */ `
  query ListSalesTaskComments(
    $filter: ModelSalesTaskCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesTaskComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        taskID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesTask = /* GraphQL */ `
  query GetSalesTask($id: ID!) {
    getSalesTask(id: $id) {
      id
      forEmail
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesTasks = /* GraphQL */ `
  query ListSalesTasks(
    $filter: ModelSalesTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forEmail
        assignedTo
        title
        content
        dueDate
        actualCompletedDate
        status
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesDealComment = /* GraphQL */ `
  query GetSalesDealComment($id: ID!) {
    getSalesDealComment(id: $id) {
      id
      content
      addedBy
      addedByName
      dateTime
      dealID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesDealComments = /* GraphQL */ `
  query ListSalesDealComments(
    $filter: ModelSalesDealCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesDealComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        dealID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesDeal = /* GraphQL */ `
  query GetSalesDeal($id: ID!) {
    getSalesDeal(id: $id) {
      id
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      proposalS3Key
      estimateID
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesDeals = /* GraphQL */ `
  query ListSalesDeals(
    $filter: ModelSalesDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesDeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignedTo
        title
        content
        dueDate
        actualCompletedDate
        status
        proposalS3Key
        estimateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscribersNew = /* GraphQL */ `
  query GetSubscribersNew($email: String!) {
    getSubscribersNew(email: $email) {
      name
      email
      emailCopy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscribersNews = /* GraphQL */ `
  query ListSubscribersNews(
    $email: String
    $filter: ModelSubscribersNewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubscribersNews(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        email
        emailCopy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfitAndLossReport = /* GraphQL */ `
  query GetProfitAndLossReport($id: ID!) {
    getProfitAndLossReport(id: $id) {
      profitAndLossReportID
      dateFrom
      dateTo
      income
      bills
      payrools
      grossProfit
      operatingExpense
      netProfit
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProfitAndLossReports = /* GraphQL */ `
  query ListProfitAndLossReports(
    $filter: ModelProfitAndLossReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfitAndLossReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        profitAndLossReportID
        dateFrom
        dateTo
        income
        bills
        payrools
        grossProfit
        operatingExpense
        netProfit
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBalanceSheetReport = /* GraphQL */ `
  query GetBalanceSheetReport($id: ID!) {
    getBalanceSheetReport(id: $id) {
      balanceSheetReportID
      asOfDate
      assets
      cashAndBank
      cashAndBankFields
      cashAndBankValues
      currentAssets
      currentAssetsFields
      currentAssetsValues
      fixedAssets
      fixedAssetsFields
      fixedAssetsValues
      liabilities
      currentLiabilities
      currentLiabilitiesFields
      currentLiabilitiesValues
      lognTermLiabilities
      lognTermLiabilitiesFields
      lognTermLiabilitiesValues
      equity
      retainedEarnings
      retainedEarningsFields
      retainedEarningsValues
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBalanceSheetReports = /* GraphQL */ `
  query ListBalanceSheetReports(
    $filter: ModelBalanceSheetReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBalanceSheetReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        balanceSheetReportID
        asOfDate
        assets
        cashAndBank
        cashAndBankFields
        cashAndBankValues
        currentAssets
        currentAssetsFields
        currentAssetsValues
        fixedAssets
        fixedAssetsFields
        fixedAssetsValues
        liabilities
        currentLiabilities
        currentLiabilitiesFields
        currentLiabilitiesValues
        lognTermLiabilities
        lognTermLiabilitiesFields
        lognTermLiabilitiesValues
        equity
        retainedEarnings
        retainedEarningsFields
        retainedEarningsValues
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgDocumentSequence = /* GraphQL */ `
  query GetOrgDocumentSequence($organizationID: ID!) {
    getOrgDocumentSequence(organizationID: $organizationID) {
      organizationID
      invoiceSequenceNumber
      estimateSequenceNumber
      proposalSequenceNumber
      salarySlipSequenceNumber
      form16SequenceNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgDocumentSequences = /* GraphQL */ `
  query ListOrgDocumentSequences(
    $organizationID: ID
    $filter: ModelOrgDocumentSequenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrgDocumentSequences(
      organizationID: $organizationID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        organizationID
        invoiceSequenceNumber
        estimateSequenceNumber
        proposalSequenceNumber
        salarySlipSequenceNumber
        form16SequenceNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSkillSet = /* GraphQL */ `
  query GetSkillSet($id: ID!) {
    getSkillSet(id: $id) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSkillSets = /* GraphQL */ `
  query ListSkillSets(
    $filter: ModelSkillSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkillSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      slug
      type
      content
      summary
      state
      seotitle
      seodescription
      seokeywords
      imageLink
      ogImage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        type
        content
        summary
        state
        seotitle
        seodescription
        seokeywords
        imageLink
        ogImage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const UsersByOrganizationID = /* GraphQL */ `
  query UsersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UsersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        fullName
        username
        email
        userType
        phone_number
        additionalInfo
        profilePicture
        isOnline
        location
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SalarySlipsByUserID = /* GraphQL */ `
  query SalarySlipsByUserID(
    $userID: ID!
    $month: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalarySlipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalarySlipsByUserID(
      userID: $userID
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        salarySlipID
        userID
        s3key
        designation
        employeeID
        month
        dateOfJoining
        accountDetails
        location
        workedDays
        monthWorkingDays
        leavesWithPay
        leavesWithoutPay
        department
        name
        netSalary
        earningBasic
        earningHRA
        earningAllowance
        earningGross
        earningBonus
        deductionPF
        deductionInsurance
        deductionProfTax
        taxDeduction
        paymentID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SalarySlipByPaymentID = /* GraphQL */ `
  query SalarySlipByPaymentID(
    $paymentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSalarySlipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalarySlipByPaymentID(
      paymentID: $paymentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        salarySlipID
        userID
        s3key
        designation
        employeeID
        month
        dateOfJoining
        accountDetails
        location
        workedDays
        monthWorkingDays
        leavesWithPay
        leavesWithoutPay
        department
        name
        netSalary
        earningBasic
        earningHRA
        earningAllowance
        earningGross
        earningBonus
        deductionPF
        deductionInsurance
        deductionProfTax
        taxDeduction
        paymentID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SalarySlipsByOrganizationID = /* GraphQL */ `
  query SalarySlipsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSalarySlipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalarySlipsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        salarySlipID
        userID
        s3key
        designation
        employeeID
        month
        dateOfJoining
        accountDetails
        location
        workedDays
        monthWorkingDays
        leavesWithPay
        leavesWithoutPay
        department
        name
        netSalary
        earningBasic
        earningHRA
        earningAllowance
        earningGross
        earningBonus
        deductionPF
        deductionInsurance
        deductionProfTax
        taxDeduction
        paymentID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CompensationsByUserID = /* GraphQL */ `
  query CompensationsByUserID(
    $userID: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompensationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CompensationsByUserID(
      userID: $userID
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        earningBonus
        earningGross
        earningAllowance
        earningHRA
        earningBasic
        netSalary
        deductionPF
        deductionInsurance
        deductionProfTax
        taxDeduction
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeDocumentByUserID = /* GraphQL */ `
  query EmployeeDocumentByUserID(
    $userID: ID!
    $uploadDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeDocumentByUserID(
      userID: $userID
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        s3key
        status
        approvedBy
        uploadDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const Form16sByUserID = /* GraphQL */ `
  query Form16sByUserID(
    $userID: ID!
    $assessmentYear: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForm16FilterInput
    $limit: Int
    $nextToken: String
  ) {
    Form16sByUserID(
      userID: $userID
      assessmentYear: $assessmentYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        grossSalary
        exemptions
        deductionsSection10
        netTaxableSalary
        deductionsChapterVIA
        totalIncome
        DeductedCurrentEmployer
        DeductedpreviousEmployer
        DeductedTotal
        DeductedCertificateNumber
        DeductedDateOfDeduction
        payableIncomeTax
        payableEducationCess
        payableTotalTax
        taxPaid
        taxRefund
        assessmentYear
        s3key
        userID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeesByGuideID = /* GraphQL */ `
  query EmployeesByGuideID(
    $guideID: ID!
    $dateOfHire: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeesByGuideID(
      guideID: $guideID
      dateOfHire: $dateOfHire
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        firstName
        middleName
        LastName
        email
        personalEmail
        userType
        profilePicture
        uniqueIdentification
        PAN
        dateOfBirth
        dateOfHire
        yearlyWage
        contactNo
        workLocation
        workHoursPerWeek
        emergencyContact1
        emergencyContact1Name
        emergencyContact1Relation
        emergencyContact2
        emergencyContact2Name
        emergencyContact2Relation
        permanentAddress
        currentAddress
        noOfLeaves
        noOfLeavesTaken
        noOfLeavesRemaining
        noOfMedicalLeaves
        noOfMedicalLeavesTaken
        noOfMedicalLeavesRemaining
        noOfCOffLeaves
        noOfCOffLeavesTaken
        noOfCOffLeavesRemaining
        currentCompensationID
        accountDetails
        employeeStatus
        designation
        employeeID
        dateOjJoining
        location
        department
        guideID
        managers
        employees
        organizationID
        skillSets
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeesByOrganizationID = /* GraphQL */ `
  query EmployeesByOrganizationID(
    $organizationID: ID!
    $dateOfHire: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeesByOrganizationID(
      organizationID: $organizationID
      dateOfHire: $dateOfHire
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        firstName
        middleName
        LastName
        email
        personalEmail
        userType
        profilePicture
        uniqueIdentification
        PAN
        dateOfBirth
        dateOfHire
        yearlyWage
        contactNo
        workLocation
        workHoursPerWeek
        emergencyContact1
        emergencyContact1Name
        emergencyContact1Relation
        emergencyContact2
        emergencyContact2Name
        emergencyContact2Relation
        permanentAddress
        currentAddress
        noOfLeaves
        noOfLeavesTaken
        noOfLeavesRemaining
        noOfMedicalLeaves
        noOfMedicalLeavesTaken
        noOfMedicalLeavesRemaining
        noOfCOffLeaves
        noOfCOffLeavesTaken
        noOfCOffLeavesRemaining
        currentCompensationID
        accountDetails
        employeeStatus
        designation
        employeeID
        dateOjJoining
        location
        department
        guideID
        managers
        employees
        organizationID
        skillSets
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeLeaveHistoryByUserID = /* GraphQL */ `
  query EmployeeLeaveHistoryByUserID(
    $userID: ID!
    $year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeLeaveHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeLeaveHistoryByUserID(
      userID: $userID
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        year
        noOfLeaves
        noOfLeavesTaken
        noOfLeavesRemaining
        noOfMedicalLeaves
        noOfMedicalLeavesTaken
        noOfMedicalLeavesRemaining
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgDomainsByOrganizationID = /* GraphQL */ `
  query OrgDomainsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgDomainsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgDomainsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const PolicyDocuemntsByOrganizationID = /* GraphQL */ `
  query PolicyDocuemntsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPolicyDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PolicyDocuemntsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        organizationID
        employeeHandbookS3Key
        employeeLeavesPolicyS3Key
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgYearLeavesByOrganizationID = /* GraphQL */ `
  query OrgYearLeavesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgYearLeavesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgYearLeavesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        organizationID
        year
        startDate
        endDate
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgLeavesByOrganizationID = /* GraphQL */ `
  query OrgLeavesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgLeavesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        organizationID
        orgYearLeavesID
        date
        occasion
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgLeavesByYear = /* GraphQL */ `
  query OrgLeavesByYear(
    $orgYearLeavesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgLeavesByYear(
      orgYearLeavesID: $orgYearLeavesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        organizationID
        orgYearLeavesID
        date
        occasion
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgBusinessDaysByOrganizationID = /* GraphQL */ `
  query OrgBusinessDaysByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgBusinessDaysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgBusinessDaysByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        organizationID
        type
        customOffDays
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgPaymentsByOrganizationID = /* GraphQL */ `
  query OrgPaymentsByOrganizationID(
    $organizationID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrgPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgPaymentsByOrganizationID(
      organizationID: $organizationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        status
        createdAt
        updatedAt
        organizationID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ReportExportsByOrganizationID = /* GraphQL */ `
  query ReportExportsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportExportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReportExportsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        reportExportID
        dateFrom
        dateTo
        s3Key
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgBillsByOrganizationID = /* GraphQL */ `
  query OrgBillsByOrganizationID(
    $organizationID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrgBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgBillsByOrganizationID(
      organizationID: $organizationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        status
        vendorID
        date
        createdAt
        updatedAt
        s3key
        organizationID
        paymentID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgBillByPaymentID = /* GraphQL */ `
  query OrgBillByPaymentID(
    $paymentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgBillByPaymentID(
      paymentID: $paymentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        status
        vendorID
        date
        createdAt
        updatedAt
        s3key
        organizationID
        paymentID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgVendorsByOrganizationID = /* GraphQL */ `
  query OrgVendorsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgVendorsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vendorOrgID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const leaveCommentsByLeaveID = /* GraphQL */ `
  query LeaveCommentsByLeaveID(
    $leaveID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaveCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leaveCommentsByLeaveID(
      leaveID: $leaveID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        leaveID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const LeavesByUserID = /* GraphQL */ `
  query LeavesByUserID(
    $userID: ID!
    $date2: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LeavesByUserID(
      userID: $userID
      date2: $date2
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeOfLeave
        quantity
        appliedBy
        approvedBy
        approvedByName
        date2
        date
        title
        details
        dateTime
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const LeavesByOrganizationID = /* GraphQL */ `
  query LeavesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LeavesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeOfLeave
        quantity
        appliedBy
        approvedBy
        approvedByName
        date2
        date
        title
        details
        dateTime
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CoffCommentsByLeaveID = /* GraphQL */ `
  query CoffCommentsByLeaveID(
    $coffId: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoffCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CoffCommentsByLeaveID(
      coffId: $coffId
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        coffId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CoffsByUserID = /* GraphQL */ `
  query CoffsByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoffRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CoffsByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        appliedBy
        approvedBy
        approvedByName
        date
        details
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CoffsByOrganizationID = /* GraphQL */ `
  query CoffsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCoffRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CoffsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        appliedBy
        approvedBy
        approvedByName
        date
        details
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const taskCommentsByTaskID = /* GraphQL */ `
  query TaskCommentsByTaskID(
    $taskID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taskCommentsByTaskID(
      taskID: $taskID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        taskID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const goalCommentsByGoalID = /* GraphQL */ `
  query GoalCommentsByGoalID(
    $goalID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalCommentsByGoalID(
      goalID: $goalID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        goalID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProjectsByOrganizationID = /* GraphQL */ `
  query ProjectsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProjectsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        assignedTo
        assignedBy
        title
        content
        dueDate
        status
        actualCompletedDate
        domain
        leadName
        teamSize
        plantForm
        customerName
        startDate
        completionDate
        poStatus
        invoiceStatus
        remark
        organizationID
        teammembers
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TasksByProjectID = /* GraphQL */ `
  query TasksByProjectID(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksByProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        status
        actualCompletedDate
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TasksByOrganizationID = /* GraphQL */ `
  query TasksByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        status
        actualCompletedDate
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TasksHoursUserID = /* GraphQL */ `
  query TasksHoursUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksHoursUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TasksHoursTaskID = /* GraphQL */ `
  query TasksHoursTaskID(
    $taskID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksHoursTaskID(
      taskID: $taskID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TasksHoursProjectID = /* GraphQL */ `
  query TasksHoursProjectID(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksHoursProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ReimbursementsByOrganizationID = /* GraphQL */ `
  query ReimbursementsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReimbursementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReimbursementsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        dueDate
        amount
        status
        s3key
        date
        organizationID
        userID
        paymentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ReimbursementsByUserID = /* GraphQL */ `
  query ReimbursementsByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReimbursementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReimbursementsByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        dueDate
        amount
        status
        s3key
        date
        organizationID
        userID
        paymentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ReimbursementByPaymentID = /* GraphQL */ `
  query ReimbursementByPaymentID(
    $paymentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReimbursementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReimbursementByPaymentID(
      paymentID: $paymentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        dueDate
        amount
        status
        s3key
        date
        organizationID
        userID
        paymentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InventoryCommentsByTaskID = /* GraphQL */ `
  query InventoryCommentsByTaskID(
    $InventoryID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InventoryCommentsByTaskID(
      InventoryID: $InventoryID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        InventoryID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InventoryByUserID = /* GraphQL */ `
  query InventoryByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InventoryByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        assignedToName
        assignedBy
        assignedByName
        title
        content
        serialNo
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InventoriesByOrganizationID = /* GraphQL */ `
  query InventoriesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InventoriesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        assignedToName
        assignedBy
        assignedByName
        title
        content
        serialNo
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SprintsByOrganizationID = /* GraphQL */ `
  query SprintsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SprintsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        endDate
        status
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeTasksByProjectID = /* GraphQL */ `
  query EmployeeTasksByProjectID(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeTasksByProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        status
        percentage
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        sprint
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeTasksByOrganizationID = /* GraphQL */ `
  query EmployeeTasksByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeTasksByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        status
        percentage
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        sprint
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TasksBySprint = /* GraphQL */ `
  query TasksBySprint(
    $sprint: String!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksBySprint(
      sprint: $sprint
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        status
        percentage
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        sprint
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TasksByUserID = /* GraphQL */ `
  query TasksByUserID(
    $userID: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksByUserID(
      userID: $userID
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        status
        percentage
        billableHours
        billedHours
        unbillableHours
        billedUnbillableHours
        projectID
        organizationID
        sprint
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeTasksHoursByUserID = /* GraphQL */ `
  query EmployeeTasksHoursByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeTasksHoursByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeTasksHoursByEmployeeTaskID = /* GraphQL */ `
  query EmployeeTasksHoursByEmployeeTaskID(
    $taskID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeTasksHoursByEmployeeTaskID(
      taskID: $taskID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeTasksHoursByProjectID = /* GraphQL */ `
  query EmployeeTasksHoursByProjectID(
    $projectID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskHoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeTasksHoursByProjectID(
      projectID: $projectID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GoalsByOrganizationID = /* GraphQL */ `
  query GoalsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GoalsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        percentage
        status
        specific
        measurable
        attainable
        relevant
        timely
        year
        organizationID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GoalsByUserID = /* GraphQL */ `
  query GoalsByUserID(
    $userID: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GoalsByUserID(
      userID: $userID
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        assignedBy
        title
        content
        dueDate
        actualCompletedDate
        percentage
        status
        specific
        measurable
        attainable
        relevant
        timely
        year
        organizationID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const AttendanceByUserID = /* GraphQL */ `
  query AttendanceByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        employeeId
        emailId
        date
        day
        description
        timeIn1
        TimeOut1
        timeIn2
        TimeOut2
        totalHours
        isApproved
        workType
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const AttendancesByOrganizationID = /* GraphQL */ `
  query AttendancesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendancesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        employeeId
        emailId
        date
        day
        description
        timeIn1
        TimeOut1
        timeIn2
        TimeOut2
        totalHours
        isApproved
        workType
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeCoursesByOrganizationID = /* GraphQL */ `
  query EmployeeCoursesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeCoursesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        userID
        courseID
        completedLessons
        completedTopics
        completedTopicResources
        progress
        completed
        estimatedCompletionDate
        actualCompletionDate
        testID
        resultPercentage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeCoursesByUserID = /* GraphQL */ `
  query EmployeeCoursesByUserID(
    $userID: ID!
    $estimatedCompletionDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeCoursesByUserID(
      userID: $userID
      estimatedCompletionDate: $estimatedCompletionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        userID
        courseID
        completedLessons
        completedTopics
        completedTopicResources
        progress
        completed
        estimatedCompletionDate
        actualCompletionDate
        testID
        resultPercentage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmployeeCoursesByCourseID = /* GraphQL */ `
  query EmployeeCoursesByCourseID(
    $courseID: ID!
    $estimatedCompletionDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeeCoursesByCourseID(
      courseID: $courseID
      estimatedCompletionDate: $estimatedCompletionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        userID
        courseID
        completedLessons
        completedTopics
        completedTopicResources
        progress
        completed
        estimatedCompletionDate
        actualCompletionDate
        testID
        resultPercentage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CoursesByOrganizationID = /* GraphQL */ `
  query CoursesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CoursesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        active
        categories
        image
        instructor
        organizationID
        totalTopicResources
        testIDs
        passPercentage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const courseLessonsByCourseID = /* GraphQL */ `
  query CourseLessonsByCourseID(
    $courseID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseLessonsByCourseID(
      courseID: $courseID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        dateTime
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lessonTopicsByLessonID = /* GraphQL */ `
  query LessonTopicsByLessonID(
    $lessonID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonTopicsByLessonID(
      lessonID: $lessonID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonID
        dateTime
        name
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const topicResourcesByTopicID = /* GraphQL */ `
  query TopicResourcesByTopicID(
    $topicID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicResourcesByTopicID(
      topicID: $topicID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicID
        name
        dateTime
        description
        resourceKey
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const JobsByOrganizationID = /* GraphQL */ `
  query JobsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    JobsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        jobType
        isActive
        description
        mustHaveSkills
        niceToHaveSkills
        questionPaperID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CandidateDocumentByUserID = /* GraphQL */ `
  query CandidateDocumentByUserID(
    $userID: ID!
    $uploadDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CandidateDocumentByUserID(
      userID: $userID
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        s3key
        approvedBy
        uploadDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const JobOfferByCandidateID = /* GraphQL */ `
  query JobOfferByCandidateID(
    $candidateID: ID!
    $dateOfOffer: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    JobOfferByCandidateID(
      candidateID: $candidateID
      dateOfOffer: $dateOfOffer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dateOfJoining
        dateOfOffer
        probationPeriod
        costToCompany
        basicSalary
        allowance
        hra
        professionalTax
        providentFund
        insurance
        gratuity
        annualBonus
        candidateID
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const JobOffersByOrganizationID = /* GraphQL */ `
  query JobOffersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    JobOffersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dateOfJoining
        dateOfOffer
        probationPeriod
        costToCompany
        basicSalary
        allowance
        hra
        professionalTax
        providentFund
        insurance
        gratuity
        annualBonus
        candidateID
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const QuestionPapersByOrganizationID = /* GraphQL */ `
  query QuestionPapersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelquestionPaperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionPapersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyName
        addedBy
        approvedBy
        paperType
        questionIds
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OffersByJobID = /* GraphQL */ `
  query OffersByJobID(
    $offerID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OffersByJobID(
      offerID: $offerID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const JobApplicationsByJobID = /* GraphQL */ `
  query JobApplicationsByJobID(
    $jobID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    JobApplicationsByJobID(
      jobID: $jobID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const JobApplicationsByCandidateID = /* GraphQL */ `
  query JobApplicationsByCandidateID(
    $candidateID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    JobApplicationsByCandidateID(
      candidateID: $candidateID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const JobApplicationsByOrganizationID = /* GraphQL */ `
  query JobApplicationsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    JobApplicationsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CustomersByOrganizationID = /* GraphQL */ `
  query CustomersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CustomersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EstimatesByCustomerID = /* GraphQL */ `
  query EstimatesByCustomerID(
    $customerID: ID!
    $createDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEstimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EstimatesByCustomerID(
      customerID: $customerID
      createDate: $createDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        estimateNumber
        createDate
        expiryDate
        customerID
        subheading
        summary
        projectName
        description
        footer
        notes
        memo
        currency
        subtotal
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EstimatesByOrganizationID = /* GraphQL */ `
  query EstimatesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEstimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EstimatesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        estimateNumber
        createDate
        expiryDate
        customerID
        subheading
        summary
        projectName
        description
        footer
        notes
        memo
        currency
        subtotal
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EstimateItemsByEstimateID = /* GraphQL */ `
  query EstimateItemsByEstimateID(
    $EstimateID: ID!
    $productServiceID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEstimateItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EstimateItemsByEstimateID(
      EstimateID: $EstimateID
      productServiceID: $productServiceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        EstimateID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductServicesByOrganizationID = /* GraphQL */ `
  query ProductServicesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductServicesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const PaymentsByOrganizationID = /* GraphQL */ `
  query PaymentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PaymentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        paymentID
        amount
        paymentDate
        paymentMethod
        status
        memo
        s3key
        direction
        fromTo
        transactionID
        vendorClientEmployeeId
        paymentTowards
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InvoicesByCustomerID = /* GraphQL */ `
  query InvoicesByCustomerID(
    $customerID: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InvoicesByCustomerID(
      customerID: $customerID
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        invoiceNumber
        createDate
        dueDate
        customerID
        paymentID
        organizationID
        customerSideNo
        subheading
        summary
        projectName
        description
        footer
        notes
        memo
        currency
        subtotal
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InvoiceByPaymentID = /* GraphQL */ `
  query InvoiceByPaymentID(
    $paymentID: ID!
    $createDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InvoiceByPaymentID(
      paymentID: $paymentID
      createDate: $createDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        invoiceNumber
        createDate
        dueDate
        customerID
        paymentID
        organizationID
        customerSideNo
        subheading
        summary
        projectName
        description
        footer
        notes
        memo
        currency
        subtotal
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InvoicesByOrganizationID = /* GraphQL */ `
  query InvoicesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InvoicesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        invoiceNumber
        createDate
        dueDate
        customerID
        paymentID
        organizationID
        customerSideNo
        subheading
        summary
        projectName
        description
        footer
        notes
        memo
        currency
        subtotal
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TaxesByOrganizationID = /* GraphQL */ `
  query TaxesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TaxesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InvoiceItemsByInvoiceNumber = /* GraphQL */ `
  query InvoiceItemsByInvoiceNumber(
    $invoiceNumber: String!
    $productServiceID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InvoiceItemsByInvoiceNumber(
      invoiceNumber: $invoiceNumber
      productServiceID: $productServiceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceNumber
        productServiceID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnquiryFormsByEmail = /* GraphQL */ `
  query GetEnquiryFormsByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEnquiryFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEnquiryFormsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        email
        companyName
        companyWebsite
        contactNumber
        expectedBudged
        expectedBudgedString
        leadOrigin
        date
        message
        industries
        services
        s3filekey
        sendNDA
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EnquiryFormsByOrganizationID = /* GraphQL */ `
  query EnquiryFormsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEnquiryFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EnquiryFormsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        email
        companyName
        companyWebsite
        contactNumber
        expectedBudged
        expectedBudgedString
        leadOrigin
        date
        message
        industries
        services
        s3filekey
        sendNDA
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const MeetingSlotsByOrganizationID = /* GraphQL */ `
  query MeetingSlotsByOrganizationID(
    $organizationID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MeetingSlotsByOrganizationID(
      organizationID: $organizationID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        addedBy
        date
        startTime
        endTime
        title
        clientFirstName
        clientLastName
        clientEmail
        website
        companyName
        contactNo
        requirements
        relevants
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const MeetingSlotsByUserID = /* GraphQL */ `
  query MeetingSlotsByUserID(
    $addedBy: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MeetingSlotsByUserID(
      addedBy: $addedBy
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        addedBy
        date
        startTime
        endTime
        title
        clientFirstName
        clientLastName
        clientEmail
        website
        companyName
        contactNo
        requirements
        relevants
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ContactTagsByOrganizationID = /* GraphQL */ `
  query ContactTagsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ContactTagsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ContactsByUserID = /* GraphQL */ `
  query ContactsByUserID(
    $assignedTo: ID!
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ContactsByUserID(
      assignedTo: $assignedTo
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        designation
        country
        contactOffice
        contactMobile
        contactMobile2
        email2
        companyName
        companyWebsite
        status
        industries
        customerID
        profilePicture
        s3businessCardKey
        assignedTo
        notes
        linkedinLink
        lnkedIsConnected
        twitterLink
        instagramLink
        socialLink
        organizationID
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ContactsByOrganizationID = /* GraphQL */ `
  query ContactsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ContactsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        designation
        country
        contactOffice
        contactMobile
        contactMobile2
        email2
        companyName
        companyWebsite
        status
        industries
        customerID
        profilePicture
        s3businessCardKey
        assignedTo
        notes
        linkedinLink
        lnkedIsConnected
        twitterLink
        instagramLink
        socialLink
        organizationID
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactNotesByEmailID = /* GraphQL */ `
  query ContactNotesByEmailID(
    $contactID: String!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactNotesByEmailID(
      contactID: $contactID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        contactID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const salesTaskCommentsByTaskID = /* GraphQL */ `
  query SalesTaskCommentsByTaskID(
    $taskID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesTaskCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesTaskCommentsByTaskID(
      taskID: $taskID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        taskID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SalesTasksByContact = /* GraphQL */ `
  query SalesTasksByContact(
    $forEmail: String!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalesTasksByContact(
      forEmail: $forEmail
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        forEmail
        assignedTo
        title
        content
        dueDate
        actualCompletedDate
        status
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SalesTasksByUserID = /* GraphQL */ `
  query SalesTasksByUserID(
    $assignedTo: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalesTasksByUserID(
      assignedTo: $assignedTo
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        forEmail
        assignedTo
        title
        content
        dueDate
        actualCompletedDate
        status
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SalesTasksByOrganizationID = /* GraphQL */ `
  query SalesTasksByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSalesTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalesTasksByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        forEmail
        assignedTo
        title
        content
        dueDate
        actualCompletedDate
        status
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const salesDealCommentsByDealID = /* GraphQL */ `
  query SalesDealCommentsByDealID(
    $dealID: ID!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesDealCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesDealCommentsByDealID(
      dealID: $dealID
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        addedBy
        addedByName
        dateTime
        dealID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DealsByUserID = /* GraphQL */ `
  query DealsByUserID(
    $assignedTo: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DealsByUserID(
      assignedTo: $assignedTo
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        title
        content
        dueDate
        actualCompletedDate
        status
        proposalS3Key
        estimateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SalesDealsByOrganizationID = /* GraphQL */ `
  query SalesDealsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSalesDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalesDealsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        title
        content
        dueDate
        actualCompletedDate
        status
        proposalS3Key
        estimateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscribersNewByEmail = /* GraphQL */ `
  query SubscribersNewByEmail(
    $emailCopy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscribersNewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscribersNewByEmail(
      emailCopy: $emailCopy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        email
        emailCopy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProfitAndLossReportsByOrganizationID = /* GraphQL */ `
  query ProfitAndLossReportsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfitAndLossReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProfitAndLossReportsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        profitAndLossReportID
        dateFrom
        dateTo
        income
        bills
        payrools
        grossProfit
        operatingExpense
        netProfit
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const BalanceSheetReportsByOrganizationID = /* GraphQL */ `
  query BalanceSheetReportsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBalanceSheetReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BalanceSheetReportsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        balanceSheetReportID
        asOfDate
        assets
        cashAndBank
        cashAndBankFields
        cashAndBankValues
        currentAssets
        currentAssetsFields
        currentAssetsValues
        fixedAssets
        fixedAssetsFields
        fixedAssetsValues
        liabilities
        currentLiabilities
        currentLiabilitiesFields
        currentLiabilitiesValues
        lognTermLiabilities
        lognTermLiabilitiesFields
        lognTermLiabilitiesValues
        equity
        retainedEarnings
        retainedEarningsFields
        retainedEarningsValues
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SkillSetsByOrganizationID = /* GraphQL */ `
  query SkillSetsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSkillSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SkillSetsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostBySlug = /* GraphQL */ `
  query GetPostBySlug(
    $slug: String!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostBySlug(
      slug: $slug
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        type
        content
        summary
        state
        seotitle
        seodescription
        seokeywords
        imageLink
        ogImage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const PostsByOrganizationID = /* GraphQL */ `
  query PostsByOrganizationID(
    $organizationID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PostsByOrganizationID(
      organizationID: $organizationID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        type
        content
        summary
        state
        seotitle
        seodescription
        seokeywords
        imageLink
        ogImage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
