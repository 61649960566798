import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API, Auth } from "aws-amplify";
import log from "loglevel";

export const hookAddComment = async (comment) => {
  log.debug(" hookAddComment : ", comment);

  try {
    const result = await API.graphql({
      query: mutations.createTaskComment,
      variables: {
        input: comment,
      },
    });

    log.debug(" hookAddComment result: ", result);

    return result?.data?.createTaskComment;
  } catch (err) {
    log.debug("CATCH ERROR hookAddComment result: ", err);
    return null;
  }
};

export const hookUpdateComment = async (Task) => {
  log.debug(" hookUpdateComment : ", Task);
  try {
    const result = await API.graphql({
      query: mutations.updateTaskComment,
      variables: {
        input: Task,
      },
    });

    log.debug(" hookUpdateComment result: ", result);
    return result?.data?.updateTaskComment;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateComment result: ", err);
  }
};

export const hookDeleteCommentByTaskId = async (TaskId) => {
  log.debug(" hookDeleteCommentByTaskId  ", TaskId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteTaskComment,
      variables: {
        input: { id: TaskId },
      },
    });

    log.debug(" hookDeleteCommentByTaskId : ", data);
    if (data !== null) return data?.data?.deleteTaskComment;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteCommentByTaskId -- ", e);
    return null;
  }
};

export const hookListCommentsByTaskID = async (currentTaskID) => {
  try {
    const data = await API.graphql({
      query: queries.taskCommentsByTaskID,
      variables: { taskID: currentTaskID },
    });

    log.debug(" hookListCommentsByTaskID : ", data);

    if (data !== null) return data?.data?.taskCommentsByTaskID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCommentsByTaskID -- ", e);
    return null;
  }
};
