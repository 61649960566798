import React, { useMemo, useState } from "react";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import {
  ScrollArea,
  ScrollBar,
} from "../../../components/CustomComponentsLibrary/ScrollArea";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { useSelector } from "@xstate/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
export function TopTeamMembers() {
  const { dashboardData } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const { teamMemberGoalsStatuses, teamMemberTasksStatuses } = dashboardData;
  const [selectedValue, setSelectedValue] = useState("Top Team Member Tasks");
  const employeeMachineService = useEmployeeContextService();
  const isTopTeamMemberLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );
  const top5TeamMembersForTasks = useMemo(() => {
    return teamMemberTasksStatuses
      .sort(
        (a, b) =>
          parseFloat(Object.values(b)[0].avgProgress) -
          parseFloat(Object.values(a)[0].avgProgress)
      )
      .slice(0, 5);
  }, [teamMemberTasksStatuses]);
  const top5TeamMembersForGoals = useMemo(() => {
    return teamMemberGoalsStatuses
      .sort(
        (a, b) =>
          parseFloat(Object.values(b)[0].avgProgress) -
          parseFloat(Object.values(a)[0].avgProgress)
      )
      .slice(0, 5);
  }, [teamMemberGoalsStatuses]);
  const convertedDataForTasks = top5TeamMembersForTasks?.map((item) => {
    const key = Object.keys(item)[0];
    const value = item[key];

    return {
      name: key,
      ...value,
    };
  });
  const convertedDataForGoals = top5TeamMembersForGoals?.map((item) => {
    const key = Object.keys(item)[0];
    const value = item[key];

    return {
      name: key,
      ...value,
    };
  });

  const columnsGoals = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Total Goal",
      accessorKey: "totalGoals",
    },
    {
      header: "Completed",
      accessorKey: "completedGoals",
    },
    {
      header: "Pending",
      accessorKey: "inProgressToDoGoals",
      cell: ({ row }) => (
        <>
          {row.original.inProgressToDoGoals > 0 ? (
            <div className="text-danger">
              {row.original.inProgressToDoGoals}{" "}
            </div>
          ) : (
            <div>{row.original.inProgressToDoGoals}</div>
          )}
        </>
      ),
    },
    {
      header: "Completion %",
      accessorKey: "avgProgress",
    },
  ];

  const columnsTasks = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Total Task",
      accessorKey: "totalTasks",
    },
    {
      header: "Completed",
      accessorKey: "completedTasks",
    },
    {
      header: "Pending",
      accessorKey: "inProgressToDoTasks",
      cell: ({ row }) => (
        <>
          {row.original.inProgressToDoTasks > 0 ? (
            <div className="text-danger">
              {row.original.inProgressToDoTasks}{" "}
            </div>
          ) : (
            <div>{row.original.inProgressToDoTasks}</div>
          )}
        </>
      ),
    },
    {
      header: "Completion %",
      accessorKey: "avgProgress",
    },
  ];
  return (
    <>
      <div className="col-span-full  text-lg shadow-lg rounded-md border-t border-t-zinc-100 h-96 sm:h-80">
        <div className="px-2 py-2 m-2">
          <span className="font-semibold">{selectedValue}</span>
          <span className="float-right ">
            <select
              id="myDropdown"
              value={selectedValue}
              onChange={(e) => {
                setSelectedValue(e.target.value);
              }}
              className="border px-3 py-1 mb-2 text-sm  rounded-md "
            >
              <option value="Top Team Member Goals">By Goals</option>
              <option value="Top Team Member Tasks">By Tasks</option>
            </select>
          </span>
        </div>
        <div className="text-sm font-bold rounded-none px-5 border-none text-foreground">
          <ScrollArea className="h-64 w-full">
            {selectedValue == "Top Team Member Goals" ? (
              <DataTable
                columns={columnsGoals}
                data={convertedDataForGoals}
                dataTableToolBar={false}
                loader={isTopTeamMemberLoading}
                customCss={`${theme?.border}`}
                customWidth={[
                  {
                    index: 0,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 1,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 2,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 3,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 4,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                ]}
              />
            ) : selectedValue == "Top Team Member Tasks" ? (
              <DataTable
                columns={columnsTasks}
                data={convertedDataForTasks}
                dataTableToolBar={false}
                loader={isTopTeamMemberLoading}
                customCss={`${theme?.border}`}
                customWidth={[
                  {
                    index: 0,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 1,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 2,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 3,
                    widthClass: "w-48",
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                  {
                    index: 4,
                    cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                  },
                ]}
              />
            ) : (
              <div></div>
            )}

            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </div>
    </>
  );
}
function getColorByAlphabet(str) {
  const firstLetter = str?.charAt(0)?.toUpperCase();
  let color;

  switch (firstLetter) {
    case "A":
    case "B":
    case "C":
    case "D":
    case "E":
      color = "#ff1744";
      break;
    case "F":
    case "G":
    case "H":
    case "I":
    case "J":
      color = "#651fff";
      break;
    case "K":
    case "L":
    case "M":
    case "N":
    case "O":
      color = "#00e676";
      break;
    case "P":
    case "Q":
    case "R":
    case "S":
    case "T":
      color = "#2979ff";
      break;
    case "U":
    case "V":
    case "W":
    case "X":
    case "Y":
    case "Z":
      color = "#f50057";
      break;
    default:
      color = "#000000";
      break;
  }

  return color;
}
