import ForgotFormComponent from "./register/ForgotFormComponent";
import ConfirmCodeNewPassword from "./register/ConfirmCodeNewPassword";
import { useAuthService } from "../customHooks/authMachineHooks";
import { useActor } from "@xstate/react";
import log from "loglevel";

export default function ForgotPasswordComponent() {
  const authService = useAuthService();
  const [state, send] = useActor(authService);

  log.debug("FoirgotPASS");

  if (
    state.matches({
      SIGNIN: {
        SIGNIN_USING_EMAIL: { FORGOT_PASSWORD_STATE: "FORGOT_PASSWORD" },
      },
    })
  )
    return (
      <>
        <ForgotFormComponent />
      </>
    );
  if (
    state.matches({
      SIGNIN: {
        SIGNIN_USING_EMAIL: {
          FORGOT_PASSWORD_STATE: "FORGOT_PASSWORD_CODE_SENT",
        },
      },
    })
  )
    return (
      <>
        <ConfirmCodeNewPassword />
      </>
    );
}
