import React from "react";
import { useEmployeeContextData } from "../../../../hooks/employeeMachineHooks";

const Blogs = () => {
  const {  blogsData ,selectedBlogToEdit} = useEmployeeContextData();


  return (
    <div className="py-6">
      {/* {selectedBlogToEdit?<> <EditBlog/></>:<> 
       {blogsData?.items?.map((item) => (
        <BlogListItem key={item?.id} {...item} />
     
      ))}</>} */}
    
    </div>
  );
};

export default Blogs;
