import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddTopic = async (Topic) => {
  log.debug(" hookAddTopic : ", Topic);

  try {
    const result = await API.graphql({
      query: mutations.createTopic,
      variables: {
        input: Topic,
      },
    });

    log.debug(" hookAddTopic result: ", result);

    return result?.data?.createTopic;
  } catch (err) {
    log.debug("CATCH ERROR hookAddTopic result: ", err);
    return null;
  }
  //Destructuring Assignment in ES6
  //setTodos([...todos, result.data.createTodo]);
};

export const hookUpdateTopic = async (Topic) => {
  log.debug(" hookUpdateTopic : ", Topic);
  try {
    const result = await API.graphql({
      query: mutations.updateTopic,
      variables: {
        input: Topic,
      },
    });

    log.debug(" hookUpdateTopic result: ", result);
    return result?.data?.updateTopic;
  } catch (err) {
    log.debug("CATCH ERROR hookAddUser result: ", err);
  }
};

export const hookGetTopicById = async (id) => {
  log.debug(" hookGetTopicById  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getTopic,
      variables: {
        id: id,
      },
    });

    log.debug(" hookGetTopicById : ", data);
    if (data !== null) return data?.data?.getTopic;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTopicByCognitoId -- ", e);
    return null;
  }
};

export const hookListTopics = async () => {
  //Connect Client Amplify GraphQL
  //TODO: add filter to get Topic by restaurent Id
  try {
    const result = await API.graphql({
      query: queries.listTopics,
      variables: {},
    });

    log.debug(" hooklistTopic : ", result);

    if (result !== null) return result?.data?.listTopics?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hooklistTopic ", err);
  }
};

export const hookGetTopicsByLessonId = async (lessonId) => {
  //Connect Client Amplify GraphQL
  //TODO: add filter to get Topic by restaurent Id
  try {
    const result = await API.graphql({
      query: queries.lessonTopicsByLessonID,
      variables: {
        lessonID: lessonId,
        sortDirection: "ASC",
      },
    });

    log.debug(" hooklistTopic : ", result);

    if (result !== null) return result?.data?.lessonTopicsByLessonID?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hooklistTopic ", err);
  }
};

export const hookDeleteTopicById = async (topicId) => {
  log.debug(" hookGetTopicById  ", topicId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteTopic,
      variables: {
        input: { id: topicId },
      },
    });

    log.debug(" hookDeleteTopicById : ", data);
    if (data !== null) return data?.data?.deleteTopic;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteTopicByCognitoId -- ", e);
    return null;
  }
};
