const isValidDomain = require("is-valid-domain");

function splitMulti(str, tokens) {
  var tempChar = tokens[0]; // We can use the first token as a temporary join character
  for (var i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  str = str.split(tempChar);
  return str;
}
export function isEmailAllowed(email) {
  //log.debug('isEmailAllowed ++ ', email)
  var isValid = false;
  let validDomain = splitMulti(email, ["@"]);

  if (validDomain.length > 1) {
    isValid = isValidDomain(validDomain[1]);

    //log.debug('Domain Name is : ', isValid)
    //log.debug('Domain Name is : ', isValid)
  }
  let emailSplit = splitMulti(email, ["@", "."]);
  //log.debug('Email Updated is : ', emailSplit)

  var personalEmails = emailSplit.includes("gmail");

  if (!personalEmails) {
    personalEmails = emailSplit.includes("yahoo");
  }
  if (!personalEmails) {
    personalEmails = emailSplit.includes("yahoomail");
  }
  if (!personalEmails) {
    personalEmails = emailSplit.includes("outlook");
  }
  if (!personalEmails) {
    personalEmails = emailSplit.includes("rediff");
  }
  if (!personalEmails) {
    personalEmails = emailSplit.includes("rediffmail");
  }

  if (personalEmails) {
    //log.debug('Email Updated is : ', personalEmails)

    return {
      isValid: false,
      error: "Personal Emails are not allowed, please use your company email",
      //        'Personal Emails gmail, yahoo, outlook, rediff are not allowed, please use your company email',
    };
  }
  if (!isValid) {
    //log.debug('Email Updated is : ', personalEmails)

    return {
      isValid: false,
      error: "Domain Name not valid",
    };
  }

  return {
    isValid: true,
    error: "Valid",
  };
}

export function isEmailCommon(email) {
  let personalEmails = false;
  personalEmails = email.includes("gmail");
  if (!personalEmails) {
    personalEmails = email.includes("yahoo");
  }
  if (!personalEmails) {
    personalEmails = email.includes("yahoomail");
  }
  if (!personalEmails) {
    personalEmails = email.includes("outlook");
  }
  if (!personalEmails) {
    personalEmails = email.includes("rediff");
  }
  if (!personalEmails) {
    personalEmails = email.includes("rediffmail");
  }
  return personalEmails;
}
