import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { API } from "aws-amplify";
import {
  isDateBetween,
  getWorkingDaysOfWeek,
  getFirstAndLastDateTimeOfWeekArray,
} from "../lib/dateService";
import log from "loglevel";
import moment from "moment";

export const hookAddAttendance = async (Attendance) => {
  log.debug(" hookAddAttendance : ", Attendance);

  try {
    const result = await API.graphql({
      query: mutations.createAttendance,
      variables: {
        input: Attendance,
      },
    });

    log.debug(" hookAddAttendance result: ", result);

    return result?.data?.createAttendance;
  } catch (err) {
    log.debug("CATCH ERROR hookAddAttendance result: ", err);
    return null;
  }
};

export const hookUpdateAttendance = async (Attendance) => {
  log.debug(" hookUpdateAttendance : ", Attendance);
  try {
    const result = await API.graphql({
      query: mutations.updateAttendance,
      variables: {
        input: Attendance,
      },
    });

    log.debug(" hookUpdateAttendance result: ", result);
    return result?.data?.updateAttendance;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateAttendance result: ", err);
  }
};

export const hookDeleteAttendanceByAttendanceId = async (AttendanceId) => {
  log.debug(" hookDeleteAttendanceByAttendanceId  ", AttendanceId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteAttendance,
      variables: {
        input: { id: AttendanceId },
      },
    });

    log.debug(" hookDeleteAttendanceByAttendanceId : ", data);
    if (data !== null) return data?.data?.deleteAttendance;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeleteAttendanceByAttendanceUniqueID -- ",
      e
    );
    return null;
  }
};

export const hookListAttendancesByUserId = async (uniqueId) => {
  log.debug(" hookListAttendancesByUserId uniqueId", uniqueId);
  try {
    const data = await API.graphql({
      query: queries.AttendanceByUserID,
      variables: {
        sortDirection: "DESC",
        userID: uniqueId,
        //  userID: "b103c472-afa3-46f3-9724-486343dd9bd8",
      },
    });

    log.debug(" hookListAttendancesByUserId : ", data);

    if (data !== null) return data?.data?.AttendanceByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListAttendancesByUserId -- ", e);
    return null;
  }
  //Connect Client Amplify GraphQL
  //TODO LIST BY EMPLOYEE ID
};

export const hookListAttendances = async () => {
  try {
    const data = await API.graphql({
      query: queries.listAttendances,
      variables: {},
    });

    log.debug(" hookListAttendancesByUserId : ", data);

    if (data !== null) return data?.data?.listAttendances.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListAttendancesByUserId -- ", e);
    return null;
  }
};

export const hookgetAttendance = async (id) => {
  log.debug(" hookgetAttendance : ", id);
  try {
    const data = await API.graphql({
      query: queries.getAttendance,
      variables: {
        id: id,
      },
    });

    log.debug(" hookgetAttendance return: ", data);

    if (data !== null) return data?.data?.getAttendance.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookgetAttendance -- ", e);
    return null;
  }
};

export const hookgetAttendanceHoursByUserId = async (weekType, cognitoId) => {
  log.debug(" hookgetAttendanceHoursByUserId uniqueId", weekType, cognitoId);
  try {
    const data = await API.graphql({
      query: customQueries.attendanceHoursByUserID,
      variables: {
        // sortDirection: "DESC",
        userID: cognitoId,
      },
    });
    // log.debug("data::hookgetAttendanceHoursByUserId", data);
    const weekDays = getWorkingDaysOfWeek(weekType);
    const { firstDay, lastDay } = getFirstAndLastDateTimeOfWeekArray(weekDays);
    const attendances = data?.data?.AttendanceByUserID.items;
    let weekAttendance = [];
    if (attendances) {
      weekAttendance = attendances.filter((attendance) => {
        return isDateBetween(firstDay, lastDay, new Date(attendance.timeIn1));
      });
    }

    weekAttendance.sort(function (a, b) {
      return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
    });
    log.debug(" hookgetAttendanceHoursByUserId : ", weekAttendance);
    let workingHoursForWeek = weekDays.map((weekDay) => {
      let attForDay = weekAttendance.find(
        (attendance) =>
          moment(new Date(attendance.timeIn1)).format("YYYY-MM-DD") ===
          moment(weekDay).format("YYYY-MM-DD")
      );
      let toRet = attForDay?.totalHours || 0;
      return parseFloat(toRet);
    });

    // log.debug(" hookgetAttendanceHoursByUserId : ", workingHoursForWeek);

    if (data !== null) return workingHoursForWeek;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookgetAttendanceHoursByUserId -- ", e);
    return null;
  }
};

export const hookAttendanceByUserID = async (userid) => {
  log.debug(" hookAttendanceByUserID : ", userid);
  try {
    const data = await API.graphql({
      query: queries.AttendanceByUserID,
      variables: {
        sortDirection: "DESC",
        userID: userid,
      },
    });

    log.debug(" hookAttendanceByUserID return: ", data);

    if (data !== null) return data?.data?.AttendanceByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookAttendanceByUserID -- ", e);
    return null;
  }
};

export const hookListEmployeeAttendanceByMonth = async (
  cognitoId,
  firstDay,
  lastDay
) => {
  log.debug("hookListEmployeeAttendanceByMonth", cognitoId, firstDay, lastDay);
  try {
    const data = await API.graphql({
      query: queries.AttendanceByUserID,
      variables: {
        sortDirection: "DESC",
        userID: cognitoId,
        date: { between: [firstDay, lastDay] },
      },
    });
    log.debug("data::hookListEmployeeAttendanceByMonth", data);

    if (data !== null) return data?.data?.AttendanceByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeAttendanceByMonth -- ", e);
    return null;
  }
  //Connect Client Amplify GraphQL
  //TODO LIST BY EMPLOYEE ID
};
