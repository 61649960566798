import { useState, useEffect, useMemo, Fragment } from "react";
import log from "loglevel";
import { User } from "../../../components/common/User";

import {
  useEmployeeContextState,
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../hooks/employeeMachineHooks";
import useForm from "../../../customHooks/useForm";
import { CONST_TASK_STATUS } from "../../../constants/dbconstants";
import { hookListCommentsByTaskID } from "../../../hooks/hookTaskComments";
import { TaskComments } from "../../../components/common/TaskComments";
import Dropdown from "../../../components/common/Dropdown";
import {
  DialogBox,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/CustomComponentsLibrary/Dailog";
import { Input } from "../../../components/CustomComponentsLibrary/Input";
import { Button } from "../../../components/CustomComponentsLibrary/Button";
import { Label } from "../../../components/CustomComponentsLibrary/Label";
import { Textarea } from "../../../components/CustomComponentsLibrary/Textarea";
import { ScrollArea } from "../../../components/CustomComponentsLibrary/ScrollArea";
import { Separator } from "../../../components/CustomComponentsLibrary/Separator";
import { X } from "lucide-react";

import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import moment from "moment";
import { sortByKeyAlphabetical } from "../../common/SortByName";
import { useToast } from "../../../components/CustomComponentsLibrary/UseToast";

export default function AddEditDeleteTask() {
  const { taskCrud } = useEmployeeContextState();

  const {
    actionForTaskCRUD,
    selectedProjectId,
    selectedTask,
    projects,
    teamMembersNames,
    myEmployeeProfile,
  } = useEmployeeContextData();
  const [comment2Save, setComment2Save] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [comments, setComments] = useState([]);

  const [totalHours, setTotalHours] = useState("");
  const [selectedProjectData, setSelectedProjectData] = useState();
  const [dialogOpen] = useState(taskCrud === true ? true : false);
  const { toast } = useToast();
  const handleClose = () => {
    employeeMachineService.send({ type: "cancel_crud" });
  };

  const employeeMachineService = useEmployeeContextService();
  const { theme } = useAuthContextData();
  const selectedProject = projects?.find(
    (item) => item.id == selectedProjectId
  );
  const filteredTeamMembers = useMemo(() => {
    if (
      teamMembersNames &&
      myEmployeeProfile?.employees &&
      (myEmployeeProfile?.userType.includes("TEAMLEAD") ||
        myEmployeeProfile?.userType?.includes("MANAGER"))
    ) {
      const filteredMembers = teamMembersNames?.filter(
        (member) =>
          myEmployeeProfile?.employees?.includes(member?.id) ||
          selectedProject?.teammembers?.includes(member?.id) // Added new extra line for getting teammembers from respective project.
      );

      return filteredMembers.map((member) => ({
        id: member.id,
        title: member.title,
      }));
    }
    return null;
  }, [teamMembersNames, myEmployeeProfile?.employees]);
  const params = useParams();
  const projectId = params?.projectId?.toLowerCase();
  const user = teamMembersNames?.find(
    (u) => u?.id === selectedTask?.assignedTo
  );
  // const user = filteredTeamMembers?.find(
  //   (u) => u.id === selectedTask?.assignedTo)

  var teamMember = [];
  if (selectedProjectData) {
    // Added new extra code for getting teammembers from respective project while editing task.
    const filteredMembers = teamMembersNames?.filter(
      (member) => selectedProjectData?.teammembers?.includes(member?.id) 
    );
    teamMember = filteredMembers.map((member) => ({
      id: member.id,
      title: member.title,
    }));
  } else if (selectedProject) {
    teamMember = filteredTeamMembers?.filter((item) =>
      selectedProject?.teammembers?.includes(item.id)
    );
  }
  const [assigneTo, setAssignedTo] = useState(user);
  const { inputs, handleChange, clearForm, resetForm } = useForm({
    title: selectedTask ? selectedTask.title : "",
    content: selectedTask ? selectedTask.content : "",
    dueDate: selectedTask ? selectedTask.dueDate : "",
    actualCompletedDate: selectedTask ? selectedTask.actualCompletedDate : null,
    billableHours: selectedTask ? selectedTask.billableHours : null,
  });

  const taskStatus = [
    { name: CONST_TASK_STATUS.taskStatusCreated, label: "Created" },
    { name: CONST_TASK_STATUS.taskStatusInprogress, label: "In Progress" },
    { name: CONST_TASK_STATUS.taskStatusOnhold, label: "On Hold" },
    { name: CONST_TASK_STATUS.taskStatusDone, label: "Closed" },
    { name: CONST_TASK_STATUS.taskStatusDoneApproved, label: "Delivered" },
  ];
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(
    taskStatus?.find((element) => element.name === selectedTask?.status) || null
  );
  useEffect(() => {
  }, [comment2Save]);

  useEffect(() => {
    async function getTaskComments() {
      const result = await hookListCommentsByTaskID(selectedTask?.id);
      setComments(result);
    }
    if (taskCrud) {
      if (actionForTaskCRUD === "EDIT") {
        getTaskComments();
      }
    }
  }, []);

  useEffect(() => {
    if (inputs.startTime && inputs.endTime) {
      const startParts = inputs?.startTime.split(":");
      const endParts = inputs.endTime.split(":");
      // Parse hours and minutes as integers
      const startHours = parseInt(startParts[0], 10);
      const startMinutes = parseInt(startParts[1], 10);
      const endHours = parseInt(endParts[0], 10);
      const endMinutes = parseInt(endParts[1], 10);
      // Calculate the total hours and minutes
      const totalHours =
        endHours - startHours + (endMinutes - startMinutes) / 60;
      setTotalHours(totalHours.toFixed(2));
    }
  }, [inputs.startTime, inputs.endTime]);

  const filteredProject = useMemo(() => {
    const result = projects?.find(
      (project) => project.id === selectedTask?.projectID
    );
    if (result) {
      setSelectedProjectData(result);
    }
  }, [projectId, projects, selectedTask]);

  const submitTaskNow = async (e) => {
    e.preventDefault();
    var percentage = 0;
    if (selectedTaskStatus?.name === "TASKSTATUS_DONE_APPROVED") {
      percentage = 100;
    } else {
      percentage = (selectedTask?.billedHours * 100) / inputs?.billableHours;
    }

    if (percentage === null || undefined) {
      percentage = 0;
    }

    if (selectedTask) {
      var task2Add;
      if (
        selectedTask?.billableHours > inputs?.billableHours &&
        selectedTask?.billedHours > 0
      ) {
        const task2Add = {
          ...inputs,
          id: selectedTask?.id,
          status: selectedTaskStatus?.name,
          assignedTo: assigneTo?.id,
          userID: assigneTo?.id,
          percentage: percentage.toFixed(0),
        };
      } else {
        var extraHours;
        var consumedHours;
        let difference =
          inputs?.billableHours -
          (selectedTask?.billedHours + selectedTask?.billedUnbillableHours);

        // Check if the difference is negative
        if (difference < 0) {
          extraHours = Math.abs(difference);
          consumedHours = inputs?.billableHours;
        } else {
          extraHours = 0;
          consumedHours =
            selectedTask?.billedHours + selectedTask?.billedUnbillableHours;
        }
        task2Add = {
          ...inputs,
          id: selectedTask?.id,
          assignedTo: assigneTo?.id,
          userID: assigneTo?.id,
          status: selectedTaskStatus?.name,
          percentage: percentage.toFixed(0),
          billedUnbillableHours: extraHours,
          billedHours: consumedHours,
        };
        // if (
        //   inputs?.billableHours > selectedTask?.billableHours &&
        //   selectedTask?.billedUnbillableHours > 0
        // ) {
        //   toast({
        //     variant: "success",
        //     title: "After updating assigned hours",
        //     description: `For updated assigned hours these hours: ${
        //       consumedHours - selectedTask?.billedHours
        //     } added to billed hours from extra hours`,
        //   });
        // }
      }
      if (
        selectedTask?.billableHours > inputs?.billableHours &&
        selectedTask?.billedHours > 0
      ) {
        toast({
          variant: "destructive",
          title: "Failed",
          description: `Assigned hours should not less than ${selectedTask?.billableHours}`,
        });
      } else {
        employeeMachineService.send({
          type: "update_task",
          data: {
            taskToBeAdded: task2Add,
          },
        });
      }
    } else {
      employeeMachineService.send({
        type: "create_task",
        data: {
          taskToBeAdded: {
            ...inputs,
            assignedTo: assigneTo?.id,
            assignedBy: myEmployeeProfile?.cognitoId,
            status: CONST_TASK_STATUS?.taskStatusCreated,
            projectID: selectedProject?.id,
            userID: assigneTo?.id,
            percentage: 0,
            dueDate: moment(inputs?.dueDate).format("YYYY-MM-DD"),
          },
        },
      });
    }
  };
  const today = new Date().toISOString().split("T")[0]; // get current date in yyyy-mm-dd format
  const submitTaskUpdate = async () => {
    var percentage = 0;
    if (selectedTaskStatus?.name === "TASKSTATUS_DONE_APPROVED") {
      percentage = 100;
    } else {
      percentage =
        (selectedTask?.billedHours * 100) / selectedTask?.billableHours;
    }
    if (percentage === null || undefined) {
      percentage = 0;
    }
    employeeMachineService.send({
      type: "update_task",
      data: {
        taskToBeAdded: {
          id: selectedTask?.id,
          status: selectedTaskStatus?.name,
          percentage: percentage.toFixed(0),
        },
      },
    });
  };

  if (taskCrud) {
    switch (actionForTaskCRUD) {
      case "DELETE":
        return (
          <Transition.Root show={taskCrud} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                employeeMachineService.send({ type: "cancel_crud" });
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <Button
                          type="submit"
                          size="xs"
                          variant="darkOutline"
                          className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          <X className="h-4 w-4 text-" />
                          <span className="sr-only">Close</span>
                        </Button>
                      </div>
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-semoibold leading-6 text-foreground"
                          >
                            Are you absolutely sure?
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete the task?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => {
                            employeeMachineService.send({
                              type: "delete_task",
                            });
                          }}
                        >
                          Continue
                        </Button>
                        <Button
                          type="button"
                          className="mr-2"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        );

      case "UPDATE":
        return (
          <DialogBox open={dialogOpen} onOpenChange={handleClose}>
            <DialogContent className="max-w-[100%] sm:max-w-[70%]  p-3 mt-10  sm:ml-10 sm:mr-10">
              <Button
                size="xs"
                variant="darkOutline"
                className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                onClick={() => {
                  employeeMachineService.send({ type: "cancel_crud" });
                }}
              >
                <X className="h-4 w-4 text-" />
                <span className="sr-only">Close</span>
              </Button>

              <ScrollArea className="lg:h-[500px] xl:h-[630px] md:h-96">
                <form onSubmit={submitTaskNow} method="POST">
                  <div className="flex flex-col p-1">
                    <div className="flex-row w-full">
                      <Label htmlFor="Title" className="text-right text-base">
                        Task Name:
                      </Label>
                    </div>
                    <div className="text-2xl">{selectedTask?.title}</div>
                    <div className="flex-row w-full">
                      <Label htmlFor="Title" className="text-right text-base">
                        Status
                      </Label>
                      <Dropdown
                        list={taskStatus}
                        setSelected={setSelectedTaskStatus}
                        selected={selectedTaskStatus}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-3 justify-start mx-3 my-1">
                    <div>
                      Assigned To:
                      <User userID={selectedTask?.assignedTo} />
                    </div>
                    <div>
                      Assigned By:
                      <User userID={selectedTask?.assignedBy} />
                    </div>
                  </div>
                  <div className="flex flex-row mt-2">
                    <div className="relative pt-1 grow m-2">
                      <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                        <div
                          style={{
                            width: `${selectedTask?.percentage}%`,
                          }}
                          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500`}
                        />
                      </div>
                      <p className="text-foreground text-xs mt-1">{`${selectedTask?.percentage}% completed`}</p>
                    </div>
                    <div>
                      <Button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={submitTaskUpdate}
                      >
                        Update Status
                      </Button>
                    </div>
                  </div>
                  <div className="ml-1">Description:</div>
                  <div className="mt-2 w-full bg-background border rounded-xl text-sm font-light p-3 mr-4 ">
                    {selectedTask?.content}
                  </div>
                </form>
                <div>
                  <Separator className="my-4" />
                  <TaskComments taskId={selectedTask?.id} />
                </div>
              </ScrollArea>
            </DialogContent>
          </DialogBox>
        );
      case "EDIT":

      case "ADD":
        return (
          <DialogBox open={dialogOpen} onOpenChange={handleClose}>
            <DialogContent className="max-w-[100%] sm:max-w-[70%]  p-3 mt-10  sm:ml-10 sm:mr-10">
              <ScrollArea className="h-96 sm:h-[600px]  md:h-[600px] lg:h-[600px]  xl:h-[700px]">
                <DialogHeader>
                  <DialogTitle>
                    {selectedTask ? "Edit Task For : " : "Add Task For : "}
                    {selectedTask?.title}
                  </DialogTitle>
                  <div className="absolute top-0 right-0   pt-4 pr-4 sm:block">
                    <Button
                      size="xs"
                      variant="darkOutline"
                      className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                      onClick={() => {
                        employeeMachineService.send({ type: "cancel_crud" });
                      }}
                    >
                      <X className="h-4 w-4 " />
                      <span className="sr-only">Close</span>
                    </Button>
                  </div>
                  <DialogDescription>
                    Make changes to your task here. Click save when you're done.
                  </DialogDescription>
                </DialogHeader>

                <form onSubmit={submitTaskNow} method="POST">
                  <div className="flex flex-col p-2 ">
                    <div className="flex-row w-full ">
                      <Label htmlFor="Title" className="text-right text-text">
                        Projects
                      </Label>

                      <Input
                        defaultValue={
                          selectedProjectData?.title
                            ? selectedProjectData?.title
                            : selectedProject?.title
                        }
                        type="text"
                        onChange={handleChange}
                        name="projects"
                        id="projects"
                        placeholder="projects"
                        readOnly={true}
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>
                    <div className="flex-row w-full pt-2">
                      <Label htmlFor="Title" className="text-right">
                        Task Name
                      </Label>
                      <Textarea
                        placeholder="Add a Task Title Here"
                        required
                        defaultValue={inputs?.title}
                        type="text"
                        onChange={handleChange}
                        name="title"
                        id="title"
                        className={`${theme?.border}`}
                        // readOnly={selectedTask}
                      />
                    </div>
                    {(myEmployeeProfile?.userType.includes("TEAMLEAD") ||
                      myEmployeeProfile?.userType?.includes("MANAGER")) && (
                      <div className="flex-row w-full">
                        <Label htmlFor="Title" className="text-right">
                          Assigned To
                        </Label>
                        <Dropdown
                          selected={assigneTo}
                          setSelected={setAssignedTo}
                          list={sortByKeyAlphabetical(teamMember, "title")}
                          nameField="title"
                        />
                      </div>
                    )}

                    <div className=" flex-row w-full  pt-2">
                      <Label htmlFor="content" className="text-right">
                        Content
                      </Label>

                      <Textarea
                        defaultValue={inputs?.content}
                        type="text"
                        onChange={handleChange}
                        name="content"
                        id="content"
                        placeholder="content"
                        maxLength={150}
                        className={`${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row w-full  pt-2">
                      <Label htmlFor="content" className="text-right">
                        Billable Hours
                      </Label>
                      <Input
                        defaultValue={selectedTask?.billableHours}
                        type="number"
                        onChange={handleChange}
                        name="billableHours"
                        id="billableHours"
                        placeholder="Billable Hours"
                        readOnly={
                          myEmployeeProfile?.userType.includes("TEAMLEAD") ||
                          myEmployeeProfile?.userType?.includes("MANAGER")
                            ? false
                            : true
                        }
                        disabled={
                          myEmployeeProfile?.userType.includes("TEAMLEAD") ||
                          myEmployeeProfile?.userType?.includes("MANAGER")
                            ? false
                            : true
                        }
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                      />
                    </div>
                    <div className=" flex-row w-full  pt-2">
                      <Label htmlFor="content" className="text-right">
                        Unbillable Hours
                      </Label>
                      <Input
                        defaultValue={selectedTask?.unbillableHours}
                        type="number"
                        onChange={handleChange}
                        name="unbillableHours"
                        id="unbillableHours"
                        placeholder="Unbillable Hours"
                        className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        disabled={selectedTask}
                      />
                    </div>
                    {selectedTask ? (
                      <>
                        <div className=" flex-row  pt-2">
                          <Label htmlFor="dueDate" className="text-right">
                            Due Date
                          </Label>
                          <Input
                            disabled={selectedTask}
                            defaultValue={inputs?.dueDate}
                            type="date"
                            onChange={handleChange}
                            name="dueDate"
                            id="dueDate"
                            min={today}
                            className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                          />
                        </div>
                        <div className="flex-row w-full  pt-2">
                          <Label htmlFor="Title" className="text-right">
                            Status
                          </Label>
                          <Dropdown
                            list={sortByKeyAlphabetical(taskStatus, "label")}
                            setSelected={setSelectedTaskStatus}
                            selected={selectedTaskStatus}
                            nameField={"label"}
                          />
                        </div>
                      </>
                    ) : (
                      <div className=" flex-row  pt-2">
                        <Label htmlFor="dueDate" className="text-right">
                          Due Date
                        </Label>
                        <Input
                          required
                          defaultValue={inputs?.dueDate}
                          type="date"
                          onChange={handleChange}
                          name="dueDate"
                          id="dueDate"
                          min={today}
                          className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        />
                      </div>
                    )}
                  </div>

                  <DialogFooter className="pt-2">
                    <Button
                      type="button"
                      onClick={() => {
                        employeeMachineService.send({ type: "cancel_crud" });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button className="bg-background text-text" type="submit">
                      {selectedTask ? "Update" : "Add"}
                    </Button>
                  </DialogFooter>
                </form>

                {selectedTask ? (
                  <>
                    <Separator className="m-4" />
                    <TaskComments taskId={selectedTask?.id} />
                  </>
                ) : null}
              </ScrollArea>
            </DialogContent>
          </DialogBox>
        );
    }
  }
}
