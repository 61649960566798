import log from "loglevel";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { API } from "aws-amplify";

export const hookAddOrgLeave = async (OrgLeave) => {
  log.debug(" hookAddOrgLeave : ", OrgLeave);

  try {
    const result = await API.graphql({
      query: mutations.createOrgLeave,
      variables: {
        input: OrgLeave,
      },
    });

    log.debug(" hookAddOrgLeave result: ", result);

    return result?.data?.createOrgLeave;
  } catch (err) {
    log.debug("CATCH ERROR hookAddOrgLeave result: ", err);
    return null;
  }
};

export const hookUpdateOrgLeave = async (OrgLeave) => {
  log.debug("hookUpdateOrgLeave: ", OrgLeave);
  try {
    const result = await API.graphql({
      query: mutations.updateOrgLeave,
      variables: {
        input: OrgLeave,
      },
    });

    log.debug(" hookUpdateOrgLeave result: ", result);
    return result?.data?.updateOrgLeave;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateOrgLeave result: ", err);
  }
};

export const hookDeleteOrgLeaveByOrgLeaveId = async (OrgLeaveId) => {
  log.debug(" hookDeleteOrgLeaveByOrgLeaveId  ", OrgLeaveId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteOrgLeave,
      variables: {
        input: { id: OrgLeaveId },
      },
    });
    log.debug(" hookDeleteOrgLeaveByOrgLeaveId : ", data);
    if (data !== null) return data?.data?.deleteOrgLeave;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteOrgLeaveByOrgLeaveUniqueID -- ", e);
    return null;
  }
};

export const hookGetOrgLeaveByOrgLeaveId = async (OrgLeaveId) => {
  log.debug("  hookGetOrgLeaveByOrgLeaveId ", OrgLeaveId);

  try {
    const data = await API.graphql({
      query: queries.getOrgLeave,
      variables: {
        input: { id: OrgLeaveId },
      },
    });

    log.debug(" hookGetOrgLeaveByOrgLeaveId : ", data);
    if (data !== null) return data?.data?.getOrgLeave;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetOrgLeaveByOrgLeaveId -- ", e);
    return null;
  }
};
export const hookListOrgLeavesByOrgId = async (orgId) => {
  log.debug("hookListOrgLeavesByOrgId OrgId", orgId);
  try {
    const data = await API.graphql({
      query: queries.OrgLeavesByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" hookListOrgLeavesByOrgId : ", data);
    if (data !== null) return data?.data?.OrgLeavesByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListOrgLeavesByOrgId -- ", e);
    return null;
  }
};

export const hookListOrgLeavesByYearId = async (yearId) => {
  log.debug("hookListOrgLeavesByYearId OrgId", yearId);
  try {
    const data = await API.graphql({
      query: queries.OrgLeavesByYear,
      variables: {
        orgYearLeavesID: yearId,
      },
    });
    log.debug(" hookListOrgLeavesByYearId : ", data);
    if (data !== null) return data?.data?.OrgLeavesByYear.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListOrgLeavesByYearId -- ", e);
    return null;
  }
};
