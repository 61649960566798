import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddGoalComment = async (comment) => {
  log.debug(" hookAddGoalComment : ", comment);

  try {
    const result = await API.graphql({
      query: mutations.createGoalComment,
      variables: {
        input: comment,
      },
    });

    log.debug(" hookAddGoalComment result: ", result);

    return result?.data?.createGoalComment;
  } catch (err) {
    log.debug("CATCH ERROR hookAddGoalComment result: ", err);
    return null;
  }
};

export const hookUpdateGoalComment = async (Goal) => {
  log.debug(" hookUpdateGoalComment : ", Goal);
  try {
    const result = await API.graphql({
      query: mutations.updateGoalComment,
      variables: {
        input: Goal,
      },
    });

    log.debug(" hookUpdateGoalComment result: ", result);
    return result?.data?.updateGoalComment;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateGoalComment result: ", err);
  }
};

export const hookDeleteCommentByGoalId = async (GoalId) => {
  log.debug(" hookDeleteCommentByGoalId  ", GoalId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteGoalComment,
      variables: {
        input: { id: GoalId },
      },
    });

    log.debug(" hookDeleteCommentByGoalId : ", data);
    if (data !== null) return data?.data?.deleteGoalComment;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteCommentByGoalId -- ", e);
    return null;
  }
};

export const hookListCommentsByGoalID = async (currentGoalID) => {
  try {
    const data = await API.graphql({
      query: queries.goalCommentsByGoalID,
      variables: { goalID: currentGoalID },
    });

    log.debug(" hookListCommentsByGoalID : ", data);

    if (data !== null) return data?.data?.goalCommentsByGoalID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCommentsByGoalID -- ", e);
    return null;
  }
};
