import React from "react";
import { TabPageLink } from "../../../components/common/TabPageLink";
import SalarySlip from "../../../components/employee/Payroll/SalarySlip";

const tabs = [
  {
    name: "Salary Slips",
    path: "/employee/payroll",
  },

  {
    name: "Reimbursements",
    path: "/employee/payroll/reimbursements",
  },
  {
    name: "Form 16",
    path: "/employee/payroll/form",
  },

  {
    name: "Bonus",
    path: "/employee/payroll/bonus",
  },
  {
    name: "Others",
    path: "/employee/payroll/others",
  },
];

export function EmployeePayrollLayout() {
  console.log("RoutestoGen", tabs);
  return (
    <>
      <TabPageLink tabs={tabs} />
    </>
  );
}
