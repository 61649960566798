import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import log from "loglevel";
import PageHeader from "../../common/PageHeader";
import { Calendar } from "lucide-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Dropdown from "../../common/Dropdown";
import { sortByKeyAlphabetical } from "../../common/SortByName";
import AddEditByProjectLogHours from "./Loghours/AddEditByProjectLogHours";

export default function LogHoursByProject() {
  const { projects, userID, myEmployeeProfile, teamLeadMembers } =
    useEmployeeContextData();
  const employeeMachineService = useEmployeeContextService();
  const params = useParams();

  const [startDate, setStartDate] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [monthWeeks, setMonthWeeks] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const myProjects = projects?.filter(
    (item) =>
      item?.leadName ===
      myEmployeeProfile?.firstName + " " + myEmployeeProfile?.LastName
  );
  useEffect(() => {
    if (selectedProject) {
      const dataToSend = selectedProject?.teammembers;
      log.debug("dataToSend:::", dataToSend);
      employeeMachineService.send({
        type: "get_employee_list",
        data: {
          teamLeadMembersToUpdate: dataToSend,
        },
      });
    }
  }, [selectedProject]);
  log.debug("teamLeadMembers::", teamLeadMembers);

  const handleDateChange = (date) => {
    log.debug("handleDateChange", date);

    setSelectedWeek(date);

    log.debug("setSelectedWeek", selectedWeek);
  };

  const getStartDatesOfWeeks = (startDate) => {
    if (!startDate) {
      return [];
    }
    setStartDate(startDate);
    const result = [];
    const startOfMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();

    const startOfWeek = new Date(startOfMonth);
    if (startOfWeek?.getDay() !== 1) {
      result.push(new Date(startOfWeek));
      while (startOfWeek?.getDay() !== 1) {
        startOfWeek?.setDate(startOfWeek?.getDate() + 1);
      }
    }
    while (startOfWeek?.getMonth() === startOfMonth?.getMonth()) {
      result?.push(new Date(startOfWeek));
      startOfWeek?.setDate(startOfWeek?.getDate() + 7);
    }
    setMonthWeeks(result);
    if (startDate?.getMonth() === currentMonth) {
      const currentWeekStartDate = getStartOfWeek();
      handleDateChange(currentWeekStartDate);
    } else {
      let defaultDate = startDate;
      if (result?.length) {
        defaultDate = result[0];
      }
      handleDateChange(defaultDate);
    }

    return result;
  };
  const getStartOfWeek = () => {
    return moment().startOf("isoWeek").format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  };

  useEffect(() => {
    employeeMachineService.send({
      type: "reload_projects",
      data: {
        userID: myEmployeeProfile?.cognitoId,
      },
    });
  }, [userID]);

  useEffect(() => {
    const currentDate = new Date();
    log.debug("Projects::currentDate ", currentDate);

    getStartDatesOfWeeks(currentDate);
  }, []);

  useEffect(() => {
    getStartDatesOfWeeks(startDate);
  }, [startDate]);
  log.debug("currentUserDetails:::", currentUserDetails);
  useEffect(() => {
    log.debug("selectedWeek::", selectedWeek);

    if (selectedWeek) {
      const startDate = new Date(selectedWeek);
      const weekDay = startDate?.getDate();
      let startDateDay = startDate.getDay();
      const num = weekDay == 1 ? (startDateDay >= 1 ? 7 - startDateDay : 1) : 6;
      const SelectedWeekData = moment(selectedWeek);
      const selectedYear = SelectedWeekData.year();
      const selectedMonth = SelectedWeekData.month();
      const selectedDate = SelectedWeekData.date();
      const lastDate = SelectedWeekData.date() + num;
      const endOfMonthDate = moment(selectedWeek).endOf("month").date();
      const finalLastDate =
        lastDate > endOfMonthDate ? endOfMonthDate : lastDate;
      const firstDay = moment()
        .year(selectedYear)
        .month(selectedMonth)
        .date(selectedDate)
        .format("YYYY-MM-DD");
      const lastDay = moment()
        .year(selectedYear)
        .month(selectedMonth)
        .date(finalLastDate)

        .format("YYYY-MM-DD");
      log.debug(
        "selectedWeek::firstDay",
        firstDay,
        lastDay,
        selectedDate,
        finalLastDate
      );
      if (selectedProject) {
        const dataToSend = {
          firstDay: firstDay,
          lastDay: lastDay,
          userID: currentUserDetails
            ? currentUserDetails?.cognitoId
            : myEmployeeProfile?.cognitoId,
          selectedProject: selectedProject,
        };
        log.debug("dataToSend::get_employee_log_hours", dataToSend);
        employeeMachineService.send({
          type: "get_employee_log_hours",
          data: {
            taskHourByMonth: dataToSend,
          },
        });
      } else {
        const dataToSend = {
          firstDay: firstDay,
          lastDay: lastDay,
          userID: currentUserDetails
            ? currentUserDetails?.cognitoId
            : myEmployeeProfile?.cognitoId,
        };
        log.debug("dataToSend::get_employee_log_hours", dataToSend);
        employeeMachineService.send({
          type: "get_employee_log_hours",
          data: {
            taskHourByMonth: dataToSend,
          },
        });
      }
    }
  }, [selectedWeek, currentUserDetails, myEmployeeProfile, selectedProject]);

  log.debug("Projects::LoghoursList", projects);
  const handleSelectProject = (item) => {
    setSelectedProject(item);
    setCurrentUserDetails(null);
  };
  return (
    <div className="p-2 mx-auto">
      <div className="flex justify-between items-center">
        <div className="m-3 w-full">
          <PageHeader
            title="Log Hours"
            SubHeading="Select a month to view you log Hours"
          />
        </div>
      </div>

      <div className="flex justify-start w-full">
        <div className="w-full flex  font-semibold text-2xl flex-row">
          <div className="w-full  flex font-semibold text-2xl flex-col ">
            <div className="text-base font-semibold">
              <p className="flex flex-1 pt-5 pb-2">
                <span>Month</span>
              </p>
            </div>
            <div className="w-full  flex  flex-row justify-start">
              <div className="w-full flex  text-base sm:w-96 z-20  h-12">
                <DatePicker
                  wrapperClassName="custom-datepicker"
                  selected={startDate}
                  onChange={(selectedDate) => {
                    setStartDate(selectedDate);
                  }}
                  dateFormat="MMMM-yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="cursor-pointer h-12 p-3  border rounded-md date-input w-full"
                  placeholderText="Select month"
                />
                <Calendar className="relative right-10 m-auto" />
              </div>
              {myEmployeeProfile?.userType.includes("TEAMLEAD") && (
                <div className="w-full  flex  flex-row justify-start">
                  <div>
                    <Dropdown
                      selected={selectedProject}
                      setSelected={handleSelectProject}
                      list={sortByKeyAlphabetical(myProjects, "title")}
                      nameField="title"
                      placeholder="Select Project."
                      // custom="w-40"
                    />
                  </div>
                  <div className="ml-20">
                    <Dropdown
                      list={sortByKeyAlphabetical(teamLeadMembers, "firstName")}
                      setSelected={setCurrentUserDetails}
                      selected={currentUserDetails}
                      className="w-auto"
                      placeholder="Select Team Member"
                      required
                      nameField="firstName"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-lg mt-7 h-full rounded-md border-t border-t-zinc-100">
        <div className="w-full   flex  font-bold flex-row justify-end">
          <div className="w-full text-sm mr-5 mt-5">
            <ul className="flex justify-center space-x-4 flex-col sm:flex-row sm:justify-end">
              <div className="font-semibold">
                <p className="flex flex-wrap sm:m-0 m-3">
                  <span className="text-sm">Week Start Date-</span>
                </p>
              </div>

              {selectedWeek != null ? (
                <>
                  {monthWeeks?.map((startDate, index) => (
                    <li key={index}>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="date"
                          className="form-radio"
                          onChange={() => {
                            handleDateChange(startDate);
                          }}
                          checked={
                            moment(new Date(selectedWeek)).format("DD") ===
                            moment(new Date(startDate)).format("DD")
                          }
                        />
                        <span className="ml-2">
                          {moment(startDate).format("DD/MM/YY")}
                        </span>
                      </label>
                    </li>
                  ))}
                </>
              ) : null}
            </ul>
          </div>
        </div>
        <div className="ml-5 mr-5 p-5 font-semibold">
          <AddEditByProjectLogHours
            week={selectedWeek}
            userToShow={currentUserDetails}
            selectedProject={selectedProject}
          />
        </div>
      </div>
    </div>
  );
}
