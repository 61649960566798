import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";
import * as customQueries from "../graphql/customQueries";

export const hookAddProject = async (Project) => {
  log.debug(" hookAddProject : ", Project);

  try {
    const result = await API.graphql({
      query: mutations.createProject,
      variables: {
        input: Project,
      },
    });

    log.debug(" hookAddProject result: ", result);

    return result?.data?.createProject;
  } catch (err) {
    log.debug("CATCH ERROR hookAddProject result: ", err);
    return null;
  }
};

export const hookUpdateProject = async (Project) => {
  log.debug(" hookUpdateProject : ", Project);
  try {
    const result = await API.graphql({
      query: mutations.updateProject,
      variables: {
        input: Project,
      },
    });

    log.debug(" hookUpdateProject result: ", result);
    return result?.data?.updateProject;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateProject result: ", err);
  }
};

export const hookDeleteProjectByProjectId = async (ProjectId) => {
  log.debug(" hookDeleteProjectByProjectId  ", ProjectId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteProject,
      variables: {
        input: { id: ProjectId },
      },
    });

    log.debug(" hookDeleteProjectByProjectId : ", data);
    if (data !== null) return data?.data?.deleteProject;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteProjectByProjectId -- ", e);
    return null;
  }
};

export const hookListProjects = async () => {
  try {
    const data = await API.graphql({
      query: queries.listProjects,
      variables: {},
    });

    log.debug(" hookListProjectsByUserId : ", data);

    if (data !== null) return data?.data?.listProjects.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListProjectsByUserId -- ", e);
    return null;
  }
};

export const hookGetProjectByProjectId = async (ProjectId, organizationID) => {
  log.debug("  hookGetProjectByProjectId ", ProjectId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getProject,
      variables: {
        id: ProjectId,
        organizationID: organizationID,
      },
    });

    log.debug(" hookGetProjectByProjectId : ", data);
    if (data !== null) return data?.data?.getProject;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetProjectByProjectId -- ", e);
    return null;
  }
};

export const hookListProjectsByOrganizationId = async (organizationID) => {
  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.ProjectsByOrganizationID,
      variables: {
        organizationID: organizationID,
        limit:200
      },
    });

    log.debug(" hookListprojectsByOrganizationId : ", data);

    if (data !== null) return data?.data?.ProjectsByOrganizationID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListprojectsByOrganizationId ", e);
    return null;
  }
};

export const hookGetEmployeeTasksByProjectID = async (ProjectId) => {
  log.debug("  hookGetEmployeeTasksByProjectID ", ProjectId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.EmployeeTasksByProjectID,
      variables: {
        projectID: ProjectId,
      },
    });

    log.debug(" hookGetEmployeeTasksByProjectID : ", data);
    if (data !== null) return data?.data?.EmployeeTasksByProjectID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeTasksByProjectID -- ", e);
    return null;
  }
};

export const hookGetProjectTitleById = async (projectID) => {
  log.debug(" hookGetProjectTitleById ++ ", projectID);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getProjectNameByProjectId,
      variables: {
        id: projectID,
      },
    });

    log.debug(" hookGetProjectTitleById : ", result);
    if (result !== null) return result?.data?.getProject?.title;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetProjectTitleById -- ", e);

    return null;
  }
};

export const hookListProjectsByDateRangeOrganizationId = async (
  organizationID,
  startDate,
  endDate
) => {
  try {
    // Connect Client Amplify GraphQL
    // TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: customQueries.projectsBySelectedDateRangeOrganizationID, // Assuming queries is the imported GraphQL queries file
      variables: {
        organizationID: organizationID,
        startDate: startDate, // Add the startDate variable
        endDate: endDate, // Add the endDate variable
      },
    });

    log.debug("hookListProjectsByDateRangeOrganizationId:Hook ", data);

    if (data !== null) return data?.data?.ProjectsByOrganizationID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListProjectsByDateRangeOrganizationId", e);
    return null;
  }
};
