import React from "react";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";

import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { DashboardDataTableWrapper } from "./DashboardDataTableWrapper";

import { FriendlyName } from "../../../lib/friendlyName";
import FormatDateAndTime from "../../../components/common/FormatDateAndTime";
import { useSelector } from "@xstate/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
export function MyLeaves() {
  const { dashboardData } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const { leaves } = dashboardData;
  const employeeMachineService = useEmployeeContextService();
  const isLeavesLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );
  const columns = [
    {
      header: "Title",
      accessorKey: "title",
    },
    {
      header: "Type",
      accessorKey: "typeOfLeave",
      cell: ({ row }) => <FriendlyName status={row.getValue("typeOfLeave")} />,
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: ({ row }) => (
        <>
          <FormatDateAndTime
            dateTime={row.original?.date?.join()}
            format={"DD/MM/YYYY"}
          />
        </>
      ),
    },

    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => <FriendlyName status={row.getValue("status")} />,
    },
  ];

  return (
    <DashboardDataTableWrapper
      title={"My Leaves"}
      action={"View"}
      actionLink={"/employee/leaves"}
    >
      <DataTable
        columns={columns}
        data={leaves || []}
        dataTableToolBar={false}
        loader={isLeavesLoading}
        customCss={`${theme?.border}`}
        customWidth={[
          {
            index: 0,
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 1,
            widthClass: "w-40",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 2,
            widthClass: "w-40",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 3,
            widthClass: "w-24",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
        ]}
      />
    </DashboardDataTableWrapper>
  );
}
