import React from "react";
import PageHeader from "../../common/PageHeader";
import { DataTable } from "../../CustomComponentsLibrary/data-table";
import { Download, Upload } from "lucide-react";
import { Button } from "../../CustomComponentsLibrary/Button";
import { useToast } from "../../CustomComponentsLibrary/UseToast";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { GetSignedURL } from "../../../hooks/s3Hooks";
import { log } from "loglevel";
import { User } from "../../common/User";
import FormatDateAndTime from "../../common/FormatDateAndTime";
import { DataTableColumnHeader } from "../../CustomComponentsLibrary/data-table-column-header";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
const SalarySlip = () => {
  const { toast } = useToast();
  const employeeMachineService = useEmployeeContextService();
  const {
    myEmployeeProfile,
    userID,
    salarySlips: salarySlip,
  } = useEmployeeContextData();
  const { theme } = useAuthContextData();

  console.log("cognitoId employeeMachineService", myEmployeeProfile, userID);
  console.log("Salary Slips employeeMachineService", salarySlip?.s3key);
  console.log("Salary Slips employeeMachineService", salarySlip);

  const DownloadSalarySlip = async (s3key) => {
    if (!s3key) {
      toast({
        variant: "destructive",
        title: "Failed",
        description: "Failed to download slip",
      });
    }
    let fileToView;
    if (s3key) {
      fileToView = s3key;
    }
    console.log("fileToView::", fileToView);
    if (fileToView) {
      let signedURL = await GetSignedURL(fileToView);
      console.log("GetSignedURL :  URL = ", signedURL);
      window.open(signedURL);
    }
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: ({ row }) => {
        return <User userID={row.original.userID} />;
      },
    },
    {
      header: "Net Salary",
      accessorKey: "netSalary",
    },
    {
      header: "Gross Salary",
      accessorKey: "earningGross",
    },
    {
      header: "Basic",
      accessorKey: "earningBasic",
    },
    {
      header: "Month",
      accessorKey: "month",
      enableSorting: true,
      cell: ({ row }) => (
        <FormatDateAndTime dateTime={row.original?.month} format={"MMMM"} />
      ),
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Month" />
      ),
    },
    {
      header: "Working Days",
      accessorKey: "monthWorkingDays",
    },
    {
      header: "Worked Days",
      accessorKey: "workedDays",
    },

    {
      header: "",
      id: "actions",
      accessorKey: "",
      cell: ({ row }) => (
        <div className="flex flex-row gap-x-2">
          {row.original?.s3key != null ? (
            <Button
              size="sm"
              className="bg-transparent shadow-none border-none"
              onClick={() => {
                DownloadSalarySlip(row.original?.s3key);
              }}
            >
              <Download className="h-4 w-4" />
            </Button>
          ) : (
            <div className="text-danger font-semibold"> </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <div className="p-2 mx-auto w-full">
      <PageHeader
        title={"Salary Slips"}
        SubHeading="Overview of all your salary slips."
        showDropdown={false}
      />
      <div className="shadow-md mt-5 border-t border-t-zinc-100 rounded-md">
        <div className=" flex flex-row justify-end"></div>
        <div className="ml-5 mr-5 mt-2 pb-5 text-sm font-semibold">
          <DataTable
            columns={columns}
            data={salarySlip}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 2,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 3,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 4,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 5,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 6,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 7,
                widthClass: "w-10",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default SalarySlip;
