import React from "react";
import log from "loglevel";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { FriendlyName } from "../../../lib/friendlyName";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { DashboardDataTableWrapper } from "./DashboardDataTableWrapper";
import { Progress } from "../../../components/CustomComponentsLibrary/Progress";
import FormatDateAndTime from "../../../components/common/FormatDateAndTime";
import { useSelector } from "@xstate/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
export function MyTasks() {
  const { dashboardData } = useEmployeeContextData();
  const { tasks } = dashboardData;
  log.debug("myTasks from dashboard ", tasks);
  const employeeMachineService = useEmployeeContextService();
  const istaskLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );
  const { userProfile, theme } = useAuthContextData;
  const checkData = useAuthContextData();
  console.log("checkData.themes@@", checkData.theme);

  log.debug("istaskLoading", istaskLoading);
  const columns = [
    {
      header: "Task Name",
      accessorKey: "title",
      cell: ({ row }) => (
        <>
          <div> {row.original.title}</div>
        </>
      ),
    },
    {
      accessorKey: "dueDate",
      header: "Date",
      cell: ({ row }) => (
        <>
          <FormatDateAndTime
            dateTime={row.original?.dueDate}
            format={"DD/MM/YYYY"}
          />
        </>
      ),
    },
    {
      header: "Progress",
      accessorKey: "percentage",
      cell: ({ row }) => (
        <>
          <div className="flex  ">
            <Progress
              value={row.original.percentage}
              className="w-[40%] m-2"
              rootCss={`${checkData.theme?.bgLight}`}
              indicatorCss={`${checkData.theme?.bgCommon}`}
            />
            <div>{`${parseInt(row.getValue("percentage"))}% `}</div>
          </div>
        </>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <div className="w-full">
          <FriendlyName status={row.getValue("status")} />
        </div>
      ),
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
  ];
  return (
    <DashboardDataTableWrapper
      title={"My Tasks"}
      action={"View"}
      actionLink={"/employee/tasks"}
    >
      <DataTable
        columns={columns}
        data={tasks}
        dataTableToolBar={false}
        loader={istaskLoading}
        customCss={`${checkData.theme?.border}`}
        customWidth={[
          {
            index: 0,
            widthClass: "w-96",
            cssStyleClass: `${checkData.theme?.bgLight} ${checkData.theme?.textCommon}`,
          },
          {
            index: 1,
            widthClass: "w-40",
            cssStyleClass: `${checkData.theme?.bgLight} ${checkData.theme?.textCommon}`,
          },
          {
            index: 2,
            widthClass: "w-40",
            cssStyleClass: `${checkData.theme?.bgLight} ${checkData.theme?.textCommon}`,
          },
          {
            index: 3,
            widthClass: "w-28",
            cssStyleClass: `${checkData.theme?.bgLight} ${checkData.theme?.textCommon}`,
          },
        ]}
      />
    </DashboardDataTableWrapper>
  );
}
