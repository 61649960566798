import React, { useState } from "react";
import { LogoName } from "../LogoName";
import ButtonComponent from "../common/ButtonComponent";
import { useAuthService } from "../../customHooks/authMachineHooks";
import { ArrowLeft } from "lucide-react";

export default function EmailConfirm() {
  const authService = useAuthService();
  return (
    <div className="flex h-full flex-col place-items-center  md:overflow-y-hidden">
      <div className="flex w-full flex-row">
        {/* <button
          className="float-left w-max basis-2/6"
          onClick={() => {
            // setMailSentAction(false)
          }}
        >
          <ArrowLeft className="h-8 w-auto" />
        </button> */}
        {/*  <div className="ml-9 basis-4/6 ">
          <LogoName />
        </div> */}
      </div>
      <div className="mt-16 flex grow flex-col place-items-center">
        <LogoName />
        <div className="mt-14 text-2xl font-bold">Check your email</div>
        <p className="mt-8 text-center">
          We have sent a verification code to your email <br></br> box. Enter
          this code to verify your account
        </p>
        <div className="flex mt-3 flex-col place-items-center w-full">
          <ButtonComponent
            action={() => {
              // setMailReceived(true)
              authService.send({ type: "emailReceived" });
            }}
            name="Got it"
          />
        </div>
      </div>
      {/* <div className="flex grow flex-col place-items-center w-full border">
        <ButtonComponent
          action={() => {
            // setMailReceived(true)
            authService.send({ type: "emailReceived" });
          }}
          name="Got it"
        />
      </div> */}
    </div>
  );
}
