import log from "loglevel";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { API } from "aws-amplify";

export const hookAddOrgBusinessDays = async (OrgBusinessDays) => {
  log.debug(" hookAddOrgBusinessDays : ", OrgBusinessDays);

  try {
    const result = await API.graphql({
      query: mutations.createOrgBusinessDays,
      variables: {
        input: OrgBusinessDays,
      },
    });

    log.debug(" hookAddOrgBusinessDays result: ", result);

    return result?.data?.createOrgBusinessDays;
  } catch (err) {
    log.debug("CATCH ERROR hookAddOrgBusinessDays result: ", err);
    return null;
  }
};

export const hookUpdateOrgBusinessDays = async (OrgBusinessDays) => {
  log.debug("hookUpdateOrgBusinessDays: ", OrgBusinessDays);
  try {
    const result = await API.graphql({
      query: mutations.updateOrgBusinessDays,
      variables: {
        input: OrgBusinessDays,
      },
    });

    log.debug(" hookUpdateOrgBusinessDays result: ", result);
    return result?.data?.updateOrgBusinessDays;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateOrgBusinessDays result: ", err);
  }
};

export const hookDeleteOrgBusinessDaysByOrgBusinessDaysId = async (
  OrgBusinessDaysId
) => {
  log.debug(
    " hookDeleteOrgBusinessDaysByOrgBusinessDaysId  ",
    OrgBusinessDaysId
  );

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteOrgBusinessDays,
      variables: {
        input: { id: OrgBusinessDaysId },
      },
    });
    log.debug(" hookDeleteOrgBusinessDaysByOrgBusinessDaysId : ", data);
    if (data !== null) return data?.data?.deleteOrgBusinessDays;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeleteOrgBusinessDaysByOrgBusinessDaysUniqueID -- ",
      e
    );
    return null;
  }
};

export const hookGetOrgBusinessDaysByOrgBusinessDaysId = async (
  OrgBusinessDaysId
) => {
  log.debug("  hookGetOrgBusinessDaysByOrgBusinessDaysId ", OrgBusinessDaysId);

  try {
    const data = await API.graphql({
      query: queries.getOrgBusinessDays,
      variables: {
        input: { id: OrgBusinessDaysId },
      },
    });

    log.debug(" hookGetOrgBusinessDaysByOrgBusinessDaysId : ", data);
    if (data !== null) return data?.data?.getOrgBusinessDays;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookGetOrgBusinessDaysByOrgBusinessDaysId -- ",
      e
    );
    return null;
  }
};
export const hookListOrgBusinessDayssByOrgId = async (orgId) => {
  log.debug("hookListOrgBusinessDayssByOrgId OrgId", orgId);
  try {
    const data = await API.graphql({
      query: queries.OrgBusinessDaysByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" hookListOrgBusinessDayssByOrgId : ", data);
    if (data !== null) return data?.data?.OrgBusinessDaysByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListOrgBusinessDayssByOrgId -- ", e);
    return null;
  }
};
