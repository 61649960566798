import {
  hookListProjectsByOrganizationId,
  hookGetProjectTitleById,
  hookUpdateProject,
} from "../../hooks/projectHooks";
import log from "loglevel";
import {
  CONST_EMPLOYEE_STATUS,
  CONST_UPDATE_CONTEXT_ACTIONS,
} from "../../constants/dbconstants";
import {
  hookGetTaskHoursByMonthAndProjectID,
  hookGetTaskHoursByProjectID,
  hookGetTaskHoursByTaskId,
  hookGetTaskHoursByUserId,
  hookGetTaskHoursByMonthAndUserId,
  hookGetTaskHoursByTaskIdAndDates,
} from "../../hooks/HookTaskHours";
import { hookGetEmployeeShortProfileById } from "../../hooks/employeeHooks";
import { hookGetTaskTitleById } from "../../hooks/tasksHooks";
export const getProjectDataByOrganizationIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { organizationID, userID, myEmployeeProfile } = context;
    let uniqueTeamMemberIds = [];
    log.debug(
      "myEmployeeProfilegetProjectDataByOrganizationIdService",
      myEmployeeProfile,
      userID
    );
    if (organizationID) {
      const projects = await hookListProjectsByOrganizationId(organizationID);

      if (projects) {
        var projectData = [];
        const filteredProjects = projects?.filter(
          (project) =>
            project?.teammembers?.includes(userID) ||
            project?.assignedTo === userID
        );
        projectData = filteredProjects;
        if (myEmployeeProfile) {
          const teamLeadProjects = projects?.filter(
            (project) =>
              project.teammembers?.includes(myEmployeeProfile?.cognitoId) ||
              project.assignedTo === myEmployeeProfile?.cognitoId
          );
          var commonProject = filteredProjects?.filter((projects) =>
            teamLeadProjects?.some((item) => projects.id === item.id)
          );

          projectData = commonProject;
        }

        uniqueTeamMemberIds = [
          ...new Set(
            [].concat(...projects?.map((project) => project?.teammembers))
          ),
        ];

        const userNameArr = uniqueTeamMemberIds
          ?.map(async (item) => {
            const userName = await hookGetEmployeeShortProfileById(item);
            log.debug("userName::hookGetEmployeeShortProfileById", userName);

            if (
              userName?.employeeStatus !==
              CONST_EMPLOYEE_STATUS.employeeTerminated
            ) {
              return {
                id: item,
                title: userName?.firstName + " " + userName?.LastName,
              };
            } else {
              return null;
            }
          })
          .filter(Boolean);
        const userNames = await Promise.all(userNameArr);

        log.debug(
          "uniqueUserIDs::hookListProjectsByOrganizationId",
          uniqueTeamMemberIds,
          userNames
        );

        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "projects",
            value: projectData,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "SuccessFully fetched projects",
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "teamMembersNames",
            value: userNames,
          },
        ]);
      }
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Fetch projects",
      },
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "teamMembersNames",
        value: uniqueTeamMemberIds,
      },
    ]);
  });

export const assignProjectToTeammemberService = (context) =>
  new Promise(async (resolve, reject) => {
    const { projectToBeAdded, myEmployeeProfile } = context;
    log.debug("hookUpdateProject", projectToBeAdded, myEmployeeProfile);
    const duplicate = projectToBeAdded?.teammembers?.includes(
      myEmployeeProfile?.cognitoId
    );
    if (!duplicate) {
      projectToBeAdded?.teammembers?.push(myEmployeeProfile?.cognitoId);
    }
    const res = await hookUpdateProject({
      ...projectToBeAdded,
    });

    if (res)
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "projects",
          value: res,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully updated project",
        },
      ]);
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Update Project",
      },
    ]);
  });
export const getEmployeeTasksHoursByUserId = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID, selectedProjectId } = context;
    log.debug("selectedProjectId::userID", userID, selectedProjectId);
    let employeeTaskHours = [];

    if (userID) {
      employeeTaskHours = await hookGetTaskHoursByUserId(userID);
    }
    if (selectedProjectId) {
      employeeTaskHours = await hookGetTaskHoursByProjectID(selectedProjectId);
    }

    if (employeeTaskHours) {
      log.debug(
        "getEmployeeTasksHoursByUserId:employeeTaskHours",
        employeeTaskHours
      );

      if (employeeTaskHours.length === 0) {
        log.debug(" if (employeeTaskHours.length === 0)");
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "No Data Found",
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "employeeTaskHours",
            value: employeeTaskHours,
          },
        ]);
      }
      // Create unique arrays for projectID and taskID

      let uniqueProjectIDs = [];
      let uniqueTaskIDs = [];
      let uniqueUserIDs = [];
      if (employeeTaskHours)
        uniqueProjectIDs = [
          ...new Set(employeeTaskHours?.map((item) => item?.projectID)),
        ];
      uniqueTaskIDs = [
        ...new Set(employeeTaskHours?.map((item) => item?.taskID)),
      ];

      uniqueUserIDs = [
        ...new Set(employeeTaskHours?.map((item) => item?.userID)),
      ];

      const taskItemsArr = uniqueTaskIDs?.map(async (item) => {
        const taskTitle = await hookGetTaskTitleById(item);
        return { id: item, title: taskTitle };
      });

      const taskItems = await Promise.all(taskItemsArr);
      log.debug("taskItems::", taskItems);

      const projectTitleArr = uniqueProjectIDs?.map(async (item) => {
        const projectTitle = await hookGetProjectTitleById(item);
        return { id: item, title: projectTitle };
      });
      const projectItems = await Promise.all(projectTitleArr);

      const userNameArr = uniqueUserIDs?.map(async (item) => {
        const userName = await hookGetEmployeeShortProfileById(item);
        return {
          id: item,
          title: userName?.firstName + " " + userName?.LastName,
        };
      });
      const userNames = await Promise.all(userNameArr);

      function replaceKeysWithTitles(
        taskHoursArray,
        taskArray,
        projectArray,
        userNames
      ) {
        log.debug("replaceKeysWithTitles");
        const resultMap = new Map(
          [...taskArray, ...projectArray, ...userNames].map((item) => [
            item.id,
            { id: item.id, title: item.title },
          ])
        );

        return taskHoursArray?.map((obj) => {
          const newObj = { ...obj };

          if (newObj.taskID) {
            const task = resultMap.get(newObj.taskID);
            newObj.taskID = task.id;
            newObj.taskTitle = task.title;
          }

          if (newObj.projectID) {
            const project = resultMap.get(newObj.projectID);
            newObj.projectID = project.id;
            newObj.projectTitle = project.title;
          }

          if (newObj.userID) {
            const user = resultMap.get(newObj.userID);
            newObj.userID = user.id;
            newObj.userName = user.title;
          }

          return newObj;
        });
      }

      log.debug(
        "beforre replaceKeysWithTitles:employeeTaskHours",
        employeeTaskHours
      );

      let newEmployeeTaskHours = replaceKeysWithTitles(
        employeeTaskHours,
        taskItems,
        projectItems,
        userNames
      );

      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "employeeTaskHours",
          value: newEmployeeTaskHours,
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "taskHoursWithTitle",
          value: taskItems,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "projectIdArray",
          value: uniqueProjectIDs,
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "SuccessFully fetched Tasks Hours",
        },
      ]);
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Fetch Tasks Hours",
      },
    ]);
  });

export const getEmployeeTasksHoursByMonth = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskHourByMonth, selectedTask } = context;

    let employeeTaskHours = [];
    log.debug("taskHourByMonth:getEmployeeTasksHoursByMonth", taskHourByMonth);
    if (
      taskHourByMonth?.userID &&
      taskHourByMonth?.firstDay !== "Invalid date" &&
      taskHourByMonth?.lastDay !== "Invalid date"
    ) {
      log.debug("selectedProjectId::userID", taskHourByMonth);
      employeeTaskHours = await hookGetTaskHoursByMonthAndUserId(
        taskHourByMonth
      );
    }
    if (taskHourByMonth?.selectedProjectId) {
      employeeTaskHours = await hookGetTaskHoursByMonthAndProjectID(
        taskHourByMonth
      );
    }

    if (employeeTaskHours) {
      log.debug(
        "getEmployeeTasksHoursByUserId:employeeTaskHours",
        employeeTaskHours
      );

      if (employeeTaskHours.length === 0) {
        log.debug(" if (employeeTaskHours.length === 0)");
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "No Data Found",
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "employeeTaskHours",
            value: employeeTaskHours,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "logHourHistory",
            value: employeeTaskHours,
          },
        ]);
      }
      // Create unique arrays for projectID and taskID

      let uniqueProjectIDs = [];
      let uniqueTaskIDs = [];
      let uniqueUserIDs = [];
      if (employeeTaskHours)
        uniqueProjectIDs = [
          ...new Set(employeeTaskHours?.map((item) => item?.projectID)),
        ];
      uniqueTaskIDs = [
        ...new Set(employeeTaskHours?.map((item) => item?.taskID)),
      ];

      uniqueUserIDs = [
        ...new Set(employeeTaskHours?.map((item) => item?.userID)),
      ];

      const taskItemsArr = uniqueTaskIDs?.map(async (item) => {
        const taskTitle = await hookGetTaskTitleById(item);
        return { id: item, title: taskTitle };
      });

      const taskItems = await Promise.all(taskItemsArr);
      log.debug("taskItems::", taskItems);

      const projectTitleArr = uniqueProjectIDs?.map(async (item) => {
        const projectTitle = await hookGetProjectTitleById(item);
        return { id: item, title: projectTitle };
      });
      const projectItems = await Promise.all(projectTitleArr);

      const userNameArr = uniqueUserIDs?.map(async (item) => {
        const userName = await hookGetEmployeeShortProfileById(item);
        return {
          id: item,
          title: userName?.firstName + " " + userName?.LastName,
        };
      });
      const userNames = await Promise.all(userNameArr);

      function replaceKeysWithTitles(
        taskHoursArray,
        taskArray,
        projectArray,
        userNames
      ) {
        log.debug("replaceKeysWithTitles");
        const resultMap = new Map(
          [...taskArray, ...projectArray, ...userNames].map((item) => [
            item.id,
            { id: item.id, title: item.title },
          ])
        );

        return taskHoursArray?.map((obj) => {
          const newObj = { ...obj };

          if (newObj.taskID) {
            const task = resultMap.get(newObj.taskID);
            newObj.taskID = task.id;
            newObj.taskTitle = task.title;
          }

          if (newObj.projectID) {
            const project = resultMap.get(newObj.projectID);
            newObj.projectID = project.id;
            newObj.projectTitle = project.title;
          }

          if (newObj.userID) {
            const user = resultMap.get(newObj.userID);
            newObj.userID = user.id;
            newObj.userName = user.title;
          }

          return newObj;
        });
      }

      let newEmployeeTaskHours = replaceKeysWithTitles(
        employeeTaskHours,
        taskItems,
        projectItems,
        userNames
      );

      const totalLogHourForDay = newEmployeeTaskHours?.reduce(
        (sum, employee) => sum + employee.totalHours,
        0
      );
      let totalLogHour = null;
      if (selectedTask) {
        totalLogHour = { totalLogHour: totalLogHourForDay };
      }

      log.debug("newEmployeeTaskHours ", newEmployeeTaskHours);

      function calculateTotalHoursByTaskAndDate(logHoursData) {
        const result = {};

        logHoursData?.forEach((item) => {
          const taskId = item.taskID;
          const date = item.date;
          const hours = item.totalHours;
          const description = item.description;
          const userName = item.userName;
          const key = `${taskId}_${date}`;

          if (!result[key]) {
            result[key] = {
              date,
              projectTitle: item.projectTitle,
              taskTitle: item.taskTitle,
              totalHours: 0,
              description: "",
              userName: item.userName,
            };
          }

          result[key].totalHours += hours;
          result[key].description += description + " ";
        });

        const finalResult = Object.values(result);
        return finalResult;
      }

      const totalHoursByTaskAndDate =
        calculateTotalHoursByTaskAndDate(newEmployeeTaskHours);

      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "employeeTaskHoursForDay",
          value: totalLogHour,
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "logHourHistory",
          value: totalHoursByTaskAndDate,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "employeeTaskHours",
          value: newEmployeeTaskHours,
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "taskHoursWithTitle",
          value: taskItems,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "projectIdArray",
          value: uniqueProjectIDs,
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "SuccessFully fetched Tasks Hours",
        },
      ]);
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Fetch Tasks Hours",
      },
    ]);
  });

export const getTaskHoursByTaskIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedTask, userID } = context;
    log.debug(
      "getTaskHoursByTaskIdService::selectedTask",
      selectedTask,
      selectedTask?.id,
      userID
    );
    let employeeTaskHours = [];

    employeeTaskHours = await hookGetTaskHoursByTaskId(selectedTask?.id);

    const myTaskHour = employeeTaskHours?.filter(
      (item) => item.userID == userID
    );
    log.debug("myTaskHour", myTaskHour);

    if (employeeTaskHours) {
      log.debug(
        "getEmployeeTasksHoursByUserId:employeeTaskHours",
        myTaskHour,
        employeeTaskHours
      );

      if (employeeTaskHours.length === 0) {
        log.debug(" if (employeeTaskHours.length === 0)");
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "No Data Found",
          },

          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "taskHours",
            value: employeeTaskHours,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "personalTaskHour",
            value: myTaskHour,
          },
        ]);
      }
      // Create unique arrays for projectID and taskID

      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "taskHours",
          value: employeeTaskHours,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "personalTaskHour",
          value: myTaskHour,
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "SuccessFully fetched Tasks Hours",
        },
      ]);
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Fetch Tasks Hours",
      },
    ]);
  });

export const getTaskHoursByTaskIdAndDateService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedTask, startDay, endDay, userID } = context;
    log.debug(
      "getTaskHoursByTaskIdService::selectedTask",
      selectedTask,
      selectedTask?.id,
      startDay,
      endDay,
      userID
    );
    let employeeTaskHours = [];

    employeeTaskHours = await hookGetTaskHoursByTaskIdAndDates(
      selectedTask?.id,
      startDay,
      endDay
    );

    if (employeeTaskHours) {
      log.debug(
        "getEmployeeTasksHoursByUserId:employeeTaskHours",
        employeeTaskHours
      );

      function calculateLoggedHoursForWeek(
        data,
        weekStartDate,
        weekEndDate,
        userId
      ) {
        const employeeHoursMap = new Map();

        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];

        // Initialize employeeHours object for each date between weekStartDate and weekEndDate
        let currentDate = new Date(weekStartDate);
        while (currentDate <= weekEndDate) {
          const formattedDate = currentDate.toISOString().split("T")[0];
          const dayName = daysOfWeek[currentDate.getDay()]; // Get the day name
          const hoursObj = {
            date: `${formattedDate} , ${dayName}`,
            loggedHours: 0,
          };
          employeeHoursMap.set(formattedDate, hoursObj);
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next date
        }

        // Calculate logged hours for each date based on the provided data and userId
        data?.forEach((entry) => {
          if (entry.userID === userId) {
            const date = entry.date;
            const formattedDate = new Date(date).toISOString().split("T")[0];
            const existingDateObj = employeeHoursMap.get(formattedDate);

            if (existingDateObj) {
              existingDateObj.loggedHours += entry.totalHours; // Add logged hours to the found date
            }
          }
        });

        // Convert the Map to an array of objects
        const employeeHoursArray = Array.from(employeeHoursMap.values());

        return employeeHoursArray; // Return the array of objects with date, day, loggedHours, and history pairs
      }

      const weekStartDate = new Date(startDay);
      const weekEndDate = new Date(endDay);

      const result = calculateLoggedHoursForWeek(
        employeeTaskHours,
        weekStartDate,
        weekEndDate,
        userID
      );
      log.debug("result::calculateLoggedHours", result);

      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "taskHours",
          value: result,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "employeeTaskHours",
          value: employeeTaskHours,
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "SuccessFully fetched Tasks Hours",
        },
      ]);
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Fetch Tasks Hours",
      },
    ]);
  });
