import clsx from "clsx";

//types:  success, warning, failure, blue, pink, neutral
const objectValues = {
  EXPENSE_CREATED: {
    text: "Expense Created",
    type: "neutral",
  },
  EXPENSE_REJECTED: {
    text: "Expense Rejected",
    type: "failure",
  },
  EXPENSE_APPROVED: {
    text: "Expense Approved",
    type: "success",
  },
  EXPENSE_INPROCESS: {
    text: "Expense in Process",
    type: "blue",
  },
  EXPENSE_ONHOLD: {
    text: "Expense On Hold",
    type: "failure",
  },
  EXPENSE_PAYMENT_DONE: {
    text: "Payment Done",
    type: "neutral",
  },
  LEAVE_ANNUAL: {
    text: "Annual",
    type: "blue",
  },
  LEAVE_UNPAID: {
    text: "Unpaid",
    type: "failure",
  },
  LEAVE_SICK: {
    text: "Sick",
    type: "orange",
  },
  LEAVE_MATERNITY: {
    text: "Maternity",
    type: "orange",
  },
  LEAVE_PARTERNITY: {
    text: "Paternity",
    type: "orange",
  },
  TIMEOFF: {
    text: "C-OFF Leave",
    type: "failure",
  },
  LEAVESTATUS_SUBMITTED: {
    text: "Submitted",
    type: "neutral",
  },
  LEAVESTATUS_REJECTED: {
    text: "Rejected",
    type: "failure",
  },
  LEAVESTATUS_APPROVED: {
    text: "Approved",
    type: "success",
  },
  LEAVESTATUS_INPROCESS: {
    text: "Approval in Process",
    type: "blue",
  },
  COFF_APPLIED: {
    text: "SUBMITTED",
    type: "neutral"
  },
  COFF_APPROVED: {
    text: "APPROVED",
    type: "success"
  },
  COFF_REJECTED: {
    text: "REJECTED",
    type: "failure"
  },
  LEAVESTATUS_ONHOLD: {
    text: "Leave On Hold",
    type: "failure",
  },
  TASKSTATUS_CREATED: {
    text: "Created",
    type: "neutral",
  },
  TASKSTATUS_INPROCESS: {
    text: "In Progress",
    type: "blue",
  },
  TASKSTATUS_ONHOLD: {
    text: "On Hold",
    type: "orange",
  },
  TASKSTATUS_CANCELLED: {
    text: "Cancelled",
    type: "failure",
  },
  TASKSTATUS_TESTING: {
    text: "Need to Test",
    type: "warning",
  },
  TASKSTATUS_DONE: {
    text: "Closed",
    type: "indigo",
  },
  TASKSTATUS_DONE_APPROVED: {
    text: "Delivered",
    type: "success",
  },
  GOALSTATUS_CREATED: {
    text: "Assigned",
    type: "neutral",
  },
  GOALSTATUS_INPROCESS: {
    text: "In Progress",
    type: "blue",
  },
  GOALSTATUS_ONHOLD: {
    text: "On Hold",
    type: "orange",
  },
  GOALSTATUS_CANCELLED: {
    text: "Cancelled",
    type: "failure",
  },
  GOALSTATUS_DONE: {
    text: "Achieved",
    type: "indigo",
  },
  GOALSTATUS_DONE_APPROVED: {
    text: "Achievement Approved",
    type: "success",
  },
  WORK_FROM_OFFICE: {
    text: "Working In Office",
    type: "success",
  },
  WORK_FROM_HOME: {
    text: "Working From Home",
    type: "blue",
  },
  WORK_FROM_CLIENTLOCATION: {
    text: "On Client Location",
    type: "orange",
  },
  WORK_FROM_UNKNOWN: {
    text: "Undisclosed Location",
    type: "failure",
  },
  YOUTUBE: {
    text: "Youtube",
    type: "success",
  },
  LINK: {
    text: "URL Link",
    type: "success",
  },
  S3STORAGE: {
    text: "S3 Storage",
    type: "success",
  },
  JobPosted: {
    text: "Job Posted",
    type: "neutral",
  },
  JobApplied: {
    text: "Job Applied",
    type: "blue",
  },
  Shortlisted: {
    text: "Shortlisted",
    type: "blue",
  },
  NotShortlisted: {
    text: "Not Shortlisted",
    type: "failure",
  },
  SendRejectionEmail: {
    text: "Send Rejection Email",
    type: "failure",
  },
  ScheduleOnlineTest: {
    text: "Schedule Online Test",
    type: "blue",
  },
  OnlineTestScheduledToEmail: {
    text: "Online Test Scheduled",
    type: "blue",
  },
  TEST_START_ALLOWED: {
    text: "Test Start Allowed",
    type: "success",
  },
  TestInProgress: {
    text: "Test In Progress",
    type: "orange",
  },
  TestSubmitted: {
    text: "Test Submitted",
    type: "success",
  },
  TestPassed: {
    text: "Test Passed",
    type: "success",
  },
  OnlineTestFailed: {
    text: "Online Test Failed",
    type: "failure",
  },
  SendOnlineTestRejectionEmailToCandidate: {
    text: "Online Test Rejection Email sent",
    type: "failure",
  },
  SendTestPassedEmailToManager: {
    text: "Send Test Passed Email To Manager",
    type: "success",
  },
  ScheduleWrittenTest: {
    text: "Schedule Written Test",
    type: "blue",
  },
  SendWrittenTestScheduledEmailToCandidate: {
    text: "Written Test Scheduled Email sent",
    type: "blue",
  },
  WRITTENTEST_START_ALLOWED: {
    text: "Allowed Written Test To Start",
    type: "success",
  },
  WrittenTestInProgress: {
    text: "Written Test In Progress",
    type: "success",
  },
  WrittenTestSubmitted: {
    text: "Written Test Submitted",
    type: "success",
  },
  WrittenTestFailed: {
    text: "Written Test Failed",
    type: "success",
  },
  SendWrittenTestRejectionEmailToCandidate: {
    text: "Written Test Rejection Email sent",
    type: "failure",
  },
  WrittenTestPassed: {
    text: "Written Test Passed",
    type: "success",
  },
  SendWrittenTestPassedEmailToManager: {
    text: "Send Written Test Passed Email To Manager",
    type: "success",
  },
  ScheduleTechnicalInterview: {
    text: "Schedule Technical Interview",
    type: "success",
  },
  SendTechnicalInterviewScheduledEmailToCandidate: {
    text: "Technical Interview Scheduled",
    type: "success",
  },
  TechnicalInterviewInProgress: {
    text: "Technical Interview InProgress",
    type: "success",
  },
  TechnicalInterviewSubmitted: {
    text: "Technical Interview Submitted",
    type: "success",
  },
  TechnicalInterviewFailed: {
    text: "Technical Interview Failed",
    type: "success",
  },
  SendTechnicalInterviewRejectionEmail: {
    text: "Technical Interview Rejection Email Sent",
    type: "failure",
  },
  TechnicalInterviewPassed: {
    text: "Technical Interview Passed",
    type: "success",
  },
  SendTechnicalInterviewPassedEmailToHR: {
    text: "Send Technical Interview Passed Email To HR",
    type: "success",
  },
  ScheduleHRInterview: {
    text: "ScheduleHRInterview",
    type: "success",
  },
  SendHRInterviewScheduledEmailToCandidate: {
    text: "HR Interview Scheduled Email sent",
    type: "success",
  },

  HRInterviewInProgress: {
    text: "HR Interview InProgress",
    type: "success",
  },
  HRInterviewSubmitted: {
    text: "HR Interview Submitted",
    type: "success",
  },
  HRInterviewFailed: {
    text: "HR Interview Failed",
    type: "success",
  },
  SendHRInterviewRejectionEmail: {
    text: "HR Interview Rejection Email sent",
    type: "failure",
  },
  HRInterviewPassed: {
    text: "HR Interview Passed",
    type: "success",
  },
  SendEmailToUploadDocuments: {
    text: "Upload Documents Email Send",
    type: "success",
  },
  SendHrInterviewPassedEmailToCandidate: {
    text: "Hr Interview Passed",
    type: "success",
  },
  DocumentUpload: {
    text: "Upload Documents",
    type: "success",
  },
  DocumentsUploadComplete: {
    text: "Documents Upload Complete",
    type: "success",
  },
  PrepareCandidateOffer: {
    text: "Prepare Candidate Offer",
    type: "success",
  },
  PreparedCandidateOffer: {
    text: "Prepared Candidate Offer",
    type: "success",
  },
  ApproveCandidateOffer: {
    text: "Approve Candidate Offer",
    type: "success",
  },
  SendCandidateOfferEmailToCandidate: {
    text: "Offer Email has been sent",
    type: "success",
  },
  CandidateOfferRejected: {
    text: "Offer Rejected",
    type: "failure",
  },
  CandidateAcceptedOffer: {
    text: "Accepted Offer",
    type: "success",
  },
  SendEmail1WeekBeforeJoining: {
    text: "Send Email 1 Week Before Joining",
    type: "success",
  },
  SendEmail1DaysBeforeJoining: {
    text: "Send Email 1 Day Before Joining",
    type: "success",
  },
  Hired: {
    text: "Hired",
    type: "success",
  },
  ApplicationEnd: {
    text: "Application End",
    type: "success",
  },
  FRESH_FRONTEND: {
    text: "Front End Developer",
    type: "success",
  },
  FRESH_EMBEDDED: {
    text: "Embedded Developer",
    type: "success",
  },
  FRESH_HARDWARE: {
    text: "Hardware Engineer",
    type: "success",
  },

  FRESH_ANDROID: {
    text: "Mobile App Developer",
    type: "success",
  },
  FRESH_GENERAL: {
    text: "Fresher General",
    type: "success",
  },
  EXP_FRONTEND: {
    text: "Experience Front End Developer",
    type: "success",
  },
  EXP_EMBEDDED: {
    text: "Experience Embedded Developer",
    type: "success",
  },
  EXP_HARDWARE: {
    text: "Experience Hardware Engineer",
    type: "success",
  },
  EXP_ANDROID: {
    text: "Experienced Mobile App Developer",
    type: "success",
  },
  EXP_GENERAL: {
    text: "Experienced General",
    type: "success",
  },
  MCQ: {
    text: "Multiple Choice Questions",
    type: "success",
  },

  CODING: {
    text: "Coding Test",
    type: "success",
  },
  DESCRIPTIVE: {
    text: "Descriptive",
    type: "success",
  },
  CONTACT_SUBSCRIBER: {
    text: "Subscriber",
    type: "neutral",
  },
  CONTACT_LEAD: {
    text: "Lead",
    type: "indigo",
  },
  CONTACT_QUALIFIED: {
    text: "Qualified Lead",
    type: "orange",
  },
  CONTACT_OPPORTUNITY: {
    text: "Opportunity",
    type: "failure",
  },
  CONTACT_CUSTOMER: {
    text: "Customer",
    type: "success",
  },
  CONTACT_EVANGELIST: {
    text: "Repeat Customer",
    type: "success",
  },
  CONTACT_PARKED: {
    text: "Contact Parked",
    type: "blue",
  },
  DEAL_CREATED: {
    text: "Deal Created",
    type: "success",
  },
  DEAL_INPROCESS: {
    text: "Deal In Progress",
    type: "success",
  },
  DEAL_ONHOLD: {
    text: "Deal On Hold",
    type: "success",
  },
  DEAL_WON: {
    text: "Deal Won",
    type: "success",
  },
  DEAL_LOST: {
    text: "Deal Lost",
    type: "success",
  },
  SALESTASK_CREATED: {
    text: "Task Created",
    type: "neutral",
  },
  SALESTASK_INPROCESS: {
    text: "In Progress",
    type: "blue",
  },
  SALESTASK_ONHOLD: {
    text: "Task On Hold",
    type: "orange",
  },
  SALESTASK_CANCELLED: {
    text: "Cancelled",
    type: "failure",
  },
  SALESTASK_DONE: {
    text: "Completed",
    type: "success",
  },
  SALESTASK_DONE_APPROVED: {
    text: "Approved",
    type: "success",
  },
  SALESTASK_ALL: {
    text: "All",
    type: "indigo",
  },
  EMPLOYEE_NEWHIRE: {
    text: "New Hire",
    type: "indigo",
  },

  EMPLOYEE_ONBOARDING: {
    text: "Onboarding",
    type: "pink",
  },

  EMPLOYEE_VERIFY_PENDING: {
    text: "Verify Pending",
    type: "orange",
  },
  EMPLOYEE_VERIFY_FAILED: {
    text: "Verify Failed",
    type: "red",
  },

  EMPLOYEE_ON_PROBATION: {
    text: "On Probation",
    type: "blue",
  },

  EMPLOYEE_TERMINATED: {
    text: "Terminated",
    type: "Red",
  },
  EMPLOYEE_NORMAL: {
    text: "Normal",
    type: "success",
  },
  EMPLOYEE_ON_PIP: {
    text: "PIP",
    type: "yellow",
  },
  SSC_MARKSHEET: {
    text: "10th Marksheet",
    type: "success",
  },
  HSC_MARKSHEET: {
    text: "12th Marksheet",
    type: "success",
  },
  DEGREE_MASTERS_MARK_SHEETS: {
    text: "Degree/Masters Marksheet",
    type: "success",
  },
  INCREMENT_LETTERS: {
    text: "Increment Letter",
    type: "success",
  },
  APPOINTMENT_LETTER: {
    text: "Appointment Letter",
    type: "success",
  },
  LAST_ONE_MONTH_SALARY_SLIP: {
    text: "Last one Month Salary Slip",
    type: "success",
  },
  LAST_SECOND_MONTH_SALARY_SLIP: {
    text: "Last Second Month Salary Slip",
    type: "success",
  },
  LAST_THIRD_MONTH_SALARY_SLIP: {
    text: "Last Third Month Salary Slip",
    type: "success",
  },
  PROFESSIONAL_CERTIFICATION: {
    text: "Professional Certification",
    type: "success",
  },
  EXPERIENCE_LETTER: {
    text: "Experience Letter",
    type: "success",
  },
  RELIEVING_LETTER: {
    text: "Relieving Letter",
    type: "success",
  },

  PAYMENT_DONE_FROM_CUSTOMER: {
    text: "Waiting for Approval",
    type: "success",
  },
  PAYMENT_PROBLEMATIC: {
    text: "Problem",
    type: "failure",
  },
  PAYMENT_ONHOLD: {
    text: "Hold",
    type: "failure",
  },
  PAYMENT_RECEIVED_VERIFIED: {
    text: "Done",
    type: "success",
  },
  PENDING: {
    text: "Pending",
    type: "warning",
  },

  PAYMENT_OUTGOING: {
    text: "Expense",
    type: "failure",
  },
  PAYMENT_INCOMING: {
    text: "Income",
    type: "success",
  },

  PAYMENT_BANK_TRANSFER: {
    text: "Bank Transfer",
    type: "success",
  },
  PAYMENT_CHEQUE: {
    text: "Cheque",
    type: "warning",
  },
  PAYMENT_CASH: {
    text: "Cash",
    type: "warning",
  },
  PAYMENT_OTHERS: {
    text: "Others",
    type: "neutral",
  },
  ORG_TO_VENDOR: {
    text: "Vendor",
    type: "neutral",
  },
  ORG_TO_EMPLOYEE: {
    text: "Employee",
    type: "neutral",
  },
  CLIENT_TO_ORG: {
    text: "Client",
    type: "success",
  },
  PROJECT_CREATED: {
    text: " Created",
    type: "neutral",
  },
  PROJECT_INPROCESS: {
    text: "In Progress",
    type: "blue",
  },
  PROJECT_ONHOLD: {
    text: "On Hold",
    type: "orange",
  },
  PROJECT_CANCELLED: {
    text: "Cancelled",
    type: "red",
  },
  PROJECT_DONE: {
    text: "Done",
    type: "indigo",
  },
  PROJECT_DONE_APPROVED: {
    text: "Approved",
    type: "success",
  },
  PROJECT_DELIVERED: {
    text: "Delivered",
    type: "success",
  },
  PROJECT_CLOSED: {
    text: "Closed",
    type: "success",
  },
};

export function FriendlyName({ status, className = "" }) {
  return (
    <>
      {objectValues[status]?.type === "neutral" && (
        <div
          className={clsx(
            "bg-zinc-300 text-xs py-1 text-center max-w-xs border border-zinc-600 text-zinc-600 rounded",
            // "bg-zinc-300 px-2 py-1 text-center text-xs border-2 border-black  rounded-md",
            className
          )}
        >
          {objectValues[status].text}
        </div>
      )}
      {objectValues[status]?.type == "failure" && (
        <div
          className={clsx(
            "bg-red-100 text-xs py-1 text-center max-w-xs border border-red-600 text-red-600 rounded",
            className
          )}
        >
          {objectValues[status].text}
        </div>
      )}
      {objectValues[status]?.type === "warning" && (
        <div
          className={clsx(
            "bg-yellow-100 text-xs py-1 text-center max-w-xs border border-yellow-600 text-yellow-600 rounded",
            className
          )}
        >
          {objectValues[status].text}{" "}
        </div>
      )}
      {objectValues[status]?.type === "success" && (
        <div
          className={clsx(
            "bg-green-100 text-xs py-1 border text-center  max-w-xs border-green-600 text-green-600 rounded",
            className
          )}
        >
          {objectValues[status].text}{" "}
        </div>
      )}
      {objectValues[status]?.type === "blue" && (
        <div
          className={clsx(
            "bg-blue-100 text-xs py-1 border text-center max-w-xs border-blue-600 text-blue-600 rounded",
            className
          )}
        >
          {objectValues[status].text}{" "}
        </div>
      )}
      {objectValues[status]?.type === "indigo" && (
        <div
          className={clsx(
            "bg-indigo-100 text-xs py-1 text-center max-w-xs  border border-indigo-600 text-indigo-600  rounded",
            className
          )}
        >
          {objectValues[status].text}{" "}
        </div>
      )}
      {objectValues[status]?.type === "purple" && (
        <div
          className={clsx(
            "bg-purple-100 text-xs py-1 border max-w-xs text-center border-purple-600 text-purple-600 rounded",
            className
          )}
        >
          {objectValues[status].text}{" "}
        </div>
      )}
      {objectValues[status]?.type === "pink" && (
        <div
          className={clsx(
            "bg-pink-100 text-xs py-1 border max-w-xs border-pink-600 text-center text-pink-600 rounded",
            className
          )}
        >
          {objectValues[status]?.text}
        </div>
      )}

      {objectValues[status]?.type === "orange" && (
        <div
          className={clsx(
            "bg-orange-100 text-xs py-1 border border-orange-600 text-center text-orange-600 rounded",
            className
          )}
        >
          {objectValues[status]?.text}
        </div>
      )}
    </>
  );
}
