import React, { useEffect, useState } from "react";
import log from "loglevel";
import { hookGetEmployeeShortProfileById } from "../../hooks/employeeHooks";

export function User({ userID }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      const userData = await hookGetEmployeeShortProfileById(userID);
      setUser(userData);
    }
    if (userID) fetchUser();
  }, [userID]);

  if (!user) {
    return null;
  }

  const { firstName,LastName } = user;
  // const bgColor = getColorByAlphabet(firstName);

  return (
    <div className="flex flex-row">
      <div
        className="flex"
        // style={{ backgroundColor: bgColor }}
      >
        <span className="text-white font-bold text-sm uppercase">
          {firstName?.charAt(0)}
        </span>
      </div>
      <div className="font-semibold self-center capitalize mt-1">
        {firstName + " " + LastName}
      </div>
    </div>
  );
}

function getColorByAlphabet(str) {
  const firstLetter = str?.charAt(0)?.toUpperCase();
  let color;

  switch (firstLetter) {
    case "A":
    case "B":
    case "C":
    case "D":
    case "E":
      color = "#ff1744";
      break;
    case "F":
    case "G":
    case "H":
    case "I":
    case "J":
      color = "#651fff";
      break;
    case "K":
    case "L":
    case "M":
    case "N":
    case "O":
      color = "#00e676";
      break;
    case "P":
    case "Q":
    case "R":
    case "S":
    case "T":
      color = "#2979ff";
      break;
    case "U":
    case "V":
    case "W":
    case "X":
    case "Y":
    case "Z":
      color = "#f50057";
      break;
    default:
      color = "#000000";
      break;
  }

  return color;
}
