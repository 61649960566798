import React, { useEffect, useMemo, useState } from "react";
import log from "loglevel";
import {
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../../hooks/employeeMachineHooks";
import {
  CONST_COFF_STATUS,
  CONST_USER_TYPES,
} from "../../../../constants/dbconstants";

import { Button } from "../../../CustomComponentsLibrary/Button";
import { Edit2, Trash } from "lucide-react";
import { FriendlyName } from "../../../../lib/friendlyName";
import { DataTableColumnHeader } from "../../../CustomComponentsLibrary/data-table-column-header";
import FormatDateAndTime from "../../../common/FormatDateAndTime";
import { HoverCard } from "@radix-ui/react-hover-card";
import { ShowLeavesOverview } from "../../../common/ShowLeavesOverview";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import PageHeader from "../../../common/PageHeader";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from "../../../CustomComponentsLibrary/Label";
import { Input } from "../../../CustomComponentsLibrary/Input";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
import Dropdown from "../../../common/Dropdown";
import { Checkbox } from "../../../CustomComponentsLibrary/Checkbox";
import { toast } from "../../../CustomComponentsLibrary/UseToast";
import { hookGetEmployeeShortProfileById } from "../../../../hooks/employeeHooks";
import { sortByKeyAlphabetical } from "../../../common/SortByName";
import { User } from "../../../common/User"

export default function ListCOffs() {
  const employeeMachineService = useEmployeeContextService();
  const {
    coffs,
    myEmployeeProfile,
    userID,
    teamMembersNames,
    leavesOverview,
    employeeList,
  } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const [selecetdStartDate, setSelecetdStartDate] = useState();
  const [selecetdEndDate, setSelecetdEndDate] = useState();
  const [currentUserCoffs, setCurrentUserCoffs] = useState();
  const [rowSelected, setRowSelected] = useState({});
  const [approveDisable, setApproveDisable] = useState(false);
  const [rejectDisable, setRejectDisable] = useState(false);

  const coffStatusLabels = [
    {
      value: CONST_COFF_STATUS.coffSubmitted,
      label: "Submitted",
    },
    {
      value: CONST_COFF_STATUS.coffRejected,
      label: "Rejected",
    },
    {
      value: CONST_COFF_STATUS.coffApproved,
      label: "Approved",
    },
  ];

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => {
            table.toggleAllPageRowsSelected(!!value);
            log.debug("valueadd", table);
          }}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <>
          {row?.original.status != CONST_COFF_STATUS.coffApproved && (
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => {
                log.debug("selecetd Tixck", value, row, row.getIsSelected());
                row.toggleSelected(!!value);
              }}
              aria-label="Select row"
              className="translate-y-[2px]"
            />
          )}
        </>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      header: "Details",
      accessorKey: "details",
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
    },
    {
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Coff Date" />
      ),
      accessorKey: "date",
      cell: ({ row }) => {
        const dates = row.getValue("date");
        return <FormatDateAndTime dateTime={dates} format={"DD/MM/YYYY"} />;
      },
    },

    {
      header: "Quantity",
      cell: ({ row }) => {
        if (row.original?.quantity == 0.5) {
          return "Half Day";
        } else if (row.original?.quantity == 1) {
          return "Full Day";
        } else {
          return "";
        }
      },
    },

    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => <FriendlyName status={row.getValue("status")} />,
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
    {
      header: "Approved By",
      accessorKey: "approvedBy",
      cell: ({ row }) => <User userID={row.getValue("approvedBy")} />
    },
    {
      header: "Actions",
      accessorKey: "",
      cell: ({ row }) => (
        <div className="flex flex-row gap-x-2">
          <HoverCard>
            <div
              title="Edit leave"
              description={"Click this button to delete the leave."}
            >
              <Button
                disabled={
                  row.original?.status ===
                    CONST_COFF_STATUS.coffApproved ||
                  row.original?.status ===
                    CONST_COFF_STATUS.coffRejected
                }
                variant="link"
                className="font-bold"
                size="sm"
                onClick={() => {
                  employeeMachineService.send({
                    type: "leaves_crud",
                    data: {
                      actionForCoffCRUD: "EDIT",
                      selectedCoff: row.original,
                    },
                  });
                }}
              >
                <Edit2 className="h-4 w-4" />
              </Button>
            </div>
          </HoverCard>
          <div
            title={"Delete leave"}
            description={"Click this button to delete the leave."}
          >
            <Button
              variant="link"
              className="font-bold"
              size="sm"
              disabled={
                row.original?.status ===
                  CONST_COFF_STATUS.coffApproved ||
                row.original?.status === CONST_COFF_STATUS.coffRejected
              }
              onClick={() => {
                employeeMachineService.send({
                type: "coff_crud",
                  data: {
                    actionForCoffCRUD: "DELETE",
                    selectedCoff: row.original,
                    selectedCoffId: row.original?.id,
                  },
                });
              }}
            >
              <Trash className="h-4 w-4" />
            </Button>
          </div>
        </div>
      ),
    },
  ];
  const sendEvent = async () => {
    const dataToSend = {
      firstday: selecetdStartDate,
      lastDay: selecetdEndDate,
    };

    employeeMachineService.send({
      type: "reload_leaves_for_selectedyear",
      data: {
        selectedYear: dataToSend,
      },
    });
  };

  const   approveRejectCoff = (type) => {
    const selectedKeys = Object.keys(rowSelected);
    const filteredCoffs = coffs?.filter((item, index) => {
      return selectedKeys?.includes(index.toString()) ? true : false;
    });

    log.debug("filtered coffs", filteredCoffs);
    if (filteredCoffs) {
      if (type == "Approve") {
        employeeMachineService.send({
          type: "action_on_teammember_coffs",
          data: {
            selectedCoffsForAction: filteredCoffs,
            actionOnCoff: "Approve",
          },
        });
      } else if (type == "Reject") {
        employeeMachineService.send({
          type: "action_on_teammember_coffs",
          data: {
            selectedCoffsForAction: filteredCoffs,
            actionOnCoff: "Reject",
          },
        });
      }
    }

    setRowSelected({});
  };
  useEffect(() => {
    if (currentUserCoffs?.id) {
      employeeMachineService.send({
        type: "reload_coffs",
        data: {
          userID: currentUserCoffs?.id,
        },
      });
    }
  }, [currentUserCoffs]);
  //teamMember for dropdown
  const filteredTeamMembers = useMemo(() => {
    if (
      employeeList &&
      myEmployeeProfile?.employees &&
      myEmployeeProfile?.userType
        ?.split(",")
        .some((type) =>
          [CONST_USER_TYPES.manager, CONST_USER_TYPES.teamLead].includes(
            type.trim()
          )
        )
    ) {
      const filteredMembers = employeeList?.filter((member) =>
        myEmployeeProfile?.employees?.includes(member?.id)
      );

      // const filteredMembers = teamMembersNames?.filter((member) =>
      //   myEmployeeProfile?.employees?.includes(member?.id)
      // );

      const currentUserInfo = {
        id: myEmployeeProfile?.cognitoId,
        title: `${myEmployeeProfile?.firstName} ${myEmployeeProfile?.LastName}`,
      };
      if (
        !filteredMembers?.some((member) => member?.id === currentUserInfo?.id)
      ) {
        filteredMembers?.unshift(currentUserInfo);
      }

      return filteredMembers?.map((member) => ({
        id: member.id,
        title: member.title,
      }));
    }
    return null;
  }, [employeeList, myEmployeeProfile?.employees]);
  if (filteredTeamMembers)
    log.debug("filteredTeamMembers", filteredTeamMembers);

  useEffect(() => {
    const selectedKeys = Object.keys(rowSelected);
    const filteredCoffs = coffs?.filter((item, index) => {
      return selectedKeys?.includes(index.toString()) ? true : false;
    });
    const isAnyCoffAlreadyApproved = filteredCoffs?.some(
      (leave) => leave?.status === CONST_COFF_STATUS.coffApproved
    );
    const isAnyCoffAlreadyRejected = filteredCoffs?.some(
      (leave) => leave?.status === CONST_COFF_STATUS.coffRejected
    );
    if (isAnyCoffAlreadyApproved) {
      // Show an error message or handle the error accordingly
      toast({
        variant: "destructive",
        title: "Failed",
        description: "already approved leaves",
      });
    }
    setApproveDisable(isAnyCoffAlreadyApproved);
    setRejectDisable(isAnyCoffAlreadyRejected);
  }, [rowSelected]);

  useEffect(() => {
    return () => {
      employeeMachineService.send({
        type: "reload_leaves",
        data: {
          userID: myEmployeeProfile?.cognitoId,
        },
      });
    };
  }, []);
  log.debug("leavesOverview", leavesOverview);
  const coffleaves = coffs?.filter(
    (coff) =>  
      coff?.coff === CONST_COFF_STATUS.coffApproved
  );
  log.debug("coffleave::C-Off", coffleaves, coffs);
  return (
    <div className="m-4">
      <div className="sm:flex">
        <div className="sm:flex-auto">
          <PageHeader
            title="C-Offs"
            SubHeading="Overview of all your coffs"
            showDropdown={false}
          />
        </div>

        <ShowLeavesOverview
          leavesOverview={leavesOverview}
        />

        <div className="sm:mt-0 sm:ml-16 sm:flex-none">
          {(myEmployeeProfile?.userType.includes("TEAMLEAD") ||
            myEmployeeProfile?.userType?.includes("MANAGER")) && (
            <div className="mb-20">
              <Dropdown
                list={sortByKeyAlphabetical(filteredTeamMembers, "title")}
                setSelected={setCurrentUserCoffs}
                selected={currentUserCoffs}
                className="w-auto"
                placeholder="Select Team Member"
                required
                nameField="title"
              />
            </div>
          )}
          <div className="flex sm:flex-row flex-col sm:w-full w-32 gap-5">
            <Button
              type="button"
              variant="default"
              className={`"" ${theme?.background} ${theme?.textColor}`}
              onClick={() => {
                //  TODO SEND EVENT TO MACHINE
                employeeMachineService.send({
                  type: "coff_crud",
                  data: {
                    actionForCoffCRUD: "ADD",
                  },
                });
              }}
            >
              <span> Request COff </span>
            </Button>
          </div>
        </div>
      </div>

      <div className="shadow-md sm:rounded-lg border-t border-t-zinc-200 mt-5">
        <div className="font-semibold text-sm mt-8 m-5 pb-5">
          <div className="w-full flex  text-base sm:w-96 z-30">
            <div className="flex flex-col sm:flex-row mb-0 lg:mb-[-30px] justify-end">
              <div className="flex flex-col sm:flex-row py-2 justify-end">
                <div className="m-2">
                  <Label>
                    Start Date
                    <span className="text-danger text-base font-bold">*</span>
                  </Label>
                  <Input
                    type="date"
                    onChange={(e) => {
                      setSelecetdStartDate(e.target.value);
                    }}
                    name="startdate"
                    id="startdate"
                    value={selecetdStartDate}
                    className="border-2 border-black p-1 "
                  />
                </div>
                <div className="m-2">
                  <Label>
                    End Date
                    <span className="text-danger text-base font-bold">*</span>
                  </Label>
                  <Input
                    type="date"
                    onChange={(e) => {
                      setSelecetdEndDate(e.target.value);
                    }}
                    name="enddate"
                    id="enddate"
                    value={selecetdEndDate}
                    className="border-2 border-black p-1 "
                  />{" "}
                </div>
                <div className="mt-8">
                  <Button
                    type="button"
                    variant="default"
                    className={`"" ${theme?.background} ${theme?.textColor}`}
                    onClick={() => {
                      sendEvent();
                    }}
                  >
                    View C-Offs By Date
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {(myEmployeeProfile?.userType.includes("TEAMLEAD") ||
            myEmployeeProfile?.userType?.includes("MANAGER")) &&
            myEmployeeProfile?.cognitoId != userID && (
              <div className=" flex flex-row justify-end mb-1 mr-5">
                <Button
                  type="button"
                  variant="default"
                  className="bg-green-600 mr-2 text-white"
                  size="sm"
                  disabled={approveDisable}
                  onClick={() => {
                    approveRejectCoff("Approve");
                  }}
                >
                  Approve
                </Button>
                <Button
                  type="button"
                  variant="default"
                  className="bg-red-600 ml-2 text-white"
                  size="sm"
                  disabled={rejectDisable || approveDisable}
                  onClick={() => {
                    approveRejectCoff("Reject");
                  }}
                >
                  Reject
                </Button>
              </div>
            )}

          <DataTable
            columns={columns}
            data={coffs}
            setRowSelected={setRowSelected}
            rowSelected={rowSelected}
            searchableColumns={[{ id: "details", details: "details" }]}
            filterableColumns={[
              {
                id: "status",
                title: "status",
                options: coffStatusLabels.map(({ value, label }) => ({
                  label: label,
                  value: value,
                })),
              },
            ]}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 5,
                widthClass: "30",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
