import React from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/CustomComponentsLibrary/Tabs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
export function TabPageLink({ tabs = [] }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useAuthContextData();
  const initialTab = tabs
    ?.map(({ path }) => path)
    ?.findLast((path) => location?.pathname?.includes(path));

  const [activeTab, setActiveTab] = React.useState(initialTab || "tab1");
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [tabs, initialTab]);

  const handleTabChange = (value) => {
    //update the state
    setActiveTab(value);
    // update the URL query parameter
    navigate(value);
  };
  return (
    <div className="w-full flex flex-col h-full">
      <Tabs onValueChange={handleTabChange} value={activeTab} className="m-4">
        <TabsList className="w-max pt-1 pb-1">
          {tabs?.map((route) => (
            <TabsTrigger
              key={route?.path}
              value={route?.path}
              className={`${theme?.activeTab}`}
            >
              {route?.name}
            </TabsTrigger>
          ))}
        </TabsList>
        {tabs?.map((route) => (
          <TabsContent key={route?.path} value={route?.path}>
            <Outlet />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
}
