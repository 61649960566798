import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddEmployeeTask = async (EmployeeTask) => {
  log.debug(" hookAddEmployeeTask : ", EmployeeTask);

  try {
    const result = await API.graphql({
      query: mutations.createEmployeeTask,
      variables: {
        input: EmployeeTask,
      },
    });

    log.debug(" hookAddEmployeeTask result: ", result);

    return result?.data?.createEmployeeTask;
  } catch (err) {
    log.debug("CATCH ERROR hookAddEmployeeTask result: ", err);
    return null;
  }
};

export const hookUpdateEmployeeTask = async (EmployeeTask) => {
  log.debug(" hookUpdateEmployeeTask : ", EmployeeTask);
  try {
    const result = await API.graphql({
      query: mutations.updateEmployeeTask,
      variables: {
        input: EmployeeTask,
      },
    });

    log.debug(" hookUpdateEmployeeTask result: ", result);
    return result?.data?.updateEmployeeTask;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateEmployeeTask result: ", err);
  }
};

export const hookDeleteEmployeeTaskByEmployeeTaskId = async (
  EmployeeTaskId
) => {
  log.debug(" hookDeleteEmployeeTaskByEmployeeTaskId  ", EmployeeTaskId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteEmployeeTask,
      variables: {
        input: { id: EmployeeTaskId },
      },
    });

    log.debug(" hookDeleteEmployeeTaskByEmployeeTaskId : ", data);
    if (data !== null) return data?.data?.deleteEmployeeTask;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeleteEmployeeTaskByEmployeeTaskUniqueID -- ",
      e
    );
    return null;
  }
};

export const hookListEmployeeTaskByUserId = async (uniqueId) => {
  log.debug(" hookListEmployeeTaskByUserId uniqueId", uniqueId);
  try {
    const data = await API.graphql({
      query: queries.TasksByUserID,
      variables: {
        userID: uniqueId,
      },
    });

    log.debug(" hookListEmployeeTaskByUserId : ", data);

    if (data !== null) return data?.data?.TasksByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeTaskByUserId -- ", e);
    return null;
  }
  //Connect Client Amplify GraphQL
  //TODO LIST BY EMPLOYEE ID
};

export const hookListEmployeeTask = async () => {
  try {
    const data = await API.graphql({
      query: queries.listEmployeeTasks,
      variables: {},
    });

    log.debug(" hookListEmployeeTaskByUserId : ", data);

    if (data !== null) return data?.data?.listEmployeeTasks.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeTaskByUserId -- ", e);
    return null;
  }
};

export const hookGetEmployeeTaskStatusByUserId = async (uniqueId) => {
  log.debug(" hookGetEmployeeTaskStatusByUserId uniqueId", uniqueId);
  try {
    const data = await API.graphql({
      query: customQueries.tasksProgressByUserID,
      variables: {
        userID: uniqueId,
      },
    });

    log.debug(" hookGetEmployeeTaskStatusByUserId : ", data);

    if (data !== null) return data?.data?.TasksByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeTaskStatusByUserId -- ", e);
    return null;
  }
};
