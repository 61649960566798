const LOG_HOUR = [
  {
    /* ipac Automation */
    orgId: "443676fb-7d78-4cd8-8558-d9175b434dcf",
    workingHour: 24,
    halfDayWorkingHour: 5,
  },
  {
    orgId: "5bb6314a-b79c-4a3c-9e90-1e26500f5a71",
    workingHour: 12,
    halfDayWorkingHour: 5,
  },
];

export const getLogHourByOrgId = (id) => {
  const ORG_LOG_HOUR = LOG_HOUR?.find((item) => item.orgId == id);
  return ORG_LOG_HOUR;
};
