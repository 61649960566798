import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddCoffComment = async (comment) => {
  log.debug(" hookAddcoffComment : ", comment);

  try {
    const result = await API.graphql({
      query: mutations.createCoffComment,
      variables: {
        input: comment,
      },
    });

    log.debug(" hookAddcoffComment result: ", result);

    return result?.data?.createcoffComment;
  } catch (err) {
    log.debug("CATCH ERROR hookAddcoffComment result: ", err);
    return null;
  }
};

export const hookUpdateCoffComment = async (coff) => {
  log.debug(" hookUpdatecoffComment : ", coff);
  try {
    const result = await API.graphql({
      query: mutations.updateCoffComment,
      variables: {
        input: coff,
      },
    });

    log.debug(" hookUpdatecoffComment result: ", result);
    return result?.data?.updatecoffComment;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdatecoffComment result: ", err);
  }
};

export const hookDeleteCommentByCoffId = async (coffId) => {
  log.debug(" hookDeleteCommentBycoffId  ", coffId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteCoffComment,
      variables: {
        input: { id: coffId },
      },
    });

    log.debug(" hookDeleteCommentBycoffId : ", data);
    if (data !== null) return data?.data?.deletecoffComment;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteCommentBycoffId -- ", e);
    return null;
  }
};

export const hookListCommentsByCoffID = async (currentcoffID) => {
  try {
    const data = await API.graphql({
      query: queries.CoffCommentsByLeaveID,
      variables: { coffId: currentcoffID },
    });

    log.debug(" hookListCommentsBycoffID : ", data);

    if (data !== null) return data?.data?.coffCommentsBycoffID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCommentsBycoffID -- ", e);
    return null;
  }
};
