import React, { useMemo, useState } from "react";
import log from "loglevel";
import LineChart from "../../charts/LineChart";
import { tailwindConfig } from "../../utils/Utils";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { useSelector } from "@xstate/react";
import { Skeleton } from "../../../components/CustomComponentsLibrary/Skeleton";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export function TimesheetChart() {
  const { dashboardData } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const { maxHours, teamTimesheetCurrentWeek } = dashboardData;
  const [selectedValue, setSelectedValue] = useState("Your Timesheet");
  const employeeMachineService = useEmployeeContextService();
  const isTimesheetLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );
  log.debug(
    "DSHBOARD_DATA",
    dashboardData.myTimeSheetCurrentWeekLastWeek.currentWeek
  );
  const showSkeleton = [1, 2, 3, 4, 5];
  const colorArray = [
    tailwindConfig().theme.colors.indigo[500],
    tailwindConfig().theme.colors.lime[500],
    tailwindConfig().theme.colors.green[500],
    tailwindConfig().theme.colors.sky[500],
    tailwindConfig().theme.colors.blue[500],
    tailwindConfig().theme.colors.pink[500],
    tailwindConfig().theme.colors.rose[500],
  ];

  const top5TeamMembers = useMemo(() => {
    return teamTimesheetCurrentWeek
      .sort(
        (a, b) =>
          parseFloat(Object.values(b)[0].avgProgress) -
          parseFloat(Object.values(a)[0].avgProgress)
      )
      .slice(0, 5);
  }, [teamTimesheetCurrentWeek]);

  const chartDataForTeamTimesheet = {
    labels: dashboardData.weekDays,
    datasets: top5TeamMembers?.map((teamHours, index) => {
      const personName = Object.keys(teamHours)[0];
      const hourArray = teamHours[personName];

      return {
        label: personName,
        data: hourArray,
        borderColor: colorArray[index % 5],
        fill: false,
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      };
    }),
  };

  const chartDataForMyTimesheet = useMemo(() => {
    return {
      labels: dashboardData.weekDays,
      datasets: [
        // Indigo line
        {
          label: "Current Week",
          data: dashboardData.myTimeSheetCurrentWeekLastWeek.currentWeek,
          borderColor: "#0048B2",
          backgroundColor: "#0048B2",
          fill: false,
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: "#0048B2",
        },
        // Blue line
        {
          label: "Last Week",
          data: dashboardData.myTimeSheetCurrentWeekLastWeek.lastWeek,
          borderColor: "#08B1BA",
          backgroundColor: "#08B1BA",
          fill: false,
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: "#08B1BA",
        },
      ],
    };
  }, [dashboardData]);

  return (
    <>
      <div className=" text-[19px] shadow-lg rounded-md border-t border-t-zinc-100 h-96">
        <div className="px-5 py-6">
          <span className="font-extrabold">Timesheet Over Time</span>
          <div className="float-right">
            <span className={`${theme?.hoverbg}`}>
              <select
                id="myDropdown"
                value={selectedValue}
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                }}
                className="border px-3 py-1 mb-2 text-sm  rounded-md"
              >
                <option value="Your Timesheet">Your Timesheet</option>
                <option value="Team Timesheet">Team Timesheet</option>
              </select>
            </span>
          </div>
        </div>
        {!isTimesheetLoading ? (
          <>
            <div className="text-sm font-bold rounded-none px-3 border-none text-foreground">
              {dashboardData?.weekDays ? (
                selectedValue === "Your Timesheet" ? (
                  <LineChart
                    data={chartDataForMyTimesheet}
                    width={595}
                    height={248}
                    maxHours={maxHours}
                    avg={
                      dashboardData?.myTimeSheetCurrentWeekLastWeek
                        ?.myCurrenWeekAvg || 0
                    }
                  />
                ) : (
                  <div></div>
                )
              ) : null}

              {dashboardData?.weekDays ? (
                selectedValue === "Team Timesheet" ? (
                  <LineChart
                    data={chartDataForTeamTimesheet}
                    width={595}
                    height={248}
                    maxHours={maxHours}
                    avg={dashboardData?.teamAvgHours || 0}
                  />
                ) : (
                  <div></div>
                )
              ) : null}
            </div>
          </>
        ) : (
          <>
            {showSkeleton?.map(() => (
              <div className="flex items-center w-full m-3 ">
                <Skeleton className="h-12 w-12 rounded-full" />
                <div className="space-y-2  w-[95%]">
                  <Skeleton className="h-4 w-[95%]" />
                  <Skeleton className="h-4 w-[50%]" />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}
