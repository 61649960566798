import React, { useState } from "react";
import {
  useAuthContextData,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import { Button } from "../CustomComponentsLibrary/Button";

export default function WelcomeComponent() {
  const [isCandidateForValidOrg, setIsCandidateForValidOrg] = useState(false);

  const authService = useAuthService();
  const { theme } = useAuthContextData();
  return (
    <>
      <div className="mx-auto flex h-full w-full max-w-7xl flex-grow flex-col sm:justify-center items-center align-middle md:justify-center md:items-center md:align-middle px-4 sm:px-6  lg:px-8">
        <div className="justify-left w-max rounded-full  p-1 md:flex md:w-20 md:justify-center"></div>
        <div className="ml-3 text-left md:place-items-center md:text-center">
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-delightmenuRichBlack sm:text-5xl">
            Welcome to<br></br> Delight360
          </h1>
          <p className="invisible mt-2 text-base text-delightmenuRichBlack md:visible">
            Super App for your Business!
          </p>
          <div className="mt-12 flex flex-col place-content-center gap-3 md:mt-6 md:flex-row">
            <Button
              className={`"w-full items-center  px-4 py-3 text-center text-base
              font-medium  md:w-32 md:max-w-sm md:py-1" ${theme?.background} ${theme?.textColor}`}
              onClick={() => {
                authService.send({ type: "signInOptions" });
              }}
            >
              Sign In
            </Button>

            <Button
              className={`"w-full items-center   px-4 py-3 text-center text-base
               font-medium  md:w-32 md:max-w-sm md:py-1" ${theme?.background} ${theme?.textColor}`}
              onClick={() => {
                authService.send({
                  type: "signUpOptions",
                  data: isCandidateForValidOrg,
                });
              }}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
