import React from "react";
import Lessons from "../../components/employee/Courses/Lessons";
import { useEmployeeContextState } from "../../hooks/employeeMachineHooks";
import log from "loglevel";
import UpdateCourseProgress from "../../components/employee/Courses/UpdateCourseProgress";
export default function LessonsLayout() {
  const { courseCrud } = useEmployeeContextState();
  log.debug("courseCrudData", courseCrud);
  return (
    <div className="h-full md:rounded-sm lg:rounded-md">
      <Lessons />
      <> {courseCrud ? <UpdateCourseProgress /> : <></>}</>
    </div>
  );
}
