import React, { useState } from "react";
import log from "loglevel";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "../CustomComponentsLibrary/Button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../CustomComponentsLibrary/HoverCard";

function PopoverCard(props) {
  const { title, description, children } = props;

  return (
    <div>
      <HoverCard>
        <HoverCardTrigger>{children}</HoverCardTrigger>
        <HoverCardContent>
          <div className="font-bold">{title}</div>
          <div className="text-sm">{description}</div>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
}

export default PopoverCard;
