/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from "react";
import log from "loglevel";
import { Transition } from "@headlessui/react";
import {
  XMarkIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";

export default function ToastMessage(props) {
  const { messageType, message, showToast, HideToastMessage } = props;

  log.debug("ToastMessage props", props);
  const bgColor = messageType === "failure" ? "red" : "white";

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showToast}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={
                messageType === "Failure"
                  ? "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-red-200 shadow-lg ring-1 ring-black ring-opacity-5"
                  : "bg-green-200 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
              }
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {messageType === "Failure" ? (
                      <XCircleIcon
                        className="h-6 w-6 text-danger"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckCircleIcon
                        className="h-6 w-6 text-success"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-lg font-medium text-muted-foreground">
                      {messageType}
                    </p>
                    <p className="mt-1 text-lg text-foreground">{message}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      // onClick={() => {
                      //   HideToastMessage
                      // }}
                      onClick={HideToastMessage}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
