import React from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../CustomComponentsLibrary/Tabs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
export default function TabPageLink2({ tabs = [] }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useAuthContextData();
  const initialTab = tabs
    ?.map(({ path }) => path)
    ?.findLast((path) => location?.pathname?.includes(path));

  const [activeTab, setActiveTab] = React.useState(initialTab || "tab1");
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [tabs, initialTab]);

  const handleTabChange = (value) => {
    //update the state
    setActiveTab(value);
    // update the URL query parameter
    navigate(value);
  };
  return (
    <div className="w-full flex flex-col h-full">
      <Tabs defaultValue={tabs[0].name} className="m-2">
        <TabsList className="w-max">
          {tabs.map((route) => (
            <TabsTrigger value={route.name} className={`${theme?.activeTab}`}>
              {route?.name}
            </TabsTrigger>
          ))}
        </TabsList>
        {tabs.map((route) => (
          <TabsContent value={route.name}>{route.element}</TabsContent>
        ))}
      </Tabs>
    </div>
  );
}
