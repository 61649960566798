import React from "react";
import log from "loglevel";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { FriendlyName } from "../../../lib/friendlyName";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { DashboardDataTableWrapper } from "./DashboardDataTableWrapper";
import { Progress } from "../../../components/CustomComponentsLibrary/Progress";
import FormatDateAndTime from "../../../components/common/FormatDateAndTime";
import { useSelector } from "@xstate/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export function MyGoals() {
  const { dashboardData } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const { goals } = dashboardData;
  log.debug("goals from dashboard ", dashboardData);
  const employeeMachineService = useEmployeeContextService();
  const isGoalsLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );

  const columns = [
    {
      header: "Name",
      accessorKey: "title",
    },
    {
      accessorKey: "dueDate",
      header: "Date",
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row.original?.dueDate}
          format={"DD/MM/YYYY"}
        />
      ),
    },
    {
      header: "Progress",
      accessorKey: "percentage",
      cell: ({ row }) => (
        <>
          <>
            <div className="flex  ">
              <Progress
                value={row.original.percentage}
                className="w-[40%] m-2"
                rootCss={`${theme?.bgLight}`}
                indicatorCss={`${theme?.bgCommon}`}
              />
              <div>{`${parseInt(row.getValue("percentage"))}% `}</div>
            </div>
          </>
        </>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <div>
          <FriendlyName status={row.getValue("status")} />
        </div>
      ),
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
  ];

  return (
    <DashboardDataTableWrapper
      title={"My Goals"}
      action={"View"}
      actionLink={"/employee/goals"}
    >
      <DataTable
        columns={columns}
        data={goals}
        dataTableToolBar={false}
        loader={isGoalsLoading}
        customCss={`${theme?.border}`}
        customWidth={[
          {
            index: 0,
            widthClass: "w-64",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 1,
            widthClass: "w-24",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 2,
            widthClass: "w-28",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
          {
            index: 3,
            widthClass: "w-20",
            cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
          },
        ]}
      />
    </DashboardDataTableWrapper>
  );
}
