import { Outlet } from "react-router";
import SignUp from "../components/common/SignUp.jsx";
import SignIn from "../components/common/SignIn.jsx";
import Auth from "../components/common/Auth.jsx";
import CandidateAuth from "../components/common/CandidateAuth.jsx";
import EmployeeDashboard from "../pages/employee/EmployeeDashboard";
import EmployeeWithContextLayout from "../contextLayout/EmployeeWithContextLayout";
import { ContactAdmin } from "./../components/common/ContactAdmin";
import Success from "../components/common/Success";
import Cancel from "../components/common/Cancel";
import History from "../components/employee/Projects/History.jsx";
import { EmployeeProjectsTasks } from "../pages/employee/EmployeeProjectsTasks.jsx";
import CoursesLayout from "../pages/employee/CoursesLayout.jsx";
import LessonsLayout from "../pages/employee/LessonsLayout.jsx";
import CoursesList from "../components/employee/Courses/CoursesList.jsx";
import Tasks from "../pages/employee/Tasks.jsx";
import { EmployeeGoalsLayout } from "../pages/employee/EmployeeGoalsLayout.jsx";
import Goals from "../pages/employee/Goals.jsx";
import Achivements from "../pages/employee/Achivements.jsx";
import { ProjectSettingsLayout } from "../components/employee/Projects/ProjectSettingsLayout.jsx";
import LoghoursLayout from "../pages/employee/LoghoursLayout.js";
import { EmployeePayrollLayout } from "../pages/employee/metrics/EmployeePayrollLayout.jsx";
import SalarySlip from "../components/employee/Payroll/SalarySlip.jsx";
import Form16 from "../components/employee/Payroll/Form16.jsx";
import Bonus from "../components/employee/Payroll/Bonus.jsx";
import { ReimbursementLayout } from "../pages/employee/metrics/ReimbursementLayout.jsx";
import { EmployeeCalendarLayout } from "../pages/employee/metrics/EmployeeCalendarLayout.jsx";
import { EmployeeSettingLayout } from "../pages/employee/EmployeeSettingLayout.jsx";
import UpdateEmployeeProfile from "../components/employee/EmployeeProfile/UpdateEmployeeProfile.jsx";

import { PolicyLayout } from "../pages/employee/metrics/PolicyLayout.jsx";
import { EmployeeModuleEmployeeHandbook } from "../components/employee/Policy/EmployeeModuleEmployeeHandbook.jsx";
import { EmployeeModuleLeavesPolicy } from "../components/employee/Policy/EmployeeModuleLeavesPolicy.jsx";
import { InventoryLayout } from "../pages/employee/metrics/InventoryLayout.jsx";
import UploadEmployeeDocs from "../components/employee/uploadFiles/UploadEmployeeDocs.jsx";
import { LeavesLayout } from "../pages/employee/metrics/LeavesLayout.jsx";
import { ManageProject } from "../components/employee/Projects/ManageProject.jsx";
import { OrganizationGraph } from "../components/employee/EmployeeProfile/OrganizationGraph.jsx";
import LogHoursByProject from "../components/employee/Projects/LogHoursByProject.jsx";
import Blogs from "../components/employee/EmployeeProfile/blogs/Blogs.js";
import BlogDetails from "../components/employee/EmployeeProfile/blogs/BlogDetails.jsx";
import { EmployeeBlogsLayout } from "../pages/employee/EmployeeBlogsLayout.jsx";
export const everyUserIsEmployeeRoutes = [
  {
    path: "/",
    element: <EmployeeWithContextLayout />,
    children: [
      {
        index: true,
        path: "/",
        element: <EmployeeDashboard />,
        //element: <EmployeeDashboardSample />,
      },
      {
        path: "/employee/tasks",
        element: (
          <>
            <Tasks />
          </>
        ),
      },
      {
        path: "/employee/goals",
        element: (
          <>
            <EmployeeGoalsLayout />,
          </>
        ),
      },
      {
        path: "/employee/goals",
        element: <EmployeeGoalsLayout />,
        children: [
          {
            path: "/employee/goals/",
            element: <Goals />,
          },
          {
            path: "/employee/goals/achivements",
            element: <Achivements />,
          },
        ],
      },
      {
        path: "/employee/projects",
        element: <ProjectSettingsLayout />,
        children: [
          {
            path: "/employee/projects/:currentUserId",
            element: <EmployeeProjectsTasks />,
          },
          {
            path: "/employee/projects/history/:currentUserId",
            element: <History />,
          },
          {
            path: "/employee/projects/loghours/:currentUserId",
            element: (
              <>
                <LoghoursLayout />
              </>
            ),
          },
          {
            path: "/employee/projects/manage/:currentUserId",
            element: (
              <>
                <ManageProject />
              </>
            ),
          },
          {
            path: "/employee/projects/loghoursbyproject/:currentUserId",
            element: (
              <>
                <LogHoursByProject />
              </>
            ),
          },
          {
            path: "/employee/projects/myloghours",
            element: <></>,
          },
        ],
      },

      {
        path: "/employee/payroll",
        element: <></>,
      },
      {
        path: "/employee/courses",
        element: (
          <>
            <CoursesLayout />
          </>
        ),
        children: [
          {
            index: true,
            path: "/employee/courses/",
            element: (
              <>
                <CoursesList />
              </>
            ),
          },
          {
            path: "/employee/courses/:courseId/lessons",
            element: (
              <>
                <Outlet />
              </>
            ),
            children: [
              {
                index: true,
                path: "/employee/courses/:courseId/lessons/",
                element: (
                  <>
                    <>
                      <LessonsLayout />
                    </>
                  </>
                ),
              },
              {
                path: "/employee/courses/:courseId/lessons/:lessonId/topics",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  {
                    index: true,
                    path: "/employee/courses/:courseId/lessons/:lessonId/topics/",
                    element: <></>,
                  },
                  {
                    path: "/employee/courses/:courseId/lessons/:lessonId/topics/:topicId/topicresources",
                    element: (
                      <>
                        <Outlet />
                      </>
                    ),
                    children: [
                      {
                        path: "/employee/courses/:courseId/lessons/:lessonId/topics/:topicId/topicresources/:topicRefid?",
                        element: <></>,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/employee/payroll",
        element: (
          <>
            <EmployeePayrollLayout />,
          </>
        ),
      },
      {
        path: "/employee/payroll",
        element: <EmployeePayrollLayout />,
        children: [
          {
            path: "/employee/payroll/",
            element: <SalarySlip />,
          },
          {
            path: "/employee/payroll/reimbursements",
            element: <ReimbursementLayout />,
          },
          {
            path: "/employee/payroll/form",
            element: <Form16 />,
          },
          {
            path: "/employee/payroll/bonus",
            element: <Bonus />,
          },
          {
            path: "/employee/payroll/others",
            element: <></>,
          },
        ],
      },
      // {
      //   path: "/employee/calendar",
      //   element: <EmployeeCalendarLayout />,
      //   children: [
      //     {
      //       path: "/employee/calendar/",
      //       element: <TimeSheet />,
      //     },
      //     {
      //       path: "/employee/calendar/leaves",
      //       element: <LeavesLayout />,
      //     },
      //     {
      //       path: "/employee/calendar/meetings",
      //       element: <></>,
      //     },
      //     {
      //       path: "/employee/calendar/holidays",
      //       element: <EmployeeHolidays />,
      //     },
      //   ],
      // },
      {
        path: "/employee/calendar",
        element: <EmployeeCalendarLayout />,
      },
      {
        path: "/employee/leaves",
        element: <LeavesLayout />,
      },
      {
        path: "/employee/policy",
        element: <PolicyLayout />,
      },
      {
        path: "/employee/policy",
        element: <PolicyLayout />,
        children: [
          {
            path: "/employee/policy/",
            element: <EmployeeModuleEmployeeHandbook />,
          },
          {
            path: "/employee/policy/leavespolicy",
            element: <EmployeeModuleLeavesPolicy />,
          },
        ],
      },
      {
        path: "/employee/settings",
        element: <EmployeeSettingLayout />,
        children: [
          {
            path: "/employee/settings/",
            element: <UpdateEmployeeProfile />,
          },
          {
            path: "/employee/settings/inventory",
            element: <InventoryLayout />,
          },
          {
            path: "/employee/settings/documents",
            element: <UploadEmployeeDocs />,
          },
          {
            path: "/employee/settings/organization",
            element: <OrganizationGraph />,
          },
        ],
      },
      {
        path: "/employee/blogs",
        element: <EmployeeBlogsLayout />,
        children: [
          {
            path: "/employee/blogs",
            element: <Blogs />,
          },
          {
            path: "/employee/blogs/details",
            element: <BlogDetails />,
          },
          {
            path: "/employee/blogs/add",
            // element: <WriteBlog />,
          },
          {
            path: "/employee/blogs/edit",
            // element: <EditBlog />,
          },
        ],
      },
      { path: "*", element: <div> NO MATCH FOUND </div> },
    ],
  },
];
export const notEmployee = [
  {
    path: "/",
    element: <ContactAdmin />,
  },
];
export const authRoutes = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <Auth />,
      },
      {
        path: "/candidate/:orgDomainName?",
        element: <CandidateAuth />,
      },
      {
        path: "/signup",
        element: (
          <div>
            <SignUp />
          </div>
        ),
      },
      {
        path: "/signin",
        element: (
          <div>
            <SignIn />
          </div>
        ),
      },
      {
        path: "/success/:orgpaymentid",
        element: (
          <div>
            <Success />
          </div>
        ),
      },
      {
        path: "/failed/:orgpaymentid",
        element: (
          <div>
            <SignUp />
          </div>
        ),
      },
      {
        path: "/cancelled/:orgpaymentid",
        element: (
          <div>
            <Cancel />
          </div>
        ),
      },
      {
        path: "*",
        element: (
          <div className="flex justify-center items-center h-screen">
            <div className="grid gap-2">
              Please wait..
              <div className="flex items-center justify-center ">
                <div className="w-40 h-40 border-t-8 border-b-8 border-indigo-500/100 rounded-full animate-spin"></div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  },
];
