import React, { useState } from "react";
import log from "loglevel";
import moment from "moment";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { TimeInTimeOut } from "./TimeInTimeOut";
import { TimesheetList } from "./TimeInTimeOut/TimesheetList";
import ConfirmationPopup from "../../common/ConfirmationPopup";
import PageHeader from "../../common/PageHeader";
export function TimeSheet() {
  const [confirmationPopup, setConfirmationPopup] = useState(false);

  const showDialog = () => {
    setConfirmationPopup(true);
  };
  const hideDialog = () => {
    setConfirmationPopup(false);
  };

  const dateTime = moment(new Date()).toISOString();

  const employeeService = useEmployeeContextService();
  const { todayAttendance, userProfile } = useEmployeeContextData();

  async function onPressStartWork() {
    log.debug("create_attendance::");
    employeeService.send({
      type: "create_attendance",
      data: {
        timeInOne: dateTime,
      },
    });
    hideDialog();
  }
  async function TimeHandling() {
    log.debug(
      "attendance:TimeHandling====>",
      todayAttendance?.timeIn1 !== null,
      todayAttendance?.TimeOut1 === null,
      todayAttendance?.timeIn2 === null,
      todayAttendance?.TimeOut2 === null
    );
    if (
      todayAttendance?.timeIn1 !== null &&
      todayAttendance?.TimeOut1 !== null &&
      todayAttendance?.timeIn2 !== null &&
      todayAttendance?.TimeOut2 == null
    ) {
      log.debug(
        "attendance:add_time_out_two o2====>",
        todayAttendance?.timeIn1 !== null,
        todayAttendance?.TimeOut1 !== null,
        todayAttendance?.timeIn2 !== null,
        todayAttendance?.TimeOut2 == null
      );
      employeeService.send({
        type: "add_time_out_two",
        data: {
          timeOutTwo: dateTime,
          attendanceId: todayAttendance?.id,
        },
      });

      log.debug(
        "attendance:add_time_out_two o2====>",
        todayAttendance?.TimeOut2 == null
      );
    } else if (
      todayAttendance?.timeIn1 !== null &&
      todayAttendance?.TimeOut1 !== null &&
      todayAttendance?.timeIn2 === null &&
      todayAttendance?.TimeOut2 === null
    ) {
      log.debug(
        "attendance:add_time_in_two i2====>",
        todayAttendance?.timeIn1 !== null,
        todayAttendance?.TimeOut1 !== null,
        todayAttendance?.timeIn2 === null,
        todayAttendance?.TimeOut2 === null
      );
      employeeService.send({
        type: "add_time_in_two",
        data: {
          timeInTwo: dateTime,
          attendanceId: todayAttendance?.id,
        },
      });

      log.debug("attendance:add_time_in_two i2====>", todayAttendance?.timeIn2);
    } else if (
      todayAttendance?.timeIn1 !== null &&
      todayAttendance?.TimeOut1 === null &&
      todayAttendance?.timeIn2 === null &&
      todayAttendance?.TimeOut2 === null
    ) {
      log.debug(
        "attendance:add_time_out_one===>o1",
        todayAttendance?.timeIn1 !== null,
        todayAttendance?.TimeOut1 === null,
        todayAttendance?.timeIn2 === null,
        todayAttendance?.TimeOut2 === null
      );
      employeeService.send({
        type: "add_time_out_one",
        data: {
          timeOutOne: dateTime,
          attendanceId: todayAttendance?.id,
        },
      });

      log.debug(
        "attendance:add_time_out_one o1====>",
        todayAttendance?.TimeOut1
      );
    }

    hideDialog();
  }
  function getTimeOfDay() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (6 <= currentHour && currentHour < 12) {
      return "Morning";
    } else if (12 <= currentHour && currentHour < 18) {
      return "Afternoon";
    } else if (18 <= currentHour && currentHour < 22) {
      return "Evening";
    } else {
      return "night";
    }
  }
  const day = moment().format("DD MMMM YYYY");
  return (
    <>
      <div className="h-auto w-full m-3">
        <PageHeader
          title="Timesheet"
          SubHeading="Overview of your timesheet."
        />
        <div className="flex flex-row mt-5">
          <div className="h-16 grow">
            <span className="text-[20px] ml-4 mt-4">
              {getTimeOfDay()}, {userProfile?.firstName}
            </span>
            <span className="text-[16px] ml-4 mt-2">{day}</span>
          </div>
        </div>
        <TimeInTimeOut showDialog={showDialog} />
        <div className="col-span-full">
          {confirmationPopup === true && (
            <>
              {todayAttendance === undefined ||
                (todayAttendance === null && (
                  <ConfirmationPopup
                    actionOnClose={hideDialog}
                    actionOnConfirm={onPressStartWork}
                    actionOnCancel={hideDialog}
                    confirmText={"Confirm"}
                    cancelText={"Cancel"}
                    message={"Are you sure on click?"}
                  />
                ))}
              {todayAttendance !== undefined &&
                todayAttendance !== null &&
                todayAttendance?.timeIn1 !== null && (
                  <ConfirmationPopup
                    actionOnClose={hideDialog}
                    actionOnConfirm={TimeHandling}
                    actionOnCancel={hideDialog}
                    confirmText={"Confirm"}
                    cancelText={"Cancel"}
                    message={"Are You Sure on click?"}
                  />
                )}
            </>
          )}
        </div>
      </div>
      <TimesheetList />
    </>
  );
}
