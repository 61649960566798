import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddOrgPayment = async (OrgPayment) => {
  log.debug(" hookAddOrgPayment : ", OrgPayment);

  try {
    const result = await API.graphql({
      query: mutations.createOrgPayment,
      variables: {
        input: OrgPayment,
      },
    });

    log.debug(" hookAddOrgPayment result: ", result);

    return result?.data?.createOrgPayment;
  } catch (err) {
    log.debug("CATCH ERROR hookAddOrgPayment result: ", err);
    return null;
  }
  //Destructuring Assignment in ES6
  //setTodos([...todos, result.data.createTodo]);
};

export const hookUpdateOrgPayment = async (OrgPayment) => {
  log.debug(" hookUpdateOrgPayment : ", OrgPayment);
  try {
    const result = await API.graphql({
      query: mutations.updateOrgPayment,
      variables: {
        input: OrgPayment,
      },
    });

    log.debug(" hookUpdateOrgPayment result: ", result);
    return result?.data?.updateOrgPayment;
  } catch (err) {
    log.debug("CATCH ERROR hookAddUser result: ", err);
  }
};

export const hookGetOrgPaymentById = async (id) => {
  log.debug(" hookGetOrgPaymentById  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getOrgPayment,
      variables: {
        id: id,
      },
    });

    log.debug(" hookGetOrgPaymentById : ", data);
    if (data !== null) return data?.data?.getOrgPayment;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetOrgPaymentByCognitoId -- ", e);
    return null;
  }
};

export const hookListOrgPayments = async () => {
  try {
    const result = await API.graphql({
      query: queries.listOrgPayments,
      variables: {},
    });

    log.debug(" hooklistOrgPayments : ", result);

    if (result !== null) return result?.data?.listOrgPayments;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hooklistOrgPayments ", err);
  }
};

export const hookDeleteOrgPaymentById = async (uniqueName) => {
  log.debug(" hookGetOrgPaymentById  ", uniqueName);
  const orgPayment = {
    id: uniqueName,
  };

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteOrgPayment,
      variables: {
        input: orgPayment,
      },
    });

    log.debug(" hookDeleteOrgPaymentById : ", data);
    if (data !== null) return data?.data?.deleteOrgPayment;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteOrgPaymentByCognitoId -- ", e);
    return null;
  }
};
