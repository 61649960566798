import React, { useState } from "react";
import log from "loglevel";
import { useEffect } from "react";
import { hookGetBirthdayMetricsByOrganizationID } from "../../../hooks/employeeHooks";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import FormatDateAndTime from "../../../components/common/FormatDateAndTime";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { DashboardDataTableWrapper } from "./DashboardDataTableWrapper";
import {
  ScrollArea,
  ScrollBar,
} from "../../../components/CustomComponentsLibrary/ScrollArea";
import { useEmployeeContextService } from "../../../hooks/employeeMachineHooks";
import { useSelector } from "@xstate/react";
import { CONST_EMPLOYEE_STATUS } from "../../../constants/dbconstants";

export function BirthDaysOfTheMonth() {
  const { organizationID, theme } = useAuthContextData();
  const [birthdays, setBirthdays] = useState([]);
  const currentMonth = new Date().getMonth() + 1; // January is 0, so we need to add 1
  const currentDate = new Date().getDate();
  const employeeMachineService = useEmployeeContextService();
  const isBirthdayLoading = useSelector(employeeMachineService, (state) =>
    state.matches("DASHBOARD.DASHBOARD_LOADING")
  );

  useEffect(() => {
    async function fetchEmployees() {
      let userData = await hookGetBirthdayMetricsByOrganizationID(
        organizationID
      );

      const filteredUserData = userData?.items?.filter((item) => {
        return (
          item?.employeeStatus !== CONST_EMPLOYEE_STATUS.employeeTerminated
        );
      });
      const birthdaysThisMonth = filteredUserData?.filter(
        (userData) =>
          new Date(userData.dateOfBirth).getMonth() + 1 === currentMonth &&
          new Date(userData.dateOfBirth).getDate() !== currentDate
      );

      const todaysBirthday = filteredUserData?.filter(
        (userData) =>
          new Date(userData.dateOfBirth).getMonth() + 1 === currentMonth &&
          new Date(userData.dateOfBirth).getDate() === currentDate
      );

      const sortedBirthdays = [...todaysBirthday, ...birthdaysThisMonth];

      log.debug(sortedBirthdays, "Birthdays");
      setBirthdays(sortedBirthdays);
    }
    fetchEmployees();
  }, []);

  useEffect(() => {
    log.debug("birthdays", birthdays);
  }, [birthdays]);

  const columns = [
    {
      header: "Name",
      accessorKey: "firstName",
    },
    {
      header: "Date",
      accessorKey: "dateOfBirth",
      cell: ({ row }) => (
        <>
          <FormatDateAndTime
            dateTime={row?.original?.dateOfBirth}
            format={"Do MMMM"}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <DashboardDataTableWrapper title={"Birthdays for the Month"}>
        <ScrollArea className="h-48 w-full">
          <DataTable
            columns={columns}
            data={birthdays}
            dataTableToolBar={false}
            loader={isBirthdayLoading}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </DashboardDataTableWrapper>
    </>
  );
}
