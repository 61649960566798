import React from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../CustomComponentsLibrary/Tabs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import log from "loglevel";
import { useEmployeeContextData } from "../../hooks/employeeMachineHooks";
import { useAuthContextData } from "../../customHooks/authMachineHooks";

export function TabPageLinkWithParam({
  tabs = [],
  currentUserId = "",
  currentUserName = "",
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { myEmployeeProfile } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const initialTab =
    tabs
      ?.map(({ path }) => path)
      ?.findLast((path) => location?.pathname?.includes(path)) +
    "/" +
    currentUserId;

  log.debug("TabPageLinkWithParam location::", initialTab);

  const [activeTab, setActiveTab] = React.useState(initialTab || "tab1");
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
      navigate(initialTab);
    }
  }, [tabs, initialTab]);

  const handleTabChange = (value) => {
    //update the state
    setActiveTab(value);
    // update the URL query parameter
    navigate(value);
  };
  return (
    <div className="w-full flex flex-col h-full mt-2">
      <div className="m-2 ml-6 relative top-7 sm:top-0">
        {myEmployeeProfile?.userType?.includes("MANAGER") ||
        myEmployeeProfile?.userType?.includes("TEAMLEAD") ? (
          <>
            {log.debug("Inner")}
            <div>Name : {currentUserName}</div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Tabs onValueChange={handleTabChange} value={activeTab} className="m-4">
        <TabsList className="w-max pt-1 pb-1 ">
          {tabs?.map((route) => (
            <TabsTrigger
              key={route?.path}
              //   value={route?.path + "/" + currentUserId}
              value={route?.path + "/" + currentUserId}
              className={`${theme?.activeTab}`}
            >
              {route?.name}
            </TabsTrigger>
          ))}
        </TabsList>
        {tabs?.map((route) => (
          <TabsContent
            key={route?.path}
            value={route?.path + "/" + currentUserId}
          >
            <Outlet />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
}
