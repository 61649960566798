import MDEditor, { fullscreen } from "@uiw/react-md-editor";
import React from "react";

const BlogDetails = ({ blog }) => {
  console.log("blog:::data", blog);
  return (
    <div>
      <h1>Blog Details</h1>
      {/* <MDEditor
        preview="preview"
        hideToolbar="true"
        value={String(blog.content)}
        height={800}
      /> */}
    </div>
  );
};

export default BlogDetails;
