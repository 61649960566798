import React, { useEffect, useState } from "react";
import log from "loglevel";

import ListLeaves from "../../../components/employee/Calendar/Leaves/ListLeaves";
import AddEditLeaves from "../../../components/employee/Calendar/Leaves/AddEditLeaves";
import { useEmployeeContextState } from "../../../hooks/employeeMachineHooks";

export function LeavesLayout() {
  const { leavesCrud } = useEmployeeContextState();

  log.debug("leaveCrud:::", leavesCrud);

  return (
    <div className="h-full md:rounded-sm lg:rounded-md">
      <ListLeaves />
      <> {leavesCrud ? <AddEditLeaves /> : <></>}</>
    </div>
  );
}
