import classnames from "classnames";
import { Fragment } from "react";
import log from "loglevel";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogoName } from "./LogoName";
import { useAuthContextData } from "../../customHooks/authMachineHooks";
import { Button } from "../CustomComponentsLibrary/Button";

export function Sidebarv2({ setMobileMenuOpen, mobileMenuOpen }) {
  const router = useLocation();
  const navigate = useNavigate();
  const { navigations, organizationStyling } = useAuthContextData();
  const { logoSmall } = organizationStyling;
  log.debug("mobileMenuOpen", navigations);
  //const scrollAreaHeight = 24 * navigationCount;
  const { theme } = useAuthContextData();
  return (
    <>
      <div
        className={classnames("h-screen pb-12 hidden lg:block md:block w-52")}
      >
        <div className={`${theme?.bgLight}`}>
          <div
            className="overflow-y-auto h-screen shadow-md w-full  py-4  px-8 pl-1"
            style={{ maxHeight: "calc(100%-0rem)" }}
          >
            {navigations?.map((mainRoute) => (
              <>
                <h2 className="mb-6  flex flex-row justify-start items-center text-lg font-semibold tracking-tight ">
                  <div>
                    {/* <mainRoute.icon className="h-4 w-4 font-bold text-common"/> */}
                  </div>
                  <div className="ml-5">{mainRoute?.name}</div>
                </h2>
                <div className="space-y-3 ml-5">
                  {mainRoute?.routes?.map((rout) => (
                    <Button
                      onClick={() => navigate(rout?.href)}
                      variant="ghost"
                      className={classnames(
                        router?.pathname === rout.href
                          ? `"pl-2 pr-4 h-12  flex items-center justify-start rounded-lg w-40  font-bold text-md" ${theme?.background} ${theme?.textColor}`
                          : `" flex font-semibold" ${theme?.hoverText} ${theme?.hoverbg}`,
                        "w-40 h-12  pl-2 pr-4 justify-start font-bold text-sm "
                      )}
                    >
                      <rout.icon className="mr-2 h-5 w-5" />
                      {rout?.name}
                    </Button>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-40 flex lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-background bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-th-sidebar pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-1 right-0 -mr-14 p-1">
                  <button
                    type="button"
                    className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <XMarkIcon
                      className="h-6 w-6 text-foreground"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <LogoName />
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                <nav className="flex h-full flex-col">
                  <div className="space-y-1">
                    {navigations.map((item, index) => (
                      <>
                        {!item.routes ? (
                          <Link key={item.name + index} to={item.href}>
                            <div
                              key={item.name}
                              className={classnames(
                                router?.asPath === item.href
                                //   ? "bg-th-nav text-white"
                                //   : "text-th-nav-forground hover:text-white hover:bg-th-nav",
                                // "group flex items-center rounded-md py-2 px-3 text-sm font-medium"
                              )}
                              aria-current={
                                router?.asPath === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              <item.icon
                                className={classnames(
                                  router?.asPath === item.href
                                    ? "text-white"
                                    : "text-blue-300 group-hover:text-white",
                                  "mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              <span>{item.name}</span>
                            </div>
                          </Link>
                        ) : (
                          <Disclosure as="div">
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classnames(
                                    item.current
                                      ? "bg-gray-50"
                                      : "hover:bg-gray-50"
                                  )}
                                >
                                  {item.name}
                                </Disclosure.Button>
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.routes.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classnames(
                                          subItem.current
                                            ? "bg-muted text-muted-foreground"
                                            : "text-foreground hover:bg-muted hover:text-muted-foreground",
                                          "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 "
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
