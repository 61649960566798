import React from "react";

import { InventoryList } from "../../../components/employee/Inventory/InventoryList";

export function InventoryLayout() {
  return (
    <div className="h-full md:rounded-sm lg:rounded-md">
      <InventoryList />
    </div>
  );
}
