import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookListGoals = async () => {
  log.debug(" hookListGoals ");

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.listGoals,
      variables: {},
    });

    log.debug(" hookListGoals : ", data);

    if (data !== null) return data?.data?.listGoals.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListGoals -- ", e);
    return null;
  }
};

export const hookAddGoal = async (Goal) => {
  log.debug(" hookAddGoal : ", Goal);

  try {
    const result = await API.graphql({
      query: mutations.createGoal,
      variables: {
        input: Goal,
      },
    });

    log.debug(" hookAddGoal result: ", result);

    return result?.data?.createGoal;
  } catch (err) {
    log.debug("CATCH ERROR hookAddGoal result: ", err);
    return null;
  }
};

export const hookUpdateGoal = async (Goal) => {
  log.debug(" hookUpdateGoal : ", Goal);
  try {
    const result = await API.graphql({
      query: mutations.updateGoal,
      variables: {
        input: Goal,
      },
    });

    log.debug(" hookUpdateGoal result: ", result);
    return result?.data?.updateGoal;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateGoal result: ", err);
  }
};

export const hookDeleteGoalByGoalId = async (GoalId) => {
  log.debug(" hookDeleteGoalByGoalId  ", GoalId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteGoal,
      variables: {
        input: { id: GoalId },
      },
    });

    log.debug(" hookDeleteGoalByGoalId : ", data);
    if (data !== null) return data?.data?.deleteGoal;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteGoalByGoalUniqueID -- ", e);
    return null;
  }
};

export const hookListGoalsByUserId = async (userID) => {
  log.debug(" hookListGoalsByUserId userID", userID);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: queries.GoalsByUserID,
      variables: {
        sortDirection: "DESC",
        userID: userID,
      },
    });

    log.debug(" hookListGoalsByUserId : ", data);

    if (data !== null) return data?.data?.GoalsByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListGoalsByUserId -- ", e);
    return null;
  }
};

export const hookListGoalsProgressByUserId = async (userID) => {
  log.debug(" hookListGoalsProgressByUserId userID", userID);

  try {
    //Connect Client Amplify GraphQL
    //TODO LIST BY EMPLOYEE ID
    const data = await API.graphql({
      query: customQueries.goalsProgressByUserID,
      variables: {
        sortDirection: "DESC",
        userID: userID,
      },
    });

    log.debug(" hookListGoalsProgressByUserId : ", data);

    if (data !== null) return data?.data?.GoalsByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListGoalsProgressByUserId -- ", e);
    return null;
  }
};
