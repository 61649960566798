import log from "loglevel";

import useForm from "../../customHooks/useForm";

import {
  useAuthContextData,
  useAuthContextState,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import moment from "moment/moment";
const AddOrganization = () => {
  const { domain } = useAuthContextData();
  const authService = useAuthService();
  const { inputs, handleChange } = useForm({
    uniqueName: domain,
    name: "",
    description: "",
    tagline: "",
    // lattitude: "",
    // longitude: "",
    // addressLine1: "",
    // addressLine2: "",
    // gstNumber: "",
    // contactNumber: "",
    city: "",
    // logoBig: "",
    // logoSmall: "",
    // brandLogoBig: "",
    // brandLogoSmall: "",
    // primaryColor: "",
    // secondaryColor: "",
    // menuBGImage: "",
    date: moment(new Date()).format("yyyy-MM-DD"),
  });
  const { orgAdding } = useAuthContextState();

  log.debug("INPUTAS", inputs);
  const onSubmit = async () => {
    // Perform form submission logic here
    // Example: You can make an API call to submit the form data

    // Simulating a delay of 2 seconds
    log.debug("Added ORg", inputs); // Form data
    authService.send({
      type: "add_org",
      data: { organizationToBeAdded: inputs },
    });
    // const addedOrg = await hookAddOrganization({
    //   uniqueName: "Delighteck",
    //   date: "2023-06-15",
    //   organizationID: "delightecklabspvtltd",
    // });

    // log.debug("Added ORg", addedOrg); // Form data
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add Organization Details</h1>

      <div className="mb-4">
        <label
          htmlFor="uniqueName"
          className="block text-gray-700 font-semibold mb-2"
        >
          Unique Name
        </label>
        <input
          type="text"
          disabled
          id="uniqueName"
          name="uniqueName"
          value={domain}
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          // onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="name"
          className="block text-gray-700 font-semibold mb-2"
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="description"
          className="block text-gray-700 font-semibold mb-2"
        >
          Description
        </label>
        <textarea
          id="description"
          name="description"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="tagline"
          className="block text-gray-700 font-semibold mb-2"
        >
          Tagline
        </label>
        <input
          type="text"
          id="tagline"
          name="tagline"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      {/* <div className="mb-4">
        <label
          htmlFor="latitude"
          className="block text-gray-700 font-semibold mb-2"
        >
          Latitude
        </label>
        <input
          type="number"
          id="latitude"
          name="latitude"
          step="0.000001"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="longitude"
          className="block text-gray-700 font-semibold mb-2"
        >
          Longitude
        </label>
        <input
          type="number"
          id="longitude"
          name="longitude"
          step="0.000001"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div> */}

      {/* <div className="mb-4">
        <label
          htmlFor="addressLine1"
          className="block text-gray-700 font-semibold mb-2"
        >
          Address Line 1
        </label>
        <input
          type="text"
          id="addressLine1"
          name="addressLine1"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="addressLine2"
          className="block text-gray-700 font-semibold mb-2"
        >
          Address Line 2
        </label>
        <input
          type="text"
          id="addressLine2"
          name="addressLine2"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="gstNumber"
          className="block text-gray-700 font-semibold mb-2"
        >
          GST Number
        </label>
        <input
          type="text"
          id="gstNumber"
          name="gstNumber"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="contactNumber"
          className="block text-gray-700 font-semibold mb-2"
        >
          Contact Number
        </label>
        <input
          type="text"
          id="contactNumber"
          name="contactNumber"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div> */}

      <div className="mb-4">
        <label
          htmlFor="city"
          className="block text-gray-700 font-semibold mb-2"
        >
          City
        </label>
        <input
          type="text"
          id="city"
          name="city"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      {/* <div className="mb-4">
        <label
          htmlFor="logoBig"
          className="block text-gray-700 font-semibold mb-2"
        >
          Logo (Big)
        </label>
        <input
          type="text"
          id="logoBig"
          name="logoBig"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="logoSmall"
          className="block text-gray-700 font-semibold mb-2"
        >
          Logo (Small)
        </label>
        <input
          type="text"
          id="logoSmall"
          name="logoSmall"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="brandLogoBig"
          className="block text-gray-700 font-semibold mb-2"
        >
          Brand Logo (Big)
        </label>
        <input
          type="text"
          id="brandLogoBig"
          name="brandLogoBig"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="brandLogoSmall"
          className="block text-gray-700 font-semibold mb-2"
        >
          Brand Logo (Small)
        </label>
        <input
          type="text"
          id="brandLogoSmall"
          name="brandLogoSmall"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div> */}

      {/* <div className="mb-4">
        <label
          htmlFor="primaryColor"
          className="block text-gray-700 font-semibold mb-2"
        >
          Primary Color
        </label>
        <input
          type="text"
          id="primaryColor"
          name="primaryColor"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="secondaryColor"
          className="block text-gray-700 font-semibold mb-2"
        >
          Secondary Color
        </label>
        <input
          type="text"
          id="secondaryColor"
          name="secondaryColor"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="menuBGImage"
          className="block text-gray-700 font-semibold mb-2"
        >
          Menu Background Image
        </label>
        <input
          type="text"
          id="menuBGImage"
          name="menuBGImage"
          className="w-full border border-gray-300 rounded-md py-2 px-3"
required
          onChange={handleChange}
        />
      </div> */}

      <div className="mb-4">
        <label
          htmlFor="date"
          className="block text-gray-700 font-semibold mb-2"
        >
          Date
        </label>

        <input
          type="date"
          id="date"
          name="date"
          disabled
          value={inputs.date}
          className="w-full border border-gray-300 rounded-md py-2 px-3"
          required
          onChange={handleChange}
        />
      </div>

      <button
        type="submit"
        disabled={orgAdding}
        className="bg-blue-500 text-white py-2 px-4 rounded-md"
        onClick={onSubmit}
      >
        {orgAdding ? "Adding" : "Submit"}
      </button>
    </div>
  );
};

export default AddOrganization;
