import React, { useEffect, useState } from "react";
import PageHeader from "../../common/PageHeader";
import { DataTable } from "../../CustomComponentsLibrary/data-table";
import { Download } from "lucide-react";
import { Button } from "../../CustomComponentsLibrary/Button";
import { GetSignedURL } from "../../../hooks/s3Hooks";
import { useToast } from "../../CustomComponentsLibrary/UseToast";
import { log } from "loglevel";
import { useEmployeeContextData } from "../../../hooks/employeeMachineHooks";
import { hookListForm16ByUserId } from "../../../hooks/form16Hooks";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

const Form16 = () => {
  const { myEmployeeProfile, userID } = useEmployeeContextData();
  const { theme } = useAuthContextData();

  console.log("ListForm16 userID", userID);

  const [form16List, setForm16List] = useState([]);
  console.log("ListForm16 ", form16List);
  useEffect(() => {
    async function fetchForm16() {
      try {
        let form16List = await hookListForm16ByUserId(userID);
        console.log("hookListForm16ByUserId by ID", form16List);
        if (form16List) {
          setForm16List(form16List);
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    }
    fetchForm16();
  }, [userID]);
  const { toast } = useToast();

  const DownloadFrom16 = async (s3key) => {
    if (!s3key) {
      toast({
        variant: "destructive",
        title: "Failed",
        description: "Failed to download slip",
      });
    }
    let fileToView;
    if (s3key) {
      fileToView = s3key;
    }
    console.log("fileToView::", fileToView);
    if (fileToView) {
      let signedURL = await GetSignedURL(fileToView);
      console.log("GetSignedURL :  URL = ", signedURL);
      window.open(signedURL);
    }
  };

  const columns = [
    {
      header: "Gross Salary",
      accessorKey: "grossSalary",
    },
    {
      header: "Exemptions",
      accessorKey: "exemptions",
    },
    {
      header: "Net Taxable Salary",
      accessorKey: "netTaxableSalary",
    },

    {
      header: "Total Deductions",
      accessorKey: "DeductedTotal",
    },
    {
      header: "Deducted Certificate Number",
      accessorKey: "DeductedCertificateNumber",
    },
    {
      header: "Assessment Year",
      accessorKey: "assessmentYear",
    },
    {
      header: "Total Income",
      accessorKey: "totalIncome",
    },
    {
      header: "",
      id: "actions",
      accessorKey: "",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row gap-x-2">
            {row.original?.s3key != null ? (
              <div
                title={"Download Form16"}
                description={"Click this button to Download the Form16."}
              >
                {}
                <Button
                  size="sm"
                  className="bg-transparent border-none shadow-none"
                  onClick={() => {
                    DownloadFrom16(row.original?.s3key);
                  }}
                >
                  <Download className="h-4 w-4" />
                </Button>
              </div>
            ) : (
              <div className="text-danger font-semibold">
                Form16 is Not Uploaded yet{" "}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-2 mx-auto">
      <PageHeader
        title={"Form 16"}
        SubHeading="A record of all your Form 16 filed."
        showDropdown={false}
      />
      <div className="shadow-md mt-5 border-t border-t-zinc-100 rounded-md">
        <div className=" flex flex-row justify-end"></div>
        <div className="ml-5 mr-5 mt-2 pb-5 text-sm font-semibold">
          <DataTable
            columns={columns}
            data={form16List}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 2,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 3,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 4,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 5,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 6,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 7,
                widthClass: "w-10",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Form16;
