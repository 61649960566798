import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import log from "loglevel";

import { API, Auth } from "aws-amplify";

export const hookAddEmployee = async (userToAdd) => {
  log.debug(" hookAddEmployee : ", userToAdd);

  try {
    const result = await API.graphql({
      query: mutations.createEmployee,
      variables: {
        input: userToAdd,
      },
    });

    log.debug(" hookAddEmployee result: ", result);

    return result?.data?.createEmployee;
  } catch (err) {
    log.debug("CATCH ERROR hookAddEmployee result: ", err);
    return null;
  }
  //Destructuring Assignment in ES6
  //setTodos([...todos, result.data.createTodo]);
};

export const hookGetEmployeeByCognitoId = async (cognitoId) => {
  const EmployeeObj = {
    cognitoId: cognitoId,
  };
  log.debug(" hookGetEmployeeByCognitoId ++ ");

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getEmployeeCustom,
      variables: {
        cognitoId: cognitoId,
      },
    });

    log.debug(" hookGetEmployeeByCognitoId : ", result);
    return result?.data?.getEmployee;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeByCognitoId -- ", e);

    return null;
  }
};

export const hookUpdateEmployee = async (Employee) => {
  log.debug(" hookUpdateEmployee : ", Employee);
  try {
    const result = await API.graphql({
      query: mutations.updateEmployee,
      variables: {
        input: Employee,
      },
    });

    log.debug(" hookUpdateEmployee result: ", result);
    return result?.data?.updateEmployee;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateEmployee result: ", err);
  }
};

export const hookDeleteEmployee = async (cognitoId) => {
  log.debug(" hookDeleteEmployee : ", cognitoId);
  try {
    const result = await API.graphql({
      query: mutations.deleteEmployee,
      variables: {
        input: { cognitoId: cognitoId },
      },
    });

    log.debug(" hookDeleteEmployee result: ", result);
    return result;
  } catch (err) {
    log.debug("CATCH ERROR hookDeleteEmployee result: ", err);
  }
};

export const hookListEmployees = async () => {
  //Connect Client Amplify GraphQL
  log.debug(" Calling hookListEmployees : ");

  try {
    const result = await API.graphql({
      query: queries.listEmployees,
      variables: {},
    });

    log.debug(" hookListEmployees : ", result);

    if (result !== null) return result?.data?.listEmployees.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookListEmployees ", err);
  }
};

export const hookListEmployeesByGuideID = async (guideID) => {
  //Connect Client Amplify GraphQL
  log.debug(" Calling hookListEmployeesByGuideID : ", guideID);

  try {
    const result = await API.graphql({
      query: queries.EmployeesByGuideID,
      variables: {
        guideID: guideID,
      },
    });

    log.debug(" hookListEmployeesByGuideID : ", result);

    if (result !== null) return result?.data?.EmployeesByGuideID.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookListEmployeesByGuideID ", err);
  }
};

export const hookListEmployeesByType = async (restaurantUniqueName) => {
  //Connect Client Amplify GraphQL

  try {
    const result = await API.graphql({
      query: queries.listEmployees,
      variables: {
        activeRestaurant: restaurantUniqueName,
      },
    });

    log.debug(" hookListEmployees : ", result);

    if (result !== null) return result?.data?.listEmployees;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookListEmployees ", err);
  }
};

export const hookGetEmployeeById = async (cognitoId) => {
  const postObj = {
    cognitoId: cognitoId,
  };

  log.debug(" hookGetEmployeeById ++  id", cognitoId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getEmployee,
      variables: {
        cognitoId,
      },
    });

    if (data?.data?.getEmployee) {
      log.debug(" hookGetEmployeeById : ", data.data.getEmployee);
      return data.data.getEmployee;
    } else {
      return null;
    }
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeById -- ", e);
    return null;
  }
};

export const hookListCognitoEmployees = async (filter) => {
  let apiName = "AdminQueries";
  let path = "/ListEmployees";
  let listEmployeesForGroup = "/listGroupsForEmployee";

  let authHeader = {
    "Content-Type": "application/json",
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };

  let myInit = {
    queryStringParameters: {
      limit: 30,
    },
    headers: authHeader,
  };
  try {
    let response = await API.get(apiName, path, myInit);
    const allusers = [];
    log.debug("list users : ", response);
    for (let i = 0; i < response.Employees.length; i++) {
      let phone_number = "";
      let address = "";
      let name = "";
      let Employeename = "";
      let cognitoId = "";
      let EmployeeCreateDate = "";
      let email = "";
      Employeename = response.Employees[i].Employeename;
      EmployeeCreateDate = response.Employees[i].EmployeeCreateDate;
      for (let j = 0; j < response.Employees[i].Attributes.length; j++) {
        if (response.Employees[i].Attributes[j].Name === "sub") {
          cognitoId = response.Employees[i].Attributes[j].Value;
        }
        if (response.Employees[i].Attributes[j].Name === "address") {
          address = response.Employees[i].Attributes[j].Value;
        }
        if (response.Employees[i].Attributes[j].Name === "name") {
          name = response.Employees[i].Attributes[j].Value;
        }
        if (response.Employees[i].Attributes[j].Name === "email") {
          email = response.Employees[i].Attributes[j].Value;
        }
        if (response.Employees[i].Attributes[j].Name === "phone_number") {
          phone_number = response.Employees[i].Attributes[j].Value;
        }
      }

      let myInit2 = {
        queryStringParameters: {
          username: Employeename,
        },
        headers: authHeader,
      };
      let groupResponse = await API.get(
        apiName,
        listEmployeesForGroup,
        myInit2
      );

      let EmployeeGroup = [];
      let EmployeeOrMenteeFound = false;
      for (let n_i = 0; n_i < groupResponse.Groups.length; n_i++) {
        if (
          groupResponse.Groups[n_i].GroupName === "Employee" ||
          groupResponse.Groups[n_i].GroupName === "mentee"
        ) {
          EmployeeOrMenteeFound = true;
        }
        if (EmployeeOrMenteeFound === false) {
          EmployeeGroup.push(groupResponse.Groups[n_i].GroupName);
        }
      }
      log.debug("groupResponse : ", groupResponse);

      if (EmployeeOrMenteeFound === false) {
        allusers.push({
          cognitoId: cognitoId,
          Enabled: response.Employees[i].Enabled,
          Employeename: Employeename,
          email: email,
          firstName: name,
          address: address,
          phone_number: phone_number,
          group: EmployeeGroup,
        });
      }
    }
    log.debug("Return list users : ", allusers);

    return allusers;
  } catch (error) {
    log.debug("ERROR", error);
    return error;
  }
};

export const hookGetEmployeeLeavesDataById = async (cognitoId) => {
  const EmployeeObj = {
    cognitoId: cognitoId,
  };
  log.debug(" hookGetEmployeeLeavesDataById ++ ");

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getEmployeeLeavesData,
      variables: {
        cognitoId: cognitoId,
      },
    });

    log.debug(" hookGetEmployeeLeavesDataById : ", result);
    return result?.data?.getEmployee;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeLeavesDataById -- ", e);

    return null;
  }
};

export const hookGetGuideDetailsbyGuideId = async (guideID) => {
  const EmployeeObj = {
    cognitoId: guideID,
  };
  log.debug(" hookGetGuideDetailsbyGuideId ++ ", guideID);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getGuideDetails,
      variables: {
        cognitoId: guideID,
      },
    });

    log.debug(" hookGetGuideDetailsbyGuideId : ", result);
    return result?.data?.getEmployee;
  } catch (e) {
    console.error("CATCH ERROR hookGetGuideDetailsbyGuideId -- ", e);

    return null;
  }
};

export const hookGetEmployeeShortProfileById = async (employeeId) => {
  log.debug(" hookGetGuideDetailsbyGuideId ++ ", employeeId);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getGuideDetails,
      variables: {
        cognitoId: employeeId,
      },
    });

    log.debug(" hookGetEmployeeShortProfileById : ", result);
    return result?.data?.getEmployee;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeShortProfileById -- ", e);

    return null;
  }
};
export const hookGetEmployeeFirstName = async (employeeId) => {
  log.debug(" hookGetEmployeeFirstName ++ ", employeeId);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getEmployeeFirstName,
      variables: {
        cognitoId: employeeId,
      },
    });

    log.debug(" hookGetEmployeeFirstName : ", result);
    return result?.data?.getEmployee;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeFirstName -- ", e);

    return null;
  }
};

export const hookGetBirthdayMetricsByOrganizationID = async (orgID) => {
  log.debug(" hookGetBirthdayMetricsByOrganizationID ++ ", orgID);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getBirthdayMetricsByOrganizationID,
      variables: {
        organizationID: orgID,
      },
    });

    log.debug(" hookGetBirthdayMetricsByOrganizationID : ", result);
    return result?.data?.EmployeesByOrganizationID;
  } catch (e) {
    console.error("CATCH ERROR hookGetBirthdayMetricsByOrganizationID -- ", e);

    return null;
  }
};

export const hookGetTeamShortProfilesByGuideID = async (guideID) => {
  //Connect Client Amplify GraphQL
  log.debug(" Calling hookGetTeamShortProfilesByGuideID : ", guideID);

  try {
    const result = await API.graphql({
      query: customQueries.teamShortProfilesByGuideID,
      variables: {
        guideID: guideID,
      },
    });

    log.debug(" hookGetTeamShortProfilesByGuideID : ", result);

    if (result !== null) return result?.data?.EmployeesByGuideID.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookGetTeamShortProfilesByGuideID ", err);
  }
};
export const hookListSalesPeopleByOrganizationId = async (orgId) => {
  //Connect Client Amplify GraphQL
  log.debug(" Calling hookListEmployees : ");

  try {
    const result = await API.graphql({
      query: customQueries.employeesShortProfilesByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });

    log.debug(" hookListEmployees : ", result);

    if (result !== null)
      return result?.data?.EmployeesByOrganizationID.items.filter((employee) =>
        employee.userType.includes("SALES")
      );
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookListEmployees ", err);
  }
};

export const hookListEmployeesByOrgnizationId = async (organizationID) => {
  try {
    const data = await API.graphql({
      query: queries.EmployeesByOrganizationID,
      variables: { organizationID: organizationID },
    });

    log.debug(" hookListEmployeesByOrgnizationId : ", data);

    if (data !== null) return data?.data?.EmployeesByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeesByOrgnizationId -- ", e);
    return null;
  }
};
