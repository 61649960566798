import { useEffect, useState } from "react";
import { Button } from "../../../components/CustomComponentsLibrary/Button";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import { Progress } from "../../../components/CustomComponentsLibrary/Progress";
import log from "loglevel";
import { Edit, Plus } from "lucide-react";
import { Edit2Icon } from "lucide-react";
import { useActor } from "@xstate/react";
import {
  useEmployeeContextData,
  useEmployeeContextService,
  useEmployeeContextState,
} from "../../../hooks/employeeMachineHooks";

import FormatDateAndTime from "../../../components/common/FormatDateAndTime";
import { FriendlyName } from "../../../lib/friendlyName";
import { TypographyH3 } from "../../../components/CustomComponentsLibrary/Typography";
import { DataTableColumnHeader } from "../../CustomComponentsLibrary/data-table-column-header";
import PageHeader from "../../common/PageHeader";
import AddEditDeleteTaskHours from "../Tasks/AddEditDeleteTaskHours";
import { useSelector } from "@xstate/react";
import {
  ScrollArea,
  ScrollBar,
} from "../../CustomComponentsLibrary/ScrollArea";
import MultiselectorDropdown from "../../common/MultiselectorDropdown";
import { useParams } from "react-router";
import PopoverCard from "../../common/PopoverCard";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

const ProjectTasksList = () => {
  const {
    projects,
    teamMembersNames,
    multipleProjectTasks,
    myEmployeeProfile,
    userID,
  } = useEmployeeContextData();
  const { taskCrud } = useEmployeeContextState();
  const [Open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [selectedProjectForTask, setSelectedProjectForTask] = useState([]);
  const selectDefaultProject = true;
  const employeeMachineService = useEmployeeContextService();
  const { theme } = useAuthContextData();
  const [state, send] = useActor(employeeMachineService);
  const istaskLoading = useSelector(employeeMachineService, (state) =>
    state.matches("PROJECT_TASKS.PROJECT_TASKS_LOADING")
  );
  const params = useParams();

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    employeeMachineService.send({
      type: "cancel_crud",
    });
    setOpen(false);
  };
  function refresh() {
    log.debug("getting called");
    setRefetch(!refetch);
  }
  useEffect(() => {
    employeeMachineService.send({
      type: "reload_projects",
      data: {
        userID: params?.currentUserId ? params?.currentUserId : userID,
      },
    });
  }, [userID]);
  useEffect(() => {
    employeeMachineService.send({
      type: "get_project_id",
      data: {
        selectedProjectForTasks: selectedProjectForTask,
      },
    });
  }, [selectedProjectForTask, taskCrud]);
  log.debug("projects", projects);
  const tabs = [
    {
      name: "Projects",
      path: "/employee/projects",
    },

    {
      name: "History",
      path: "/employee/projects/history",
    },
    {
      name: "Log Hours",
      path: "/employee/projects/myloghours",
    },
  ];

  const columns = [
    {
      header: "Task Name",
      accessorKey: "title",
      cell: ({ row }) => (
        <>
          <div>{row.original?.title}</div>
        </>
      ),
    },
    {
      header: "Description",
      accessorKey: "content",
      cell: ({ row }) => (
        <>
          <div>{row.original?.content}</div>
        </>
      ),
    },
    {
      header: "Assigned To",
      accessorKey: "assignedTo",
      cell: ({ row }) => {
        const assignee = teamMembersNames?.find(
          (member) => member?.id === row.original?.assignedTo
        );
        return (
          <div className="flex flex-row">
            <div>{assignee ? assignee?.title : null}</div>
            {(myEmployeeProfile?.userType.includes("TEAMLEAD") ||
              myEmployeeProfile?.userType?.includes("MANAGER")) && (
              <PopoverCard description="Change assignee">
                <Button
                  variant="link"
                  className="text-black"
                  onClick={() => {
                    log.debug("row?.original", row?.original);
                    employeeMachineService.send({
                      type: "task_crud",
                      data: {
                        actionForTaskCRUD: "EDIT",
                        selectedTask: row?.original,
                        selectedTaskId: row.original?.id,
                      },
                    });
                  }}
                >
                  <Edit className="h-4 w-8 mt-4" />
                </Button>
              </PopoverCard>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "dueDate",
      enableSorting: true,
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row.original?.dueDate}
          format={"DD/MM/YYYY"}
        />
      ),
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Due on" />
      ),
    },
    {
      header: "Task Completion (%)",
      accessorKey: "percentage",
      cell: ({ row }) => (
        <>
          <div className="flex  ">
            <Progress
              value={row.original?.percentage}
              className="w-[40%] m-2"
              rootCss={`${theme?.bgLight}`}
              indicatorCss={`${theme?.bgCommon}`}
            />
            <div className="text-sm font-semibold text-common">{`${parseInt(
              row.getValue("percentage")
            )}% `}</div>
          </div>
        </>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",

      cell: ({ row }) => (
        <FriendlyName status={row.getValue("status")} className="p-1" />
      ),
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
    {
      header: "Billable Hours",
      accessorKey: "billableHours",
      cell: ({ row }) => <>{row.original?.billableHours}</>,
    },
    {
      header: "Billed Hours",
      accessorKey: "billedHours",
      cell: ({ row }) => <>{row.original?.billedHours}</>,
    },

    {
      header: "Unbillable Hours",
      accessorKey: "unbillableHours",
      cell: ({ row }) => {
        const unbillableHours = row.getValue("unbillableHours");
        const displayValue =
          unbillableHours >= 0 ? unbillableHours?.toFixed(2) : "0.00";
        return <div>{displayValue}</div>;
      },
    },
    {
      header: "BilledUnbilled Hours",
      accessorKey: "billedUnbillableHours",
      cell: ({ row }) => (
        <div>{row.getValue("billedUnbillableHours")?.toFixed(2)}</div>
      ),
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <>
          <div className="flex gap-1 ">
            {(myEmployeeProfile?.userType.includes("TEAMLEAD") ||
              myEmployeeProfile?.userType?.includes("MANAGER")) && (
              <Button
                variant="link"
                className="font-bold underline  flex flex-col text-sm"
                onClick={() => {
                  employeeMachineService.send({
                    type: "task_crud",
                    data: {
                      actionForTaskCRUD: "EDIT",
                      selectedTask: row?.original,
                      selectedTaskId: row.original?.id,
                    },
                  });
                }}
              >
                <div>
                  <Edit2Icon className="h-4" />
                </div>
                <div> Edit</div>
              </Button>
            )}
            <Button
              variant="link"
              className="font-bold underline text-sm flex flex-col "
              onClick={() => {
                openDialog();
                employeeMachineService.send({
                  type: "get_Task_id_for_task_hours",
                  data: {
                    selectedTask: row.original,
                    selectedProject: row.original,
                  },
                });
              }}
            >
              <div>
                <Plus className="h-5" />
              </div>
              <div> Log</div>
            </Button>
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="m-3 w-full">
          <PageHeader
            title="Projects"
            SubHeading="Overview and hours of all your Projects and tasks"
            showDropdown={true}
          />
        </div>
      </div>

      <div className="flex flex-col border-t border-t-zinc-100 align-middle bg-100  bg-white-100 shadow-md rounded  border-black p-5 mb-5 m-2">
        <p className="text-base">Search/Select projects from the list</p>
        <div className="flex flex-row justify-start align-middle gap-5 m-5 w-fit">
          <div className=" w-full h-fit z-20 sm:h-10 pb-5">
            <MultiselectorDropdown
              selected={selectedProjectForTask}
              setSelected={setSelectedProjectForTask}
              list={projects}
              nameField="title"
              placeholder="Select Project."
              defaultSelectedIndex={selectDefaultProject}
            />
          </div>
        </div>
      </div>
      {multipleProjectTasks?.length <= 0 && (
        <ScrollArea className="h-96"></ScrollArea>
      )}
      <div className="shadow-lg h-full mt-8   rounded-md border-t border-t-zinc-100 m-2">
        {multipleProjectTasks?.map((item, index) => (
          <ScrollArea className="w-full">
            <div className="mt-10" key={index}>
              {log.debug("selectedProjectForTask", item?.projectId)}
              <div className="flex felx-row justify-between mt-2 mb-[-20px] m-5">
                <TypographyH3>{item.projectTitle}</TypographyH3>
                {(myEmployeeProfile?.userType.includes("TEAMLEAD") ||
                  myEmployeeProfile?.userType?.includes("MANAGER")) && (
                  <div className="mb-5">
                    <Button
                      className={`""${theme?.textColor} ${theme?.background} ${theme?.default}`}
                      onClick={() => {
                        employeeMachineService.send({
                          type: "task_crud",
                          data: {
                            actionForTaskCRUD: "ADD",
                            selectedProjectId: item?.projectId,
                          },
                        });
                      }}
                    >
                      Add Task
                    </Button>
                  </div>
                )}
              </div>
              <div className="m-5">
                <DataTable
                  columns={columns}
                  loader={istaskLoading}
                  data={item?.taskData ? item?.taskData : []}
                  customCss={`${theme?.border}`}
                  customWidth={[
                    {
                      index: 0,
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                      widthClass: "w-64",
                    },
                    {
                      index: 1,
                      widthClass: "w-80",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 2,
                      widthClass: "w-32",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 3,
                      widthClass: "w-32",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 4,
                      widthClass: "w-40",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 5,
                      widthClass: "w-32",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 6,
                      widthClass: "w-10",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 7,
                      widthClass: "w-10",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 8,
                      widthClass: "w-10",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 9,
                      widthClass: "w-10",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                    {
                      index: 10,
                      widthClass: "w-28",
                      cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                    },
                  ]}
                />
              </div>
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        ))}
      </div>
      {Open ? (
        <AddEditDeleteTaskHours
          isOpen={Open}
          onClose={closeDialog}
          onOpenChange={closeDialog}
          refreshTask={refresh}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ProjectTasksList;
