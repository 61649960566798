import React, { Fragment, useEffect, useMemo, useState } from "react";
import PageHeader from "../../common/PageHeader";
import { DataTable } from "../../CustomComponentsLibrary/data-table";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import log from "loglevel";
import { Button } from "../../CustomComponentsLibrary/Button";
import { Edit2Icon, X } from "lucide-react";
import PopoverCard from "../../common/PopoverCard";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";
import { useParams } from "react-router-dom";
import { sortByKeyAlphabetical } from "../../common/SortByName";
import { ScrollArea } from "../../CustomComponentsLibrary/ScrollArea";
export const ManageProject = () => {
  const { projects, myEmployeeProfile, teamMembersNames } =
    useEmployeeContextData();
  const { theme } = useAuthContextData();
  const employeeMachineService = useEmployeeContextService();
  const [assignProject, setAssignedProject] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedTeammember, setSelectedTeammember] = useState([]);

  log.debug("ManageProject", projects);
  const params = useParams();
  const userId = params?.currentUserId;
  const filteredProjects = projects?.filter(
    (item) => item?.teammembers?.includes(userId) || item?.assignedTo == userId
  );
  log.debug("filteredProjects", filteredProjects);

  const filteredTeamMembers = useMemo(() => {
    if (
      teamMembersNames &&
      myEmployeeProfile?.employees &&
      (myEmployeeProfile?.userType.includes("TEAMLEAD") ||
        myEmployeeProfile?.userType?.includes("MANAGER"))
    ) {
      const filteredMembers = teamMembersNames?.filter((member) =>
        myEmployeeProfile?.employees?.includes(member?.id)
      );

      return filteredMembers.map((member) => ({
        id: member.id,
        title: member.title,
      }));
    }
    return null;
  }, [teamMembersNames, myEmployeeProfile?.employees]);

  useEffect(() => {
    const previousTeammember = assignProject?.teammembers
      ?.map((item) => {
        const found = teamMembersNames?.find(
          (data) =>
            data?.id === item && data?.id !== myEmployeeProfile?.cognitoId
        );
        if (found) {
          return found;
        }
        return null;
      })
      .filter((item) => item !== null);
    log.debug("previousTeammember@", previousTeammember);
    if (previousTeammember) {
      setSelectedTeammember([...previousTeammember]);
    }
  }, [assignProject]);
  log.debug("selectedTeammember@", selectedTeammember);
  const handleSelection = (selectedItems) => {
    log.debug("selectedItems::", selectedItems);

    if (selectedTeammember?.some((item) => item?.id === selectedItems?.id)) {
      setSelectedTeammember(
        selectedTeammember?.filter((item) => item?.id !== selectedItems?.id)
      );
    } else {
      setSelectedTeammember([...selectedTeammember, selectedItems]);
    }
  };
  const removeSelected = (data) => {
    setSelectedTeammember(
      selectedTeammember?.filter((item) => item?.id !== data?.id)
    );
    setOpen(true);
  };
  const isSelected = (data) => {
    return selectedTeammember?.some((item) => item?.id === data?.id);
  };

  const columns = [
    {
      header: "Project Name",
      accessorKey: "title",
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <PopoverCard description="Assign project to teammember">
          <Button
            variant="link"
            className="text-black"
            onClick={() => {
              setAssignedProject(row?.original);
              employeeMachineService.send({
                type: "project_crud",
              });
              setOpen(true);
            }}
          >
            <Edit2Icon className="h-5" />
          </Button>
        </PopoverCard>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="m-3 w-full">
          <PageHeader
            title={"Assign Project"}
            SubHeading="Assign project to teammember"
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="w-1/2  ">
          <DataTable
            data={filteredProjects || []}
            columns={columns}
            dataTableToolBar={false}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>

      <>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={() => {
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10  overflow-y-auto">
              <div className="flex min-h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute top-0 right-0  hidden pt-4 pr-4 sm:block">
                      <Button
                        size="xs"
                        variant="darkOutline"
                        className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                        onClick={() => {
                          employeeMachineService.send({
                            type: "cancle_crud",
                          });
                          setOpen(false);
                        }}
                      >
                        <X className="h-4 w-4 " />
                        <span className="sr-only">Close</span>
                      </Button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-semoibold leading-6 text-foreground"
                        >
                          Assign project to teammember's.
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="h-96">
                      {log.debug("selectedTeammember", selectedTeammember)}
                      <Listbox
                        as="div"
                        value={selectedTeammember}
                        onChange={handleSelection}
                        // multiple
                      >
                        {() => (
                          <>
                            <Listbox.Label className="block text-2xl leading-5 font-medium text-indigo-700"></Listbox.Label>
                            <div className="relative mt-2">
                              <span className="inline-block w-full rounded-md shadow-sm">
                                <Listbox.Button
                                  onClick={() => setOpen(true)}
                                  className="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left text-base focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                >
                                  {selectedTeammember?.length === 0 && (
                                    <div className="inline-flex items-center px-1 mr-1 mt-1 text-base font-light rounded text-white bg-indigo-700">
                                      Select...
                                    </div>
                                  )}

                                  {sortByKeyAlphabetical(
                                    selectedTeammember,
                                    "title"
                                  )?.map((employee) => (
                                    <div
                                      key={employee}
                                      className="inline-flex items-center px-1 mr-1 mt-1 text-1xl font-light rounded text-white bg-indigo-700"
                                    >
                                      {employee?.title}
                                      <div
                                        className="ml-1 bg-indigo-100 rounded-full cursor-pointer"
                                        onClick={() => removeSelected(employee)}
                                      >
                                        <svg
                                          width="15"
                                          height="15"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                                            fill="#4A5568"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  ))}
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg
                                      className="h-5 w-5 text-gray-400"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </Listbox.Button>
                              </span>

                              {open && (
                                // Display options when isOpen is true

                                <Listbox.Options
                                  static
                                  className="absolute mt-1 w-full max-h-64 overflow-y-auto pb-5 rounded-md  bg-slate-300  shadow-lg"
                                >
                                  <ScrollArea className="h-80 px-2">
                                    {sortByKeyAlphabetical(
                                      filteredTeamMembers,
                                      "title"
                                    )?.map((member) => (
                                      <Listbox.Option
                                        key={member}
                                        value={member}
                                      >
                                        {({ active }) => (
                                          <div
                                            className={`${
                                              active
                                                ? "text-white bg-indigo-600"
                                                : "text-gray-900"
                                            }  select-none cursor-pointer  relative py-2 pl-8 pr-4`}
                                          >
                                            <span className="block truncate px-1 mr-1 mt-1 text-1xl">
                                              {member?.title}
                                            </span>
                                            {isSelected(member) && (
                                              <span
                                                className={`${
                                                  active
                                                    ? "text-white"
                                                    : "text-blue-600"
                                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                              >
                                                <svg
                                                  className="h-5 w-5"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 20 20"
                                                  fill="currentColor"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </ScrollArea>
                                </Listbox.Options>
                              )}
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        className=" bg-green-500"
                        onClick={() => {
                          const teammember = selectedTeammember?.map(
                            (item) => item?.id
                          );
                          const projectToBeAdded = {
                            id: assignProject?.id,
                            title: assignProject?.title,
                            organizationID: assignProject?.assignProject,
                            assignedBy: assignProject?.assignedBy,
                            assignedTo: assignProject?.assignedTo,
                            status: assignProject?.status,
                            teammembers: teammember,
                          };
                          log.debug("projectToBeAdded", projectToBeAdded);
                          employeeMachineService.send({
                            type: "assign_project",
                            data: {
                              projectToBeAdded: projectToBeAdded,
                              myEmployeeProfile: myEmployeeProfile,
                            },
                          });
                          setOpen(false);
                        }}
                      >
                        Assign
                      </Button>
                      <Button
                        type="button"
                        variant="danger"
                        className="mr-5"
                        onClick={() => {
                          employeeMachineService.send({
                            type: "cancle_crud",
                          });
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    </>
  );
};
