import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";

export const hookAddEmployeeCourse = async (EmployeeCourse) => {
  log.debug(" hookAddEmployeeCourse : ", EmployeeCourse);

  try {
    const result = await API.graphql({
      query: mutations.createEmployeeCourse,
      variables: {
        input: EmployeeCourse,
      },
    });

    log.debug(" hookAddEmployeeCourse result: ", result);

    return result?.data?.createEmployeeCourse;
  } catch (err) {
    log.debug("CATCH ERROR hookAddEmployeeCourse result: ", err);
    return null;
  }
  //Destructuring Assignment in ES6
  //setTodos([...todos, result.data.createTodo]);
};

export const hookUpdateEmployeeCourse = async (EmployeeCourse) => {
  log.debug(" hookUpdateEmployeeCourse : ", EmployeeCourse);
  try {
    const result = await API.graphql({
      query: mutations.updateEmployeeCourse,
      variables: {
        input: EmployeeCourse,
      },
    });

    log.debug(" hookUpdateEmployeeCourse result: ", result);
    return result?.data?.updateEmployeeCourse;
  } catch (err) {
    log.debug("CATCH ERROR hookAddUser result: ", err);
  }
};

export const hookGetEmployeeCourseById = async (id) => {
  log.debug(" hookGetEmployeeCourseById  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: queries.getEmployeeCourse,
      variables: {
        questionId: id,
      },
    });

    log.debug(" hookGetEmployeeCourseById : ", data);
    if (data !== null) return data?.data?.getEmployeeCourse;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeCourseByCognitoId -- ", e);
    return null;
  }
};

export const hookListEmployeeCourses = async () => {
  //Connect Client Amplify GraphQL
  //TODO: add filter to get EmployeeCourse by restaurent Id
  try {
    const result = await API.graphql({
      query: queries.listEmployeeCourses,
      variables: {},
    });

    log.debug(" hooklistEmployeeCourse : ", result);

    if (result !== null) return result?.data?.listEmployeeCourses?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hooklistEmployeeCourse ", err);
  }
};

export const hookListEmployeeCoursesByOrganizationId = async (orgId) => {
  //Connect Client Amplify GraphQL
  //TODO: add filter to get EmployeeCourse by restaurent Id
  try {
    const result = await API.graphql({
      query: queries.EmployeeCoursesByOrganizationID,
      variables: { organizationID: orgId },
    });

    log.debug(" hookListEmployeeCoursesByOrganizationId : ", result);

    if (result !== null)
      return result?.data?.EmployeeCoursesByOrganizationID?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookListEmployeeCoursesByOrganizationId ", err);
  }
};

export const hookGetEmployeeCourseByUserId = async (userID) => {
  //Connect Client Amplify GraphQL
  log.debug(" hookGetEmployeeCourseByUserId : ", userID);
  try {
    const result = await API.graphql({
      query: queries.EmployeeCoursesByUserID,
      variables: {
        userID: userID,
      },
    });

    log.debug(" hookGetEmployeeCourseByUserId : ", result);

    if (result !== null) return result?.data?.EmployeeCoursesByUserID?.items;
    else return null;
  } catch (err) {
    log.debug("CATCH ERROR hookGetEmployeeCourseByUserId ", err);
  }
};

export const hookDeleteEmployeeCourseById = async (id) => {
  log.debug(" hookGetEmployeeCourseById  ", id);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteEmployeeCourse,
      variables: {
        input: { id: id },
      },
    });

    log.debug(" hookDeleteEmployeeCourseById : ", data);
    if (data !== null) return data?.data?.deleteEmployeeCourse;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteEmployeeCourseByCognitoId -- ", e);
    return null;
  }
};
