import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../CustomComponentsLibrary/Card";
import { Button } from "../../CustomComponentsLibrary/Button";
import { Progress } from "../../CustomComponentsLibrary/Progress";
import { Skeleton } from "../../CustomComponentsLibrary/Skeleton";
import image from "../../../images/buisness2.jpg";
import { Link } from "react-router-dom";
import { TruncateDescription } from "../../common/TruncateDescription";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

export default function CourseCard({ data }) {
  console.log("data::CourseCard", data);
  console.log(
    "data::CourseCard",
    (data?.completedTopicResources?.length /
      data?.totalTopicResources?.length) *
      100
      ? (data?.completedTopicResources?.length /
          data?.totalTopicResources?.length) *
          100
      : 0
  );
  const { theme } = useAuthContextData();
  return (
    <div>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4">
        {data ? (
          <Card
            className={`"h-fit bg-white max-w-sm  sm:w-[430px] w-[290px] rounded-lg overflow-hidden border-2  shadow-lg  hover:shadow-lg transition duration-300" ${theme?.borderBg}`}
          >
            <CardHeader className="relative  p-3">
              <img
                src={image}
                alt="Product Image"
                className="w-full h-48 bg-contain bg-center rounded-md"
              />
            </CardHeader>
            <CardContent className=" h-auto">
              <CardTitle
                className={`"text-base font-semibold mb-2" ${theme?.textCommon}`}
              >
                {data?.name}
                <div className="text-sm text-muted-foreground font-thin flex flex-row mt-1 justify-between">
                  <span>By - {data?.instructor}.</span>
                  <span>|</span>
                  <span>{data?.lessons.items?.length} lessons </span>
                  <span> {data?.hours} total hours</span>
                </div>
              </CardTitle>

              <CardDescription className="text-gray-600 mb-4">
                <div className="text-sm font-bold">Description</div>

                <div className="text-sm text-muted-foreground font-thin">
                  <TruncateDescription
                    content={data.description}
                    uptoChars={150}
                  />
                </div>
              </CardDescription>

              <div className="text-gray-700 mb-2  flex flex-row p-1 justify-center text-xs font-semibold">
                <Progress
                  value={
                    (data.completedTopicResources?.length /
                      data.totalTopicResources?.length) *
                    100
                      ? (data.completedTopicResources?.length /
                          data.totalTopicResources?.length) *
                        100
                      : 0
                  }
                  className="w-[40%] mt-1"
                  rootCss={`${theme?.bgLight}`}
                  indicatorCss={`${theme?.bgCommon}`}
                />
                <span className={`"mx-1" ${theme?.textCommon}`}>
                  {(data.completedTopicResources?.length /
                    data.totalTopicResources?.length) *
                  100
                    ? (data.completedTopicResources?.length /
                        data.totalTopicResources?.length) *
                      100
                    : 0}
                  %
                </span>
              </div>
            </CardContent>
            <CardFooter className="relative  bg-white">
              <Link
                to={`/employee/courses/${data?.courseID}/lessons`}
                className="w-full"
              >
                <Button
                  className={`"py-4  px-4 w-full bg-transpert" ${theme?.borderBg} ${theme?.textCommon} ${theme?.hoverbg} ${theme?.hoverText} ${theme?.border}`}
                >
                  Continue
                </Button>
              </Link>
            </CardFooter>
          </Card>
        ) : (
          <Card className="h-fit bg-white max-w-sm  sm:w-[430px] w-[290px] rounded-lg overflow-hidden border-2 border-light shadow-lg hover:border-background hover:shadow-lg transition duration-300">
            <CardHeader className="relative  p-3">
              <Skeleton className="w-full h-52 bg-contain bg-center rounded-md" />
            </CardHeader>
            <CardContent className="p-3">
              <CardTitle className="text-xl text-common font-semibold mb-2">
                <Skeleton className="w-full h-4 bg-contain bg-center rounded-md" />

                <div className="text-sm text-muted-foreground font-thin flex flex-row mt-1 justify-between">
                  <Skeleton className="w-full h-4 bg-contain bg-center rounded-md" />
                </div>
              </CardTitle>
              <CardDescription className="text-gray-600 mb-4 h-14">
                <Skeleton className="w-full h-4 bg-contain bg-center rounded-md" />
              </CardDescription>
              <div className="text-gray-700 mb-2  flex flex-row p-1 justify-center">
                <Skeleton className="w-full h-4 bg-contain bg-center rounded-md" />
              </div>
            </CardContent>
            <CardFooter className="p-4 bg-white">
              <Skeleton className="h-7  w-full" />
            </CardFooter>
          </Card>
        )}
      </div>
    </div>
  );
}
