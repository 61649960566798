import { createMachine, assign } from "xstate";
import log from "loglevel";
import {
  getCoursesByUserIdService,
  submitCourseDataService,
  updateCourseDataService,
  deleteCourseDataService,
} from "../services/employeeServices/courseServices";
import {
  getEmployeePersonalDetailService,
  getHierarchyDataByEmployeeService,
  getMyEmployeeProfileService,
  getTeamLeadEmployeeListService,
  updateEmployeeProfileService,
} from "../services/employeeServices/employeeService";
import {
  getGoalDataByEmployeeIdService,
  submitGoalDataService,
  updateGoalDataService,
  deleteGoalDataService,
} from "../services/employeeServices/goalServices";
import {
  getReimbursementDataByEmployeeIdService,
  submitReimbursementDataService,
  updateReimbursementDataService,
  deleteReimbursementDataService,
} from "../services/employeeServices/reimbursementServices";
import {
  getTaskDataByEmployeeIdService,
  submitTaskDataService,
  updateTaskDataService,
  deleteTaskDataService,
  getTaskDataBySelectedProjectId,
} from "../services/employeeServices/tasksServices";
import {
  deleteTaskHoursDataService,
  submitTaskHoursDataService,
  updateMultipleTaskHoursDataService,
  updateTaskHoursDataService,
  getEmployeeLogHoursService,
  getEmployeeTaskHoursByTaskIdForSelectedDatesService,
  updateWeeklyTaskHoursDataService,
  submitWeeklyTaskHoursDataService,
  approveAndRejectLogHoursDataService,
  deleteWeeklyTaskHoursDataService,
} from "../services/employeeServices/taskHoursServices";
import {
  getLeaveDataByEmployeeIdService,
  updateLeaveDataService,
  submitLeaveDataService,
  submitMultipleLeaveDataService,
  deleteLeaveDataService,
  actionOnMultipleLeaveDataService,
} from "../services/employeeServices/leaveServices";
import {
  getCoffDataByEmployeeIdService,
  updateCoffDataService,
  submitCoffDataService,
  submitMultipleCoffDataService,
  deleteCoffDataService,
  actionOnMultipleCoffDataService,
} from "../services/employeeServices/coffServices";
import {
  ListAttendanceDataService,
  deleteAttendanceByAttendanceIdService,
  submitAttendanceDataService,
  updateAttendanceService,
} from "../services/employeeServices/attendanceServices";
import { assignContext, assignUpdateContext } from "./actions/actions";

import {
  submitInventoryDataService,
  updateInventoryDataService,
  deleteInventoryDataService,
  getInventoryDataByEmployeeIdService,
} from "../services/employeeServices/inventoryServices";
import { hookGetEmployeeTaskStatusByUserId } from "../hooks/employeeTasksHooks";
import { hookListGoalsProgressByUserId } from "../hooks/goalsHooks";
import {
  hookListEmployeeLeavesOfCurrentWeek,
  hookListLeaveStatusByUserId,
  hookListLeavesByUserId,
} from "../hooks/leaveHooks";
import {
  hookGetTeamShortProfilesByGuideID,
  hookGetTeamShortProfilesByOrgID,
  hookListEmployeesByOrgnizationId,
} from "../hooks/employeeHooks";

import { hookgetAttendanceHoursByUserId } from "../hooks/attendanceHooks";
import {
  getFirstAndLastDateTimeOfWeekArray,
  getWorkingDaysOfWeek,
} from "../lib/dateService";
import moment from "moment";
import {
  CONST_EMPLOYEE_STATUS,
  CONST_GOAL_STATUS,
  CONST_LEAVE_STATUS,
  CONST_TASK_STATUS,
} from "../constants/dbconstants";
import { hookListSalarySlipByUserId } from "../hooks/salarySlipHooks";
import {
  getProjectDataByOrganizationIdService,
  getTaskHoursByTaskIdService,
  getEmployeeTasksHoursByMonth,
  getTaskHoursByTaskIdAndDateService,
  assignProjectToTeammemberService,
} from "../services/employeeServices/projectServices";
import { initializeWeekdays } from "../lib/dateService";
import { hookGetTaskHoursByMonthAndUserId } from "../hooks/HookTaskHours";
import { hookGetEmployeeCourseByUserId } from "../hooks/employeeCourseHooks";
import {
  createBlogService, 
  deleteBlogService, 
  getBlogsByEmployeeIdService, 
  updateBlogService,
} from "../services/blogsServices/blogServices";

export const employeeMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QHcCGBLALmATgWVQGMALdAOzADoAVAQQCEAZAUQGVLaBhagSQDVmAfTpMhjAPK0AIjwByAcQDEEAPYVK5AG4qA1lTRZcBEuSoiW7LrwHCGLQROlz5CLSsKpM6NQG0ADAC6-gGIoAAOKrBY3mShIACeiABMABwpADQgAB6IAMwAnACMlACsAGxJJQC+VZkG2PhEpOrmbBzc-EKtDpIyCoq4OCo4lGEANp4AZsMAtpT1Rk2mNHZtVp22oj1OCq5k2h5evoHBcRFRR7FI2XlFpRUlmYkISQAsfjV1GA3GzWar7FarG2UmYUkUsDAYzAhGwEEEmFQACNoadrudomo4jkEK8SgB2Ur4174-FlIq5MkpMpPPIlFKUQpMwplPz5an5Pz4pKfEALRomFoAlaiYGOUFSSicACqACVZcxZNRBOJZaC1T0+kpVOo3Hp5t9FoL-qKRRYQWCpXKFUqVWrmBqJFq9gdPDFgmjwpFMVdQDjcq9XpQkvjCvliWGSvkkrlco8Esl8iVg34UiG-Bmua9Y7z+b9lkCzWwLZKZfLFcrVeqpJrnAMcEMRuMprMDYYBX8i4CASWreXbVWHTWnc4Xe43ccgoEzt7Lti8oHg6Hw69I9HY-HnnikpQyvSQ3j8SlcikM7lc4aO8tB7L2DexeIpFrlGoqHr9Jf8+p75R75rnW4hzuic07orOMTzgghRkqUfiriUsZlGm5RJLSCAALSvCkF7tl+VA-n+TrPoMwyjBMmDTDgcx5ks372rev70Q+T6joBE5kB6oFehcEHXP6hQ7tmZTkoUKTlLkTL5Gh6Gicm1JpNyeJ7iU9I1LUIBkCoEBwHENHGjOPFYnxiDobkXKlPScFlNyfjCeU0m5KylDySkoaBkUSFcjhPy0SaFgGT6kHoW8yYqcerzCRJxInmhAl+CmabRiUrxJEkqZlIU3lGp2hbrDY3Tis4AVzsZCB+GhqTYepek5cKQIlsVvF+iZYaEmFAaRdB2YZAmLzmfiqapAJbn4lG1TVZ+vldl2vZljalb0cOj5FWBhm+jcGGFK8+QWeFnXRT1zwqUG6XvEUob0vkZRZVeQqmvV4qWnNFZ2uqD7SGCjVGc1m3bbtHVlFF3VoficalNSZ3QYUl3XRNuFTfeX3rTiwVRrtVk2XZNK9cFoOUI5aTkvi+T5Hiq6w188PGoxb00w6zFakjQVJEy6MRZjyn4hVlKUElfiFFmSGHjdeF0wxhG9J9q2BaVMnhv9EWA11MW9e8ZQJW8aZpZS1KwzUQA */
  createMachine(
    {
      id: "employeeMachine",

      context: {
        organizationID: null,
        userID: null,
        organization: null,

        goals: [],
        goalToBeAdded: null,
        selectedGoalId: undefined,
        selectedGoal: null,
        actionForGoalCRUD: "",

        blogs: [],
        blogToBeAdded: null,
        selectedBlogId: undefined,
        selectedBlog: null,
        selectedBlogToEdit:null,
        actionForBlogCRUD: "",
        blogToAdd:null,
        blogsData:[],
        tasks: [],
        taskToBeAdded: null,
        selectedTask: null,
        selectedTaskId: undefined,
        actionForTaskCRUD: "",
        taskHoursToBeAdded: null,
        employeeTaskHoursForDay: null,
        employeeTaskHours: [],
        logHourHistory: [],

        taskHoursWithTitle: [],
        projectIdArray: [],
        employeeWeeklyTaskHours: [],
        unMergedemployeeWeeklyTaskHours: [],
        taskHours: [],
        selectedTaskHour: null,
        selectedTaskHourToBeDelete: null,
        personalTaskHour: [],
        selectedLogHourForApprove: [],
        filterLogHourForApprove: [],
        isApprove: false,
        projectToBeAdded: null,

        selectedTopic: null,
        selectedLesson: null,
        selectedTopicResource: null,
        courseToBeAdded: null,
        selectedCourseId: undefined,
        selectedCourse: null,
        actionForCourseCRUD: "",
        courses: [],

        inventories: [],
        inventoryToAdd: null,
        selectedInventory: null,
        selectedInventoryId: undefined,
        actionForInventoryCRUD: "",

        coffs: [],
        coffsToAdd: null,
        selectedCoff: null,
        selectedCoffId: undefined,
        actionForCoffCRUD: "",
        selectedYear: null,
        selectedCoffsForAction: [],
        actionOnCoff: "",


        leaves: [],
        leavesToAdd: null,
        selectedLeave: null,
        selectedLeaveId: undefined,
        actionForLeaveCRUD: "",
        selectedYear: null,
        selectedLeavesForAction: [],
        actionOnLeave: "",
        leavesOverview: null,

        reimbursements: [],
        reimbursementsToAdd: null,
        selectedReimbursement: null,
        selectedReimbursementId: undefined,
        actionForReimbursementCRUD: "",

        salarySlips: [],
        personalProfile: null,
        personalProfileToBeUpdate: null,
        myEmployeeProfile: null,
        employeeList: null,
        attendanceObjectToBeAdded: null,
        selectedAttendanceId: undefined,

        attendancesList: [],
        attendanceId: "",
        timeInOne: null,
        timeOutOne: null,
        timeInTwo: null,
        timeOutTwo: null,
        todayAttendance: null,
        description: [],

        employeeProfile: null,
        dashboardData: {
          tasks: [],
          goals: [],
          leaves: [],
          todayAttendance: null,
          myTimeSheetCurrentWeekLastWeek: {
            lastWeek: [], //length m
            currentWeek: [], //length m
          },
          teamTimesheetCurrentWeek: [],
          teamMemberGoalsStatuses: [],
          teamMemberTasksStatuses: [],
          teamMemberCurrentWeekLeave: [],
          billableHours: null,

          projects: [],
          projectToBeAdded: null,
          selectedProject: null,
          actionForProjectCRUD: "",
          taskHoursToBeAdded: null,

          taskHours: [],
          projectStats: [],
          teamMembersNames: [],
          logHoursArrayToAdd: [],
        },
        message: null,
        selectedProjectId: null,
        selectedProjectForTasks: [],
        projectTasks: [],
        multipleProjectTasks: [],
        taskHourByMonth: null,
        startDay: null,
        endDay: null,
        loghoursDataTobeAdded: null,
        actionForTaskHoursCURD: null,
        logHourDate: null,
        selectedMonth: null,
        orgEmployeeManagerArray: [],
        userEmployeeList: [],
        initNodesData: [],
        initEdgesData: [],
        teamLeadMembers: [],
        teamLeadMembersToUpdate: null,
      },

      type: "parallel",

      states: {
        DASHBOARD: {
          initial: "WAIT_LOAD_DASHBOARD",
          states: {
            WAIT_LOAD_DASHBOARD: {
              on: { load_dashboard_data: "DASHBOARD_LOADING" },
            },
            DASHBOARD_LOADING: {
              invoke: {
                src: "loadDashbordData",
                onDone: {
                  target: "DASHBOARD_LOADED",
                  actions: "assignContext",
                },
                onError: {
                  target: "DASHBOARD_LOADED",
                },
              },
            },
            DASHBOARD_LOADED: {
              on: {
                reload: "DASHBOARD_LOADING",
              },
            },
          },
        },
        EMPLOYEE_PROFILE: {
          initial: "PROFILE_LOADING",
          states: {
            PROFILE_LOADING: {
              invoke: {
                src: "getMyEmployeeProfile",
                onDone: {
                  target: "PROFILE_LOADED",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "PROFILE_LOADED",
                },
              },
            },
            PROFILE_LOADED: {
              on: {
                get_employee_list: {
                  target: "EMPLOYEE_LIST_TEAMLEAD_LOADING",
                  actions: "assignContext",
                },
              },
            },
            EMPLOYEE_LIST_TEAMLEAD_LOADING: {
              invoke: {
                src: "getTeamLeadEmployeeList",
                onDone: {
                  target: "PROFILE_LOADED",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "PROFILE_LOADED",
                  actions: "assignUpdateContext",
                },
              },
            },
          },
        },
        PERSONAL_PROFILE: {
          initial: "PERSONAL_PROFILE_LOADING",
          states: {
            PERSONAL_PROFILE_LOADING: {
              invoke: {
                src: "getEmployeePersonalDetail",
                onDone: {
                  target: "PERSONAL_PROFILE_LOADED",
                  actions: "assignContext",
                },
              },
            },
            PERSONAL_PROFILE_LOADED: {
              on: {
                update_profile: {
                  target: "UPDATE_PROFILE",
                  actions: "assignUpdateContext",
                },
                // get_hierarchy_data: {
                //   target: "HIERARCHY_DATA",
                //   actions: "assignContext",
                // },
              },
            },

            UPDATE_PROFILE: {
              invoke: {
                src: "updateEmployeeProfile",
                onDone: {
                  target: "PERSONAL_PROFILE_LOADED",
                  actions: "assignContext",
                },
              },
            },
          },
        },
        HIERARCHY_DATA: {
          initial: "WAIT_FOR_ORG_EVENT",
          states: {
            WAIT_FOR_ORG_EVENT: {
              on: {
                send_hirarchy_event: {
                  target: "HIERARCHY_DATA_LOADING",
                  actions: "assignContext",
                },
              },
            },
            HIERARCHY_DATA_LOADING: {
              invoke: {
                src: "getHierarchyData",
                onDone: {
                  target: "HIERARCHY_DATA_LOADED",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "HIERARCHY_DATA_LOADED",
                },
              },
            },
            HIERARCHY_DATA_LOADED: {},
          },
        },
        GOALS: {
          initial: "GOALS_LOADING",
          states: {
            GOALS_LOADING: {
              invoke: {
                src: "getGoalDataByEmployeeId",
                onDone: {
                  target: "GOALS_LOADED",
                  actions: "assignContext",
                },
              },
            },

            GOALS_LOADED: {
              on: {
                goals_crud: {
                  target: "GOAL_CRUD",
                  actions: ["assignContext"],
                },
                reload_goals: "GOALS_LOADING",
              },
            },

            GOAL_CRUD: {
              initial: "GOAL_ACTION",
              states: {
                GOAL_ACTION: {
                  on: {
                    create_goal: {
                      target: "GOAL_CREATING",
                      actions: "assignContext",
                    },
                    update_goal: {
                      target: "GOAL_UPDATING",
                      actions: "assignContext",
                    },
                    delete_goal: {
                      target: "GOAL_DELETING",
                    },
                    cancel_crud: {
                      target: "#employeeMachine.GOALS.GOALS_LOADED",
                      actions: "assignSelectedGoalToNull",
                    },
                  },
                },

                GOAL_CREATING: {
                  invoke: {
                    src: "submitGoalData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.GOALS.GOAL_CRUD.GOAL_CRUD_SUCCEEDED",
                        actions: [
                          "assignGoadToBeAddedToNull",
                          "assignUpdateContext",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.GOALS.GOAL_CRUD.GOAL_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },

                GOAL_UPDATING: {
                  invoke: {
                    src: "updateGoalData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.GOALS.GOAL_CRUD.GOAL_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignGoalToBeAddedToNull",
                          "assignSelectedGoalToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.GOALS.GOAL_CRUD.GOAL_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          // "assignGoalToBeAddedToNull",
                          // "assignSelectedGoalToNull",
                        ],
                      },
                    ],
                  },
                },

                GOAL_DELETING: {
                  invoke: {
                    src: "deleteGoalData",
                    onDone: [
                      {
                        target: "#employeeMachine.GOALS.GOALS_LOADING",
                        actions: ["assignSelectedGoalToNull"],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.GOALS.GOALS_LOADING",
                        actions: ["assignContext", "assignSelectedGoalToNull"],
                      },
                    ],
                  },
                },

                GOAL_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.GOALS.GOAL_CRUD.GOAL_ACTION",
                      actions: "assignMessageToNull",
                    },
                  },
                },

                GOAL_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.GOALS.GOALS_LOADED",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },

        TASKS: {
          initial: "TASKS_LOADING",
          states: {
            TASKS_LOADING: {
              invoke: {
                src: "getTaskDataByEmployeeId",
                onDone: {
                  target: "TASKS_LOADED",
                  actions: "assignUpdateContext",
                },
              },
            },
            TASKS_LOADED: {
              on: {
                task_crud: {
                  target: "TASK_CRUD",
                  actions: ["assignContext"],
                },

                reload_tasks: "TASKS_LOADING",
              },
            },
            TASK_CRUD: {
              initial: "TASK_ACTION",
              states: {
                TASK_ACTION: {
                  on: {
                    create_task: {
                      target: "TASK_CREATING",
                      actions: "assignContext",
                    },
                    update_task: {
                      target: "TASK_UPDATING",
                      actions: "assignContext",
                    },
                    delete_task: {
                      target: "TASK_DELETING",
                    },

                    fetch_task_Hours_by_taskId: {
                      target: "FETCH_TASK_HOURS",
                    },
                    add_task_hours: {
                      target: "ADD_TASK_HOURS",
                      actions: "assignContext",
                    },

                    update_multiple_task_hours: {
                      target: "UPDATE_MULTIPLE_TASK_HOURS",
                      actions: "assignMultipleLoghoursToContext",
                    },

                    cancel_crud: {
                      target: "#employeeMachine.TASKS.TASKS_LOADING",
                      actions: [
                        "assignSelectedTaskToNull",
                        "assignSelectedTaskHourToNull",
                      ],
                    },
                  },
                },

                TASK_CREATING: {
                  invoke: {
                    src: "submitTaskData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.TASKS.TASK_CRUD.TASK_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignTaskToBeAddedToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.TASKS.TASK_CRUD.TASK_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },

                TASK_UPDATING: {
                  invoke: {
                    src: "updateTaskData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.TASKS.TASK_CRUD.TASK_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignTaskToBeAddedToNull",
                          "assignSelectedTaskToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.TASKS.TASK_CRUD.TASK_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          // "assignTaskToBeAddedToNull",
                          // "assignSelectedTaskToNull",
                        ],
                      },
                    ],
                  },
                },

                TASK_DELETING: {
                  invoke: {
                    src: "deleteTaskData",
                    onDone: [
                      {
                        target: "#employeeMachine.TASKS.TASKS_LOADING",
                        actions: ["assignSelectedTaskToNull"],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.TASKS.TASKS_LOADING",
                        actions: ["assignContext", "assignSelectedTaskToNull"],
                      },
                    ],
                  },
                },

                FETCH_TASK_HOURS: {
                  invoke: {
                    src: "getTaskHoursByTaskId",
                    onDone: [
                      {
                        target: "TASK_CRUD_SUCCEEDED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                    onError: [
                      {
                        target: "TASK_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },
                UPDATE_MULTIPLE_TASK_HOURS: {
                  invoke: {
                    src: "updateMultipleTaskHoursData",
                    onDone: [
                      {
                        target: "TASK_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignSelectedTaskHourToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target: "TASK_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },

                ADD_TASK_HOURS: {
                  invoke: {
                    src: "submitTaskHoursData",
                    onDone: [
                      {
                        target: "TASK_CRUD_SUCCEEDED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                    onError: [
                      {
                        target: "TASK_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },
                TASK_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.TASKS.TASK_CRUD.TASK_ACTION",
                      actions: "assignMessageToNull",
                    },
                  },
                },

                TASK_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.TASKS.TASKS_LOADING",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },

        EMPLOYEE_LOG_HOURS: {
          initial: "EMPLOYEE_LOG_HOURS_LOADING",
          states: {
            EMPLOYEE_LOG_HOURS_LOADING: {
              on: {
                get_employee_log_hours: {
                  target: "EMPLOYEE_LOG_HOURS_LOADED",
                  actions: ["assignContext", "assignEmployeeTaskHourToEmpty"],
                },
                task_hours_crud: {
                  target: "TASK_HOURS_CRUD",
                  actions: ["assignContext"],
                },
                on_month_change_clear_data: {
                  actions: ["assignEmployeeWeeklyTaskHourToEmpty"],
                },
                change_userId: {
                  actions: ["assignContext"],
                },
              },
            },
            EMPLOYEE_LOG_HOURS_LOADED: {
              invoke: {
                src: "getemployeeLogHoursData",
                onDone: {
                  target: "EMPLOYEE_LOG_HOURS_LOADING",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "EMPLOYEE_LOG_HOURS_LOADING",
                },
              },
              on: {
                update_rows_task_selected_id: {
                  actions: "addLocalTaskHourObj",
                },
              },
            },

            TASK_HOURS_CRUD: {
              initial: "TASK_HOURS_ACTION",
              states: {
                TASK_HOURS_ACTION: {
                  on: {
                    get_task_log_hours_by_taskid: {
                      target: "GET_EMPLOYEE_TASK_HOURS_BY_TASK_ID",
                      actions: [
                        "assignContext",
                        "assignEmployeeTaskHourToEmpty",
                      ],
                    },
                    add_Log_hours: {
                      target: "ADD_TASK_LOG_HOURS",
                      actions: "assignContext",
                    },

                    update_task_hours: {
                      target: "UPDATE_TASK_LOG_HOURS",
                      actions: "assignContext",
                    },
                    delete_task_hours: {
                      target: "DELETE_TASK_LOG_HOURS",
                      actions: "assignContext",
                    },
                    approve_task_hours: {
                      target: "APPROVE_REJECT_LOG_HOURS",
                      actions: "assignContext",
                    },

                    cancel_task_hours_crud: {
                      target:
                        "#employeeMachine.EMPLOYEE_LOG_HOURS.EMPLOYEE_LOG_HOURS_LOADING",
                      actions: [
                        "assignSelectedTaskToNull",
                        "assignSelectedTaskHourToNull",
                      ],
                    },
                  },
                },

                GET_EMPLOYEE_TASK_HOURS_BY_TASK_ID: {
                  invoke: {
                    src: "getEmployeeLogHoursByTaskID",
                    onDone: {
                      target: "GET_EMPLOYEE_TASK_HOURS_BY_TASK_ID_SUCCEEDED",
                      actions: "assignUpdateContext",
                    },
                    onError: {
                      target: "GET_EMPLOYEE_TASK_HOURS_BY_TASK_ID_ERRORED",
                    },
                  },
                },
                ADD_TASK_LOG_HOURS: {
                  invoke: {
                    src: "submitLogHoursData",
                    onDone: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_SUCCEEDED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                    onError: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },
                UPDATE_TASK_LOG_HOURS: {
                  invoke: {
                    src: "updateLogHoursData",
                    onDone: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_SUCCEEDED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                    onError: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },
                DELETE_TASK_LOG_HOURS: {
                  invoke: {
                    src: "deleteLogHourData",
                    onDone: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_SUCCEEDED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                    onError: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },
                APPROVE_REJECT_LOG_HOURS: {
                  invoke: {
                    src: "approveAndRejectLogHours",
                    onDone: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_SUCCEEDED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                    onError: [
                      {
                        target: "EMPLOYEE_LOG_HOURS_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },
                EMPLOYEE_LOG_HOURS_ERRORED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.EMPLOYEE_LOG_HOURS.EMPLOYEE_LOG_HOURS_LOADING",
                      actions: [
                        "assignMessageToNull",
                        "assignLogHoursDateToNull",
                      ],
                    },
                  },
                },
                EMPLOYEE_LOG_HOURS_SUCCEEDED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.EMPLOYEE_LOG_HOURS.EMPLOYEE_LOG_HOURS_LOADING",
                      actions: [
                        "assignMessageToNull",
                        "assignLogHoursDateToNull",
                      ],
                    },
                  },
                },
                GET_EMPLOYEE_TASK_HOURS_BY_TASK_ID_ERRORED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.EMPLOYEE_LOG_HOURS.TASK_HOURS_CRUD",
                      actions: "assignMessageToNull",
                    },
                  },
                },
                GET_EMPLOYEE_TASK_HOURS_BY_TASK_ID_SUCCEEDED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.EMPLOYEE_LOG_HOURS.TASK_HOURS_CRUD",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },

        TASKS_HOURS: {
          initial: "WAIT_FOR_TASK_ID",
          states: {
            WAIT_FOR_TASK_ID: {
              on: {
                get_Task_id_for_task_hours: {
                  target: "TASKS_HOURS_LOADING",
                  actions: ["assignContext", "assignTaskHoursToNull"],
                },
                get_task_id_for_task_hours_by_date: {
                  target: "TASKS_HOURS_BY_DATE_RANGE_LOADING",
                  actions: ["assignContext", "assignTaskHoursToNull"],
                },
                update_rows_task_selected_id: {
                  actions: "addLocalTaskHourObj",
                },
              },
            },
            TASKS_HOURS_LOADING: {
              invoke: {
                src: "getTaskHoursByTaskId",
                onDone: {
                  target: "TASKS_HOURS_LOADED",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "WAIT_FOR_TASK_ID",
                },
              },
            },
            TASKS_HOURS_BY_DATE_RANGE_LOADING: {
              invoke: {
                src: "getTaskHoursByTaskIdAndDate",
                onDone: {
                  target: "TASKS_HOURS_LOADED",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "WAIT_FOR_TASK_ID",
                },
              },
            },
            TASKS_HOURS_LOADED: {
              initial: "TASK_ACTION",
              states: {
                TASK_ACTION: {
                  on: {
                    get_selected_task_hours: {
                      actions: "assignContext",
                    },
                    add_task_hours: {
                      target: "ADD_TASK_HOURS",
                      actions: "assignContext",
                    },
                    update_task_hours: {
                      target: "UPDATE_TASK_HOURS",
                      actions: "assignContext",
                    },
                    delete_task_hours: {
                      target: "DELETE_TASK_HOURS",
                      actions: "assignContext",
                    },
                    reload_task_hours: {
                      target:
                        "#employeeMachine.TASKS_HOURS.TASKS_HOURS_BY_DATE_RANGE_LOADING",
                      actions: "assignContext",
                    },

                    cancel_crud: {
                      target: "#employeeMachine.TASKS_HOURS.WAIT_FOR_TASK_ID",
                      actions: [
                        "assignSelectedTaskToNull",
                        "assignSelectedTaskHourToNull",
                      ],
                    },
                  },
                },

                ADD_TASK_HOURS: {
                  invoke: {
                    src: "submitTaskHoursData",
                    onDone: [
                      {
                        target: "TASKS_HOURS_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignSelectedTaskHourToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target: "TASKS_HOURS_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },

                UPDATE_TASK_HOURS: {
                  invoke: {
                    src: "updateTaskHoursData",
                    onDone: [
                      {
                        target: "TASKS_HOURS_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignSelectedTaskHourToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target: "TASKS_HOURS_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },

                DELETE_TASK_HOURS: {
                  invoke: {
                    src: "deleteTaskHoursData",
                    onDone: [
                      {
                        target: "TASKS_HOURS_CRUD_SUCCEEDED",
                        actions: [
                          "assignSelectedTaskHourToNull",
                          "assignUpdateContext",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target: "TASKS_HOURS_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },

                TASKS_HOURS_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.TASKS_HOURS.TASKS_HOURS_LOADED",
                      actions: "assignMessageToNull",
                    },
                  },
                },
                TASKS_HOURS_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.TASKS_HOURS.TASKS_HOURS_LOADING",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },

        ATTENDANCE: {
          initial: "ATTENDANCE_LOADING",
          states: {
            ATTENDANCE_LOADING: {
              invoke: {
                src: "ListAttendanceData",
                onDone: {
                  target: "ATTENDANCE_LOADED",
                  actions: "assignContext",
                },
                onError: {
                  target: "ATTENDANCE_LOADING",
                },
              },
            },
            ATTENDANCE_LOADED: {
              on: {
                attendance_crud: {
                  target: "ATTENDANCE_CRUD",
                  actions: ["assignContext"],
                },
                reload_tasks: {
                  target: "ATTENDANCE_LOADING",
                  actions: ["assignContext"],
                },
              },
            },
            ATTENDANCE_CRUD: {
              initial: "ATTENDANCE_ACTION",
              states: {
                ATTENDANCE_ACTION: {
                  on: {
                    add_time_In_one: {
                      target: "ATTENDANCE_CREATING",
                      actions: "assignContext",
                    },
                    create_attendance: {
                      target: "ATTENDANCE_CREATING",
                      actions: "assignContext",
                    },
                    add_time_out_one: {
                      target: "ATTENDANCE_UPDATING",
                      actions: "assignContext",
                    },
                    add_time_in_two: {
                      target: "ATTENDANCE_UPDATING",
                      actions: "assignContext",
                    },
                    add_time_out_two: {
                      target: "ATTENDANCE_UPDATING",
                      actions: "assignContext",
                    },

                    update_attendance: {
                      target: "ATTENDANCE_UPDATING",
                      // actions: 'assignContext',
                    },
                    delete_attendance: {
                      target: "ATTENDANCE_DELETING",
                      actions: "assignContext",
                    },
                    cancel_crud: {
                      target: "#employeeMachine.ATTENDANCE.ATTENDANCE_LOADING",
                      actions: "",
                    },
                  },
                },

                ATTENDANCE_CREATING: {
                  invoke: {
                    src: "submitAttendanceData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.ATTENDANCE.ATTENDANCE_LOADING",
                        actions: "assignContext",
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.ATTENDANCE.ATTENDANCE_LOADED",
                        actions: "assignContext",
                      },
                    ],
                  },
                },

                ATTENDANCE_UPDATING: {
                  invoke: {
                    src: "updateAttendance",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.ATTENDANCE.ATTENDANCE_LOADING",
                        // actions: "assignContext",
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.ATTENDANCE.ATTENDANCE_LOADED",
                        actions: "assignContext",
                      },
                    ],
                  },
                },
                ATTENDANCE_DELETING: {
                  invoke: {
                    src: "deleteAttendanceByAttendanceId",
                    onDone: [
                      {
                        target: "#employeeMachine.ATTENDANCE.ATTENDANCE_LOADED",
                        // actions: 'assigncontext',
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.ATTENDANCE.ATTENDANCE_LOADED",
                        actions: "",
                      },
                    ],
                  },
                },
              },
            },
          },
        },

        LEAVES: {
          initial: "LEAVES_LOADING",
          states: {
            LEAVES_LOADING: {
              invoke: {
                src: "getLeaveDataByEmployeeId",
                onDone: {
                  target: "LEAVES_LOADED",
                  actions: "assignContext",
                },
              },
            },

            LEAVES_LOADED: {
              on: {
                leaves_crud: {
                  target: "LEAVE_CRUD",
                  actions: ["assignContext"],
                },
                action_on_teammember_leaves: {
                  target: "APPROVE_REJECT_MULTIPLE_LEAVE",
                  actions: ["assignContext"],
                },

                reload_leaves: {
                  target: "LEAVES_LOADING",
                  actions: "assignContext",
                },
                reload_leaves_for_selectedyear: {
                  target: "LEAVES_LOADING",
                  actions: "assignContext",
                },
              },
            },

            APPROVE_REJECT_MULTIPLE_LEAVE: {
              invoke: {
                src: "actionOnMultipleLeaveData",
                onDone: [
                  {
                    target: "#employeeMachine.LEAVES.LEAVE_APPROVED_SUCCEEDED",
                    actions: ["assignUpdateContext"],
                  },
                ],
                onError: [
                  {
                    target: "#employeeMachine.LEAVES.LEAVE_APPROVED_ERRORED",
                    actions: "assignUpdateContext",
                  },
                ],
              },
            },

            LEAVE_APPROVED_ERRORED: {
              on: {
                ack: {
                  target: "#employeeMachine.LEAVES.LEAVES_LOADING",
                  actions: "assignMessageToNull",
                },
              },
            },

            LEAVE_APPROVED_SUCCEEDED: {
              on: {
                ack: {
                  target: "#employeeMachine.LEAVES.LEAVES_LOADING",
                  actions: "assignMessageToNull",
                },
              },
            },

            LEAVE_CRUD: {
              initial: "LEAVE_ACTION",
              states: {
                LEAVE_ACTION: {
                  on: {
                    create_leave: {
                      target: "LEAVE_CREATING",
                      actions: "assignContext",
                    },
                    create_multiple_leave: {
                      target: "CREATING_MULTIPLE_LEAVE",
                      actions: "assignContext",
                    },
                    update_leave: {
                      target: "LEAVE_UPDATING",
                      actions: "assignContext",
                    },
                    delete_leave: {
                      target: "LEAVE_DELETING",
                    },

                    cancel_crud: {
                      target: "#employeeMachine.LEAVES.LEAVES_LOADED",
                      actions: "assignSelectedLeaveToNull",
                    },
                  },
                },

                LEAVE_CREATING: {
                  invoke: {
                    src: "submitLeaveData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.LEAVES.LEAVE_CRUD.LEAVE_CRUD_SUCCEEDED",
                        actions: [
                          "assignLeaveToBeAddedToNull",
                          "assignUpdateContext",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.LEAVES.LEAVE_CRUD.LEAVE_CRUD_ERRORED",
                        actions: "assignUpdateContext",
                      },
                    ],
                  },
                },

                CREATING_MULTIPLE_LEAVE: {
                  invoke: {
                    src: "submitMultipleLeaveData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.LEAVES.LEAVE_CRUD.LEAVE_CRUD_SUCCEEDED",
                        actions: [
                          "assignLeaveToBeAddedToNull",
                          "assignUpdateContext",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.LEAVES.LEAVE_CRUD.LEAVE_CRUD_ERRORED",
                        actions: "assignUpdateContext",
                      },
                    ],
                  },
                },

                LEAVE_UPDATING: {
                  invoke: {
                    src: "updateLeaveData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.LEAVES.LEAVE_CRUD.LEAVE_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignLeaveToBeAddedToNull",
                          "assignSelectedLeaveToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.LEAVES.LEAVE_CRUD.LEAVE_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          // "assignLeaveToBeAddedToNull",
                          // "assignSelectedLeaveToNull",
                        ],
                      },
                    ],
                  },
                },

                LEAVE_DELETING: {
                  invoke: {
                    src: "deleteLeaveData",
                    onDone: [
                      {
                        target: "#employeeMachine.LEAVES.LEAVES_LOADING",
                        actions: ["assignSelectedLeaveToNull"],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.LEAVES.LEAVES_LOADING",
                        actions: ["assignContext", "assignSelectedLeaveToNull"],
                      },
                    ],
                  },
                },

                LEAVE_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.LEAVES.LEAVE_CRUD.LEAVE_ACTION",
                      actions: "assignMessageToNull",
                    },
                  },
                },

                LEAVE_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.LEAVES.LEAVES_LOADED",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },

        COFFS: {
          initial: "COFFS_LOADING",
          states: {
            COFFS_LOADING: {
              invoke: {
                src: "getCoffDataByEmployeeId",
                onDone: {
                  target: "COFFS_LOADED",
                  actions: "assignContext",
                },
              },
            },
  
            COFFS_LOADED: {
              on: {
                coff_crud: {
                  target: "COFF_CRUD",
                  actions: ["assignContext"],
                },
                action_on_teammember_coffs: {
                  target: "APPROVE_REJECT_MULTIPLE_COFF",
                  actions: ["assignContext"],
                },
  
                reload_coffs: {
                  target: "COFFS_LOADING",
                  actions: "assignContext",
                },
                reload_coffs_for_selectedyear: {
                  target: "COFFS_LOADING",
                  actions: "assignContext",
                },
              },
            },
  
            APPROVE_REJECT_MULTIPLE_COFF: {
              invoke: {
                src: "actionOnMultipleCoffData",
                onDone: [
                  {
                    target: "#employeeMachine.COFFS.COFF_APPROVED_SUCCEEDED",
                    actions: ["assignUpdateContext"],
                  },
                ],
                onError: [
                  {
                    target: "#employeeMachine.COFFS.COFF_APPROVED_ERRORED",
                    actions: "assignUpdateContext",
                  },
                ],
              },
            },
  
            COFF_APPROVED_ERRORED: {
              on: {
                ack: {
                  target: "#employeeMachine.COFFS.COFFS_LOADING",
                  actions: "assignMessageToNull",
                },
              },
            },
  
            COFF_APPROVED_SUCCEEDED: {
              on: {
                ack: {
                  target: "#employeeMachine.COFFS.COFFS_LOADING",
                  actions: "assignMessageToNull",
                },
              },
            },
  
            COFF_CRUD: {
              initial: "COFF_ACTION",
              states: {
                COFF_ACTION: {
                  on: {
                    create_coff: {
                      target: "COFF_CREATING",
                      actions: "assignContext",
                    },
                    create_multiple_coff: {
                      target: "CREATING_MULTIPLE_COFF",
                      actions: "assignContext",
                    },
                    update_coff: {
                      target: "COFF_UPDATING",
                      actions: "assignContext",
                    },
                    delete_coff: {
                      target: "COFF_DELETING",
                    },
  
                    cancel_crud: {
                      target: "#employeeMachine.COFFS.COFFS_LOADED",
                      actions: "assignSelectedCoffToNull",
                    },
                  },
                },
  
                COFF_CREATING: {
                  invoke: {
                    src: "submitCoffData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.COFFS.COFF_CRUD.COFF_CRUD_SUCCEEDED",
                        actions: [
                          "assignCoffToBeAddedToNull",
                          "assignUpdateContext",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.COFFS.COFF_CRUD.COFF_CRUD_ERRORED",
                        actions: "assignUpdateContext",
                      },
                    ],
                  },
                },
  
                CREATING_MULTIPLE_COFF: {
                  invoke: {
                    src: "submitMultipleCoffData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.COFFS.COFF_CRUD.COFF_CRUD_SUCCEEDED",
                        actions: [
                          "assignCoffToBeAddedToNull",
                          "assignUpdateContext",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.COFFS.COFF_CRUD.COFF_CRUD_ERRORED",
                        actions: "assignUpdateContext",
                      },
                    ],
                  },
                },
  
                COFF_UPDATING: {
                  invoke: {
                    src: "updateCoffData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.COFFS.COFF_CRUD.COFF_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignCoffToBeAddedToNull",
                          "assignSelectedCoffToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.COFFS.COFF_CRUD.COFF_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          // "assignCoffToBeAddedToNull",
                          // "assignSelectedCoffToNull",
                        ],
                      },
                    ],
                  },
                },
  
                COFF_DELETING: {
                  invoke: {
                    src: "deleteCoffData",
                    onDone: [
                      {
                        target: "#employeeMachine.COFFS.COFFS_LOADING",
                        actions: ["assignSelectedCoffToNull"],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.COFFS.COFFS_LOADING",
                        actions: ["assignContext", "assignSelectedCoffToNull"],
                      },
                    ],
                  },
                },
  
                COFF_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.COFFS.COFF_CRUD.COFF_ACTION",
                      actions: "assignMessageToNull",
                    },
                  },
                },
  
                COFF_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.COFFS.COFFS_LOADED",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },

        REIMBURSEMENTS: {
          initial: "WAIT_LOAD_REIMBURSEMENT",
          states: {
            WAIT_LOAD_REIMBURSEMENT: {
              on: {
                load_reimbursements: {
                  target: "REIMBURSEMENTS_LOADING",
                },
              },
            },
            REIMBURSEMENTS_LOADING: {
              invoke: {
                src: "getReimbursementDataByEmployeeId",
                onDone: {
                  target: "REIMBURSEMENTS_LOADED",
                  actions: "assignContext",
                },
              },
            },
            REIMBURSEMENTS_LOADED: {
              on: {
                reimbursement_crud: {
                  target: "REIMBURSEMENT_CRUD",
                  actions: ["assignContext"],
                },
                reload_reimbursements: {
                  target: "REIMBURSEMENTS_LOADING",
                  actions: ["assignContext"],
                },
              },
            },
            REIMBURSEMENT_CRUD: {
              initial: "REIMBURSEMENT_ACTION",
              states: {
                REIMBURSEMENT_ACTION: {
                  on: {
                    create_reimbursement: {
                      target: "REIMBURSEMENT_CREATING",
                      actions: "assignContext",
                    },
                    update_reimbursement: {
                      target: "REIMBURSEMENT_UPDATING",
                      actions: "assignContext",
                    },
                    delete_reimbursement: {
                      target: "REIMBURSEMENT_DELETING",
                    },
                    cancel_crud: {
                      target:
                        "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENTS_LOADING",
                      actions: "assignSelectedReimbursementToNull",
                    },
                  },
                },

                REIMBURSEMENT_CREATING: {
                  invoke: {
                    src: "submitReimbursementData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENT_CRUD.REIMBURSEMENT_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignReimbursementToBeAddedToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENT_CRUD.REIMBURSEMENT_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          // "assignReimbursementToBeAddedToNull",
                          // "assignSelectedReimbursementToNull",
                        ],
                      },
                    ],
                  },
                },

                REIMBURSEMENT_UPDATING: {
                  invoke: {
                    src: "updateReimbursementData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENT_CRUD.REIMBURSEMENT_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignReimbursementToBeAddedToNull",
                          "assignSelectedReimbursementToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENT_CRUD.REIMBURSEMENT_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          // "assignReimbursementToBeAddedToNull",
                          // "assignSelectedReimbursementToNull",
                        ],
                      },
                    ],
                  },
                },

                REIMBURSEMENT_DELETING: {
                  invoke: {
                    src: "deleteReimbursementData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENT_CRUD.REIMBURSEMENT_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignSelectedReimbursementToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENT_CRUD.REIMBURSEMENT_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          // "assignSelectedReimbursementToNull",
                          // "assignSelectedReimbursementToNull",
                        ],
                      },
                    ],
                  },
                },

                REIMBURSEMENT_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENT_CRUD.REIMBURSEMENT_ACTION",
                      actions: "assignMessageToNull",
                    },
                  },
                },

                REIMBURSEMENT_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.REIMBURSEMENTS.REIMBURSEMENTS_LOADED",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },

        INVENTORY: {
          initial: "WAIT_LOAD_INVENTORY",
          states: {
            WAIT_LOAD_INVENTORY: {
              on: {
                load_inventories: {
                  target: "INVENTORY_LOADING",
                  actions: "assignContext",
                },
              },
            },
            INVENTORY_LOADING: {
              invoke: {
                src: "getInventoryDataByEmployeeId",
                onDone: {
                  target: "INVENTORY_LOADED",
                  actions: "assignContext",
                },
              },
            },
            INVENTORY_LOADED: {
              on: {
                inventory_crud: {
                  target: "INVENTORY_CRUD",
                  actions: ["assignContext"],
                },
                reload_inventories: "INVENTORY_LOADING",
              },
            },
            INVENTORY_CRUD: {
              initial: "INVENTORY_ACTION",
              states: {
                INVENTORY_ACTION: {
                  on: {
                    create_inventory: {
                      target: "INVENTORY_CREATING",
                      actions: "assignContext",
                    },
                    update_inventory: {
                      target: "INVENTORY_UPDATING",
                      actions: "assignContext",
                    },
                    delete_inventory: {
                      target: "INVENTORY_DELETING",
                    },
                    cancel_crud: {
                      target: "#employeeMachine.INVENTORY.INVENTORY_LOADING",
                      actions: "assignSelectedInventoryToNull",
                    },
                  },
                },

                INVENTORY_CREATING: {
                  invoke: {
                    src: "submitInventoryData",
                    onDone: [
                      {
                        target: "#employeeMachine.INVENTORY.INVENTORY_LOADED",
                        actions: "assignInventoryToBeAddedToNull",
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.INVENTORY.INVENTORY_LOADED",
                        actions: "assignContext",
                      },
                    ],
                  },
                },

                INVENTORY_UPDATING: {
                  invoke: {
                    src: "updateInventoryData",
                    onDone: [
                      {
                        target: "#employeeMachine.INVENTORY.INVENTORY_LOADING",
                        actions: [
                          "assignInventoryToBeAddedToNull",
                          "assignSelectedInventoryToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.INVENTORY.INVENTORY_LOADING",
                        actions: [
                          "assignContext",
                          "assignInventoryToBeAddedToNull",
                          "assignSelectedInventoryToNull",
                        ],
                      },
                    ],
                  },
                },

                INVENTORY_DELETING: {
                  invoke: {
                    src: "deleteInventoryData",
                    onDone: [
                      {
                        target: "#employeeMachine.INVENTORY.INVENTORY_LOADING",
                        actions: ["assignSelectedInventoryToNull"],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.INVENTORY.INVENTORY_LOADING",
                        actions: [
                          "assignContext",
                          "assignSelectedInventoryToNull",
                        ],
                      },
                    ],
                  },
                },
              },
            },
          },
        },

        SALARYSLIPS: {
          initial: "SALARYSLIPS_LOADING",
          states: {
            SALARYSLIPS_LOADING: {
              invoke: {
                src: "getSalarySlipsDataByEmployeeId",
                onDone: {
                  target: "SALARYSLIPS_LOADED",
                  actions: "assignContext",
                },
              },
            },
            SALARYSLIPS_LOADED: {
              on: {
                reload_salary_slip: "SALARYSLIPS_LOADING",
              },
            },
          },
        },

        COURSES: {
          initial: "WAIT_LOAD_COURSES",
          states: {
            WAIT_LOAD_COURSES: {
              on: { load_courses: "COURSES_LOADING" },
            },
            COURSES_LOADING: {
              invoke: {
                src: "getCoursesByUserId",
                onDone: {
                  target: "COURSES_LOADED",
                  actions: "assignContext",
                },
                onError: {
                  target: "COURSES_LOADED",
                },
              },
            },

            COURSES_LOADED: {
              on: {
                course_crud: {
                  target: "COURSE_CRUD",
                  actions: ["assignContext"],
                },
                reload_courses: "COURSES_LOADING",
                select_team_member_id_courses: {
                  target: "COURSES_LOADING",
                  actions: "assignContext",
                },
              },
            },

            COURSE_CRUD: {
              initial: "COURSE_ACTION",
              states: {
                COURSE_ACTION: {
                  on: {
                    create_course: {
                      target: "COURSE_CREATING",
                      actions: "assignContext",
                    },
                    update_course: {
                      target: "COURSE_UPDATING",
                      actions: "assignContext",
                    },
                    delete_course: {
                      target: "COURSE_DELETING",
                    },
                    cancel_crud: {
                      target: "#employeeMachine.COURSES.COURSES_LOADED",
                      actions: "assignSelectedCourseToNull",
                    },
                  },
                },

                COURSE_CREATING: {
                  invoke: {
                    src: "submitCourseData",
                    onDone: [
                      {
                        target: "#employeeMachine.COURSES.COURSES_LOADING",
                        actions: "assignGoadToBeAddedToNull",
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.COURSES.COURSES_LOADED",
                        actions: "assignContext",
                      },
                    ],
                  },
                },

                COURSE_UPDATING: {
                  invoke: {
                    src: "updateCourseData",
                    onDone: [
                      {
                        target: "#employeeMachine.COURSES.COURSES_LOADING",
                        actions: [
                          "assignCourseToBeAddedToNull",
                          "assignSelectedCourseToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.COURSES.COURSES_LOADED",
                        actions: [
                          "assignContext",
                          "assignCourseToBeAddedToNull",
                          "assignSelectedCourseToNull",
                        ],
                      },
                    ],
                  },
                },

                COURSE_DELETING: {
                  invoke: {
                    src: "deleteCourseData",
                    onDone: [
                      {
                        target: "#employeeMachine.COURSES.COURSES_LOADING",
                        actions: ["assignSelectedCourseToNull"],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.COURSES.COURSES_LOADED",
                        actions: [
                          "assignContext",
                          "assignSelectedCourseToNull",
                        ],
                      },
                    ],
                  },
                },
              },
            },
          },
        },
        PROJECTS: {
          initial: "PROJECTS_LOADING",
          states: {
            PROJECTS_LOADING: {
              invoke: {
                src: "getProjectDataByOrganizationId",
                onDone: {
                  target: "PROJECTS_LOADED",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "PROJECTS_LOADED",
                },
              },
            },
            PROJECTS_LOADED: {
              on: {
                project_crud: {
                  target: "PROJECT_CRUD",
                  actions: "assignContext",
                },
                load_employee_task: {
                  target: "PROJECT_CRUD",
                  actions: "assignContext",
                },
                //  reload_projects: "PROJECTS_LOADING",
                reload_projects: {
                  target: "PROJECTS_LOADING",
                  actions: "assignContext",
                },
              },
            },
            PROJECT_CRUD: {
              initial: "PROJECT_ACTION",
              states: {
                PROJECT_ACTION: {
                  on: {
                    assign_project: {
                      target: "ASSIGN_PROJECT",
                      actions: "assignContext",
                    },
                    cancel_crud: {
                      target: "#employeeMachine.PROJECTS.PROJECTS_LOADING",
                      actions: [
                        "assignSelectedProjectToNull",
                        "assignSelectedTaskToNull",
                      ],
                    },
                    select_project_id: {
                      target: "GET_TASK_BY_PROJECT_ID",
                      actions: "assignContext",
                    },
                  },
                },

                PROJECT_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.PROJECTS.PROJECT_CRUD.PROJECT_ACTION",
                      actions: "assignMessageToNull",
                    },
                  },
                },
                PROJECT_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.PROJECTS.PROJECTS_LOADED",
                      actions: "assignMessageToNull",
                    },
                  },
                },

                ASSIGN_PROJECT: {
                  invoke: {
                    src: "assignProjectToTeammember",
                    onDone: [
                      {
                        target: "#employeeMachine.PROJECTS.PROJECTS_LOADING",
                        actions: "assignSelectedProjectToNull",
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.PROJECTS.PROJECTS_LOADING",
                        actions: "assignSelectedProjectToNull",
                      },
                    ],
                  },
                },
                GET_TASK_BY_PROJECT_ID: {
                  invoke: {
                    src: "getEmployeeTasksByProjectsId",
                    onDone: [
                      {
                        target: "PROJECT_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignProjectToBeAddedToNull",
                          "assignSelectedProjectToNull",
                        ],
                      },
                    ],
                    onError: [
                      {
                        target: "PROJECT_CRUD_ERRORED",
                        actions: ["assignUpdateContext"],
                      },
                    ],
                  },
                },
              },
            },
            LOAD_TASKS: {
              invoke: {
                src: "getEmployeeTasksByProjectsId",
                onDone: [
                  {
                    target: "PROJECTS_LOADED",
                    actions: ["assignUpdateContext"],
                  },
                ],
                onError: [
                  {
                    target: "PROJECTS_LOADED",
                    actions: [
                      "assignUpdateContext",
                      // "assignSelectedProjectToNull",
                      // "assignProjectToBeAddedToNull",
                    ],
                  },
                ],
              },
            },
          },
        },
        PROJECTDASHBOARD: {
          initial: "PROJECTS_DASHBOARD_LOADING",
          states: {
            PROJECTS_DASHBOARD_LOADING: {
              on: {
                get_employee_task_hours: {
                  target: "PROJECTS_DASHBOARD_LOADED",
                  actions: ["assignContext", "assignEmployeeTaskHourToEmpty"],
                },
              },
            },
            PROJECTS_DASHBOARD_LOADED: {
              invoke: {
                src: "getProjectDashboardData",
                onDone: {
                  target: "PROJECTS_DASHBOARD_LOADING",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "PROJECTS_DASHBOARD_LOADING",
                },
              },
            },
          },
        },

        PROJECT_TASKS: {
          initial: "WAIT_FOR_PROJECT_ID",
          states: {
            WAIT_FOR_PROJECT_ID: {
              on: {
                get_project_id: {
                  target: "PROJECT_TASKS_LOADING",
                  actions: "assignContext",
                },
              },
            },
            PROJECT_TASKS_LOADING: {
              invoke: {
                src: "getTasksBySelectedProjectId",
                onDone: {
                  target: "WAIT_FOR_PROJECT_ID",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "WAIT_FOR_PROJECT_ID",
                },
              },
            },
            PROJECT_TASKS_LOADED: {
              initial: "TASK_ACTION",
              states: {
                TASK_ACTION: {},

                PROJECT_TASKS_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.PROJECT_TASKS.WAIT_FOR_PROJECT_ID",
                      actions: "assignMessageToNull",
                    },
                  },
                },
                PROJECT_TASKS_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target:
                        "#employeeMachine.PROJECT_TASKS.WAIT_FOR_PROJECT_ID",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
          },
        },
        BLOGS: {
          initial: "BLOGS_LOADING",
          states: {
            BLOGS_LOADING: {
              invoke: {
                src: "getBlogsDataByEmployeeId",
                onDone: {
                  target: "BLOG_CRUD",
                  actions: "assignUpdateContext",
                },
                onError: {
                  target: "BLOG_CRUD",
                },
              },
            },

            BLOGS_LOADED: {
              on: {
               
                create_blog: {
                  target: "BLOG_CREATING",
                  actions: "assignContext",
                },
                
                reload_blogs: "BLOGS_LOADING",
              },
            },
           
            BLOG_CREATING: {
              invoke: {
                src: "submitBlogData",
                onDone: {
                  target:  "BLOGS_LOADING",                              
                },
                onError: {
                  target:   "BLOGS_LOADING",
                },                   
              },
            },
            BLOG_CRUD: {
              initial: "BLOG_ACTION",
              states: {
                BLOG_ACTION: {
                  on: {
                    create_blog: {
                      target: "BLOG_CREATING",
                      actions: "assignContext",
                    },
                    update_blog: {
                      target: "BLOG_UPDATING",
                      actions: "assignContext",
                    },
                    delete_blog: {
                      target: "BLOG_DELETING",
                      actions: "assignContext",
                    },
                    assign_blog: {                      
                      actions: "assignContext",
                    },
                  cancle_blog_to_edit: {                      
                      actions: "assignSelectedBlogToBeToEditNull",
                    },
             
                  },
                },

                BLOG_CREATING: {
                  invoke: {
                    src: "submitBlogData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.BLOGS.BLOG_CRUD.BLOG_CRUD_SUCCEEDED",
                        actions: [
                          "assignBlogToBeAddedToNull",
                          "assignUpdateContext",
                          'assignContext'
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.BLOGS.BLOG_CRUD.BLOG_CRUD_ERRORED",
                        actions: ["assignUpdateContext",'assignContext'],
                      },
                    ],
                  },
                },

                BLOG_UPDATING: {
                  invoke: {
                    src: "updateBlogData",
                    onDone: [
                      {
                        target:
                          "#employeeMachine.BLOGS.BLOG_CRUD.BLOG_CRUD_SUCCEEDED",
                        actions: [
                          "assignUpdateContext",
                          "assignSelectedBlogToBeToEditNull",
                          "assignBlogToBeAddedToNull",               
                        ],
                      },
                    ],
                    onError: [
                      {
                        target:
                          "#employeeMachine.BLOGS.BLOG_CRUD.BLOG_CRUD_ERRORED",
                        actions: [
                          "assignUpdateContext",
                          'assignContext'
                        ],
                      },
                    ],
                  },
                },

                BLOG_DELETING: {
                  invoke: {
                    src: "deleteBlogData",
                    onDone: [
                      {
                        target: "#employeeMachine.BLOGS.BLOGS_LOADING",  
                        actions: ['assignContext', "assignUpdateContext",],
                      },
                    ],
                    onError: [
                      {
                        target: "#employeeMachine.BLOGS.BLOGS_LOADING",
                        actions: ['assignContext', "assignUpdateContext",],
                      },
                    ],
                  },
                },

                BLOG_CRUD_ERRORED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.BLOGS.BLOG_CRUD.BLOG_ACTION",
                      actions: "assignMessageToNull",
                    },
                  },
                },

                BLOG_CRUD_SUCCEEDED: {
                  on: {
                    ack: {
                      target: "#employeeMachine.BLOGS.BLOGS_LOADING",
                      actions: "assignMessageToNull",
                    },
                  },
                },
              },
            },
  
          },
        },
      },
    },
    {
      services: {
        loadDashbordData: (context) =>
          new Promise(async (resolve, reject) => {
            const { employeeProfile, userID, organizationID, organization } =
              context;
            let tasks = [];
            let goals = [];
            let leaves = [];
            let courses = [];
            log.debug("organizationSetting", organization?.attendanceFrom);
            tasks = await hookGetEmployeeTaskStatusByUserId(userID);
            courses = await hookGetEmployeeCourseByUserId(userID);

            log.debug("courses::getCoursesByUserIdService", courses);

            tasks =
              tasks?.filter(
                (task) =>
                  task.status === CONST_TASK_STATUS.taskStatusCreated ||
                  task.status === CONST_TASK_STATUS.taskStatusInprogress
              ) || [];

            goals = await hookListGoalsProgressByUserId(userID);
            goals =
              goals?.filter(
                (goal) =>
                  goal.status === CONST_GOAL_STATUS.goalStatusCreated ||
                  goal.status === CONST_GOAL_STATUS.goalStatusInprocess
              ) || [];
            leaves = await hookListLeaveStatusByUserId(userID);

            log.debug("Tasks, Goals, Leaves", tasks, goals, leaves);

            const { guideID } = employeeProfile;
            const teamMembers = await hookGetTeamShortProfilesByGuideID(
              guideID
            );
            const filteredTeamMembers = teamMembers?.filter((item) => {
              return (
                item.employeeStatus !== CONST_EMPLOYEE_STATUS.employeeTerminated
              );
            });
            // log.debug("teamMembers", teamMembers);
            // log.debug("filteredTeamMembers", filteredTeamMembers);

            var mycurrentWeekHours = [];
            var myLastWeekHours = [];
            var teamMemberCurrentWeekHours = [];

            if (organization?.attendanceFrom == "PROJECT_HOURS_AS_TIMESHEET") {
              const currentWeekDays = getWorkingDaysOfWeek("currentWeek");
              const lastWeekDays = getWorkingDaysOfWeek("lastWeek");

              const currentWeekDate =
                getFirstAndLastDateTimeOfWeekArray(currentWeekDays);
              const lastWeekDate =
                getFirstAndLastDateTimeOfWeekArray(lastWeekDays);

              const formattedCurrentWeekDays = (weekdays) => {
                const weekdate = weekdays?.map((dateString) =>
                  moment(dateString)?.format("YYYY-MM-DD")
                );
                return weekdate;
              };

              const logHourCurrentWeek = await hookGetTaskHoursByMonthAndUserId(
                {
                  firstDay: moment(currentWeekDate?.firstDay)?.format(
                    "YYYY-MM-DD"
                  ),
                  lastDay: moment(currentWeekDate?.lastDay)?.format(
                    "YYYY-MM-DD"
                  ),
                  userID: userID,
                }
              );

              const logHourLastWeek = await hookGetTaskHoursByMonthAndUserId({
                firstDay: moment(lastWeekDate?.firstDay)?.format("YYYY-MM-DD"),
                lastDay: moment(lastWeekDate?.lastDay)?.format("YYYY-MM-DD"),
                userID: userID,
              });

              function combineDateAndLogArrays(dateArray, logArray) {
                const logHourArray = [];
                const totalHoursMap = new Map();
                logArray?.forEach((log) => {
                  const logDate = log.date;
                  const logHours = log.totalHours || 0;
                  if (!totalHoursMap?.has(logDate)) {
                    totalHoursMap?.set(logDate, logHours);
                  } else {
                    totalHoursMap?.set(
                      logDate,
                      totalHoursMap?.get(logDate) + logHours
                    );
                  }
                });
                dateArray?.forEach((date) => {
                  const totalHours = totalHoursMap.get(date) || 0;
                  logHourArray?.push(totalHours);
                });

                return logHourArray;
              }

              var allCurrentWeekDay = formattedCurrentWeekDays(currentWeekDays);
              mycurrentWeekHours = combineDateAndLogArrays(
                allCurrentWeekDay,
                logHourCurrentWeek
              );

              var allLastWeekDay = formattedCurrentWeekDays(lastWeekDays);
              myLastWeekHours = combineDateAndLogArrays(
                allLastWeekDay,
                logHourLastWeek
              );

              const teamMemberCurrentWeekLogHoursAll = filteredTeamMembers?.map(
                (teamMember, index) =>
                  new Promise(async (resolve, reject) => {
                    const logHourWeekForPerson =
                      await hookGetTaskHoursByMonthAndUserId({
                        firstDay: moment(currentWeekDate?.firstDay)?.format(
                          "YYYY-MM-DD"
                        ),
                        lastDay: moment(currentWeekDate?.lastDay)?.format(
                          "YYYY-MM-DD"
                        ),
                        userID: teamMember?.cognitoId,
                      });
                    const logHour = combineDateAndLogArrays(
                      allCurrentWeekDay,
                      logHourWeekForPerson
                    );

                    resolve({ [`Person${index}`]: logHour });
                  })
              );

              teamMemberCurrentWeekHours = await Promise.all(
                teamMemberCurrentWeekLogHoursAll
              );

              log.debug(
                "InLoop",
                mycurrentWeekHours,
                myLastWeekHours,
                teamMemberCurrentWeekHours
              );
            } else if (
              organization?.attendanceFrom === "TIME_IN_TIME_OUT_AS_TIMESEET"
            ) {
              mycurrentWeekHours = await hookgetAttendanceHoursByUserId(
                "currentWeek",
                userID
              );
              myLastWeekHours = await hookgetAttendanceHoursByUserId(
                "lastWeek",
                userID
              );

              const teamMemberCurrentWeekHoursAll = filteredTeamMembers?.map(
                (teamMember, index) =>
                  new Promise(async (resolve, reject) => {
                    const personWorkHours =
                      await hookgetAttendanceHoursByUserId(
                        "currentWeek",
                        teamMember.cognitoId
                      );
                    resolve({ [`Person${index}`]: personWorkHours });
                  })
              );

              teamMemberCurrentWeekHours = await Promise.all(
                teamMemberCurrentWeekHoursAll
              );
              log.debug(
                "TIME_IN_TIME_OUT_AS_TIMESEET",
                mycurrentWeekHours,
                myLastWeekHours,
                teamMemberCurrentWeekHours
              );
            }

            log.debug(
              "teamMemberCurrentWeekLogHours",
              mycurrentWeekHours,
              myLastWeekHours,
              teamMemberCurrentWeekHours
            );
            const daysOfWeek = getWorkingDaysOfWeek("currentWeek");
            const shortDaysOfWeek = daysOfWeek?.map((day) =>
              moment(day).format("MM-DD-YYYY")
            );
            const teamMemberGoalsStatuses = await Promise.all(
              filteredTeamMembers?.map(
                (teamMember, index) =>
                  new Promise(async (resolve, reject) => {
                    let personGoals = await hookListGoalsProgressByUserId(
                      teamMember.cognitoId
                    );
                    let totalGoals = personGoals.length;
                    personGoals = personGoals?.filter(
                      (goal) =>
                        goal.status === CONST_GOAL_STATUS.goalStatusInprocess ||
                        goal.status === CONST_GOAL_STATUS.goalStatusCreated
                    );
                    let inProgressToDoGoals = personGoals.length;
                    let completedGoals = totalGoals - inProgressToDoGoals;
                    let goalOfOne = 0;
                    if (totalGoals > 0) {
                      goalOfOne = 100 / totalGoals;
                    }
                    let avgProgress = 0;
                    if (totalGoals !== 0) {
                      avgProgress = completedGoals * goalOfOne;
                    }
                    let isMe = teamMember.cognitoId === userID;
                    resolve({
                      [teamMember?.firstName + "  " + teamMember?.LastName]: {
                        inProgressToDoGoals,
                        totalGoals,
                        avgProgress: avgProgress.toFixed(2),
                        completedGoals,
                        isMe,
                      },
                    });
                  })
              )
            );

            const teamMemberTasksStatuses = await Promise.all(
              filteredTeamMembers?.map(
                (teamMember, index) =>
                  new Promise(async (resolve, reject) => {
                    let personTasks = await hookGetEmployeeTaskStatusByUserId(
                      teamMember.cognitoId
                    );
                    let totalTasks = personTasks?.length;
                    personTasks = personTasks?.filter(
                      (task) =>
                        task?.status === CONST_TASK_STATUS?.taskStatusCreated ||
                        task?.status === CONST_TASK_STATUS?.taskStatusInprogress
                    );
                    let inProgressToDoTasks = personTasks?.length;
                    let completedTasks = totalTasks - inProgressToDoTasks;
                    let TaskOfOne = 0;
                    if (totalTasks > 0) {
                      TaskOfOne = 100 / totalTasks;
                    }
                    let avgProgress = 0;
                    log.debug("avgProgress", avgProgress);
                    if (totalTasks !== 0) {
                      avgProgress = completedTasks * TaskOfOne;
                    }
                    let isMe = teamMember.cognitoId === userID;
                    log.debug("AverageProgress", avgProgress);

                    resolve({
                      [teamMember?.firstName + "  " + teamMember?.LastName]: {
                        totalTasks,
                        avgProgress: avgProgress.toFixed(2),
                        completedTasks,
                        inProgressToDoTasks,
                        isMe,
                      },
                    });
                  })
              )
            );

            let teamMemberCurrentWeekLeave =
              await hookListEmployeeLeavesOfCurrentWeek(organizationID);
            teamMemberCurrentWeekLeave = teamMemberCurrentWeekLeave.filter(
              (leave) => leave.status === CONST_LEAVE_STATUS.leaveStatusApproved
            );

            let totalHoursArr = [];
            for (let i = 0; i < teamMemberCurrentWeekHours.length; i++) {
              totalHoursArr = [
                ...totalHoursArr,
                ...Object.values(teamMemberCurrentWeekHours[i])[0],
              ];
            }
            function calculateAverage(arr, index) {
              log.debug("Calculate Avg", arr, index);
              if (index < 0 || index >= arr.length) {
                return null; // Invalid index, return null
              }

              let sum = 0;
              for (let i = 0; i <= index; i++) {
                sum += arr[i];
              }

              return sum / (index + 1);
            }

            function findMax(arr) {
              let maxElement = Number.NEGATIVE_INFINITY; // Initialize with negative infinity

              for (let i = 0; i < arr.length; i++) {
                if (arr[i] > maxElement) {
                  maxElement = arr[i];
                }
              }

              return maxElement;
            }
            const maxHours = findMax(totalHoursArr);
            let todayIndex = 4;
            const todayDate = moment(new Date()).format("MM-DD-YYYY");
            for (let i = 0; i < 5; i++) {
              if (shortDaysOfWeek[i] === todayDate) {
                todayIndex = i;
                break;
              }
            }

            const myLastWeekAvg = calculateAverage(
              myLastWeekHours,
              myLastWeekHours.length
            );
            const myCurrenWeekAvg = calculateAverage(
              mycurrentWeekHours,
              todayIndex - 1
            );

            const teamAvgHoursArr = teamMemberCurrentWeekHours.map(
              (teamHours) => {
                return todayIndex
                  ? calculateAverage(
                      Object.values(teamHours)[0],
                      todayIndex - 1
                    )
                  : 0;
              }
            );
            const teamAvgHours = calculateAverage(
              teamAvgHoursArr,
              teamAvgHoursArr.length - 1
            );
            resolve({
              dashboardData: {
                tasks: tasks?.slice(0, 4),
                goals: goals?.slice(0, 4),
                leaves: leaves?.slice(0, 4),
                courses: courses,
                todayAttendance: null,
                maxHours: maxHours,
                teamAvgHours: teamAvgHours?.toFixed(2),
                weekDays: shortDaysOfWeek, //length m
                myTimeSheetCurrentWeekLastWeek: {
                  lastWeek: myLastWeekHours, //length m
                  currentWeek: mycurrentWeekHours, //length m
                  myCurrenWeekAvg: myCurrenWeekAvg?.toFixed(2),
                  myLastWeekAvg: myLastWeekAvg?.toFixed(2),
                },
                teamTimesheetCurrentWeek: teamMemberCurrentWeekHours,
                teamMemberGoalsStatuses: teamMemberGoalsStatuses,
                teamMemberTasksStatuses: teamMemberTasksStatuses,
                teamMemberCurrentWeekLeave: teamMemberCurrentWeekLeave,
              },
            });
          }),
        getReimbursementDataByEmployeeId:
          getReimbursementDataByEmployeeIdService,
        submitReimbursementData: submitReimbursementDataService,
        updateReimbursementData: updateReimbursementDataService,
        deleteReimbursementData: deleteReimbursementDataService,

        getSalarySlipsDataByEmployeeId: (context) =>
          new Promise(async (resolve, reject) => {
            const { userID } = context;
            log.debug("calling:hookListReimbursementByUserId", userID);

            const salary_slips = await hookListSalarySlipByUserId(userID);
            //const reimbursements = await hookListReimbursements(userID);
            log.debug(
              "salary_slips:getSalarySlipsDataByEmployeeId",
              salary_slips
            );
            if (salary_slips) resolve({ salarySlips: salary_slips });
            reject("Failed To Fetch Tables");
          }),
        getCoursesByUserId: getCoursesByUserIdService,
        submitCourseData: submitCourseDataService,
        updateCourseData: updateCourseDataService,
        deleteCourseData: deleteCourseDataService,

        ListAttendanceData: ListAttendanceDataService,
        deleteAttendanceByAttendanceId: deleteAttendanceByAttendanceIdService,
        submitAttendanceData: submitAttendanceDataService,
        updateAttendance: updateAttendanceService,

        submitInventoryData: submitInventoryDataService,
        updateInventoryData: updateInventoryDataService,
        deleteInventoryData: deleteInventoryDataService,
        getInventoryDataByEmployeeId: getInventoryDataByEmployeeIdService,

        getMyEmployeeProfile: getMyEmployeeProfileService,
        getEmployeePersonalDetail: getEmployeePersonalDetailService,
        updateEmployeeProfile: updateEmployeeProfileService,

        getLeaveDataByEmployeeId: getLeaveDataByEmployeeIdService,
        updateLeaveData: updateLeaveDataService,
        submitLeaveData: submitLeaveDataService,
        submitMultipleLeaveData: submitMultipleLeaveDataService,
        deleteLeaveData: deleteLeaveDataService,
        actionOnMultipleLeaveData: actionOnMultipleLeaveDataService,

        getCoffDataByEmployeeId: getCoffDataByEmployeeIdService,
        updateCoffData: updateCoffDataService,
        submitCoffData: submitCoffDataService,
        submitMultipleCoffData: submitMultipleCoffDataService,
        deleteCoffData: deleteCoffDataService,
        actionOnMultipleCoffData: actionOnMultipleCoffDataService,

        getGoalDataByEmployeeId: getGoalDataByEmployeeIdService,
        submitGoalData: submitGoalDataService,
        updateGoalData: updateGoalDataService,
        deleteGoalData: deleteGoalDataService,

        getTaskDataByEmployeeId: getTaskDataByEmployeeIdService,
        submitTaskData: submitTaskDataService,
        updateTaskData: updateTaskDataService,
        deleteTaskData: deleteTaskDataService,
        getTasksBySelectedProjectId: getTaskDataBySelectedProjectId,

        submitTaskHoursData: submitTaskHoursDataService,
        updateTaskHoursData: updateTaskHoursDataService,
        getProjectDataByOrganizationId: getProjectDataByOrganizationIdService,
        assignProjectToTeammember: assignProjectToTeammemberService,
        getProjectDashboardData: getEmployeeTasksHoursByMonth,
        updateMultipleTaskHoursData: updateMultipleTaskHoursDataService,
        getTaskHoursByTaskId: getTaskHoursByTaskIdService,
        deleteTaskHoursData: deleteTaskHoursDataService,
        getTaskHoursByTaskIdAndDate: getTaskHoursByTaskIdAndDateService,
        getemployeeLogHoursData: getEmployeeLogHoursService,
        getEmployeeLogHoursByTaskID:
          getEmployeeTaskHoursByTaskIdForSelectedDatesService,
        submitLogHoursData: submitWeeklyTaskHoursDataService,
        updateLogHoursData: updateWeeklyTaskHoursDataService,
        deleteLogHourData: deleteWeeklyTaskHoursDataService,
        approveAndRejectLogHours: approveAndRejectLogHoursDataService,
        getHierarchyData: getHierarchyDataByEmployeeService,
        getTeamLeadEmployeeList: getTeamLeadEmployeeListService,
        getBlogsDataByEmployeeId: getBlogsByEmployeeIdService,
        submitBlogData: createBlogService,
        updateBlogData: updateBlogService,
        deleteBlogData: deleteBlogService,
      },
      actions: {
        addLocalTaskHourObj: assign((context, event) => {
          if (event?.data) {
            const {
              selectedProjectId,
              projectTasks,
              projects,
              taskHourByMonth,
              employeeWeeklyTaskHours,
            } = context;
            const { selectedTaskId } = event?.data;
            let selectedProject = projects?.find(
              (item) => item.id === selectedProjectId
            );
            let selectedTask = projectTasks?.find(
              (item) => item.id === selectedTaskId
            );
            if (!taskHourByMonth) {
              return {};
            }
            let weekDays = initializeWeekdays(
              taskHourByMonth?.firstDay,
              taskHourByMonth?.lastDay
            );

            log.debug(
              "weekDays::addLocalTaskHourObj",
              weekDays,
              employeeWeeklyTaskHours
            );
            if (!selectedProject) {
              return {};
            }

            let objectToReturn = {
              ...weekDays,
              ...selectedTask,
              projectTitle: selectedProject?.title,
              taskID: selectedTask?.id,
              taskTitle: selectedTask?.title,
              unbillableHours: selectedTask?.unbillableHours,
              billableHours: selectedTask?.billableHours,
              billedHours: selectedTask?.billedHours,
              billedUnbillableHours: selectedTask?.billedUnbillableHours,
              LoggedHours:
                selectedTask?.billedHours + selectedTask?.billedUnbillableHours,
              logHours: 0,
              projectID: selectedProjectId,
              leaveDate: employeeWeeklyTaskHours[0]?.leaveDate,
              rowLogHour: [],
            };

            return {
              employeeWeeklyTaskHours: [
                objectToReturn,
                ...employeeWeeklyTaskHours,
              ],
            };
          }
        }),
        assignContext: assignContext,
        assignUpdateContext: assignUpdateContext,
        assignInventoryToBeAddedToNull: assign({
          reimbursementsToAdd: null,
        }),

        assignSelectedReimbursementToNull: assign({
          reimbursementsToAdd: null,
          selectedReimbursement: null,
          selectedReimbursementId: undefined,
          actionForReimbursementCRUD: "",
        }),

        assignCourseToBeAddedToNull: assign({
          courseToBeAdded: null,
        }),

        assignSelectedCourseToNull: assign({
          selectedCourseId: undefined,
          selectedCourse: null,
          actionForCourseCRUD: "",
        }),

        assignSelectedInventoryToNull: assign({
          inventoryToAdd: null,
          selectedInventory: null,
          selectedInventoryId: undefined,
          actionForInventoryCRUD: "",
        }),

        assignSelectedLeaveToNull: assign({
          leavesToAdd: null,
          selectedLeaveId: undefined,
          selectedLeave: null,
          actionForleaveCRUD: "",
        }),

        assignSelectedCoffToNull: assign({
          coffsToAdd: null,
          selectedCoffId: undefined,
          selectedCoff: null,
          actionForCoffCRUD: "",
        }),

        assignLeaveToBeAddedToNull: assign({
          leavesToAdd: null,
        }),

        assignCoffToBeAddedToNull: assign({
          coffsToAdd: null,
        }),

        assignGoalToBeAddedToNull: assign({
          goalToBeAdded: null,
        }),

        assignSelectedGoalToNull: assign({
          selectedGoalId: undefined,
          selectedGoal: null,
          actionForGoalCRUD: "",
        }),
        assignSelectedBlogToNull: assign({
          selectedBlogId: undefined,
          selectedBlog: null,
          actionForBlogCRUD: "",
        }),

        assignTaskToBeAddedToNull: assign({
          taskToBeAdded: null,
        }),

        assignSelectedTaskToNull: assign({
          selectedTaskId: null,
          selectedTask: null,
          actionForTaskCRUD: "",
          employeeTaskHoursDetils: null,
          actionForTaskHoursCURD: null,
        }),

        assignReimbursementToBeAddedToNull: assign({
          actionForReimbursementCRUD: "",
          selectedReimbursement: null,
          selectedReimbursementId: null,
        }),

        assignMessageToNull: assign({
          message: null,
        }),

        assignSelectedTaskHourToNull: assign({
          selectedTaskHourId: null,
          selectedTaskHour: null,
          actionForTaskCRUD: "",
          logHoursArrayToAdd: [],
          loghoursDataTobeAdded: null,
          actionForTaskHoursCURD: null,
          employeeTaskHours: [],
        }),
        assignEmployeeTaskHourToEmpty: assign({
          employeeTaskHours: [],
        }),

        assignMultipleLoghoursToContext: assign({
          logHoursArrayToAdd: (context, event) => {
            log.debug("assignMultipleLoghoursToContext:", event.data);
            return event.data.logHoursArrayToAdd;
          },
        }),
        assignTaskHoursToNull: assign({
          taskHours: [],
        }),
        assignLogHoursDateToNull: assign({
          logHourDate: null,
          employeeTaskHours: [],
        }),
        assignEmployeeWeeklyTaskHourToEmpty: assign({
          employeeWeeklyTaskHours: [],
        }),
        assignBlogToBeAddedToNull: assign({
          blogToAdd: null,
        }),
   
        assignSelectedBlogToBeToEditNull: assign({
          selectedBlogToEdit: null,
        }),
      },
    }
  );
