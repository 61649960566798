import React, { useEffect, useState } from "react";
import { DataTable } from "../../../components/CustomComponentsLibrary/data-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageHeader from "../../common/PageHeader";
import { useSelector } from "@xstate/react";
import FormatDateAndTime from "../../common/FormatDateAndTime";
import { DataTableColumnHeader } from "../../CustomComponentsLibrary/data-table-column-header";
import log from "loglevel";
import moment from "moment";
import * as XLSX from "xlsx";
import { Checkbox } from "../../CustomComponentsLibrary/Checkbox";
import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import { getFirstAndLastDateTimeOfMonth } from "../../../lib/dateService";
import { Button } from "../../CustomComponentsLibrary/Button";
import { Upload } from "lucide-react";
import { Calendar } from "lucide-react";
import { useParams } from "react-router";
import { TruncateDescription } from "../../common/TruncateDescription";
import { useAuthContextData } from "../../../customHooks/authMachineHooks";

const History = () => {
  const { userID, logHourHistory } = useEmployeeContextData();
  const { theme } = useAuthContextData();
  const employeeMachineService = useEmployeeContextService();
  const params = useParams();
  const [rowSelected, setRowSelected] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const istaskLoading = useSelector(employeeMachineService, (state) =>
    state.matches("PROJECTDASHBOARD.PROJECTS_DASHBOARD_LOADED")
  );

  log.debug("History::currentUserID param", params);

  log.debug("istaskLoading", istaskLoading);
  const getLogHoursBySelectedMonth = async (selectedDate) => {
    const selectedMonth = moment(selectedDate).format("YYYY-MM");
    const { firstDayOfMonth, lastDayOfMonth } =
      getFirstAndLastDateTimeOfMonth(selectedMonth);
    const firstDay = moment(firstDayOfMonth)?.format("YYYY-MM-DD");
    const lastDay = moment(lastDayOfMonth)?.format("YYYY-MM-DD");
    const dataToSend = {
      firstDay: firstDay,
      lastDay: lastDay,
      userID: params?.currentUserId ? params?.currentUserId : userID,
    };
    log.debug(
      "dataToSend:get_employee_task_hours",
      dataToSend,
      params?.currentUserId
    );
    employeeMachineService.send({
      type: "get_employee_task_hours",
      data: {
        taskHourByMonth: dataToSend,
      },
    });

    log.debug("logHourHistory", logHourHistory);
  };

  useEffect(() => {
    getLogHoursBySelectedMonth(moment().format("YYYY-MM-DD"));
  }, []);
  const handleRowClick = (filteredallProjects) => {
    if (filteredallProjects) {
      const xlsData = filteredallProjects.map((row) => ({
        ProjectTitle: row?.projectTitle,
        TaskTitle: row?.taskTitle,
        Date: moment(row?.date)?.format("DD-MM-YYYY, dddd"),
        TotalHours: row?.totalHours,
        Description: row?.description,
        LoggedBy: row?.userName,
      }));

      const xlsWorksheet = XLSX.utils.json_to_sheet(xlsData);
      const xlsWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(xlsWorkbook, xlsWorksheet, "SelectedRows");

      XLSX.writeFile(xlsWorkbook, "selected_rows.xlsx");
    }

    log.debug("handleRowClick::", rowSelected);
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            log.debug("selecetd Tixck", value, row, row.getIsSelected());
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      header: "Project Name",
      accessorKey: "projectTitle",
      cell: ({ row }) => (
        <>
          <div>{row.original?.projectTitle}</div>
        </>
      ),
      filterFn: (row, id, value) => {
        return value instanceof Array && value.includes(row.getValue(id));
      },
    },
    {
      header: "Task Name",
      accessorKey: "taskTitle",
      cell: ({ row }) => (
        <>
          <div>{row.original?.taskTitle}</div>
        </>
      ),
    },

    {
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Date" />
      ),
      accessorKey: "date",
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row.original?.date}
          format={"DD/MM/YYYY"}
        />
      ),
    },

    {
      header: "Total Hours",
      accessorKey: "totalHours",
      cell: ({ row }) => (
        <>
          <div>{row.original?.totalHours}</div>
        </>
      ),
    },

    {
      header: "Description",
      accessorKey: "description",
      cell: ({ row }) => (
        <TruncateDescription
          content={row.original?.description}
          uptoChars={90}
        />
      ),
    },
  ];

  const labelsArray = logHourHistory?.map((item) => ({
    value: item.projectTitle,
    label: item.projectTitle,
  }));

  const labels = labelsArray?.reduce((accumulator, current) => {
    const existingItem = accumulator?.find(
      (item) => item.value === current.value
    );
    if (!existingItem) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
  console.log("projectsDataHistory", labels);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="m-3 w-full">
          <PageHeader
            title={"History"}
            SubHeading="View and edit history of your log hours"
          />
        </div>
      </div>
      <div className="p-2 mx-auto">
        <div className="flex justify-start w-full">
          <div className="w-full flex  font-semibold text-2xl flex-row">
            <div className="w-64  flex font-semibold text-2xl flex-col">
              <div className="text-base font-semibold">
                <p className="flex flex-1 pt-5 pb-2">
                  <span>Select Month</span>
                </p>
              </div>

              <div className="w-full flex  text-base sm:w-96 z-20">
                <DatePicker
                  wrapperClassName="custom-datepicker"
                  selected={startDate}
                  onChange={(selectedDate) => {
                    setStartDate(selectedDate);
                    getLogHoursBySelectedMonth(selectedDate);
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="cursor-pointer h-12 p-3  border w-full rounded-md date-input"
                  placeholderText="Select month"
                />
                <Calendar className="relative right-10 m-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-5 m-2 border-t border-t-zinc-100 rounded-md">
        <div className=" flex flex-row justify-end mt-5 mr-5">
          <Button
            className={` ${theme?.hoverbg} ${theme?.hoverText} ${theme?.border}`}
            size="sm"
            onClick={() => {
              const selectedKeys = Object.keys(rowSelected);
              const filteredHours = logHourHistory?.filter((item, index) => {
                log.debug("Task, index", item, index);
                return selectedKeys?.includes(index.toString()) ? true : false;
              });
              if (filteredHours) {
                handleRowClick(filteredHours, selectedKeys);
              }
              log.debug("filteredHours", filteredHours, selectedKeys);
              setRowSelected({});
            }}
          >
            <Upload className="h-4 w-4 " /> Export Selected Row
          </Button>
        </div>
        <div className="ml-5 mr-5 mt-2 pb-5">
          <DataTable
            columns={columns}
            data={logHourHistory}
            loader={istaskLoading}
            setRowSelected={setRowSelected}
            rowSelected={rowSelected}
            filterableColumns={[
              {
                id: "projectTitle",
                title: "Filter by project",
                options: labels?.map(({ value, label }) => ({
                  label: label,
                  value: value,
                })),
              },
            ]}
            customCss={`${theme?.border}`}
            customWidth={[
              {
                index: 0,
                widthClass: "w-20",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 1,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 2,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 3,
                widthClass: "w-40",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 4,
                widthClass: "w-28",
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
              {
                index: 5,
                cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default History;
