/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEmployeeLeavesData = /* GraphQL */ `
  query GetEmployee($cognitoId: ID!) {
    getEmployee(cognitoId: $cognitoId) {
      cognitoId
      email
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      noOfCOffLeaves
      noOfCOffLeavesTaken
      noOfCOffLeavesRemaining
    }
  }
`;

export const getGuideDetails = /* GraphQL */ `
  query GetEmployee($cognitoId: ID!) {
    getEmployee(cognitoId: $cognitoId) {
      cognitoId
      firstName
      middleName
      LastName
      email
      contactNo
      employeeStatus
      userType
    }
  }
`;

export const goalsProgressByUserID = /* GraphQL */ `
  query GoalsByUserID(
    $userID: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GoalsByUserID(
      userID: $userID
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedBy
        title
        dueDate
        percentage
        status
      }
    }
  }
`;

export const leavesStatusByUserID = /* GraphQL */ `
  query LeavesByUserID(
    $userID: ID!
    $date2: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LeavesByUserID(
      userID: $userID
      date2: $date2
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        typeOfLeave
        title
        status
      }
      nextToken
    }
  }
`;

export const coffsStatusByUserID = /* GraphQL */ `
  query CoffsByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoffRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CoffsByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        appliedBy
        approvedBy
        approvedByName
        date
        details
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;



export const tasksProgressByUserID = /* GraphQL */ `
  query TasksByUserID(
    $userID: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TasksByUserID(
      userID: $userID
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        dueDate
        status
        percentage
      }
      nextToken
    }
  }
`;

export const attendanceHoursByUserID = /* GraphQL */ `
  query AttendanceByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalHours
        timeIn1
      }
      nextToken
    }
  }
`;

export const teamShortProfilesByGuideID = /* GraphQL */ `
  query EmployeesByGuideID(
    $guideID: ID!
    $dateOfHire: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeesByGuideID(
      guideID: $guideID
      dateOfHire: $dateOfHire
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        firstName
        LastName
        email
        designation
        employeeStatus
      }
      nextToken
    }
  }
`;

export const getBirthdayMetricsByOrganizationID = /* GraphQL */ `
  query EmployeesByOrganizationID(
    $organizationID: ID!
    $dateOfHire: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeesByOrganizationID(
      organizationID: $organizationID
      dateOfHire: $dateOfHire
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        firstName
        LastName
        email
        dateOfBirth
        employeeStatus
      }
      nextToken
    }
  }
`;

export const employeesShortProfilesByOrganizationID = /* GraphQL */ `
  query EmployeesByOrganizationID(
    $organizationID: ID!
    $dateOfHire: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmployeesByOrganizationID(
      organizationID: $organizationID
      dateOfHire: $dateOfHire
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        firstName
        LastName
        email
        userType
        contactNo
        workLocation
        designation
        employeeID
        organizationID
      }
    }
  }
`;

export const teramLeavesByOrganizationID = /* GraphQL */ `
  query LeavesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LeavesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        appliedBy
        date
        status
        userID
        organizationID
      }
      nextToken
    }
  }
`;

export const teramCoffsByOrganizationID = /* GraphQL */ `
query CoffsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCoffRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CoffsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        appliedBy
        approvedBy
        approvedByName
        date
        details
        status
        userID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getEmployeeCustom = /* GraphQL */ `
  query GetEmployee($cognitoId: ID!) {
    getEmployee(cognitoId: $cognitoId) {
      cognitoId
      firstName
      middleName
      LastName
      email
      personalEmail
      userType
      profilePicture
      uniqueIdentification
      PAN
      dateOfBirth
      dateOfHire
      yearlyWage
      contactNo
      workLocation
      workHoursPerWeek
      emergencyContact1
      emergencyContact1Name
      emergencyContact1Relation
      emergencyContact2
      emergencyContact2Name
      emergencyContact2Relation
      permanentAddress
      currentAddress
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      currentCompensationID
      accountDetails
      employeeStatus
      designation
      employeeID
      dateOjJoining
      location
      department
      guideID
      organizationID
      skillSets
      createdAt
      updatedAt
    }
  }
`;

export const salarySlipsByUserIDCustom = /* GraphQL */ `
  query SalarySlipsByUserID(
    $userID: ID!
    $month: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalarySlipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SalarySlipsByUserID(
      userID: $userID
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        salarySlipID
        userID
        s3key
        designation
        employeeID
        month
        dateOfJoining
        accountDetails
        location
        workedDays
        monthWorkingDays
        leavesWithPay
        leavesWithoutPay
        department
        name
        netSalary
        earningBasic
        earningHRA
        earningAllowance
        earningGross
        earningBonus
        deductionPF
        deductionInsurance
        deductionProfTax
        taxDeduction
        paymentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEmployeeFirstName = /* GraphQL */ `
  query GetEmployee($cognitoId: ID!) {
    getEmployee(cognitoId: $cognitoId) {
      firstName
      LastName
    }
  }
`;
export const getTaskNameByTaskId = /* GraphQL */ `
  query GetEmployeeTask($id: ID!) {
    getEmployeeTask(id: $id) {
      title
    }
  }
`;
export const getProjectNameByProjectId = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      title
    }
  }
`;

export const projectsBySelectedDateRangeOrganizationID = /* GraphQL */ `
  query ProjectsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $startDate: String
    $endDate: String
    $limit: Int
    $nextToken: String
  ) {
    ProjectsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: {
        and: [
          { startDate: { ge: $startDate } }
          { startDate: { le: $endDate } }
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        assignedTo
        assignedBy
        title
        content
        dueDate
        status
        actualCompletedDate
        domain
        leadName
        teamSize
        plantForm
        customerName
        startDate
        completionDate
        poStatus
        invoiceStatus
        remark
        organizationID
        teammembers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeeTasksHoursByProjectID = /* GraphQL */ `
  query EmployeeTasksHoursByProjectID(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $startDate: String
    $endDate: String
    $limit: Int
    $nextToken: String
  ) {
    EmployeeTasksHoursByProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: {
        and: [{ date: { ge: $startDate } }, { date: { le: $endDate } }]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const employeeTasksHoursByUserID = /* GraphQL */ `
  query EmployeeTasksHoursByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $startDate: String
    $endDate: String
    $limit: Int
    $nextToken: String
  ) {
    EmployeeTasksHoursByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: {
        and: [{ date: { ge: $startDate } }, { date: { le: $endDate } }]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        startTime
        endTime
        date
        description
        totalHours
        isApproved
        taskID
        projectID
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const employeeLeavesByYearAndUserID = /* GraphQL */ `
  query LeavesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $startDate: String
    $endDate: String
    $limit: Int
    $nextToken: String
  ) {
    LeavesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: {
        and: [{ date2: { ge: $startDate } }, { date2: { le: $endDate } }]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeOfLeave
        quantity
        appliedBy
        approvedBy
        approvedByName
        date2
        date
        title
        details
        dateTime
        status
        userID
        organizationID
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEmployeeLeaveHistoryByUserIdAndYear = /* GraphQL */ `
  query EmployeeLeaveHistoryByUserID(
    $userID: ID!
    $year: String!
    $nextToken: String
  ) {
    EmployeeLeaveHistoryByUserID(
      userID: $userID
      year: { eq: $year }
      nextToken: $nextToken
    ) {
      items {
        userID
        year
        noOfLeaves
        noOfLeavesTaken
        noOfLeavesRemaining
        noOfMedicalLeaves
        noOfMedicalLeavesTaken
        noOfMedicalLeavesRemaining
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployeeLeavesOverviewByUserID = /* GraphQL */ `
  query GetEmployee($cognitoId: ID!) {
    getEmployee(cognitoId: $cognitoId) {
      cognitoId
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      noOfCOffLeaves
      noOfCOffLeavesTaken
      noOfCOffLeavesRemaining
      createdAt
      updatedAt
    }
  }
`;
