import {
  hookUpdateCourse,
  hookAddCourse,
  hookGetCourseById,
  hookDeleteCourseById,
} from "../../hooks/coursehooks";
import log from "loglevel";
import {
  hookGetEmployeeCourseByUserId,
  hookUpdateEmployeeCourse,
} from "../../hooks/employeeCourseHooks";

export const getCoursesByUserIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userProfile } = context;
    log.debug("userProfile::getCoursesByUserIdService", userProfile);
    if (userProfile) {
      const courses = await hookGetEmployeeCourseByUserId(
        userProfile?.cognitoId
      );
      log.debug("courses::getCoursesByUserIdService", courses);

      if (courses) {
        const trainings = courses?.map(async (course) => {
          const courseById = await hookGetCourseById(course?.courseID);
          return { ...courseById, ...course };
        });

        const res = await Promise.all(trainings);
        log.debug("trainings::getCoursesByUserIdService", res);

        resolve({ courses: res });
      }
    }

    reject();
  });

export const submitCourseDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { courseToBeAdded } = context;

    log.debug("courseToBeAdded:submitCourseData", courseToBeAdded);
    const courses = await hookAddCourse({
      ...courseToBeAdded,
      active: true,
    });
    if (courses) resolve(courses);
    reject("Failed To Fetch Tables");
  });

export const updateCourseDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { courseToBeAdded } = context;
    log.debug("courseToBeAdded", courseToBeAdded);
    const res = await hookUpdateEmployeeCourse({
      ...courseToBeAdded,
    });

    if (res) resolve(res);
    reject("Failed To Update Table");
  });
export const deleteCourseDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedCourseId } = context;
    log.debug("deleteCourseData====>", selectedCourseId);
    const courseDeleted = await hookDeleteCourseById(selectedCourseId);
    log.debug("courseDeleted====>", courseDeleted);
    if (courseDeleted) resolve(true);
    reject("Failed To Delete Course");
  });
