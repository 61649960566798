import React, { useState } from "react";
import {
  useAuthContextData,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import log from "loglevel";

export default function WelcomeComponentOrg({ orgDetail }) {
  log.debug("orgDetail", orgDetail);
  const [isCandidateForValidOrg, setIsCandidateForValidOrg] = useState(
    orgDetail?.name ? true : false
  );

  const authService = useAuthService();
  const { theme } = useAuthContextData();

  return (
    <>
      <div className="mx-auto flex h-full w-full max-w-7xl flex-grow flex-col justify-end px-4 sm:px-6 md:justify-center lg:px-8">
        <div className="justify-left w-max rounded-full bg-white p-1 md:flex md:w-full md:justify-center">
          {/* <LogoName /> */}
          {/* <Logo className="h-10 w-auto" /> */}
        </div>
        <div className="ml-3 text-left md:place-items-center md:text-center">
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-delightmenuRichBlack sm:text-5xl">
            Welcome to<br></br> {orgDetail.name}
          </h1>
          <p className="invisible mt-2 text-base text-delightmenuRichBlack md:visible">
            Please continue with Sign Up if not already done!
          </p>
          <div className="mt-12 flex flex-col place-content-center gap-3 md:mt-6 md:flex-row">
            <button
              className={`"w-full items-center  px-4 py-3 text-center text-base
              font-medium  md:w-32 md:max-w-sm md:py-1" ${theme?.background} ${theme?.textColor}`}
              onClick={() => {
                authService.send({
                  type: "signInOptions",
                });
              }}
            >
              Sign In
            </button>
            <button
              className={`"w-full items-center  px-4 py-3 text-center text-base
              font-medium  md:w-32 md:max-w-sm md:py-1" ${theme?.background} ${theme?.textColor}`}
              onClick={() => {
                authService.send({
                  type: "signUpOptions",
                  data: isCandidateForValidOrg,
                });
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
