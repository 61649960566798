import React, { useState, Fragment, useEffect, useMemo } from "react";
import log from "loglevel";
import {
  useEmployeeContextData,
  useEmployeeContextState,
  useEmployeeContextService,
} from "../../../../hooks/employeeMachineHooks";
import { Dialog, Transition } from "@headlessui/react";
import useForm from "../../../../customHooks/useForm";
import Dropdown from "../../../common/Dropdown";
import {
  CONST_TYPE_OF_LEAVE,
  CONST_LEAVE_STATUS,
} from "../../../../constants/dbconstants";
import moment from "moment";
import LeaveComments from "../../../common/LeaveComments";
import RadioGroupMulti from "../../../common/RadioGroupMulti";
import {
  DialogBox,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../CustomComponentsLibrary/Dailog";
import { Input } from "../../../CustomComponentsLibrary/Input";
import { Button } from "../../../CustomComponentsLibrary/Button";
import { Label } from "../../../CustomComponentsLibrary/Label";
import { Textarea } from "../../../CustomComponentsLibrary/Textarea";
import { ScrollArea } from "../../../CustomComponentsLibrary/ScrollArea";
import { Trash, X } from "lucide-react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "../../../CustomComponentsLibrary/UseToast";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
import { hookListLeavesByUserId } from "../../../../hooks/leaveHooks";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import { sortByKeyAlphabetical } from "../../../common/SortByName";

export default function AddEditLeaves() {
  const { leavesCrud } = useEmployeeContextState();
  const employeeMachineService = useEmployeeContextService();
  const { organization, theme } = useAuthContextData();
  const [leaveData, setLeaveData] = useState([]);
  const [items, setItems] = useState([]);
  log.debug("leavesCrud", leavesCrud);
  const { actionForLeaveCRUD, selectedLeave, userID, leavesOverview } =
    useEmployeeContextData();
  log.debug(
    "actionForLeaveCRUD, selectedLeave",
    actionForLeaveCRUD,
    selectedLeave,
    leavesOverview
  );

  const LeaveOptions = [
    {
      name: "Full Day",
      value: 1.0,
    },
    {
      name: "Half Day",
      value: 0.5,
    },
  ];
  const { inputs, handleChange, clearForm, resetForm } = useForm({
    // id: selectedLeave ? selectedLeave.id : "",

    typeOfLeave: selectedLeave ? selectedLeave.typeOfLeave : "",
    appliedBy: selectedLeave ? selectedLeave.appliedBy : "",
    date: selectedLeave ? selectedLeave.date : "",
    title: selectedLeave ? selectedLeave.title : "",
    details: selectedLeave ? selectedLeave.details : "",
    dateTime: selectedLeave ? selectedLeave.dateTime : "",
    status: selectedLeave ? selectedLeave.status : "",
  });

  const [selectedLeaveQuantity, handleSelectedLeaveQuantity] = useState(1.0);
  const [dialogOpen] = useState(leavesCrud === true ? true : false);
  const handleClose = () => {
    employeeMachineService.send({ type: "cancel_crud" });
  };
  useEffect(() => {
    const LeaveData = async () => {
      const leavesOfSelectedPerson = await hookListLeavesByUserId(userID);
      setLeaveData(leavesOfSelectedPerson);
    };
    LeaveData();
  }, [userID]);
  log.debug("LeaveData_for", leaveData);

  useEffect(() => {
    log.debug("selectedLeaveQuantity : ", selectedLeaveQuantity);
  }, [selectedLeaveQuantity]);

  const allLeaveTypes = [
    { name: CONST_TYPE_OF_LEAVE.leaveAnnual, label: "ANNUAL LEAVE" },
    { name: CONST_TYPE_OF_LEAVE.leaveUnpaid, label: "UNPAID LEAVE" },
    { name: CONST_TYPE_OF_LEAVE.leaveSick, label: "SICK LEAVE" },
    { name: CONST_TYPE_OF_LEAVE.leaveMaternity, label: "MATERNITY LEAVE" },
    { name: CONST_TYPE_OF_LEAVE.leaveParternity, label: "PATERNITY LEAVE" },
    // { name: CONST_TYPE_OF_LEAVE.timeOf, label: "C-OFF LEAVE" },
  ];
  const coffLeaveTypes = [
    { name: CONST_TYPE_OF_LEAVE.timeOf, label: "C-OFF LEAVE" },
  ];

  const { hasAnnualLeaves, hasSickLeaves, hasCoffLeaves } = useMemo(() => {
    let annualLeavesLeft = true,
      sickLeavesLeft = true,
      hasCoffLeaves = true;
    annualLeavesLeft = leavesOverview?.noOfLeavesRemaining > 0;
    sickLeavesLeft = leavesOverview?.noOfMedicalLeavesRemaining > 0;
    hasCoffLeaves = leavesOverview?.noOfCOffLeavesRemaining > 0;
    return {
      hasAnnualLeaves: annualLeavesLeft,
      hasSickLeaves: sickLeavesLeft,
      hasCoffLeaves: hasCoffLeaves,
    };
  }, [leavesOverview]);

  const leaveStatus = [
    { name: CONST_LEAVE_STATUS.leaveStatusSubmitted },
    { name: CONST_LEAVE_STATUS.leaveStatusRejected },
    { name: CONST_LEAVE_STATUS.leaveStatusApproved },
    { name: CONST_LEAVE_STATUS.leaveStatusInprogress },
    { name: CONST_LEAVE_STATUS.leaveStatusOnhold },
  ];
  const [status, setStatus] = useState(
    leaveStatus.find((element) => element.name === selectedLeave?.status) ||
      null
  );
  const [selectedTypeOfLeave, setSelectedTypeOfLeave] = useState(
    allLeaveTypes.find(
      (element) => element.name === selectedLeave?.typeOfLeave
    ) || null
  );
  useEffect(() => {
    dateSelector();
  }, [inputs?.date]);

  function showLimitExceededToast() {
    toast({
      variant: "destructive",
      title:
        "This Leave selection having 0  leave balance, Apply for correct leave type ",
    });
  }

  log.debug("Type of leave ", hasAnnualLeaves, hasCoffLeaves, hasSickLeaves);
  const dateSelector = () => {
    let filterLevesByDate;

    if (leaveData) {
      filterLevesByDate = leaveData.filter((leave) =>
        leave?.date.includes(inputs?.date)
      );
    }

    if (filterLevesByDate?.length > 0) {
      return toast({
        variant: "destructive",
        title: "Leave Exsits For the Selected Date",
      });
    }
  };
  const selectType = () => {
    toast({
      variant: "destructive",
      title: "select leaveType first ",
    });
  };
  useEffect(() => {
    if (actionForLeaveCRUD !== "EDIT") {
      if (selectedTypeOfLeave) {
        if (
          selectedTypeOfLeave?.name === CONST_TYPE_OF_LEAVE?.leaveAnnual &&
          hasAnnualLeaves === false
        ) {
          showLimitExceededToast();
        } else if (
          selectedTypeOfLeave?.name === CONST_TYPE_OF_LEAVE?.leaveSick &&
          hasSickLeaves === false
        ) {
          showLimitExceededToast();
        } else if (
          selectedTypeOfLeave?.name === CONST_TYPE_OF_LEAVE.timeOf &&
          hasCoffLeaves === false
        ) {
          showLimitExceededToast();
        }
      }
    }
  }, [selectedTypeOfLeave]);

  const submitLeaves = async () => {
    log.debug("submitLeaveNow onclickAdd", inputs, leaveData);

    if (selectedLeave) {
      const addLeave = {
        ...inputs,
        id: selectedLeave?.id,
        typeOfLeave: selectedTypeOfLeave?.name,
        quantity: selectedLeaveQuantity ? selectedLeaveQuantity : 1.0,
      };
      log.debug("submitLeaveDataNow ", addLeave);
      // addItem(addLeave);
      let filterLevesByDate;
      let selectDate;
      if (leaveData) {
        filterLevesByDate = leaveData.filter((leave) =>
          leave?.date.includes(inputs?.date)
        );
      }
      if (items) {
        selectDate = items.filter((leave) =>
          leave?.date.includes(inputs?.date)
        );
      }
      if (filterLevesByDate?.length > 0 || selectDate?.length > 0) {
        return toast({
          variant: "destructive",
          title: "Leave Exsits For the Selected Date",
        });
      } else {
        employeeMachineService.send({
          type: "update_leave",
          data: {
            leavesToAdd: addLeave,
          },
        });
      }
    } else {
      log.debug("submitleavesDataNowADD onclickAdd", inputs);
      const dateTime = moment(new Date()).toISOString();
      const formData = {
        ...inputs,
        typeOfLeave:
          actionForLeaveCRUD === "Add_comp"
            ? CONST_TYPE_OF_LEAVE.timeOf
            : selectedTypeOfLeave?.name,
        appliedBy: userID,
        userID: userID,
        status: CONST_LEAVE_STATUS.leaveStatusSubmitted,
        dateTime: dateTime,
        quantity: selectedLeaveQuantity ? selectedLeaveQuantity : 1.0,
      };
      let filterLevesByDate;
      let selectDate;
      if (leaveData) {
        filterLevesByDate = leaveData?.filter((leave) =>
          leave?.date.includes(inputs?.date)
        );
      }
      if (items) {
        selectDate = items?.filter((leave) =>
          leave?.date?.includes(inputs?.date)
        );
      }

      let annualLeaves = items?.filter(
        (item) => item.typeOfLeave == CONST_TYPE_OF_LEAVE.leaveAnnual
      );
      let sickLeaves = items?.filter(
        (item) => item.typeOfLeave == CONST_TYPE_OF_LEAVE.leaveSick
      );
      let coffLeaves = items?.filter(
        (item) => item.typeOfLeave == CONST_TYPE_OF_LEAVE.timeOf
      );

      let totalAnnualLeaveQuantity = annualLeaves?.reduce(
        (acc, item) => acc + item?.quantity,
        0
      );
      let totalSickLeaveQuantity = sickLeaves?.reduce(
        (acc, item) => acc + item?.quantity,
        0
      );
      let totalCoffLeaveQuantity = coffLeaves?.reduce(
        (acc, item) => acc + item?.quantity,
        0
      );
      switch (selectedTypeOfLeave?.name) {
        case CONST_TYPE_OF_LEAVE?.leaveAnnual:
          totalAnnualLeaveQuantity += selectedLeaveQuantity;
          if (totalAnnualLeaveQuantity > leavesOverview?.noOfLeavesRemaining) {
            return toast({
              variant: "destructive",
              title: "Annual leaves exhausted, apply another type of leave",
            });
          }
          break;
        case CONST_TYPE_OF_LEAVE.leaveSick:
          totalSickLeaveQuantity += selectedLeaveQuantity;
          if (
            totalSickLeaveQuantity > leavesOverview?.noOfMedicalLeavesRemaining
          ) {
            return toast({
              variant: "destructive",
              title: "Medical leaves exhausted, apply another type of leave",
            });
          }
          break;
        case CONST_TYPE_OF_LEAVE.timeOf:
          totalCoffLeaveQuantity += selectedLeaveQuantity;
          console.log("totalCoffLeaveQuantity::", totalCoffLeaveQuantity);
          if (
            totalCoffLeaveQuantity > leavesOverview?.noOfCOffLeavesRemaining
          ) {
            return toast({
              variant: "destructive",
              title: "Coff leaves exhausted, apply another type of leave",
            });
          }
          break;
        default:
          break;
      }

      if (filterLevesByDate?.length > 0 || selectDate?.length > 0) {
        return toast({
          variant: "destructive",
          title: "Leave Exsits For the Selected Date",
        });
      } else if (
        actionForLeaveCRUD === "Add_comp" &&
        hasCoffLeaves === "false"
      ) {
        return toast({
          variant: "destructive",
          title: "Coff leaves exhausted, apply another type of leave",
        });
      } else {
        addItem(formData);
      }
    }
  };
  const addItem = (formData) => {
    if (inputs?.date) {
      const newItem = [...items, formData];
      setItems(newItem);
    }

    inputs.title = "";
    inputs.date = "";
    inputs.typeOfLeave = "";
    inputs.details = "";
    handleSelectedLeaveQuantity("");
  };
  log.debug("item", items);
  log.debug("selectedLeave:::", selectedLeave);

  const today = new Date().toISOString().split("T")[0]; // get current date in yyyy-mm-dd format
  const columns = [
    {
      header: "Date",
      accessorKey: "date",
    },
    {
      header: "LeaveQuantity",
      accessorKey: "quantity",
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <div className="flex flex-row gap-x-2">
          <Button
            variant="danger"
            size="sm"
            onClick={() => removeItem(row)}
            className="inline-flex text-base font-semibold leading-5"
          >
            <Trash className="h-4 w-4" />
          </Button>
        </div>
      ),
    },
  ];
  const removeItem = (row) => {
    const updatedItems = items.filter(
      (item, index) => index !== parseInt(row.id, 10)
    );
    setItems(updatedItems);
  };
  if (leavesCrud) {
    switch (actionForLeaveCRUD) {
      case "EDIT":
        return (
          <DialogBox open={dialogOpen} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[70%] p-3  mt-10 sm:ml-20 sm:mr-20">
              <ScrollArea className="h-96 sm:h-[500px] sm:pb-10 pb-10">
                <DialogHeader>
                  <DialogTitle>
                    {selectedLeave ? "Edit Leave" : "Add Leave"}
                  </DialogTitle>
                  <DialogDescription>
                    Make changes to your Leaves here. Click save when you're
                    done.
                  </DialogDescription>
                  <Button
                    size="xs"
                    variant=""
                    className="fixed right-4 top-0 z-50 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                    onClick={() => {
                      employeeMachineService.send({ type: "cancel_crud" });
                    }}
                  >
                    <X className="h-5 w-10" />
                  </Button>
                </DialogHeader>

                <form onSubmit={submitLeaves} method="POST">
                  <div className="flex flex-col p-1">
                    {selectedLeave?.typeOfLeave === "TIMEOFF" ? (
                      <>
                        <div className="flex-row w-full">
                          <Label htmlFor="Title" className="text-right">
                            Type of leave
                          </Label>
                          <Input
                            defaultValue={"C-OFF LEAVE"}
                            type="number"
                            onChange={handleChange}
                            name="leaveType"
                            id="leaveType"
                            setSelected={CONST_TYPE_OF_LEAVE.timeOf}
                            placeholder="C-OFF LEAVE"
                            readOnly={actionForLeaveCRUD === "EDIT"}
                            disabled={actionForLeaveCRUD === "EDIT"}
                            className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="flex-row w-full">
                        <Label htmlFor="Title" className="text-right">
                          Type of leave
                        </Label>
                        <Dropdown
                          list={sortByKeyAlphabetical(allLeaveTypes, "label")}
                          setSelected={setSelectedTypeOfLeave}
                          selected={selectedTypeOfLeave}
                          onChange={handleChange}
                          nameField={"label"}
                          // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    )}

                    <div className=" flex-row w-full">
                      <Label htmlFor="content" className="text-right">
                        Title
                      </Label>

                      <Textarea
                        required
                        defaultValue={inputs?.title}
                        type="text"
                        onChange={handleChange}
                        name="title"
                        id="title"
                        placeholder="Title"
                        className={`${theme?.border}`}
                      />
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="dueDate" className="text-right">
                        Date
                      </Label>
                      {organization?.allowPastLeaves === true ? (
                        <Input
                          type="date"
                          onChange={handleChange}
                          name="date"
                          id="date"
                          value={inputs?.date}
                          className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        />
                      ) : (
                        <Input
                          type="date"
                          onChange={handleChange}
                          name="date"
                          id="date"
                          value={inputs?.date}
                          min={today}
                          className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                        />
                      )}
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="specific" className="text-right">
                        Select Half day or Full Day
                      </Label>
                      <div className="">
                        <RadioGroupMulti
                          list={LeaveOptions?.map((option, index) => {
                            if (
                              selectedLeave?.typeOfLeave === "TIMEOFF" &&
                              index === 1
                            ) {
                              return {
                                ...option,
                                disabled: true,
                                className: "opacity-10 cursor-not-allowed",
                              };
                            }
                            return option;
                          })}
                          selected={selectedLeaveQuantity}
                          setSelected={(value) => {
                            if (
                              !(
                                selectedLeave?.typeOfLeave === "TIMEOFF" &&
                                value === LeaveOptions[1]?.value
                              )
                            ) {
                              handleSelectedLeaveQuantity(value);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className=" flex-row">
                      <Label htmlFor="measurable" className="text-right">
                        Details
                      </Label>
                      <Textarea
                        defaultValue={inputs?.details}
                        type="text"
                        onChange={handleChange}
                        name="details"
                        id="details"
                        placeholder="Details"
                        className={`${theme?.border}`}
                      />
                    </div>
                  </div>
                  <DialogFooter className="pt-2">
                    <Button
                      type="button"
                      variant="primary"
                      className="bg-background border shadow-sm"
                      onClick={() =>
                        employeeMachineService.send({
                          type: "cancel_crud",
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Save changes</Button>
                  </DialogFooter>
                </form>
                {selectedLeave ? (
                  <LeaveComments leaveId={selectedLeave?.id} />
                ) : null}
              </ScrollArea>
            </DialogContent>
          </DialogBox>
        );
      case "DELETE":
        return (
          <Transition.Root show={leavesCrud} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                employeeMachineService.send({ type: "cancel_crud" });
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <Button
                          type="submit"
                          size="xs"
                          variant="darkOutline"
                          className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          <X className="h-4 w-4 text-" />
                          <span className="sr-only">Close</span>
                        </Button>
                      </div>
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-semoibold leading-6 text-foreground"
                          >
                            Are you absolutely sure?
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete the leave?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => {
                            employeeMachineService.send({
                              type: "delete_leave",
                            });
                          }}
                        >
                          Continue
                        </Button>
                        <Button
                          type="button"
                          variant="primary"
                          className="bg-background border shadow-sm mr-2"
                          onClick={() =>
                            employeeMachineService.send({ type: "cancel_crud" })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        );

      case "ADD":
      case "Add_comp":
        return (
          <>
            <DialogBox open={dialogOpen} onOpenChange={handleClose}>
              <DialogContent className="sm:max-w-[70%] p-3  mt-10 sm:ml-20 sm:mr-20">
                <ScrollArea className="h-96 sm:h-[500px] sm:pb-20 pb-10">
                  <DialogHeader>
                    <DialogTitle>
                      {actionForLeaveCRUD === "ADD"
                        ? selectedLeave
                          ? "Edit Leave"
                          : "Add Leave"
                        : "Add C-Off"}
                    </DialogTitle>
                    <DialogDescription>
                      {actionForLeaveCRUD === "Add_comp"
                        ? "Add Your C-Off here"
                        : "Make changes to your Leaves here. Click save when you're done."}
                    </DialogDescription>
                    <Button
                      size="xs"
                      variant=""
                      className="fixed right-4 top-0 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                      onClick={() => {
                        employeeMachineService.send({ type: "cancel_crud" });
                      }}
                    >
                      <X className="h-5 w-10" />
                    </Button>
                  </DialogHeader>

                  <div>
                    <div className="flex flex-col p-1">
                      {actionForLeaveCRUD === "ADD" ? (
                        <div className="flex-row w-full">
                          <Label htmlFor="Title" className="text-right">
                            Type of leave
                          </Label>
                          <Dropdown
                            list={sortByKeyAlphabetical(allLeaveTypes, "label")}
                            setSelected={setSelectedTypeOfLeave}
                            selected={selectedTypeOfLeave}
                            onChange={handleChange}
                            nameField={"label"}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </div>
                      ) : (
                        <>
                          <div className="flex-row w-full">
                            <Label htmlFor="Title" className="text-right">
                              Type of leave
                            </Label>
                            <Input
                              type="number"
                              name="leaveType"
                              id="leaveType"
                              setSelectedTypeOfLeave={coffLeaveTypes[0]}
                              selected={coffLeaveTypes[0]}
                              placeholder="C-OFF LEAVE"
                              readOnly={actionForLeaveCRUD === "Add_comp"}
                              disabled={actionForLeaveCRUD === "Add_comp"}
                              className={`"mt-1 p-4 text-sm ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                            />
                          </div>
                        </>
                      )}

                      <div className=" flex-row w-full">
                        <Label htmlFor="content" className="text-right">
                          Title
                        </Label>

                        <Textarea
                          required
                          value={inputs?.title}
                          type="text"
                          onChange={handleChange}
                          name="title"
                          id="title"
                          placeholder={
                            actionForLeaveCRUD === "Add_comp"
                              ? "Enter tittle of your C-Off"
                              : "Title"
                          }
                          className={`${theme?.border}`}
                        />
                      </div>

                      <div className=" flex-row">
                        <Label htmlFor="dueDate" className="text-right">
                          Date
                        </Label>
                        {organization?.allowPastLeaves === true ? (
                          <>
                            <Input
                              type="date"
                              onChange={handleChange}
                              name="date"
                              id="date"
                              value={inputs?.date}
                              className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                            />
                          </>
                        ) : (
                          <Input
                            type="date"
                            onChange={handleChange}
                            name="date"
                            id="date"
                            min={today}
                            value={inputs?.date}
                            className={`"mt-1 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
                          />
                        )}
                      </div>

                      <div className=" flex-row">
                        <Label htmlFor="specific" className="text-right">
                          Select Half day or Full Day
                        </Label>
                        <RadioGroupMulti
                          list={LeaveOptions?.map((option, index) => {
                            if (
                              actionForLeaveCRUD === "Add_comp" &&
                              index === 1
                            ) {
                              return {
                                ...option,
                                disabled: true,
                                className: "opacity-10 cursor-not-allowed",
                              };
                            }
                            return option;
                          })}
                          selected={selectedLeaveQuantity}
                          setSelected={(value) => {
                            if (
                              !(
                                actionForLeaveCRUD === "Add_comp" &&
                                value === LeaveOptions[1]?.value
                              )
                            ) {
                              handleSelectedLeaveQuantity(value);
                            }
                          }}
                        />
                      </div>
                      <DialogFooter className="pt-2"></DialogFooter>
                      <div className=" flex-row">
                        <Label htmlFor="measurable" className="text-right">
                          Details
                        </Label>
                        <Textarea
                          value={inputs?.details}
                          type="text"
                          onChange={handleChange}
                          name="details"
                          id="details"
                          placeholder={
                            actionForLeaveCRUD === "Add_comp"
                              ? "Enter details about C-Off"
                              : "Description"
                          }
                          className={`${theme?.border}`}
                        />
                      </div>
                    </div>
                    <DialogFooter className="pt-2">
                      <Button
                        type="submit"
                        onClick={() => {
                          actionForLeaveCRUD === "Add_comp"
                            ? hasCoffLeaves
                              ? submitLeaves()
                              : showLimitExceededToast()
                            : selectedTypeOfLeave
                            ? submitLeaves()
                            : selectType();
                        }}
                      >
                        Add{" "}
                      </Button>
                    </DialogFooter>
                  </div>
                  {selectedLeave ? (
                    <LeaveComments leaveId={selectedLeave?.id} />
                  ) : null}
                  <DataTable
                    columns={columns}
                    data={items}
                    customCss={`${theme?.border}`}
                    customWidth={[
                      {
                        index: 0,
                        cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                      },
                      {
                        index: 1,
                        cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                      },
                      {
                        index: 2,
                        cssStyleClass: `${theme?.bgLight} ${theme?.textCommon}`,
                      },
                    ]}
                  />
                  {items?.length > 0 ? (
                    <div className="flex flex-row justify-end pt-2">
                      <>
                        <div className=" mr-2">
                          <Button
                            type="button"
                            variant="primary"
                            className="bg-background border shadow-sm"
                            onClick={() =>
                              employeeMachineService.send({
                                type: "cancel_crud",
                              })
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                        <Button
                          type="button"
                          onClick={() => {
                            employeeMachineService.send({
                              type: "create_multiple_leave",
                              data: {
                                leavesToAdd: items,
                              },
                            });
                            setItems([]);
                          }}
                        >
                          Save Changes
                        </Button>
                      </>
                    </div>
                  ) : null}
                </ScrollArea>
              </DialogContent>
            </DialogBox>
          </>
        );
    }
  }
}
