import { useState, useEffect } from "react";
import log from "loglevel";
import {
  useEmployeeContextService,
  useEmployeeContextData,
} from "../../../../hooks/employeeMachineHooks";
import useForm from "../../../../customHooks/useForm";
import { Input } from "../../../CustomComponentsLibrary/Input";
import { Button } from "../../../CustomComponentsLibrary/Button";
import { Label } from "../../../CustomComponentsLibrary/Label";
import { Textarea } from "../../../CustomComponentsLibrary/Textarea";
import { Edit2, Trash2 } from "lucide-react";
import moment from "moment";
import { useToast } from "../../../CustomComponentsLibrary/UseToast";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import FormatDateAndTime from "../../../common/FormatDateAndTime";
import PopoverCard from "../../../common/PopoverCard";
import { useAuthContextData } from "../../../../customHooks/authMachineHooks";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EditLogHours({ onClose, refreshTaskHours }) {
  const employeeMachineService = useEmployeeContextService();
  const {
    selectedTask,
    employeeTaskHours,
    selectedTaskHour,
    userID,
    loghoursDataTobeAdded,
    employeeTaskHoursDetils,
    taskHours,
  } = useEmployeeContextData();

  log.debug(
    "loghoursDataTobeAdded::EditLogHours",
    loghoursDataTobeAdded,
    employeeTaskHours,
    employeeTaskHoursDetils,
    selectedTask,
    taskHours
  );
  const [totalHours, setTotalHours] = useState("");
  const [hasError, setHasError] = useState(false);
  const { toast } = useToast();
  const [error, setError] = useState("");
  const [items, setItems] = useState([]);
  const [newTotalHours, setNewTotalHours] = useState(0);
  const [editTableData, setEditTabledata] = useState(null);
  log.debug("editLogHour", employeeTaskHours);
  const columns = [
    {
      header: "Date",
      accessorKey: "date",
      cell: ({ row }) => (
        <FormatDateAndTime dateTime={row.original?.date} format="DD/MM/YYYY" />
      ),
    },

    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Total Hours",
      accessorKey: "totalHours",
    },
    {
      header: "Is Approved",
      cell: ({ row }) => (
        <>
          {row?.original?.isApproved === true ? (
            <span className="text-success font-bold">Yes</span>
          ) : row?.original?.isApproved === false ? (
            <span className="text-danger font-bold">No</span>
          ) : (
            <span className=" font-bold">Teamlead will take action</span>
          )}
        </>
      ),
    },
    {
      header: "Action",
      accessorKey: "",
      cell: ({ row }) => (
        <div className="flex flex-row gap-x-2">
          <Button
            href="#"
            variant="primary"
            className="2xl:ml-2"
            disabled={row?.original?.isApproved === true}
            onClick={() => {
              log.debug("_editLogHour", row.original);
              setEditTabledata(row.original);
            }}
          >
            <Edit2 className="h-4 w-4" />
          </Button>
          <PopoverCard description="Delete log hour">
            <Button
              href="#"
              variant="primary"
              className="2xl:ml-2"
              disabled={row?.original?.isApproved === true}
              onClick={() => {
                employeeMachineService.send({
                  type: "delete_task_hours",
                  data: {
                    taskHoursToBeAdded: row?.original,
                    selectedTask: selectedTask,
                  },
                });
              }}
            >
              <Trash2 className="h-4 w-4 text-danger" />
            </Button>
          </PopoverCard>
        </div>
      ),
    },
  ];

  const { inputs, handleChange } = useForm({
    userID: userID,
    startTime: editTableData ? editTableData?.startTime : "",
    endTime: editTableData ? editTableData?.endTime : "",
    date: editTableData ? moment(editTableData?.date).format("YYYY-MM-DD") : "",
    description: editTableData ? editTableData.description : "",
    isApproved: editTableData ? editTableData.isApproved : "",
    taskID: selectedTask?.taskID,
    projectID: selectedTask?.projectID,
    totalHours: editTableData ? editTableData.totalHours : 0,
  });

  useEffect(() => {
    log.debug(
      "editData::EditLogHours:useeffect",
      editTableData?.totalHours,
      employeeTaskHoursDetils?.remainingHours,
      inputs?.totalHours
    );

    if (
      editTableData?.totalHours + employeeTaskHoursDetils?.remainingHours <
      inputs?.totalHours
    ) {
      log.debug(
        "editData::EditLogHours:inside if",
        editTableData,
        +employeeTaskHoursDetils,
        inputs?.totalHours
      );
      log.debug(
        "editData:: editTableData?.totalHours",
        editTableData,
        editTableData?.totalHours
      );
      log.debug(
        "editData::inputs?.totalHours",

        inputs?.totalHours
      );
      log.debug(
        "editData::employeeTaskHoursDetils?.remainingHours",

        employeeTaskHoursDetils?.remainingHours
      );

      setHasError(true);
      toast({
        variant: "destructive",
        title: "Total Hour cannot exceed Total Allocated Hours",
        description: `for this date you can add less than ${employeeTaskHoursDetils?.totalAllocatedHoursForDay}`,
      });
    } else {
      setTotalHours(newTotalHours);
      setHasError(false);
    }
    if (inputs?.totalHours <= 0) {
      setHasError(true);
    }
  }, [employeeTaskHoursDetils, inputs?.totalHours, editTableData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    log.debug("inputs::handleSubmit:edit", inputs);
    const selectedTaskId = {
      taskID: selectedTask?.taskID,
      billableHours: selectedTask?.billableHours,
      projectID: selectedTask?.projectID,
    };

    log.debug("inputs::selectedTaskId", selectedTaskId);

    if (inputs?.date) {
      employeeMachineService.send({
        type: "update_task_hours",
        data: {
          taskHoursToBeAdded: {
            ...inputs,
            id: editTableData?.id,
            isApproved: null,
          },
          selectedTask: selectedTaskId,
        },
      });
      setEditTabledata(null);
      onClose();
    }
  };
  const { theme } = useAuthContextData();
  return (
    <>
      {editTableData ? (
        <form onSubmit={handleSubmit} method="POST">
          <div className="flex flex-col p-1">
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <Label
                htmlFor="totalHours"
                className="block text-sm font-medium leading-6 text-foreground"
              >
                Date
              </Label>
              <Input
                value={inputs?.date}
                defaultValue={moment(loghoursDataTobeAdded?.date).format(
                  "YYYY-MM-DD"
                )}
                required
                type="date"
                name="date"
                id="session-time"
                onChange={handleChange}
                disabled
                className={`"mt-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
              />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <Label
                htmlFor="totalHours"
                className="block text-sm font-medium leading-6 text-foreground"
              >
                Description
              </Label>
              <Textarea
                value={inputs?.description}
                type="text"
                name="description"
                id="description"
                onChange={handleChange}
                maxLength={150}
                className={`"mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-foreground shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" ${theme?.border}`}
              />
            </div>
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <Label
                htmlFor="totalHours"
                className="block text-sm font-medium leading-6 text-foreground"
              >
                Log Hours
              </Label>
              <Input
                type="float"
                name="totalHours"
                id="totalHours"
                required
                value={inputs?.totalHours}
                onChange={handleChange}
                step="any"
                className={`"mt-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
              />
            </div>
          </div>

          <div className="col-span-6 sm:col-span-3 text-danger text-lg lg:col-span-2">
            {error ? error : null}
          </div>
          <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
            <Button
              type="cancle"
              onClick={() => {
                onClose();
                if (!selectedTaskHour) {
                  employeeMachineService.send({
                    type: "cancel_task_hours_crud",
                  });
                }
                setItems([]);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={hasError}>
              {selectedTaskHour ? "Update" : "Update"}
            </Button>
          </div>
        </form>
      ) : null}
      <div className="container">
        <h2 className="font-bold my-2 mt-4">Log Hours History</h2>
        <div className="flex flex-col justify-start items-center md:items-end">
          <div className="sm:w-full w-[310px]">
            {employeeTaskHours?.length > 0 ? (
              <>
                <DataTable columns={columns} data={employeeTaskHours} />
              </>
            ) : null}
          </div>
          <div className="mt-4">
            <Button
              type="cancle"
              onClick={() => {
                onClose();
                if (!selectedTaskHour) {
                  employeeMachineService.send({
                    type: "cancel_task_hours_crud",
                  });
                }
                setItems([]);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
