import React, { Fragment } from "react";
import {
  useAuthContextData,
  useAuthService,
} from "../../customHooks/authMachineHooks";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Input } from "../CustomComponentsLibrary/Input";
import { Button } from "../CustomComponentsLibrary/Button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Searchbar({ setMobileMenuOpen }) {
  const authService = useAuthService();
  const { email, organizationStyling, theme } = useAuthContextData();
  const { logoSmall } = organizationStyling;

  return (
    <div className="flex text-center justify-center shadow-md h-20 border-b border-b-zinc-200">
      <div>
        <img
          className="h-10  ml-5 mt-2 sm:ml-10"
          src={logoSmall}
          alt="Your Logo"
        />
      </div>

      <header className="w-full  ">
        <div className="relative flex h-16 flex-shrink-0">
          <button
            type="button"
            className="ml-10 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden lg:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between ">
            <div className="flex flex-1">
              <form className="flex w-full md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search all files
                </label>

                <div className="relative w-full text-gray-400 focus-within:text-gray-600 flex  items-center justify-start sm:justify-end">
                  {/* <div className="pointer-events-none  inset-y-0 left-0 flex  items-center">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </div> */}

                  <Input
                    name="search-field"
                    id="search-field"
                    className={`"h-1/2 sm:w-1/4 w-2/3 p-5 border text-lg rounded-lg  pl-4 pr-2  focus:shadow-lg focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:block placeholder-gray-500 " ${theme?.border}`}
                    placeholder="Search..."
                    type="search"
                  />
                </div>
              </form>
            </div>
            <div className="ml-2 flex items-center  border-transparent space-x-4 sm:ml-6 sm:space-x-6">
              {/* Profile dropdown */}
              <Menu as="div" className="relative flex-shrink-0">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div className="absolute z-50 right-0 mt-2 w-48 origin-top-right">
                        <Menu.Items
                          static
                          className={`" rounded-md py-1  shadow-lg ring-1 ring-slate-500 ring-opacity-30 focus:outline-none z-50" ${theme?.background}`}
                        >
                          <Menu.Item key={0}>
                            <Link
                              to={"/employee/settings"}
                              className={classNames(
                                "block px-4 py-2 text-sm  hover:cursor-pointer"
                              )}
                            >
                              <div
                                className={`"flex flex-col" ${theme?.textColor}`}
                              >
                                <p className="font ">Your Profile</p>
                                <span className="text-xs">{email}</span>
                              </div>
                            </Link>
                          </Menu.Item>

                          <Menu.Item key={1}>
                            <Button
                              onClick={() =>
                                authService.send({ type: "logout" })
                              }
                              className={classNames(
                                `"block px-4 text-sm hover:cursor-pointer font-semibold" ${theme?.textColor}`
                              )}
                            >
                              Sign Out
                            </Button>
                          </Menu.Item>
                        </Menu.Items>
                      </div>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
