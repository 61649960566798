import clsx from "clsx";

const formClasses =
  "flex h-10 w-full rounded-md border bg-transparent px-3 py-2 text-lg ring-offset-input file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";

function Label({ id, children }) {
  return (
    <label
      htmlFor={id}
      className="mb-3 block text-sm font-medium text-gray-700"
    >
      {children}
    </label>
  );
}

export function TextField({
  id,
  label,
  type = "text",
  className = "",
  ...props
}) {
  return (
    <div className={className}>
      {/* {label && <Label id={id}>{label}</Label>} */}
      <input
        id={id}
        type={type}
        {...props}
        className={formClasses}
        placeholder={label}
      />
    </div>
  );
}

export function SelectField({ id, label, className = "", ...props }) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select id={id} {...props} className={clsx(formClasses, "pr-8")} />
    </div>
  );
}
