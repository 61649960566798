import {
  hookGetAllInventory,
  hookAddEmployeeInventory,
  hookUpdateEmployeeEnventory,
  hookDeleteEmployeeInventoryById,
  hookGetEmployeeInventory,
} from "../../hooks/hookEmployeeInventory";
import log from "loglevel";

export const submitInventoryDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { inventoryToAdd } = context;
    log.debug("inventoryToAdd:submitInventoryData", inventoryToAdd);
    const inventory = await hookAddEmployeeInventory(inventoryToAdd);
    log.debug("inventoryToAdd:inventory", inventory);

    if (inventory) resolve(inventory);
    reject("Failed to fetch inventory table");
  });

export const updateInventoryDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { inventoryToAdd } = context;
    const res = await hookUpdateEmployeeEnventory({
      ...inventoryToAdd,
    });
    if (res) resolve(res);
    reject("Failed To Update Table for updateInventoryData");
  });

export const deleteInventoryDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedInventory } = context;
    log.debug("deleteGoalData====>", selectedInventory);
    const inventoryDeleted = await hookDeleteEmployeeInventoryById(
      selectedInventory
    );
    log.debug("inventoryDeleted====>", inventoryDeleted);
    if (inventoryDeleted) resolve(true);
    reject("Failed To Delete Inventory");
  });

export const getInventoryDataByEmployeeIdService = (context) =>
  new Promise(async (resolve, reject) => {
    const { userID } = context;
    const inventories = await hookGetEmployeeInventory(userID);
    log.debug(
      "userID, inventories:hookGetEmployeeInventory in machine",
      userID,
      inventories
    );

    if (inventories) resolve({ inventories });
    reject("Failed To Fetch employeeProfile Tables");
  });
