import React, { createContext } from "react";
import log from "loglevel";
import { useInterpret } from "@xstate/react";
import { employeeMachine } from "../machines/employeeMachine";
import { spawn } from "xstate";
import { Outlet } from "react-router";
import { useAuthContextData } from "../customHooks/authMachineHooks";
import { useToastEffect } from "../customHooks/toastHooks";
export const EmployeeContext = createContext(spawn(employeeMachine));

export const EmployeeMachineContextProvider = ({ children }) => {
  const { userProfile, organizationID, employeeProfile, organization } =
    useAuthContextData();

  const employeeService = useInterpret(
    employeeMachine,
    {
      context: {
        userID: userProfile?.cognitoId,
        userProfile: userProfile,
        organizationID: organizationID,
        employeeProfile: employeeProfile,
        organization: organization,
      },
    },
    (state) => {
      log.debug("STATE CHANGED ", state.value, state.context);
    }
  );
  useToastEffect(employeeService);
  return (
    <EmployeeContext.Provider value={employeeService}>
      {children}
    </EmployeeContext.Provider>
  );
};

export default function EmployeeContextLayout() {
  return (
    <EmployeeMachineContextProvider>
      <Outlet />
    </EmployeeMachineContextProvider>
  );
}
