import { loadStripe } from "@stripe/stripe-js";
import log from "loglevel";

let stripePromise;

const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY =
  "pk_test_51MdrqfCLds5ZYjtN0phott2LeHBsBckwG8KqBNrNMkua1EuRY5Hvoy44YSos1m2jtVUFGOr8e1QaZLnUBtA0Te8S00aZ05cZWk";
const NEXT_PUBLIC_STRIPE_PRICE_ID = "price_1NLS2GCLds5ZYjtNkK1cqbh9";

const getStripe = () => {
  log.debug("(process.env : ", process.env);

  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51MdrqfCLds5ZYjtN0phott2LeHBsBckwG8KqBNrNMkua1EuRY5Hvoy44YSos1m2jtVUFGOr8e1QaZLnUBtA0Te8S00aZ05cZWk"
    );
  }
  return stripePromise;
};

export default getStripe;
