import React, { useState, useMemo, useEffect } from "react";
import { TabPageLinkWithParam } from "../../../components/common/TabPageLinkWithParam";
import { useLocation } from "react-router";

import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../hooks/employeeMachineHooks";
import Dropdown from "../../common/Dropdown";
import log from "loglevel";
import { CONST_USER_TYPES } from "../../../constants/dbconstants";
import { sortByKeyAlphabetical } from "../../common/SortByName";
const tabs = [
  {
    name: "Projects",
    path: "/employee/projects",
    subHead: "Overview and hours of all your Projects and tasks",
  },

  {
    name: "History",
    path: "/employee/projects/history",
    subHead: "View and edit history of your log hours",
  },
  {
    name: "Log Hours",
    path: "/employee/projects/loghours",
    subHead: "Select a month to view your log hours",
  },
  // {
  //   name: "Log Hours By Project",
  //   path: "/employee/projects/loghoursbyproject",
  //   subHead: "Select a month to view your log hours",
  // },
];

const tabs2 = [
  {
    name: "Projects",
    path: "/employee/projects",
    subHead: "Overview and hours of all your Projects and tasks",
  },

  {
    name: "History",
    path: "/employee/projects/history",
    subHead: "View and edit history of your log hours",
  },
  {
    name: "Log Hours",
    path: "/employee/projects/loghours",
    subHead: "Select a month to view your log hours",
  },
  {
    name: "Manage",
    path: "/employee/projects/manage",
    subHead: "Assign project to teammember",
  },
];
var ram;
export function ProjectSettingsLayout() {
  const { userID, myEmployeeProfile, teamMembersNames, employeeList } =
    useEmployeeContextData();
  const [ManageProject, setManageProject] = useState([...tabs]);
  const location = useLocation();
  useEffect(() => {
    if (myEmployeeProfile?.userType?.includes("MANAGER")) {
      log.debug("myEmployeeProfile?.userType.includes");
      const tabs2 = [
        {
          name: "Projects",
          path: "/employee/projects",
          subHead: "Overview and hours of all your Projects and tasks",
        },

        {
          name: "History",
          path: "/employee/projects/history",
          subHead: "View and edit history of your log hours",
        },
        {
          name: "Log Hours",
          path: "/employee/projects/loghours",
          subHead: "Select a month to view your log hours",
        },
        {
          name: "Manage",
          path: "/employee/projects/manage",
          subHead: "Assign project to teammember",
        },
      ];
      setManageProject(tabs2);
    }
    if (myEmployeeProfile?.userType?.includes("TEAMLEAD")) {
      const tabs2 = [
        {
          name: "Projects",
          path: "/employee/projects",
          subHead: "Overview and hours of all your Projects and tasks",
        },

        {
          name: "History",
          path: "/employee/projects/history",
          subHead: "View and edit history of your log hours",
        },
        {
          name: "Log Hours",
          path: "/employee/projects/loghours",
          subHead: "Select a month to view your log hours",
        },
        {
          name: "Manage",
          path: "/employee/projects/manage",
          subHead: "Assign project to teammember",
        },
        {
          name: "Log Hours By Project",
          path: "/employee/projects/loghoursbyproject",
          subHead: "Select a month to view your log hours",
        },
      ];
      setManageProject(tabs2);
    }
  }, [myEmployeeProfile]);

  log.debug(
    "myEmployeeProfile::teamMembersNames",
    myEmployeeProfile,
    employeeList
  );
  /* myTeamMembers  from machine */
  const [currentUserDetails, setCurrentUserDetails] = useState({
    id: userID,
    name: "Self",
  });

  const filteredTeamMembers = useMemo(() => {
    if (
      employeeList &&
      myEmployeeProfile?.employees &&
      myEmployeeProfile?.userType
        ?.split(",")
        .some((type) =>
          [CONST_USER_TYPES.manager, CONST_USER_TYPES.teamLead].includes(
            type.trim()
          )
        )
    ) {
      const filteredMembers = employeeList?.filter((member) =>
        myEmployeeProfile?.employees?.includes(member?.id)
      );

      const currentUserInfo = {
        id: myEmployeeProfile?.cognitoId,
        title: `${myEmployeeProfile?.firstName} ${myEmployeeProfile?.LastName}`,
      };
      if (
        !filteredMembers?.some((member) => member?.id === currentUserInfo?.id)
      ) {
        filteredMembers?.unshift(currentUserInfo);
      }

      return filteredMembers?.map((member) => ({
        id: member.id,
        title: member.title,
      }));
    }
    return null;
  }, [employeeList, myEmployeeProfile?.employees]);
  log.debug("filteredTeamMembers", filteredTeamMembers);
  log.debug("ManageProject::", ManageProject);
  const initialTab = ManageProject?.map(({ path }) => path)?.findLast((path) =>
    location?.pathname?.includes(path)
  );
  log.debug("initialTab::ProjectLayout", initialTab);
  return (
    <>
      <div className="flex flex-col w-full border">
        <div className="flex justify-end items-end w-full py-2 px-2">
          {(myEmployeeProfile?.userType?.includes("TEAMLEAD") ||
            myEmployeeProfile?.userType?.includes("MANAGER")) &&
            initialTab !== "/employee/projects/loghoursbyproject" && (
              <Dropdown
                list={sortByKeyAlphabetical(filteredTeamMembers, "title")}
                setSelected={setCurrentUserDetails}
                selected={currentUserDetails}
                className="w-auto"
                placeholder="Select Team Member"
                required
                nameField="title"
              />
            )}
        </div>

        <div className="flex  w-full relative top-[-50px]">
          <TabPageLinkWithParam
            tabs={ManageProject}
            currentUserId={currentUserDetails?.id}
            currentUserName={
              currentUserDetails?.title
                ? currentUserDetails?.title
                : currentUserDetails?.name
            }
          />
        </div>
      </div>
    </>
  );
}
