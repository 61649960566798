import { CONST_LEAVE_STATUS } from "../constants/dbconstants";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { API } from "aws-amplify";
import log from "loglevel";
import { getWorkingDaysOfWeek } from "../lib/dateService";
import moment from "moment";

export const hookListCoffsByUserId = async (cognitoId) => {
  log.debug("hookListCoffsByUserId:cognitoId", cognitoId);
  try {
    const data = await API.graphql({
      query: queries.CoffsByUserID,
      variables: {
        sortDirection: "DESC",
        userID: cognitoId,
      },
    });

    log.debug(" hookListCoffsByUserId : ", data);
    if (data !== null) return data?.data?.CoffsByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCoffsByUserId -- ", e);
    return null;
  }
};
export const hookListCoffsByUserIdAndYear = async (
  cognitoId,
  firstDay,
  lastDay
) => {
  log.debug("hookListCoffsByUserIdAndYear", cognitoId);
  try {
    const data = await API.graphql({
      query: queries.CoffsByUserID,
      variables: {
        sortDirection: "DESC",
        userID: cognitoId,
        date2: { between: [firstDay, lastDay] },
      },
    });

    log.debug(" hookListCoffsByUserIdAndYear : ", data);
    if (data !== null) return data?.data?.CoffsByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCoffsByUserIdAndYear -- ", e);
    return null;
  }
};

export const hookListCoffStatusByUserId = async (cognitoId) => {
  try {
    const data = await API.graphql({
      query: customQueries.coffsStatusByUserID,
        variables: {
          sortDirection: "DESC",
          userID: cognitoId,
        },
    });

    log.debug(" hookListCoffStatusByUserId : ", data);
    if (data !== null) return data?.data?.CoffsByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCoffStatusByUserId -- ", e);
    return null;
  }
};

export const hookListSubmittedCoffs = async () => {
  try {
    const data = await API.graphql({
      query: queries.listCoffRequests,

      variables: {
        sortDirection: "DESC",
        limit: 25,
      },
    });

    log.debug(" hookListCoffs : ", data);
    const allCoffs = data?.data?.listCoffRequests.items;
    if (allCoffs) {
      return allCoffs.filter(
        (coff) => coff.status === CONST_LEAVE_STATUS.coffStatusSubmitted
      );
    } else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListSubmittedCoffs -- ", e);
    return null;
  }
};

export const hookListEmployeeCoffsByMonth = async ({ month, cognitoId }) => {
  const monthStr = month.substring(0, 7);

  log.debug(" hookListEmployeeCoffsByMonth monthStr: ", monthStr);

  try {
    const data = await API.graphql({
      query: queries.CoffsByUserID,
      variables: {
        userID: cognitoId,
      },
    });
    // log.debug(" hookListEmployeeCoffsByMonth : ", data);

    let monthCoffs = [];
    const coffs = data?.data?.CoffsByUserID.items;
    monthCoffs = coffs.filter((coff) => {
      const monthStrMatchingCoffs = coff.date.filter((dateStr) =>
        dateStr.includes(monthStr)
      );
      log.debug(
        " hookListEmployeeCoffsByMonth monthStrMatchingCoffs: ",
        monthStrMatchingCoffs
      );
      log.debug(
        " hookListEmployeeCoffsByMonth monthStrMatchingCoffs.length: ",
        monthStrMatchingCoffs.length
      );

      return monthStrMatchingCoffs.length > 0
        ? /*  && Comment for now CONST_LEAVE_STATUS.coffStatusApproved */
          1
        : 0;
    });
    if (data !== null) {
      log.debug(" hookListEmployeeCoffsByMonth : ", monthCoffs);

      return monthCoffs;
    } else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCoffsByUserId -- ", e);
    return null;
  }
};

export const hookListEmployeeCoffsOfCurrentWeek = async (orgId) => {
  log.debug(" hookListEmployeeCoffsOfCurrentWeek  ", orgId);

  try {
    const data = await API.graphql({
      query: customQueries.teramCoffsByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" hookListEmployeeCoffsOfCurrentWeek : ", data);

    let weekCoffs = [];
    const coffs = data?.data?.CoffsByOrganizationID?.items || [];
    const daysOfWeek = getWorkingDaysOfWeek("currentWeek");
    const shortDaysOfWeek = daysOfWeek.map((day) =>
      moment(day).format("YYYY-MM-DD")
    );

    weekCoffs = coffs.map((coff) => {
      // log.debug("Coffs Mapping,", coff);

      let isCoffOfCurrentWeek = false;
      shortDaysOfWeek.forEach((weekDay) => {
        // log.debug("Weekday foeeach,", weekDay);
        if (!coff.date) isCoffOfCurrentWeek = false;
        if (coff.date.includes(weekDay)) {
          // log.debug("Found Coff Week", weekDay, coff);
          isCoffOfCurrentWeek = true;
        }
        if (isCoffOfCurrentWeek) return;
      });
      if (isCoffOfCurrentWeek) return coff;
    });

    // log.debug("WeekCoffs", weekCoffs, shortDaysOfWeek);
    weekCoffs = weekCoffs.filter((coff) => coff);
    if (data !== null) {
      log.debug(" hookListEmployeeCoffsOfCurrentWeek : ", weekCoffs);

      return weekCoffs;
    } else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeCoffsOfCurrentWeek -- ", e);
    return null;
  }
};

export const hookAddCoff = async (Coff) => {
  log.debug(" hookAddCoff : ", Coff);

  try {
    const result = await API.graphql({
      query: mutations.createCoffRequest,
      variables: {
        input: Coff,
      },
    });

    log.debug(" hookAddCoff result: ", result);

    return result?.data?.createCoffRequest;
  } catch (err) {
    log.debug("CATCH ERROR hookAddCoff result: ", err);
    return null;
  }
};

export const hookUpdateCoff = async (Coff) => {
  log.debug(" hookUpdateCoff : ", Coff);
  try {
    const result = await API.graphql({
      query: mutations.updateCoffRequest,
      variables: {
        input: Coff,
      },
    });

    log.debug(" hookUpdateCoff result: ", result);
    return result?.data?.updateCoffRequest;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateCoff result: ", err);
  }
};

export const hookListCoffs = async (cognitoId) => {
  try {
    const data = await API.graphql({
      query: queries.listCoffRequests,

      variables: {
        sortDirection: "DESC",
        limit: 100,
      },
    });

    log.debug(" hookListCoffs : ", data);

    if (data !== null) return data?.data?.listCoffs.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListCoffsByUserId -- ", e);
    return null;
  }
};

export const hookDeleteCoffsByCoffId = async (coffId) => {
  log.debug(" hookDeleteCoffsByCoffId : ", coffId);
  try {
    const result = await API.graphql({
      query: mutations.deleteCoffRequest,
      variables: {
        input: { id: coffId },
      },
    });

    log.debug(" hookDeleteCoffsByCoffId result: ", result);
    return result;
  } catch (err) {
    log.debug("CATCH ERROR hookDeleteCoffsByCoffId result: ", err);
  }
};

export const hookListEmployeeCoffsByOrgID = async (organizationID) => {
  try {
    const data = await API.graphql({
      query: queries.CoffsByOrganizationID,

      variables: {
        organizationID: organizationID,
        limit: 200,
      },
    });

    log.debug(" hookListEmployeeCoffsByOrgID : ", data);

    if (data !== null) return data?.data?.CoffsByOrganizationID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListEmployeeCoffsByOrgID -- ", e);
    return null;
  }
};
export const hookGetEmployeeCoffHistoryByIdAndYear = async (userId, year) => {
  log.debug(" hookGetEmployeeCoffHistoryByIdAndYear ", userId, year);
  log.debug("typeofDataYear", typeof year);

  try {
    const data = await API.graphql({
      query: customQueries.getEmployeeLeaveHistoryByUserIdAndYear,
      variables: {
        userID: userId,
        year: year,
      },
    });

    log.debug(" hookGetEmployeeCoffHistoryByIdAndYear: ", data);

    if (data !== null) return data?.data?.EmployeeCoffHistoryByUserID?.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetEmployeeCoffHistoryByIdAndYear -- ", e);
    return null;
  }
};
