import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../CustomComponentsLibrary/table";
import { useAuthContextData } from "../../customHooks/authMachineHooks";

export function ShowLeavesOverview({ leavesOverview, unpaidLeaves }) {
  const { theme } = useAuthContextData();
  console.log("leavesoverview:::", leavesOverview, unpaidLeaves);
  return (
    <div class="relative overflow-x-auto rounded-md shadow-md p-5">
      <Table className="border">
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead className="">Leaves Allocated</TableHead>
            <TableHead> Leaves Taken</TableHead>
            <TableHead> Leaves Remaining</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableHead className="font-medium">Annual Leaves</TableHead>
            <TableCell className="font-medium">
              {leavesOverview?.noOfLeaves}
            </TableCell>
            <TableCell className="font-medium">
              {leavesOverview?.noOfLeavesTaken}
            </TableCell>
            <TableCell className="font-medium">
              {leavesOverview?.noOfLeavesRemaining}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead className="font-medium"> Medical Leaves</TableHead>
            <TableCell className="font-medium">
              {leavesOverview?.noOfMedicalLeaves}
            </TableCell>
            <TableCell className="font-medium">
              {leavesOverview?.noOfMedicalLeavesTaken}
            </TableCell>
            <TableCell className="font-medium">
              {leavesOverview?.noOfMedicalLeavesRemaining}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead className="font-medium"> C-OFF Leaves</TableHead>
            <TableCell className="font-medium">
              {leavesOverview?.noOfCOffLeaves}
            </TableCell>
            <TableCell className="font-medium">
              {leavesOverview?.noOfCOffLeavesTaken}
            </TableCell>
            <TableCell className="font-medium">
              {leavesOverview?.noOfCOffLeavesRemaining}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead className="font-medium"> Unpaid Leaves</TableHead>
            <TableCell className="font-medium">-</TableCell>
            <TableCell className="font-medium">{unpaidLeaves}</TableCell>
            <TableCell className="font-medium">-</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div>
        <span className="text-danger text-base font-bold">*</span>
        <span className="item-center text-lg">
          The Leave balance is only based on Approved leaves
        </span>
        <span className="text-danger text-base font-bold">*</span>
      </div>
    </div>
  );
}
