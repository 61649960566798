import React from "react";
import { TabPageLink } from "../../components/common/TabPageLink";

const tabs = [
  {
    name: "Profile",
    path: "/employee/settings",
  },

  {
    name: "Inventory",
    path: "/employee/settings/inventory",
  },
  {
    name: "Documents",
    path: "/employee/settings/documents",
  },
  {
    name: "Organization Hierarchy",
    path: "/employee/settings/organization",
  },
];

export function EmployeeSettingLayout() {
  return (
    <>
      <TabPageLink tabs={tabs} />
    </>
  );
}
