import { useState } from "react";
import Messages from "./Messages";
export default function ConfirmPasswords({ setPassword }) {
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const [message, setMessage] = useState({
    messageText: "",
    messageType: "",
  });
  function onPasswordsChange(e) {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    if (e.target.name === "confirmPassword") {
      if (passwords.password !== e.target.value) {
        setMessage({
          messageText: "confirm Password not matching with password!",
          messageType: "failure",
        });
        setPassword("");
      } else {
        setMessage({
          messageText: "both passwords  matching",
          messageType: "success",
        });
        setPassword(passwords.password);
      }
    }
  }
  return (
    <>
      <div className="m-2">
        <label>Password</label>
        <input
          id="password"
          name="password"
          type="password"
          required
          className="bg-gray-300"
          onChange={onPasswordsChange}
        />
      </div>

      <div className="m-2">
        <label>Confirm Password</label>
        <input
          required
          className="bg-gray-300"
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          onChange={onPasswordsChange}
        />
      </div>

      {message.messageText ? (
        <div className="text-center">
          <Messages message={message} setMessage={setMessage} />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
