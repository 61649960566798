import React, { createContext } from "react";
import log from "loglevel";
import { useInterpret } from "@xstate/react";
import { authMachine } from "../machines/authMachine";
import { useToastEffect } from "../customHooks/toastHooks";

export const AuthMachineContext = createContext(null);

export const AuthMachineContextProvider = ({ children }) => {
  const authService = useInterpret(authMachine, { context: {} }, (state) => {
    log.debug("STATE CHANGED ", state.value, state.context);
  });
  useToastEffect(authService);
  return (
    <AuthMachineContext.Provider value={{ authService }}>
      {children}
    </AuthMachineContext.Provider>
  );
};
