import {
  hookAddTaskHours,
  hookUpdateTaskHours,
  hookGetTaskHoursByTaskId,
  hookDeleteTaskHoursByTaskHoursId,
  hookGetTaskHoursByMonthAndUserId,
  hookGetTaskHoursByTaskIdAndDates,
  hookGetTaskHoursByMonthAndTaskId,
} from "../../hooks/HookTaskHours";
import {
  CONST_LEAVE_STATUS,
  CONST_TASK_STATUS,
  CONST_UPDATE_CONTEXT_ACTIONS,
} from "../../constants/dbconstants";
import log from "loglevel";
import { hookUpdateEmployeeTask } from "../../hooks/employeeTasksHooks";
import { hookGetTaskById } from "../../hooks/tasksHooks";
import { hookGetProjectTitleById } from "../../hooks/projectHooks";
import {
  getFirstAndLastDateTimeOfMonth,
  initializeWeekdays,
} from "../../lib/dateService";
import { hookListOrgBusinessDayssByOrgId } from "../../hooks/orgBusinessDaysHooks";
import { getLogHourByOrgId } from "../../constants/orgLogHourConstant";
import {
  hookListLeavesByUserId,
  hookListLeavesByUserIdAndYear,
} from "../../hooks/leaveHooks";
import { hookListOrgLeavesByYearId } from "../../hooks/orgLeaveHooks";
import { hookListOrgYearLeavessByOrgId } from "../../hooks/orgYearLeaveHooks";
import moment from "moment";
export const submitTaskHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskHoursToBeAdded, selectedTask, multipleProjectTasks } = context;
    log.debug(
      "taskHoursToBeAdded:submitTaskHoursDataService",
      taskHoursToBeAdded,
      selectedTask
    );

    let updateEmployeeTaskForHours = [];
    const taskHours = await hookAddTaskHours({
      ...taskHoursToBeAdded,
    });
    log.debug("taskHoursToBeAdded:tasksHours", taskHours);

    let finaltotalHours;

    const result = await hookGetTaskHoursByTaskId(taskHours?.taskID);

    if (result) {
      const totalHoursArray = result?.map((item) => item?.totalHours);
      finaltotalHours = totalHoursArray?.reduce(
        (accumulator, currentTotalHours) => {
          return accumulator + currentTotalHours;
        }
      );
      log.debug(
        "taskHoursArrayforService:totalhoursArray",
        result,
        totalHoursArray,
        finaltotalHours
      );
    }

    let billedHours;
    let billedUnbillableHours = 0;
    if (finaltotalHours > selectedTask?.billableHours) {
      billedHours = selectedTask?.billableHours;
      billedUnbillableHours = finaltotalHours - selectedTask?.billableHours;
    } else {
      billedHours = finaltotalHours;
    }
    var percentage = 0;
    percentage = (billedHours * 100) / selectedTask?.billableHours;
    if (selectedTask?.billableHours === 0 && billedHours === 0) {
      percentage = 0;
    }
    if (percentage === null) {
      percentage = 0;
    }
    const dataToUpdate = {
      id: selectedTask?.id,
      billedHours: billedHours,
      billedUnbillableHours: billedUnbillableHours,
      percentage: percentage.toFixed(0),
      status: CONST_TASK_STATUS.taskStatusInprogress,
    };
    updateEmployeeTaskForHours = await hookUpdateEmployeeTask(dataToUpdate);
    log.debug("updateEmployeeTaskForHours::", updateEmployeeTaskForHours);

    const updatedProjects = replaceTask(
      multipleProjectTasks,
      updateEmployeeTaskForHours?.id,
      updateEmployeeTaskForHours
    );

    resolve([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
        contextField: "taskHours",
        value: taskHours,
      },
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
        contextField: "tasks",
        value: updateEmployeeTaskForHours,
      },
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
        contextField: "projectTasks",
        value: updateEmployeeTaskForHours,
      },
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update, //
        contextField: "multipleProjectTasks",
        value: updatedProjects ? updatedProjects : [],
      },
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Successfully added taskhours",
      },
    ]);

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To add taskHours",
      },
    ]);
  });

export const updateTaskHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskHoursToBeAdded, selectedTask, multipleProjectTasks } = context;

    const taskHours = await hookUpdateTaskHours({
      ...taskHoursToBeAdded,
    });

    let finaltotalHours = 0;

    if (taskHours) {
      const result = await hookGetTaskHoursByTaskId(taskHours?.taskID);

      if (result) {
        const totalHoursArray = result?.map((item) => item?.totalHours);
        finaltotalHours = totalHoursArray?.reduce(
          (accumulator, currentTotalHours) => {
            return accumulator + currentTotalHours;
          }
        );
        log.debug(
          "taskHoursArrayforService:totalhoursArray",
          result,
          totalHoursArray,
          finaltotalHours,
          selectedTask
        );
      }
      let billedHours = finaltotalHours;
      let billedUnbillableHours = 0;
      if (finaltotalHours > selectedTask?.billableHours) {
        billedHours = selectedTask?.billableHours;
        billedUnbillableHours = finaltotalHours - selectedTask?.billableHours;
      }
      var percentage = 0;
      percentage =
        (selectedTask?.billedHours * 100) / selectedTask?.billableHours;
      if (selectedTask?.billableHours === 0 && billedHours === 0) {
        percentage = 0;
      }
      if (percentage === null || undefined) {
        percentage = 0;
      }

      const dataToUpdate = {
        id: taskHours?.taskID,
        billedHours: billedHours,
        billedUnbillableHours: billedUnbillableHours,
        percentage: percentage.toFixed(0),
      };

      log.debug(
        "taskHours:updateTaskHoursDataService",
        taskHours,
        dataToUpdate
      );
      const updateEmployeeTaskForHours = await hookUpdateEmployeeTask(
        dataToUpdate
      );
      log.debug("updateEmployeeTaskForHours::", updateEmployeeTaskForHours);

      const updatedProjects = replaceTask(
        multipleProjectTasks,
        updateEmployeeTaskForHours?.id,
        updateEmployeeTaskForHours
      );

      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "taskHours",
          value: taskHours,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "projectTasks",
          value: updateEmployeeTaskForHours,
        },
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
          contextField: "tasks",
          value: updateEmployeeTaskForHours ? updateEmployeeTaskForHours : [],
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update, //
          contextField: "multipleProjectTasks",
          value: updatedProjects ? updatedProjects : [],
        },

        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Successfully Updated taskhours",
        },
      ]);
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update taskHours",
      },
    ]);
  });

export const deleteTaskHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedTaskHour, selectedTask, multipleProjectTasks } = context;
    log.debug("deleteTaskHourData====>", selectedTaskHour);

    const taskHourDeleted = await hookDeleteTaskHoursByTaskHoursId(
      selectedTaskHour?.id
    );

    let finaltotalHours = 0;

    if (selectedTaskHour) {
      const result = await hookGetTaskHoursByTaskId(selectedTaskHour?.taskID);

      if (result?.length) {
        const totalHoursArray = result?.map((item) => item?.totalHours);
        finaltotalHours = totalHoursArray?.reduce(
          (accumulator, currentTotalHours) => {
            return accumulator + currentTotalHours;
          }
        );
      }
      log.debug("result", finaltotalHours);
      let billedHours = finaltotalHours;
      let billedUnbillableHours = 0;
      if (finaltotalHours > selectedTask?.billableHours) {
        billedHours = selectedTask?.billableHours;
        billedUnbillableHours = finaltotalHours - selectedTask?.billableHours;
      }

      var percentage = 0;
      percentage = (billedHours * 100) / selectedTask?.billableHours;
      if (selectedTask?.billableHours === 0 && billedHours === 0) {
        percentage = 0;
      }
      if (percentage === null) {
        percentage = 0;
      }
      const dataToUpdate = {
        id: selectedTask?.id,
        // unbillableHours: unbillableHours,
        billedHours: billedHours,
        billedUnbillableHours: billedUnbillableHours,
        percentage: percentage.toFixed(0),
      };
      const updateEmployeeTaskForHours = await hookUpdateEmployeeTask(
        dataToUpdate
      );
      const updatedProjects = replaceTask(
        multipleProjectTasks,
        updateEmployeeTaskForHours?.id,
        updateEmployeeTaskForHours
      );
      log.debug("deleteTaskHourData====>", updatedProjects);
      log.debug("multipleProjectTasks", multipleProjectTasks);
      if (taskHourDeleted) {
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.removeArr, // what to do
            contextField: "taskHours",
            value: taskHourDeleted,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
            contextField: "projectTasks",
            value: updateEmployeeTaskForHours,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, //
            contextField: "multipleProjectTasks",
            value: updatedProjects ? updatedProjects : [],
          },
        ]);
      }
    }
    reject("Failed To Delete TaskHourData");
  });

export const approveSelectedLogHours = (context) =>
  new Promise(async (resolve, reject) => {
    const { selectedRowsToApprove, isApprove } = context;
    log.debug(
      "selectedRowsToApprove:approveSelectedLogHours",
      selectedRowsToApprove,
      isApprove
    );

    const updateData = async (item) => {
      let DataToSend = {};
      if (isApprove) {
        DataToSend = {
          ...item,
          isApproved: true,
        };
      } else {
        DataToSend = {
          ...item,
          isApproved: false,
        };
      }
      delete DataToSend?.createdAt;
      delete DataToSend?.updatedAt;
      try {
        const taskHours = await hookUpdateTaskHours({ ...DataToSend });

        return {
          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArrMultiple,
          contextField: "taskHours",
          value: taskHours,
        };
      } catch (error) {
        return {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To Approve taskHours",
        };
      }
    };

    try {
      const promises = selectedRowsToApprove?.map((item) => updateData(item));
      const results = await Promise.all(promises);

      if (results?.length > 0) {
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArrMultiple,
            contextField: "taskHours",
            value: results,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArrMultiple,
            contextField: "employeeTaskHours",
            value: results,
          },
        ]);
      } else {
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Failed To Update taskHours",
          },
        ]);
      }
    } catch (error) {
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To Update taskHours",
        },
      ]);
    }
  });

export const updateMultipleTaskHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { logHoursArrayToAdd, selectedTask } = context;
    log.debug(
      "logHoursArrayToAdd:updateMultipleTaskHoursDataService",
      logHoursArrayToAdd
    );

    const updateData = async (item) => {
      const taskHours = await hookAddTaskHours({ ...item });

      let finaltotalHours = 0;

      if (taskHours) {
        const result = await hookGetTaskHoursByTaskId(taskHours?.taskID);

        if (result) {
          const totalHoursArray = result?.map((item) => item?.totalHours);
          finaltotalHours = totalHoursArray?.reduce(
            (accumulator, currentTotalHours) => {
              return accumulator + currentTotalHours;
            }
          );
          log.debug(
            "taskHoursArrayforService:totalhoursArray",
            result,
            totalHoursArray,
            finaltotalHours
          );
        }
        let billedHours = finaltotalHours;
        let billedUnbillableHours;
        if (finaltotalHours > selectedTask?.billableHours) {
          billedHours = selectedTask?.billableHours;
          billedUnbillableHours = finaltotalHours - selectedTask?.billableHours;
        }

        const dataToUpdate = {
          id: selectedTask?.id,
          billedHours: billedHours,
          billedUnbillableHours: billedUnbillableHours,
        };

        const updateEmployeeTaskForHours = await hookUpdateEmployeeTask(
          dataToUpdate
        );

        return {
          action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
          contextField: "taskHours",
          value: taskHours,

          action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, //
          contextField: "tasks",
          value: updateEmployeeTaskForHours,
        };
      }
      return {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update taskHours",
      };
    };

    const promises = logHoursArrayToAdd?.map((item) => updateData(item));
    try {
      const results = await Promise.all(promises);
      log.debug("results::", results);
      if (results) {
        resolve([
          ...results,
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
            contextField: "tasks",
            value: results,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully Updated taskhours",
          },
        ]);
      } else {
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Failed To update taskHours",
          },
        ]);
      }
    } catch (error) {
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To update taskHours",
        },
      ]);
    }
  });

export const getEmployeeLogHoursService = (context) =>
  new Promise(async (resolve, reject) => {
    let employeeTaskHours = [];
    let customOffDays = [];
    let orgHolidays = [];
    let leaveByMonth = [];
    let filteredHolidays = [];

    const {
      taskHourByMonth,
      actionForTaskHoursCURD,
      organizationID,
      selectedProject,
    } = context;
    log.debug(
      "taskHourByMonth:getEmployeeLogHoursService",
      taskHourByMonth,
      actionForTaskHoursCURD
    );

    employeeTaskHours = await hookGetTaskHoursByMonthAndUserId(taskHourByMonth);
    customOffDays = await hookListOrgBusinessDayssByOrgId(organizationID);
    leaveByMonth = await hookListLeavesByUserIdAndYear(
      taskHourByMonth?.userID,
      taskHourByMonth?.firstDay,
      taskHourByMonth?.lastDay
    );
    const fetchedOrgYearHolidays = await hookListOrgYearLeavessByOrgId(
      organizationID
    );
    log.debug("fetchedOrgYearHolidays", fetchedOrgYearHolidays);
    if (fetchedOrgYearHolidays) {
      const currentYear = new Date().getFullYear();
      filteredHolidays = fetchedOrgYearHolidays?.filter((holiday) => {
        const holidayYear = new Date(holiday?.year).getFullYear();
        return holidayYear === currentYear;
      });
    }
    log.debug("filteredHolidays", filteredHolidays);

    if (filteredHolidays?.length) {
      const fetchedOrgLeaves = await hookListOrgLeavesByYearId(
        filteredHolidays[0]?.id
      );

      if (fetchedOrgLeaves) {
        orgHolidays = [...new Set(fetchedOrgLeaves?.map((item) => item?.date))];
      }
      log.debug("fetchedOrgLeaves", fetchedOrgLeaves, orgHolidays);
    }

    const filterApprovedLeaves = (val) => {
      const leaveDay = [];
      val?.forEach((item) => {
        if (
          item.status === CONST_LEAVE_STATUS.leaveStatusApproved ||
          item.status === CONST_LEAVE_STATUS.leaveStatusSubmitted
        ) {
          leaveDay.push(item);
        }
      });
      return leaveDay;
    };
    const approvedLeave = filterApprovedLeaves(leaveByMonth);
    let leaveDate = [];
    if (approvedLeave) {
      log.debug("approvedLeave::", approvedLeave);
      leaveDate = [...new Set(approvedLeave?.map((item) => item))];
    }

    if (employeeTaskHours) {
      let uniqueProjectIDs = [];
      let uniqueTaskIDs = [];

      uniqueProjectIDs = [
        ...new Set(employeeTaskHours?.map((item) => item?.projectID)),
      ];
      uniqueTaskIDs = [
        ...new Set(employeeTaskHours?.map((item) => item?.taskID)),
      ];

      const tasks = uniqueTaskIDs?.map(async (item) => {
        const task = await hookGetTaskById(item);

        return task ? { ...task } : null;
      });

      const tasksArray = await Promise.all(tasks);

      const projectTitleArr = uniqueProjectIDs?.map(async (item) => {
        const projectTitle = await hookGetProjectTitleById(item);
        return {
          id: item,
          title: projectTitle,
        };
      });
      const projectItems = await Promise.all(projectTitleArr);

      function replaceKeysWithTitles(taskHoursArray, taskArray, projectArray) {
        log.debug("replaceKeysWithTitles", taskHoursArray, taskArray);

        // Filter out null tasks from taskArray
        const filteredTaskArray = taskArray.filter((task) => task !== null);
        log.debug("replaceKeysWithTitles:filteredTaskArray", filteredTaskArray);

        const resultMap = new Map(
          [...filteredTaskArray, ...projectArray].map((item) => [
            item.id,
            {
              id: item.id,
              title: item.title,
              unbillableHours: item.unbillableHours || 0, // Ensure it defaults to 0 if null or undefined
              billableHours: item.billableHours || 0, // Ensure it defaults to 0 if null or undefined
              billedHours: item.billedHours || 0, // Ensure it defaults to 0 if null or undefined
              billedUnbillableHours: item.billedUnbillableHours || 0, // Ensure it defaults to 0 if null or undefined
            },
          ])
        );

        return taskHoursArray?.map((obj) => {
          const newObj = { ...obj };

          if (newObj?.taskID) {
            const task = resultMap?.get(newObj?.taskID);
            log.debug("task::", task);
            if (task) {
              // Check if task is not undefined or null
              newObj.taskID = task.id;
              newObj.taskTitle = task.title;
              newObj.unbillableHours = task.unbillableHours;
              newObj.billableHours = task.billableHours;
              newObj.billedHours = task.billedHours;
              newObj.billedUnbillableHours = task.billedUnbillableHours;
            }
          }

          if (newObj?.projectID) {
            const project = resultMap.get(newObj.projectID);
            newObj.projectID = project.id;
            newObj.projectTitle = project.title;
          }

          return newObj;
        });
      }

      let newEmployeeTaskHours = replaceKeysWithTitles(
        employeeTaskHours,
        tasksArray,
        projectItems
      );

      const calculateHoursByDateForTaskID = (
        logHoursData,
        startDate,
        endDate
      ) => {
        const result = [];
        const uniqueTaskIDs = [
          ...new Set(logHoursData.map((log) => log.taskID)),
        ];

        log.debug(
          "logHoursData::calculateHoursByDateForTaskID::getEmployeeLogHoursService",
          logHoursData,
          startDate,
          endDate,
          uniqueTaskIDs
        );
        uniqueTaskIDs.forEach((taskID) => {
          const taskLogs = logHoursData.filter((log) => log.taskID === taskID);
          log.debug(
            "taskLogs::calculateHoursByDateForTaskID::getEmployeeLogHoursService",
            taskLogs
          );
          log.debug("checking tasklogs percentage", taskLogs);
          const mergedHoursMap = {};

          // Iterate through the array and merge hours for objects with the same date
          taskLogs?.forEach((obj) => {
            const { date, totalHours } = obj;

            if (mergedHoursMap[date]) {
              // If date exists in the map, add hours to the existing value
              mergedHoursMap[date] += totalHours;
            } else {
              // If date is not in the map, create a new entry
              mergedHoursMap[date] = totalHours;
            }
          });
          const mergedDescriptionMap = {};

          // Iterate through the array and merge description for objects with the same date
          const logHourData = taskLogs?.filter((item) => item?.totalHours > 0);
          taskLogs?.forEach((obj) => {
            const { date, description } = obj;

            if (mergedDescriptionMap[date]) {
              // If date exists in the map, add description to the existing value
              mergedDescriptionMap[date] += "/" + description;
            } else {
              // If date is not in the map, create a new entry
              mergedDescriptionMap[date] = description;
            }
          });

          log.debug("mergedDescriptionMap", mergedDescriptionMap, taskLogs);
          log.debug("mergedHoursMap", mergedHoursMap);
          const mergedArray = Object.keys(mergedHoursMap)?.map((date) => ({
            date,
            totalHours: mergedHoursMap[date],
            description: mergedDescriptionMap[date],
          }));
          log.debug("mergedArray", mergedArray);
          const taskDetails = {
            taskID,
            projectID: taskLogs[0]?.projectID || "",
            description: taskLogs[0]?.description || "",
            rowLogHour: logHourData,
            id: taskLogs[0]?.id || "",
            isApproved: taskLogs[0]?.isApproved || false,
            taskTitle: taskLogs[0]?.taskTitle || "",
            projectTitle: taskLogs[0]?.projectTitle || "",
            billableHours: taskLogs[0]?.billableHours || 0,
            billedUnbillableHours: taskLogs[0]?.billedUnbillableHours || 0,
            LoggedHours:
              (taskLogs[0]?.billedHours || 0) +
              (taskLogs[0]?.billedUnbillableHours || 0),
            leaveDate,
            orgHolidays,
          };
          let weekdays = initializeWeekdays(
            startDate,
            endDate,
            customOffDays[0]?.customOffDays
          );

          mergedArray?.forEach((item) => {
            const dayName = new Date(item.date).toLocaleDateString("en-US", {
              weekday: "long",
            });
            weekdays[dayName] = {
              date: item.date,
              logHours: item.totalHours,
              description: item.description,
            };
          });
          result.push({ ...taskDetails, ...weekdays });
        });

        let weekDays2 = initializeWeekdays(
          startDate,
          endDate,
          customOffDays[0]?.customOffDays
        );
        result.push({
          taskID: null,
          projectID: null,
          description: "",
          id: "uniqueId",
          isApproved: false,
          taskTitle: "",
          projectTitle: "",
          billableHours: 0,
          LoggedHours: 0,
          leaveDate,
          orgHolidays,
          ...weekDays2,
        });
        return result;
      };

      const result = calculateHoursByDateForTaskID(
        newEmployeeTaskHours,
        taskHourByMonth?.firstDay,
        taskHourByMonth?.lastDay
      );
      let filterdata = [];
      const filterDataByCustomOffDays = (entries) => {
        let defaultOffDays = [];
        if (customOffDays?.length > 0) {
          defaultOffDays = customOffDays[0]?.customOffDays;
        }

        return entries.map((entry) => {
          const modifiedEntry = {};

          Object.keys(entry).forEach((day) => {
            if (!defaultOffDays?.includes(day)) {
              modifiedEntry[day] = entry[day];
            }
          });

          return modifiedEntry;
        });
      };
      if (result) {
        filterdata = filterDataByCustomOffDays(result);
      }
      log.debug("filterdata::", filterdata, taskHourByMonth?.selectedProject);
      let finalData = [];
      if (taskHourByMonth?.selectedProject) {
        finalData = filterdata?.filter(
          (item) =>
            item?.projectID === taskHourByMonth?.selectedProject?.id ||
            item?.projectID === null
        );
      } else {
        finalData = filterdata;
      }

      log.debug("finalData::", finalData, taskHourByMonth?.selectedProject);
      if (finalData) {
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "employeeWeeklyTaskHours",
            value: finalData,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "unMergedemployeeWeeklyTaskHours",
            value: newEmployeeTaskHours,
          },

          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully Updated taskhours",
          },
        ]);
      } else {
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Failed To get taskHours",
          },
        ]);
      }
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To get taskHours",
      },
    ]);
  });

export const getEmployeeTaskHoursByTaskIdForSelectedDatesService = (context) =>
  new Promise(async (resolve, reject) => {
    let employeeTaskHours = [];
    let logHoursForDay = null;
    let employeeTaskhoursForselectedTask = [];

    const {
      taskHourByMonth,
      selectedTask,
      actionForTaskHoursCURD,
      organizationID,
    } = context;
    log.debug(
      "taskHourByMonth:getEmployeeTaskHoursByTaskIdForSelectedDatesService",
      taskHourByMonth,
      actionForTaskHoursCURD,
      selectedTask
    );
    const Leaves = await hookListLeavesByUserId(taskHourByMonth?.userID);
    const orgLogHour = getLogHourByOrgId(organizationID);
    if (Leaves) {
      const leaveForToday = Leaves?.find((leave) =>
        leave?.date?.some((date) => date === taskHourByMonth?.firstDay)
      );
      log.debug(
        "leaveForToday:getEmployeeLogHoursService",
        leaveForToday?.quantity,
        leaveForToday
      );
      if (
        leaveForToday?.quantity == "0.5" &&
        (leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusApproved ||
          leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusSubmitted)
      ) {
        logHoursForDay = orgLogHour?.halfDayWorkingHour;
      } else if (
        leaveForToday?.quantity == "1" &&
        (leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusApproved ||
          leaveForToday?.status === CONST_LEAVE_STATUS.leaveStatusSubmitted)
      ) {
        logHoursForDay = null;
        const resultData = {
          totalAllocatedHoursForDay: 0,
          totalLoggedHours: 0,
          remainingHours: 0,
        };
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "employeeTaskHoursDetils",
            value: resultData,
          },

          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Today you are on Leave",
          },
        ]);
      } else {
        logHoursForDay = orgLogHour?.workingHour;
      }
    }

    employeeTaskHours = await hookGetTaskHoursByMonthAndUserId(taskHourByMonth);
    log.debug(
      "employeeTaskHours::getEmployeeLogHoursService",
      employeeTaskHours
    );

    function calculateLoggedHours(logHoursData, targetDate, logHoursForDay) {
      log.debug("logHoursForDay:calculateLoggedHours", logHoursForDay);
      // Filter the log hours data for the given target date
      const filteredLogs = logHoursData.filter(
        (log) => log.date === targetDate
      );

      // Calculate total logged hours for the target date
      const totalLoggedHours = filteredLogs.reduce(
        (acc, log) => acc + log.totalHours,
        0
      );

      // Calculate remaining hours
      const remainingHours = logHoursForDay - totalLoggedHours;

      return {
        totalLoggedHours,
        remainingHours,
      };
    }

    if (actionForTaskHoursCURD === "EDIT") {
      employeeTaskhoursForselectedTask = await hookGetTaskHoursByTaskIdAndDates(
        selectedTask?.taskID,
        taskHourByMonth?.firstDay,
        taskHourByMonth?.lastDay
      );
      const filteredTasks = employeeTaskhoursForselectedTask?.filter(
        (task) => task?.userID === taskHourByMonth?.userID
      );

      employeeTaskhoursForselectedTask = filteredTasks;
    }
    log.debug(
      "employeeTaskhoursForselectedTask::",
      employeeTaskhoursForselectedTask
    );
    if (logHoursForDay && employeeTaskHours) {
      const result = calculateLoggedHours(
        employeeTaskHours,
        taskHourByMonth?.firstDay,
        logHoursForDay
      );

      if (result) {
        const resultData = {
          totalAllocatedHoursForDay: logHoursForDay,
          totalLoggedHours: result.totalLoggedHours,
          remainingHours: result.remainingHours,
        };

        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "employeeTaskHoursDetils",
            value: resultData,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "employeeTaskHours",
            value: employeeTaskhoursForselectedTask,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully Fetched taskhours",
          },
        ]);
      } else {
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Failed To Fetched taskHours",
          },
        ]);
      }
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Fetched taskHours",
      },
    ]);
  });

export const updateWeeklyTaskHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskHoursToBeAdded, selectedTask, employeeWeeklyTaskHours } =
      context;
    let orgHolidays = [];
    let date = taskHoursToBeAdded?.date;
    const selectedMonth = moment(date).format("YYYY-MM");
    const { firstDayOfMonth, lastDayOfMonth } =
      getFirstAndLastDateTimeOfMonth(selectedMonth);
    const firstDay = moment(firstDayOfMonth)?.format("YYYY-MM-DD");
    const lastDay = moment(lastDayOfMonth)?.format("YYYY-MM-DD");
    log.debug(
      "taskHoursToBeAdded:updateWeeklyTaskHoursDataService",
      taskHoursToBeAdded,
      selectedTask,
      date,
      selectedMonth,
      lastDay,
      firstDay
    );
    log.debug(
      "employeeWeeklyTaskHours:updateWeeklyTaskHoursDataService",
      employeeWeeklyTaskHours
    );

    const taskHours = await hookUpdateTaskHours({
      ...taskHoursToBeAdded,
    });

    let finaltotalHours = 0;

    if (taskHours) {
      const result = await hookGetTaskHoursByMonthAndTaskId(
        taskHours?.taskID,
        lastDay,
        firstDay
      );

      if (result) {
        const totalHoursArray = result?.map((item) => item?.totalHours);
        finaltotalHours = totalHoursArray?.reduce(
          (accumulator, currentTotalHours) => {
            return accumulator + currentTotalHours;
          }
        );
        log.debug(
          "taskHoursArrayforService:totalhoursArray",
          result,
          totalHoursArray,
          finaltotalHours,
          selectedTask
        );
      }
      let billedHours = finaltotalHours;
      let billedUnbillableHours = 0;
      if (finaltotalHours > selectedTask?.billableHours) {
        billedHours = selectedTask?.billableHours;
        billedUnbillableHours = finaltotalHours - selectedTask?.billableHours;
      }

      var percentage = 0;
      percentage = (billedHours * 100) / selectedTask?.billableHours;
      if (selectedTask?.billableHours === 0 && billedHours === 0) {
        percentage = 0;
      }
      if (percentage === null || undefined) {
        percentage = 0;
      }
      const dataToUpdate = {
        id: selectedTask?.taskID,
        billedHours: billedHours,
        billedUnbillableHours: billedUnbillableHours,
        percentage: percentage.toFixed(0),
      };

      log.debug(
        "taskHours:updateTaskHoursDataService",
        taskHours,
        dataToUpdate
      );
      const updateEmployeeTaskForHours = await hookUpdateEmployeeTask(
        dataToUpdate
      );
      log.debug("updateEmployeeTaskForHours::", updateEmployeeTaskForHours);
      if (updateEmployeeTaskForHours) {
        try {
          const totalHoursSum = result
            ?.filter(
              (entry) =>
                entry?.userID === taskHoursToBeAdded?.userID &&
                entry?.date === taskHoursToBeAdded?.date
            )
            .reduce((sum, entry) => sum + entry.totalHours, 0);

          log.debug("totalHoursSum:", totalHoursSum);
          const resolvedEmployeeWeeklyTaskHours = employeeWeeklyTaskHours?.map(
            (task) => {
              if (task.taskID === taskHoursToBeAdded?.taskID) {
                // Only modify the log hours for the specified task

                const updatedRowLogHour = task?.rowLogHour?.map((item) => {
                  if (item?.id === taskHours?.id) {
                    return {
                      ...item,
                      totalHours: taskHours.totalHours,
                      isApproved: null,
                    };
                  }
                  return { ...item };
                });

                const resolvedTask = {
                  LoggedHours: task?.LoggedHours,
                  Monday: task?.Monday,
                  Tuesday: task?.Tuesday,
                  Wednesday: task?.Wednesday,
                  Thursday: task?.Thursday,
                  Friday: task?.Friday,
                  Saturday: task?.Saturday,
                  Sunday: task?.Sunday,
                  billableHours: task?.billableHours,
                  billedUnbillableHours: task?.billedUnbillableHours,
                  description: task?.description ? task?.description : "",
                  id: task?.id,
                  isApproved: task?.isApprove ? task?.isApproved : "",
                  projectID: task?.projectID,
                  projectTitle: task?.projectTitle ? task?.projectTitle : "",
                  taskID: task?.taskID,
                  taskTitle: task?.taskTitle ? task?.taskTitle : "",
                  rowLogHour: updatedRowLogHour,
                  orgHolidays: employeeWeeklyTaskHours[0]?.orgHolidays
                    ? employeeWeeklyTaskHours[0]?.orgHolidays
                    : orgHolidays,
                  leaveDate: task?.leaveDate,
                };

                log.debug("resolvedTask::", resolvedTask, taskHoursToBeAdded);

                Object.keys(resolvedTask)?.forEach((day) => {
                  log.debug("day::", day);
                  if (resolvedTask[day]?.date === taskHoursToBeAdded?.date) {
                    resolvedTask[day].logHours = totalHoursSum;
                    resolvedTask.LoggedHours =
                      updateEmployeeTaskForHours?.billedHours +
                      updateEmployeeTaskForHours?.billedUnbillableHours;
                    resolvedTask.billedUnbillableHours =
                      updateEmployeeTaskForHours?.billedUnbillableHours;
                  }
                });

                return resolvedTask;
              }

              return task; // If taskID does not match, return the original task unchanged
            }
          );

          resolve([
            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
              contextField: "taskHours",
              value: taskHours,
            },

            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
              contextField: "employeeWeeklyTaskHours",
              value: resolvedEmployeeWeeklyTaskHours,
            },
            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
              contextField: "tasks",
              value: updateEmployeeTaskForHours
                ? updateEmployeeTaskForHours
                : [],
            },

            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.update,
              contextField: "message",
              value: "Successfully Updated taskhours",
            },
          ]);
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To update taskHours",
      },
    ]);
  });

export const deleteWeeklyTaskHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskHoursToBeAdded, selectedTask, employeeWeeklyTaskHours } =
      context;
    let orgHolidays = [];

    log.debug(
      "taskHoursToBeAdded:updateWeeklyTaskHoursDataService",
      taskHoursToBeAdded,
      selectedTask
    );

    const taskHours = await hookDeleteTaskHoursByTaskHoursId(
      taskHoursToBeAdded?.id
    );

    log.debug(
      "employeeWeeklyTaskHours:updateWeeklyTaskHoursDataService",
      employeeWeeklyTaskHours,
      taskHours
    );
    let finaltotalHours = 0;

    if (taskHours) {
      const result = await hookGetTaskHoursByTaskId(taskHours?.taskID);
      if (result?.length) {
        const totalHoursArray = result?.map((item) => item?.totalHours);
        finaltotalHours = totalHoursArray?.reduce(
          (accumulator, currentTotalHours) => {
            return accumulator + currentTotalHours;
          }
        );
        log.debug(
          "taskHoursArrayforService:totalhoursArray",
          result,
          totalHoursArray,
          finaltotalHours,
          selectedTask
        );
      }
      let billedHours = finaltotalHours;
      let billedUnbillableHours = 0;
      if (finaltotalHours > selectedTask?.billableHours) {
        billedHours = selectedTask?.billableHours;
        billedUnbillableHours = finaltotalHours - selectedTask?.billableHours;
      }

      const dataToUpdate = {
        id: selectedTask?.taskID,
        billedHours: billedHours,
        billedUnbillableHours: billedUnbillableHours,
      };

      log.debug(
        "taskHours:updateTaskHoursDataService",
        taskHours,
        dataToUpdate
      );
      const updateEmployeeTaskForHours = await hookUpdateEmployeeTask(
        dataToUpdate
      );
      log.debug("updateEmployeeTaskForHours::", updateEmployeeTaskForHours);
      if (updateEmployeeTaskForHours) {
        try {
          const totalHoursSum = result
            ?.filter(
              (entry) =>
                entry?.userID === taskHoursToBeAdded?.userID &&
                entry?.date === taskHoursToBeAdded?.date
            )
            .reduce((sum, entry) => sum + entry.totalHours, 0);

          log.debug("totalHoursSum:", totalHoursSum);
          const resolvedEmployeeWeeklyTaskHours = employeeWeeklyTaskHours?.map(
            (task) => {
              var updatedRowLogHour = [];
              if (task.taskID === taskHoursToBeAdded?.taskID) {
                updatedRowLogHour = task?.rowLogHour?.filter(
                  (item) => item.id !== taskHoursToBeAdded?.id
                );
              }
              if (task.taskID === taskHoursToBeAdded?.taskID) {
                // Only modify the log hours for the specified task

                const resolvedTask = {
                  LoggedHours: task?.LoggedHours,
                  Monday: task?.Monday,
                  Tuesday: task?.Tuesday,
                  Wednesday: task?.Wednesday,
                  Thursday: task?.Thursday,
                  Friday: task?.Friday,
                  Saturday: task?.Saturday,
                  Sunday: task?.Sunday,
                  billableHours: task?.billableHours,
                  billedUnbillableHours: task?.billedUnbillableHours,
                  description: task?.description ? task?.description : "",
                  id: task?.id,
                  isApproved: task?.isApprove ? task?.isApproved : "",
                  projectID: task?.projectID,
                  projectTitle: task?.projectTitle ? task?.projectTitle : "",
                  taskID: task?.taskID,
                  taskTitle: task?.taskTitle ? task?.taskTitle : "",
                  rowLogHour: updatedRowLogHour
                    ? updatedRowLogHour
                    : task?.rowLogHour
                    ? task?.rowLogHour
                    : [],
                  orgHolidays: employeeWeeklyTaskHours[0]?.orgHolidays
                    ? employeeWeeklyTaskHours[0]?.orgHolidays
                    : orgHolidays,
                  leaveDate: task?.leaveDate,
                };

                log.debug("resolvedTask::", resolvedTask, taskHoursToBeAdded);

                Object.keys(resolvedTask)?.forEach((day) => {
                  log.debug("day::", day);
                  if (resolvedTask[day]?.date === taskHoursToBeAdded?.date) {
                    resolvedTask[day].logHours = totalHoursSum;
                    resolvedTask.LoggedHours =
                      updateEmployeeTaskForHours?.billedHours +
                      updateEmployeeTaskForHours?.billedUnbillableHours;
                    resolvedTask.billedUnbillableHours =
                      updateEmployeeTaskForHours?.billedUnbillableHours;
                  }
                });

                return resolvedTask;
              }

              return task; // If taskID does not match, return the original task unchanged
            }
          );

          resolve([
            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
              contextField: "taskHours",
              value: taskHours,
            },

            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
              contextField: "employeeWeeklyTaskHours",
              value: resolvedEmployeeWeeklyTaskHours,
            },
            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
              contextField: "tasks",
              value: updateEmployeeTaskForHours
                ? updateEmployeeTaskForHours
                : [],
            },

            {
              action: CONST_UPDATE_CONTEXT_ACTIONS.update,
              contextField: "message",
              value: "Successfully Deleted taskhours",
            },
          ]);
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    }
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To Delete taskHours",
      },
    ]);
  });

export const approveAndRejectLogHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const {
      selectedLogHourForApprove,
      filterLogHourForApprove,
      isApprove,
      employeeWeeklyTaskHours,
    } = context;
    log.debug("approveAndRejectLogHoursDataService", employeeWeeklyTaskHours);
    const updateData = async (item) => {
      let DataToSend = {};

      if (isApprove) {
        DataToSend = {
          id: item?.id,
          isApproved: true,
        };
      } else {
        DataToSend = {
          id: item?.id,
          isApproved: false,
        };
      }
      try {
        const taskHours = await hookUpdateTaskHours({ ...DataToSend });

        return taskHours;
      } catch (error) {
        return {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To Approve taskHours",
        };
      }
    };

    try {
      const promises = filterLogHourForApprove?.map((item) => updateData(item));
      const results = await Promise.all(promises);
      var updateObject = {};
      if (results?.length > 0) {
        var updateHour = employeeWeeklyTaskHours?.find(
          (item) => item?.taskID == results[0]?.taskID
        );

        const updatedRowLogHour = updateHour?.rowLogHour?.map((logEntry) => {
          const matchingOtherData = results.find(
            (data) => data.id === logEntry.id
          );

          if (matchingOtherData) {
            logEntry.isApproved = matchingOtherData.isApproved;
          } else {
            logEntry.isApproved = logEntry.isApproved;
          }

          return logEntry;
        });

        updateObject = {
          ...updateHour,
          rowLogHour: updatedRowLogHour,
        };
      }
      log.debug("resultsDataForApprove", updateObject);
      if (results?.length > 0) {
        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "filterLogHourForApprove",
            value: results,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr,
            contextField: "employeeWeeklyTaskHours",
            value: updateObject,
          },
        ]);
      } else {
        reject([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Failed To Update taskHours",
          },
        ]);
      }
    } catch (error) {
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: "Failed To Update taskHours",
        },
      ]);
    }
  });

export const submitWeeklyTaskHoursDataService = (context) =>
  new Promise(async (resolve, reject) => {
    const { taskHoursToBeAdded, selectedTask, employeeWeeklyTaskHours } =
      context;
    log.debug(
      "taskHoursToBeAdded:submitWeeklyTaskHoursDataService",
      taskHoursToBeAdded,
      selectedTask,
      employeeWeeklyTaskHours
    );

    let updateEmployeeTaskForHours = [];
    let orgHolidays = [];
    const taskHours = await hookAddTaskHours({
      ...taskHoursToBeAdded,
    });
    log.debug("taskHoursToBeAdded:tasksHours", taskHours);

    let finaltotalHours;

    const result = await hookGetTaskHoursByTaskId(taskHours?.taskID);

    if (result) {
      const totalHoursArray = result?.map((item) => item?.totalHours);
      finaltotalHours = totalHoursArray?.reduce(
        (accumulator, currentTotalHours) => {
          return accumulator + currentTotalHours;
        }
      );
      log.debug(
        "taskHoursArrayforService:totalhoursArray",
        result,
        totalHoursArray,
        finaltotalHours
      );
    }

    let billedHours = 0;
    let billedUnbillableHours = 0;
    if (finaltotalHours > selectedTask?.billableHours) {
      billedHours = selectedTask?.billableHours;
      billedUnbillableHours = finaltotalHours - selectedTask?.billableHours;
    } else {
      billedHours = finaltotalHours;
    }
    var percentage = 0;
    percentage = (billedHours * 100) / selectedTask?.billableHours;
    if (selectedTask?.billableHours === 0 && billedHours === 0) {
      percentage = 0;
    }
    if (percentage === null) {
      percentage = 0;
    }
    const dataToUpdate = {
      id: selectedTask?.id,
      billedHours: billedHours,
      billedUnbillableHours: billedUnbillableHours,
      percentage: percentage.toFixed(0),
      status: CONST_TASK_STATUS.taskStatusInprogress,
    };
    log.debug("dataToupdate$", dataToUpdate);
    updateEmployeeTaskForHours = await hookUpdateEmployeeTask(dataToUpdate);
    log.debug("updateEmployeeTaskForHours::", updateEmployeeTaskForHours);

    try {
      // Find the task in the employeeWeeklyTaskHours array based on taskID
      const taskToUpdate = employeeWeeklyTaskHours.find(
        (task) => task.taskID === taskHoursToBeAdded?.taskID
      );

      if (taskToUpdate) {
        // Now, resolve all tasks in employeeWeeklyTaskHours with updated log hours
        const resolvedEmployeeWeeklyTaskHours = employeeWeeklyTaskHours?.map(
          (task) => {
            if (task.taskID === taskHoursToBeAdded?.taskID) {
              // Only modify the log hours for the specified task
              log.debug("task::", task);
              const updatedRowLogHour = [...task?.rowLogHour, taskHours];
              const resolvedTask = {
                LoggedHours: task?.LoggedHours,
                Monday: task?.Monday,
                Tuesday: task?.Tuesday,
                Wednesday: task?.Wednesday,
                Thursday: task?.Thursday,
                Friday: task?.Friday,
                Saturday: task?.Saturday,
                Sunday: task?.Sunday,
                billableHours: task?.billableHours,
                billedUnbillableHours: task?.billedUnbillableHours,
                description: task?.description ? task?.description : "",
                id: task?.id,
                isApproved: task?.isApprove ? task?.isApproved : "",
                projectID: task?.projectID,
                projectTitle: task?.projectTitle ? task?.projectTitle : "",
                taskID: task?.taskID,
                taskTitle: task?.taskTitle ? task?.taskTitle : "",
                rowLogHour: updatedRowLogHour,
                orgHolidays: employeeWeeklyTaskHours[0]?.orgHolidays
                  ? employeeWeeklyTaskHours[0]?.orgHolidays
                  : orgHolidays,
                leaveDate: task?.leaveDate,
              };

              log.debug("resolvedTask::", resolvedTask, taskHoursToBeAdded);

              Object.keys(resolvedTask)?.forEach((day) => {
                log.debug("day::", day);
                if (resolvedTask[day]?.date === taskHoursToBeAdded?.date) {
                  resolvedTask[day].logHours = parseFloat(
                    taskHoursToBeAdded?.totalHours
                  );
                  resolvedTask.billedUnbillableHours = parseFloat(
                    updateEmployeeTaskForHours?.billedUnbillableHours
                  );

                  resolvedTask.LoggedHours =
                    parseFloat(resolvedTask?.LoggedHours) +
                    parseFloat(taskHoursToBeAdded?.totalHours);
                }
              });

              return resolvedTask;
            }

            return task; // If taskID does not match, return the original task unchanged
          }
        );

        resolve([
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, // what to do
            contextField: "taskHours",
            value: taskHours,
          },

          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update, // what to do
            contextField: "employeeWeeklyTaskHours",
            value: resolvedEmployeeWeeklyTaskHours,
          },
          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.updateArr, // what to do
            contextField: "tasks",
            value: updateEmployeeTaskForHours ? updateEmployeeTaskForHours : [],
          },

          {
            action: CONST_UPDATE_CONTEXT_ACTIONS.update,
            contextField: "message",
            value: "Successfully added taskhours",
          },
        ]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Failed To add taskHours",
      },
    ]);
  });

const replaceTask = (projects, taskId, newTask) => {
  return projects.map((project) => {
    if (project.taskData) {
      const updatedTaskData = project.taskData.map((task) => {
        if (task && task.id === taskId) {
          return { ...task, ...newTask };
        }
        return task;
      });

      return { ...project, taskData: updatedTaskData };
    }

    return project;
  });
};
