/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      cognitoId
      fullName
      username
      email
      userType
      phone_number
      additionalInfo
      profilePicture
      isOnline
      location
      organizationID
      pushNotificationTokens {
        service
        deviceTokens
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      cognitoId
      fullName
      username
      email
      userType
      phone_number
      additionalInfo
      profilePicture
      isOnline
      location
      organizationID
      pushNotificationTokens {
        service
        deviceTokens
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      cognitoId
      fullName
      username
      email
      userType
      phone_number
      additionalInfo
      profilePicture
      isOnline
      location
      organizationID
      pushNotificationTokens {
        service
        deviceTokens
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalarySlip = /* GraphQL */ `
  mutation CreateSalarySlip(
    $input: CreateSalarySlipInput!
    $condition: ModelSalarySlipConditionInput
  ) {
    createSalarySlip(input: $input, condition: $condition) {
      salarySlipID
      userID
      s3key
      designation
      employeeID
      month
      dateOfJoining
      accountDetails
      location
      workedDays
      monthWorkingDays
      leavesWithPay
      leavesWithoutPay
      department
      name
      netSalary
      earningBasic
      earningHRA
      earningAllowance
      earningGross
      earningBonus
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      paymentID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalarySlip = /* GraphQL */ `
  mutation UpdateSalarySlip(
    $input: UpdateSalarySlipInput!
    $condition: ModelSalarySlipConditionInput
  ) {
    updateSalarySlip(input: $input, condition: $condition) {
      salarySlipID
      userID
      s3key
      designation
      employeeID
      month
      dateOfJoining
      accountDetails
      location
      workedDays
      monthWorkingDays
      leavesWithPay
      leavesWithoutPay
      department
      name
      netSalary
      earningBasic
      earningHRA
      earningAllowance
      earningGross
      earningBonus
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      paymentID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalarySlip = /* GraphQL */ `
  mutation DeleteSalarySlip(
    $input: DeleteSalarySlipInput!
    $condition: ModelSalarySlipConditionInput
  ) {
    deleteSalarySlip(input: $input, condition: $condition) {
      salarySlipID
      userID
      s3key
      designation
      employeeID
      month
      dateOfJoining
      accountDetails
      location
      workedDays
      monthWorkingDays
      leavesWithPay
      leavesWithoutPay
      department
      name
      netSalary
      earningBasic
      earningHRA
      earningAllowance
      earningGross
      earningBonus
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      paymentID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompensation = /* GraphQL */ `
  mutation CreateCompensation(
    $input: CreateCompensationInput!
    $condition: ModelCompensationConditionInput
  ) {
    createCompensation(input: $input, condition: $condition) {
      id
      startDate
      endDate
      earningBonus
      earningGross
      earningAllowance
      earningHRA
      earningBasic
      netSalary
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompensation = /* GraphQL */ `
  mutation UpdateCompensation(
    $input: UpdateCompensationInput!
    $condition: ModelCompensationConditionInput
  ) {
    updateCompensation(input: $input, condition: $condition) {
      id
      startDate
      endDate
      earningBonus
      earningGross
      earningAllowance
      earningHRA
      earningBasic
      netSalary
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompensation = /* GraphQL */ `
  mutation DeleteCompensation(
    $input: DeleteCompensationInput!
    $condition: ModelCompensationConditionInput
  ) {
    deleteCompensation(input: $input, condition: $condition) {
      id
      startDate
      endDate
      earningBonus
      earningGross
      earningAllowance
      earningHRA
      earningBasic
      netSalary
      deductionPF
      deductionInsurance
      deductionProfTax
      taxDeduction
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeDocument = /* GraphQL */ `
  mutation CreateEmployeeDocument(
    $input: CreateEmployeeDocumentInput!
    $condition: ModelEmployeeDocumentConditionInput
  ) {
    createEmployeeDocument(input: $input, condition: $condition) {
      id
      name
      s3key
      status
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeDocument = /* GraphQL */ `
  mutation UpdateEmployeeDocument(
    $input: UpdateEmployeeDocumentInput!
    $condition: ModelEmployeeDocumentConditionInput
  ) {
    updateEmployeeDocument(input: $input, condition: $condition) {
      id
      name
      s3key
      status
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeDocument = /* GraphQL */ `
  mutation DeleteEmployeeDocument(
    $input: DeleteEmployeeDocumentInput!
    $condition: ModelEmployeeDocumentConditionInput
  ) {
    deleteEmployeeDocument(input: $input, condition: $condition) {
      id
      name
      s3key
      status
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createForm16 = /* GraphQL */ `
  mutation CreateForm16(
    $input: CreateForm16Input!
    $condition: ModelForm16ConditionInput
  ) {
    createForm16(input: $input, condition: $condition) {
      grossSalary
      exemptions
      deductionsSection10
      netTaxableSalary
      deductionsChapterVIA
      totalIncome
      DeductedCurrentEmployer
      DeductedpreviousEmployer
      DeductedTotal
      DeductedCertificateNumber
      DeductedDateOfDeduction
      payableIncomeTax
      payableEducationCess
      payableTotalTax
      taxPaid
      taxRefund
      assessmentYear
      s3key
      userID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateForm16 = /* GraphQL */ `
  mutation UpdateForm16(
    $input: UpdateForm16Input!
    $condition: ModelForm16ConditionInput
  ) {
    updateForm16(input: $input, condition: $condition) {
      grossSalary
      exemptions
      deductionsSection10
      netTaxableSalary
      deductionsChapterVIA
      totalIncome
      DeductedCurrentEmployer
      DeductedpreviousEmployer
      DeductedTotal
      DeductedCertificateNumber
      DeductedDateOfDeduction
      payableIncomeTax
      payableEducationCess
      payableTotalTax
      taxPaid
      taxRefund
      assessmentYear
      s3key
      userID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteForm16 = /* GraphQL */ `
  mutation DeleteForm16(
    $input: DeleteForm16Input!
    $condition: ModelForm16ConditionInput
  ) {
    deleteForm16(input: $input, condition: $condition) {
      grossSalary
      exemptions
      deductionsSection10
      netTaxableSalary
      deductionsChapterVIA
      totalIncome
      DeductedCurrentEmployer
      DeductedpreviousEmployer
      DeductedTotal
      DeductedCertificateNumber
      DeductedDateOfDeduction
      payableIncomeTax
      payableEducationCess
      payableTotalTax
      taxPaid
      taxRefund
      assessmentYear
      s3key
      userID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      cognitoId
      firstName
      middleName
      LastName
      email
      personalEmail
      userType
      profilePicture
      uniqueIdentification
      PAN
      dateOfBirth
      dateOfHire
      yearlyWage
      contactNo
      workLocation
      workHoursPerWeek
      emergencyContact1
      emergencyContact1Name
      emergencyContact1Relation
      emergencyContact2
      emergencyContact2Name
      emergencyContact2Relation
      permanentAddress
      currentAddress
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      noOfCOffLeaves
      noOfCOffLeavesTaken
      noOfCOffLeavesRemaining
      currentCompensationID
      accountDetails
      employeeStatus
      designation
      employeeID
      dateOjJoining
      location
      department
      guideID
      managers
      employees
      organizationID
      reimbursements {
        nextToken
        __typename
      }
      form16s {
        nextToken
        __typename
      }
      documentList {
        nextToken
        __typename
      }
      attendance {
        nextToken
        __typename
      }
      salarySlips {
        nextToken
        __typename
      }
      goals {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      inventory {
        nextToken
        __typename
      }
      leaves {
        nextToken
        __typename
      }
      skillSets
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      cognitoId
      firstName
      middleName
      LastName
      email
      personalEmail
      userType
      profilePicture
      uniqueIdentification
      PAN
      dateOfBirth
      dateOfHire
      yearlyWage
      contactNo
      workLocation
      workHoursPerWeek
      emergencyContact1
      emergencyContact1Name
      emergencyContact1Relation
      emergencyContact2
      emergencyContact2Name
      emergencyContact2Relation
      permanentAddress
      currentAddress
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      noOfCOffLeaves
      noOfCOffLeavesTaken
      noOfCOffLeavesRemaining
      currentCompensationID
      accountDetails
      employeeStatus
      designation
      employeeID
      dateOjJoining
      location
      department
      guideID
      managers
      employees
      organizationID
      reimbursements {
        nextToken
        __typename
      }
      form16s {
        nextToken
        __typename
      }
      documentList {
        nextToken
        __typename
      }
      attendance {
        nextToken
        __typename
      }
      salarySlips {
        nextToken
        __typename
      }
      goals {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      inventory {
        nextToken
        __typename
      }
      leaves {
        nextToken
        __typename
      }
      skillSets
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      cognitoId
      firstName
      middleName
      LastName
      email
      personalEmail
      userType
      profilePicture
      uniqueIdentification
      PAN
      dateOfBirth
      dateOfHire
      yearlyWage
      contactNo
      workLocation
      workHoursPerWeek
      emergencyContact1
      emergencyContact1Name
      emergencyContact1Relation
      emergencyContact2
      emergencyContact2Name
      emergencyContact2Relation
      permanentAddress
      currentAddress
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      noOfCOffLeaves
      noOfCOffLeavesTaken
      noOfCOffLeavesRemaining
      currentCompensationID
      accountDetails
      employeeStatus
      designation
      employeeID
      dateOjJoining
      location
      department
      guideID
      managers
      employees
      organizationID
      reimbursements {
        nextToken
        __typename
      }
      form16s {
        nextToken
        __typename
      }
      documentList {
        nextToken
        __typename
      }
      attendance {
        nextToken
        __typename
      }
      salarySlips {
        nextToken
        __typename
      }
      goals {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      inventory {
        nextToken
        __typename
      }
      leaves {
        nextToken
        __typename
      }
      skillSets
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeLeaveHistory = /* GraphQL */ `
  mutation CreateEmployeeLeaveHistory(
    $input: CreateEmployeeLeaveHistoryInput!
    $condition: ModelEmployeeLeaveHistoryConditionInput
  ) {
    createEmployeeLeaveHistory(input: $input, condition: $condition) {
      userID
      year
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeLeaveHistory = /* GraphQL */ `
  mutation UpdateEmployeeLeaveHistory(
    $input: UpdateEmployeeLeaveHistoryInput!
    $condition: ModelEmployeeLeaveHistoryConditionInput
  ) {
    updateEmployeeLeaveHistory(input: $input, condition: $condition) {
      userID
      year
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeLeaveHistory = /* GraphQL */ `
  mutation DeleteEmployeeLeaveHistory(
    $input: DeleteEmployeeLeaveHistoryInput!
    $condition: ModelEmployeeLeaveHistoryConditionInput
  ) {
    deleteEmployeeLeaveHistory(input: $input, condition: $condition) {
      userID
      year
      noOfLeaves
      noOfLeavesTaken
      noOfLeavesRemaining
      noOfMedicalLeaves
      noOfMedicalLeavesTaken
      noOfMedicalLeavesRemaining
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      organizationID
      uniqueName
      name
      description
      tagline
      lattitude
      longitude
      addressLine1
      addressLine2
      gstNumber
      contactNumber
      city
      activePlan
      logoBig
      logoSmall
      brandLogoBig
      brandLogoSmall
      primaryColor
      secondaryColor
      menuBGImage
      date
      salarySlipTemplateKey
      offerLetterTemplateKey
      payments {
        nextToken
        __typename
      }
      allowedDocumentsToUpload
      noOfLeaves
      noOfMedicalLeaves
      allowPastLeaves
      attendanceFrom
      allowTeamLeadToAddTask
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      organizationID
      uniqueName
      name
      description
      tagline
      lattitude
      longitude
      addressLine1
      addressLine2
      gstNumber
      contactNumber
      city
      activePlan
      logoBig
      logoSmall
      brandLogoBig
      brandLogoSmall
      primaryColor
      secondaryColor
      menuBGImage
      date
      salarySlipTemplateKey
      offerLetterTemplateKey
      payments {
        nextToken
        __typename
      }
      allowedDocumentsToUpload
      noOfLeaves
      noOfMedicalLeaves
      allowPastLeaves
      attendanceFrom
      allowTeamLeadToAddTask
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      organizationID
      uniqueName
      name
      description
      tagline
      lattitude
      longitude
      addressLine1
      addressLine2
      gstNumber
      contactNumber
      city
      activePlan
      logoBig
      logoSmall
      brandLogoBig
      brandLogoSmall
      primaryColor
      secondaryColor
      menuBGImage
      date
      salarySlipTemplateKey
      offerLetterTemplateKey
      payments {
        nextToken
        __typename
      }
      allowedDocumentsToUpload
      noOfLeaves
      noOfMedicalLeaves
      allowPastLeaves
      attendanceFrom
      allowTeamLeadToAddTask
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgDomains = /* GraphQL */ `
  mutation CreateOrgDomains(
    $input: CreateOrgDomainsInput!
    $condition: ModelOrgDomainsConditionInput
  ) {
    createOrgDomains(input: $input, condition: $condition) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgDomains = /* GraphQL */ `
  mutation UpdateOrgDomains(
    $input: UpdateOrgDomainsInput!
    $condition: ModelOrgDomainsConditionInput
  ) {
    updateOrgDomains(input: $input, condition: $condition) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgDomains = /* GraphQL */ `
  mutation DeleteOrgDomains(
    $input: DeleteOrgDomainsInput!
    $condition: ModelOrgDomainsConditionInput
  ) {
    deleteOrgDomains(input: $input, condition: $condition) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPolicyDocument = /* GraphQL */ `
  mutation CreatePolicyDocument(
    $input: CreatePolicyDocumentInput!
    $condition: ModelPolicyDocumentConditionInput
  ) {
    createPolicyDocument(input: $input, condition: $condition) {
      organizationID
      employeeHandbookS3Key
      employeeLeavesPolicyS3Key
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePolicyDocument = /* GraphQL */ `
  mutation UpdatePolicyDocument(
    $input: UpdatePolicyDocumentInput!
    $condition: ModelPolicyDocumentConditionInput
  ) {
    updatePolicyDocument(input: $input, condition: $condition) {
      organizationID
      employeeHandbookS3Key
      employeeLeavesPolicyS3Key
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePolicyDocument = /* GraphQL */ `
  mutation DeletePolicyDocument(
    $input: DeletePolicyDocumentInput!
    $condition: ModelPolicyDocumentConditionInput
  ) {
    deletePolicyDocument(input: $input, condition: $condition) {
      organizationID
      employeeHandbookS3Key
      employeeLeavesPolicyS3Key
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgYearLeaves = /* GraphQL */ `
  mutation CreateOrgYearLeaves(
    $input: CreateOrgYearLeavesInput!
    $condition: ModelOrgYearLeavesConditionInput
  ) {
    createOrgYearLeaves(input: $input, condition: $condition) {
      organizationID
      year
      startDate
      endDate
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgYearLeaves = /* GraphQL */ `
  mutation UpdateOrgYearLeaves(
    $input: UpdateOrgYearLeavesInput!
    $condition: ModelOrgYearLeavesConditionInput
  ) {
    updateOrgYearLeaves(input: $input, condition: $condition) {
      organizationID
      year
      startDate
      endDate
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgYearLeaves = /* GraphQL */ `
  mutation DeleteOrgYearLeaves(
    $input: DeleteOrgYearLeavesInput!
    $condition: ModelOrgYearLeavesConditionInput
  ) {
    deleteOrgYearLeaves(input: $input, condition: $condition) {
      organizationID
      year
      startDate
      endDate
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgLeave = /* GraphQL */ `
  mutation CreateOrgLeave(
    $input: CreateOrgLeaveInput!
    $condition: ModelOrgLeaveConditionInput
  ) {
    createOrgLeave(input: $input, condition: $condition) {
      organizationID
      orgYearLeavesID
      date
      occasion
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgLeave = /* GraphQL */ `
  mutation UpdateOrgLeave(
    $input: UpdateOrgLeaveInput!
    $condition: ModelOrgLeaveConditionInput
  ) {
    updateOrgLeave(input: $input, condition: $condition) {
      organizationID
      orgYearLeavesID
      date
      occasion
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgLeave = /* GraphQL */ `
  mutation DeleteOrgLeave(
    $input: DeleteOrgLeaveInput!
    $condition: ModelOrgLeaveConditionInput
  ) {
    deleteOrgLeave(input: $input, condition: $condition) {
      organizationID
      orgYearLeavesID
      date
      occasion
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgRoutes = /* GraphQL */ `
  mutation CreateOrgRoutes(
    $input: CreateOrgRoutesInput!
    $condition: ModelOrgRoutesConditionInput
  ) {
    createOrgRoutes(input: $input, condition: $condition) {
      orgName
      sales
      salesSubRoutes
      hr
      hrSubRoutes
      finance
      financeSubRoutes
      manager
      managerSubRoutes
      employee
      employeeSubRoutes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgRoutes = /* GraphQL */ `
  mutation UpdateOrgRoutes(
    $input: UpdateOrgRoutesInput!
    $condition: ModelOrgRoutesConditionInput
  ) {
    updateOrgRoutes(input: $input, condition: $condition) {
      orgName
      sales
      salesSubRoutes
      hr
      hrSubRoutes
      finance
      financeSubRoutes
      manager
      managerSubRoutes
      employee
      employeeSubRoutes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgRoutes = /* GraphQL */ `
  mutation DeleteOrgRoutes(
    $input: DeleteOrgRoutesInput!
    $condition: ModelOrgRoutesConditionInput
  ) {
    deleteOrgRoutes(input: $input, condition: $condition) {
      orgName
      sales
      salesSubRoutes
      hr
      hrSubRoutes
      finance
      financeSubRoutes
      manager
      managerSubRoutes
      employee
      employeeSubRoutes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgBusinessDays = /* GraphQL */ `
  mutation CreateOrgBusinessDays(
    $input: CreateOrgBusinessDaysInput!
    $condition: ModelOrgBusinessDaysConditionInput
  ) {
    createOrgBusinessDays(input: $input, condition: $condition) {
      organizationID
      type
      customOffDays
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgBusinessDays = /* GraphQL */ `
  mutation UpdateOrgBusinessDays(
    $input: UpdateOrgBusinessDaysInput!
    $condition: ModelOrgBusinessDaysConditionInput
  ) {
    updateOrgBusinessDays(input: $input, condition: $condition) {
      organizationID
      type
      customOffDays
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgBusinessDays = /* GraphQL */ `
  mutation DeleteOrgBusinessDays(
    $input: DeleteOrgBusinessDaysInput!
    $condition: ModelOrgBusinessDaysConditionInput
  ) {
    deleteOrgBusinessDays(input: $input, condition: $condition) {
      organizationID
      type
      customOffDays
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgPayment = /* GraphQL */ `
  mutation CreateOrgPayment(
    $input: CreateOrgPaymentInput!
    $condition: ModelOrgPaymentConditionInput
  ) {
    createOrgPayment(input: $input, condition: $condition) {
      id
      amount
      status
      createdAt
      updatedAt
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateOrgPayment = /* GraphQL */ `
  mutation UpdateOrgPayment(
    $input: UpdateOrgPaymentInput!
    $condition: ModelOrgPaymentConditionInput
  ) {
    updateOrgPayment(input: $input, condition: $condition) {
      id
      amount
      status
      createdAt
      updatedAt
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrgPayment = /* GraphQL */ `
  mutation DeleteOrgPayment(
    $input: DeleteOrgPaymentInput!
    $condition: ModelOrgPaymentConditionInput
  ) {
    deleteOrgPayment(input: $input, condition: $condition) {
      id
      amount
      status
      createdAt
      updatedAt
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createReportExport = /* GraphQL */ `
  mutation CreateReportExport(
    $input: CreateReportExportInput!
    $condition: ModelReportExportConditionInput
  ) {
    createReportExport(input: $input, condition: $condition) {
      reportExportID
      dateFrom
      dateTo
      s3Key
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReportExport = /* GraphQL */ `
  mutation UpdateReportExport(
    $input: UpdateReportExportInput!
    $condition: ModelReportExportConditionInput
  ) {
    updateReportExport(input: $input, condition: $condition) {
      reportExportID
      dateFrom
      dateTo
      s3Key
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReportExport = /* GraphQL */ `
  mutation DeleteReportExport(
    $input: DeleteReportExportInput!
    $condition: ModelReportExportConditionInput
  ) {
    deleteReportExport(input: $input, condition: $condition) {
      reportExportID
      dateFrom
      dateTo
      s3Key
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgBill = /* GraphQL */ `
  mutation CreateOrgBill(
    $input: CreateOrgBillInput!
    $condition: ModelOrgBillConditionInput
  ) {
    createOrgBill(input: $input, condition: $condition) {
      id
      amount
      status
      vendorID
      date
      createdAt
      updatedAt
      s3key
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      paymentID
      __typename
    }
  }
`;
export const updateOrgBill = /* GraphQL */ `
  mutation UpdateOrgBill(
    $input: UpdateOrgBillInput!
    $condition: ModelOrgBillConditionInput
  ) {
    updateOrgBill(input: $input, condition: $condition) {
      id
      amount
      status
      vendorID
      date
      createdAt
      updatedAt
      s3key
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      paymentID
      __typename
    }
  }
`;
export const deleteOrgBill = /* GraphQL */ `
  mutation DeleteOrgBill(
    $input: DeleteOrgBillInput!
    $condition: ModelOrgBillConditionInput
  ) {
    deleteOrgBill(input: $input, condition: $condition) {
      id
      amount
      status
      vendorID
      date
      createdAt
      updatedAt
      s3key
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      paymentID
      __typename
    }
  }
`;
export const createOrgVendor = /* GraphQL */ `
  mutation CreateOrgVendor(
    $input: CreateOrgVendorInput!
    $condition: ModelOrgVendorConditionInput
  ) {
    createOrgVendor(input: $input, condition: $condition) {
      id
      name
      vendorOrgID
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgVendor = /* GraphQL */ `
  mutation UpdateOrgVendor(
    $input: UpdateOrgVendorInput!
    $condition: ModelOrgVendorConditionInput
  ) {
    updateOrgVendor(input: $input, condition: $condition) {
      id
      name
      vendorOrgID
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgVendor = /* GraphQL */ `
  mutation DeleteOrgVendor(
    $input: DeleteOrgVendorInput!
    $condition: ModelOrgVendorConditionInput
  ) {
    deleteOrgVendor(input: $input, condition: $condition) {
      id
      name
      vendorOrgID
      organizationID
      organization {
        organizationID
        uniqueName
        name
        description
        tagline
        lattitude
        longitude
        addressLine1
        addressLine2
        gstNumber
        contactNumber
        city
        activePlan
        logoBig
        logoSmall
        brandLogoBig
        brandLogoSmall
        primaryColor
        secondaryColor
        menuBGImage
        date
        salarySlipTemplateKey
        offerLetterTemplateKey
        allowedDocumentsToUpload
        noOfLeaves
        noOfMedicalLeaves
        allowPastLeaves
        attendanceFrom
        allowTeamLeadToAddTask
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLeaveComment = /* GraphQL */ `
  mutation CreateLeaveComment(
    $input: CreateLeaveCommentInput!
    $condition: ModelLeaveCommentConditionInput
  ) {
    createLeaveComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      leaveID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLeaveComment = /* GraphQL */ `
  mutation UpdateLeaveComment(
    $input: UpdateLeaveCommentInput!
    $condition: ModelLeaveCommentConditionInput
  ) {
    updateLeaveComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      leaveID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLeaveComment = /* GraphQL */ `
  mutation DeleteLeaveComment(
    $input: DeleteLeaveCommentInput!
    $condition: ModelLeaveCommentConditionInput
  ) {
    deleteLeaveComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      leaveID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLeave = /* GraphQL */ `
  mutation CreateLeave(
    $input: CreateLeaveInput!
    $condition: ModelLeaveConditionInput
  ) {
    createLeave(input: $input, condition: $condition) {
      id
      typeOfLeave
      quantity
      appliedBy
      approvedBy
      approvedByName
      date2
      date
      title
      details
      dateTime
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLeave = /* GraphQL */ `
  mutation UpdateLeave(
    $input: UpdateLeaveInput!
    $condition: ModelLeaveConditionInput
  ) {
    updateLeave(input: $input, condition: $condition) {
      id
      typeOfLeave
      quantity
      appliedBy
      approvedBy
      approvedByName
      date2
      date
      title
      details
      dateTime
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLeave = /* GraphQL */ `
  mutation DeleteLeave(
    $input: DeleteLeaveInput!
    $condition: ModelLeaveConditionInput
  ) {
    deleteLeave(input: $input, condition: $condition) {
      id
      typeOfLeave
      quantity
      appliedBy
      approvedBy
      approvedByName
      date2
      date
      title
      details
      dateTime
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoffComment = /* GraphQL */ `
  mutation CreateCoffComment(
    $input: CreateCoffCommentInput!
    $condition: ModelCoffCommentConditionInput
  ) {
    createCoffComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      coffId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoffComment = /* GraphQL */ `
  mutation UpdateCoffComment(
    $input: UpdateCoffCommentInput!
    $condition: ModelCoffCommentConditionInput
  ) {
    updateCoffComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      coffId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoffComment = /* GraphQL */ `
  mutation DeleteCoffComment(
    $input: DeleteCoffCommentInput!
    $condition: ModelCoffCommentConditionInput
  ) {
    deleteCoffComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      coffId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoffRequest = /* GraphQL */ `
  mutation CreateCoffRequest(
    $input: CreateCoffRequestInput!
    $condition: ModelCoffRequestConditionInput
  ) {
    createCoffRequest(input: $input, condition: $condition) {
      id
      quantity
      appliedBy
      approvedBy
      approvedByName
      date
      details
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoffRequest = /* GraphQL */ `
  mutation UpdateCoffRequest(
    $input: UpdateCoffRequestInput!
    $condition: ModelCoffRequestConditionInput
  ) {
    updateCoffRequest(input: $input, condition: $condition) {
      id
      quantity
      appliedBy
      approvedBy
      approvedByName
      date
      details
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoffRequest = /* GraphQL */ `
  mutation DeleteCoffRequest(
    $input: DeleteCoffRequestInput!
    $condition: ModelCoffRequestConditionInput
  ) {
    deleteCoffRequest(input: $input, condition: $condition) {
      id
      quantity
      appliedBy
      approvedBy
      approvedByName
      date
      details
      status
      userID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskComment = /* GraphQL */ `
  mutation CreateTaskComment(
    $input: CreateTaskCommentInput!
    $condition: ModelTaskCommentConditionInput
  ) {
    createTaskComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskComment = /* GraphQL */ `
  mutation UpdateTaskComment(
    $input: UpdateTaskCommentInput!
    $condition: ModelTaskCommentConditionInput
  ) {
    updateTaskComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskComment = /* GraphQL */ `
  mutation DeleteTaskComment(
    $input: DeleteTaskCommentInput!
    $condition: ModelTaskCommentConditionInput
  ) {
    deleteTaskComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoalComment = /* GraphQL */ `
  mutation CreateGoalComment(
    $input: CreateGoalCommentInput!
    $condition: ModelGoalCommentConditionInput
  ) {
    createGoalComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      goalID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGoalComment = /* GraphQL */ `
  mutation UpdateGoalComment(
    $input: UpdateGoalCommentInput!
    $condition: ModelGoalCommentConditionInput
  ) {
    updateGoalComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      goalID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGoalComment = /* GraphQL */ `
  mutation DeleteGoalComment(
    $input: DeleteGoalCommentInput!
    $condition: ModelGoalCommentConditionInput
  ) {
    deleteGoalComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      goalID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      number
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      domain
      leadName
      teamSize
      plantForm
      customerName
      startDate
      completionDate
      poStatus
      invoiceStatus
      remark
      organizationID
      teammembers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      number
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      domain
      leadName
      teamSize
      plantForm
      customerName
      startDate
      completionDate
      poStatus
      invoiceStatus
      remark
      organizationID
      teammembers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      number
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      domain
      leadName
      teamSize
      plantForm
      customerName
      startDate
      completionDate
      poStatus
      invoiceStatus
      remark
      organizationID
      teammembers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTasks = /* GraphQL */ `
  mutation CreateTasks(
    $input: CreateTasksInput!
    $condition: ModelTasksConditionInput
  ) {
    createTasks(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTasks = /* GraphQL */ `
  mutation UpdateTasks(
    $input: UpdateTasksInput!
    $condition: ModelTasksConditionInput
  ) {
    updateTasks(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTasks = /* GraphQL */ `
  mutation DeleteTasks(
    $input: DeleteTasksInput!
    $condition: ModelTasksConditionInput
  ) {
    deleteTasks(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      status
      actualCompletedDate
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskHours = /* GraphQL */ `
  mutation CreateTaskHours(
    $input: CreateTaskHoursInput!
    $condition: ModelTaskHoursConditionInput
  ) {
    createTaskHours(input: $input, condition: $condition) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskHours = /* GraphQL */ `
  mutation UpdateTaskHours(
    $input: UpdateTaskHoursInput!
    $condition: ModelTaskHoursConditionInput
  ) {
    updateTaskHours(input: $input, condition: $condition) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskHours = /* GraphQL */ `
  mutation DeleteTaskHours(
    $input: DeleteTaskHoursInput!
    $condition: ModelTaskHoursConditionInput
  ) {
    deleteTaskHours(input: $input, condition: $condition) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReimbursement = /* GraphQL */ `
  mutation CreateReimbursement(
    $input: CreateReimbursementInput!
    $condition: ModelReimbursementConditionInput
  ) {
    createReimbursement(input: $input, condition: $condition) {
      id
      title
      content
      dueDate
      amount
      status
      s3key
      date
      organizationID
      userID
      paymentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReimbursement = /* GraphQL */ `
  mutation UpdateReimbursement(
    $input: UpdateReimbursementInput!
    $condition: ModelReimbursementConditionInput
  ) {
    updateReimbursement(input: $input, condition: $condition) {
      id
      title
      content
      dueDate
      amount
      status
      s3key
      date
      organizationID
      userID
      paymentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReimbursement = /* GraphQL */ `
  mutation DeleteReimbursement(
    $input: DeleteReimbursementInput!
    $condition: ModelReimbursementConditionInput
  ) {
    deleteReimbursement(input: $input, condition: $condition) {
      id
      title
      content
      dueDate
      amount
      status
      s3key
      date
      organizationID
      userID
      paymentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInventoryComment = /* GraphQL */ `
  mutation CreateInventoryComment(
    $input: CreateInventoryCommentInput!
    $condition: ModelInventoryCommentConditionInput
  ) {
    createInventoryComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      InventoryID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInventoryComment = /* GraphQL */ `
  mutation UpdateInventoryComment(
    $input: UpdateInventoryCommentInput!
    $condition: ModelInventoryCommentConditionInput
  ) {
    updateInventoryComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      InventoryID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInventoryComment = /* GraphQL */ `
  mutation DeleteInventoryComment(
    $input: DeleteInventoryCommentInput!
    $condition: ModelInventoryCommentConditionInput
  ) {
    deleteInventoryComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      InventoryID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      id
      userID
      date
      assignedToName
      assignedBy
      assignedByName
      title
      content
      serialNo
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      id
      userID
      date
      assignedToName
      assignedBy
      assignedByName
      title
      content
      serialNo
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      id
      userID
      date
      assignedToName
      assignedBy
      assignedByName
      title
      content
      serialNo
      organizationID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSprint = /* GraphQL */ `
  mutation CreateSprint(
    $input: CreateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    createSprint(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      status
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSprint = /* GraphQL */ `
  mutation UpdateSprint(
    $input: UpdateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    updateSprint(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      status
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSprint = /* GraphQL */ `
  mutation DeleteSprint(
    $input: DeleteSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    deleteSprint(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      status
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeTask = /* GraphQL */ `
  mutation CreateEmployeeTask(
    $input: CreateEmployeeTaskInput!
    $condition: ModelEmployeeTaskConditionInput
  ) {
    createEmployeeTask(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      status
      percentage
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      sprint
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeTask = /* GraphQL */ `
  mutation UpdateEmployeeTask(
    $input: UpdateEmployeeTaskInput!
    $condition: ModelEmployeeTaskConditionInput
  ) {
    updateEmployeeTask(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      status
      percentage
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      sprint
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeTask = /* GraphQL */ `
  mutation DeleteEmployeeTask(
    $input: DeleteEmployeeTaskInput!
    $condition: ModelEmployeeTaskConditionInput
  ) {
    deleteEmployeeTask(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      status
      percentage
      billableHours
      billedHours
      unbillableHours
      billedUnbillableHours
      projectID
      organizationID
      sprint
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeTaskHours = /* GraphQL */ `
  mutation CreateEmployeeTaskHours(
    $input: CreateEmployeeTaskHoursInput!
    $condition: ModelEmployeeTaskHoursConditionInput
  ) {
    createEmployeeTaskHours(input: $input, condition: $condition) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeTaskHours = /* GraphQL */ `
  mutation UpdateEmployeeTaskHours(
    $input: UpdateEmployeeTaskHoursInput!
    $condition: ModelEmployeeTaskHoursConditionInput
  ) {
    updateEmployeeTaskHours(input: $input, condition: $condition) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeTaskHours = /* GraphQL */ `
  mutation DeleteEmployeeTaskHours(
    $input: DeleteEmployeeTaskHoursInput!
    $condition: ModelEmployeeTaskHoursConditionInput
  ) {
    deleteEmployeeTaskHours(input: $input, condition: $condition) {
      userID
      startTime
      endTime
      date
      description
      totalHours
      isApproved
      taskID
      projectID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      percentage
      status
      specific
      measurable
      attainable
      relevant
      timely
      year
      organizationID
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      percentage
      status
      specific
      measurable
      attainable
      relevant
      timely
      year
      organizationID
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      assignedTo
      assignedBy
      title
      content
      dueDate
      actualCompletedDate
      percentage
      status
      specific
      measurable
      attainable
      relevant
      timely
      year
      organizationID
      userID
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAttendance = /* GraphQL */ `
  mutation CreateAttendance(
    $input: CreateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    createAttendance(input: $input, condition: $condition) {
      id
      userID
      employeeId
      emailId
      date
      day
      description
      timeIn1
      TimeOut1
      location {
        lat
        lon
        __typename
      }
      timeIn2
      TimeOut2
      totalHours
      isApproved
      workType
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAttendance = /* GraphQL */ `
  mutation UpdateAttendance(
    $input: UpdateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    updateAttendance(input: $input, condition: $condition) {
      id
      userID
      employeeId
      emailId
      date
      day
      description
      timeIn1
      TimeOut1
      location {
        lat
        lon
        __typename
      }
      timeIn2
      TimeOut2
      totalHours
      isApproved
      workType
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAttendance = /* GraphQL */ `
  mutation DeleteAttendance(
    $input: DeleteAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    deleteAttendance(input: $input, condition: $condition) {
      id
      userID
      employeeId
      emailId
      date
      day
      description
      timeIn1
      TimeOut1
      location {
        lat
        lon
        __typename
      }
      timeIn2
      TimeOut2
      totalHours
      isApproved
      workType
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeCourse = /* GraphQL */ `
  mutation CreateEmployeeCourse(
    $input: CreateEmployeeCourseInput!
    $condition: ModelEmployeeCourseConditionInput
  ) {
    createEmployeeCourse(input: $input, condition: $condition) {
      id
      organizationID
      userID
      courseID
      completedLessons
      completedTopics
      completedTopicResources
      progress
      completed
      estimatedCompletionDate
      actualCompletionDate
      testID
      resultPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeCourse = /* GraphQL */ `
  mutation UpdateEmployeeCourse(
    $input: UpdateEmployeeCourseInput!
    $condition: ModelEmployeeCourseConditionInput
  ) {
    updateEmployeeCourse(input: $input, condition: $condition) {
      id
      organizationID
      userID
      courseID
      completedLessons
      completedTopics
      completedTopicResources
      progress
      completed
      estimatedCompletionDate
      actualCompletionDate
      testID
      resultPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeCourse = /* GraphQL */ `
  mutation DeleteEmployeeCourse(
    $input: DeleteEmployeeCourseInput!
    $condition: ModelEmployeeCourseConditionInput
  ) {
    deleteEmployeeCourse(input: $input, condition: $condition) {
      id
      organizationID
      userID
      courseID
      completedLessons
      completedTopics
      completedTopicResources
      progress
      completed
      estimatedCompletionDate
      actualCompletionDate
      testID
      resultPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      description
      active
      categories
      image
      instructor
      organizationID
      lessons {
        nextToken
        __typename
      }
      totalTopicResources
      testIDs
      passPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      name
      description
      active
      categories
      image
      instructor
      organizationID
      lessons {
        nextToken
        __typename
      }
      totalTopicResources
      testIDs
      passPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name
      description
      active
      categories
      image
      instructor
      organizationID
      lessons {
        nextToken
        __typename
      }
      totalTopicResources
      testIDs
      passPercentage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      courseID
      dateTime
      name
      description
      topics {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      courseID
      dateTime
      name
      description
      topics {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      courseID
      dateTime
      name
      description
      topics {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      lessonID
      dateTime
      name
      notes
      resources {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      lessonID
      dateTime
      name
      notes
      resources {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      lessonID
      dateTime
      name
      notes
      resources {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTopicResource = /* GraphQL */ `
  mutation CreateTopicResource(
    $input: CreateTopicResourceInput!
    $condition: ModelTopicResourceConditionInput
  ) {
    createTopicResource(input: $input, condition: $condition) {
      id
      topicID
      name
      dateTime
      description
      resourceKey
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTopicResource = /* GraphQL */ `
  mutation UpdateTopicResource(
    $input: UpdateTopicResourceInput!
    $condition: ModelTopicResourceConditionInput
  ) {
    updateTopicResource(input: $input, condition: $condition) {
      id
      topicID
      name
      dateTime
      description
      resourceKey
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTopicResource = /* GraphQL */ `
  mutation DeleteTopicResource(
    $input: DeleteTopicResourceInput!
    $condition: ModelTopicResourceConditionInput
  ) {
    deleteTopicResource(input: $input, condition: $condition) {
      id
      topicID
      name
      dateTime
      description
      resourceKey
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      title
      jobType
      isActive
      description
      mustHaveSkills
      niceToHaveSkills
      questionPaperID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      title
      jobType
      isActive
      description
      mustHaveSkills
      niceToHaveSkills
      questionPaperID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      title
      jobType
      isActive
      description
      mustHaveSkills
      niceToHaveSkills
      questionPaperID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCandidateDocument = /* GraphQL */ `
  mutation CreateCandidateDocument(
    $input: CreateCandidateDocumentInput!
    $condition: ModelCandidateDocumentConditionInput
  ) {
    createCandidateDocument(input: $input, condition: $condition) {
      id
      name
      s3key
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCandidateDocument = /* GraphQL */ `
  mutation UpdateCandidateDocument(
    $input: UpdateCandidateDocumentInput!
    $condition: ModelCandidateDocumentConditionInput
  ) {
    updateCandidateDocument(input: $input, condition: $condition) {
      id
      name
      s3key
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCandidateDocument = /* GraphQL */ `
  mutation DeleteCandidateDocument(
    $input: DeleteCandidateDocumentInput!
    $condition: ModelCandidateDocumentConditionInput
  ) {
    deleteCandidateDocument(input: $input, condition: $condition) {
      id
      name
      s3key
      approvedBy
      uploadDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      cognitoId
      firstName
      middleName
      lastName
      personalEmail
      uniqueIdentification
      contactNo
      expectedCTC
      aboutMe
      dateOfBirth
      preferredLocation
      permanentAddress
      currentAddress
      s3resume
      documentList {
        nextToken
        __typename
      }
      appliedJobs {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      cognitoId
      firstName
      middleName
      lastName
      personalEmail
      uniqueIdentification
      contactNo
      expectedCTC
      aboutMe
      dateOfBirth
      preferredLocation
      permanentAddress
      currentAddress
      s3resume
      documentList {
        nextToken
        __typename
      }
      appliedJobs {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      cognitoId
      firstName
      middleName
      lastName
      personalEmail
      uniqueIdentification
      contactNo
      expectedCTC
      aboutMe
      dateOfBirth
      preferredLocation
      permanentAddress
      currentAddress
      s3resume
      documentList {
        nextToken
        __typename
      }
      appliedJobs {
        id
        resume
        status
        dateApplied
        onlineTestScore
        writtenTestResult
        interviewResult
        hrInterviewResult
        contactNo
        expectedSalary
        onlineTestDateTime
        writtenTestDateTime
        interviewTestDateTime
        hrInterviewDateTime
        offerID
        questionPaperID
        jobID
        candidateID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJobOffer = /* GraphQL */ `
  mutation CreateJobOffer(
    $input: CreateJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    createJobOffer(input: $input, condition: $condition) {
      dateOfJoining
      dateOfOffer
      probationPeriod
      costToCompany
      basicSalary
      allowance
      hra
      professionalTax
      providentFund
      insurance
      gratuity
      annualBonus
      candidateID
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJobOffer = /* GraphQL */ `
  mutation UpdateJobOffer(
    $input: UpdateJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    updateJobOffer(input: $input, condition: $condition) {
      dateOfJoining
      dateOfOffer
      probationPeriod
      costToCompany
      basicSalary
      allowance
      hra
      professionalTax
      providentFund
      insurance
      gratuity
      annualBonus
      candidateID
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJobOffer = /* GraphQL */ `
  mutation DeleteJobOffer(
    $input: DeleteJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    deleteJobOffer(input: $input, condition: $condition) {
      dateOfJoining
      dateOfOffer
      probationPeriod
      costToCompany
      basicSalary
      allowance
      hra
      professionalTax
      providentFund
      insurance
      gratuity
      annualBonus
      candidateID
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestionPaper = /* GraphQL */ `
  mutation CreateQuestionPaper(
    $input: CreateQuestionPaperInput!
    $condition: ModelQuestionPaperConditionInput
  ) {
    createQuestionPaper(input: $input, condition: $condition) {
      id
      friendlyName
      addedBy
      approvedBy
      paperType
      questionIds
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuestionPaper = /* GraphQL */ `
  mutation UpdateQuestionPaper(
    $input: UpdateQuestionPaperInput!
    $condition: ModelQuestionPaperConditionInput
  ) {
    updateQuestionPaper(input: $input, condition: $condition) {
      id
      friendlyName
      addedBy
      approvedBy
      paperType
      questionIds
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuestionPaper = /* GraphQL */ `
  mutation DeleteQuestionPaper(
    $input: DeleteQuestionPaperInput!
    $condition: ModelQuestionPaperConditionInput
  ) {
    deleteQuestionPaper(input: $input, condition: $condition) {
      id
      friendlyName
      addedBy
      approvedBy
      paperType
      questionIds
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      options
      answer
      type
      position
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      options
      answer
      type
      position
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      options
      answer
      type
      position
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAnswers = /* GraphQL */ `
  mutation CreateAnswers(
    $input: CreateAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    createAnswers(input: $input, condition: $condition) {
      questionId
      answers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAnswers = /* GraphQL */ `
  mutation UpdateAnswers(
    $input: UpdateAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    updateAnswers(input: $input, condition: $condition) {
      questionId
      answers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAnswers = /* GraphQL */ `
  mutation DeleteAnswers(
    $input: DeleteAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    deleteAnswers(input: $input, condition: $condition) {
      questionId
      answers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJobApplication = /* GraphQL */ `
  mutation CreateJobApplication(
    $input: CreateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    createJobApplication(input: $input, condition: $condition) {
      id
      candidate {
        cognitoId
        firstName
        middleName
        lastName
        personalEmail
        uniqueIdentification
        contactNo
        expectedCTC
        aboutMe
        dateOfBirth
        preferredLocation
        permanentAddress
        currentAddress
        s3resume
        createdAt
        updatedAt
        __typename
      }
      job {
        id
        title
        jobType
        isActive
        description
        mustHaveSkills
        niceToHaveSkills
        questionPaperID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      resume
      status
      dateApplied
      onlineTestScore
      writtenTestResult
      interviewResult
      hrInterviewResult
      contactNo
      expectedSalary
      onlineTestDateTime
      writtenTestDateTime
      interviewTestDateTime
      hrInterviewDateTime
      offer {
        dateOfJoining
        dateOfOffer
        probationPeriod
        costToCompany
        basicSalary
        allowance
        hra
        professionalTax
        providentFund
        insurance
        gratuity
        annualBonus
        candidateID
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      offerID
      questionPaperID
      jobID
      candidateID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJobApplication = /* GraphQL */ `
  mutation UpdateJobApplication(
    $input: UpdateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    updateJobApplication(input: $input, condition: $condition) {
      id
      candidate {
        cognitoId
        firstName
        middleName
        lastName
        personalEmail
        uniqueIdentification
        contactNo
        expectedCTC
        aboutMe
        dateOfBirth
        preferredLocation
        permanentAddress
        currentAddress
        s3resume
        createdAt
        updatedAt
        __typename
      }
      job {
        id
        title
        jobType
        isActive
        description
        mustHaveSkills
        niceToHaveSkills
        questionPaperID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      resume
      status
      dateApplied
      onlineTestScore
      writtenTestResult
      interviewResult
      hrInterviewResult
      contactNo
      expectedSalary
      onlineTestDateTime
      writtenTestDateTime
      interviewTestDateTime
      hrInterviewDateTime
      offer {
        dateOfJoining
        dateOfOffer
        probationPeriod
        costToCompany
        basicSalary
        allowance
        hra
        professionalTax
        providentFund
        insurance
        gratuity
        annualBonus
        candidateID
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      offerID
      questionPaperID
      jobID
      candidateID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJobApplication = /* GraphQL */ `
  mutation DeleteJobApplication(
    $input: DeleteJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    deleteJobApplication(input: $input, condition: $condition) {
      id
      candidate {
        cognitoId
        firstName
        middleName
        lastName
        personalEmail
        uniqueIdentification
        contactNo
        expectedCTC
        aboutMe
        dateOfBirth
        preferredLocation
        permanentAddress
        currentAddress
        s3resume
        createdAt
        updatedAt
        __typename
      }
      job {
        id
        title
        jobType
        isActive
        description
        mustHaveSkills
        niceToHaveSkills
        questionPaperID
        organizationID
        createdAt
        updatedAt
        __typename
      }
      resume
      status
      dateApplied
      onlineTestScore
      writtenTestResult
      interviewResult
      hrInterviewResult
      contactNo
      expectedSalary
      onlineTestDateTime
      writtenTestDateTime
      interviewTestDateTime
      hrInterviewDateTime
      offer {
        dateOfJoining
        dateOfOffer
        probationPeriod
        costToCompany
        basicSalary
        allowance
        hra
        professionalTax
        providentFund
        insurance
        gratuity
        annualBonus
        candidateID
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      offerID
      questionPaperID
      jobID
      candidateID
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      contactemailID
      contactFirstName
      contactLastName
      contactEmail
      contactPhoneNo
      email
      phone
      website
      notes
      billingAddress
      shippingAddress
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      contactemailID
      contactFirstName
      contactLastName
      contactEmail
      contactPhoneNo
      email
      phone
      website
      notes
      billingAddress
      shippingAddress
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      contactemailID
      contactFirstName
      contactLastName
      contactEmail
      contactPhoneNo
      email
      phone
      website
      notes
      billingAddress
      shippingAddress
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEstimate = /* GraphQL */ `
  mutation CreateEstimate(
    $input: CreateEstimateInput!
    $condition: ModelEstimateConditionInput
  ) {
    createEstimate(input: $input, condition: $condition) {
      id
      estimateNumber
      createDate
      expiryDate
      customerID
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      estimateItems {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEstimate = /* GraphQL */ `
  mutation UpdateEstimate(
    $input: UpdateEstimateInput!
    $condition: ModelEstimateConditionInput
  ) {
    updateEstimate(input: $input, condition: $condition) {
      id
      estimateNumber
      createDate
      expiryDate
      customerID
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      estimateItems {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEstimate = /* GraphQL */ `
  mutation DeleteEstimate(
    $input: DeleteEstimateInput!
    $condition: ModelEstimateConditionInput
  ) {
    deleteEstimate(input: $input, condition: $condition) {
      id
      estimateNumber
      createDate
      expiryDate
      customerID
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      estimateItems {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEstimateItem = /* GraphQL */ `
  mutation CreateEstimateItem(
    $input: CreateEstimateItemInput!
    $condition: ModelEstimateItemConditionInput
  ) {
    createEstimateItem(input: $input, condition: $condition) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      productServiceID
      EstimateID
      description
      quantity
      price
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      taxFloat
      taxString
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEstimateItem = /* GraphQL */ `
  mutation UpdateEstimateItem(
    $input: UpdateEstimateItemInput!
    $condition: ModelEstimateItemConditionInput
  ) {
    updateEstimateItem(input: $input, condition: $condition) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      productServiceID
      EstimateID
      description
      quantity
      price
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      taxFloat
      taxString
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEstimateItem = /* GraphQL */ `
  mutation DeleteEstimateItem(
    $input: DeleteEstimateItemInput!
    $condition: ModelEstimateItemConditionInput
  ) {
    deleteEstimateItem(input: $input, condition: $condition) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      productServiceID
      EstimateID
      description
      quantity
      price
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      taxFloat
      taxString
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductService = /* GraphQL */ `
  mutation CreateProductService(
    $input: CreateProductServiceInput!
    $condition: ModelProductServiceConditionInput
  ) {
    createProductService(input: $input, condition: $condition) {
      id
      name
      price
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductService = /* GraphQL */ `
  mutation UpdateProductService(
    $input: UpdateProductServiceInput!
    $condition: ModelProductServiceConditionInput
  ) {
    updateProductService(input: $input, condition: $condition) {
      id
      name
      price
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductService = /* GraphQL */ `
  mutation DeleteProductService(
    $input: DeleteProductServiceInput!
    $condition: ModelProductServiceConditionInput
  ) {
    deleteProductService(input: $input, condition: $condition) {
      id
      name
      price
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      paymentID
      amount
      paymentDate
      paymentMethod
      status
      memo
      s3key
      direction
      fromTo
      transactionID
      vendorClientEmployeeId
      paymentTowards
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      paymentID
      amount
      paymentDate
      paymentMethod
      status
      memo
      s3key
      direction
      fromTo
      transactionID
      vendorClientEmployeeId
      paymentTowards
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      paymentID
      amount
      paymentDate
      paymentMethod
      status
      memo
      s3key
      direction
      fromTo
      transactionID
      vendorClientEmployeeId
      paymentTowards
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      invoiceNumber
      createDate
      dueDate
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      customerID
      paymentID
      organizationID
      payment {
        paymentID
        amount
        paymentDate
        paymentMethod
        status
        memo
        s3key
        direction
        fromTo
        transactionID
        vendorClientEmployeeId
        paymentTowards
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      customerSideNo
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      invoiceItems {
        id
        invoiceNumber
        productServiceID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      invoiceNumber
      createDate
      dueDate
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      customerID
      paymentID
      organizationID
      payment {
        paymentID
        amount
        paymentDate
        paymentMethod
        status
        memo
        s3key
        direction
        fromTo
        transactionID
        vendorClientEmployeeId
        paymentTowards
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      customerSideNo
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      invoiceItems {
        id
        invoiceNumber
        productServiceID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      invoiceNumber
      createDate
      dueDate
      customer {
        id
        name
        contactemailID
        contactFirstName
        contactLastName
        contactEmail
        contactPhoneNo
        email
        phone
        website
        notes
        billingAddress
        shippingAddress
        organizationID
        createdAt
        updatedAt
        __typename
      }
      customerID
      paymentID
      organizationID
      payment {
        paymentID
        amount
        paymentDate
        paymentMethod
        status
        memo
        s3key
        direction
        fromTo
        transactionID
        vendorClientEmployeeId
        paymentTowards
        organizationID
        id
        createdAt
        updatedAt
        __typename
      }
      customerSideNo
      subheading
      summary
      projectName
      description
      footer
      notes
      memo
      currency
      subtotal
      invoiceItems {
        id
        invoiceNumber
        productServiceID
        description
        quantity
        price
        taxFloat
        taxString
        createdAt
        updatedAt
        __typename
      }
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTax = /* GraphQL */ `
  mutation CreateTax(
    $input: CreateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    createTax(input: $input, condition: $condition) {
      id
      name
      percentage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTax = /* GraphQL */ `
  mutation UpdateTax(
    $input: UpdateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    updateTax(input: $input, condition: $condition) {
      id
      name
      percentage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTax = /* GraphQL */ `
  mutation DeleteTax(
    $input: DeleteTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    deleteTax(input: $input, condition: $condition) {
      id
      name
      percentage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvoiceItem = /* GraphQL */ `
  mutation CreateInvoiceItem(
    $input: CreateInvoiceItemInput!
    $condition: ModelInvoiceItemConditionInput
  ) {
    createInvoiceItem(input: $input, condition: $condition) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      invoiceNumber
      productServiceID
      description
      quantity
      price
      taxFloat
      taxString
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvoiceItem = /* GraphQL */ `
  mutation UpdateInvoiceItem(
    $input: UpdateInvoiceItemInput!
    $condition: ModelInvoiceItemConditionInput
  ) {
    updateInvoiceItem(input: $input, condition: $condition) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      invoiceNumber
      productServiceID
      description
      quantity
      price
      taxFloat
      taxString
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvoiceItem = /* GraphQL */ `
  mutation DeleteInvoiceItem(
    $input: DeleteInvoiceItemInput!
    $condition: ModelInvoiceItemConditionInput
  ) {
    deleteInvoiceItem(input: $input, condition: $condition) {
      id
      productservice {
        id
        name
        price
        description
        organizationID
        createdAt
        updatedAt
        __typename
      }
      invoiceNumber
      productServiceID
      description
      quantity
      price
      taxFloat
      taxString
      tax {
        id
        name
        percentage
        organizationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnquiryForm = /* GraphQL */ `
  mutation CreateEnquiryForm(
    $input: CreateEnquiryFormInput!
    $condition: ModelEnquiryFormConditionInput
  ) {
    createEnquiryForm(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      companyName
      companyWebsite
      contactNumber
      expectedBudged
      expectedBudgedString
      leadOrigin
      date
      message
      industries
      services
      s3filekey
      sendNDA
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnquiryForm = /* GraphQL */ `
  mutation UpdateEnquiryForm(
    $input: UpdateEnquiryFormInput!
    $condition: ModelEnquiryFormConditionInput
  ) {
    updateEnquiryForm(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      companyName
      companyWebsite
      contactNumber
      expectedBudged
      expectedBudgedString
      leadOrigin
      date
      message
      industries
      services
      s3filekey
      sendNDA
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnquiryForm = /* GraphQL */ `
  mutation DeleteEnquiryForm(
    $input: DeleteEnquiryFormInput!
    $condition: ModelEnquiryFormConditionInput
  ) {
    deleteEnquiryForm(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      companyName
      companyWebsite
      contactNumber
      expectedBudged
      expectedBudgedString
      leadOrigin
      date
      message
      industries
      services
      s3filekey
      sendNDA
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeetingSlot = /* GraphQL */ `
  mutation CreateMeetingSlot(
    $input: CreateMeetingSlotInput!
    $condition: ModelMeetingSlotConditionInput
  ) {
    createMeetingSlot(input: $input, condition: $condition) {
      id
      organizationID
      addedBy
      date
      startTime
      endTime
      title
      clientFirstName
      clientLastName
      clientEmail
      website
      companyName
      contactNo
      requirements
      relevants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeetingSlot = /* GraphQL */ `
  mutation UpdateMeetingSlot(
    $input: UpdateMeetingSlotInput!
    $condition: ModelMeetingSlotConditionInput
  ) {
    updateMeetingSlot(input: $input, condition: $condition) {
      id
      organizationID
      addedBy
      date
      startTime
      endTime
      title
      clientFirstName
      clientLastName
      clientEmail
      website
      companyName
      contactNo
      requirements
      relevants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeetingSlot = /* GraphQL */ `
  mutation DeleteMeetingSlot(
    $input: DeleteMeetingSlotInput!
    $condition: ModelMeetingSlotConditionInput
  ) {
    deleteMeetingSlot(input: $input, condition: $condition) {
      id
      organizationID
      addedBy
      date
      startTime
      endTime
      title
      clientFirstName
      clientLastName
      clientEmail
      website
      companyName
      contactNo
      requirements
      relevants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactTag = /* GraphQL */ `
  mutation CreateContactTag(
    $input: CreateContactTagInput!
    $condition: ModelContactTagConditionInput
  ) {
    createContactTag(input: $input, condition: $condition) {
      id
      label
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactTag = /* GraphQL */ `
  mutation UpdateContactTag(
    $input: UpdateContactTagInput!
    $condition: ModelContactTagConditionInput
  ) {
    updateContactTag(input: $input, condition: $condition) {
      id
      label
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactTag = /* GraphQL */ `
  mutation DeleteContactTag(
    $input: DeleteContactTagInput!
    $condition: ModelContactTagConditionInput
  ) {
    deleteContactTag(input: $input, condition: $condition) {
      id
      label
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      email
      firstName
      lastName
      designation
      country
      contactOffice
      contactMobile
      contactMobile2
      email2
      companyName
      companyWebsite
      status
      industries
      customerID
      profilePicture
      s3businessCardKey
      assignedTo
      notes
      contactNotes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      deals {
        nextToken
        __typename
      }
      linkedinLink
      lnkedIsConnected
      twitterLink
      instagramLink
      socialLink
      organizationID
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      email
      firstName
      lastName
      designation
      country
      contactOffice
      contactMobile
      contactMobile2
      email2
      companyName
      companyWebsite
      status
      industries
      customerID
      profilePicture
      s3businessCardKey
      assignedTo
      notes
      contactNotes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      deals {
        nextToken
        __typename
      }
      linkedinLink
      lnkedIsConnected
      twitterLink
      instagramLink
      socialLink
      organizationID
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      email
      firstName
      lastName
      designation
      country
      contactOffice
      contactMobile
      contactMobile2
      email2
      companyName
      companyWebsite
      status
      industries
      customerID
      profilePicture
      s3businessCardKey
      assignedTo
      notes
      contactNotes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      deals {
        nextToken
        __typename
      }
      linkedinLink
      lnkedIsConnected
      twitterLink
      instagramLink
      socialLink
      organizationID
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactNote = /* GraphQL */ `
  mutation CreateContactNote(
    $input: CreateContactNoteInput!
    $condition: ModelContactNoteConditionInput
  ) {
    createContactNote(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      contactID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactNote = /* GraphQL */ `
  mutation UpdateContactNote(
    $input: UpdateContactNoteInput!
    $condition: ModelContactNoteConditionInput
  ) {
    updateContactNote(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      contactID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactNote = /* GraphQL */ `
  mutation DeleteContactNote(
    $input: DeleteContactNoteInput!
    $condition: ModelContactNoteConditionInput
  ) {
    deleteContactNote(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      contactID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalesTaskComment = /* GraphQL */ `
  mutation CreateSalesTaskComment(
    $input: CreateSalesTaskCommentInput!
    $condition: ModelSalesTaskCommentConditionInput
  ) {
    createSalesTaskComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalesTaskComment = /* GraphQL */ `
  mutation UpdateSalesTaskComment(
    $input: UpdateSalesTaskCommentInput!
    $condition: ModelSalesTaskCommentConditionInput
  ) {
    updateSalesTaskComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalesTaskComment = /* GraphQL */ `
  mutation DeleteSalesTaskComment(
    $input: DeleteSalesTaskCommentInput!
    $condition: ModelSalesTaskCommentConditionInput
  ) {
    deleteSalesTaskComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      taskID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalesTask = /* GraphQL */ `
  mutation CreateSalesTask(
    $input: CreateSalesTaskInput!
    $condition: ModelSalesTaskConditionInput
  ) {
    createSalesTask(input: $input, condition: $condition) {
      id
      forEmail
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalesTask = /* GraphQL */ `
  mutation UpdateSalesTask(
    $input: UpdateSalesTaskInput!
    $condition: ModelSalesTaskConditionInput
  ) {
    updateSalesTask(input: $input, condition: $condition) {
      id
      forEmail
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalesTask = /* GraphQL */ `
  mutation DeleteSalesTask(
    $input: DeleteSalesTaskInput!
    $condition: ModelSalesTaskConditionInput
  ) {
    deleteSalesTask(input: $input, condition: $condition) {
      id
      forEmail
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalesDealComment = /* GraphQL */ `
  mutation CreateSalesDealComment(
    $input: CreateSalesDealCommentInput!
    $condition: ModelSalesDealCommentConditionInput
  ) {
    createSalesDealComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      dealID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalesDealComment = /* GraphQL */ `
  mutation UpdateSalesDealComment(
    $input: UpdateSalesDealCommentInput!
    $condition: ModelSalesDealCommentConditionInput
  ) {
    updateSalesDealComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      dealID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalesDealComment = /* GraphQL */ `
  mutation DeleteSalesDealComment(
    $input: DeleteSalesDealCommentInput!
    $condition: ModelSalesDealCommentConditionInput
  ) {
    deleteSalesDealComment(input: $input, condition: $condition) {
      id
      content
      addedBy
      addedByName
      dateTime
      dealID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalesDeal = /* GraphQL */ `
  mutation CreateSalesDeal(
    $input: CreateSalesDealInput!
    $condition: ModelSalesDealConditionInput
  ) {
    createSalesDeal(input: $input, condition: $condition) {
      id
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      proposalS3Key
      estimateID
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalesDeal = /* GraphQL */ `
  mutation UpdateSalesDeal(
    $input: UpdateSalesDealInput!
    $condition: ModelSalesDealConditionInput
  ) {
    updateSalesDeal(input: $input, condition: $condition) {
      id
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      proposalS3Key
      estimateID
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalesDeal = /* GraphQL */ `
  mutation DeleteSalesDeal(
    $input: DeleteSalesDealInput!
    $condition: ModelSalesDealConditionInput
  ) {
    deleteSalesDeal(input: $input, condition: $condition) {
      id
      assignedTo
      title
      content
      dueDate
      actualCompletedDate
      status
      proposalS3Key
      estimateID
      comments {
        nextToken
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscribersNew = /* GraphQL */ `
  mutation CreateSubscribersNew(
    $input: CreateSubscribersNewInput!
    $condition: ModelSubscribersNewConditionInput
  ) {
    createSubscribersNew(input: $input, condition: $condition) {
      name
      email
      emailCopy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscribersNew = /* GraphQL */ `
  mutation UpdateSubscribersNew(
    $input: UpdateSubscribersNewInput!
    $condition: ModelSubscribersNewConditionInput
  ) {
    updateSubscribersNew(input: $input, condition: $condition) {
      name
      email
      emailCopy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscribersNew = /* GraphQL */ `
  mutation DeleteSubscribersNew(
    $input: DeleteSubscribersNewInput!
    $condition: ModelSubscribersNewConditionInput
  ) {
    deleteSubscribersNew(input: $input, condition: $condition) {
      name
      email
      emailCopy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProfitAndLossReport = /* GraphQL */ `
  mutation CreateProfitAndLossReport(
    $input: CreateProfitAndLossReportInput!
    $condition: ModelProfitAndLossReportConditionInput
  ) {
    createProfitAndLossReport(input: $input, condition: $condition) {
      profitAndLossReportID
      dateFrom
      dateTo
      income
      bills
      payrools
      grossProfit
      operatingExpense
      netProfit
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProfitAndLossReport = /* GraphQL */ `
  mutation UpdateProfitAndLossReport(
    $input: UpdateProfitAndLossReportInput!
    $condition: ModelProfitAndLossReportConditionInput
  ) {
    updateProfitAndLossReport(input: $input, condition: $condition) {
      profitAndLossReportID
      dateFrom
      dateTo
      income
      bills
      payrools
      grossProfit
      operatingExpense
      netProfit
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProfitAndLossReport = /* GraphQL */ `
  mutation DeleteProfitAndLossReport(
    $input: DeleteProfitAndLossReportInput!
    $condition: ModelProfitAndLossReportConditionInput
  ) {
    deleteProfitAndLossReport(input: $input, condition: $condition) {
      profitAndLossReportID
      dateFrom
      dateTo
      income
      bills
      payrools
      grossProfit
      operatingExpense
      netProfit
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBalanceSheetReport = /* GraphQL */ `
  mutation CreateBalanceSheetReport(
    $input: CreateBalanceSheetReportInput!
    $condition: ModelBalanceSheetReportConditionInput
  ) {
    createBalanceSheetReport(input: $input, condition: $condition) {
      balanceSheetReportID
      asOfDate
      assets
      cashAndBank
      cashAndBankFields
      cashAndBankValues
      currentAssets
      currentAssetsFields
      currentAssetsValues
      fixedAssets
      fixedAssetsFields
      fixedAssetsValues
      liabilities
      currentLiabilities
      currentLiabilitiesFields
      currentLiabilitiesValues
      lognTermLiabilities
      lognTermLiabilitiesFields
      lognTermLiabilitiesValues
      equity
      retainedEarnings
      retainedEarningsFields
      retainedEarningsValues
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBalanceSheetReport = /* GraphQL */ `
  mutation UpdateBalanceSheetReport(
    $input: UpdateBalanceSheetReportInput!
    $condition: ModelBalanceSheetReportConditionInput
  ) {
    updateBalanceSheetReport(input: $input, condition: $condition) {
      balanceSheetReportID
      asOfDate
      assets
      cashAndBank
      cashAndBankFields
      cashAndBankValues
      currentAssets
      currentAssetsFields
      currentAssetsValues
      fixedAssets
      fixedAssetsFields
      fixedAssetsValues
      liabilities
      currentLiabilities
      currentLiabilitiesFields
      currentLiabilitiesValues
      lognTermLiabilities
      lognTermLiabilitiesFields
      lognTermLiabilitiesValues
      equity
      retainedEarnings
      retainedEarningsFields
      retainedEarningsValues
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBalanceSheetReport = /* GraphQL */ `
  mutation DeleteBalanceSheetReport(
    $input: DeleteBalanceSheetReportInput!
    $condition: ModelBalanceSheetReportConditionInput
  ) {
    deleteBalanceSheetReport(input: $input, condition: $condition) {
      balanceSheetReportID
      asOfDate
      assets
      cashAndBank
      cashAndBankFields
      cashAndBankValues
      currentAssets
      currentAssetsFields
      currentAssetsValues
      fixedAssets
      fixedAssetsFields
      fixedAssetsValues
      liabilities
      currentLiabilities
      currentLiabilitiesFields
      currentLiabilitiesValues
      lognTermLiabilities
      lognTermLiabilitiesFields
      lognTermLiabilitiesValues
      equity
      retainedEarnings
      retainedEarningsFields
      retainedEarningsValues
      organizationID
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgDocumentSequence = /* GraphQL */ `
  mutation CreateOrgDocumentSequence(
    $input: CreateOrgDocumentSequenceInput!
    $condition: ModelOrgDocumentSequenceConditionInput
  ) {
    createOrgDocumentSequence(input: $input, condition: $condition) {
      organizationID
      invoiceSequenceNumber
      estimateSequenceNumber
      proposalSequenceNumber
      salarySlipSequenceNumber
      form16SequenceNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgDocumentSequence = /* GraphQL */ `
  mutation UpdateOrgDocumentSequence(
    $input: UpdateOrgDocumentSequenceInput!
    $condition: ModelOrgDocumentSequenceConditionInput
  ) {
    updateOrgDocumentSequence(input: $input, condition: $condition) {
      organizationID
      invoiceSequenceNumber
      estimateSequenceNumber
      proposalSequenceNumber
      salarySlipSequenceNumber
      form16SequenceNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgDocumentSequence = /* GraphQL */ `
  mutation DeleteOrgDocumentSequence(
    $input: DeleteOrgDocumentSequenceInput!
    $condition: ModelOrgDocumentSequenceConditionInput
  ) {
    deleteOrgDocumentSequence(input: $input, condition: $condition) {
      organizationID
      invoiceSequenceNumber
      estimateSequenceNumber
      proposalSequenceNumber
      salarySlipSequenceNumber
      form16SequenceNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSkillSet = /* GraphQL */ `
  mutation CreateSkillSet(
    $input: CreateSkillSetInput!
    $condition: ModelSkillSetConditionInput
  ) {
    createSkillSet(input: $input, condition: $condition) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSkillSet = /* GraphQL */ `
  mutation UpdateSkillSet(
    $input: UpdateSkillSetInput!
    $condition: ModelSkillSetConditionInput
  ) {
    updateSkillSet(input: $input, condition: $condition) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSkillSet = /* GraphQL */ `
  mutation DeleteSkillSet(
    $input: DeleteSkillSetInput!
    $condition: ModelSkillSetConditionInput
  ) {
    deleteSkillSet(input: $input, condition: $condition) {
      id
      label
      description
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      slug
      type
      content
      summary
      state
      seotitle
      seodescription
      seokeywords
      imageLink
      ogImage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      slug
      type
      content
      summary
      state
      seotitle
      seodescription
      seokeywords
      imageLink
      ogImage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      slug
      type
      content
      summary
      state
      seotitle
      seodescription
      seokeywords
      imageLink
      ogImage
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
