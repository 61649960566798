import TasksList from "../../components/employee/Tasks/TasksList";
import { useEmployeeContextState } from "../../hooks/employeeMachineHooks";
import React from "react";
import AddEditDeleteTask from "../../components/employee/Tasks/AddEditDeleteTask";
import log from "loglevel";
const Tasks = () => {
  const { taskCrud } = useEmployeeContextState();
  log.debug("taskCrud", taskCrud);
  return (
    <div className="h-full md:rounded-sm lg:rounded-md m-4">
      
      <TasksList />
      <> {taskCrud ? <AddEditDeleteTask /> : <></>}</>
    </div>
  );
};

export default Tasks;
