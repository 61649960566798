import React, { useEffect } from "react";
import log from "loglevel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Message({
  message: { messageText, messageType },
  setMessage,
}) {
  useEffect(() => {
    /* setTimeout(() => {
      setMessage({ messageText: '', messageType: '' })
    }, 1500)*/
    log.debug("Message", messageText);
  }, [messageText]);

  return (
    <div
      className={classNames(
        messageType === "success" ? "text-blue-600" : "",
        messageType === "failure" ? "text-red-600" : "",
        "text-left"
      )}
    >
      {messageText}
    </div>
  );
}
