import React, { useState, useEffect } from "react";
import log from "loglevel";
import { GetSignedURL } from "./../../../hooks/s3Hooks";
import { S3SignedPDFViewer } from "./S3SignedPDFViewer";

const S3FileViewer = (s3Key) => {
  const { url } = s3Key;
  log.debug("s3Key::", s3Key, url);
  const [fileType, setFileType] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null);

  useEffect(() => {
    const getFileType = () => {
      const ext = url.split(".").pop().toLowerCase();

      switch (ext) {
        case "mp4":
          return "video/mp4";
        case "pdf":
          return "application/pdf";
        case "png":
          return "image/png";
        case "jpg":
          return "image/jpg";
        case "jpeg":
          return "image/jpeg";
        default:
          return null;
      }
    };

    const fetchSignedUrl = async () => {
      try {
        const signedurl = await GetSignedURL(url);
        setSignedUrl(signedurl);
        setFileType(getFileType());
      } catch (error) {
        console.error(error);
      }
    };

    fetchSignedUrl();
  }, [s3Key]);

  if (!signedUrl || !fileType) {
    return <div>Loading...</div>;
  }

  switch (fileType) {
    case "video/mp4":
      return <video src={signedUrl} controls />;
    case "application/pdf":
      return <S3SignedPDFViewer s3SignedUrl={signedUrl} />;
    case "image/png":
    case "image/jpg":
    case "image/jpeg":
      return <img src={signedUrl} alt={s3Key} />;
    default:
      return <div>Unsupported file type</div>;
  }
};

export default S3FileViewer;
