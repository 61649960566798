import log from "loglevel";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { API } from "aws-amplify";

export const hookAddPolicyDocument = async (PolicyDocument) => {
  log.debug(" hookAddPolicyDocument : ", PolicyDocument);

  try {
    const result = await API.graphql({
      query: mutations.createPolicyDocument,
      variables: {
        input: PolicyDocument,
      },
    });

    log.debug(" hookAddPolicyDocument result: ", result);

    return result?.data?.createPolicyDocument;
  } catch (err) {
    log.debug("CATCH ERROR hookAddPolicyDocument result: ", err);
    return null;
  }
};

export const hookUpdatePolicyDocument = async (PolicyDocument) => {
  log.debug("hookUpdatePolicyDocument: ", PolicyDocument);
  try {
    const result = await API.graphql({
      query: mutations.updatePolicyDocument,
      variables: {
        input: PolicyDocument,
      },
    });

    log.debug(" hookUpdatePolicyDocument result: ", result);
    return result?.data?.updatePolicyDocument;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdatePolicyDocument result: ", err);
  }
};

export const hookDeletePolicyDocumentByPolicyDocumentId = async (
  PolicyDocumentId
) => {
  log.debug(" hookDeletePolicyDocumentByPolicyDocumentId  ", PolicyDocumentId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deletePolicyDocument,
      variables: {
        input: { id: PolicyDocumentId },
      },
    });
    log.debug(" hookDeletePolicyDocumentByPolicyDocumentId : ", data);
    if (data !== null) return data?.data?.deletePolicyDocument;
    else return null;
  } catch (e) {
    console.error(
      "CATCH ERROR hookDeletePolicyDocumentByPolicyDocumentUniqueID -- ",
      e
    );
    return null;
  }
};

export const hookGetPolicyDocumentByPolicyDocumentId = async (
  PolicyDocumentId
) => {
  log.debug("  hookGetPolicyDocumentByPolicyDocumentId ", PolicyDocumentId);

  try {
    const data = await API.graphql({
      query: queries.getPolicyDocument,
      variables: {
        input: { id: PolicyDocumentId },
      },
    });

    log.debug(" hookGetPolicyDocumentByPolicyDocumentId : ", data);
    if (data !== null) return data?.data?.getPolicyDocument;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetPolicyDocumentByPolicyDocumentId -- ", e);
    return null;
  }
};
export const hookListPolicyDocumentsByOrgId = async (orgId) => {
  log.debug("hookListPolicyDocumentsByOrgId OrgId", orgId);
  try {
    const data = await API.graphql({
      query: queries.PolicyDocuemntsByOrganizationID,
      variables: {
        organizationID: orgId,
      },
    });
    log.debug(" hookListPolicyDocumentsByOrgId : ", data);
    if (data !== null) return data?.data?.PolicyDocuemntsByOrganizationID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListPolicyDocumentsByOrgId -- ", e);
    return null;
  }
};
