import log from "loglevel";
import moment from "moment";

export function getCurrentAndLastMonth() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const lastMonth = new Date(today.getFullYear(), currentMonth - 1, 1);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Add 1 because getMonth() returns a 0-based index
    return `${year}-${month.toString().padStart(2, "0")}-01`;
  };

  const months = [
    { month: formatDate(today), monthName: getMonthName(currentMonth) },
    {
      month: formatDate(lastMonth),
      monthName: getMonthName(lastMonth.getMonth()),
    },
  ];

  return months;
}
export function getWorkingDaysInMonth(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const numDays = new Date(year, month + 1, 0).getDate();

  let workingDays = 0;
  for (let day = 1; day <= numDays; day++) {
    const currentDate = new Date(year, month, day);

    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      workingDays++;
    }
  }

  return workingDays;
}
export function calculateHalfDaysAndFullDays(Attendance) {
  log.debug("Attendance:calculateHalfDaysAndFullDays", Attendance);
  let numHalfDays = 0;
  let numFullDays = 0;
  let totalWorkedDays = 0;

  for (let i = 0; i < Attendance.length; i++) {
    let totalHours = Attendance[i].totalHours;

    if (totalHours) {
      totalHours = totalHours.toString().replace(/\s/g, "").split("hr");

      let hours = parseInt(totalHours[0]);
      let minutes = parseInt(totalHours[1]);

      if (minutes >= 30) {
        hours++;
      }

      if (hours >= 4) {
        numFullDays++;
        totalWorkedDays += 1;
      } else {
        numHalfDays++;
        totalWorkedDays += 0.5;
      }
    }
  }

  log.debug(
    "numHalfDays::numFullDays",
    numFullDays,
    numHalfDays,
    totalWorkedDays
  );

  return {
    numHalfDays: numHalfDays,
    numFullDays: numFullDays,
  };
}
export function getMonthName(monthIndex) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return months[monthIndex];
}

export function getFirstAndLastDateTimeOfMonth(dateParam) {
  // Parse the input date parameter
  const [year, month] = dateParam?.split("-").map(Number);

  // Create a new date object for the first day of the month
  const firstDayOfMonth = new Date(year, month - 1, 1);

  // Create a new date object for the last day of the month
  const lastDayOfMonth = new Date(year, month, 0);

  // Set the time to the beginning and end of the day respectively
  firstDayOfMonth.setHours(0, 0, 0, 0);
  lastDayOfMonth.setHours(23, 59, 59, 999);

  // Return an object containing the first and last date time of the month
  return { firstDayOfMonth, lastDayOfMonth };
}
export function isDateBetween(startDate, endDate, dateToCheck) {
  // log.debug("IS BDAT EBETWEEN", startDate, dateToCheck, endDate);
  // Convert the input dates to timestamps
  const startTimestamp = new Date(startDate).getTime();
  const endTimestamp = new Date(endDate).getTime();
  const dateToCheckTimestamp = new Date(dateToCheck).getTime();

  // Check if the date to check is between the start and end dates
  return (
    dateToCheckTimestamp >= startTimestamp &&
    dateToCheckTimestamp <= endTimestamp
  );
}

export function getFirstAndLastDateTimeOfWeekArray(arr) {
  return { firstDay: arr[0], lastDay: arr[arr.length - 1] };
}

export function getWorkingDaysOfWeek(weekType) {
  // Get the current date
  var currentDate = new Date();

  // Calculate the first day and last day of the specified week
  var firstDay, lastDay;
  if (weekType === "currentWeek") {
    // Calculate the first day of the current week (Monday)
    firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() + 1
    );

    // Calculate the last day of the current week (Friday)
    lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() + 5
    );
  } else if (weekType === "lastWeek") {
    // Calculate the first day of the last week (Monday)
    firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() - 6
    );

    // Calculate the last day of the last week (Friday)
    lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() - 2
    );
  } else {
    throw new Error(
      "Invalid weekType. Please provide 'currentWeek' or 'lastWeek'."
    );
  }

  // Initialize an array to store the working days
  var workingDays = [];

  // Iterate from the first day to the last day of the specified week
  var currentDay = firstDay;
  while (currentDay <= lastDay) {
    // Check if the current day is a working day (Monday to Friday)
    if (currentDay.getDay() >= 1 && currentDay.getDay() <= 5) {
      workingDays.push(new Date(currentDay.getTime()));
    }

    // Move to the next day
    currentDay.setDate(currentDay.getDate() + 1);
  }
  workingDays[workingDays.length - 1] = new Date(
    workingDays[workingDays.length - 1].setHours(23)
  );

  return workingDays;
}

export function getFinancialYearDates(month, finYear) {
  let year = parseInt(finYear);
  // Convert input month to 0-based index (January is 0, February is 1, and so on)
  const inputMonthIndex = month - 1;

  // If the input month is January (0), the financial year starts from the previous year
  const financialYearStartYear = inputMonthIndex === 0 ? year - 1 : year;

  // The financial year starts on the 1st of the input month
  const financialYearStartDate = new Date(
    financialYearStartYear - 1,
    inputMonthIndex,
    1
  );

  // The financial year ends on the last day of the month before the input month
  const financialYearEndDate = new Date(year, inputMonthIndex, 0);

  return {
    start: financialYearStartDate,
    end: financialYearEndDate,
  };
}

export function getMonthIndexFromAbbreviation(monthAbbreviation) {
  const months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthIndex = months.indexOf(monthAbbreviation);

  return monthIndex;
}

export function convertToMMMYY(yyyyMM) {
  const [year, month] = yyyyMM.split("-");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const shortMonth = monthNames[Number(month) - 1];
  const shortYear = year.slice(-2);
  return `${shortMonth} ${shortYear}`;
}

export function getMonthNumber(month) {
  const monthMap = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  return monthMap[month];
}

export function convertToAWSDateTime(date, startTime, endTime) {
  // Combine date and startTime strings to form startDateTime
  const startDateTimeStr = `${date}T${startTime}:00`;
  const endDateTimeStr = `${date}T${endTime}:00`;
  console.log("START", startDateTimeStr, "\nEND", endDateTimeStr);
  // Create local datetime objects
  const localStartDt = new Date(startDateTimeStr);
  const localEndDt = new Date(endDateTimeStr);
  console.log("LSTART", localStartDt, "\nLEND", localEndDt);

  // Format UTC datetime objects as AWSDate and AWSTime
  const awsStartDate = localStartDt.toISOString().substr(0, 10);
  const awsStartTime = localStartDt.toISOString().substr(11, 8);
  const awsEndTime = localEndDt.toISOString().substr(11, 8);

  return {
    awsStartDate,
    awsStartTime,
    awsEndTime,
  };
}
function convertTo24HourFormat(time12Hour) {
  const [time, period] = time12Hour.split(" ");
  let [hours, minutes, seconds] = time.split(":").map(Number);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

export function convertUTCLocalDateTime(date, startTime, endTime) {
  if (!date) return {};
  // Combine date and startTime strings to form startDateTime
  const startDateTimeStr = `${date}T${startTime}Z`;
  const endDateTimeStr = `${date}T${endTime}Z`;

  const startTimeDt = new Date(startDateTimeStr);
  const endTimeDt = new Date(endDateTimeStr);

  // Format UTC datetime objects as AWSDate and AWSTime
  const localStartDate = moment(startTimeDt).format("yyyy-MM-DD");
  let localStartTime = startTimeDt.toLocaleTimeString();
  let localEndTime = endTimeDt.toLocaleTimeString();

  localStartTime = convertTo24HourFormat(localStartTime);
  localEndTime = convertTo24HourFormat(localEndTime);
  return {
    localStartDate,
    localStartTime,
    localEndTime,
  };
}

export const formatTime = (time, date) => {
  const [hour, minute] = time.split(":");
  const dateTime = `${date}T${hour}:${minute}:00Z`;
  return moment(new Date(dateTime)).format("LT");
};

export const getFirstAndLastDayOfCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth(); // Note that the month is 0-indexed

  // Get the first day of the current month
  const firstDay = new Date(year, month, 1);
  const formattedFirstDay = formatDate(firstDay); // Format the first day

  // Get the last day of the current month
  const lastDay = new Date(year, month + 1, 0);
  const formattedLastDay = formatDate(lastDay); // Format the last day

  return { firstDay: formattedFirstDay, lastDay: formattedLastDay };
};

// Helper function to format the date as 'YYYY-MM-DD'
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // Months are 0-indexed, so we add 1 to get the actual month
  const day = now.getDate();

  // Format the date as a string
  const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;

  return formattedDate;
};

export const getFirstAndLastDayOfCurrentWeek = () => {
  const now = new Date();
  const currentDay = now.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  const diff = now.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // Adjust for Sunday
  const firstDay = new Date(now.setDate(diff));
  const formattedFirstDay = formatDate(firstDay);
  const lastDay = new Date(now.setDate(diff + 6));
  const formattedLastDay = formatDate(lastDay);
  return { firstDay: formattedFirstDay, lastDay: formattedLastDay };
};

export const getWeekRange = (mondayDateString) => {
  // Extract the day, month, and year components from the input string
  const day = parseInt(mondayDateString.substring(0, 2));
  const month = parseInt(mondayDateString.substring(3, 5)) - 1;
  const year = 2000 + parseInt(mondayDateString.substring(6, 8));
  log.debug("day,month,year", day, month, year);
  // Create a Date object using the extracted components
  const mondayDate = new Date(year, month, day);
  log.debug("mondayDate", mondayDate);
  // Calculate the difference between the input day and the Sunday of the same week
  const daysUntilSunday = (7 - mondayDate.getDay()) % 7; // Calculate days until next Sunday
  const nextSundayDate = new Date(mondayDate);
  nextSundayDate.setDate(mondayDate.getDate() + daysUntilSunday);
  log.debug("nextSundayDate", nextSundayDate);
  // Format the next Sunday date as a string in the desired format (DD-MM-YY)
  const formattedNextSunday = formatWeekDate(nextSundayDate);
  const formattedMonday = formatWeekDate(mondayDate);

  // Return the next Sunday date
  return {
    firstDay: formattedMonday,
    lastDay: formattedNextSunday,
  };
};
function formatWeekDate(date) {
  log.debug("date::formatWeekDate", date);
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const getDayName = (date) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[date.getDay()];
};

export const initializeWeekdays = (startDate, endDate) => {
  const weekdays = {};
  let weekDate = [];
  let weekDataForDay = [];

  const currentDatestr = new Date(startDate);
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(currentDatestr);
    currentDate?.setDate(currentDatestr.getDate() + i);
    const dateString = currentDate.toISOString().slice(0, 10);

    weekDate?.push(dateString);

    const dayString = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate.getDay()];

    weekDataForDay?.push(dayString);
  }

  weekDataForDay?.forEach((dayName, index) => {
    weekdays[dayName] = {
      date: weekDate[index],
      logHours: 0,
    };
  });
  log.debug("weekdays:data service", weekdays);
  return weekdays;
};
