import { Fragment, useState } from "react";
import log from "loglevel";
import { RadioGroup } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function RadioGroupMulti(props) {
  const {
    title,
    list,
    label,
    setSelected,
    selected,
    nameField = "name",
  } = props;

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label>{title}</RadioGroup.Label>
      <div className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2">
        {list?.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting.value}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                settingIdx === setting.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "z-10 border-indigo-200 bg-indigo-50"
                  : "border-gray-200",
                "relative flex cursor-pointer border p-4 focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "border-transparent bg-indigo-600"
                      : "border-gray-300 bg-white",
                    active ? "ring-indigo-500 " : "",
                    "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                  )}
                  aria-hidden="true"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-background" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-900" : "text-foreground",
                      "block text-sm font-medium"
                    )}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-700" : "text-foreground",
                      "block text-sm"
                    )}
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
