import { Fragment, useState } from "react";
import log from "loglevel";
import { Dialog, Transition } from "@headlessui/react";

import {
  useEmployeeContextData,
  useEmployeeContextService,
} from "../../../../hooks/employeeMachineHooks";

import { Button } from "../../../CustomComponentsLibrary/Button";

import { X } from "lucide-react";
import { DataTable } from "../../../CustomComponentsLibrary/data-table";
import { Checkbox } from "../../../CustomComponentsLibrary/Checkbox";
import FormatDateAndTime from "../../../common/FormatDateAndTime";

export default function ApproveRejectLogHour({ approve, onClose, setApprove }) {
  const employeeMachineService = useEmployeeContextService();
  const { selectedLogHourForApprove } = useEmployeeContextData();
  const [rowSelected, setRowSelected] = useState({});

  log.debug("ApproveRejectLogHour", rowSelected);

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            log.debug("selecetd Tixck", value, row, row.getIsSelected());
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: ({ row }) => (
        <FormatDateAndTime
          dateTime={row.original?.date}
          format={"DD/MM/YYYY"}
        />
      ),
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Log Hour",
      accessorKey: "totalHours",
    },
    {
      header: "Is Approved",
      cell: ({ row }) => (
        <>
          {row?.original?.isApproved === true ? (
            <span className="text-success font-bold">Approved</span>
          ) : row?.original?.isApproved === false ? (
            <span className="text-danger font-bold">Rejected</span>
          ) : (
            <span className="font-bold">Take Action</span>
          )}
        </>
      ),
    },
  ];
  return (
    <Transition.Root show={approve} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => {
          setApprove(false);
          employeeMachineService.send({ type: "cancel_task_hours_crud" });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <Button
                    size="xs"
                    variant="darkOutline"
                    className="absolute right-4 top-3 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-dark-foreground"
                    onClick={() => {
                      employeeMachineService.send({
                        type: "cancel_task_hours_crud",
                      });
                      onClose();
                    }}
                  >
                    <X className="h-4 w-4 text-" />
                    <span className="sr-only">Close</span>
                  </Button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semoibold leading-6 text-foreground"
                    >
                      Approve or reject your log hour.
                    </Dialog.Title>
                  </div>
                </div>
                <div>
                  <DataTable
                    data={selectedLogHourForApprove}
                    columns={columns}
                    setRowSelected={setRowSelected}
                    rowSelected={rowSelected}
                  />
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className=" bg-green-500"
                    disabled={Object.keys(rowSelected).length === 0}
                    onClick={() => {
                      const selectedKeys = Object.keys(rowSelected);
                      const filteredHours = selectedLogHourForApprove?.filter(
                        (item, index) => {
                          log.debug("Task, index", item, index);
                          return selectedKeys.includes(index.toString())
                            ? true
                            : false;
                        }
                      );
                      log.debug("filteredHours", filteredHours);
                      employeeMachineService.send({
                        type: "approve_task_hours",
                        data: {
                          filterLogHourForApprove: filteredHours,
                          isApprove: true,
                        },
                      });
                      onClose();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    type="button"
                    variant="danger"
                    disabled={Object.keys(rowSelected).length === 0}
                    className="mr-5"
                    onClick={() => {
                      const selectedKeys = Object.keys(rowSelected);
                      const filteredHours = selectedLogHourForApprove?.filter(
                        (item, index) => {
                          log.debug("Task, index", item, index);
                          return selectedKeys.includes(index.toString())
                            ? true
                            : false;
                        }
                      );
                      employeeMachineService.send({
                        type: "approve_task_hours",
                        data: {
                          filterLogHourForApprove: filteredHours,
                          isApprove: false,
                        },
                      });
                      onClose();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
