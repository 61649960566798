import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import log from "loglevel";
import * as customQueries from "../graphql/customQueries";

export const hookAddTask = async (Task) => {
  log.debug(" hookAddTask : ", Task);

  try {
    const result = await API.graphql({
      query: mutations.createTasks,
      variables: {
        input: Task,
      },
    });

    log.debug(" hookAddTask result: ", result);

    return result?.data?.createTasks;
  } catch (err) {
    log.debug("CATCH ERROR hookAddTask result: ", err);
    return null;
  }
};

export const hookUpdateTask = async (Task) => {
  log.debug(" hookUpdateTask : ", Task);
  try {
    const result = await API.graphql({
      query: mutations.updateTasks,
      variables: {
        input: Task,
      },
    });

    log.debug(" hookUpdateTask result: ", result);
    return result?.data?.updateTasks;
  } catch (err) {
    log.debug("CATCH ERROR hookUpdateTask result: ", err);
  }
};

export const hookDeleteTaskByTaskId = async (TaskId) => {
  log.debug(" hookDeleteTaskByTaskId  ", TaskId);

  try {
    //Connect Client Amplify GraphQL
    const data = await API.graphql({
      query: mutations.deleteTasks,
      variables: {
        input: { id: TaskId },
      },
    });

    log.debug(" hookDeleteTaskByTaskId : ", data);
    if (data !== null) return data?.data?.deleteTasks;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookDeleteTaskByTaskUniqueID -- ", e);
    return null;
  }
};

export const hookListTasksByUserId = async (userid) => {
  log.debug(" hookListTasksByUserId uniqueId", userid);

  try {
    const data = await API.graphql({
      query: queries.TasksByUserID,
      variables: {
        userID: userid,
      },
    });

    log.debug(" hookListTasksByUserId : ", data);

    if (data !== null) return data?.data?.TasksByUserID.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListTasksByUserId -- ", e);
    return null;
  }
};

export const hookListTasks = async () => {
  try {
    const data = await API.graphql({
      query: queries.listTasks,
      variables: {},
    });

    log.debug(" hookListTasksByUserId : ", data);

    if (data !== null) return data?.data?.listTasks.items;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookListTasksByUserId -- ", e);
    return null;
  }
};

export const hookGetTaskTitleById = async (taskId) => {
  log.debug(" hookGetTaskTitleById ++ ", taskId);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: customQueries.getTaskNameByTaskId,
      variables: {
        id: taskId,
      },
    });

    log.debug(" hookGetTaskTitleById : ", result);
    if (result !== null) return result?.data?.getEmployeeTask?.title;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskTitleById -- ", e);

    return null;
  }
};

export const hookGetTaskById = async (taskId) => {
  log.debug(" hookGetTaskById ++ ", taskId);

  try {
    //Connect Client Amplify GraphQL
    const result = await API.graphql({
      query: queries.getEmployeeTask,
      variables: {
        id: taskId,
      },
    });

    log.debug(" hookGetTaskById : ", result);
    if (result !== null) return result?.data?.getEmployeeTask;
    else return null;
  } catch (e) {
    console.error("CATCH ERROR hookGetTaskById -- ", e);

    return null;
  }
};
