import React, { useState } from "react";
import {
  useAuthRegisterState,
  useAuthService,
  useAuthContextData,
} from "../../customHooks/authMachineHooks";
import { TextField } from "../Fields";
import Message from "../common/Messages";
import { cn } from "../../lib/utils";
import log from "loglevel";
import { TypographyH2 } from "../CustomComponentsLibrary/Typography";
import { Label } from "../CustomComponentsLibrary/Label";
import { Input } from "../CustomComponentsLibrary/Input";
import { Button } from "../CustomComponentsLibrary/Button";

export default function RegisterFormComponent() {
  const { theme } = useAuthContextData();
  const [imageToUpload, setImageToUpload] = useState({
    preview: "",
    raw: "",
    name: "",
  });

  let [isTSOpen, setIsTSOpen] = useState(false);
  let [isPPOpen, setIsPPOpen] = useState(false);
  const [message, setMessage] = useState({
    messageText: "",
    messageType: "",
  });
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const { isFormSubmitting } = useAuthRegisterState();
  const authService = useAuthService();
  const { error } = useAuthContextData();

  function onPasswordsChange(e) {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    if (e.target.name === "confirmPassword") {
      if (passwords.password !== e.target.value) {
        setMessage({
          messageText: "confirm Password not matching with password!",
          messageType: "failure",
        });
      } else {
        setMessage({
          messageText: "both passwords  matching",
          messageType: "success",
        });
      }
    }
  }

  function handleSubmit() {
    log.debug("Submit", email, username, passwords.password);
    authService.send({
      type: "submitSignUpForm",
      data: {
        email: email,
        name: username,
        password: passwords.password,
      },
    });
    // setMailSent(true)
  }
  return (
    <>
      <div className={cn("flex flex-col place-items-center sm:w-[60%]  ")}>
        <div className="mx-auto flex w-full flex-col justify-center bg-transparent space-y-10">
          <div className="flex flex-col space-y-2 text-center">
            <TypographyH2>Sign Up</TypographyH2>
            <Label>Create your account by entering the following details</Label>
          </div>

          <form className="mt-10 grid grid-cols-1 gap-y-3  ml-8 md:0 gap-x-6 ">
            <Label>Email Address</Label>
            <Input
              label="Email"
              id="email"
              name="email"
              type="text"
              autoComplete="email"
              className={`"mt-2 mr-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
              onChange={(e) => {
                log.debug("OnChange", e.target.value);
                setEmail(e.target.value);
              }}
              required
            />
            <Label>Full Name</Label>
            <Input
              label="Full Name"
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              className={`"mt-2 mr-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
              onChange={(e) => {
                log.debug("OnChange", e.target.value);
                setUsername(e.target.value);
              }}
            />
            <Label>Password</Label>
            <Input
              className={`"mt-2 mr-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
              label="Password"
              id="password"
              name="password"
              type="password"
              required
              onChange={(e) => {
                onPasswordsChange(e);
              }}
            />
            <Label>Confirm Password</Label>
            <Input
              className={`"mt-2 mr-2 p-4  ring-offset-common  transition-colors  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-1   focus-visible:ring-1 focus-visible:ring-common" ${theme?.border}`}
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={onPasswordsChange}
              required
            />

            {message.messageText ? (
              <div className="text-center flex flex-row">
                <Message message={message} setMessage={setMessage} />
              </div>
            ) : (
              ""
            )}

            <Button
              disabled={passwords.password !== passwords.confirmPassword}
              type="button"
              onClick={handleSubmit}
              className={`" items-center px-4 py-5  md:w-full w-64 text-center text-base font-medium" ${theme?.background} ${theme?.textColor}`}
            >
              Sign Up
            </Button>
          </form>
          <div className="flex grow flex-row justify-center gap-2">
            <p className=" invisible mt-2 text-sm  text-black md:visible">
              If you already have an account?
              <Button
                variant="link"
                onClick={() => {
                  authService.send({ type: "signInOptions" });
                }}
                className={`${theme?.textLight}`}
              >
                Sign In
              </Button>
              for a free trial.
            </p>
          </div>
          <p className="px-8 text-center text-sm  text-black">
            By clicking continue, you agree to our
            <Button
              variant="link"
              onClick={() => {
                openInNewTab("https://www.delight360.biz/terms-of-service");
              }}
              type="button"
              className="text-delightmenuRichBlack underline"
            >
              Terms of Service
            </Button>
            <Button
              className="text-delightmenuRichBlack underline"
              variant="link"
              onClick={() => {
                openInNewTab("https://www.delight360.biz/privacy-policy");
              }}
            >
              Privacy Policy
            </Button>
            .
          </p>
        </div>

        <div className="flex grow flex-col justify-end gap-2">
          {/* User already exists error */}
          {error ? (
            <div className="flex grow flex-row justify-center gap-2">
              <div className="bg-red-100 py-4 text-center lg:px-4">
                <div
                  className="flex items-center bg-red-500 p-2 leading-none text-white lg:inline-flex lg:rounded-full"
                  role="alert"
                >
                  <span className="mr-2 flex-auto text-left font-semibold">
                    {error}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
