import log from "loglevel";
import { hookAddBlogs, hookDeleteBlogByBlogId, hookGetBlogsByOrgId, hookUpdateBlog } from "../../hooks/blogsHooks";
import { CONST_UPDATE_CONTEXT_ACTIONS } from "../../constants/dbconstants";

export const getBlogsByEmployeeIdService = (context) => {
  return new Promise(async (resolve, reject) => {
    const {organizationID } = context;
    const blogs= await hookGetBlogsByOrgId(organizationID)
  
    if (blogs) {  
      console.log("getBlogsByEmployeeIdService=>",blogs)   
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "blogsData",
          value: blogs,
        },           
      ]);
   }else{
    reject([
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.addArr, 
        contextField: "blogsData",
        value: [],
      },       
    ]);
   }
   
  })
};

export const createBlogService = (context) => {
  return new Promise(async (resolve, reject) => {
    const { blogToAdd, organizationID } = context;   
    const blog = await hookAddBlogs({ ...blogToAdd, organizationID });
    console.log("createBlogService=>",blog)
    if (blog) { 
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: 'Blog created succesfully',
        },       
      ]);
    }else{
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update, 
          contextField: "message",
          value: 'Failed to create Blog.',
        },       
      ]);
    }
   
  });
};

export const updateBlogService = (context) =>{
return  new Promise(async (resolve, reject) => {
  const { blogToAdd ,organizationID} = context;
 
  const res = await hookUpdateBlog({
    ...blogToAdd,organizationID
  });

  if (res)
    resolve([     
      {
        action: CONST_UPDATE_CONTEXT_ACTIONS.update,
        contextField: "message",
        value: "Successfully updated blog",
      },
    ]);
  reject([
    {
      action: CONST_UPDATE_CONTEXT_ACTIONS.update,
      contextField: "message",
      value: "Failed To update blog",
    },
  ]);
});
}
 
export const deleteBlogService = (context) =>{
  return new Promise(async (resolve, reject) => {
    const { selectedBlogId } = context;
    const blogDeleted = await hookDeleteBlogByBlogId(selectedBlogId);
    log.debug("deletedBlogService====>", blogDeleted);
    if (blogDeleted) {
      resolve([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: 'Blog deleted succesfully',
        },       
      ]);
    }else{
      reject([
        {
          action: CONST_UPDATE_CONTEXT_ACTIONS.update,
          contextField: "message",
          value: 'Failed to delete blog',
        },       
      ]);
    } 
    
  });
}
 

